import { color } from '../../../theme';

export const styles = {
  name: {
    textDecoration: 'underline',
    ml: 1,
    cursor: 'pointer',
    overflowWrap: 'anywhere',
    '&:hover': {
      color: color.link
    }
  },
  container: {
    backgroundColor: color.primaryBackground,
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    minHeight: '200px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 2
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 1
  },
  callerIcon: {
    height: '129px',
    width: '129px'
  },
  icon: {
    fontSize: '30px'
  },
  text: {
    marginLeft: 1,
    marginRight: 3
  },
  phoneCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  },
  phone: {
    width: 'calc(50% - 7px)'
  },
  btn: {
    width: 'fit-content',
    mt: 1
  },
  noActiveCont: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
