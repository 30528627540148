import { put, takeLatest } from 'redux-saga/effects';
import { KEYS } from '../../../constants';
import {
  addNoteFailure,
  addNoteRequest,
  addNoteSuccess,
  addTaskCallCenterFailure,
  addTaskCallCenterRequest,
  addTaskCallCenterSuccess,
  getCallAgentAllCallHistoryFailure,
  getCallAgentAllCallHistoryRequest,
  getCallAgentAllCallHistorySuccess,
  getCallAgentAllTasksFailure,
  getCallAgentAllTasksRequest,
  getCallAgentAllTasksSuccess,
  getCallAgentCallerDetailsFailure,
  getCallAgentCallerDetailsRequest,
  getCallAgentCallerDetailsSuccess,
  getCallAgentCattleOrEmployeeFailure,
  getCallAgentCattleOrEmployeeRequest,
  getCallAgentCattleOrEmployeeSuccess,
  getCallAgentMyTasksFailure,
  getCallAgentMyTasksRequest,
  getCallAgentMyTasksSuccess,
  getCallCenterAgentsFailure,
  getCallCenterAgentsRequest,
  getCallCenterAgentsSuccess,
  getCallCenterTaskDetailsFailure,
  getCallCenterTaskDetailsRequest,
  getCallCenterTaskDetailsSuccess,
  getCallCenterUserInfoFailure,
  getCallCenterUserInfoRequest,
  getCallCenterUserInfoSuccess,
  getCallCenterUsersFailure,
  getCallCenterUsersRequest,
  getCallCenterUsersSuccess,
  getCallerCallHistoryFailure,
  getCallerCallHistoryRequest,
  getCallerCallHistorySuccess,
  getCallerTasksFailure,
  getCallerTasksRequest,
  getCallerTasksSuccess,
  getCattleTaskFailure,
  getCattleTaskRequest,
  getCattleTaskSuccess,
  updateCallCenterAgentsFailure,
  updateCallCenterAgentsRequest,
  updateCallCenterAgentsSuccess,
  updateTaskCallCenterFailure,
  updateTaskCallCenterRequest,
  updateTaskCallCenterSuccess
} from '../slices';
import {
  addNoteApi,
  addTaskCallCenterApi,
  callAgentAllCallHistoryApi,
  callAgentAllTasksApi,
  callAgentMyTasksApi,
  callCenterAgentApi,
  callCenterTaskDetailsApi,
  callCenterUserInfoApi,
  callCenterUsersApi,
  callerCallHistoryApi,
  callerDetailsApi,
  callerTasksApi,
  cattleOrEmployeeApi,
  cattleTasksApi,
  updateCallCenterAgentApi,
  updateTaskCallCenterApi
} from '../../api';

function* callAgentMyTasks(action) {
  try {
    const response = yield callAgentMyTasksApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCallAgentMyTasksSuccess(response.data));
    } else {
      yield put(getCallAgentMyTasksFailure(response.data));
    }
  } catch (error) {
    yield put(getCallAgentMyTasksFailure(error.response.data));
  }
}

function* callAgentAllTasks(action) {
  try {
    const response = yield callAgentAllTasksApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCallAgentAllTasksSuccess(response.data));
    } else {
      yield put(getCallAgentAllTasksFailure(response.data));
    }
  } catch (error) {
    yield put(getCallAgentAllTasksFailure(error.response.data));
  }
}

function* callAgentAllCallHistory(action) {
  try {
    const response = yield callAgentAllCallHistoryApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCallAgentAllCallHistorySuccess(response.data));
    } else {
      yield put(getCallAgentAllCallHistoryFailure(response.data));
    }
  } catch (error) {
    yield put(getCallAgentAllCallHistoryFailure(error.response.data));
  }
}

function* callCenterTaskDetails(action) {
  try {
    const response = yield callCenterTaskDetailsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCallCenterTaskDetailsSuccess(response.data));
    } else {
      yield put(getCallCenterTaskDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getCallCenterTaskDetailsFailure(error.response.data));
  }
}

function* callCenterUsers(action) {
  try {
    const response = yield callCenterUsersApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCallCenterUsersSuccess(response.data));
    } else {
      yield put(getCallCenterUsersFailure(response.data));
    }
  } catch (error) {
    yield put(getCallCenterUsersFailure(error.response.data));
  }
}

function* callCenterUserInfo(action) {
  try {
    const response = yield callCenterUserInfoApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCallCenterUserInfoSuccess(response.data));
    } else {
      yield put(getCallCenterUserInfoFailure(response.data));
    }
  } catch (error) {
    yield put(getCallCenterUserInfoFailure(error.response.data));
  }
}

function* addTaskCallCenter(action) {
  try {
    const response = yield addTaskCallCenterApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addTaskCallCenterSuccess(response.data));
    } else {
      yield put(addTaskCallCenterFailure(response.data));
    }
  } catch (error) {
    yield put(addTaskCallCenterFailure(error.response.data));
  }
}

function* updateTaskCallCenter(action) {
  try {
    const response = yield updateTaskCallCenterApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateTaskCallCenterSuccess(response.data));
    } else {
      yield put(updateTaskCallCenterFailure(response.data));
    }
  } catch (error) {
    yield put(updateTaskCallCenterFailure(error.response.data));
  }
}
function* callerTasks(action) {
  try {
    const response = yield callerTasksApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCallerTasksSuccess(response.data));
    } else {
      yield put(getCallerTasksFailure(response.data));
    }
  } catch (error) {
    yield put(getCallerTasksFailure(error.response.data));
  }
}

function* callerCallHistory(action) {
  try {
    const response = yield callerCallHistoryApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCallerCallHistorySuccess(response.data));
    } else {
      yield put(getCallerCallHistoryFailure(response.data));
    }
  } catch (error) {
    yield put(getCallerCallHistoryFailure(error.response.data));
  }
}

function* addNote(action) {
  try {
    const response = yield addNoteApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addNoteSuccess(response.data));
    } else {
      yield put(addNoteFailure(response.data));
    }
  } catch (error) {
    yield put(addNoteFailure(error.response.data));
  }
}

function* cattleOrEmployee(action) {
  try {
    const response = yield cattleOrEmployeeApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCallAgentCattleOrEmployeeSuccess(response.data));
    } else {
      yield put(getCallAgentCattleOrEmployeeFailure(response.data));
    }
  } catch (error) {
    yield put(getCallAgentCattleOrEmployeeFailure(error.response.data));
  }
}

function* callerDetails(action) {
  try {
    const response = yield callerDetailsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCallAgentCallerDetailsSuccess(response.data));
    } else {
      yield put(getCallAgentCallerDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getCallAgentCallerDetailsFailure(error.response.data));
  }
}

function* cattleTasks(action) {
  try {
    const response = yield cattleTasksApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCattleTaskSuccess(response.data));
    } else {
      yield put(getCattleTaskFailure(response.data));
    }
  } catch (error) {
    yield put(getCattleTaskFailure(error.response.data));
  }
}

function* callCenteragents(action) {
  try {
    const response = yield callCenterAgentApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getCallCenterAgentsSuccess(response.data));
    } else {
      yield put(getCallCenterAgentsFailure(response.data));
    }
  } catch (error) {
    yield put(getCallCenterAgentsFailure(error.response.data));
  }
}

function* updateCallCenteragents(action) {
  try {
    const response = yield updateCallCenterAgentApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateCallCenterAgentsSuccess(response.data));
    } else {
      yield put(updateCallCenterAgentsFailure(response.data));
    }
  } catch (error) {
    yield put(updateCallCenterAgentsFailure(error.response.data));
  }
}

export function* callCenterSaga() {
  yield takeLatest(getCallAgentMyTasksRequest.type, callAgentMyTasks);
  yield takeLatest(getCallAgentAllTasksRequest.type, callAgentAllTasks);
  yield takeLatest(getCallAgentAllCallHistoryRequest.type, callAgentAllCallHistory);
  yield takeLatest(getCallCenterTaskDetailsRequest.type, callCenterTaskDetails);
  yield takeLatest(getCallCenterUsersRequest.type, callCenterUsers);
  yield takeLatest(getCallCenterUserInfoRequest.type, callCenterUserInfo);
  yield takeLatest(addTaskCallCenterRequest.type, addTaskCallCenter);
  yield takeLatest(updateTaskCallCenterRequest.type, updateTaskCallCenter);
  yield takeLatest(getCallerTasksRequest.type, callerTasks);
  yield takeLatest(getCallerCallHistoryRequest.type, callerCallHistory);
  yield takeLatest(addNoteRequest.type, addNote);
  yield takeLatest(getCallAgentCattleOrEmployeeRequest.type, cattleOrEmployee);
  yield takeLatest(getCallAgentCallerDetailsRequest.type, callerDetails);
  yield takeLatest(getCattleTaskRequest.type, cattleTasks);
  yield takeLatest(getCallCenterAgentsRequest.type, callCenteragents);
  yield takeLatest(updateCallCenterAgentsRequest.type, updateCallCenteragents);
}
