import { Box } from '@mui/material';
import { Text } from '../text';
import { getTitles } from '../../utils';
import { globalStyles } from '../../theme';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { defaultValues } from '../../constants';
import { useEffect, useState } from 'react';
import { UIButton } from '../button';
import { Delete } from '@mui/icons-material';
import { EditButton } from '../edit-button';

export const CollapseDropView = ({
  title,
  subTitle = getTitles('T-10428'),
  handleSubTitleClick,
  open,
  isSubtitle = true,
  containerStyle,
  handleDelete,
  handleEdit,
  titleVariant = 'BoldH22',
  subTitleVariant = 'BoldH22',
  chevronStyle
}) => {
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setExpand(open);
  }, [open]);
  const styles = {
    container: (style) => ({ display: 'flex', width: '100%', alignItems: 'center', ...style }),
    title: {
      width: defaultValues.isMobile ? '50%' : '30%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    subTitle: {
      cursor: 'pointer'
    },
    icon: (expand, style) => ({
      transform: expand ? `rotate(90deg)` : 'none',
      transition: 'transform 0.3s ease',
      fontSize: defaultValues.isResponsive ? '20px' : '32px',
      cursor: 'pointer',
      ...style
    }),
    toolsCont: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      gap: '10px',
      marginLeft: 'auto'
    },
    editBtn: {
      '&.MuiButton-root': {
        minWidth: '0px'
      },
      width: 'fit-content',
      '& .MuiButton-startIcon': {
        marginRight: '0px',
        marginLeft: '0px'
      },
      padding: '5px',
      boxSizing: 'border-box'
    },
    delete: {
      cursor: 'pointer',
      width: defaultValues.isResponsive ? '20px' : '26px',
      height: defaultValues.isResponsive ? '20px' : '26px'
    }
  };
  return (
    <Box sx={styles.container(containerStyle)}>
      <Text variant={titleVariant} style={styles.title}>
        {title}
      </Text>
      <ChevronRightIcon sx={styles.icon(expand, chevronStyle)} onClick={handleSubTitleClick} />
      {handleSubTitleClick && isSubtitle && !expand && (
        <Text
          variant={subTitleVariant}
          style={{ ...globalStyles.link, ...styles.subTitle }}
          onClick={handleSubTitleClick}>
          {subTitle}
        </Text>
      )}

      {expand && (
        <Box sx={styles.toolsCont}>
          <>
            {handleEdit && (
              <UIButton
                startIcon={<EditButton iconStyle={styles.delete} />}
                style={styles.editBtn}
                onClick={() => handleEdit()}
              />
            )}
            {handleDelete && (
              <UIButton
                startIcon={<Delete sx={styles.delete} />}
                style={styles.editBtn}
                onClick={() => handleDelete()}
              />
            )}
          </>
        </Box>
      )}
    </Box>
  );
};
