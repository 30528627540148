import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { defaultValues } from '../../../../constants';
import { filterSelectedValue, getNamesByIds, getTitles } from '../../../../utils';
import {
  Text,
  UICheckBox,
  UIChipButton,
  UIRadioButton,
  UISelect,
  UITextInputWithTitle,
  UITitleWithContent
} from '../../../../ui-kits';
import { color, globalStyles } from '../../../../theme';
import { ModalButtons } from '../../../common/modal-buttons';
import { useEffect, useState } from 'react';
import { FormTitleCard } from '../../../common';
import {
  addSocietyRequest,
  updateScrollToTop,
  updateSocietyRequest
} from '../../../../services/redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import { getMilkSuppliedList, getScroll } from '../../../../services/redux/selectors';

const res = defaultValues.isResponsive;

const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '10px'
  },
  width: res ? '100%' : 'calc((100% - 10px)/3)',

  radioSubCont: {
    width: defaultValues.isResponsive ? '100%' : 'calc((100% + 5px)/3 * 2)',
    display: 'flex',
    gap: '10px'
  },
  checkboxIcon: {
    color: color.primaryText
  },
  divider: {
    borderBottom: `2px solid ${color.primaryBorder}`,
    height: '10px',
    width: 'calc(((100%/3 )* 2) - 5px)'
  },
  namegridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `"first first"
    "middle last"
    `
      : `
    "first middle last"
    `,
    gridTemplateColumns: res ? globalStyles.gridRes : globalStyles.gridNormal,
    gap: '10px',
    marginTop: '10px'
  },

  milkgridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `
      "first middle"
      "last total"
      "active count"
    `
      : `
    "first middle last"
    "total active count"
    `,
    gridTemplateColumns: res ? globalStyles.gridRes : globalStyles.gridNormal,
    gap: '10px',
    marginTop: '10px'
  },

  gridFirstName: {
    gridArea: 'first',
    marginTop: '0px'
  },
  gridMiddlename: {
    gridArea: 'middle',
    marginTop: '0px'
  },
  gridLastname: {
    gridArea: 'last',
    marginTop: '0px'
  },
  totalMember: {
    gridArea: 'total',
    marginTop: '0px'
  },
  activeMember: {
    gridArea: 'active',
    marginTop: '0px'
  },
  empCount: {
    gridArea: 'count',
    marginTop: '0px'
  },
  dateCont: {
    width: 'calc((100% + 5px)/3 * 2)',
    display: 'flex',
    gap: '10px'
  },
  chipTitle: { mt: 1, mb: 0 }
};

export const MilkDetailsForm = ({ societyId, societyData, onNext, onPrev, currRender }) => {
  const dispatch = useDispatch();
  const scroll = useSelector(getScroll);
  const milkSupplied = useSelector(getMilkSuppliedList);
  const { add, edit, view } = defaultValues.actionType;

  const [filteredOptions, setFilteredOptions] = useState([]);

  const { values, errors, touched, setValues, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      milk_supplied: [],
      milk_sent: [],
      total_milk_given_last_year: '',
      milk_procurement_computerized: null,
      avg_milk_collected_morning: {
        cow_milk_in_liters: '',
        cow_fat: '',
        cow_snf: '',
        buffalo_milk_in_liters: '',
        buffalo_fat: '',
        buffalo_snf: ''
      },
      avg_milk_collected_evening: {
        cow_milk_in_liters: '',
        cow_fat: '',
        cow_snf: '',
        buffalo_milk_in_liters: '',
        buffalo_fat: '',
        buffalo_snf: ''
      },
      avg_milk_supplied: {
        dairy: '',
        other_society: '',
        private_dairy_gavali: '',
        hotel: '',
        sweet_mart: '',
        local_supply: ''
      },
      feed_stock_mineral: {
        average_feedstock_required_per_month: '',
        minimum_quantity_maintained_feedstock: '',
        average_mineral_required_per_month: '',
        minimum_quantity_maintained_mineral: '',
        number_of_silage_bags_sold_till_date: '',
        silage_bags_stock_maintained: ''
      }
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  useEffect(() => {
    if (societyData) {
      let newValues = {
        ...societyData,
        milk_sent: societyData.milk_sent.map((item) => ({ id: item.id }))
      };
      setValues(newValues);
    }
  }, [societyData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'milk_supplied') {
      if (!values.milk_supplied.includes(value)) {
        setFieldValue(name, [...values.milk_supplied, { _id: value }]);
      }
    } else {
      setFieldValue(name, value);
    }
  };

  useEffect(() => {
    setFilteredOptions(filterSelectedValue(milkSupplied, values.milk_supplied));
  }, [values.milk_supplied, milkSupplied]);

  const handleSubmit = () => {
    let payload = {
      step_number: defaultValues.societyRegisterSteps.milkAndFeedDetails
    };
    if (currRender === add || (currRender === edit && !societyData)) {
      payload = {
        ...payload,
        _id: societyId,
        data: {
          ...values,
          milk_supplied:
            values.milk_supplied?.length > 0 ? values.milk_supplied.map((item) => item._id) : [],
          milk_sent: values.milk_sent.map((item) => item.id)
        }
      };
      dispatch(addSocietyRequest(payload));
    } else if (currRender === edit) {
      payload = {
        ...payload,
        _id: societyId,
        milk_feed_details: {
          ...values,
          milk_supplied:
            values.milk_supplied?.length > 0 ? values.milk_supplied.map((item) => item._id) : [],
          milk_sent: values.milk_sent.map((item) => item.id)
        }
      };

      dispatch(updateSocietyRequest(payload));
    }
    onNext();
    dispatch(updateScrollToTop(!scroll));
  };

  const handleBackButton = () => {
    onPrev();
    dispatch(updateScrollToTop(!scroll));
  };

  const handleDelete = (milkToDelete) => {
    const newVal = values.milk_supplied.filter((milk) => milk._id !== milkToDelete);
    setFieldValue('milk_supplied', newVal);
  };

  return (
    <Box sx={styles.container}>
      {currRender !== view && (
        <UISelect
          name="milk_supplied"
          value={''}
          title={getTitles('T-10754')}
          optionLabel="name"
          returnValue="_id"
          options={filteredOptions}
          onChange={handleChange}
          disabled={currRender === view}
          placeholder={getTitles('T-10350')}
          containerStyles={{ width: res ? '100%' : 'calc((100% + 5px)/3 * 2)' }}
        />
      )}
      {currRender === view && values.milk_supplied?.length > 0 && (
        <Text variant="D20" style={styles.chipTitle}>
          {getTitles('T-10754')}
        </Text>
      )}
      {values.milk_supplied?.length > 0 &&
        values.milk_supplied?.map((item, index) => (
          <UIChipButton
            showDelete={currRender !== view}
            title={getNamesByIds([item._id], milkSupplied)}
            key={index}
            onDelete={() => handleDelete(item._id)}
          />
        ))}

      <UITitleWithContent title={getTitles('T-10755')}>
        <Box sx={styles.radioSubCont}>
          <UICheckBox
            title={getTitles('T-10756')}
            iconStyle={styles.checkboxIcon}
            checked={values.milk_sent.some((item) => item?.id === 1)}
            onClick={() => {
              let arr = [...values.milk_sent];
              handleChange({
                target: {
                  name: 'milk_sent',
                  value: values.milk_sent?.some((item) => item.id === 1)
                    ? arr.filter((item) => item.id !== 1)
                    : [...arr, { id: 1 }]
                }
              });
            }}
            disabled={currRender === view}
          />
          <UICheckBox
            title={getTitles('T-10757')}
            iconStyle={styles.checkboxIcon}
            checked={values.milk_sent?.some((item) => item?.id === 2)}
            onClick={() => {
              let arr = [...values.milk_sent];
              handleChange({
                target: {
                  name: 'milk_sent',
                  value: values.milk_sent?.some((item) => item?.id === 2)
                    ? arr.filter((item) => item?.id !== 2)
                    : [...arr, { id: 2 }]
                }
              });
            }}
            disabled={currRender === view}
          />
          <UICheckBox
            title={getTitles('T-10758')}
            iconStyle={styles.checkboxIcon}
            checked={values.milk_sent.some((item) => item.id === 3)}
            onClick={() => {
              let arr = [...values.milk_sent];
              handleChange({
                target: {
                  name: 'milk_sent',
                  value: values.milk_sent?.some((item) => item.id === 3)
                    ? arr.filter((item) => item.id !== 3)
                    : [...arr, { id: 3 }]
                }
              });
            }}
            disabled={currRender === view}
          />
        </Box>
      </UITitleWithContent>

      <UITextInputWithTitle
        name="total_milk_given_last_year"
        title={getTitles('T-10759')}
        placeHolder={getTitles('T-10360')}
        error={touched.total_milk_given_last_year && errors.total_milk_given_last_year}
        value={values.total_milk_given_last_year}
        onChange={handleChange}
        onBlur={handleBlur}
        allowDecimal={true}
        inputStyle={{ width: `calc(${styles.width} - 5px)` }}
        disabled={currRender === view}
      />

      <UITitleWithContent title={getTitles('T-10760')}>
        <Box sx={styles.radioSubCont}>
          <UIRadioButton
            title={getTitles('T-10705')}
            checked={values.milk_procurement_computerized}
            onClick={() =>
              handleChange({ target: { name: 'milk_procurement_computerized', value: true } })
            }
            containerStyle={{ width: styles.width }}
            disabled={currRender === view}
          />
          <UIRadioButton
            title={getTitles('T-10706')}
            checked={values.milk_procurement_computerized === false}
            onClick={() =>
              handleChange({ target: { name: 'milk_procurement_computerized', value: false } })
            }
            containerStyle={{ width: styles.width }}
            disabled={currRender === view}
          />
        </Box>
      </UITitleWithContent>

      <FormTitleCard title={getTitles('T-10761')}>
        <Text variant="boldH32Sec" style={{ mt: 1 }}>
          {getTitles('T-10762')}
        </Text>

        <UITitleWithContent
          title={getTitles('T-10516')}
          variant="BoldH22sec"
          containerStyle={{ marginTop: '0px' }}>
          <Box sx={styles.namegridCont}>
            <UITextInputWithTitle
              name="avg_milk_collected_morning.cow_milk_in_liters"
              title={getTitles('T-10517')}
              placeHolder={getTitles('T-10520')}
              value={
                values.avg_milk_collected_morning &&
                values.avg_milk_collected_morning.cow_milk_in_liters
              }
              onChange={handleChange}
              onBlur={handleBlur}
              containerStyles={globalStyles.gridFirstName}
              disabled={currRender === view}
              allowDecimal={true}
            />
            <UITextInputWithTitle
              name="avg_milk_collected_morning.cow_fat"
              title={getTitles('T-10518')}
              placeHolder={getTitles('T-10521')}
              value={values.avg_milk_collected_morning && values.avg_milk_collected_morning.cow_fat}
              onChange={handleChange}
              onBlur={handleBlur}
              containerStyles={globalStyles.gridMiddlename}
              disabled={currRender === view}
              allowDecimal={true}
            />

            <UITextInputWithTitle
              name="avg_milk_collected_morning.cow_snf"
              title={getTitles('T-10519')}
              placeHolder={getTitles('T-10522')}
              value={values.avg_milk_collected_morning && values.avg_milk_collected_morning.cow_snf}
              onChange={handleChange}
              onBlur={handleBlur}
              containerStyles={globalStyles.gridLastname}
              disabled={currRender === view}
              allowDecimal={true}
            />
          </Box>
        </UITitleWithContent>

        <UITitleWithContent title={getTitles('T-10523')} variant="BoldH22sec">
          <Box sx={styles.namegridCont}>
            <UITextInputWithTitle
              name="avg_milk_collected_morning.buffalo_milk_in_liters"
              title={getTitles('T-10517')}
              placeHolder={getTitles('T-10520')}
              value={
                values.avg_milk_collected_morning &&
                values.avg_milk_collected_morning.buffalo_milk_in_liters
              }
              onChange={handleChange}
              onBlur={handleBlur}
              containerStyles={globalStyles.gridFirstName}
              disabled={currRender === view}
              allowDecimal={true}
            />
            <UITextInputWithTitle
              name="avg_milk_collected_morning.buffalo_fat"
              title={getTitles('T-10518')}
              placeHolder={getTitles('T-10521')}
              value={
                values.avg_milk_collected_morning && values.avg_milk_collected_morning.buffalo_fat
              }
              onChange={handleChange}
              onBlur={handleBlur}
              containerStyles={globalStyles.gridMiddlename}
              disabled={currRender === view}
              allowDecimal={true}
            />

            <UITextInputWithTitle
              name="avg_milk_collected_morning.buffalo_snf"
              title={getTitles('T-10519')}
              placeHolder={getTitles('T-10522')}
              value={
                values.avg_milk_collected_morning && values.avg_milk_collected_morning.buffalo_snf
              }
              onChange={handleChange}
              onBlur={handleBlur}
              containerStyles={globalStyles.gridLastname}
              disabled={currRender === view}
              allowDecimal={true}
            />
          </Box>
        </UITitleWithContent>

        <Text variant="boldH32Sec" style={{ mt: 3 }}>
          {getTitles('T-10763')}
        </Text>

        <UITitleWithContent
          title={getTitles('T-10516')}
          variant="BoldH22sec"
          containerStyle={{ marginTop: '0px' }}>
          <Box sx={styles.namegridCont}>
            <UITextInputWithTitle
              name="avg_milk_collected_evening.cow_milk_in_liters"
              title={getTitles('T-10517')}
              placeHolder={getTitles('T-10520')}
              value={
                values.avg_milk_collected_evening &&
                values.avg_milk_collected_evening.cow_milk_in_liters
              }
              onChange={handleChange}
              onBlur={handleBlur}
              containerStyles={globalStyles.gridFirstName}
              disabled={currRender === view}
              allowDecimal={true}
            />
            <UITextInputWithTitle
              name="avg_milk_collected_evening.cow_fat"
              title={getTitles('T-10518')}
              placeHolder={getTitles('T-10521')}
              value={values.avg_milk_collected_evening && values.avg_milk_collected_evening.cow_fat}
              onChange={handleChange}
              onBlur={handleBlur}
              containerStyles={globalStyles.gridMiddlename}
              disabled={currRender === view}
              allowDecimal={true}
            />

            <UITextInputWithTitle
              name="avg_milk_collected_evening.cow_snf"
              title={getTitles('T-10519')}
              placeHolder={getTitles('T-10522')}
              value={values.avg_milk_collected_evening && values.avg_milk_collected_evening.cow_snf}
              onChange={handleChange}
              onBlur={handleBlur}
              containerStyles={globalStyles.gridLastname}
              disabled={currRender === view}
              allowDecimal={true}
            />
          </Box>
        </UITitleWithContent>

        <UITitleWithContent title={getTitles('T-10523')} variant="BoldH22sec">
          <Box sx={styles.namegridCont}>
            <UITextInputWithTitle
              name="avg_milk_collected_evening.buffalo_milk_in_liters"
              title={getTitles('T-10517')}
              placeHolder={getTitles('T-10520')}
              value={
                values.avg_milk_collected_evening &&
                values.avg_milk_collected_evening.buffalo_milk_in_liters
              }
              onChange={handleChange}
              onBlur={handleBlur}
              containerStyles={globalStyles.gridFirstName}
              disabled={currRender === view}
              allowDecimal={true}
            />
            <UITextInputWithTitle
              name="avg_milk_collected_evening.buffalo_fat"
              title={getTitles('T-10518')}
              placeHolder={getTitles('T-10521')}
              value={
                values.avg_milk_collected_evening && values.avg_milk_collected_evening.buffalo_fat
              }
              onChange={handleChange}
              onBlur={handleBlur}
              containerStyles={globalStyles.gridMiddlename}
              disabled={currRender === view}
              allowDecimal={true}
            />

            <UITextInputWithTitle
              name="avg_milk_collected_evening.buffalo_snf"
              title={getTitles('T-10519')}
              placeHolder={getTitles('T-10522')}
              value={
                values.avg_milk_collected_evening && values.avg_milk_collected_evening.buffalo_snf
              }
              onChange={handleChange}
              onBlur={handleBlur}
              containerStyles={globalStyles.gridLastname}
              disabled={currRender === view}
              allowDecimal={true}
            />
          </Box>
        </UITitleWithContent>
      </FormTitleCard>

      <FormTitleCard title={getTitles('T-10764')}>
        <Box sx={styles.milkgridCont}>
          <UITextInputWithTitle
            name="avg_milk_supplied.dairy"
            title={getTitles('T-10613')}
            placeHolder={getTitles('T-11050')}
            value={values.avg_milk_supplied && values.avg_milk_supplied.dairy}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.gridFirstName}
            disabled={currRender === view}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="avg_milk_supplied.private_dairy_gavali"
            title={getTitles('T-10765')}
            placeHolder={getTitles('T-11050')}
            value={values.avg_milk_supplied && values.avg_milk_supplied.private_dairy_gavali}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.gridMiddlename}
            titleStyle={globalStyles.text}
            disabled={currRender === view}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="avg_milk_supplied.other_society"
            title={getTitles('T-10766')}
            placeHolder={getTitles('T-11050')}
            value={values.avg_milk_supplied && values.avg_milk_supplied.other_society}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.gridLastname}
            disabled={currRender === view}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="avg_milk_supplied.hotel"
            title={getTitles('T-10767')}
            placeHolder={getTitles('T-11050')}
            value={values.avg_milk_supplied && values.avg_milk_supplied.hotel}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.totalMember}
            disabled={currRender === view}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="avg_milk_supplied.sweet_mart"
            title={getTitles('T-10768')}
            placeHolder={getTitles('T-11050')}
            value={values.avg_milk_supplied && values.avg_milk_supplied.sweet_mart}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.activeMember}
            disabled={currRender === view}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="avg_milk_supplied.local_supply"
            title={getTitles('T-10769')}
            placeHolder={getTitles('T-11050')}
            value={values.avg_milk_supplied && values.avg_milk_supplied.local_supply}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.empCount}
            disabled={currRender === view}
            allowDecimal={true}
          />
        </Box>
      </FormTitleCard>

      <FormTitleCard title={getTitles('T-10771')}>
        <Box sx={styles.nameCont}>
          <UITextInputWithTitle
            name="feed_stock_mineral.average_feedstock_required_per_month"
            title={getTitles('T-10772')}
            placeHolder={getTitles('T-10777')}
            value={
              values.feed_stock_mineral &&
              values.feed_stock_mineral.average_feedstock_required_per_month
            }
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={currRender === view}
          />
          <UITextInputWithTitle
            name="feed_stock_mineral.minimum_quantity_maintained_feedstock"
            title={getTitles('T-10773')}
            placeHolder={getTitles('T-10777')}
            value={
              values.feed_stock_mineral &&
              values.feed_stock_mineral.minimum_quantity_maintained_feedstock
            }
            onChange={handleChange}
            onBlur={handleBlur}
            titleStyle={globalStyles.text}
            disabled={currRender === view}
          />
        </Box>

        <Box sx={styles.nameCont}>
          <UITextInputWithTitle
            name="feed_stock_mineral.average_mineral_required_per_month"
            title={getTitles('T-10774')}
            placeHolder={getTitles('T-10777')}
            value={
              values.feed_stock_mineral &&
              values.feed_stock_mineral.average_mineral_required_per_month
            }
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={currRender === view}
          />
          <UITextInputWithTitle
            name="feed_stock_mineral.minimum_quantity_maintained_mineral"
            title={getTitles('T-10773')}
            placeHolder={getTitles('T-10777')}
            value={
              values.feed_stock_mineral &&
              values.feed_stock_mineral.minimum_quantity_maintained_mineral
            }
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={currRender === view}
          />
        </Box>

        <Box sx={styles.nameCont}>
          <UITextInputWithTitle
            name="feed_stock_mineral.number_of_silage_bags_sold_till_date"
            title={getTitles('T-10775')}
            placeHolder={getTitles('T-10777')}
            value={
              values.feed_stock_mineral &&
              values.feed_stock_mineral.number_of_silage_bags_sold_till_date
            }
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={currRender === view}
          />
          <UITextInputWithTitle
            name="feed_stock_mineral.silage_bags_stock_maintained"
            title={getTitles('T-10776')}
            placeHolder={getTitles('T-10777')}
            value={
              values.feed_stock_mineral && values.feed_stock_mineral.silage_bags_stock_maintained
            }
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={currRender === view}
            allowDecimal={true}
          />
        </Box>
      </FormTitleCard>

      <ModalButtons
        secondaryBtnTitle={getTitles('T-10340')}
        onSecondaryBtnClick={handleBackButton}
        onPrimaryBtnClick={handleSubmit}
        primaryBtnTitle={
          currRender === add
            ? getTitles('T-10339')
            : currRender === edit
              ? getTitles('T-10733')
              : getTitles('T-10804')
        }
        containerStyle={globalStyles.mt(3)}
      />
    </Box>
  );
};
