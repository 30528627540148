import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, IconButton } from '@mui/material';
import { Text } from '../../../ui-kits';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { color } from '../../../theme';
import { defaultValues } from '../../../constants';

const res = defaultValues.isResponsive;

export const ResponsiveViewDetails = ({
  title,
  details,
  handleEdit,
  handleDelete,
  hideIcons = false
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: res ? '4px' : '8px',
      border: `1px solid ${color.primaryBorder}`,
      borderRadius: '4px',
      backgroundColor: color.palette.paleYellow
    },
    subContainer: (style) => ({
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'right',
      ...style
    }),
    secondHalf: {
      justifyContent: 'start'
    },
    detailContainer: { display: 'flex', alignItems: 'center', padding: '4px 0' },
    item: {
      border: `1px solid ${color.primaryBorder}`,
      ml: 1,
      height: '40px',
      flex: 1,
      borderRadius: '5px',
      paddingX: '4px'
    },
    p: { paddingLeft: '16px' },
    flex: { flex: 1 }
  };

  return (
    <Box>
      <Box sx={styles.container}>
        <Box sx={styles.subContainer(styles.secondHalf)}>
          <Text variant="D22">{title}</Text>
          <IconButton onClick={() => setShowDetails(!showDetails)}>
            {showDetails ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
        </Box>
        {showDetails && !hideIcons && (
          <Box sx={styles.subContainer()}>
            <IconButton onClick={handleEdit}>
              <EditIcon style={{ color: color.primary }} />
            </IconButton>
            <IconButton onClick={handleDelete}>
              <DeleteIcon style={{ color: color.primary }} />
            </IconButton>
          </Box>
        )}
      </Box>
      {showDetails && (
        <Box sx={styles.p}>
          {details.map((detail, index) => (
            <Box key={index} sx={styles.detailContainer}>
              <Text variant="D22" style={styles.flex}>
                {detail.label}:
              </Text>
              <Box sx={styles.item}>
                <Text variant="D22">{detail.value}</Text>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
