import { defaultValues } from '../../../../constants';
import { color, globalStyles } from '../../../../theme';

const sm = defaultValues.isMobile;
const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const xsm = defaultValues.isMdSmall;
export const styles = {
  container: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    boxSizing: 'border-box',
    mt: 2
  },
  left: {
    width: '30%',
    backgroundColor: color.primaryBackground,
    p: 4,
    boxSizing: 'border-box'
  },
  right: {
    width: res ? '100%' : '70%',
    backgroundColor: color.primaryBackground,
    p: sm ? 1 : 2,
    boxSizing: 'border-box'
  },
  radioTitle: {
    textOverflow: 'anywhere',
    whiteSpace: 'wrap'
  },
  common: {
    py: 2,
    borderBottom: `1px solid ${color.primaryBorder}`
  },
  minMaxCont: {
    width: '100%',
    display: 'flex',
    jusitifyContent: 'space-between',
    gap: '10px'
  },
  brands: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  checkbox: {
    width: '50%'
  },
  tag: {
    ...globalStyles.text,
    width: 'calc((100% - 20px)/3)',
    p: 1,
    boxSizing: 'border-box',
    backgroundColor: color.secondaryBackground,
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    textAlign: 'center'
  },
  tagCont: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    mt: 1
  },
  optionsContainer: {
    width: '100%',
    display: 'flex',
    gap: res ? '7px' : '10px'
  },
  productCardContainerStyles: {
    minWidth: xsm ? '100%' : md ? 'calc((100% - 10px)/2)' : 'calc((100% - 20px)/3)'
  },
  tabSub: {
    width: '65%'
  },
  tabContStyle: {
    width: '100%',
    jusitifyContent: 'end'
  },
  toolsIcons: {
    maxWidth: '100%',
    maxHeight: sm ? '14px' : res ? '18px' : '24px'
  },
  toolsIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: sm ? '10px' : '10px 20px',
    boxSizing: 'border-box',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '8px',
    cursor: 'pointer',
    alignItems: 'center',
    backgroundColor: color.secondaryBackground,
    height: res ? '32px' : '48px'
  },
  pagination: { display: 'flex', justifyContent: 'center', mt: 4 },
  filterCont: {
    width: '100%',
    p: sm ? 2 : res ? 4 : 0,
    boxSizing: 'border-box'
  },
  filterSubCont: {
    width: '100%',
    padding: res ? '10px' : '0px 20px 20px 20px',
    boxSizing: 'border-box'
  },
  drawer: {
    '& .MuiPaper-root': {
      width: sm ? '250px' : '350px',
      backgroundColor: color.primaryBackground
    }
  },
  btn: {
    padding: '5px 30px',
    mt: 2
  },
  itemsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'none'
  },
  productCardImageStyle: {
    width: sm ? '100px' : res ? '200px' : '250px',
    height: sm ? '100px' : res ? '200px' : '250px'
  },
  cartCont: {
    padding: '1px 5px',
    height: res ? '30px' : '35px'
  },
  cartCountText: {
    width: sm ? '25px' : '50px',
    textAlign: 'center'
  },
  cartBtn: {
    cursor: 'pointer',
    fontSize: sm ? '15px' : res ? '20px' : '25px'
  },
  activeFilterCont: {
    width: '100%',
    p: 1,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    backgroundColor: color.secondaryBackground,
    borderRadius: '4px',
    mt: 2
  },
  activeFilterSubCont: {
    width: xsm ? '25%' : sm ? '40%' : '60%',
    height: res ? '20px' : '40px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none'
  },
  chip: {
    margin: 0,
    backgroundColor: color.secondaryBackground,
    color: color.primaryText,
    p: sm ? 0 : 1,
    minWidth: 'fit-content',
    boxSizing: 'border-box',
    height: 'fit-content',
    fontWeight: 600,
    fontSize: res ? '0.725rem' : md ? '0.875rem' : '1rem',
    '& .MuiChip-deleteIcon': {
      color: color.primaryText
    }
  },
  filterText: {
    color: color.secondaryText
  },
  results: {
    ml: 'auto',
    color: color.secondaryText
  },
  totalProduct: {
    color: color.secondaryText,
    fontWeight: 600,
    fontSize: sm ? '0.725rem' : md ? '0.875rem' : '1.125rem',
    marginRight: '10px'
  },
  deleteIconStyle: {
    fontSize: res ? '0.725rem' : md ? '0.875rem' : '1rem'
  }
};
