import { Box } from '@mui/material';
import { CollapsableData, Text } from '../../../ui-kits';
import { getTitles } from '../../../utils';
import { useEffect, useState } from 'react';

import {
  getDepartmentRequest,
  getDesignationRequest,
  getStateRequest,
  setClearProcessPages,
  setClearProcessStateDistTalVill
} from '../../../services/redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import { getStateNextPage, getStates } from '../../../services/redux/selectors/manageProccess';
import {
  getCurrEmployeeData,
  getDepartmentsList,
  getDesignationsList
} from '../../../services/redux/selectors';

import { defaultValues } from '../../../constants';
import {
  EmployeeAccountDetails,
  EmployeeDocuments,
  EmployeeEmergencyContact,
  EmployeeEmploymentDetails,
  EmployeePersonalDetails,
  EmployeePreviousEmployment,
  FamilyDetails,
  PermissionDetails
} from '../../../components/HOC/employee';
import { UIBackButton } from '../../../ui-kits/back-button';
import { EducationalDetails } from '../../../components/HOC/employee/educational-details';
import { EmployeeVehicleDetails } from '../../../components/HOC/employee/vehicle-details';
import { color } from '../../../theme';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;

const styles = {
  container: {
    width: '100%',
    padding: res ? '2%' : ' 2% 6%',
    boxSizing: 'border-box',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground,
    marginTop: res ? '10px' : '20px'
  },
  subCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  backBtnCont: {
    width: '100%',
    display: 'flex',
    mb: 1,
    position: 'relative'
  },
  backBtn: {
    position: 'absolute',
    top: 10,
    left: md ? -42 : -55
  }
};

export const AddEditEmployee = ({ handleBackButton, currRender, isReadOnly, successMsg }) => {
  const dispatch = useDispatch();

  const nextPage = useSelector(getStateNextPage);
  const departments = useSelector(getDepartmentsList);
  const designations = useSelector(getDesignationsList);

  const [open, setOpen] = useState(1);
  const [addAnother, setAddAnother] = useState(false);
  const [isEdit, setIsEdit] = useState({
    personalDetails: false,
    emergency: false,
    accountDetails: false,
    documents: false,
    previousEmployment: false,
    employment: false,
    permission: false,
    family: false,
    education: false,
    vehicle: false
  });

  const { add, edit } = defaultValues.actionType;

  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    dispatch(getStateRequest({ page: 1 }));
    dispatch(getDepartmentRequest({ master_id: defaultValues.masterListType.department }));
    dispatch(getDesignationRequest({ master_id: defaultValues.masterListType.designation }));
  }, []);

  const states = useSelector(getStates);
  const employeeData = useSelector(getCurrEmployeeData);

  useEffect(() => {
    let temp = [...stateList, ...states];

    const uniqueStates = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });
    setStateList((uniqueStates.length > 0 && uniqueStates) || []);
  }, [states]);

  useEffect(() => {
    if (nextPage !== null) dispatch(getStateRequest({ page: nextPage }));
  }, [nextPage]);

  useEffect(() => {
    if (['S-10047', 'S-10046'].includes(successMsg)) {
      if (!addAnother) {
        setOpen(open + 1);
      }
      setAddAnother(false);
    }
  }, [successMsg]);

  const handleOpen = (val) => {
    if ([1, 2, 5, 7].includes(val)) {
      setStateList([]);
      dispatch(getStateRequest({ page: 1 }));
    }
    setOpen(val);
  };

  const handleEditBtnClick = (data, val, isEditMode) => {
    if ([1, 2, 5, 7].includes(val) && isEditMode) {
      setStateList([]);
      dispatch(getStateRequest({ page: 1 }));
    }
    setIsEdit(data);
    setOpen(val);
  };

  return (
    <Box sx={styles.container}>
      {!res && (
        <Box sx={styles.backBtnCont}>
          <UIBackButton onClick={handleBackButton} style={styles.backBtn} />

          <Text variant="boldH32Sec" style={{ mt: 1 }}>
            {currRender === add
              ? getTitles('T-10326')
              : currRender === edit && getTitles('T-10327')}
          </Text>
        </Box>
      )}
      <Box sx={styles.subCont}>
        <CollapsableData
          isReadOnly={isReadOnly}
          isOpen={open === 1}
          status={employeeData && employeeData.first_name}
          handleOpen={(val) => handleOpen(val ? 1 : null)}
          currRender={currRender}
          isEdit={isEdit.personalDetails}
          handleEditClick={(val) => handleEditBtnClick({ ...isEdit, personalDetails: val }, 1, val)}
          title={getTitles('T-10330')}>
          {
            <EmployeePersonalDetails
              states={stateList}
              employeeData={employeeData}
              editable={currRender === add || isEdit.personalDetails}
              open={open === 1}
            />
          }
        </CollapsableData>

        <CollapsableData
          isReadOnly={isReadOnly}
          title={getTitles('T-10331')}
          isOpen={open === 2}
          disabled={!employeeData || !employeeData._id}
          currRender={currRender}
          isEdit={isEdit.emergency}
          handleEditClick={(val) => handleEditBtnClick({ ...isEdit, emergency: val }, 2, val)}
          status={employeeData && employeeData.emergency && employeeData.emergency.length > 0}
          handleOpen={(val) => employeeData && employeeData._id && handleOpen(val ? 2 : null)}>
          {
            <EmployeeEmergencyContact
              handleContinue={() => setAddAnother(true)}
              states={stateList}
              employeeData={employeeData && employeeData.emergency ? employeeData.emergency : null}
              employeeId={employeeData && employeeData._id}
              editable={currRender === add || isEdit.emergency}
            />
          }
        </CollapsableData>

        <CollapsableData
          title={getTitles('T-10657')}
          isReadOnly={isReadOnly}
          isOpen={open === 3}
          disabled={!employeeData || !employeeData._id}
          currRender={currRender}
          status={
            employeeData &&
            employeeData.vehicle_details &&
            employeeData.vehicle_details.vehicle_number
          }
          isEdit={isEdit.vehicle}
          handleEditClick={(val) => handleEditBtnClick({ ...isEdit, vehicle: val }, 3, val)}
          handleOpen={(val) => employeeData && employeeData._id && handleOpen(val ? 3 : null)}>
          <EmployeeVehicleDetails
            employeeId={employeeData && employeeData._id}
            employeeData={
              employeeData && employeeData.vehicle_details ? employeeData.vehicle_details : null
            }
            editable={currRender === add || isEdit.vehicle}
          />
        </CollapsableData>

        <CollapsableData
          isReadOnly={isReadOnly}
          title={getTitles('T-10643')}
          disabled={!employeeData || !employeeData._id}
          isOpen={open === 4}
          currRender={currRender}
          isEdit={isEdit.education}
          handleEditClick={(val) => handleEditBtnClick({ ...isEdit, education: val }, 4, val)}
          status={
            employeeData &&
            employeeData.education_details &&
            employeeData.education_details.education &&
            employeeData.education_details.education.length > 0
          }
          handleOpen={(val) => employeeData && employeeData._id && handleOpen(val ? 4 : null)}>
          {
            <EducationalDetails
              handleContinue={() => setAddAnother(true)}
              employeeData={
                employeeData && employeeData.education_details
                  ? employeeData.education_details
                  : null
              }
              employeeId={employeeData && employeeData._id}
              editable={currRender === add || isEdit.education}
            />
          }
        </CollapsableData>

        <CollapsableData
          isReadOnly={isReadOnly}
          title={getTitles('T-10332')}
          isOpen={open === 5}
          disabled={!employeeData || !employeeData._id}
          status={employeeData && employeeData.sb_account}
          currRender={currRender}
          isEdit={isEdit.accountDetails}
          handleEditClick={(val) => handleEditBtnClick({ ...isEdit, accountDetails: val }, 5, val)}
          handleOpen={(val) => employeeData && employeeData._id && handleOpen(val ? 5 : null)}>
          {
            <EmployeeAccountDetails
              sbAccount={employeeData && employeeData.sb_account ? employeeData.sb_account : null}
              pfAccount={employeeData && employeeData.pf_account ? employeeData.pf_account : null}
              insurance={employeeData && employeeData.insurance ? employeeData.insurance : null}
              employeeId={employeeData && employeeData._id}
              states={stateList}
              editable={currRender === add || isEdit.accountDetails}
            />
          }
        </CollapsableData>

        <CollapsableData
          title={currRender === 2 ? getTitles('T-10333') : getTitles('T-10334')}
          isReadOnly={isReadOnly}
          isOpen={open === 6}
          disabled={!employeeData || !employeeData._id}
          status={employeeData && employeeData.upload_document && employeeData.upload_document._id}
          currRender={currRender}
          isEdit={isEdit.documents}
          handleEditClick={(val) => handleEditBtnClick({ ...isEdit, documents: val }, 6, val)}
          handleOpen={(val) => employeeData && employeeData._id && handleOpen(val ? 6 : null)}>
          <EmployeeDocuments
            employeeData={
              employeeData && employeeData.upload_document ? employeeData.upload_document : null
            }
            employeeId={employeeData && employeeData._id}
            editable={currRender === add || isEdit.documents}
          />
        </CollapsableData>

        <CollapsableData
          title={getTitles('T-10335')}
          isReadOnly={isReadOnly}
          isOpen={open === 7}
          disabled={!employeeData || !employeeData._id}
          status={
            employeeData &&
            employeeData.previous_employment &&
            employeeData.previous_employment.length > 0
          }
          currRender={currRender}
          isEdit={isEdit.previousEmployment}
          handleEditClick={(val) =>
            handleEditBtnClick({ ...isEdit, previousEmployment: val }, 7, val)
          }
          handleOpen={(val) => employeeData && employeeData._id && handleOpen(val ? 7 : null)}>
          <EmployeePreviousEmployment
            handleContinue={() => setAddAnother(true)}
            employeeData={
              employeeData &&
              employeeData.previous_employment &&
              employeeData.previous_employment.length > 0
                ? employeeData.previous_employment
                : null
            }
            states={stateList}
            employeeId={employeeData && employeeData._id}
            editable={currRender === add || isEdit.previousEmployment}
          />
        </CollapsableData>

        <CollapsableData
          title={getTitles('T-10336')}
          isReadOnly={isReadOnly}
          isOpen={open === 8}
          disabled={!employeeData || !employeeData._id}
          currRender={currRender}
          status={
            employeeData &&
            employeeData.employment_details &&
            employeeData.employment_details.department_id
          }
          isEdit={isEdit.employment}
          handleEditClick={(val) => handleEditBtnClick({ ...isEdit, employment: val }, 8, val)}
          handleOpen={(val) => employeeData && employeeData._id && handleOpen(val ? 8 : null)}>
          <EmployeeEmploymentDetails
            employeeId={employeeData && employeeData._id}
            employeeData={
              employeeData && employeeData.employment_details
                ? employeeData.employment_details
                : null
            }
            departments={departments}
            designations={designations}
            editable={currRender === add || isEdit.employment}
          />
        </CollapsableData>

        <CollapsableData
          title={getTitles('T-10447')}
          isReadOnly={isReadOnly}
          isOpen={open === 9}
          disabled={!employeeData || !employeeData._id}
          currRender={currRender}
          status={employeeData && employeeData.permissions && employeeData.permissions.length > 0}
          isEdit={isEdit.permission}
          handleEditClick={(val) => handleEditBtnClick({ ...isEdit, permission: val }, 9, val)}
          handleOpen={(val) => {
            employeeData && employeeData._id && handleOpen(val ? 9 : null);
            val && dispatch(setClearProcessStateDistTalVill());
            val && dispatch(setClearProcessPages());
            val &&
              dispatch(
                getStateRequest({
                  page: 1,
                  type: 1
                  // permission_id: defaultValues.employeeRol.employeeManager
                })
              );
          }}>
          <PermissionDetails
            handleContinue={() => setAddAnother(true)}
            employeeId={employeeData && employeeData._id}
            permissionData={
              employeeData && employeeData.permissions && employeeData.permissions.length > 0
                ? employeeData.permissions
                : null
            }
            additional={
              employeeData && employeeData.additional_details
                ? employeeData.additional_details
                : null
            }
            editable={currRender === add || isEdit.permission}
            states={stateList}
            setStateList={setStateList}
          />
        </CollapsableData>

        <CollapsableData
          title={getTitles('T-10448')}
          isReadOnly={isReadOnly}
          isOpen={open === 10}
          disabled={!employeeData || !employeeData._id}
          currRender={currRender}
          status={
            employeeData &&
            employeeData.family_member_details &&
            employeeData.family_member_details.length > 0
          }
          isEdit={isEdit.family}
          handleEditClick={(val) => handleEditBtnClick({ ...isEdit, family: val }, 10, val)}
          handleOpen={(val) => employeeData && employeeData._id && handleOpen(val ? 10 : null)}>
          <FamilyDetails
            handleContinue={() => setAddAnother(true)}
            employeeId={employeeData && employeeData._id}
            employeeData={
              employeeData &&
              employeeData.family_member_details &&
              employeeData.family_member_details.length > 0
                ? employeeData.family_member_details
                : null
            }
            editable={currRender === add || isEdit.family}
          />
        </CollapsableData>
      </Box>
    </Box>
  );
};
