import { Box, Grid, Input } from '@mui/material';
import { useEffect, useState } from 'react';
import { color } from '../../theme/color';
import { defaultValues, regExp } from '../../constants';
import { getTitles } from '../../utils';
import { UIButton } from '../button';
import searchIconImg from '../../assets/icons/searchIcon.png';

export const SearchBarWithButton = ({
  searchedVlaue,
  placeHolder = getTitles('T-10077'),
  style,
  onRefresh,
  buttonClicked,
  allowOnlyAlphabets = false,
  allowOnlyNumbers = false,
  disableButton,
  preSelectedValue
}) => {
  const [search, setSearch] = useState('');
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (changed) {
      const getData = setTimeout(() => {
        searchedVlaue && searchedVlaue(search);
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [search]);

  useEffect(() => {
    if (onRefresh && Object.keys(onRefresh).length === 0) {
      setSearch('');
    }
  }, [onRefresh]);

  useEffect(() => {
    setSearch(preSelectedValue ? preSelectedValue : '');
  }, [preSelectedValue]);

  const defaultStyles = {
    gridBox: {
      margin: '20px 0px 0px 0px',
      justifyContent: 'space-between'
    },
    itemBox: {
      paddingLeft: '0px',
      paddingTop: '0px'
    },
    searchBarContainer: {
      display: 'flex',
      border: `1px solid ${color.primaryBorder}`,
      width: '98%',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: defaultValues.isResponsive ? '5px' : '8px',
      padding: !defaultValues.isResponsive ? '0px 16px' : '0px 8px',
      boxSizing: 'border-box',
      backgroundColor: color.primaryBackground,
      height: defaultValues.isResponsive ? '32px' : '60px'
    },
    searchBar: {
      width: '100%',
      height: '100%',
      fontSize: defaultValues.isResponsive ? '16px' : '20px'
    },
    searchButton: {
      width: defaultValues.isResponsive ? '32px' : '187px',
      height: defaultValues.isResponsive ? '32px' : '60px',
      minWidth: '0px',
      justifyContent: defaultValues.isResponsive ? 'center' : 'start',
      borderRadius: defaultValues.isResponsive ? '4px' : '7px',
      padding: defaultValues.isResponsive ? '4px' : '8px 11px'
    },
    searchIcon: {
      width: defaultValues.isResponsive ? '20px' : '28px',
      height: defaultValues.isResponsive ? '20px' : '28px',
      ml: 1
    }
  };

  const Styles = {
    ...defaultStyles,
    ...style
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const trimmedValue = inputValue.trimStart();

    if (trimmedValue !== inputValue) {
      return;
    }

    if (allowOnlyAlphabets) {
      if (inputValue === '' || regExp.alphabetRegExp.test(inputValue)) {
        setChanged(true);
        setSearch(inputValue);
      }
    } else if (allowOnlyNumbers) {
      if (inputValue === '' || regExp.onlyNumbers.test(inputValue)) {
        setChanged(true);
        setSearch(inputValue);
      }
    } else {
      setChanged(true);
      setSearch(inputValue);
    }
  };

  return (
    <Grid container sx={Styles.gridBox}>
      <Grid item xs={!defaultValues.isResponsive ? 10 : 11} sx={Styles.itemBox}>
        <Box sx={Styles.searchBarContainer}>
          <Input
            type="search"
            value={search}
            disableUnderline
            placeholder={placeHolder}
            sx={Styles.searchBar}
            onChange={handleInputChange}
          />
        </Box>
      </Grid>
      <Grid item xs={!defaultValues.isResponsive ? 2 : 1}>
        <UIButton
          size={defaultValues.isResponsive ? 'small' : 'large'}
          title={defaultValues.isResponsive ? '' : getTitles('T-10077')}
          style={Styles.searchButton}
          startIcon={
            <Box component="img" sx={Styles.searchIcon} alt="Search_Icon" src={searchIconImg} />
          }
          onClick={buttonClicked}
          disabled={disableButton}
        />
      </Grid>
    </Grid>
  );
};
