import { Box } from '@mui/material';
import {
  DatePickers,
  UIPhoneInputTitle,
  UISelect,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { useFormik } from 'formik';
import { employeePersonalSchema, getTitles } from '../../../../utils';
import { useEffect, useState } from 'react';
import { ModalButtons } from '../../../../components';
import { defaultValues } from '../../../../constants';
import { globalStyles } from '../../../../theme';
import { useDispatch } from 'react-redux';
import { addEmployeeRequest, updateEmployeeRequest } from '../../../../services/redux/slices';
import dayjs from 'dayjs';
import { ViewPersonel } from './view-personel';
import { Address } from '../../../common/address';

const res = defaultValues.isResponsive;
const styles = {
  container: {
    width: '100%'
  },

  width: defaultValues.isResponsive ? '100%' : 'calc((100% - 16px)/3)',
  widthYears: defaultValues.isResponsive ? '100%' : 'calc((100% + 10px)/3)',

  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '10px'
  },
  phoneWidth: res ? '100%' : 'calc((100% - 20px)/3)',
  nameSub: {
    display: 'flex',
    gap: '10px'
  },
  genderSecSub: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `"dob gender"
    "marital national"
    "voter voter"
    "ration ration"
    `
      : `
    "dob gender marital"
    "national voter ration"
    `,
    gridTemplateColumns: res ? '1fr 1fr' : '1fr 1fr 1fr',
    gap: '10px'
  },
  namegridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `"first middle"
    "last none"
    `
      : `
    "first middle last"
    `,
    gridTemplateColumns: res ? 'calc(50% - 5px) calc(50% - 5px)' : '1fr 1fr 1fr',
    gap: '10px'
  },
  gridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `"country state"
    "district taluk"
    "village post"
    `
      : `
    "country state district"
    "taluk village post"
    `,
    gridTemplateColumns: res ? 'calc(50% - 5px) calc(50% - 5px)' : '1fr 1fr 1fr',
    gap: '10px'
  },
  gridCountry: {
    gridArea: 'country'
  },
  gridState: {
    gridArea: 'state'
  },
  gridDistrict: {
    gridArea: 'district'
  },
  gridTaluk: {
    gridArea: 'taluk'
  },
  gridVillage: {
    gridArea: 'village'
  },
  gridPostcode: {
    gridArea: 'post'
  },
  gridFirstName: {
    gridArea: 'first'
  },
  gridMiddlename: {
    gridArea: 'middle'
  },
  gridLastname: {
    gridArea: 'last'
  },
  gridDob: {
    gridArea: 'dob'
  },
  gridGender: {
    gridArea: 'gender'
  },
  gridMaritalstatus: {
    gridArea: 'marital'
  },
  gridNationality: {
    gridArea: 'national'
  },
  gridVoter: {
    gridArea: 'voter'
  },
  gridRation: {
    gridArea: 'ration'
  }
};

export const EmployeePersonalDetails = ({ states, employeeData, editable }) => {
  const dispatch = useDispatch();

  const [btnDisabled, setBtndisabled] = useState(true);

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldTouched,
    handleBlur,
    setFieldValue,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
      phone_number: '',
      secondary_phone_number: '',
      email_id: '',
      secondary_email_id: '',
      date_of_birth: null,
      gender: '',
      nationality: '',
      marital_status: '',
      aadhaar_number: '',
      driving_license_number: '',
      pan_card_number: '',
      address_line_one: '',
      address_line_two: '',
      country_id: '',
      state_id: {
        _id: ''
      },
      district_id: {
        _id: ''
      },
      taluka_id: {
        _id: ''
      },
      village_id: {
        _id: ''
      },
      post_code: '',
      voter_id: '',
      ration_card_number: '',
      highest_qual: '',
      years_experience: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: employeePersonalSchema
  });

  useEffect(() => {
    if (employeeData) {
      let newValues = {
        ...employeeData,
        secondary_email_id: employeeData.secondary_email_id || '',
        email_id: employeeData.email_id || ''
      };

      if (employeeData.date_of_birth) {
        newValues = { ...newValues, date_of_birth: dayjs(employeeData.date_of_birth) };
      }

      setValues(newValues);
    }
  }, [employeeData, setValues, states]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = { ...values };
    if (!employeeData) {
      dispatch(addEmployeeRequest(payload));
    } else {
      payload = {
        ...payload,
        _id: employeeData._id,
        date_of_birth: new Date(values.date_of_birth),
        marital_status: parseInt(values.marital_status),
        gender: parseInt(values.gender),
        state_id: values.state_id?._id,
        district_id: values.district_id?._id,
        taluka_id: values.taluka_id?._id,
        village_id: values.village_id?._id
      };
      dispatch(updateEmployeeRequest(payload));
    }
  };

  if (editable) {
    return (
      <Box sx={styles.container}>
        <Box sx={styles.namegridCont}>
          <UITextInputWithTitle
            name="first_name"
            title={getTitles('T-10041')}
            placeHolder={getTitles('T-10047')}
            error={touched.first_name && errors.first_name}
            value={values.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            containerStyles={styles.gridFirstName}
            maxLength={10}
            allowOnlyAlphabets={true}
          />
          <UITextInputWithTitle
            name="middle_name"
            title={getTitles('T-10337')}
            placeHolder={getTitles('T-10338')}
            error={touched.middle_name && errors.middle_name}
            value={values.middle_name}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.gridMiddlename}
            maxLength={10}
            allowOnlyAlphabets={true}
          />

          <UITextInputWithTitle
            name="last_name"
            title={getTitles('T-10042')}
            placeHolder={getTitles('T-10048')}
            value={values.last_name}
            error={touched.last_name && errors.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            containerStyles={styles.gridLastname}
            maxLength={10}
            allowOnlyAlphabets={true}
          />
        </Box>
        <Box sx={styles.nameCont}>
          <UIPhoneInputTitle
            name="phone_number"
            phoneValue={values.phone_number}
            onChange={(val) => {
              setFieldValue('phone_number', val);
              setBtndisabled(val ? false : true);
            }}
            handleError={(isError) => setBtndisabled(isError)}
            required={true}
            width={styles.phoneWidth}
            onBlur={handleBlur}
          />

          <UIPhoneInputTitle
            title={getTitles('T-10341')}
            phoneValue={values.secondary_phone_number}
            onChange={(val) => {
              setFieldValue('secondary_phone_number', val);
              setBtndisabled(val ? false : true);
            }}
            handleError={(isError) => setBtndisabled(isError)}
            width={styles.phoneWidth}
          />
        </Box>

        <Box sx={styles.nameCont}>
          <UITextInputWithTitle
            name="email_id"
            title={getTitles('T-10043')}
            placeHolder={getTitles('T-10049')}
            error={touched.email_id && errors.email_id}
            value={values.email_id}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <UITextInputWithTitle
            name="secondary_email_id"
            title={getTitles('T-10342')}
            placeHolder={getTitles('T-10343')}
            error={touched.secondary_email_id && errors.secondary_email_id}
            value={values.secondary_email_id}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>

        <Box sx={styles.genderSecSub}>
          <DatePickers
            dateValue={values.date_of_birth}
            onDateChange={(val) => handleChange({ target: { value: val, name: 'date_of_birth' } })}
            title={getTitles('T-10344')}
            required={true}
            error={touched.date_of_birth && errors.date_of_birth}
            containerStyles={styles.gridDob}
            disableFuture={true}
            onOpen={() => setFieldTouched('date_of_birth', true)}
          />
          <UISelect
            name="gender"
            value={values.gender ? values.gender : ''}
            title={getTitles('T-10351')}
            options={defaultValues.genderOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            containerStyles={styles.gridGender}
          />

          <UISelect
            name="marital_status"
            value={values.marital_status ? values.marital_status : ''}
            title={getTitles('T-10352')}
            options={defaultValues.maritalStatus}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            containerStyles={styles.gridMaritalstatus}
          />

          <UISelect
            name="nationality"
            value={values.nationality}
            title={getTitles('T-10356')}
            options={defaultValues.nationalityOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            error={touched.nationality && errors.nationality}
            required={true}
            containerStyles={styles.gridNationality}
          />

          <UITextInputWithTitle
            name="voter_id"
            title={getTitles('T-10436')}
            placeHolder={getTitles('T-10360')}
            value={values.voter_id}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.gridVoter}
          />

          <UITextInputWithTitle
            name="ration_card_number"
            title={getTitles('T-10437')}
            placeHolder={getTitles('T-10360')}
            value={values.ration_card_number}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={styles.gridRation}
          />
        </Box>

        <Box sx={styles.nameCont}>
          <UITextInputWithTitle
            name="aadhaar_number"
            title={getTitles('T-10353')}
            placeHolder={getTitles('T-10360')}
            value={values.aadhaar_number}
            onChange={handleChange}
            onBlur={handleBlur}
            allowOnlyNumbers={true}
            required={true}
            error={touched.aadhaar_number && errors.aadhaar_number}
            maxLength={12}
          />
          <UITextInputWithTitle
            name="driving_license_number"
            title={getTitles('T-10354')}
            placeHolder={getTitles('T-10360')}
            value={values.driving_license_number}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <UITextInputWithTitle
            name="pan_card_number"
            title={getTitles('T-10355')}
            placeHolder={getTitles('T-10360')}
            value={values.pan_card_number}
            error={touched.pan_card_number && errors.pan_card_number}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={10}
          />
        </Box>

        <Box sx={styles.nameCont}>
          <UITextInputWithTitle
            name="highest_qual"
            title={getTitles('T-10374')}
            placeHolder={getTitles('T-10376')}
            error={touched.highest_qual && errors.highest_qual}
            value={values.highest_qual}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
          />
          <UITextInputWithTitle
            name="years_experience"
            title={getTitles('T-10375')}
            placeHolder={getTitles('T-10377')}
            error={touched.years_experience && errors.years_experience}
            value={values.years_experience}
            onChange={handleChange}
            onBlur={handleBlur}
            allowOnlyNumbers={true}
            titleStyle={globalStyles.text}
            width={styles.width}
          />
        </Box>

        <Box sx={styles.nameCont}>
          <UITextInputWithTitle
            name="address_line_one"
            title={getTitles('T-10357')}
            placeHolder={getTitles('T-10361')}
            value={values.address_line_one}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            error={touched.address_line_one && errors.address_line_one}
          />
          <UITextInputWithTitle
            name="address_line_two"
            title={getTitles('T-10358')}
            placeHolder={getTitles('T-10362')}
            value={values.address_line_two}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>

        <Address
          actionType={defaultValues.actionType.edit}
          objects={values}
          divider={false}
          type={'location'}
          showPostCode={true}
          isCountryRequired={true}
          isStateRequired={true}
          isDistrictRequired={true}
          isVillageRequired={true}
          isTalukaRequired={true}
          isPostCodeRequired={true}
          onCountrySelected={(e) =>
            handleChange({ target: { name: 'country_id', value: e.target.value } })
          }
          onCountryBlurred={handleBlur('country_id')}
          countryCodeError={touched.country_id && errors.country_id}
          onStateSelected={(val) => {
            handleChange({ target: { name: 'state_id', value: val ? val : { _id: '' } } });
          }}
          onStateBlurred={handleBlur('state_id')}
          stateCodeError={touched.state_id && errors.state_id?._id}
          onDistrictSelected={(val) =>
            handleChange({
              target: {
                name: 'district_id',
                value: val ? val : { _id: '' }
              }
            })
          }
          onDistrictBlurred={handleBlur('district_id')}
          districtCodeError={touched.district_id && errors.district_id?._id}
          onTalukaSelected={(val) =>
            handleChange({ target: { name: 'taluka_id', value: val ? val : { _id: '' } } })
          }
          onTalukaBlurred={handleBlur('taluka_id')}
          talukaCodeError={touched.taluka_id && errors.taluka_id?._id}
          onVillageSelected={(val) =>
            handleChange({ target: { name: 'village_id', value: val ? val : { _id: '' } } })
          }
          villageCodeError={touched.village_id && errors.village_id?._id}
          onVillageBlurred={handleBlur('village_id')}
          onPostCodeBlurred={handleBlur('post_code')}
          postCodeError={touched.post_code && errors.post_code}
          onPostCodeChange={(val) => {
            handleChange({ target: { name: 'post_code', value: val.target.value } });
          }}
        />

        <ModalButtons
          onPrimaryBtnClick={handleSubmit}
          primaryBtnTitle={getTitles('T-10339')}
          disabled={!isValid || !dirty || btnDisabled}
          containerStyle={globalStyles.mt(3)}
        />
      </Box>
    );
  }
  return (
    <Box sx={styles.container}>
      <ViewPersonel employeeData={employeeData} stateList={states} />
    </Box>
  );
};
