import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const xsm = defaultValues.isMdSmall;
const res = defaultValues.isResponsive;
const sm = defaultValues.isMobile;
const md = defaultValues.isMedium;
export const styles = {
  mainCont: {
    p: xsm ? 1 : sm ? 0 : 2,
    boxSizing: 'border-box',
    marginTop: 2,
    position: 'relative',
    height: 'max-content'
  },
  titleTxt: {
    pl: 3
  },
  subCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    px: !res && 3,
    mt: !res && 3
  },
  collapseHeader: {
    backgroundColor: `${color.tableHeading}`,
    padding: res ? '8px' : '10px 20px 10px 20px',
    borderRadius: res ? '0px' : '5px'
  },
  titleCont: {
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    alignItems: res ? 'start' : 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  titleContSec: {
    width: 'calc(100%/3)',
    display: 'flex',
    alignItems: 'center'
  },
  titleBold: {
    fontWeight: 600
  },
  collapseChildCont: {
    border: '0px',
    padding: res ? '16px' : md ? '10px 2%' : '10px 0px',
    display: 'flex',
    flexDirection: 'column'
  },
  statusCont: {
    display: 'flex',
    justifyContent: 'space-between',
    px: 2,
    mt: 1,
    flexWrap: 'wrap',
    gap: '10px'
  },
  statusBox: {
    textAlign: 'center',
    color: `${color.tertiaryText}`
  },
  statusBoxSec: {
    display: 'flex',
    justifyContent: 'center',
    mr: 2,
    borderRadius: '4px'
  },
  statusTxt: (status) => ({
    backgroundColor:
      (status === 1 && `${color.primaryText}`) ||
      ([2, 4].includes(status) && `${color.successAction}`) ||
      ([3, 5].includes(status) && `${color.primary}`),
    color: `${color.tertiaryText}`,
    px: res ? 1 : 5,
    borderRadius: '4px',
    fontSize: res ? '1rem' : '1.375rem'
  }),
  productCardCont: {
    mt: res ? 1 : 3,
    mb: res ? 0.5 : 1,
    p: sm ? 1 : res ? 2 : 3,
    borderRadius: res ? '10px' : '8px',
    boxShadow: res && '0 4px 12px rgba(0, 0, 0, 0.08)'
  },
  paginationBox: {
    my: 2,
    display: 'flex',
    justifyContent: 'center'
  },
  orderIdCont: {
    display: 'flex',
    gap: '10px'
  },
  orderCardCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    flexDirection: 'column'
  }
};
