import { Box } from '@mui/material';
import { Screen, Text, UILayout } from '../../../ui-kits';
import { getFormatDate, getTitles, resetMessages } from '../../../utils';
import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCallCenterCattleCurrPage,
  getCallCenterCattleTotalPage,
  getCallCenterErrorMsg,
  getCallcenterLoading,
  getCallCenterSuccessMsg,
  getCattleTaskCallCenter
} from '../../../services/redux/selectors';
import { UIBackButton } from '../../../ui-kits/back-button';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCattleTaskRequest, resetCallcenterMsg } from '../../../services/redux/slices';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getErrorMessage, getSuccessMessage } from '../../../constants';
import { globalStyles } from '../../../theme';

export const CattleTaskDetailsCallCenter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(getCallcenterLoading);
  const taskList = useSelector(getCattleTaskCallCenter);
  const currentPage = useSelector(getCallCenterCattleCurrPage);
  const totalPage = useSelector(getCallCenterCattleTotalPage);
  const success = useSelector(getCallCenterSuccessMsg);
  const error = useSelector(getCallCenterErrorMsg);
  const location = useLocation();

  useEffect(() => {
    dispatch(getCattleTaskRequest({ cattle_id: location?.state?.cattle_id, page: 1 }));
  }, []);

  useEffect(() => {
    if (success) {
      toast.success(getSuccessMessage(success));
    }

    if (error) {
      toast.error(getErrorMessage(error));
    }

    resetMessages(() => dispatch(resetCallcenterMsg()));
  }, [success, error]);

  const handlePagination = (val) => {
    dispatch(getCattleTaskRequest({ cattle_id: location.state.cattle_id, page: val }));
  };

  const TaskDetailsModel = (item, idx) => {
    return [
      {
        title: getTitles('T-11407'),
        values: `${idx + 1}`,
        valueTextStyle: { ml: 3 }
      },
      {
        title: getTitles('T-11264'),
        values: item.task_name
      },

      {
        title: getTitles('T-11248'),
        values: item.task_type
      },

      {
        title: getTitles('T-11251'),
        values: item.due_date && getFormatDate(item.due_date)
      }
    ];
  };

  const taskData = taskList?.length > 0 && taskList.map((item, idx) => TaskDetailsModel(item, idx));
  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-11235')}>
      <Box sx={styles.container}>
        <Box sx={styles.backCont}>
          <UIBackButton onClick={() => navigate(-1)} />
          <Text sx={globalStyles.underLine} onClick={() => navigate(-1)}>
            {location?.state?.name}
          </Text>
        </Box>
        <Text style={styles.text} variant="BoldH24">
          {getTitles('T-11422')}
        </Text>
        <UILayout
          tableItems={taskData}
          containerStyles={styles.layoutCont}
          showNoData={!taskData.length > 0}
          currPage={currentPage}
          pageCount={totalPage}
          handlePagination={handlePagination}
          showPagination={taskData.length > 0 && totalPage > 1}
          subContStyles={styles.layoutSubCont}
          optionStyles={styles.optionStyles}
          message={getErrorMessage('E-10194')}
        />
      </Box>
    </Screen>
  );
};
