import { Box } from '@mui/material';
import { color } from '../../../../theme';
import { Text } from '../../../../ui-kits';

export const TableCard = ({
  isHeader = false,
  data,
  rowValueContainerStyle,
  valuesSubStyle,
  headerSubStyle,
  headerContStyle,
  showHeader,
  headerTextStyle,
  customData,
  customEndData
}) => {
  const styles = {
    container: (isHead, styles) => ({
      width: '100%',
      border: `1px solid ${color.primaryBorder}`,
      backgroundColor: isHead ? color.palette.paleYellow : color.primaryBackground,
      borderRadius: '5px',
      display: 'flex',
      boxSizing: 'border-box',
      ...styles
    }),
    subBox: (item, style) => ({
      width: item.columnWidth || `calc(100%/${data.length})`,
      flex: !item.columnWidth ? 1 : 'none',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      padding: '8px',
      boxSizing: 'border-box',
      ...style
    }),
    text: (isHead, style) => ({
      textOverflow: 'ellipsis',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontWeight: isHead ? 600 : 400,
      ...style
    })
  };

  return (
    <>
      {isHeader && showHeader && (
        <Box sx={styles.container(true, headerContStyle)}>
          {Object.values(data ? data : {}).map(
            (item, index) =>
              item && (
                <Box key={index} sx={styles.subBox(item, headerSubStyle)}>
                  <Text
                    variant="D22"
                    style={styles.text(true, item.headerTextStyle || headerTextStyle)}>
                    {item.title}
                  </Text>
                </Box>
              )
          )}
        </Box>
      )}

      {customData}
      <Box sx={styles.container(false, rowValueContainerStyle)}>
        {Object.values(data ? data : {}).map(
          (item, index) =>
            item && (
              <Box key={index} sx={styles.subBox(item, valuesSubStyle)}>
                {typeof item.values === 'string' ? (
                  <Text variant="D22" style={styles.text(false, item.valueTextStyle)}>
                    {item.values}
                  </Text>
                ) : (
                  item.values
                )}
              </Box>
            )
        )}
      </Box>
      {customEndData}
    </>
  );
};
