import { useDispatch, useSelector } from 'react-redux';
import { defaultValues, getErrorMessage } from '../../../constants';
import { Screen, Text, UILayout, UISelect, ViewData } from '../../../ui-kits';
import { UIBackButton } from '../../../ui-kits/back-button';
import { getFormatDate, getTitles, resetMessages } from '../../../utils';
import { styles } from './style';
import {
  getEcomOrderDetailsRequest,
  getStoreRequest,
  resetECommerceMsg,
  setManageOrderActionType,
  updateDetailsPg,
  updateEcomOrderDetailsRequest
} from '../../../services/redux/slices';
import { UIContainer } from '../../../ui-kits/container';
import { Box } from '@mui/material';
import { getStore } from '../../../services/redux/selectors/manageProccess';
import { useEffect, useState } from 'react';
import {
  getDetailsPg,
  getEcommerceLoading,
  getEcommerceSuccessMsg,
  getEcomOrderInfo
} from '../../../services/redux/selectors';
import { globalStyles } from '../../../theme';
import { OrderDetailsModel } from '../../../components';

export const EcomOrderDetails = ({ order_Id, setOrder_Id }) => {
  const dispatch = useDispatch();
  const res = defaultValues.isResponsive;
  const mdSm = defaultValues.isMdSmall;

  const stores = useSelector(getStore);
  const detailsPg = useSelector(getDetailsPg);
  const isLoading = useSelector(getEcommerceLoading);
  const success = useSelector(getEcommerceSuccessMsg);
  const orderInfo = useSelector(getEcomOrderInfo);

  const [storeList, setStoreList] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(
      getStoreRequest({
        filter: JSON.stringify([{ is_active: true }])
      })
    );
  }, []);

  useEffect(() => {
    stores && setStoreList(stores);
  }, [stores]);

  useEffect(() => {
    !detailsPg && handleBackButton();
  }, [detailsPg]);

  useEffect(() => {
    order_Id && dispatch(getEcomOrderDetailsRequest({ _id: order_Id }));
  }, [order_Id]);

  useEffect(() => {
    if (orderInfo) {
      setOrderDetails(orderInfo);
    }
  }, [orderInfo]);

  useEffect(() => {
    if (success === 'S-10137' && order_Id) {
      dispatch(getEcomOrderDetailsRequest({ _id: order_Id }));
    }

    resetMessages(() => dispatch(resetECommerceMsg()));
  }, [success]);

  const handleClose = () => {
    setOpen(false);
  };

  const { products } = orderDetails;

  let leftBoxProperties = [
    { name: getTitles('T-11683'), key: 'order_id' },
    { name: getTitles('T-11410'), key: 'full_name' },
    { name: getTitles('T-11688'), key: 'phone_number' }
  ];

  let leftBoxValues = {
    order_id: orderDetails?.order_id || ' - ',
    full_name: orderDetails?.order_by?.full_name || ' - ',
    phone_number: orderDetails?.order_by?.phone_number || ' - '
  };

  let rightBoxProperties = [
    { name: getTitles('T-11674'), key: 'ordered_date' },
    { name: getTitles('T-11682'), key: 'status' }
  ];

  let rightBoxValues = {
    ordered_date: getFormatDate(orderDetails?.ordered_date) || ' - ',
    status: orderDetails?.status?.name || defaultValues.orderStatus.Pending
  };
  let ship_address = orderDetails?.order_by?.shipping_address;

  if (!res) {
    leftBoxProperties.push({ name: getTitles('T-11660'), key: 'shipping_address' });
    leftBoxValues.shipping_address = ship_address
      ? [
          ship_address?.address_line_one,
          ship_address?.address_line_two,
          ship_address?.country_id,
          ship_address?.state_id,
          ship_address?.district_id,
          ship_address?.taluka_id,
          ship_address?.village_id,
          ship_address?.post_code
        ]
          .filter((elem) => ![null, undefined, ''].includes(elem))
          ?.join(', ')
      : ' - ';
  }

  let resMiddleOrderProperties = [
    { name: getTitles('T-11660'), key: 'shipping_address' },
    { name: getTitles('T-11690'), key: 'payment_method' },
    { name: getTitles('T-11692'), key: 'payment_status' }
  ];

  let resMiddleOrderValues = {
    shipping_address: ship_address
      ? [
          ship_address?.address_line_one,
          ship_address?.address_line_two,
          ship_address?.country_id,
          ship_address?.state_id,
          ship_address?.district_id,
          ship_address?.taluka_id,
          ship_address?.village_id,
          ship_address?.post_code
        ]
          .filter((elem) => ![null, undefined, ''].includes(elem))
          ?.join(', ')
      : ' - ',
    payment_method: orderDetails?.payment_method?.name || ' - ',
    payment_status: orderDetails?.payment_status?.name || ' - '
  };

  const tableData = products?.map((item) => {
    return [
      {
        title: getTitles('T-11457'),
        values: <Text variant={res ? 'D18' : 'D22'}>{item?.product_id}</Text>,
        columnWidth: styles.col17
      },
      {
        title: getTitles('T-11437'),
        values: (
          <Text variant={res ? 'D18' : 'D22'} style={styles.textOverFlow}>
            {item?.name || ' - '}
          </Text>
        ),
        columnWidth: styles.col22
      },
      {
        title: getTitles('T-11501'),
        values: (
          <img
            src={item?.product_image && item?.product_image[0]}
            style={styles.productImg}
            alt="product_img"
          />
        ),
        columnWidth: styles.col20
      },
      {
        title: getTitles('T-10659'),
        values: (
          <Text
            variant="D22"
            style={globalStyles.title}
            onClick={() => (orderDetails?.payment_status.id !== 1 ? setOpen(item) : null)}>
            {getTitles('T-10444')}
          </Text>
        ),
        columnWidth: styles.col15
      },

      {
        title: getTitles('T-11733'),
        values: (
          <UISelect
            containerStyles={styles.statusSelectCont}
            inputStyle={styles.statusSelectInput}
            value={item?.delivery_status?.id || defaultValues.orderStatus.Pending}
            options={defaultValues.deliveryStatusSelectOptions}
            onChange={(e) => {
              if (item.assigned_store?._id) {
                dispatch(
                  updateEcomOrderDetailsRequest({
                    _id: orderDetails?._id,
                    product_id: item._id,
                    delivery_status: e.target.value,
                    assigned_store: item.assigned_store?._id
                  })
                );
              }
            }}
            readOnly={!item.assigned_store?._id}
          />
        ),
        columnWidth: styles.col20
      },

      {
        title: getTitles('T-11022'),
        values: <Text variant={res ? 'D18' : 'D22'}>{item?.quantity}</Text>,
        columnWidth: styles.col12
      },
      {
        title: getTitles('T-10180'),
        values: (
          <Text variant={res ? 'D18' : 'D22'} style={styles.textOverFlow}>
            {item?.material_group?.name}
          </Text>
        ),
        columnWidth: styles.col30
      },
      {
        title: getTitles('T-11523'),
        values: <Text variant={res ? 'D18' : 'D22'}>{item?.price}</Text>,
        columnWidth: styles.col17
      },
      {
        title: getTitles('T-11693'),
        values: <Text variant={res ? 'D18' : 'D22'}>{item?.total_amount}</Text>,
        columnWidth: styles.col15
      }
    ];
  });

  const handleBackButton = () => {
    dispatch(setManageOrderActionType(null));
    setOrder_Id(null);
    dispatch(updateDetailsPg(false));
  };

  return (
    <Screen
      showSideBar={true}
      isLoading={isLoading}
      currentPage={`${getTitles('T-11683')} : ${orderDetails?.order_id}`}>
      {!res && (
        <UIBackButton
          backRoot={styles.backArrowBtn}
          buttonStyle={styles.btnIcon}
          onClick={handleBackButton}
        />
      )}
      <UIContainer styles={styles.mainCont}>
        <Box sx={styles.orderDetailsCont}>
          <Box sx={styles.leftBoxCont}>
            {leftBoxProperties?.map((item, idx) => {
              return (
                <ViewData
                  key={idx}
                  title={item.name}
                  containerStyle={styles.viewDataCont}
                  titleVariant={mdSm ? 'D16' : res ? 'D18' : 'BASE'}
                  subTitleVariant={mdSm ? 'D16' : res ? 'D18' : 'BASE'}
                  titleStyle={styles.leftTitleTxt}
                  subTitleCont={styles.leftValTxt}
                  subTitle={leftBoxValues && leftBoxValues[item?.key]}
                />
              );
            })}
          </Box>
          <Box sx={styles.rightBoxCont}>
            {rightBoxProperties?.map((item, idx) => {
              if (item) {
                return (
                  <ViewData
                    key={idx}
                    title={item.name}
                    containerStyle={styles.viewDataCont}
                    titleVariant={mdSm ? 'D16' : res ? 'D18' : 'BASE'}
                    subTitleVariant={mdSm ? 'D16' : res ? 'D18' : 'BASE'}
                    titleStyle={styles.rightTitleTxt}
                    subTitleCont={styles.rightValTxt}
                    subTitle={rightBoxValues && rightBoxValues[item?.key]}
                  />
                );
              }
            })}
          </Box>
        </Box>
        {res && (
          <Box sx={styles.midorderDetailsCont}>
            {resMiddleOrderProperties?.map((item, idx) => {
              return (
                <ViewData
                  key={idx}
                  title={item.name}
                  titleVariant={mdSm ? 'D16' : 'D18'}
                  subTitleVariant={mdSm ? 'D16' : 'D18'}
                  containerStyle={styles.viewDataCont}
                  titleStyle={styles.middleTitleTxt}
                  subTitleCont={styles.middleValTxt}
                  subTitle={resMiddleOrderValues && resMiddleOrderValues[item?.key]}
                />
              );
            })}
          </Box>
        )}
        <>
          <Box sx={styles.midCont}>
            {!res && (
              <>
                <Box sx={styles.midChildCont}>
                  <Text variant="BASE" style={styles.fontBold}>
                    {`${getTitles('T-11690')} : `}
                  </Text>
                  <Text variant="BASE">{resMiddleOrderValues?.payment_method}</Text>
                </Box>
                <Box sx={styles.midChildCont}>
                  <Text variant="BASE" style={styles.fontBold}>
                    {`${getTitles('T-11692')} : `}
                  </Text>
                  <Text variant="BASE">{resMiddleOrderValues?.payment_status}</Text>
                </Box>
              </>
            )}
          </Box>
        </>

        <UILayout
          tableTitle={getTitles('T-11497')}
          tableItems={tableData}
          showNoData={!products?.length > 0}
          containerStyles={styles.uiCont}
          subContStyles={styles.uiSubCont}
          tableHeaderTextStyle={styles.headertxtStyle}
          tableSuperSubCont={styles.tableSubCont(products?.length > 0)}
          showTableHeader={true}
          message={getErrorMessage('E-10207')}
        />
      </UIContainer>
      <OrderDetailsModel
        open={open}
        handleClose={handleClose}
        storeList={storeList}
        orderDetails={orderDetails}
      />
    </Screen>
  );
};
