import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { defaultValues } from '../../../../constants';
import { getTitles, handleMultiDelete } from '../../../../utils';
import {
  Text,
  UIChipButton,
  UIMultipleSelectCheckmarks,
  UIRadioButton,
  UITextInputWithTitle,
  UITitleWithContent
} from '../../../../ui-kits';
import { globalStyles } from '../../../../theme';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addVillageRequest,
  updateScrollToTop,
  updateVillageRequest
} from '../../../../services/redux/slices';
import { ModalButtons } from '../../../common/modal-buttons';
import { FormTitleCard } from '../../../common/cards';
import { getScroll } from '../../../../services/redux/selectors';
import { getSuccessMsg } from '../../../../services/redux/selectors/manageProccess';

const res = defaultValues.isResponsive;

const styles = {
  container: {
    width: '100%'
  },

  width: res ? '100%' : 'calc((100% - 10px)/3)',
  phone: {
    width: res ? '100%' : 'calc((100% + 5px)/3 * 2)',
    maxWidth: 'none'
  },
  radioSubCont: {
    width: defaultValues.isResponsive ? '30%' : 'calc((100% + 5px)/3)',
    display: 'flex',
    gap: '10px'
  },

  naturalCont: {
    display: 'flex',
    gap: '10px'
  }
};

export const VillageAICenterDetailsForm = ({
  aiCenterRunner,
  villageFullData,
  villageData,
  villageId,
  id,
  currRender,
  onNext,
  onPrev
}) => {
  const dispatch = useDispatch();
  const scroll = useSelector(getScroll);
  const successMsg = useSelector(getSuccessMsg);

  const [btnDisabled, setBtndisabled] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const { add, edit, view } = defaultValues.actionType;

  const { values, setValues, setFieldValue } = useFormik({
    initialValues: {
      has_ai_center: null,
      ai_center_runners: [],
      has_dairy_ai_center: null,
      ai_center_distance: '',
      number_of_bulls_for_natural_services: '',
      natural_centers_for_reproduction_runners: [],
      natural_service: {
        cow: '',
        buffalo: ''
      },
      artificial_insemination: {
        cow: '',
        buffalo: ''
      },
      big_hospital: null,
      big_hospital_distance: '',
      small_hospital: null,
      small_hospital_distance: '',
      center: null,
      center_distance: '',
      help_center: null,
      help_center_distance: '',
      veterinary_service_runner: []
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  const getFullObject = (arr1, arr2) => {
    if (arr1 && arr1.length > 0) {
      return arr2
        .filter((item) => arr1.includes(item.value))
        .map((ele) => ({ value: ele.value, title: ele.title }));
    }
    return [];
  };

  useEffect(() => {
    if (['S-10066', 'S-10055'].includes(successMsg) && isFinished) {
      onNext();
      dispatch(updateScrollToTop(!scroll));
    }
  }, [successMsg]);

  useEffect(() => {
    if (villageData) {
      let newValues = { ...villageData };
      if (
        villageData.natural_centers_for_reproduction_runners &&
        villageData.natural_centers_for_reproduction_runners.length > 0
      ) {
        newValues = {
          ...newValues,
          natural_centers_for_reproduction_runners: getFullObject(
            villageData.natural_centers_for_reproduction_runners,
            defaultValues.dairyRunners
          )
        };
      }

      if (
        villageData.veterinary_service_runner &&
        villageData.veterinary_service_runner.length > 0
      ) {
        newValues = {
          ...newValues,
          veterinary_service_runner: getFullObject(
            villageData.veterinary_service_runner,
            defaultValues.dairyRunners
          )
        };
      }

      if (villageData.ai_center_runners && villageData.ai_center_runners.length > 0) {
        newValues = {
          ...newValues,
          ai_center_runners: getFullObject(
            villageData.ai_center_runners,
            aiCenterRunner.map((item) => ({ value: item._id, title: item.name }))
          )
        };
      }
      setValues(newValues);
    }
  }, [villageData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (currRender !== view) {
      setFieldValue(name, value);
      setBtndisabled(false);
    }
  };

  const handleSubmit = () => {
    let payload = {
      step: defaultValues.villageRegisterSteps.aiCenterDetails,
      ai_center_details: {
        ...values,
        natural_centers_for_reproduction_runners:
          (values.natural_centers_for_reproduction_runners &&
            values.natural_centers_for_reproduction_runners.length > 0 &&
            values.natural_centers_for_reproduction_runners.map((item) => item.value)) ||
          [],
        veterinary_service_runner:
          (values.veterinary_service_runner &&
            values.veterinary_service_runner.length > 0 &&
            values.veterinary_service_runner.map((item) => item.value)) ||
          [],
        ai_center_runners:
          (values.ai_center_runners &&
            values.ai_center_runners.length > 0 &&
            values.ai_center_runners.map((item) => item.value)) ||
          []
      }
    };
    if (currRender === add) {
      payload = {
        ...payload,
        village_id: villageId,
        _id: id
      };
      dispatch(addVillageRequest(payload));
    }
    if (currRender === edit) {
      payload = {
        ...villageFullData,
        ...payload,
        village_id: villageId,
        step: defaultValues.villageRegisterSteps.aiCenterDetails,
        _id: id
      };
      dispatch(updateVillageRequest(payload));
    }
    if (currRender === view) {
      onNext();
      dispatch(updateScrollToTop(!scroll));
    }
    setIsFinished(true);
  };

  const handleBackButton = () => {
    onPrev();
    dispatch(updateScrollToTop(!scroll));
  };

  return (
    <Box sx={styles.container}>
      {defaultValues.isResponsive && <Text variant="BoldH22Sec">{getTitles('T-11037')}</Text>}
      <UITitleWithContent title={getTitles('T-10934')}>
        <Box sx={styles.radioSubCont}>
          <UIRadioButton
            title={getTitles('T-10705')}
            checked={values.has_ai_center}
            onClick={() => handleChange({ target: { name: 'has_ai_center', value: true } })}
          />
          <UIRadioButton
            title={getTitles('T-10706')}
            checked={values.has_ai_center === false}
            onClick={() => {
              handleChange({ target: { name: 'has_ai_center', value: false } });
              setFieldValue('ai_center_runners', []);
            }}
          />
        </Box>
      </UITitleWithContent>

      {values.has_ai_center && (
        <UIMultipleSelectCheckmarks
          name="ai_center_runners"
          title={getTitles('T-10935')}
          options={
            aiCenterRunner &&
            aiCenterRunner.length > 0 &&
            aiCenterRunner.map((item) => ({ value: item._id, title: item.name }))
          }
          onChange={(val) => handleChange({ target: { name: 'ai_center_runners', value: val } })}
          placeholder={getTitles('T-10350')}
          containerStyles={styles.phone}
          preSelectedValue={values.ai_center_runners}
          disabled={currRender === view}
          showTitles={false}
          showSelect={currRender !== view}
        />
      )}

      {values.ai_center_runners?.length > 0 &&
        values.ai_center_runners.map((item, idx) => (
          <UIChipButton
            key={idx}
            title={item.title}
            onDelete={() => {
              setFieldValue('ai_center_runners', handleMultiDelete(idx, values.ai_center_runners));
              setBtndisabled(false);
            }}
            showDelete={currRender !== view}
          />
        ))}

      <UITitleWithContent title={getTitles('T-10950')}>
        <Box sx={styles.radioSubCont}>
          <UIRadioButton
            title={getTitles('T-10705')}
            checked={values.has_dairy_ai_center}
            onClick={() => handleChange({ target: { name: 'has_dairy_ai_center', value: true } })}
          />
          <UIRadioButton
            title={getTitles('T-10706')}
            checked={values.has_dairy_ai_center === false}
            onClick={() => handleChange({ target: { name: 'has_dairy_ai_center', value: false } })}
          />
        </Box>
      </UITitleWithContent>

      <UITextInputWithTitle
        name="ai_center_distance"
        title={getTitles('T-10936')}
        placeHolder={getTitles('T-10952')}
        value={values.ai_center_distance}
        onChange={handleChange}
        containerStyles={styles.phone}
        allowOnlyNumbers={true}
        disabled={currRender === view}
      />

      <UITextInputWithTitle
        name="number_of_bulls_for_natural_services"
        title={getTitles('T-10937')}
        placeHolder={getTitles('T-10813')}
        value={values.number_of_bulls_for_natural_services}
        onChange={handleChange}
        containerStyles={styles.phone}
        allowOnlyNumbers={true}
        disabled={currRender === view}
      />

      <UIMultipleSelectCheckmarks
        name="natural_centers_for_reproduction_runners"
        value={values.natural_centers_for_reproduction_runners}
        title={getTitles('T-10938')}
        options={defaultValues.dairyRunners}
        onChange={(val) =>
          handleChange({ target: { name: 'natural_centers_for_reproduction_runners', value: val } })
        }
        placeholder={getTitles('T-10350')}
        containerStyles={styles.phone}
        preSelectedValue={values.natural_centers_for_reproduction_runners}
        disabled={currRender === view}
        showTitles={false}
        showSelect={currRender !== view}
      />

      {values.natural_centers_for_reproduction_runners?.length > 0 &&
        values.natural_centers_for_reproduction_runners.map((item, idx) => (
          <UIChipButton
            key={idx}
            title={item.title}
            onDelete={() => {
              setFieldValue(
                'natural_centers_for_reproduction_runners',
                handleMultiDelete(idx, values.natural_centers_for_reproduction_runners)
              );
              setBtndisabled(false);
            }}
            showDelete={currRender !== view}
          />
        ))}

      <Box sx={{ width: '100%', mt: 2 }}>
        <Text variant="D24" style={{ mt: 1 }}>
          {getTitles('T-10939')}
        </Text>
        <Box sx={styles.phone}>
          <Text variant="BoldH22Sec" style={{ mt: 1 }}>
            {getTitles('T-10940')}
          </Text>
          <Box sx={styles.naturalCont}>
            <UITextInputWithTitle
              name="natural_service.cow"
              title={getTitles('T-10748')}
              placeHolder={getTitles('T-10360')}
              value={values.natural_service?.cow}
              onChange={handleChange}
              containerStyles={styles.phone}
              allowOnlyNumbers={true}
              disabled={currRender === view}
            />
            <UITextInputWithTitle
              name="natural_service.buffalo"
              title={getTitles('T-10749')}
              placeHolder={getTitles('T-10360')}
              value={values.natural_service?.buffalo}
              onChange={handleChange}
              containerStyles={styles.phone}
              allowOnlyNumbers={true}
              disabled={currRender === view}
            />
          </Box>
          <Text variant="BoldH22Sec" style={{ mt: 2 }}>
            {getTitles('T-10941')}
          </Text>
          <Box sx={styles.naturalCont}>
            <UITextInputWithTitle
              name="artificial_insemination.cow"
              title={getTitles('T-10748')}
              placeHolder={getTitles('T-10360')}
              value={values.artificial_insemination?.cow}
              onChange={handleChange}
              containerStyles={styles.phone}
              allowOnlyNumbers={true}
              disabled={currRender === view}
            />
            <UITextInputWithTitle
              name="artificial_insemination.buffalo"
              title={getTitles('T-10749')}
              placeHolder={getTitles('T-10360')}
              value={values.artificial_insemination?.buffalo}
              onChange={handleChange}
              containerStyles={styles.phone}
              allowOnlyNumbers={true}
              disabled={currRender === view}
            />
          </Box>
        </Box>
      </Box>
      {values.has_ai_center === true && (
        <>
          <FormTitleCard title={getTitles('T-10942')}>
            <UITitleWithContent title={getTitles('T-10943')}>
              <Box sx={styles.radioSubCont}>
                <UIRadioButton
                  title={getTitles('T-10705')}
                  checked={values.big_hospital}
                  onClick={() => handleChange({ target: { name: 'big_hospital', value: true } })}
                />
                <UIRadioButton
                  title={getTitles('T-10706')}
                  checked={values.big_hospital === false}
                  onClick={() => {
                    handleChange({ target: { name: 'big_hospital', value: false } });
                    setFieldValue('big_hospital_distance', '');
                  }}
                />
              </Box>
            </UITitleWithContent>

            {values.big_hospital === false && (
              <UITextInputWithTitle
                name="big_hospital_distance"
                title={getTitles('T-10945')}
                placeHolder={getTitles('T-10952')}
                value={values.big_hospital_distance}
                onChange={handleChange}
                containerStyles={styles.phone}
                allowOnlyNumbers={true}
                disabled={currRender === view}
              />
            )}

            <UITitleWithContent title={getTitles('T-10944')}>
              <Box sx={styles.radioSubCont}>
                <UIRadioButton
                  title={getTitles('T-10705')}
                  checked={values.small_hospital}
                  onClick={() => handleChange({ target: { name: 'small_hospital', value: true } })}
                />
                <UIRadioButton
                  title={getTitles('T-10706')}
                  checked={values.small_hospital === false}
                  onClick={() => {
                    handleChange({ target: { name: 'small_hospital', value: false } });
                    setFieldValue('small_hospital_distance', '');
                  }}
                />
              </Box>
            </UITitleWithContent>

            {values.small_hospital === false && (
              <UITextInputWithTitle
                name="small_hospital_distance"
                title={getTitles('T-11215')}
                placeHolder={getTitles('T-10952')}
                value={values.small_hospital_distance}
                onChange={handleChange}
                containerStyles={styles.phone}
                allowOnlyNumbers={true}
                disabled={currRender === view}
              />
            )}
          </FormTitleCard>
          <UITitleWithContent title={getTitles('T-10946')}>
            <Box sx={styles.radioSubCont}>
              <UIRadioButton
                title={getTitles('T-10705')}
                checked={values.center}
                onClick={() => handleChange({ target: { name: 'center', value: true } })}
              />
              <UIRadioButton
                title={getTitles('T-10706')}
                checked={values.center === false}
                onClick={() => {
                  handleChange({ target: { name: 'center', value: false } });
                  setFieldValue('center_distance', '');
                }}
              />
            </Box>
          </UITitleWithContent>

          {values.center === false && (
            <UITextInputWithTitle
              name="center_distance"
              title={getTitles('T-11051')}
              placeHolder={getTitles('T-10952')}
              value={values.center_distance}
              onChange={handleChange}
              containerStyles={styles.phone}
              allowOnlyNumbers={true}
              disabled={currRender === view}
            />
          )}

          <UITitleWithContent title={getTitles('T-10947')}>
            <Box sx={styles.radioSubCont}>
              <UIRadioButton
                title={getTitles('T-10705')}
                checked={values.help_center}
                onClick={() => handleChange({ target: { name: 'help_center', value: true } })}
              />
              <UIRadioButton
                title={getTitles('T-10706')}
                checked={values.help_center === false}
                onClick={() => {
                  handleChange({ target: { name: 'help_center', value: false } });
                  setFieldValue('help_center_distance', '');
                }}
              />
            </Box>
          </UITitleWithContent>

          {values.help_center === false && (
            <UITextInputWithTitle
              name="help_center_distance"
              title={getTitles('T-11052')}
              placeHolder={getTitles('T-10952')}
              value={values.help_center_distance}
              onChange={handleChange}
              containerStyles={styles.phone}
              allowOnlyNumbers={true}
              disabled={currRender === view}
            />
          )}

          <UIMultipleSelectCheckmarks
            name="veterinary_service_runner"
            title={getTitles('T-10948')}
            options={defaultValues.dairyRunners}
            onChange={(val) =>
              handleChange({ target: { name: 'veterinary_service_runner', value: val } })
            }
            placeholder={getTitles('T-10350')}
            containerStyles={styles.phone}
            preSelectedValue={values.veterinary_service_runner}
            disabled={currRender === view}
            showTitles={false}
            showSelect={currRender !== view}
          />

          {values.veterinary_service_runner?.length > 0 &&
            values.veterinary_service_runner.map((item, idx) => (
              <UIChipButton
                key={idx}
                title={item.title}
                onDelete={() => {
                  setFieldValue(
                    'veterinary_service_runner',
                    handleMultiDelete(idx, values.veterinary_service_runner)
                  );
                  setBtndisabled(false);
                }}
                showDelete={currRender !== view}
              />
            ))}
        </>
      )}

      <ModalButtons
        secondaryBtnTitle={getTitles('T-10340')}
        onSecondaryBtnClick={handleBackButton}
        onPrimaryBtnClick={handleSubmit}
        primaryBtnTitle={
          currRender === add
            ? getTitles('T-10339')
            : currRender === edit
              ? getTitles('T-10733')
              : getTitles('T-10804')
        }
        disabled={currRender !== view && btnDisabled}
        containerStyle={globalStyles.mt(3)}
      />
    </Box>
  );
};
