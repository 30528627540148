import { Box, Divider } from '@mui/material';
import { styles } from './styles';
import { useEffect, useState } from 'react';
import { NoData, Text, UIButton } from '../../../../ui-kits';
import {
  convertToTitleCase,
  getFormatDate,
  getTitles,
  isImageUrl,
  isVideoUrl,
  resetMessages
} from '../../../../utils';
import { CartCounter, DescTitle } from '../../../common/utility-components';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
  SCREEN_PATH
} from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  authStatus,
  getCartItems,
  getEcommerceErrorMsg,
  getEcommerceSuccessMsg,
  getEcomProductDetails,
  getScroll
} from '../../../../services/redux/selectors';
import {
  addCartItemsRequest,
  getEcomProductDetailsRequest,
  resetECommerceMsg,
  setCartItems,
  setIsEcommerce,
  updateCartItemsRequest,
  updateScrollToTop
} from '../../../../services/redux/slices';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const EcomProductDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const cartItems = useSelector(getCartItems);
  const productData = useSelector(getEcomProductDetails);
  const successMsg = useSelector(getEcommerceSuccessMsg);
  const errorMsg = useSelector(getEcommerceErrorMsg);
  const isAuthenticated = useSelector(authStatus);
  const scroll = useSelector(getScroll);

  const res = defaultValues.isResponsive;

  const [productDetails, setProductDetails] = useState(null);
  const [image, setImage] = useState(productDetails?.product_image[0]);
  const [index, setIndex] = useState(0);
  const [currTab, setCurrTab] = useState(0);

  const { semenStraw } = defaultValues.inventoryMaterialGroupId;

  useEffect(() => {
    dispatch(setIsEcommerce(defaultValues.appLogicalTexts.eCommerce));
    dispatch(updateScrollToTop(!scroll));
  }, []);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetECommerceMsg()));
  }, [successMsg, errorMsg]);

  useEffect(() => {
    if (params?.id) {
      dispatch(getEcomProductDetailsRequest({ product_id: params?.id }));
    }
  }, [params]);

  useEffect(() => {
    if (productData) {
      let proImages = [...productData.product_image];
      if (productData.product_video) {
        proImages = [...proImages, ...productData.product_video];
      }
      setProductDetails({ ...productData, product_image: proImages });
      productData?.product_image && setImage(productData?.product_image[0] || null);
    }
  }, [productData]);

  const handleImageChange = (item, idx) => {
    setImage(item);
    setIndex(idx);
  };

  const handleMinusQty = (item) => {
    if (isAuthenticated) {
      dispatch(
        updateCartItemsRequest({ product_id: item._id, quantity: cartItems[item._id].quantity - 1 })
      );
    } else {
      let final = { ...cartItems };
      if (cartItems[item._id].quantity > 1) {
        final = { ...final, [item._id]: { ...item, quantity: cartItems[item._id].quantity - 1 } };
      } else {
        delete final[item._id];
      }
      dispatch(setCartItems(final));
    }
  };

  const addCartClick = (item) => {
    if (isAuthenticated) {
      dispatch(addCartItemsRequest({ product_id: item._id }));
    } else {
      dispatch(setCartItems({ ...cartItems, [item._id]: { ...item, quantity: 1 } }));
    }
  };

  const handleAddCartQuatity = (item) => {
    if (isAuthenticated) {
      dispatch(
        updateCartItemsRequest({ product_id: item._id, quantity: cartItems[item._id].quantity + 1 })
      );
    } else {
      dispatch(
        setCartItems({
          ...cartItems,
          [item._id]: { ...item, quantity: cartItems[item._id].quantity + 1 }
        })
      );
    }
  };

  const addCartBuynowClick = (item) => {
    if (isAuthenticated) {
      dispatch(addCartItemsRequest({ product_id: item._id }));
    } else {
      !cartItems[item._id] &&
        dispatch(setCartItems({ ...cartItems, [item._id]: { ...item, quantity: 1 } }));
    }
  };

  const handleBuyNow = (item) => {
    addCartBuynowClick(item);
    navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.SHOPPING_CART}`);
  };

  const proDetailsSec = [
    {
      title1: getTitles('T-11457'),
      title2: productDetails?.product_id
    },

    {
      title1: getTitles('T-10180'),
      title2: convertToTitleCase(productDetails?.material_group?.name)
    },

    {
      title1: getTitles('T-11471'),
      title2:
        convertToTitleCase(productDetails?.category_enum?.name) ||
        convertToTitleCase(productDetails?.category_id?.name)
    },

    {
      title1: getTitles('T-10743'),
      title2: productDetails?.expiry_date && getFormatDate(productDetails?.expiry_date)
    },
    productDetails?.material_group?.id !== semenStraw && {
      title1: getTitles('T-11472'),
      title2: productDetails?.product_type
    },
    productDetails?.material_group?.id !== semenStraw && {
      title1: getTitles('T-11615'),
      title2: productDetails?.brand_name
    },
    productDetails?.material_group?.id !== semenStraw &&
      productDetails?.power && {
        title1: getTitles('T-11532'),
        title2: productDetails?.power
      },
    productDetails?.material_group?.id === semenStraw && {
      title1: getTitles('T-11478'),
      title2: convertToTitleCase(productDetails?.bull_type?.name)
    },
    productDetails?.material_group?.id === semenStraw && {
      title1: getTitles('T-11479'),
      title2: productDetails?.year_production && getFormatDate(productDetails?.year_production)
    },
    productDetails?.material_group?.id === semenStraw && {
      title1: getTitles('T-11504'),
      title2: productDetails?.iso_certified ? getTitles('T-10705') : getTitles('T-10705')
    },
    productDetails?.material_group?.id === semenStraw && {
      title1: getTitles('T-11499'),
      title2: convertToTitleCase(productDetails?.affiliation?.name)
    }
  ];

  if (!productDetails) {
    return <NoData message={getErrorMessage('E-10275')} height={'80vh'} />;
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.left}>
        <Box sx={styles.imageCont}>
          {productDetails?.product_image.map((item, idx) => {
            if (isImageUrl(item)) {
              return (
                <img
                  src={item}
                  alt={`No image found`}
                  style={styles.img(index === idx, productDetails?.product_image?.length > 0)}
                  key={idx}
                  onClick={() => handleImageChange(item, idx)}
                />
              );
            } else if (isVideoUrl(item)) {
              return (
                <video
                  src={item}
                  key={idx}
                  alt={`No video found`}
                  style={styles.video(index === idx)}
                  onClick={!res ? () => handleImageChange(item, idx) : null}
                  controls={res}
                  controlsList="nodownload"
                />
              );
            }
          })}
        </Box>
        {!res && (
          <Box sx={styles.mainImageCont}>
            {isImageUrl(image) ? (
              <img src={image} style={styles.mainImage} />
            ) : isVideoUrl(image) ? (
              <video src={image} style={styles.mainImage} controls controlsList="nodownload" />
            ) : (
              <></>
            )}
            <Box sx={styles.cartCont}>
              {cartItems && cartItems[productDetails._id]?.quantity > 0 ? (
                <CartCounter
                  count={cartItems && cartItems[productDetails._id]?.quantity}
                  onAddClick={() => handleAddCartQuatity(productDetails)}
                  onMinusClick={() => handleMinusQty(productDetails)}
                  containerStyles={styles.counterCont}
                />
              ) : (
                <UIButton
                  endIcon={<ShoppingCartOutlinedIcon />}
                  style={styles.addCartBtn}
                  title={getTitles('T-11616').toUpperCase()}
                  onClick={() => addCartClick(productDetails)}
                />
              )}

              <UIButton
                style={styles.buyNowBtn}
                title={getTitles('T-11601').toUpperCase()}
                onClick={() => handleBuyNow(productDetails)}
              />
            </Box>
          </Box>
        )}
      </Box>
      <div style={styles.right}>
        <Box sx={styles.nameCont}>
          <Text variant="BoldH20">{productDetails?.product_name}</Text>
          <Text
            style={styles.price}
            variant="BoldH22">{`₹${productDetails?.final_price || ''}`}</Text>
          {productDetails?.material_group?.id !== semenStraw && productDetails?.color && (
            <Text variant="D20">
              {getTitles('T-10178')} : <span style={styles.colorText}>{productDetails?.color}</span>
            </Text>
          )}
        </Box>
        <Divider sx={styles.divider} />
        <Text variant="D20">{productDetails?.description}</Text>
        <Divider sx={styles.divider} />
        {res && (
          <Box sx={styles.tabCont}>
            <Text variant="BoldH22" style={styles.tab(currTab === 0)} onClick={() => setCurrTab(0)}>
              {getTitles('T-11497')}
            </Text>
            {productDetails?.return_policy && (
              <Text
                variant="BoldH22"
                style={styles.tab(currTab === 1)}
                onClick={() => setCurrTab(1)}>
                {getTitles('T-11533')}
              </Text>
            )}
          </Box>
        )}
        {!res && <Text variant="BoldH22">{getTitles('T-11497')}</Text>}
        {(!res || (res && currTab === 0)) && (
          <Box sx={styles.proDetBox}>
            {proDetailsSec.map((el, idx) =>
              el ? (
                <DescTitle
                  key={idx}
                  title1={el.title1}
                  title2={el.title2}
                  containerStyles={styles.descCont}
                />
              ) : (
                <></>
              )
            )}
          </Box>
        )}
        {!res && <Divider sx={styles.divider} />}
        {((productDetails?.return_policy && !res) || (res && currTab === 1)) && (
          <>
            {!res && <Text variant="D22">{getTitles('T-11533')}</Text>}
            <Text sx={styles.returnText} variant="D20">
              {productDetails?.return_policy}
            </Text>
          </>
        )}

        {res && (
          <Box sx={styles.cartCont}>
            {cartItems && cartItems[productDetails._id]?.quantity > 0 ? (
              <CartCounter
                count={cartItems && cartItems[productDetails._id]?.quantity}
                onAddClick={() => handleAddCartQuatity(productDetails)}
                onMinusClick={() => handleMinusQty(productDetails)}
                containerStyles={styles.counterCont}
              />
            ) : (
              <UIButton
                endIcon={<ShoppingCartOutlinedIcon />}
                style={styles.addCartBtn}
                title={getTitles('T-11616').toUpperCase()}
                onClick={() => addCartClick(productDetails)}
              />
            )}

            <UIButton style={styles.buyNowBtn} title={getTitles('T-11601').toUpperCase()} />
          </Box>
        )}
      </div>
    </Box>
  );
};
