import { Box } from '@mui/material';
import { Text } from '../text';
import { defaultValues } from '../../constants';
import { Styles } from './style';
import img from '../../assets/icons/refres.png';
import { getTitles } from '../../utils';

export const UIRefresh = ({ handleRefresh, showTitle = true }) => {
  return (
    <Box style={Styles.toolsIconContainer(showTitle)} onClick={handleRefresh}>
      <img style={Styles.toolsIcons(showTitle)} src={img} />
      {!defaultValues.isResponsive && showTitle && (
        <Text variant="D20">{getTitles('T-10074')}</Text>
      )}
    </Box>
  );
};
