import { createSelector } from 'reselect';

export const getCallcenterLoading = createSelector(
  [(state) => state.callCenter.isLoading],
  (d) => d
);
export const getCallCenterSuccessMsg = createSelector(
  [(state) => state.callCenter.successMessage],
  (d) => d
);
export const getCallCenterErrorMsg = createSelector(
  [(state) => state.callCenter.errorMessage],
  (d) => d
);
export const getCallcenterCurrTab = createSelector([(state) => state.callCenter.currTab], (d) => d);

export const getCallcenterMyTasks = createSelector([(state) => state.callCenter.myTasks], (d) => d);
export const getCallcenterAllCallHistory = createSelector(
  [(state) => state.callCenter.allCallHistory],
  (d) => d
);
export const getCallcenterAllTasks = createSelector(
  [(state) => state.callCenter.allTasks],
  (d) => d
);
export const getCallcenterTaskDetails = createSelector(
  [(state) => state.callCenter.taskDetails],
  (d) => d
);
export const getCallcenterUsersList = createSelector(
  [(state) => state.callCenter.usersList],
  (d) => d
);
export const getCallcenterUserInfo = createSelector(
  [(state) => state.callCenter.userInfo],
  (d) => d
);
export const getCallcenterCallerTasks = createSelector(
  [(state) => state.callCenter.callerTasks],
  (d) => d
);
export const getCallcenterCallerCallHistory = createSelector(
  [(state) => state.callCenter.callerCallHistory],
  (d) => d
);

export const getCallcenterTaskModel = createSelector(
  [(state) => state.callCenter.taskModel],
  (d) => d
);

export const getCallcenterCalleOrEmployee = createSelector(
  [(state) => state.callCenter.cattleOrEmployee],
  (d) => d
);

export const getCallerDetails = createSelector(
  [(state) => state.callCenter.callerDetails],
  (d) => d
);

export const getCattleTaskCallCenter = createSelector(
  [(state) => state.callCenter.cattleTask],
  (d) => d
);
export const getCallCenterCattleNxtPage = createSelector(
  [(state) => state.callCenter.cattleTaskNxtPage],
  (d) => d
);

export const getCallCenterCattleCurrPage = createSelector(
  [(state) => state.callCenter.cattleTaskCurrPage],
  (d) => d
);
export const getCallCenterCattleTotalPage = createSelector(
  [(state) => state.callCenter.cattleTaskTotalPage],
  (d) => d
);

export const getCallCenterAgentsNxtPage = createSelector(
  [(state) => state.callCenter.callCenterAgentsNxtPage],
  (d) => d
);

export const getCallCenterAgentsCurrPage = createSelector(
  [(state) => state.callCenter.callCenterAgentsCurrPage],
  (d) => d
);
export const getCallCenterAgentsTotalPage = createSelector(
  [(state) => state.callCenter.callCenterAgentsTotalPage],
  (d) => d
);

export const getCallCenterAgentsList = createSelector(
  [(state) => state.callCenter.callCenterAgents],
  (d) => d
);
