import { Box } from '@mui/material';
import { ViewData } from '../../../../../ui-kits';
import { getFormatDate, getTitles } from '../../../../../utils';

const styles = {
  container: {
    width: '100%'
  }
};
export const ViewAccountDetails = ({ pfAccount, sbAccount, insurance }) => {
  return (
    <Box sx={styles.container}>
      {sbAccount && (
        <>
          <ViewData title={getTitles('T-10370')} subTitle={sbAccount.sb_account_number} />
          <ViewData title={getTitles('T-10404')} subTitle={sbAccount.bank_name} bullets={true} />
          <ViewData title={getTitles('T-10405')} subTitle={sbAccount.ifsc_code} bullets={true} />
          <ViewData
            title={getTitles('T-10407')}
            subTitle={`${sbAccount.branch_address_line_one ? [sbAccount.branch_address_line_one, sbAccount.branch_address_line_two].join(' ') : sbAccount.branch_address_line_two}`}
            bullets={true}
          />
          <ViewData title={getTitles('T-10363')} subTitle={sbAccount.country_id} bullets={true} />
          <ViewData
            title={getTitles('T-10070')}
            subTitle={sbAccount?.state_id?.name}
            bullets={true}
          />
          <ViewData
            title={getTitles('T-10069')}
            subTitle={sbAccount?.district_id?.name}
            bullets={true}
          />
          <ViewData
            title={getTitles('T-10071')}
            subTitle={sbAccount?.taluka_id?.name}
            bullets={true}
          />
          <ViewData
            title={getTitles('T-10068')}
            subTitle={sbAccount?.village_id?.name}
            bullets={true}
          />
          <ViewData title={getTitles('T-10359')} subTitle={sbAccount.post_code} bullets={true} />
        </>
      )}
      {pfAccount && (
        <>
          <ViewData title={getTitles('T-10371')} subTitle={pfAccount.pf_account_number} />
          <ViewData
            title={getTitles('T-10409')}
            subTitle={pfAccount.pf_account_details}
            bullets={true}
          />
        </>
      )}

      {insurance && (
        <>
          <ViewData title={getTitles('T-10372')} subTitle={insurance.insurance_policy_number} />

          <ViewData
            title={getTitles('T-10655')}
            subTitle={insurance.insurance_provider}
            bullets={true}
          />
          <ViewData
            title={getTitles('T-10410')}
            subTitle={insurance.insurance_policy_details}
            bullets={true}
          />
          <ViewData
            title={getTitles('T-10411')}
            subTitle={getFormatDate(insurance.start_date)}
            bullets={true}
          />
          <ViewData
            title={getTitles('T-10412')}
            subTitle={getFormatDate(insurance.end_date)}
            bullets={true}
          />
        </>
      )}
    </Box>
  );
};
