import { Badge, Box } from '@mui/material';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Screen, SearchBar, Text } from '../../ui-kits';
import { styles } from './styles';
import { Footer } from '../../components';
import { defaultValues, KEYS, SCREEN_PATH } from '../../constants';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  authStatus,
  getActivityFilter,
  getCartItems,
  getCartOpen,
  getEcommerceLoading,
  getUserData
} from '../../services/redux/selectors';
import {
  getActivityProductsRequest,
  setActivitySearchText,
  setAuthStatus,
  setCartOpen,
  setIsEcommerce,
  setUserData
} from '../../services/redux/slices';
import { CartModal, EcommerceLogin } from '../../components/HOC/e-commerce';
import call from '../../assets/icons/call_quality.png';
import { useEffect } from 'react';
import { decryptUserData, getTitles } from '../../utils';
import { UIBackButton } from '../../ui-kits/back-button';

export const EcommerceHome = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(getEcommerceLoading);
  const cartItems = useSelector(getCartItems);
  const userData = useSelector(getUserData);
  const isAuthenticated = useSelector(authStatus);
  const open = useSelector(getCartOpen);
  const filter = useSelector(getActivityFilter);
  const decryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const sm = defaultValues.isMobile;

  const res = defaultValues.isResponsive;

  useEffect(() => {
    const queryString = window.location.search;
    const encryptedData = queryString ? queryString.substring(1) : null;

    if (encryptedData) {
      try {
        const decryptedUser = decryptUserData(decodeURIComponent(encryptedData), decryptionKey);
        if (decryptedUser) {
          dispatch(
            setUserData({
              ...decryptedUser.user_data,
              is_mobile: decryptedUser.user_data?.is_web ? false : sm
            })
          );
          dispatch(setAuthStatus(true));
          dispatch(setIsEcommerce(defaultValues.appLogicalTexts.eCommerce));
          localStorage.setItem(KEYS.ACCESS_KEY, decryptedUser.auth_token);
        }
      } catch (error) {
        console.error('Decryption failed: ', error);
      }
    }
  }, []);

  useEffect(() => {
    const handleBackButton = () => {
      if (location.pathname === SCREEN_PATH.ECOMMERCE && isAuthenticated) {
        window.history.pushState(null, null, window.location.href);
      }
    };

    if (location.pathname === SCREEN_PATH.ECOMMERCE && isAuthenticated) {
      window.history.pushState(null, null, window.location.href);
      window.addEventListener('popstate', handleBackButton);
    }

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [location.pathname, isAuthenticated]);

  const handleClose = () => {
    dispatch(setCartOpen(false));
  };

  const handleSearch = (val) => {
    const path = `${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.ACTIVITY}`;
    dispatch(setActivitySearchText(val));
    dispatch(
      getActivityProductsRequest({
        search_text: val || null,
        type: location?.state?.type,
        mark_as: location?.state?.mark_as,
        filter: location?.pathname === path && JSON.stringify(filter)
      })
    );

    if (location?.pathname !== path) {
      navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.ACTIVITY}`, {
        state: {
          title: defaultValues.productActivityTitles.actProductList
        }
      });
    }
  };

  const handleShoppingCart = () => {
    navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.SHOPPING_CART}`);
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleMyOrders = () => {
    navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.MY_ORDERS}`);
  };

  return (
    <Screen showHeader={!userData?.is_mobile} isLoading={isLoading}>
      <Box sx={styles.container}>
        <Box sx={styles.searchBarCont}>
          <Box sx={styles.cartSuperCont}>
            {![
              SCREEN_PATH.ECOMMERCE,
              `${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.ORDER_CONFIRMATION}`
            ].includes(location.pathname) && (
              <UIBackButton onClick={handleBackButton} style={styles.backBtn} />
            )}

            {res && location?.pathname.includes(SCREEN_PATH.SHOPPING_CART) && (
              <Text variant="boldH32" style={{ ml: '30px' }}>
                {getTitles('T-11663')}
              </Text>
            )}
            {res && location?.pathname.includes(SCREEN_PATH.MY_ORDERS) && (
              <Text variant="boldH32" style={{ ml: '30px' }}>
                {getTitles('T-11672')}
              </Text>
            )}

            {res && location?.pathname.includes(SCREEN_PATH.CHECKOUT) && (
              <Text variant="boldH32" style={{ ml: '30px' }}>
                {getTitles('T-11718')}
              </Text>
            )}

            {!res && <SearchBar containerStyles={styles.searchBar} searchedVlaue={handleSearch} />}

            <Box sx={styles.cartCont}>
              <LocalMallOutlinedIcon sx={styles.icons} onClick={handleMyOrders} />
              <Badge
                badgeContent={
                  Object.values(cartItems || {})?.length > 0
                    ? Object.values(cartItems || {})?.length
                    : null
                }
                onClick={handleShoppingCart}
                sx={styles.badge}>
                <ShoppingCartOutlinedIcon sx={styles.icons} />
              </Badge>
              <img src={call} style={styles.call} />
            </Box>
          </Box>

          {res && <SearchBar containerStyles={styles.searchBar} searchedVlaue={handleSearch} />}
        </Box>

        <Outlet />

        {!userData?.is_mobile && (
          <Box>
            <Box sx={styles.dummyDiv}></Box>
            <Footer type={defaultValues.appLogicalTexts.eCommerce} />
          </Box>
        )}

        {![
          `${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.SHOPPING_CART}`,
          `${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.CHECKOUT}`,
          `${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.ECOM_SHIPPING_POLICY}`,
          `${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.ECOM_TERMS_CONDITIONS}`,
          `${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.ECOM_DISCLAIMER}`,
          `${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.ECOM_PRIVACY_POLICY}`,
          `${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.ORDER_CONFIRMATION}`
        ].includes(location?.pathname) && <CartModal showModel={open} onClose={handleClose} />}
      </Box>
      <EcommerceLogin />
    </Screen>
  );
};
