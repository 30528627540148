import { Inward, ManageOutward } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { checkReadOnly, getTitles, resetMessages } from '../../utils';
import { Screen, Text, UITablist } from '../../ui-kits';
import {
  getInventoryCurrTab,
  getInventoryErrorMsg,
  getInventoryLoading,
  getInventorySuccessMsg,
  getUserData
} from '../../services/redux/selectors';
import { toast } from 'react-toastify';
import { resetInventoryMsg, setInventoryCurrTab } from '../../services/redux/slices/inventorySlice';
import { color } from '../../theme';

const styles = {
  tabStyle: { flex: 1, maxWidth: 'none' },
  tabCont: {
    backgroundColor: color.secondaryBackground
  }
};

export const ManageInventory = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getInventoryLoading);
  const successMsg = useSelector(getInventorySuccessMsg);
  const errorMsg = useSelector(getInventoryErrorMsg);
  const userData = useSelector(getUserData);
  const currTab = useSelector(getInventoryCurrTab);

  const [isReadOnly, setIsReadOnly] = useState(false);
  const { inventoryManager } = defaultValues.employeeRol;

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, inventoryManager));
  }, [userData]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetInventoryMsg()));
  }, [successMsg, errorMsg]);

  const tabList = [
    {
      value: <Inward isReadOnly={isReadOnly} />,
      title: getTitles('T-11462')
    },
    {
      value: <ManageOutward isReadOnly={isReadOnly} />,
      title: getTitles('T-11463')
    }
  ];

  const handleTabChange = (val) => {
    dispatch(setInventoryCurrTab(val));
  };

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-10122')}>
      {defaultValues.isResponsive && <Text variant="boldH32Sec">{getTitles('T-10122')}</Text>}

      <UITablist
        renderElements={tabList}
        currentTab={currTab}
        onTabChange={handleTabChange}
        tabStyle={styles.tabStyle}
        containerStyle={styles.tabCont}
      />
    </Screen>
  );
};
