import { useDispatch, useSelector } from 'react-redux';
import {
  OtpInput,
  PopupModal,
  Text,
  UIButton,
  UIPhoneInputTitle,
  UITextInputWithTitle
} from '../../../../ui-kits';
import {
  ecomGetPhone,
  ecomPhoneVerified,
  getAuthErrorMessage,
  getAuthSuccessMessage,
  getEcommerceLoginOpen,
  getIsRegisteredUser
} from '../../../../services/redux/selectors';
import {
  ecomLoginRequest,
  ecomResendOtpRequest,
  ecomUpdatePhone,
  ecomUpdatePhoneVerified,
  ecomVerifyLoginRequest,
  resetAuthMessages,
  setEcommerceLoginModalOpen
} from '../../../../services/redux/slices';
import { styles } from './style';
import { Box } from '@mui/material';
import { ecomNewUserSchema, getTitles, resetMessages } from '../../../../utils';
import { getErrorMessage, getSuccessMessage } from '../../../../constants';
import { color } from '../../../../theme';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

export const EcommerceLogin = () => {
  const dispatch = useDispatch();

  const showLogin = useSelector(getEcommerceLoginOpen);
  const phoneVerified = useSelector(ecomPhoneVerified);
  const isRegistered = useSelector(getIsRegisteredUser);
  const successMessage = useSelector(getAuthSuccessMessage);
  const errorMessage = useSelector(getAuthErrorMessage);
  const phone = useSelector(ecomGetPhone);

  const [otpSend, setOtpSend] = useState(false);
  const [otp, setOtp] = useState('');
  const [btnDisabled, setBtndisabled] = useState(true);
  const [otpErrorMessage, setOtpErrorMessage] = useState('');

  useEffect(() => {
    if (phoneVerified) {
      setOtpSend(true);
      setBtndisabled(true);
    } else {
      setOtpSend(false);
      setBtndisabled(true);
    }
  }, [phoneVerified]);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessages(() => dispatch(resetAuthMessages()));
  }, [successMessage, errorMessage]);

  const { values, errors, touched, dirty, handleBlur, setFieldValue, isValid } = useFormik({
    initialValues: {
      first_name: '',
      middle_name: '',
      last_name: ''
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: ecomNewUserSchema
  });

  const handleButtonClick = () => {
    setOtpSend(false);
    if (phoneVerified && isRegistered) {
      dispatch(ecomVerifyLoginRequest({ phone_number: phone, otp: parseInt(otp), fcm_token: '' }));
      popupClose();
    } else if (phoneVerified && !isRegistered) {
      dispatch(
        ecomVerifyLoginRequest({
          phone_number: phone,
          otp: parseInt(otp),
          fcm_token: '',
          ...values
        })
      );
      popupClose();
    } else {
      dispatch(ecomLoginRequest({ phone_number: phone }));
    }
  };

  const handlePhoneChange = (val) => {
    dispatch(ecomUpdatePhone(val));
  };
  const handlePhoneError = (error) => {
    setBtndisabled(error);
  };
  const handleOtpSend = (val) => {
    setOtpSend(val);
  };

  const handleOtpChange = (val) => {
    if (!isNaN(Number(val))) {
      setOtp(val);
      if (!val) {
        setOtpErrorMessage(getErrorMessage('E-10008'));
      } else {
        setOtpErrorMessage('');
      }
    } else if (!otp) {
      setOtpErrorMessage(getErrorMessage('E-10008'));
    }
  };

  const handleOtpResend = () => {
    dispatch(ecomResendOtpRequest({ phone_number: phone }));
    setBtndisabled(true);
    setTimeout(() => {
      setBtndisabled(false);
    }, 20000);
  };

  const handleOtpBlur = () => {
    if (otp.length !== 6) {
      setOtpErrorMessage(getErrorMessage('E-10008'));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const popupClose = () => {
    dispatch(setEcommerceLoginModalOpen(false));
    dispatch(ecomUpdatePhoneVerified(false));
    dispatch(ecomUpdatePhone(null));
    setOtp('');
  };

  return (
    <PopupModal
      showModel={showLogin}
      onClose={popupClose}
      containerStyle={styles.modalCont}
      childContStyle={styles.modalChildCont}>
      <Box sx={styles.container}>
        <Box sx={styles.sectionBox2}>
          <Box sx={styles.sectionSubBox}>
            <Text variant="boldH48" style={styles.heading}>
              {phoneVerified ? getTitles('T-10055') : getTitles('T-10054')}
            </Text>
            <UIPhoneInputTitle
              phoneValue={phone}
              onChange={handlePhoneChange}
              handleError={handlePhoneError}
              disabled={phoneVerified}
              onBlurErrorMsg={getErrorMessage('E-10002')}
              required={true}
            />

            {phoneVerified && (
              <>
                {!isRegistered && (
                  <>
                    <UITextInputWithTitle
                      name="first_name"
                      value={values?.first_name}
                      onChange={handleChange}
                      title={getTitles('T-10041')}
                      placeHolder={getTitles('T-10047')}
                      required={true}
                      onBlur={handleBlur}
                      error={touched?.first_name && errors?.first_name}
                    />
                    <UITextInputWithTitle
                      name="middle_name"
                      value={values?.middle_name}
                      onChange={handleChange}
                      title={getTitles('T-10337')}
                      placeHolder={getTitles('T-10338')}
                    />
                    <UITextInputWithTitle
                      name="last_name"
                      title={getTitles('T-10042')}
                      value={values?.last_name}
                      onChange={handleChange}
                      placeHolder={getTitles('T-10048')}
                      required={true}
                      onBlur={handleBlur}
                      error={touched?.last_name && errors?.last_name}
                    />
                  </>
                )}

                <Text variant="D16" style={styles.otpDesc1} textColor={color.secondaryText}>
                  {getTitles('T-10056')}
                </Text>

                <Text
                  variant="D16"
                  style={styles.otpDesc2}
                  onClick={() => {
                    setOtp('');
                    setBtndisabled(false);
                    setOtpErrorMessage('');
                    dispatch(ecomUpdatePhoneVerified(false));
                  }}>
                  <u>{getTitles('T-10057')}</u>
                </Text>
                <OtpInput
                  otp={otp}
                  onChange={handleOtpChange}
                  onBlur={() => handleOtpBlur()}
                  errorMsg={otpErrorMessage}
                  onResendClick={handleOtpResend}
                  otpSend={otpSend}
                  handleOtpSend={handleOtpSend}
                />
              </>
            )}
            <UIButton
              title={phoneVerified ? getTitles('T-10035') : getTitles('T-10002')}
              disabled={
                phoneVerified
                  ? otp.length < 6 ||
                    otpErrorMessage ||
                    (!isRegistered ? !dirty || !isValid : false)
                  : btnDisabled
              }
              style={styles.btn}
              onClick={handleButtonClick}
            />
          </Box>
        </Box>
      </Box>
    </PopupModal>
  );
};
