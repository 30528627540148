import { defaultValues } from '../../../../constants';

const res = defaultValues.isResponsive;
export const styles = {
  profileBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    mt: res ? '10px' : '20px'
  },
  profileImg: {
    width: '36px',
    height: '36px'
  },
  profileTitle: {
    fontSize: '18px'
  },
  profileSubTitle: {
    fontSize: '11px'
  },
  id: {
    textOverflow: 'ellipsis',
    width: '100%',
    overflowWrap: 'break-word'
  },
  columnWidth: '33.3%',
  assign: {
    padding: res ? '0px' : '5px 20px',
    boxSizing: 'border-box',
    height: res ? '15px' : '25px',
    cursor: 'pointer'
  },
  backArrowButton: {
    p: '10px'
  },
  cattleName: {
    textDecoration: 'underline',
    cursor: 'pointer',
    overflowWrap: 'anywhere'
  },
  tableItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    pl: '5px'
  }
};
