import { Box } from '@mui/material';
import { TableCard } from '../../components/common/cards/table-card';
import { defaultValues } from '../../constants';

// below code is the example of data for table rows
// tableRows = [
//   {
//     id: { title: "Id", columnWidth: '100px', values: '2125' },
//     name: { columnWidth: '25%',  values: <Text>Name<Text /> // can use components like this  },
//   }
// ];

export const UIDataTable = ({
  tableRows,
  rowValueContainerStyle,
  valuesSubStyle,
  headerSubStyle,
  headerContStyle,
  headerTextStyle,
  isHeader = null,
  showHeader = !defaultValues.isResponsive,
  customData = () => {},
  customEndData = () => {}
}) => {
  const styles = {
    container: {
      width: '100%',
      display: 'flex',
      gap: '10px',
      flexDirection: 'column'
    }
  };

  return (
    <Box sx={styles.container}>
      {tableRows &&
        tableRows.map((e, index) => (
          <TableCard
            key={index}
            data={e}
            isHeader={isHeader !== null ? isHeader : index === 0}
            rowValueContainerStyle={rowValueContainerStyle}
            valuesSubStyle={valuesSubStyle}
            headerSubStyle={headerSubStyle}
            headerContStyle={headerContStyle}
            headerTextStyle={headerTextStyle}
            showHeader={showHeader}
            customData={customData(index)}
            customEndData={customEndData(index)}
          />
        ))}
    </Box>
  );
};
