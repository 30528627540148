import { Box } from '@mui/material';
import { CollapseDropView, NoData } from '../../../../ui-kits';
import { useFormik } from 'formik';
import { employeepermissionsSchema, getTitles } from '../../../../utils';
import { useEffect, useRef, useState } from 'react';
import { ModalButtons } from '../../../../components';
import { globalStyles } from '../../../../theme';
import {
  getDistrictByStateRequest,
  getStateRequest,
  getTalukaByDistrictRequest,
  getVillageByTalukaRequest,
  setClearProcessPages,
  setClearProcessStateDistTalVill,
  updateEmployeeRequest
} from '../../../../services/redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import { defaultValues } from '../../../../constants';

import { PermissionForm } from './permissions-form';
import { ViewPermissions } from './view-permissions';
import {
  getdistrictByState,
  getdistrictByStateNxtPage,
  gettalukaByDistrict,
  gettalukaByDistrictNxtPage,
  getvillageByTaluka,
  getvillageByTalukaNxtPage
} from '../../../../services/redux/selectors/manageProccess';

const res = defaultValues.isResponsive;
const styles = {
  container: {
    width: '100%'
  },
  newCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'start'
  },
  editBtn: {
    padding: '5px 20px',
    height: res ? '35px' : '45px',
    width: res ? '90px' : '150px',
    marginBottom: '10px'
  }
};

export const PermissionDetails = ({
  employeeId,
  permissionData,
  additional,
  editable,
  states,
  handleContinue,
  setStateList
}) => {
  const dispatch = useDispatch();
  const districts = useSelector(getdistrictByState);
  const talukas = useSelector(gettalukaByDistrict);
  const villages = useSelector(getvillageByTaluka);
  const districtNxtPg = useSelector(getdistrictByStateNxtPage);
  const villageNxtPge = useSelector(getvillageByTalukaNxtPage);
  const talukNxtPge = useSelector(gettalukaByDistrictNxtPage);

  const [btnDisabled, setBtndisabled] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [selectedPerm, setSelectedPerm] = useState(null);
  const [index, setIndex] = useState(true);
  const [districtsList, setDistrictsLists] = useState([]);
  const [talukasList, setTalukasList] = useState([]);
  const [villagesList, setVillagesList] = useState([]);
  const [isEmp, setIsEmp] = useState(false);

  const typeAssign = {
    type: 1,
    // permission_id: defaultValues.employeeRol.employeeManager,
    filter: JSON.stringify([{ is_active: true }])
  };

  const {
    values,
    errors,
    touched,
    resetForm,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    dirty,
    isValid,
    setValues
  } = useFormik({
    initialValues: {
      permissions: '',
      states: [],
      districts: [],
      taluka: [],
      villages: [],
      read_only: false,
      has_approve_permission: false,
      assign_location: null,
      permObj: null
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: employeepermissionsSchema
  });

  const previousPages = useRef({
    districtNxtPage: districtNxtPg,
    villageNxtPage: villageNxtPge,
    talukaNextPage: talukNxtPge
  });

  useEffect(() => {
    deduplicateAndSet(districtsList, districts, setDistrictsLists);
    deduplicateAndSet(talukasList, talukas, setTalukasList);
    deduplicateAndSet(villagesList, villages, setVillagesList);
  }, [districts, talukas, villages]);

  useEffect(() => {
    const prev = previousPages.current;

    if (districtNxtPg !== prev?.districtNxtPage && districtNxtPg !== null) {
      dispatch(
        getDistrictByStateRequest({
          state_id: JSON.stringify(values.states),
          page: districtNxtPg,
          ...typeAssign
        })
      );
    }
    if (talukNxtPge !== prev?.talukaNextPage && talukNxtPge !== null) {
      dispatch(
        getTalukaByDistrictRequest({
          district_id: JSON.stringify(values.districts),
          page: talukNxtPge,
          ...typeAssign
        })
      );
    }

    if (villageNxtPge !== prev?.villageNxtPage && villageNxtPge !== null) {
      dispatch(
        getVillageByTalukaRequest({
          taluka_id: JSON.stringify(values.taluka),
          page: villageNxtPge,
          ...typeAssign
        })
      );
    }

    previousPages.current = {
      districtNxtPage: districtNxtPg,
      villageNxtPage: villageNxtPge,
      talukaNextPage: talukNxtPge
    };
  }, [districtNxtPg, villageNxtPge, talukNxtPge]);

  useEffect(() => {
    if (permissionData) {
      const perm = permissionData.map((item) => ({
        ...item,
        title:
          item.permission_id &&
          defaultValues.employeePermissions.find((ele) => ele.value === item.permission_id).title
      }));
      setPermissions(perm);
    }
  }, [permissionData]);

  const previousValues = useRef({
    state: values?.states?.length,
    district: values?.districts?.length,
    village: values?.villages?.length,
    taluka: values?.taluka?.length
  });

  useEffect(() => {
    const prev = previousValues.current;

    if (isEmp) {
      const [updatedDistricts, updatedDistrictOptions] = removeDataOfRemovedItem(
        values.states,
        values.districts,
        'state_id',
        districtsList
      );
      setFieldValue(defaultValues.appLogicalTexts.districts, updatedDistricts);
      setDistrictsLists(updatedDistrictOptions);

      const [updatedTaluka, updatedTalukaOptions] = removeDataOfRemovedItem(
        values.districts,
        values.taluka,
        'district_id',
        talukasList
      );
      setFieldValue('taluka', updatedTaluka);
      setTalukasList(updatedTalukaOptions);

      const [updatedVillage, updatedVillageOptions] = removeDataOfRemovedItem(
        values.taluka,
        values.villages,
        'taluka_id',
        villagesList
      );
      setFieldValue('villages', updatedVillage);
      setVillagesList(updatedVillageOptions);
      setTimeout(() => {
        setIsEmp(false);
      }, 3000);
    }

    if (prev?.state !== values.states?.length && values.states?.length > 0) {
      dispatch(
        getDistrictByStateRequest({
          state_id: JSON.stringify(values.states),
          page: 1,
          ...typeAssign
        })
      );
    }
    if (prev?.district !== values.districts?.length && values.districts?.length > 0) {
      dispatch(
        getTalukaByDistrictRequest({
          district_id: JSON.stringify(values.districts),
          page: 1,
          ...typeAssign
        })
      );
    }
    if (prev?.taluka !== values.taluka?.length && values.taluka?.length > 0) {
      dispatch(
        getVillageByTalukaRequest({
          taluka_id: JSON.stringify(values.taluka),
          page: 1,
          ...typeAssign
        })
      );
    }

    previousValues.current = {
      state: values?.states?.length,
      district: values?.districts?.length,
      village: values?.villages?.length,
      taluka: values?.taluka?.length
    };
  }, [
    values.states?.length,
    values.districts?.length,
    values.taluka?.length,
    values.villages?.length,
    villagesList,
    talukasList,
    districtsList
  ]);

  useEffect(() => {
    if (selectedPerm) {
      resetForm();

      setFieldValue('permissions', selectedPerm.permission_id);
      setFieldValue('read_only', selectedPerm.read_only);
      setFieldValue('has_approve_permission', selectedPerm.has_approve_permission);
      setFieldValue('assign_location', selectedPerm.assign_location);
      setFieldValue('permObj', selectedPerm);

      setFieldValue('states', selectedPerm.assign_location.state_id);
      setFieldValue('districts', selectedPerm.assign_location.district_id);
      setFieldValue('taluka', selectedPerm.assign_location.taluka_id);
      setFieldValue('villages', selectedPerm.assign_location.village_id);
    }
  }, [selectedPerm]);

  const removeDataOfRemovedItem = (parentArray, childArray, childField, distList) => {
    const remainingData = distList.filter((item) => parentArray.includes(item[childField]));
    const secondary = remainingData?.map((ele) => ele._id);
    const final = secondary.filter((ele) => childArray.includes(ele));

    return [final?.length > 0 ? final : [], remainingData?.length > 0 ? remainingData : []];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const deduplicateAndSet = (list, newItems, setFunction) => {
    let temp = [...list, ...newItems];

    const uniqueItems = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });

    setFunction(uniqueItems?.length > 0 ? uniqueItems : []);
  };

  const handleSubmit = () => {
    let payload = {
      _id: employeeId,
      permissions: permissions
    };
    const obj = {
      ...values.permObj,
      permission_id: values.permissions,
      read_only: values.read_only,
      has_approve_permission: values.has_approve_permission,
      assign_location: {
        ...values.assign_location,
        state_id: values.states,
        district_id: values.districts,
        taluka_id: values.taluka,
        village_id: values.villages
      }
    };
    delete obj.title;
    if (index === true) {
      payload.permissions = [...permissions, obj];
    } else {
      const temp = permissions.map((item, idx) => (idx === index ? obj : item));
      payload.permissions = temp;
    }
    dispatch(updateEmployeeRequest(payload));
    setIndex(true);
  };

  const handleAddAnother = () => {
    handleContinue();
    handleSubmit();
    setIndex(true);
    resetForm();
  };

  const handleTitleClick = (item, idx) => {
    setSelectedPerm(null);
    setBtndisabled(true);
    setIsEmp(false);
    setSelectedPerm(item);
    setStateList([]);
    setDistrictsLists([]);
    setTalukasList([]);
    setVillagesList([]);
    dispatch(setClearProcessStateDistTalVill());
    dispatch(setClearProcessPages());
    dispatch(getStateRequest({ page: 1, ...typeAssign }));
    resetForm();
    if (index === idx) {
      setIsEmp(false);
      setSelectedPerm(null);
      return setIndex(true);
    }
    setIndex(idx);
    setFieldTouched('permissions', false);
  };

  if (editable) {
    return (
      <Box sx={styles.container}>
        {permissions &&
          permissions.length > 0 &&
          permissions.map((item, idx) => (
            <Box key={idx} sx={{ width: '100%' }}>
              <CollapseDropView
                title={
                  item.permission_id &&
                  defaultValues.employeePermissions.find(
                    (elem) => elem.value === item.permission_id
                  ).title
                }
                handleSubTitleClick={() => handleTitleClick(item, idx)}
                open={index === idx}
              />
              {index === idx && (
                <PermissionForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setBtndisabled={setBtndisabled}
                  handleAddAnother={handleAddAnother}
                  setSelectedPerm={setSelectedPerm}
                  selectedPerm={selectedPerm}
                  isValid={isValid}
                  dirty={dirty}
                  btnDisabled={btnDisabled}
                  states={states}
                  resetForm={resetForm}
                  isEdit={index}
                  permissions={permissions}
                  districtList={districtsList}
                  talukaList={talukasList}
                  villageList={villagesList}
                  setIsEmp={setIsEmp}
                  setStateList={setStateList}
                />
              )}
            </Box>
          ))}

        {index === true && (
          <PermissionForm
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setBtndisabled={setBtndisabled}
            handleAddAnother={handleAddAnother}
            setSelectedPerm={setSelectedPerm}
            selectedPerm={selectedPerm}
            isValid={isValid}
            dirty={dirty}
            btnDisabled={btnDisabled}
            states={states}
            resetForm={resetForm}
            isEdit={index}
            permissions={permissions}
            districtList={districtsList}
            talukaList={talukasList}
            villageList={villagesList}
            setIsEmp={setIsEmp}
            setStateList={setStateList}
          />
        )}

        <ModalButtons
          onPrimaryBtnClick={handleSubmit}
          primaryBtnTitle={getTitles('T-10339')}
          disabled={!isValid || !dirty || btnDisabled}
          containerStyle={globalStyles.mt(3)}
        />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {permissionData || additional ? (
        <ViewPermissions
          employeeData={permissionData}
          additional={additional}
          stateList={states}
          districtList={districtsList}
          talukaList={talukasList}
          villageList={villagesList}
          setDistrictsLists={setDistrictsLists}
          setVillagesList={setVillagesList}
          setTalukasList={setTalukasList}
          values={values}
          setValues={setValues}
          handleTitleClick={handleTitleClick}
          currEmp={selectedPerm}
          index={index}
        />
      ) : (
        <NoData message={getTitles('T-11061')} height="30vh" />
      )}
    </Box>
  );
};
