import { put, takeLatest } from 'redux-saga/effects';
import { KEYS } from '../../../constants';
import {
  addlabInchargeCattleReportFailure,
  addlabInchargeCattleReportRequest,
  addlabInchargeCattleReportSuccess,
  getlabFarmerFailure,
  getlabFarmersCattleFailure,
  getlabFarmersCattleRequest,
  getlabFarmersCattleSuccess,
  getlabFarmersRequest,
  getlabFarmerSuccess,
  getlabInchargeCattleDetailsFailure,
  getlabInchargeCattleDetailsRequest,
  getlabInchargeCattleDetailsSuccess,
  getlabInchargeCattleReportFailure,
  getlabInchargeCattleReportRequest,
  getlabInchargeCattleReportSuccess,
  updatelabInchargeCattleReportFailure,
  updatelabInchargeCattleReportRequest,
  updatelabInchargeCattleReportSuccess
} from '../slices';
import {
  addLabFarmersCattleReportApi,
  getLabFarmersApi,
  getLabFarmersCattleDetailsApi,
  getLabFarmersCattleReportApi,
  getLabFarmersCattlesApi,
  updateLabFarmersCattleReportApi
} from '../../api';

function* getLabFarmers(action) {
  try {
    const response = yield getLabFarmersApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getlabFarmerSuccess(response.data));
    } else {
      yield put(getlabFarmerFailure(response.data));
    }
  } catch (error) {
    yield put(getlabFarmerFailure(error.response.data));
  }
}

function* getLabFarmersCattles(action) {
  try {
    const response = yield getLabFarmersCattlesApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getlabFarmersCattleSuccess(response.data));
    } else {
      yield put(getlabFarmersCattleFailure(response.data));
    }
  } catch (error) {
    yield put(getlabFarmersCattleFailure(error.response.data));
  }
}

function* getLabFarmersCattleDetails(action) {
  try {
    const response = yield getLabFarmersCattleDetailsApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getlabInchargeCattleDetailsSuccess(response.data));
    } else {
      yield put(getlabInchargeCattleDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getlabInchargeCattleDetailsFailure(error.response.data));
  }
}

function* getLabFarmersCattleReport(action) {
  try {
    const response = yield getLabFarmersCattleReportApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getlabInchargeCattleReportSuccess(response.data));
    } else {
      yield put(getlabInchargeCattleReportFailure(response.data));
    }
  } catch (error) {
    yield put(getlabInchargeCattleReportFailure(error.response.data));
  }
}

function* addLabFarmersCattleReport(action) {
  try {
    const response = yield addLabFarmersCattleReportApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addlabInchargeCattleReportSuccess(response.data));
    } else {
      yield put(addlabInchargeCattleReportFailure(response.data));
    }
  } catch (error) {
    yield put(addlabInchargeCattleReportFailure(error.response.data));
  }
}

function* updateLabFarmersCattleReport(action) {
  try {
    const response = yield updateLabFarmersCattleReportApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updatelabInchargeCattleReportSuccess(response.data));
    } else {
      yield put(updatelabInchargeCattleReportFailure(response.data));
    }
  } catch (error) {
    yield put(updatelabInchargeCattleReportFailure(error.response.data));
  }
}

export function* labFarmerSaga() {
  yield takeLatest(getlabFarmersRequest.type, getLabFarmers);
  yield takeLatest(getlabFarmersCattleRequest.type, getLabFarmersCattles);
  yield takeLatest(getlabInchargeCattleDetailsRequest.type, getLabFarmersCattleDetails);
  yield takeLatest(getlabInchargeCattleReportRequest.type, getLabFarmersCattleReport);
  yield takeLatest(addlabInchargeCattleReportRequest.type, addLabFarmersCattleReport);
  yield takeLatest(updatelabInchargeCattleReportRequest.type, updateLabFarmersCattleReport);
}
