import { defaultValues } from '../../constants';
import { color } from '../../theme';

const isRes = defaultValues.isResponsive;
export const Styles = {
  secOneContainer: { p: isRes ? 1 : 5, boxSizing: 'border-box', width: isRes ? '100%' : '65%' },
  secOneSubContainer: { display: 'flex', justifyContent: 'space-between' },
  halfField: { width: 'calc(50% - 5px)' },
  secSecondContainer: { py: isRes ? 1 : 5, px: isRes ? 1 : '120px' },
  secSecondSubContainer: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)',
      md: 'repeat(3, 1fr)',
      rowGap: '20px',
      columnGap: '30px',
      width: '80%'
    }
  },
  thirdSecContainer: { p: isRes ? 1 : 5 },
  thirdSecField: { width: isRes ? '100%' : 'calc(65% - 5px)' },
  text: { marginY: '12px' },
  divider: { width: isRes ? '100%' : '65%' },
  datePickerStyle: {
    width: isRes ? '100%' : '65%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  commonCont: { width: isRes ? '100%' : '65%' },
  commonFlex: { display: 'flex', justifyContent: 'space-between' },
  lastDate: { width: isRes ? 'calc(50% - 5px)' : 'calc(30% - 5px)' },
  thirdSecHalfField: { width: 'calc(49% - 5px)' },
  dietTableIcon: {
    width: '52px',
    height: '52px',
    borderRadius: '5px',
    backgroundColor: color.primary,
    minWidth: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white', // Set icon color to white
    mr: 1,
    '&:hover': {
      backgroundColor: color.primary, // Keep the same background color on hover
      color: 'white' // Ensure the icon color stays white on hover
    }
  },
  dietCont: {
    width: '100%',
    backgroundColor: color.primaryBackground,
    borderRadius: '5px',
    display: 'flex',
    boxSizing: 'border-box',
    height: '56px',
    p: 1,
    mb: 1,
    alignItems: 'center'
  },
  dietInnerCont: {
    display: 'flex',
    width: '90%',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '5px',
    border: `1px solid ${color.primaryBorder}`
  },
  ninty: { width: '90%' },
  rowValue: {
    border: `1px solid transparent`,
    gap: '10px'
  },
  gap: { gap: '10px' },
  Pzeo: { padding: '0px' },
  vieDat: { flex: 1, p: 2 },
  iconBtnCont: { display: 'flex', alignItems: 'center', ml: 1 },
  additionalDCont: { p: isRes ? 1 : 5 },
  additionalDSubCont: { minHeight: isRes ? '60vh' : '45vh' },
  textCont: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  additionalDataCont: {
    height: '60px',
    my: 1,
    borderRadius: 1,
    backgroundColor: color.secondaryBorder,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  saBtn: { width: '100px', height: '40px', my: 1 },
  dd: { Width: '100%' },
  subC: {
    display: 'flex',
    height: '65px',
    backgroundColor: color.palette.paleYellow,
    borderRadius: '5px',
    alignItems: 'center',
    boxSizing: 'border-box',
    mt: 2,
    mb: 1
  },
  flex: { flex: 2, p: 1 }
};
