import { Box } from '@mui/material';
import { styles } from './styles';
import { getFormatDate, getTitles } from '../../../utils';
import { CollapseDropView, NoData, Text, UIDataTable, UILayout, ViewData } from '../../../ui-kits';
import { defaultValues, getErrorMessage, SCREEN_PATH } from '../../../constants';
import { useEffect, useState } from 'react';
import { globalStyles } from '../../../theme';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCallCenterSuccessMsg, getCallerDetails } from '../../../services/redux/selectors';
import { clearCattleData, getCallAgentCallerDetailsRequest } from '../../../services/redux/slices';

export const CallerDetails = ({ caller }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const details = useSelector(getCallerDetails);
  const success = useSelector(getCallCenterSuccessMsg);

  const [index, setIndex] = useState(null);

  const { member, employee } = defaultValues.callCenterUserTypes;

  useEffect(() => {
    if (success) {
      if (['S-10101', 'S-10098', 'S-10097'].includes(success))
        dispatch(getCallAgentCallerDetailsRequest({ caller_user_id: caller.user_id }));
    }
  }, [success]);

  const handleSubTitleClick = (idx) => {
    if (index === idx) {
      return setIndex(null);
    }
    setIndex(idx);
  };

  const handlePagination = (e, val) => {
    dispatch(getCallAgentCallerDetailsRequest({ caller_user_id: caller.user_id, page: val }));
  };

  const addressData = [
    caller?.user_type?.id === member && {
      title: getTitles('T-11162'),
      value: details?.farmer_id
    },
    {
      title: getTitles('T-10363'),
      value: 'India'
    },
    {
      title: getTitles('T-10070'),
      value: details?.address?.state
    },
    {
      title: getTitles('T-10069'),
      value: details?.address?.district
    },
    {
      title: getTitles('T-10071'),
      value: details?.address?.taluka
    },
    {
      title: getTitles('T-10068'),
      value: details?.address?.village
    },
    {
      title: getTitles('T-10359'),
      value: details?.address?.pincode
    }
  ];

  const assignedLocationData = [
    {
      title: getTitles('T-10070'),
      value: 'states'
    },
    {
      title: getTitles('T-10069'),
      value: 'districts'
    },
    {
      title: getTitles('T-10071'),
      value: 'talukas'
    },
    {
      title: getTitles('T-10068'),
      value: 'villages'
    }
  ];

  const historyDataModel = (item, idx) => {
    return [
      {
        title: getTitles('T-11407'),
        values: `${idx + 1}`,
        valueTextStyle: { pl: 4 },
        headerTextStyle: { pl: 2 }
      },
      {
        title: getTitles('T-11264'),
        values: item.task_name
      },

      {
        title: getTitles('T-11248'),
        values: item.task_type
      },

      {
        title: getTitles('T-11251'),
        values: item.due_date && getFormatDate(item.due_date)
      }
    ];
  };

  const historyData =
    details?.task_history?.tasks?.length > 0 &&
    details?.task_history?.tasks.map((item, idx) => historyDataModel(item, idx));

  const cattleDetails =
    details?.cattles?.length > 0 &&
    details?.cattles?.map((item, idx) => {
      return [
        {
          title: getTitles('T-11407'),
          values: `${idx + 1}`,
          valueTextStyle: { pl: 3 },
          headerTextStyle: { pl: 1 },
          columnWidth: '15%'
        },
        {
          title: getTitles('T-11408'),
          values: item.cattle_id
        },

        {
          title: getTitles('T-10984'),
          values: item.cattle_name
        },

        {
          title: getTitles('T-10192'),
          values: item.cattle_type
        },
        {
          title: getTitles('T-11422'),
          values: (
            <Text
              style={globalStyles.title}
              onClick={() => {
                dispatch(clearCattleData());
                navigate(SCREEN_PATH.CATTLE_TASK_DETAILS_CALL_CENTER, {
                  state: { cattle_id: item._id, name: item.cattle_name }
                });
              }}>
              {getTitles('T-10659')}
            </Text>
          )
        }
      ];
    });
  cattleDetails;
  return (
    <Box sx={styles.container}>
      <Box sx={styles.subContainer}>
        <Text style={styles.text} variant="BoldH24">
          {getTitles('T-11409')}
        </Text>
        <Box sx={styles.subCont}>
          <Box sx={styles.leftCont}>
            <Text style={styles.textSubHead} variant="BoldH24">
              {caller?.user_type?.id === employee
                ? getTitles('T-11077')
                : caller?.user_type?.id === member && getTitles('T-11229')}
            </Text>
            <Box sx={styles.addressSubCont}>
              {addressData.map(
                (item, idx) =>
                  item && (
                    <ViewData
                      key={idx}
                      title={item.title}
                      subTitle={item.value}
                      titleVariant="BoldH20"
                      subTitleVariant="D20"
                      titleStyle={styles.viewDataTitle}
                      subTitleCont={styles.viewDataSubTitle}
                    />
                  )
              )}
            </Box>
          </Box>
          <Box sx={styles.rightCont}>
            <Text style={styles.textSubHead} variant="BoldH24">
              {caller?.user_type?.id === employee
                ? getTitles('T-10447')
                : caller?.user_type?.id === member && getTitles('T-10981')}
            </Text>
            {caller?.user_type?.id === employee ? (
              <Box sx={styles.addressSubCont}>
                <ViewData
                  title={getTitles('T-11115')}
                  subTitle={details?.employee_id}
                  titleVariant="BoldH20"
                  subTitleVariant="D20"
                  titleStyle={styles.viewDataTitle}
                  subTitleCont={styles.viewDataSubTitle}
                />
                <Text variant="BoldH20">{getTitles('T-11413')}</Text>
                {details?.permissions?.length > 0 &&
                  details?.permissions.map((item, idx) => (
                    <Box key={idx}>
                      <CollapseDropView
                        title={`${idx + 1}. ${defaultValues.employeePermissions.find((el) => el.value === item.permissions_id?.id)?.title}`}
                        handleSubTitleClick={() => handleSubTitleClick(idx)}
                        open={idx === index}
                        titleVariant="BoldH20"
                        subTitleVariant="D20"
                        chevronStyle={styles.chevron}
                      />
                      {idx === index && (
                        <>
                          <Text variant="BoldH20">{getTitles('T-10456')}</Text>
                          {assignedLocationData?.map((ele, idx2) => (
                            <ViewData
                              key={idx2}
                              title={ele.title}
                              subTitle={item.assigned_location[ele.value]?.join(', ')}
                              titleVariant="BoldH20"
                              subTitleVariant="D20"
                              titleStyle={styles.viewData2Title}
                              subTitleCont={styles.viewDataSubTitle}
                            />
                          ))}
                        </>
                      )}
                    </Box>
                  ))}
              </Box>
            ) : (
              caller?.user_type?.id === member && (
                <Box sx={styles.subCont}>
                  {cattleDetails?.length > 0 ? (
                    <UIDataTable tableRows={cattleDetails} />
                  ) : (
                    <NoData message={getErrorMessage('E-10155')} height="200px" />
                  )}
                </Box>
              )
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={styles.subContainer}>
        <Text style={{ ...styles.text, borderRadius: 0 }} variant="BoldH24">
          {getTitles('T-11414')}
        </Text>
        <UILayout
          tableItems={historyData}
          containerStyles={styles.layoutCont}
          showNoData={!historyData.length > 0}
          currPage={details?.task_history?.current_page}
          pageCount={details?.task_history?.total_page}
          handlePagination={handlePagination}
          showPagination={historyData.length > 0 && details?.task_history?.total_page > 1}
          subContStyles={styles.layoutSubCont}
          optionStyles={styles.optionStyles}
          message={getErrorMessage('E-10194')}
        />
      </Box>
    </Box>
  );
};
