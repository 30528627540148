import { defaultValues } from '../../constants';
import { color } from '../../theme';

const res = defaultValues.isResponsive;
export const styles = {
  id: {
    textOverflow: 'ellipsis',
    width: '100%',
    overflowWrap: 'break-word'
  },
  profileImg: {
    width: res ? '32px' : '36px',
    height: res ? '32px' : '36px'
  },
  columnWidth: '20%',
  assign: {
    padding: res ? '0px' : '5px 20px',
    boxSizing: 'border-box',
    height: res ? '15px' : '25px',
    cursor: 'pointer'
  },
  cattleList: {
    color: color.link,
    textDecoration: 'underline',
    cursor: 'pointer',
    overflowWrap: 'anywhere'
  }
};
