export * from './common/header';
export * from './common/footer';
export * from './common/modal-buttons';
export * from './common/save&add-another';
export * from './common/utility-components';
export * from './common/cards';

export * from './manage-state/manage-taluk';
export * from './manage-state/manage-district';
export * from './manage-state/manage-village';
export * from './manage-admin/user-role';
export * from './manage-admin/admin-profile-with-name';
export * from './common/cards/chip-card-checkbox';
export * from './manage-masterlist';
export * from './HOC/employee';
export * from './HOC/society';
export * from './farmer-registration/fodder-details';
export * from './farmer-registration/identification-details';
export * from './farmer-registration/land-details';
export * from './farmer-registration/personal-details';
export * from './farmer-registration/production-details';
export * from './farmer-registration/responsive-table-view';
export * from './HOC/village';

export * from './register-cattle/additonal-details';
export * from './register-cattle/upload-photos';
export * from './register-cattle/cattle-details';
export * from './register-cattle/cattle-description';
export * from './register-cattle/report-table';
export * from './register-cattle/view-muiti-data';
export * from './call-center';
export * from './manage-profile';
export * from './insurance-dashboard';
export * from './insurance-farmar-table';
export * from './insurance-cattle-table';
export * from './insurace-cattle-details';
export * from './HOC/inventory';
export * from './HOC';
