import { useEffect, useState } from 'react';
import { getErrorMessage, SCREEN_PATH } from '../../constants';
import { Screen, SearchBar, UIButton } from '../../ui-kits';
import { UIContainer } from '../../ui-kits/container';
import { getTitles, resetMessages } from '../../utils';
import { Box } from '@mui/material';
import personSearchImg from '../../assets/icons/person_search.png';
import searchIconImag from '../../assets/icons/searchIcon.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInsuranceFarmerRequest, resetInsuranceMsg } from '../../services/redux/slices';
import {
  getFarmerInfoErrorMsg,
  getFarmerInfoSuccessMsg,
  getInsuranceFarmerLoading
} from '../../services/redux/selectors';
import { toast } from 'react-toastify';
import { styles } from './style';

export const ManageInsuranceDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const success = useSelector(getFarmerInfoSuccessMsg);
  const error = useSelector(getFarmerInfoErrorMsg);
  const isLoading = useSelector(getInsuranceFarmerLoading);

  const [searchedTxt, setSearchedTxt] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    searchedTxt.length >= 2 ? setButtonDisable(false) : setButtonDisable(true);
  }, [searchedTxt]);

  useEffect(() => {
    if (success === 'S-10043') {
      navigate(SCREEN_PATH.MANAGE_INSURANCE_FARMERS_INFO);
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }
    resetMessages(() => dispatch(resetInsuranceMsg()));
  }, [success, error]);

  const handleClick = () => {
    dispatch(getInsuranceFarmerRequest({ search_text: searchedTxt }));
  };
  return (
    <>
      <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-10037')}>
        <UIContainer styles={styles.container}>
          <Box component="img" sx={styles.img} alt="Person_Search_Img" src={personSearchImg} />
          <SearchBar
            searchedVlaue={(inputValue) => setSearchedTxt(inputValue)}
            style={styles.searchBar}
            placeHolder={getTitles('T-11404')}
            containerStyles={styles.searchCont}
          />
          <UIButton
            size={'large'}
            title={getTitles('T-10077')}
            style={styles.searchButton}
            disabled={buttonDisable}
            startIcon={
              <Box component="img" sx={styles.searchIcon} alt="Search_Icon" src={searchIconImag} />
            }
            onClick={handleClick}
          />
        </UIContainer>
      </Screen>
    </>
  );
};
