import { Box } from '@mui/material';
import { CollapseDropView, ViewData } from '../../../../../ui-kits';
import { getFormatDate, getTitles } from '../../../../../utils';
import { defaultValues } from '../../../../../constants';

const styles = {
  container: {
    width: '100%'
  }
};
export const ViewPreviousEmployment = ({ employeeData, handleSubTitleClick, currEmp, index }) => {
  const getName = (arr, checkVal) => {
    const newVal = arr && arr.length > 0 && arr.find((item) => item.value == checkVal);
    return newVal ? newVal.title : '';
  };

  return (
    <Box sx={styles.container}>
      {employeeData &&
        employeeData.length > 0 &&
        employeeData.map((item, idx) => (
          <Box key={idx}>
            <CollapseDropView
              title={item.employer_name}
              handleSubTitleClick={() => handleSubTitleClick(item, idx)}
              open={index === idx}
            />
            {currEmp && idx === index && (
              <Box sx={{ marginTop: '10px' }}>
                <ViewData title={getTitles('T-10443')} subTitle={currEmp.employer_mobile_number} />
                <ViewData
                  title={getTitles('T-10442')}
                  subTitle={`${currEmp.address_line_one} ${currEmp.address_line_two}`}
                />
                <ViewData title={getTitles('T-10363')} subTitle={currEmp.country_id} />
                <ViewData title={getTitles('T-10070')} subTitle={currEmp?.state_id?.name} />
                <ViewData title={getTitles('T-10069')} subTitle={currEmp?.district_id?.name} />
                <ViewData title={getTitles('T-10071')} subTitle={currEmp?.taluka_id?.name} />
                <ViewData title={getTitles('T-10068')} subTitle={currEmp?.village_id?.name} />
                <ViewData title={getTitles('T-10359')} subTitle={currEmp.post_code} />
                <ViewData
                  title={getTitles('T-10378')}
                  subTitle={currEmp.date_joining && getFormatDate(currEmp.date_joining)}
                />
                <ViewData
                  title={getTitles('T-10379')}
                  subTitle={currEmp.date_relieving && getFormatDate(currEmp.date_relieving)}
                />
                <ViewData title={getTitles('T-10382')} subTitle={currEmp.last_salary} />
                <ViewData title={getTitles('T-10050')} subTitle={currEmp.designation} />
                <ViewData
                  title={getTitles('T-10386')}
                  subTitle={getName(defaultValues.modeOfEmployment, currEmp.mode_employment)}
                />
                <ViewData
                  title={getTitles('T-10387')}
                  subTitle={getName(defaultValues.employmentSector, currEmp.employment_sector)}
                />
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
};
