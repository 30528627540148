export const palette = {
  darkGreen: '#00703F',
  lightIvory: '#FFFEFA',
  darkGray: '#323338',
  mossGreen: 'rgba(0, 112, 63, 0.3)',
  grayishBlue: '#F3F6F8',
  darkBrown: '#6F3D2A',
  steelGray: '#676879',
  lightGray: 'rgba(103, 104, 121, 0.8)',
  white: '#FFFFFF',
  sunflowerYellow: '#F7CE26',
  paleYellow: 'rgba(247, 206, 38, 0.2)',
  paleIvory: 'rgba(255, 254, 250, 0.5)',
  brightRed: '#E52E2E',
  blueGray: '#C5C7D0',
  darkerBlueGray: '#E6E9EF',
  royalBlue: '#1F76C2',
  green: '#5CB85C',
  yellow: '#FFCB00',
  blue: '#0073EA',
  pearGreen: '#D1DB61',
  limeGreen: '#82C448',
  lightGreen: 'rgba(219, 254, 219, 1)',
  transparentBlack: 'rgba(0, 0, 0, 0.7)',
  lightGoldenrodYellow: 'rgba(251, 230, 144, 1)',
  orange: 'rgba(250, 130, 50, 1)',
  violet: 'background: rgba(168, 45, 243, 1)'
};
