import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { defaultValues } from '../../../../constants';
import { getTitles, handleMultiDelete } from '../../../../utils';
import {
  Text,
  UIChipButton,
  UIMultipleSelectCheckmarks,
  UIRadioButton,
  UITextInputWithTitle,
  UITitleWithContent
} from '../../../../ui-kits';
import { globalStyles } from '../../../../theme';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addVillageRequest,
  updateDetailsPg,
  updateScrollToTop,
  updateVillageCurrPage,
  updateVillageRequest
} from '../../../../services/redux/slices';
import { ModalButtons } from '../../../common/modal-buttons';
import { FormTitleCard } from '../../../common/cards';
import { getScroll } from '../../../../services/redux/selectors';
import { getSuccessMsg } from '../../../../services/redux/selectors/manageProccess';

const res = defaultValues.isResponsive;

const styles = {
  container: {
    width: '100%'
  },

  width: res ? '100%' : 'calc((100% - 10px)/3)',
  phone: {
    width: res ? '100%' : 'calc((100% + 5px)/3 * 2)',
    maxWidth: 'none'
  },
  sweetMart: {
    width: res ? 'calc(50% - 5px)' : 'calc((100% + 5px)/3 * 2)'
  },
  nearestKm: {
    width: defaultValues.isMedium ? 'calc(50% - 5px)' : 'calc((100% + 5px)/3 * 2)'
  },
  radioSubCont: {
    width: defaultValues.isResponsive ? '30%' : 'calc((100% + 5px)/3)',
    display: 'flex',
    gap: '10px'
  },

  naturalCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  }
};

export const VillageAdditionalDetailsForm = ({
  currRender,
  villageData,
  id,
  villageFullData,
  onNext,
  villageId,
  onPrev,
  sourceOfWater,
  sourceOfIrrigationWater,
  majorCrops
}) => {
  const dispatch = useDispatch();
  const scroll = useSelector(getScroll);
  const successMsg = useSelector(getSuccessMsg);

  const [btnDisabled, setBtndisabled] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const { add, edit, view } = defaultValues.actionType;

  const { values, setValues, setFieldValue } = useFormik({
    initialValues: {
      primary_health_care_center: null,
      nearest_health_care_center_distance: '',
      industry_gives_employment_oppurtunity: null,
      industry_name: '',
      number_of_gobar_gas_plant: '',
      nearest_town: '',
      nearest_town_distance: '',
      nearest_apmc_yard_distance: '',
      nearest_weekly_market_distance: '',
      number_of_tea_stall: '',
      number_of_sweet_mart: '',
      major_crop_grown: [],
      drinking_water: null,
      source_of_water: [],
      source_of_irrigation_water: [],
      primary_school: null,
      secondary_school: null,
      pre_university: null,
      degree: null,
      post_graduation: null
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  const getFullObject = (arr1, arr2) => {
    if (arr1 && arr1.length > 0) {
      return arr2
        .filter((item) => arr1.includes(item.value))
        .map((ele) => ({ value: ele.value, title: ele.title }));
    }
    return [];
  };

  useEffect(() => {
    if (['S-10067', 'S-10055'].includes(successMsg) && isFinished) {
      onNext();
      dispatch(updateScrollToTop(!scroll));
      dispatch(updateDetailsPg(false));
      dispatch(updateVillageCurrPage(1));
    }
  }, [successMsg]);

  useEffect(() => {
    if (villageData) {
      let newValues = { ...villageData };
      if (villageData.major_crop_grown && villageData.major_crop_grown.length > 0) {
        newValues = {
          ...newValues,
          major_crop_grown: getFullObject(
            villageData.major_crop_grown,
            majorCrops.map((item) => ({ value: item._id, title: item.name }))
          )
        };
      }

      if (villageData.source_of_water && villageData.source_of_water.length > 0) {
        newValues = {
          ...newValues,
          source_of_water: getFullObject(
            villageData.source_of_water,
            sourceOfWater.map((item) => ({ value: item._id, title: item.name }))
          )
        };
      }

      if (
        villageData.source_of_irrigation_water &&
        villageData.source_of_irrigation_water.length > 0
      ) {
        newValues = {
          ...newValues,
          source_of_irrigation_water: getFullObject(
            villageData.source_of_irrigation_water,
            sourceOfIrrigationWater.map((item) => ({ value: item._id, title: item.name }))
          )
        };
      }
      setValues(newValues);
    }
  }, [villageData, sourceOfWater, sourceOfIrrigationWater, majorCrops]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (currRender !== view) {
      setFieldValue(name, value);
      setBtndisabled(false);
    }
  };
  const handleBackButton = () => {
    onPrev();
    dispatch(updateScrollToTop(!scroll));
  };

  const handleSubmit = () => {
    let payload = {
      step: defaultValues.villageRegisterSteps.additionalDetails,
      additional_details: {
        ...values,
        major_crop_grown:
          (values.major_crop_grown &&
            values.major_crop_grown.length > 0 &&
            values.major_crop_grown.map((item) => item.value)) ||
          [],
        source_of_water:
          (values.source_of_water &&
            values.source_of_water.length > 0 &&
            values.source_of_water.map((item) => item.value)) ||
          [],
        source_of_irrigation_water:
          (values.source_of_irrigation_water &&
            values.source_of_irrigation_water.length > 0 &&
            values.source_of_irrigation_water.map((item) => item.value)) ||
          []
      }
    };
    if (currRender === add) {
      payload = {
        ...payload,
        village_id: villageId,
        _id: id
      };
      dispatch(addVillageRequest(payload));
    }
    if (currRender === edit) {
      payload = {
        ...villageFullData,
        ...payload,
        village_id: villageId,
        step: defaultValues.villageRegisterSteps.additionalDetails,
        _id: id
      };
      dispatch(updateVillageRequest(payload));
    }
    if (currRender === view) {
      dispatch(updateScrollToTop(!scroll));
      dispatch(updateDetailsPg(false));
      dispatch(updateVillageCurrPage(1));
    }

    setIsFinished(true);
  };

  return (
    <Box sx={styles.container}>
      {defaultValues.isResponsive && <Text variant="BoldH22Sec">{getTitles('T-10997')}</Text>}
      <UITitleWithContent title={getTitles('T-10953')}>
        <Box sx={styles.radioSubCont}>
          <UIRadioButton
            title={getTitles('T-10705')}
            checked={values.primary_health_care_center}
            onClick={() =>
              handleChange({ target: { name: 'primary_health_care_center', value: true } })
            }
          />
          <UIRadioButton
            title={getTitles('T-10706')}
            checked={values.primary_health_care_center === false}
            onClick={() =>
              handleChange({ target: { name: 'primary_health_care_center', value: false } })
            }
          />
        </Box>
      </UITitleWithContent>

      <UITextInputWithTitle
        name="nearest_health_care_center_distance"
        title={getTitles('T-10954')}
        placeHolder={getTitles('T-10952')}
        value={values.nearest_health_care_center_distance}
        onChange={handleChange}
        containerStyles={styles.phone}
        disabled={currRender === view}
        allowOnlyNumbers={true}
      />

      <UITitleWithContent title={getTitles('T-10955')}>
        <Box sx={styles.radioSubCont}>
          <UIRadioButton
            title={getTitles('T-10705')}
            checked={values.industry_gives_employment_oppurtunity}
            onClick={() =>
              handleChange({
                target: { name: 'industry_gives_employment_oppurtunity', value: true }
              })
            }
          />
          <UIRadioButton
            title={getTitles('T-10706')}
            checked={values.industry_gives_employment_oppurtunity === false}
            onClick={() => {
              handleChange({
                target: { name: 'industry_gives_employment_oppurtunity', value: false }
              });
              currRender !== view && setFieldValue('industry_name', '');
            }}
          />
        </Box>
      </UITitleWithContent>

      {values.industry_gives_employment_oppurtunity && (
        <UITextInputWithTitle
          name="industry_name"
          title={getTitles('T-10956')}
          placeHolder={getTitles('T-10384')}
          value={values.industry_name}
          onChange={handleChange}
          containerStyles={styles.phone}
          disabled={currRender === view}
        />
      )}

      <UITextInputWithTitle
        name="number_of_gobar_gas_plant"
        title={getTitles('T-10957')}
        placeHolder={getTitles('T-10360')}
        value={values.number_of_gobar_gas_plant}
        onChange={handleChange}
        containerStyles={styles.phone}
        disabled={currRender === view}
        allowOnlyNumbers={true}
      />

      <Box sx={styles.phone}>
        <Box sx={styles.naturalCont}>
          <UITextInputWithTitle
            name="nearest_town"
            title={getTitles('T-10958')}
            placeHolder={getTitles('T-10975')}
            value={values.nearest_town}
            onChange={handleChange}
            containerStyles={styles.phone}
            disabled={currRender === view}
          />
          <UITextInputWithTitle
            name="nearest_town_distance"
            title={getTitles('T-10959')}
            placeHolder={getTitles('T-10976')}
            value={values.nearest_town_distance}
            onChange={handleChange}
            containerStyles={styles.phone}
            disabled={currRender === view}
            allowOnlyNumbers={true}
          />
        </Box>

        <Box sx={styles.naturalCont}>
          <UITextInputWithTitle
            name="nearest_apmc_yard_distance"
            title={getTitles('T-10960')}
            placeHolder={getTitles('T-10977')}
            value={values.nearest_apmc_yard_distance}
            onChange={handleChange}
            containerStyles={styles.nearestKm}
            titleStyle={globalStyles.text}
            disabled={currRender === view}
            allowOnlyNumbers={true}
          />
          <UITextInputWithTitle
            name="nearest_weekly_market_distance"
            title={getTitles('T-10961')}
            placeHolder={getTitles('T-10978')}
            value={values.nearest_weekly_market_distance}
            onChange={handleChange}
            containerStyles={styles.nearestKm}
            disabled={currRender === view}
            allowOnlyNumbers={true}
            titleStyle={globalStyles.text}
          />
        </Box>

        <Box sx={styles.naturalCont}>
          <UITextInputWithTitle
            name="number_of_tea_stall"
            title={getTitles('T-10962')}
            placeHolder={getTitles('T-10360')}
            value={values.number_of_tea_stall}
            onChange={handleChange}
            containerStyles={styles.sweetMart}
            titleStyle={globalStyles.text}
            disabled={currRender === view}
            allowOnlyNumbers={true}
          />
          <UITextInputWithTitle
            name="number_of_sweet_mart"
            title={getTitles('T-10963')}
            placeHolder={getTitles('T-10360')}
            value={values.number_of_sweet_mart}
            onChange={handleChange}
            containerStyles={{ ...styles.sweetMart }}
            titleStyle={globalStyles.text}
            disabled={currRender === view}
            allowOnlyNumbers={true}
          />
        </Box>
      </Box>
      <UIMultipleSelectCheckmarks
        name="major_crop_grown"
        value={values.major_crop_grown}
        title={getTitles('T-10964')}
        options={
          majorCrops &&
          majorCrops.length > 0 &&
          majorCrops.map((item) => ({ value: item._id, title: item.name }))
        }
        onChange={(val) => handleChange({ target: { name: 'major_crop_grown', value: val } })}
        placeholder={getTitles('T-10350')}
        containerStyles={styles.phone}
        preSelectedValue={values.major_crop_grown}
        disabled={currRender === view}
        showSelect={currRender !== view}
        showTitles={false}
      />

      {values.major_crop_grown?.length > 0 &&
        values.major_crop_grown.map((item, idx) => (
          <UIChipButton
            key={idx}
            title={item.title}
            onDelete={() => {
              setFieldValue('major_crop_grown', handleMultiDelete(idx, values.major_crop_grown));
              setBtndisabled(false);
            }}
            showDelete={currRender !== view}
          />
        ))}

      <FormTitleCard title={getTitles('T-10965')}>
        <UITitleWithContent title={getTitles('T-10966')}>
          <Box sx={styles.radioSubCont}>
            <UIRadioButton
              title={getTitles('T-10705')}
              checked={values.drinking_water}
              onClick={() => handleChange({ target: { name: 'drinking_water', value: true } })}
            />
            <UIRadioButton
              title={getTitles('T-10706')}
              checked={values.drinking_water === false}
              onClick={() => handleChange({ target: { name: 'drinking_water', value: false } })}
            />
          </Box>
        </UITitleWithContent>

        <UIMultipleSelectCheckmarks
          name="source_of_water"
          value={values.source_of_water}
          title={getTitles('T-10967')}
          options={
            sourceOfWater &&
            sourceOfWater.length > 0 &&
            sourceOfWater.map((item) => ({ value: item._id, title: item.name }))
          }
          onChange={(val) => handleChange({ target: { name: 'source_of_water', value: val } })}
          placeholder={getTitles('T-10350')}
          containerStyles={styles.phone}
          preSelectedValue={values.source_of_water}
          disabled={currRender === view}
          showSelect={currRender !== view}
          showTitles={false}
        />

        {values.source_of_water?.length > 0 &&
          values.source_of_water.map((item, idx) => (
            <UIChipButton
              key={idx}
              title={item.title}
              onDelete={() => {
                setFieldValue('source_of_water', handleMultiDelete(idx, values.source_of_water));
                setBtndisabled(false);
              }}
              showDelete={currRender !== view}
            />
          ))}

        <UIMultipleSelectCheckmarks
          name="source_of_irrigation_water"
          preSelectedValue={values.source_of_irrigation_water}
          title={getTitles('T-10968')}
          options={
            sourceOfIrrigationWater &&
            sourceOfIrrigationWater.length > 0 &&
            sourceOfIrrigationWater.map((item) => ({ value: item._id, title: item.name }))
          }
          onChange={(val) =>
            handleChange({ target: { name: 'source_of_irrigation_water', value: val } })
          }
          placeholder={getTitles('T-10350')}
          containerStyles={styles.phone}
          disabled={currRender === view}
          showSelect={currRender !== view}
          showTitles={false}
        />

        {values.source_of_irrigation_water?.length > 0 &&
          values.source_of_irrigation_water.map((item, idx) => (
            <UIChipButton
              key={idx}
              title={item.title}
              onDelete={() => {
                setFieldValue(
                  'source_of_irrigation_water',
                  handleMultiDelete(idx, values.source_of_irrigation_water)
                );
                setBtndisabled(false);
              }}
              showDelete={currRender !== view}
            />
          ))}
      </FormTitleCard>

      <FormTitleCard title={getTitles('T-10969')}>
        <UITitleWithContent title={getTitles('T-10970')}>
          <Box sx={styles.radioSubCont}>
            <UIRadioButton
              title={getTitles('T-10705')}
              checked={values.primary_school}
              onClick={() => handleChange({ target: { name: 'primary_school', value: true } })}
            />
            <UIRadioButton
              title={getTitles('T-10706')}
              checked={values.primary_school === false}
              onClick={() => handleChange({ target: { name: 'primary_school', value: false } })}
            />
          </Box>
        </UITitleWithContent>

        <UITitleWithContent title={getTitles('T-10971')}>
          <Box sx={styles.radioSubCont}>
            <UIRadioButton
              title={getTitles('T-10705')}
              checked={values.secondary_school}
              onClick={() => handleChange({ target: { name: 'secondary_school', value: true } })}
            />
            <UIRadioButton
              title={getTitles('T-10706')}
              checked={values.secondary_school === false}
              onClick={() => handleChange({ target: { name: 'secondary_school', value: false } })}
            />
          </Box>
        </UITitleWithContent>

        <UITitleWithContent title={getTitles('T-10972')}>
          <Box sx={styles.radioSubCont}>
            <UIRadioButton
              title={getTitles('T-10705')}
              checked={values.pre_university}
              onClick={() => handleChange({ target: { name: 'pre_university', value: true } })}
            />
            <UIRadioButton
              title={getTitles('T-10706')}
              checked={values.pre_university === false}
              onClick={() => handleChange({ target: { name: 'pre_university', value: false } })}
            />
          </Box>
        </UITitleWithContent>

        <UITitleWithContent title={getTitles('T-10973')}>
          <Box sx={styles.radioSubCont}>
            <UIRadioButton
              title={getTitles('T-10705')}
              checked={values.degree}
              onClick={() => handleChange({ target: { name: 'degree', value: true } })}
            />
            <UIRadioButton
              title={getTitles('T-10706')}
              checked={values.degree === false}
              onClick={() => handleChange({ target: { name: 'degree', value: false } })}
            />
          </Box>
        </UITitleWithContent>

        <UITitleWithContent title={getTitles('T-10974')}>
          <Box sx={styles.radioSubCont}>
            <UIRadioButton
              title={getTitles('T-10705')}
              checked={values.post_graduation}
              onClick={() => handleChange({ target: { name: 'post_graduation', value: true } })}
            />
            <UIRadioButton
              title={getTitles('T-10706')}
              checked={values.post_graduation === false}
              onClick={() => handleChange({ target: { name: 'post_graduation', value: false } })}
            />
          </Box>
        </UITitleWithContent>
      </FormTitleCard>

      <ModalButtons
        secondaryBtnTitle={getTitles('T-10340')}
        onSecondaryBtnClick={handleBackButton}
        onPrimaryBtnClick={handleSubmit}
        primaryBtnTitle={
          currRender === add
            ? getTitles('T-10063')
            : currRender === edit
              ? getTitles('T-11736')
              : getTitles('T-11103')
        }
        disabled={currRender !== view && btnDisabled}
        containerStyle={globalStyles.mt(3)}
      />
    </Box>
  );
};
