import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import titles from '../constants/titles.json';
import { KEYS } from '../constants/keys';
import { defaultValues } from '../constants';
import CryptoJS from 'crypto-js';

dayjs.extend(utc);

// we cannot add language name in default values we are importing getTitles function in default values
export const language_name = {
  english: 'english',
  hindi: 'hindi',
  kannada: 'kannada',
  marathi: 'marathi'
};

export function getTitles(code, varNameArr) {
  const currentLang = localStorage.getItem(KEYS.LANGUAGE) || language_name.english;

  const header = titles[code] && titles[code].titles[currentLang];
  if (header && header.indexOf('<') !== -1) {
    let updatedMsg = header;

    if (varNameArr) {
      varNameArr.map((val, index) => {
        updatedMsg = updatedMsg.replace(
          '<' + parseInt(parseInt(index, 10) + 1, 10) + '>',

          val
        );
      });
    }
    return updatedMsg;
  } else if (header) {
    return header;
  } else {
    return 'XXX';
  }
}

export const getApplicationLanguage = () => {
  const ele = defaultValues.appLanguages.filter(
    (item) => item.value === localStorage.getItem(defaultValues.appLogicalTexts.language)
  );

  return ele[0] || defaultValues.appLanguages[0];
};

export const getFormatDate = (value) => {
  return dayjs(value).format('DD-MM-YYYY');
};

export const getFormatUtcDate = (value) => {
  return dayjs(value).utc().format('DD-MM-YYYY');
};

export const formatDateTime = (dateInput) => {
  const inputDate = new Date(dateInput);
  const today = new Date();

  const isToday = today.toDateString() === inputDate.toDateString();

  const format12HourClock = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${formattedMinutes} ${ampm}`;
  };

  if (isToday) {
    return format12HourClock(inputDate);
  } else {
    const datePart = inputDate.toLocaleDateString();
    const timePart = format12HourClock(inputDate);
    return `${datePart}, ${timePart}`;
  }
};

export const resetMessages = (cb) => {
  setTimeout(() => {
    cb();
  }, 1000);
};

export const getTitleWithId = (arr, id) => {
  const role = arr.find((role) => role.value === id);
  return role ? role.title : null;
};

export const getNameWithIdForEnum = (arr, id) => {
  const role = arr.find((role) => role.id === id);
  return role ? role.name : null;
};

export const removeFalsyValues = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      // eslint-disable-next-line no-unused-vars
      ([key, value]) => value !== '' && value !== null && value !== undefined
    )
  );
};

export const getUniqueAndSortedData = (data) => {
  const uniqueData = data.filter(
    (item, index, self) => index === self.findIndex((d) => d._id === item._id)
  );

  // Sort alphabetically by name
  uniqueData.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return uniqueData;
};

export const checkReadOnly = (userData, role) => {
  switch (userData.user_role) {
    case defaultValues.userRole.superAdmin: // if role is super admin
      return false;
    case defaultValues.userRole.employee: {
      const menuData = userData.employee_permissions.filter(({ permission_id }) => {
        if (typeof role === 'number') {
          return permission_id === role;
        } else if (Array.isArray(role)) {
          return role.includes(permission_id);
        }
      });
      return menuData?.some((item) => item.read_only);
    }
  }
};

export const convertStringsToBoolean = (arr) => {
  return arr.map((obj) => {
    // Create a new object to avoid mutating the original
    const newObj = { ...obj };

    return newObj;
  });
};

//  to  get ids of the value which is in an array
export function getIdsByNames(namesArray, objectsArray) {
  return namesArray.map((name) => {
    const found = objectsArray.find((obj) => obj.name === name);
    return found ? found._id : null; // Return null if no match is found
  });
}

export function getNamesByIds(idsArray, objectsArray) {
  return idsArray.map((id) => {
    const found = objectsArray.find((obj) => obj._id === id);
    return found ? found.name : null; // Return null if no match is found
  });
}

export function getIsOther(idsArray, objectsArray) {
  return idsArray.map((id) => {
    const found = objectsArray.find((obj) => obj._id === id?._id);
    return found ? found.name : null; // Return null if no match is found
  });
}

// to get the id of enum values with title multi select
export function getIdsByTitle(namesArray, objectsArray) {
  return namesArray.map((name) => {
    const found = objectsArray.find((obj) => obj.title === name);
    return found ? found.value : null; // Return null if no match is found
  });
}

export function getTitlesByValue(namesArray, objectsArray) {
  return (
    namesArray &&
    namesArray.map((val) => {
      const found = objectsArray.find((obj) => obj.value === val);
      return found ? found.title : null; // Return null if no match is found
    })
  );
}

export const mergeLabAndReportData = (labReport, reportData) => {
  // Create a copy of the labReport to avoid modifying the original
  const mergedReport = JSON.parse(JSON.stringify(labReport));

  // Iterate through each section in the reportData
  reportData.sections.forEach((reportSection) => {
    // Find the corresponding section in the labReport
    const labSection = mergedReport.sections.find(
      (labSec) => labSec._id === reportSection._id?._id
    );

    if (labSection) {
      // Iterate through each test field in the reportSection
      reportSection.test_fields.forEach((reportField) => {
        // Find the corresponding field in the labSection
        const labField = labSection.test_fields.find(
          (labFld) => labFld._id === reportField._id?._id
        );

        if (labField) {
          // Add the sample_value to the labField
          labField.sample_value = reportField.sample_value;
        }
      });
    }
  });

  return mergedReport;
};

export const getMasterListItems = (masterLists, idNo) => {
  const item = masterLists.find((item) => item.master_id === idNo);
  return item ? item.data : [];
};

export function formatDates(dateObj) {
  // Convert the M type date object to a standard Date object
  const date = new Date(dateObj.$d);
  // Use toLocaleDateString with a custom format string
  // DD/MM/YYYY will give you the date in the format you requested
  return date
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
    .replace(/\//g, '/');
}

export const handleMultiDelete = (index, parentArr) => {
  let arr = [...parentArr];
  const arr2 = arr.filter((_, idx) => idx !== index);
  return arr2?.length > 0 ? arr2 : [];
};

export const calculateBodyWeight = (length, girth) => {
  const parsedLength = parseFloat(length); // BL
  const parsedGirth = parseFloat(girth); // CC

  if (!isNaN(parsedLength) && !isNaN(parsedGirth)) {
    const weight = (parsedGirth ** 2 * parsedLength) / 10840;
    return weight.toFixed(2);
  } else {
    return null;
  }
};

// function to check the values in rules
export const processRule = (rule) => {
  // Only return properties that are valid
  const processedRule = {};

  if (rule.subject?.id) processedRule.subject = rule.subject?.id;
  if (rule.condition_number?.id) processedRule.condition_number = rule.condition_number?.id;
  if (rule.condition_string?.id) processedRule.condition_string = rule.condition_string?.id;
  if (rule.condition_date?.id) processedRule.condition_date = rule.condition_date?.id;
  if (rule.condition_boolean?.id) processedRule.condition_boolean = rule.condition_boolean;

  // Set non-object values directly
  if (rule.condition_number_min_value !== undefined)
    processedRule.condition_number_min_value = rule.condition_number_min_value;
  if (rule.condition_number_max_value !== undefined)
    processedRule.condition_number_max_value = rule.condition_number_max_value;
  if (rule.condition_string_value !== undefined)
    processedRule.condition_string_value = rule.condition_string_value;
  if (rule.condition_date_start_value !== undefined)
    processedRule.condition_date_start_value = dayjs(rule.condition_date_start_value);
  if (rule.condition_date_end_value !== undefined)
    processedRule.condition_date_end_value = dayjs(rule.condition_date_end_value);
  if (rule.condition_date_days_value !== undefined)
    processedRule.condition_date_days_value = rule.condition_date_days_value;
  if (rule.condition_boolean_value !== undefined)
    processedRule.condition_boolean_value = rule.condition_boolean_value;

  return processedRule;
};

export const getFullName = (parts) => {
  return parts.filter(Boolean).join(' ');
};

export const filterSelectedValue = (
  originalValues = [],
  selectedValues = [],
  selectValKey = '_id',
  originalValKey = '_id'
) => {
  const selectedSet = new Set(selectedValues.map((val) => val[selectValKey]));

  const filtered = originalValues.filter((item) => !selectedSet.has(item[originalValKey]));

  return filtered;
};

export const getFormattedOptions = (initialArray, parentKey) => {
  if (initialArray && parentKey) {
    const output = initialArray.reduce((acc, elem) => {
      const state = acc.find((item) => item.id === elem[parentKey]);
      if (state) {
        state.value.push(elem);
      } else {
        acc.push({
          id: elem[parentKey],
          value: [elem]
        });
      }
      return acc;
    }, []);

    return output || [];
  }
};

export const deduplicateAndSet = (list = [], newItems = []) => {
  let temp = [...list, ...newItems];

  const uniqueItems = temp.filter((item, index, self) => {
    return index === self.findIndex((s) => s._id === item._id);
  });

  return uniqueItems?.length > 0 ? uniqueItems : [];
};

export const removeDataOfRemovedItem = (parentArray, childArray, childField, distList) => {
  const remainingData = distList.filter((item) => parentArray.includes(item[childField]));
  const secondary = remainingData?.map((ele) => ele._id);
  const final = secondary.filter((ele) => childArray.includes(ele));

  return [final?.length > 0 ? final : [], remainingData?.length > 0 ? remainingData : []];
};

export const formatKey = (key) => {
  return key
    .split('_')
    .join(' ')
    .replace(/^\w/, (c) => c.toUpperCase());
};

export const objectToFormData = (obj, formData = new FormData(), parentKey = '') => {
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const propName = parentKey ? `${parentKey}[${key}]` : key;
      const value = obj[key];

      if (value instanceof File || value instanceof Blob) {
        formData.append(propName, value);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (item instanceof File || item instanceof Blob) {
            formData.append(`${propName}`, item);
          } else if (typeof item === 'object' && item !== null) {
            objectToFormData(item, formData, `${propName}[${index}]`);
          } else {
            formData.append(`${propName}`, item);
          }
        });
      } else if (dayjs.isDayjs(value)) {
        formData.append(propName, value.toISOString());
      } else if (typeof value === 'object' && value !== null) {
        objectToFormData(value, formData, propName);
      } else {
        formData.append(propName, value);
      }
    }
  }
  return formData;
};

export const convertToTitleCase = (str) => {
  if (str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .split('_')
      .join(' ');
  } else {
    return '';
  }
};

export const decryptUserData = (encryptedData, decryptionKey) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, decryptionKey);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};

export const encryptUserData = (userObj, encryptionKey) => {
  const userString = JSON.stringify(userObj);
  const encryptedData = CryptoJS.AES.encrypt(userString, encryptionKey).toString();
  return encryptedData;
};

export const filtereAssignedLocations = (userData, role) => {
  let final = userData?.employee_permissions?.find(
    (el) => el.permission_id === role
  )?.assign_location;

  const transformedArray =
    Object.entries(final || {}).map(([key, value]) => ({
      [key]: value
    })) || null;

  return transformedArray.length > 0 ? JSON.stringify(transformedArray.slice(0, -1)) : null;
};

export const isImageFormat = (arr) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];

  const isImageString = (str) => {
    const extension = str.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
  };

  for (const item of arr) {
    if (typeof item === 'string') {
      if (isImageString(item)) {
        return true;
      }
    } else if (item instanceof File) {
      if (item.type.startsWith('image/')) {
        return true;
      }
    }
  }

  return false;
};

export const isVideoUrl = (url) => {
  const videoExtensions = ['mp4', 'webm', 'ogg', 'avi', 'mkv', 'mov', 'flv', 'wmv', 'm4v'];

  try {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const extension = pathname.split('.').pop().toLowerCase();

    return videoExtensions.includes(extension);
  } catch (error) {
    return false;
  }
};

export const isImageUrl = (url) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'tiff'];

  try {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const extension = pathname.split('.').pop().toLowerCase();

    return imageExtensions.includes(extension);
  } catch (error) {
    return false;
  }
};

export const checkNumber = (num) => {
  return num > 100 ? '99+' : num;
};
