import { Box } from '@mui/material';
import { CollapsableData, DatePickers, NoData, UITextInputWithTitle } from '../../../../ui-kits';
import { useFormik } from 'formik';
import { employeeAccountsSchema, getTitles } from '../../../../utils';
import { useEffect, useState } from 'react';
import { ModalButtons } from '../../../../components';
import { globalStyles } from '../../../../theme';
import { updateEmployeeRequest } from '../../../../services/redux/slices';
import { useDispatch } from 'react-redux';
import { defaultValues } from '../../../../constants';
import dayjs from 'dayjs';
import { ViewAccountDetails } from './view-account-details';

import { Address } from '../../../common/address';

const res = defaultValues.isResponsive;
const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    gap: '10px'
  },
  btnCont: {
    marginTop: '50px'
  },
  subCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  dateCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    justifyContent: 'end'
  },
  nameWidth: res ? '100%' : 'calc((100% - 10px)/3)',
  addressWidth: res ? '100%' : 'calc((100% - 10px)/2)',
  accountWidth: res ? '100%' : 'calc((100% - 30px)/3)'
};

export const EmployeeAccountDetails = ({
  employeeId,
  states,
  editable,
  sbAccount,
  pfAccount,
  insurance
}) => {
  const dispatch = useDispatch();

  const [btnDisabled, setBtndisabled] = useState(true);
  const [open, setOpen] = useState(null);
  const [sbDisable, setSbDisable] = useState(false);

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldTouched,
    handleBlur,
    setFieldValue,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      sb_account_number: '',
      bank_name: '',
      ifsc_code: '',
      branch_address_line_one: '',
      branch_address_line_two: '',
      country_id: '',
      state_id: '',
      district_id: '',
      taluka_id: '',
      village_id: '',
      post_code: '',
      pf_account_number: '',
      pf_account_details: '',
      insurance_policy_number: '',
      insurance_provider: '',
      insurance_policy_details: '',
      start_date: null,
      end_date: null
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: employeeAccountsSchema
  });

  useEffect(() => {
    setSbDisable(values.sb_account_number ? false : true);
  }, [values.sb_account_number]);

  useEffect(() => {
    let newValues = { ...values };
    if (sbAccount) {
      newValues = { ...newValues, ...sbAccount };
    }
    if (pfAccount) {
      newValues = { ...newValues, ...pfAccount };
    }
    if (insurance) {
      newValues = {
        ...newValues,
        ...insurance,
        start_date: dayjs(insurance.start_date),
        end_date: dayjs(insurance.end_date)
      };
    }
    setValues(newValues);
  }, [sbAccount, pfAccount, insurance, setValues, states]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = {
      _id: employeeId,
      sb_account: {
        sb_account_number: values.sb_account_number,
        bank_name: values.bank_name,
        ifsc_code: values.ifsc_code,
        branch_address_line_one: values.branch_address_line_one,
        branch_address_line_two: values.branch_address_line_two,
        country_id: values.country_id,
        state_id: values.state_id?._id,
        district_id: values.district_id?._id,
        taluka_id: values.taluka_id?._id,
        village_id: values.village_id?._id,
        post_code: values.post_code
      },
      pf_account: {
        pf_account_number: values.pf_account_number,
        pf_account_details: values.pf_account_details
      },
      insurance: {
        insurance_policy_number: values.insurance_policy_number,
        insurance_provider: values.insurance_provider,
        insurance_policy_details: values.insurance_policy_details,
        start_date: values.start_date,
        end_date: values.end_date
      }
    };
    dispatch(updateEmployeeRequest(payload));
  };

  const handleAccountModal = (val) => {
    setOpen(val);
  };

  if (editable) {
    return (
      <Box sx={styles.container}>
        <CollapsableData
          title={getTitles('T-10432')}
          isOpen={open === 1}
          isStatus={false}
          handleOpen={(val) => {
            handleAccountModal(val ? 1 : null);
          }}>
          <Box sx={styles.nameCont}>
            <UITextInputWithTitle
              name="sb_account_number"
              title={getTitles('T-10370')}
              placeHolder={getTitles('T-10360')}
              error={touched.sb_account_number && errors.sb_account_number}
              value={values.sb_account_number}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.nameWidth}
              allowOnlyNumbers={true}
            />
            <UITextInputWithTitle
              name="bank_name"
              title={getTitles('T-10404')}
              placeHolder={getTitles('T-10384')}
              error={touched.bank_name && errors.bank_name}
              value={values.bank_name}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.nameWidth}
              disabled={sbDisable}
            />
            <UITextInputWithTitle
              name="ifsc_code"
              title={getTitles('T-10405')}
              placeHolder={getTitles('T-10406')}
              error={touched.ifsc_code && errors.ifsc_code}
              value={values.ifsc_code}
              bankIfscCode={true}
              maxLength={11}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.nameWidth}
              disabled={sbDisable}
            />
          </Box>
          <Box sx={styles.nameCont}>
            <UITextInputWithTitle
              name="branch_address_line_one"
              title={getTitles('T-10430')}
              placeHolder={getTitles('T-10361')}
              error={touched.branch_address_line_one && errors.branch_address_line_one}
              value={values.branch_address_line_one}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.addressWidth}
              disabled={sbDisable}
            />
            <UITextInputWithTitle
              name="branch_address_line_two"
              title={getTitles('T-10431')}
              placeHolder={getTitles('T-10362')}
              error={touched.branch_address_line_two && errors.branch_address_line_two}
              value={values.branch_address_line_two}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.addressWidth}
              disabled={sbDisable}
            />
          </Box>
          <Address
            actionType={defaultValues.actionType.edit}
            objects={values}
            divider={false}
            addressOneDisabled={sbDisable}
            addressTwoDisabled={sbDisable}
            countryDisabled={sbDisable}
            stateDisabled={sbDisable}
            districtDisabled={sbDisable}
            talukaDisabled={sbDisable}
            villageDisabled={sbDisable}
            postCodeDisabled={sbDisable}
            type={'location'}
            showPostCode={true}
            onCountrySelected={(e) =>
              handleChange({ target: { name: 'country_id', value: e.target.value } })
            }
            onStateSelected={(val) => {
              handleChange({ target: { name: 'state_id', value: val } });
            }}
            onDistrictSelected={(val) =>
              handleChange({ target: { name: 'district_id', value: val } })
            }
            onTalukaSelected={(val) => handleChange({ target: { name: 'taluka_id', value: val } })}
            onVillageSelected={(val) =>
              handleChange({ target: { name: 'village_id', value: val } })
            }
            onPostCodeChange={(e) => {
              handleChange({ target: { name: 'post_code', value: e.target.value } });
            }}
          />
        </CollapsableData>

        <CollapsableData
          title={getTitles('T-10433')}
          isOpen={open === 2}
          isStatus={false}
          handleOpen={(val) => handleAccountModal(val ? 2 : null)}>
          <Box sx={styles.nameCont}>
            <UITextInputWithTitle
              name="pf_account_number"
              title={getTitles('T-10371')}
              placeHolder={getTitles('T-10413')}
              error={touched.pf_account_number && errors.pf_account_number}
              value={values.pf_account_number}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.accountWidth}
            />
            <UITextInputWithTitle
              name="pf_account_details"
              title={getTitles('T-10409')}
              placeHolder={getTitles('T-10373')}
              error={touched.pf_account_details && errors.pf_account_details}
              value={values.pf_account_details}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.accountWidth}
            />
          </Box>
        </CollapsableData>

        <CollapsableData
          title={getTitles('T-10434')}
          isOpen={open === 3}
          isStatus={false}
          handleOpen={(val) => handleAccountModal(val ? 3 : null)}>
          <Box sx={styles.nameCont}>
            <UITextInputWithTitle
              name="insurance_policy_number"
              title={getTitles('T-10372')}
              placeHolder={getTitles('T-10414')}
              error={touched.insurance_policy_number && errors.insurance_policy_number}
              value={values.insurance_policy_number}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.accountWidth}
              required={true}
              titleStyle={globalStyles.text}
            />

            <UITextInputWithTitle
              name="insurance_provider"
              title={getTitles('T-10655')}
              placeHolder={getTitles('T-10414')}
              error={touched.insurance_provider && errors.insurance_provider}
              value={values.insurance_provider}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.accountWidth}
              required={true}
            />

            <UITextInputWithTitle
              name="insurance_policy_details"
              title={getTitles('T-10410')}
              placeHolder={getTitles('T-10373')}
              error={touched.insurance_policy_details && errors.insurance_policy_details}
              value={values.insurance_policy_details}
              onChange={handleChange}
              onBlur={handleBlur}
              width={styles.accountWidth}
            />
          </Box>

          <Box sx={styles.nameCont}>
            <DatePickers
              dateValue={values.start_date}
              onDateChange={(val) => handleChange({ target: { value: val, name: 'start_date' } })}
              title={getTitles('T-10411')}
              width={styles.accountWidth}
              required={true}
              error={touched.start_date && errors.start_date}
              maxDate={values.end_date}
              onOpen={() => setFieldTouched('start_date', true)}
            />

            <DatePickers
              dateValue={values.end_date}
              onDateChange={(val) => handleChange({ target: { value: val, name: 'end_date' } })}
              title={getTitles('T-10412')}
              width={styles.accountWidth}
              required={true}
              minDate={values.start_date}
              error={touched.end_date && errors.end_date}
              onOpen={() => setFieldTouched('end_date', true)}
            />
          </Box>
        </CollapsableData>

        <ModalButtons
          onPrimaryBtnClick={handleSubmit}
          primaryBtnTitle={getTitles('T-10339')}
          disabled={!isValid || !dirty || btnDisabled}
          containerStyle={globalStyles.mt(3)}
        />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {pfAccount || insurance || sbAccount ? (
        <ViewAccountDetails sbAccount={sbAccount} pfAccount={pfAccount} insurance={insurance} />
      ) : (
        <NoData message={getTitles('T-11061')} height="30vh" />
      )}
    </Box>
  );
};
