import { defaultValues, KEYS, SCREEN_PATH } from '../../../../constants';
import languageIcon from '../../../../assets/icons/language.png';
import shoppingIcon from '../../../../assets/icons/shopping_basket.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { useDispatch } from 'react-redux';
import { updateDetailsPg } from '../../../../services/redux/slices';
import { Avatar, Box, Button, Dialog, DialogContent } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { UIBackButton } from '../../../../ui-kits/back-button';
import { Text, UIAutocompleted } from '../../../../ui-kits';
import { HeaderStyles } from '../styles';
import { encryptUserData, getTitles } from '../../../../utils';
import { useNavigate } from 'react-router-dom';

export const AuthenticatedHeader = ({
  isAuthenticated,
  toggleDrawer,
  detailsPg,
  currentPage,
  setLangOpen,
  langOpen,
  handleDisplayLang,
  handleLanguage,
  getApplicationLanguage,
  userData,
  totalNotification
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { laboratoryIncharge } = defaultValues.employeeRol;
  const permissinIds = userData?.employee_permissions?.map((elem) => elem.permission_id);
  const currServer = process.env.REACT_APP_SERVER;
  const ecomRoute = process.env.REACT_APP_ECOMMERCE_ROUTE;
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

  const handleShoppingClick = () => {
    let params = {
      auth_token: localStorage.getItem(KEYS.ACCESS_KEY),
      user_data: { ...userData, is_web: true }
    };
    const encryptedUser = encryptUserData(params, encryptionKey);
    let uri = `${ecomRoute}?${encodeURIComponent(encryptedUser)}`;
    if (['test', 'dev', 'loc'].includes(currServer)) {
      window.location.href = `${SCREEN_PATH.ECOMMERCE}?${encodeURIComponent(encryptedUser)}`;
    }

    if (['prod', 'uat'].includes(currServer)) {
      window.location.href = uri;
    }
  };
  return (
    <>
      {defaultValues.isResponsive && isAuthenticated && (
        <Box>
          {!detailsPg ? (
            <MenuIcon onClick={toggleDrawer('Right', true)} />
          ) : (
            <UIBackButton onClick={() => dispatch(updateDetailsPg(false))} />
          )}
        </Box>
      )}

      <Box sx={{ flex: 1 }}>
        {!defaultValues.isResponsive && <Text variant="boldH32">{currentPage && currentPage}</Text>}
      </Box>
      <Box sx={HeaderStyles.profileSuperCont}>
        {permissinIds?.every((element) => [laboratoryIncharge].includes(element)) && (
          <Box
            sx={{ ...HeaderStyles.languageCont, ...HeaderStyles.shoppingCont }}
            onClick={handleShoppingClick}>
            <img src={shoppingIcon} style={HeaderStyles.shopping} />
            {!defaultValues.isResponsive && <Text variant="D20">{getTitles('T-11658')}</Text>}
          </Box>
        )}
        <Box sx={HeaderStyles.languageCont}>
          <img
            src={languageIcon}
            style={HeaderStyles.langImg}
            onClick={() => {
              setLangOpen(true);
            }}
          />

          <Dialog
            open={langOpen}
            onClose={() => handleDisplayLang(false)}
            sx={HeaderStyles.dialogueCont}>
            <DialogContent sx={HeaderStyles.dialogue}>
              {defaultValues.appLanguages.map((item, idx) => (
                <Button key={idx} onClick={() => handleLanguage(item)} sx={HeaderStyles.dBtn}>
                  {item.title}
                </Button>
              ))}
            </DialogContent>
          </Dialog>
          <UIAutocompleted
            options={defaultValues.appLanguages}
            optionLabel={(item) => item.title}
            containerStyle={HeaderStyles.autoCont}
            autoCompleteStyle={HeaderStyles.autoStyle}
            PaperBoxStyle={HeaderStyles.paperBox}
            textInputStyles={HeaderStyles.textInp}
            selectedValue={handleLanguage}
            preSelectedValue={getApplicationLanguage()}
            clearIcon={null}
            startIcon={languageIcon}
            disableClearable
          />
        </Box>

        <Box sx={HeaderStyles.profile}>
          <Avatar alt="" src={userData?.photo_url} sx={HeaderStyles.avatar} />
          {!defaultValues.isResponsive && (
            <Text variant="D18">{`${userData?.first_name} ${userData?.last_name}`}</Text>
          )}
          <Badge
            badgeContent={totalNotification}
            sx={HeaderStyles.badge}
            onClick={() => navigate(SCREEN_PATH.NOTIFICATION)}>
            <NotificationsIcon />
          </Badge>
        </Box>
      </Box>
    </>
  );
};
