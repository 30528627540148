import { useDispatch, useSelector } from 'react-redux';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';

import { useEffect, useState } from 'react';
import {
  getGovtOfficialsRequest,
  resetGovtOfficialMsg,
  setCurrUserId,
  setGovtOfficialCurrentRender,
  setGovtOfficialData,
  setGovtOfficialParams,
  updateDetailsPg,
  updateGovtOfficialRequest
} from '../../services/redux/slices';
import { toast } from 'react-toastify';
import { checkReadOnly, convertStringsToBoolean, getTitles, resetMessages } from '../../utils';
import { ActionButton, Screen, Text, UILayout } from '../../ui-kits';
import { BasicProfile } from '../../components';
import {
  getGovtOfficial,
  getGovtOfficialCurPage,
  getGovtOfficialCurrRender,
  getGovtOfficialCurruserId,
  getGovtOfficialData,
  getGovtOfficialErrorMsg,
  getGovtOfficialLoading,
  getGovtOfficialParams,
  getGovtOfficialSuccessMsg,
  getGovtOfficialTotalPage,
  getUserData
} from '../../services/redux/selectors';
import { ResActionTitle } from '../../components/common/res-action-title';
import { AddEditGovtOfficial } from '../../components/HOC';
import { processLoading } from '../../services/redux/selectors/manageProccess';

const res = defaultValues.isResponsive;

export const ManageGovtOfficial = () => {
  const dispatch = useDispatch();

  const govtOfficial = useSelector(getGovtOfficial);
  const errorMsg = useSelector(getGovtOfficialErrorMsg);
  const isLoading = useSelector(getGovtOfficialLoading);
  const processIsLoading = useSelector(processLoading);
  const successMsg = useSelector(getGovtOfficialSuccessMsg);
  const currRender = useSelector(getGovtOfficialCurrRender);
  const currentPage = useSelector(getGovtOfficialCurPage);
  const totalPages = useSelector(getGovtOfficialTotalPage);
  const govtOfficialParams = useSelector(getGovtOfficialParams);
  const govtOfficialData = useSelector(getGovtOfficialData);
  const userData = useSelector(getUserData);
  const currUserId = useSelector(getGovtOfficialCurruserId);

  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(null);

  const { add, edit, view } = defaultValues.actionType;

  useEffect(() => {
    handleGovtOfficial();
  }, []);

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.govtOfficeManager));
  }, [userData]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));

      if (['S-10082', 'S-10083'].includes(successMsg)) handleGovtOfficial();
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetGovtOfficialMsg()));
  }, [successMsg, errorMsg]);

  const handleGovtOfficial = (data) => {
    const payload = { ...govtOfficialParams, ...data };
    dispatch(setGovtOfficialParams(payload));
    dispatch(getGovtOfficialsRequest(payload));
  };

  const handleStatus = (item) => {
    dispatch(updateGovtOfficialRequest({ ...item, _id: item._id, is_active: !item.is_active }));
  };

  const handleEdit = (data) => {
    dispatch(setCurrUserId(data._id));
    dispatch(updateDetailsPg(true));
    dispatch(setGovtOfficialCurrentRender(view));
  };

  const handleSearch = (data) => {
    dispatch(getGovtOfficialsRequest({ search_text: data }));
  };
  const handleFilter = (data) => {
    setFilter(data);
    handleGovtOfficial({
      page: 1,
      filter: JSON.stringify(convertStringsToBoolean(data)),
      sort: sort
    });
  };

  const handleSort = (data) => {
    setFilter([]);
    setSort(data);
    handleGovtOfficial({ page: 1, sort: data, filter: null });
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    handleGovtOfficial({ page: 1, search_text: null, filter: null, sort: null });
  };

  const handlePagination = (e, data) => {
    handleGovtOfficial({ page: data });
  };

  const tableData =
    govtOfficial &&
    govtOfficial.length > 0 &&
    govtOfficial.map((item) => {
      if (!res) {
        return [
          {
            title: getTitles('T-10751'),
            values: item.officer_id,
            columnWidth: '15%'
          },
          {
            title: getTitles('T-10081'),
            values: (
              <BasicProfile
                title={`${item.full_name}`}
                isIcon={false}
                textVariant="D22"
                onTitleClick={() => handleEdit(item)}
              />
            )
          },

          {
            title: getTitles('T-10440'),
            values: item.phone_number
          },

          {
            title: getTitles('T-10320'),
            values: item.department
          },

          {
            columnWidth: '12%',
            title: getTitles('T-10103'),
            values: (
              <ActionButton
                status={item.is_active}
                onStatusChange={() => handleStatus(item)}
                alterTitle={defaultValues.confirmationModel.changeGovtOfficialStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.subTitle}
                isReadOnly={isReadOnly}
              />
            )
          }
        ];
      } else {
        return {
          title: (
            <BasicProfile
              title={`${item.full_name}`}
              isIcon={false}
              textVariant="D22"
              onTitleClick={() => handleEdit(item)}
            />
          ),
          details: [
            {
              subTitle: getTitles('T-10751'),
              action: item.officer_id
            },
            {
              subTitle: getTitles('T-10440'),
              action: item.phone_number
            },

            {
              subTitle: getTitles('T-10320'),
              action: item.department
            },

            {
              subTitle: getTitles('T-10103'),
              action: (
                <ActionButton
                  status={item.is_active}
                  onStatusChange={() => handleStatus(item)}
                  alterTitle={defaultValues.confirmationModel.changeGovtOfficialStatus.title(
                    item.is_active
                  )}
                  alterSubTitle={defaultValues.confirmationModel.subTitle}
                  isReadOnly={isReadOnly}
                />
              )
            }
          ]
        };
      }
    });

  if ([add, edit, view].includes(currRender)) {
    return (
      <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-11148')}>
        {res && ![1].includes(currRender) && (
          <ResActionTitle
            title={
              currRender === add
                ? getTitles('T-11151')
                : currRender === view
                  ? getTitles('T-11149')
                  : getTitles('T-11150')
            }
            handleBtnClick={
              currRender === view && !isReadOnly
                ? () => dispatch(setGovtOfficialCurrentRender(edit))
                : null
            }
          />
        )}
        <AddEditGovtOfficial
          govtOfficialData={govtOfficialData}
          isReadOnly={isReadOnly}
          currUserId={currUserId}
        />
      </Screen>
    );
  }

  return (
    <Screen
      showSideBar={true}
      isLoading={isLoading || processIsLoading}
      currentPage={getTitles('T-11148')}>
      {res && (
        <Text variant="boldH32Sec" style={{ mt: 1 }}>
          {getTitles('T-11148')}
        </Text>
      )}
      <UILayout
        isMultiSelectFilterOption={true}
        currentlyActiveSortOption={sort}
        currentlyActiveFilterOption={filter}
        handleSearch={handleSearch}
        searchBarPlaceHolder={getTitles('T-11005')}
        handleRefresh={handleRefresh}
        handleSort={handleSort}
        handleFilter={handleFilter}
        optionsForSorting={defaultValues.farmerSortOptions}
        clearSearch={filter}
        handleAddButton={() => {
          dispatch(setGovtOfficialCurrentRender(add));
          dispatch(setGovtOfficialData(null));
          dispatch(updateDetailsPg(true));
        }}
        tableItems={tableData}
        showNoData={!govtOfficial.length > 0}
        showDataTable={!res}
        message={getErrorMessage('E-10167')}
        currPage={currentPage}
        pageCount={totalPages}
        handlePagination={handlePagination}
        showPagination={govtOfficial.length > 0 && totalPages > 1}
        onTitleClick={(ele) => handleEdit(ele)}
        isReadOnly={isReadOnly}
      />
    </Screen>
  );
};
