import { createSlice } from '@reduxjs/toolkit';
import { defaultValues } from '../../../constants';
const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  currTab: defaultValues.callCenterCurrTabs.myTask,
  myTasks: [],
  allTasks: [],
  allCallHistory: [],
  taskDetails: null,
  usersList: [],
  userInfo: null,
  callerTasks: [],
  callerCallHistory: [],
  taskModel: null,
  cattleOrEmployee: [],
  callerDetails: null,
  cattleTask: [],
  cattleTaskNxtPage: null,
  cattleTaskCurrPage: null,
  cattleTaskTotalPage: null,
  callCenterAgents: [],
  callCenterAgentsNxtPage: null,
  callCenterAgentsCurrPage: null,
  callCenterAgentsTotalPage: null
};
const callcenterSlice = createSlice({
  initialState,
  name: 'callCenter',
  reducers: {
    resetCallcenterMsg(state) {
      state.errorMessage = null;
      state.successMessage = null;
    },
    resetCallcenter(state) {
      state.isLoading = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.currTab = defaultValues.callCenterCurrTabs.myTask;
      state.myTasks = [];
      state.allTasks = [];
      state.allCallHistory = [];
      state.taskDetails = null;
      state.usersList = [];
      state.userInfo = null;
      state.callerTasks = [];
      state.callerCallHistory = [];
      state.taskModel = null;
      state.cattleOrEmployee = [];
      state.callerDetails = null;
      state.cattleTaskNxtPage = null;
      state.cattleTaskCurrPage = null;
      state.cattleTaskTotalPage = null;
    },
    clearCallcenterUsersSugestionList(state) {
      state.usersList = [];
    },
    clearCallcenterUserInfo(state) {
      state.userInfo = null;
    },
    setCallcenterCurrTab(state, action) {
      state.currTab = action.payload;
    },
    setCallCenterTaskModel(state, action) {
      state.taskModel = action.payload;
    },
    setCallCenterTaskDetails(state, action) {
      state.taskDetails = action.payload;
    },
    getCallAgentMyTasksRequest(state) {
      state.isLoading = true;
    },
    getCallAgentMyTasksSuccess(state, action) {
      state.isLoading = false;
      state.myTasks = action.payload.data;
    },
    getCallAgentMyTasksFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.myTasks = [];
    },
    getCallAgentAllTasksRequest(state) {
      state.isLoading = true;
    },
    getCallAgentAllTasksSuccess(state, action) {
      state.isLoading = false;
      state.allTasks = action.payload.data;
    },
    getCallAgentAllTasksFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.allTasks = [];
    },
    getCallAgentAllCallHistoryRequest(state) {
      state.isLoading = true;
    },
    getCallAgentAllCallHistorySuccess(state, action) {
      state.isLoading = false;
      state.allCallHistory = action.payload.data.calls;
    },
    getCallAgentAllCallHistoryFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.allCallHistory = [];
    },
    getCallCenterTaskDetailsRequest(state) {
      state.isLoading = true;
    },
    getCallCenterTaskDetailsSuccess(state, action) {
      state.isLoading = false;
      state.taskDetails = action.payload.data;
      state.successMessage = action.payload.status_code;
    },
    getCallCenterTaskDetailsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getCallCenterUsersRequest(state) {
      state.isLoading = true;
    },
    getCallCenterUsersSuccess(state, action) {
      state.isLoading = false;
      state.usersList = action.payload.data.users;
    },
    getCallCenterUsersFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.usersList = [];
    },
    getCallCenterUserInfoRequest(state) {
      state.isLoading = true;
      state.callerTasks = [];
    },
    getCallCenterUserInfoSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.userInfo = action.payload.data;
    },
    getCallCenterUserInfoFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.userInfo = null;
    },
    addTaskCallCenterRequest(state) {
      state.isLoading = true;
    },
    addTaskCallCenterSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    addTaskCallCenterFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateTaskCallCenterRequest(state) {
      state.isLoading = true;
    },
    updateTaskCallCenterSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    updateTaskCallCenterFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getCallerTasksRequest(state) {
      state.isLoading = true;
    },
    getCallerTasksSuccess(state, action) {
      state.isLoading = false;
      state.callerTasks = action.payload.data.tasks;
    },
    getCallerTasksFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.callerTasks = [];
    },
    getCallerCallHistoryRequest(state) {
      state.isLoading = true;
    },
    getCallerCallHistorySuccess(state, action) {
      state.isLoading = false;
      state.callerCallHistory = action.payload.data.call_history;
    },
    getCallerCallHistoryFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.callerCallHistory = [];
    },
    getCallAgentCattleOrEmployeeRequest(state) {
      state.isLoading = true;
    },
    getCallAgentCattleOrEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.cattleOrEmployee = action.payload.data;
    },
    getCallAgentCattleOrEmployeeFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    addNoteRequest(state) {
      state.isLoading = true;
    },
    addNoteSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    addNoteFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getCallAgentCallerDetailsRequest(state) {
      state.isLoading = true;
    },
    getCallAgentCallerDetailsSuccess(state, action) {
      state.isLoading = false;
      state.callerDetails = action.payload.data;
      state.successMessage = action.payload.status_code;
    },
    getCallAgentCallerDetailsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getCattleTaskRequest(state) {
      state.isLoading = true;
    },
    getCattleTaskSuccess(state, action) {
      state.isLoading = false;
      state.cattleTask = action.payload.data.tasks;
      state.successMessage = action.payload.status_code;
      state.cattleTaskNxtPage = action.payload.data.next_page;
      state.cattleTaskCurrPage = action.payload.data.current_page;
      state.cattleTaskTotalPage = action.payload.data.total_page;
    },
    getCattleTaskFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.cattleTask = [];
    },
    clearCattleData(state) {
      state.cattleTask = [];
      state.cattleTaskNxtPage = null;
      state.cattleTaskCurrPage = null;
      state.cattleTaskTotalPage = null;
    },
    getCallCenterAgentsRequest(state) {
      state.isLoading = true;
    },
    getCallCenterAgentsSuccess(state, action) {
      state.isLoading = false;
      state.callCenterAgents = action.payload.data.agents;
      state.callCenterAgentsNxtPage = null;
      state.callCenterAgentsCurrPage = null;
      state.callCenterAgentsTotalPage = null;
    },
    getCallCenterAgentsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.callCenterAgents = [];
    },
    updateCallCenterAgentsRequest(state) {
      state.isLoading = true;
    },
    updateCallCenterAgentsSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    updateCallCenterAgentsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    }
  }
});

export const {
  resetCallcenterMsg,
  setCallcenterCurrTab,
  getCallAgentMyTasksFailure,
  getCallAgentMyTasksRequest,
  getCallAgentMyTasksSuccess,
  getCallAgentAllTasksFailure,
  getCallAgentAllTasksRequest,
  getCallAgentAllTasksSuccess,
  getCallAgentAllCallHistoryFailure,
  getCallAgentAllCallHistoryRequest,
  getCallAgentAllCallHistorySuccess,
  getCallCenterTaskDetailsFailure,
  getCallCenterTaskDetailsRequest,
  getCallCenterTaskDetailsSuccess,
  getCallCenterUsersFailure,
  getCallCenterUsersRequest,
  getCallCenterUsersSuccess,
  getCallCenterUserInfoFailure,
  getCallCenterUserInfoRequest,
  getCallCenterUserInfoSuccess,
  addTaskCallCenterFailure,
  addTaskCallCenterRequest,
  addTaskCallCenterSuccess,
  updateTaskCallCenterFailure,
  updateTaskCallCenterRequest,
  updateTaskCallCenterSuccess,
  getCallerTasksFailure,
  getCallerTasksRequest,
  getCallerTasksSuccess,
  getCallerCallHistoryFailure,
  getCallerCallHistoryRequest,
  getCallerCallHistorySuccess,
  addNoteFailure,
  addNoteRequest,
  addNoteSuccess,
  clearCallcenterUsersSugestionList,
  clearCallcenterUserInfo,
  setCallCenterTaskModel,
  setCallCenterTaskDetails,
  getCallAgentCattleOrEmployeeRequest,
  getCallAgentCattleOrEmployeeFailure,
  getCallAgentCattleOrEmployeeSuccess,
  resetCallcenter,
  getCallAgentCallerDetailsRequest,
  getCallAgentCallerDetailsFailure,
  getCallAgentCallerDetailsSuccess,
  getCattleTaskFailure,
  getCattleTaskRequest,
  getCattleTaskSuccess,
  clearCattleData,
  getCallCenterAgentsFailure,
  getCallCenterAgentsRequest,
  getCallCenterAgentsSuccess,
  updateCallCenterAgentsSuccess,
  updateCallCenterAgentsFailure,
  updateCallCenterAgentsRequest
} = callcenterSlice.actions;

export default callcenterSlice.reducer;
