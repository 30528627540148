import { Box } from '@mui/material';
import { Screen, Text } from '../../ui-kits';
import { color, globalStyles } from '../../theme';

export const ShippingPolicy = () => {
  const styles = {
    container: {
      display: 'flex',
      padding: '16px',
      justifyContent: 'center',
      mt: 4,
      backgroundColor: color.primaryBackground
    },
    subContainer: { width: '90%', textAlign: 'left' },
    innerText: {
      fontWeight: 400,
      display: 'inline-block'
    }
  };

  return (
    <Screen showHeader={true}>
      <Box sx={styles.container}>
        <Box sx={styles.subContainer}>
          <Text variant="boldH40">Shipping & Delivery Policy</Text>

          <Text variant="D20" style={globalStyles.mt(1)}>
            At My Gopalan, we are committed to providing a reliable and efficient shipping
            experience for our customers. This policy outlines the shipping process, timelines, and
            conditions for orders of veterinary products, medicines, and agricultural equipment
            purchased through our website.
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'1. Order Processing Time'}
            <ul>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'All orders are processed within 1-3 business days after receiving your order confirmation. Orders placed on weekends or public holidays will be processed on the next business day. '
                  }
                </Text>
              </li>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'In case of any delays in processing or shipping, we will notify you via email or phone. '
                  }
                </Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'2. shipping Rates & Delivery Times'}
            <ul>
              <li>
                <Text variant="BoldH20">{`Domestic Shipping`}</Text>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'We offer standard and express shipping options across India. Shipping costs are calculated based on the weight, size, and destination of the order and will be displayed at checkout.'
                  }
                  <ul>
                    <li>
                      <Text variant="D20" style={globalStyles.mt(1)}>
                        {`Standard shipping: `}
                        <span style={globalStyles.bold}>{'3-7 business days'}</span>
                      </Text>
                    </li>
                    <li>
                      <Text variant="D20" style={globalStyles.mt(1)}>
                        {`Express shipping: `}{' '}
                        <span style={globalStyles.bold}>{'1-3 business days'}</span>
                      </Text>
                    </li>
                  </ul>
                </Text>
              </li>
              <li>
                <Text
                  variant="BoldH20"
                  style={globalStyles.mt(1)}>{`International Shipping: `}</Text>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {`We currently do not offer international shipping for veterinary products, medicines, or agricultural equipment. Please check back in the future for updates.`}
                </Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'3. Shipping Restrictions'}
            <ul>
              <li>
                <Text variant="BoldH20">
                  {`Veterinary Medicines: `}
                  <Text variant="D20" style={globalStyles.mt(1)}>
                    {
                      'Certain veterinary medicines may be subject to regional or state-specific shipping restrictions. My Gopalan complies with all applicable regulations, and in some cases, we may be unable to ship specific products to certain locations.'
                    }
                  </Text>
                </Text>
              </li>

              <li>
                <Text variant="BoldH20" style={globalStyles.mt(1)}>
                  {`Agricultural Equipment: `}
                  <Text variant="D20" style={globalStyles.mt(1)}>
                    {
                      'Some larger or heavier items may require specialized shipping arrangements. In such cases, we will provide details and shipping costs during the checkout process.'
                    }
                  </Text>
                </Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'4. Tracking Your Order'}
            <ul>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'Once your order has been shipped, we will send you a confirmation email with tracking details. You can use this tracking information to monitor your order’s progress and expected delivery date. '
                  }
                </Text>
              </li>

              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'If you experience any issues with tracking or delivery, please contact our customer support team for assistance. '
                  }
                </Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'5. Shipping Damages'}
            <ul>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'My Gopalan takes great care to ensure that your products are packaged securely. However, in the event that your order arrives damaged, please contact us immediately at mygopalan24@gmail.com/+91-8792217331 within 48 hours of receiving the product. We will arrange for a replacement or refund, depending on the circumstances. '
                  }
                </Text>
              </li>

              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'Please retain all packaging and documentation to facilitate the claims process. '
                  }
                </Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'6. Order Cancellations & Address Changes'}
            <ul>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'If you wish to cancel your order, please contact us at mygopalan24@gmail.com/+91-8792217331 within 12 hours of placing the order. After the order has been processed or shipped, cancellations may not be possible.'
                  }
                </Text>
              </li>

              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'If you need to modify your shipping address after placing an order, please notify us as soon as possible. Address changes can only be made before the order has been dispatched.'
                  }
                </Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'7. Non-Deliverable Packages'}
            <ul>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'In the event that a package is returned to us due to an incorrect address, failed delivery attempts, or refusal to accept the delivery, we will contact you to arrange for re-shipment. Additional shipping charges may apply. '
                  }
                </Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'8. Shipping Locations'}
            <ul>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'We ship to all regions across India. However, certain remote areas may experience longer delivery times or limited courier availability. We will notify you if shipping to your location may require additional time. '
                  }
                </Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'9. Contact Information'}
            <Text variant="D20" style={globalStyles.mt(1)}>
              {`If you have any questions or concerns regarding your shipment, please contact our customer support team at: `}
            </Text>
            <ul>
              <li>
                <Text variant="BoldH20" style={globalStyles.mt(1)}>
                  {'Phone : '}
                  <Text variant="D20" style={styles.innerText}>{`+91-8792217331 `}</Text>
                </Text>
              </li>
              <li>
                <Text variant="BoldH20" style={globalStyles.mt(1)}>
                  {'Email : '}
                  <Text variant="D20" style={styles.innerText}>{`mygopalan24@gmail.com`}</Text>
                </Text>
              </li>
              <li>
                <Text variant="BoldH20" style={globalStyles.mt(1)}>
                  {'Business Hours : '}
                  <Text
                    variant="D20"
                    style={styles.innerText}>{`Mon-Sat : 10.00am – 7.00pm `}</Text>
                </Text>
              </li>
            </ul>
          </Text>
        </Box>
      </Box>
    </Screen>
  );
};
