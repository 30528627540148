import { useEffect, useState } from 'react';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import {
  ActionButton,
  EditButton,
  PopupModal,
  Text,
  UILayout,
  UISaveAndAdd,
  UITextInputWithTitle
} from '../../ui-kits';
import { ModalButtons } from '../../components';
import { useFormik } from 'formik';
import { stateSchema } from '../../utils/schemas';
import { useDispatch, useSelector } from 'react-redux';
import {
  addStateRequest,
  getStateRequest,
  resetManageProcessMsg,
  selectedState,
  setDistrictParams,
  setStateParams,
  updateStateNavDetails,
  updateStateRequest
} from '../../services/redux/slices';
import {
  getErrorMsg,
  getStateCurrPage,
  getStateParams,
  getStateTotalPage,
  getStates,
  getSuccessMsg
} from '../../services/redux/selectors/manageProccess';
import { toast } from 'react-toastify';
import { getTitles, resetMessages } from '../../utils/commonFunction';
import { useNavigate } from 'react-router-dom';
import { color } from '../../theme';
import { Box } from '@mui/material';

export const ManageState = ({ isReadOnly, access, parentRoute, renderType }) => {
  const styles = {
    title: {
      color: color.link,
      textDecoration: 'underline',
      cursor: 'pointer',
      overflowWrap: 'anywhere'
    },
    columnWidth: '40%',
    subClmWdth: defaultValues.isMobile ? '22%' : '23%',
    overLap: { overflowWrap: 'anywhere' }
  };

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const states = useSelector(getStates);
  const errorMsg = useSelector(getErrorMsg);
  const successMsg = useSelector(getSuccessMsg);
  const totalPages = useSelector(getStateTotalPage);
  const currentPage = useSelector(getStateCurrPage);
  const stateParams = useSelector(getStateParams);

  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const { process, village } = defaultValues.appLogicalTexts;

  const typeAssign = {
    type: renderType === village ? 2 : null,
    permission_id: renderType === village ? defaultValues.employeeRol.villageManager : null
  };

  const { values, errors, touched, handleBlur, resetForm, setFieldValue, dirty, isValid } =
    useFormik({
      initialValues: {
        name: '',
        code: ''
      },
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: stateSchema
    });

  useEffect(() => {
    handleState();
    dispatch(selectedState(null));
  }, []);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (['S-10012', 'S-10011'].includes(successMsg)) {
        handleState();
      }
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetManageProcessMsg()));
  }, [successMsg, errorMsg]);

  const handleState = (val) => {
    let payload = { ...stateParams, ...typeAssign, ...val };
    dispatch(setStateParams(payload));
    dispatch(getStateRequest(payload));
  };

  const handleModalClose = () => {
    setOpen(false);
    setIsEdit(false);
    resetForm();
  };

  const handleSaveAndClose = () => {
    if (isEdit) {
      dispatch(updateStateRequest(values));
    } else {
      dispatch(addStateRequest(values));
    }
    handleModalClose();
  };

  const handleAddAnother = () => {
    dispatch(addStateRequest(values));
    resetForm();
  };

  const handleStatus = (id, status) => {
    dispatch(updateStateRequest({ _id: id, is_active: !status }));
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setFieldValue('_id', data._id);
    setFieldValue('name', data.name);
    setFieldValue('code', data.code);
    setOpen(true);
    setFormChanged(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFormChanged(true);
  };

  const handleSearch = (data) => {
    handleState({ search_text: data || null });
  };

  const handlePagination = (e, data) => {
    handleState({ page: data });
  };

  const handleAdd = () => {
    setOpen(true);
  };

  const tableData =
    states?.length > 0 &&
    states.map((item, idx) => [
      renderType === village && {
        title: getTitles('T-11407'),
        values: `${idx + 1}`,
        valueTextStyle: { ml: 3 }
      },
      {
        title: getTitles('T-10070'),
        values: (
          <Text
            variant="D20"
            style={styles.title}
            onClick={() => {
              dispatch(selectedState(currentPage));
              dispatch(updateStateNavDetails({ state_id: item._id, state: item.name }));
              dispatch(setDistrictParams({}));
              navigation(SCREEN_PATH.MANAGE_DISTRICT, {
                state: { access, parentRoute, renderType }
              });
            }}>
            {item.name}
          </Text>
        )
      },
      {
        title: getTitles('T-10160'),
        columnWidth: renderType === process ? styles.subClmWdth : '20%',
        values: (
          <Text style={styles.overLap} variant="D20">
            {item.code}
          </Text>
        )
      },
      renderType === process && {
        title: getTitles('T-10103'),
        columnWidth: styles.subClmWdth,
        values: (
          <ActionButton
            status={item.is_active}
            alterTitle={defaultValues.confirmationModel.changeStateStatus.title(item.is_active)}
            alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
            onStatusChange={() => handleStatus(item._id, item.is_active)}
            isReadOnly={isReadOnly}
          />
        )
      },
      renderType === process && {
        title: getTitles('T-10084'),
        columnWidth: '10%',
        values: (
          <EditButton
            status={item.is_active}
            onClick={() => handleEdit(item)}
            isReadOnly={isReadOnly}
          />
        )
      }
    ]);

  return (
    <Box>
      <UILayout
        handleSearch={handleSearch}
        onlySearchAlphabets={true}
        searchBarPlaceHolder={getTitles('T-10144')}
        handleAddButton={renderType === process && handleAdd}
        tableItems={tableData}
        tableTitle={renderType === process ? getTitles('T-10163') : getTitles('T-11453')}
        currPage={currentPage}
        handlePagination={handlePagination}
        pageCount={totalPages}
        showPagination={totalPages > 1}
        showNoData={!states.length > 0}
        message={getErrorMessage('E-10023')}
        isReadOnly={isReadOnly}
      />

      <PopupModal
        title={isEdit ? getTitles('T-10152') : getTitles('T-10148')}
        onClose={handleModalClose}
        showModel={open}>
        <UITextInputWithTitle
          name="name"
          value={values.name}
          title={getTitles('T-10070')}
          placeHolder={getTitles('T-10156')}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && errors.name}
          allowOnlyAlphabets
          maxLength={20}
        />
        <UITextInputWithTitle
          name="code"
          value={values.code}
          title={getTitles('T-10160')}
          placeHolder={getTitles('T-10162')}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.code && errors.code}
          maxLength={10}
        />
        {!isEdit && (
          <UISaveAndAdd
            onAddAnother={handleAddAnother}
            disabled={!isValid || !dirty || !formChanged}
          />
        )}
        <ModalButtons
          onPrimaryBtnClick={handleSaveAndClose}
          primaryBtnTitle={isEdit ? getTitles('T-10046') : getTitles('T-10063')}
          disabled={!isValid || !dirty || !formChanged}
        />
      </PopupModal>
    </Box>
  );
};
