import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { Screen, UITablist } from '../../ui-kits';
import { getTitles, resetMessages } from '../../utils/commonFunction';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  getCallAgentAllTasksRequest,
  getCallAgentMyTasksRequest,
  resetCallcenterMsg,
  setCallcenterCurrTab,
  setCallCenterTaskModel
} from '../../services/redux/slices';
import {
  getCallcenterCurrTab,
  getCallCenterErrorMsg,
  getCallcenterLoading,
  getCallCenterSuccessMsg,
  getCallcenterTaskModel,
  getCallcenterUserInfo
} from '../../services/redux/selectors/callcenterSelector';
import {
  CallInfoBox,
  CallerDetails,
  CallHistory,
  TaskCallCenterModal,
  TasksCallcenter
} from '../../components';
import { styles } from './styles';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';

export const CallCenter = () => {
  const dispatch = useDispatch();

  const { callerTask, callerCallHis, myTask, callerDetails } = defaultValues.callCenterCurrTabs;
  const { inactive } = defaultValues.callStatus;

  const isLoading = useSelector(getCallcenterLoading);
  const currTab = useSelector(getCallcenterCurrTab);
  const success = useSelector(getCallCenterSuccessMsg);
  const error = useSelector(getCallCenterErrorMsg);
  const caller = useSelector(getCallcenterUserInfo);
  const open = useSelector(getCallcenterTaskModel);

  const [callState, setCallState] = useState(inactive);

  useEffect(() => {
    currTab === myTask && dispatch(getCallAgentMyTasksRequest());
  }, []);

  useEffect(() => {
    if (success) {
      toast.success(getSuccessMessage(!['S-10101'].includes(success) && success));
      if (['S-10098', 'S-10097'].includes(success)) {
        handleOpen(false);
      }
    }

    if (error) {
      toast.error(getErrorMessage(!['E-10194'].includes(error) && error));
    }

    resetMessages(() => dispatch(resetCallcenterMsg()));
  }, [success, error]);

  const handleOpen = (val) => {
    dispatch(setCallCenterTaskModel(val));
  };

  const tabList = [
    {
      value: <TasksCallcenter currTab={currTab} />,
      title: getTitles('T-11236')
    },
    {
      value: <TasksCallcenter currTab={currTab} />,
      title: getTitles('T-11237')
    },
    { value: <CallHistory currTab={currTab} />, title: getTitles('T-11238') }
  ];

  const handleTabChange = (val) => {
    dispatch(setCallcenterCurrTab(val));
    if (val === 0) return dispatch(getCallAgentMyTasksRequest());
    if (val === 1) return dispatch(getCallAgentAllTasksRequest({ due_date_range: 1 }));
  };

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-11235')}>
      <Box sx={styles.container}>
        <CallInfoBox
          caller={caller}
          handleCreateTaskModal={handleOpen}
          callState={callState}
          setCallState={setCallState}
          isLoading={isLoading}
        />

        {currTab === callerTask && <TasksCallcenter currTab={currTab} caller={caller} />}
        {currTab === callerCallHis && <CallHistory currTab={currTab} />}
        {currTab === callerDetails && <CallerDetails currTab={currTab} caller={caller} />}
        <UITablist
          renderElements={tabList}
          currentTab={currTab}
          onTabChange={handleTabChange}
          tabStyle={styles.tabStyle}
        />
        <TaskCallCenterModal
          showModel={open}
          onClose={() => handleOpen(null)}
          isLoading={isLoading}
          caller={caller}
        />
      </Box>
    </Screen>
  );
};
