import { useDispatch, useSelector } from 'react-redux';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { ActionButton, EditButton, Text, UILayout } from '../../../ui-kits';
import { getMasterListItems, getTitles, resetMessages } from '../../../utils';
import {
  getCurPage,
  getErrorMsg,
  getMasterLists,
  getProductList,
  getSuccessMsg,
  getTotalPage,
  processLoading
} from '../../../services/redux/selectors/manageProccess';
import { useEffect, useState } from 'react';
import { styles } from './style';
import {
  getMasterListWiseRequest,
  getProductRequest,
  resetManageProcessMsg,
  updateDetailsPg,
  updateProductRequest
} from '../../../services/redux/slices';
import { toast } from 'react-toastify';
import { ViewAddEditProduct } from './view-add-edit-product';

export const ManageProductMaster = ({ isReadOnly }) => {
  const isRes = defaultValues.isResponsive;
  const { add, view, edit } = defaultValues.actionType;
  const filterOptions = defaultValues.productMasterFilterOption;
  const materialGroup = defaultValues.materialGroupOptions;
  const dispatch = useDispatch();

  const productList = useSelector(getProductList);
  const success = useSelector(getSuccessMsg);
  const error = useSelector(getErrorMsg);
  const isLoading = useSelector(processLoading);
  const totalPages = useSelector(getTotalPage);
  const currentPage = useSelector(getCurPage);
  const listItems = useSelector(getMasterLists);

  const [products, setProducts] = useState([]);
  const [productData, setProductData] = useState({});
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState('');
  const [units, setUnits] = useState([]);
  const [tempConditions, setTempConditions] = useState([]);
  const [actionType, setActionType] = useState(null);

  useEffect(() => {
    dispatch(getProductRequest({ sort: 'latest' }));
  }, [actionType]);

  useEffect(() => {
    dispatch(
      getMasterListWiseRequest({
        master_id: JSON.stringify([
          defaultValues.mListIds.quantityUnits,
          defaultValues.mListIds.temperatureCondition
        ]),
        filter: JSON.stringify([{ is_active: true }])
      })
    );
  }, []);

  useEffect(() => {
    if (listItems) {
      setUnits(getMasterListItems(listItems, defaultValues.mListIds.quantityUnits));
      setTempConditions(getMasterListItems(listItems, defaultValues.mListIds.temperatureCondition));
    }
  }, [listItems]);

  useEffect(() => {
    setProducts(productList);
  }, [productList]);

  useEffect(() => {
    if (success === 'S-10110' || success === 'S-10111') {
      toast.success(getSuccessMessage(success));
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }

    resetMessages(() => dispatch(resetManageProcessMsg()));
  }, [success, error]);

  const handleAddButton = () => {
    dispatch(updateDetailsPg(true));
    setActionType(add);
    setProductData(null);
  };

  const handleEditButton = (productId) => {
    getParticularProduct(productId);
    dispatch(updateDetailsPg(true));
    setActionType(edit);
  };

  const getParticularProduct = (id) => {
    const specificProduct = products.find((ele) => ele.product_id === id);
    setProductData(specificProduct);
  };

  const viewProduct = (productId) => {
    getParticularProduct(productId);
    dispatch(updateDetailsPg(true));
    setActionType(view);
  };

  const handleStatus = (_id, is_active) => {
    dispatch(
      updateProductRequest({
        _id,
        is_active: !is_active
      })
    );
  };

  const tableData = products?.map((item) => {
    if (!isRes) {
      return [
        {
          title: getTitles('T-10438'),
          columnWidth: styles.columnWidth,
          values: <Text variant="BASE">{item?.product_id}</Text>
        },
        {
          title: getTitles('T-11437'),
          columnWidth: '20%',
          values: (
            <Text style={styles.tableItemProductName} onClick={() => viewProduct(item?.product_id)}>
              {item?.name}
            </Text>
          )
        },
        {
          title: getTitles('T-10180'),
          columnWidth: !isReadOnly ? '30%' : '37%',
          values: (
            <Text variant="BASE" style={styles.textOverFlow}>
              {item?.material_group.name}
            </Text>
          )
        },
        {
          title: getTitles('T-10103'),
          columnWidth: !isReadOnly ? styles.columnWidth : '15%',
          values: (
            <ActionButton
              alterTitle={defaultValues.confirmationModel.changeProductStatus.title(item.is_active)}
              alterSubTitle={defaultValues.confirmationModel.changeProductStatus.subtitles}
              status={item?.is_active}
              onStatusChange={() => handleStatus(item._id, item.is_active)}
              isReadOnly={isReadOnly}
            />
          )
        },
        {
          title: getTitles('T-11438'),
          columnWidth: !isReadOnly ? styles.columnWidth : '15%',
          values: <Text variant="BASE">{item?.min_stock_qty}</Text>
        },
        !isReadOnly && {
          title: getTitles('T-10084'),
          columnWidth: '12%',
          values: (
            <EditButton
              status={item.is_active}
              onClick={() => handleEditButton(item?.product_id)}
            />
          )
        }
      ];
    } else {
      return {
        title: (
          <Text style={styles.tableItemProductName} onClick={() => viewProduct(item?.product_id)}>
            {item?.name}
          </Text>
        ),
        details: [
          {
            subTitle: getTitles('T-10438'),
            action: <Text variant="D20">{item?.product_id}</Text>
          },
          {
            subTitle: getTitles('T-10180'),
            action: (
              <Text variant="D20" style={styles.textOverFlow}>
                {item?.material_group.name}
              </Text>
            )
          },
          {
            subTitle: getTitles('T-11438'),
            action: <Text variant="D20">{item?.min_stock_qty}</Text>
          },
          {
            subTitle: getTitles('T-10103'),
            action: (
              <ActionButton
                alterTitle={defaultValues.confirmationModel.changeProductStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.changeProductStatus.subtitles}
                status={item?.is_active}
                onStatusChange={() => handleStatus(item._id, item.is_active)}
                isReadOnly={isReadOnly}
              />
            )
          },
          !isReadOnly && {
            subTitle: getTitles('T-10084'),
            action: (
              <EditButton
                status={item?.is_active}
                onClick={() => handleEditButton(item?.product_id)}
              />
            )
          }
        ]
      };
    }
  });

  const handleSearch = (data) => {
    dispatch(getProductRequest({ search_text: data }));
  };

  const handleRefresh = () => {
    setSort('');
    setFilter([]);
    dispatch(getProductRequest());
  };

  const handleFilter = (data) => {
    setFilter(data);
    dispatch(getProductRequest({ filter: JSON.stringify(data), sort: sort }));
  };

  const handleSort = (data) => {
    setSort(data);
    dispatch(getProductRequest({ sort: data, filter: JSON.stringify(filter) }));
  };

  const handlePagination = (e, data) => {
    dispatch(getProductRequest({ page: data }));
  };

  return (
    <>
      {!actionType ? (
        <UILayout
          isLoading={isLoading}
          handleSearch={handleSearch}
          searchBarPlaceHolder={getTitles('T-10308')}
          handleRefresh={handleRefresh}
          handleSort={handleSort}
          optionsForSorting={defaultValues.farmerSortOptions}
          currentlyActiveFilterOption={filter}
          currentlyActiveSortOption={sort}
          optionsForFiltering={filterOptions}
          isMultiSelectFilterOption={true}
          handleFilter={handleFilter}
          handleAddButton={!isReadOnly && handleAddButton}
          tableItems={tableData}
          tableHeaderTextStyle={styles.headertxtStyle}
          tableSuperSubCont={styles.tableSubCont}
          showPagination={totalPages > 1}
          pageCount={totalPages}
          currPage={currentPage}
          showNoData={!products.length > 0}
          showDataTable={!isRes}
          handlePagination={handlePagination}
          message={getErrorMessage('E-10207')}
        />
      ) : (
        <ViewAddEditProduct
          actionType={actionType}
          setActionType={setActionType}
          materialGroup={materialGroup}
          units={units}
          tempConditions={tempConditions}
          productData={productData}
          isReadOnly={isReadOnly}
        />
      )}
    </>
  );
};
