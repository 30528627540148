import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import { Styles } from './styles';
import {
  getDetailsPg,
  getLabFarmerErrorMsg,
  getLabFarmerLoading,
  getLabFarmerSuccessMsg,
  getLabInchargeReport,
  getLabInchargeReportCurrRender,
  getReports,
  getUserData
} from '../../../../services/redux/selectors';
import {
  addlabInchargeCattleReportRequest,
  getlabInchargeCattleReportRequest,
  getLabReportRequest,
  resetLabFarmerMsg,
  setLabReportCurrRender,
  updateDetailsPg,
  updatelabInchargeCattleReportRequest
} from '../../../../services/redux/slices';
import { ModalButtons } from '../../../common/modal-buttons';
import {
  EditButton,
  Screen,
  Text,
  UIButton,
  UISelect,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { checkReadOnly, getTitles, resetMessages } from '../../../../utils';
import {
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
  SCREEN_PATH
} from '../../../../constants';
import { ReportTable } from '../../../register-cattle/report-table';
import { UIBackButton } from '../../../../ui-kits/back-button';
import { styles } from '../cattle-list/styles';

const isRes = defaultValues.isResponsive;

export const LabInchargeLabReports = ({ onPrev }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { view, edit, add } = defaultValues.actionType;

  const data = useSelector(getLabInchargeReport);
  const actionType = useSelector(getLabInchargeReportCurrRender);
  const reports = useSelector(getReports);
  const success = useSelector(getLabFarmerSuccessMsg);
  const error = useSelector(getLabFarmerErrorMsg);
  const isLoading = useSelector(getLabFarmerLoading);
  const showBackButton = useSelector(getDetailsPg);
  const userData = useSelector(getUserData);

  const [selectedReport, setSelectedReport] = useState('');
  const [reportSections, setReportSections] = useState([]);
  const [microscopicExamination, setMicroScopicExamination] = useState('');
  const [remarks, setRemarks] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(null);

  const [openAccordion, setOpenAccordion] = useState(0);

  const handleNextAccordion = () => {
    setOpenAccordion((prev) => (prev + 1 < reportSections.length ? prev + 1 : prev));
  };

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.laboratoryIncharge));
  }, [userData]);

  useEffect(() => {
    dispatch(getLabReportRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!showBackButton) {
      handleBackButton();
    }
  }, [showBackButton]);

  useEffect(() => {
    if (data) {
      setSelectedReport(data.report_id);
      setReportSections(data.sections);
      setMicroScopicExamination(data.microscopic_examination);
      setRemarks(data.remarks);

      dispatch(setLabReportCurrRender(view));
    } else {
      dispatch(setLabReportCurrRender(add));
    }
  }, [data, reports]);

  const handleSubmit = () => {
    const payload = {
      cattle_id: params?.cattle_id,
      report_id: selectedReport,
      _id: data?._id,
      microscopic_examination: microscopicExamination,
      remarks: remarks,
      sections: reportSections.map((se) => ({
        _id: se._id,
        test_fields: se.test_fields.map((te) => ({
          _id: te._id,
          sample_value: parseInt(te.sample_value)
        }))
      }))
    };
    if (actionType === edit) {
      dispatch(updatelabInchargeCattleReportRequest(payload));
    } else if (actionType === add) {
      dispatch(addlabInchargeCattleReportRequest(payload));
    } else {
      handleBackButton();
    }
  };

  const handleBackButton = () => {
    dispatch(updateDetailsPg(true));
    navigate(`${SCREEN_PATH.LAB_FARMER_CATTLE_DETAILS}/${params?.cattle_id}`);
  };

  useEffect(() => {
    if (success) {
      toast.success(getSuccessMessage(success));
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }
    resetMessages(() => dispatch(resetLabFarmerMsg()));
  }, [success, error]);

  const handleReportChange = (val) => {
    const selectedReportId = val.target.value;
    setSelectedReport(selectedReportId);

    const selectedReport = reports.find((report) => report._id === selectedReportId);
    if (selectedReport) {
      setReportSections(selectedReport.sections);
    } else {
      setReportSections([]);
    }

    dispatch(
      getlabInchargeCattleReportRequest({
        cattle_id: params?.cattle_id,
        report_id: selectedReport?._id
      })
    );
  };

  const handleChange = (sampleValue, sectionId, testFieldId) => {
    const reportDD = reportSections;

    const temp = [];

    reportDD.forEach((section) => {
      if (section._id === sectionId) {
        const testTemp = [];
        section.test_fields.forEach((test) => {
          if (test._id === testFieldId) {
            testTemp.push({
              ...test,
              sample_value: sampleValue
            });
          } else {
            testTemp.push(test);
          }
        });
        temp.push({ ...section, test_fields: testTemp });
      } else {
        temp.push(section);
      }
    });

    setReportSections(temp);
  };

  return (
    <Screen showSideBar={true} currentPage={getTitles('T-11100')} isLoading={isLoading}>
      <Box sx={Styles.superCont}>
        <Box sx={Styles.additionalDCont}>
          {!defaultValues.isResponsive && (
            <UIBackButton backRoot={styles.backArrowButton} onClick={handleBackButton} />
          )}
          <Box sx={Styles.additionalDSubCont}>
            <Box sx={Styles.textCont}>
              <UISelect
                placeholder={getTitles('T-10087')}
                containerStyles={{ width: isRes ? 'fit-content' : '20%' }}
                value={selectedReport}
                options={reports}
                optionLabel="report_name"
                returnValue="_id"
                onChange={handleReportChange}
              />
              {actionType === view && !isReadOnly && (
                <UIButton
                  title={!defaultValues.isResponsive && getTitles('T-10084')}
                  startIcon={<EditButton iconStyle={Styles.editIcon} />}
                  style={Styles.editBtn}
                  onClick={() => {
                    dispatch(setLabReportCurrRender(edit));
                  }}
                />
              )}
            </Box>
            <>
              {reportSections.map((item, index) => (
                <ReportTable
                  key={index}
                  title={item.section_name}
                  sectionId={item._id}
                  items={item.test_fields}
                  onChange={handleChange}
                  disabled={actionType === view}
                  accordionIndex={index}
                  openAccordion={openAccordion}
                  setOpenAccordion={setOpenAccordion}
                  onNext={handleNextAccordion}
                  onPrev={onPrev}
                  onsubmit={handleSubmit}
                  isLastAccordion={index === reportSections.length - 1}
                  showSubmitButton={false}
                  microscopicExamination={microscopicExamination}
                  setMicroScopicExamination={setMicroScopicExamination}
                  remarks={remarks}
                  setRemarks={setRemarks}
                />
              ))}
            </>
          </Box>

          {selectedReport && !isRes && (
            <>
              <Box sx={Styles.subC}>
                <Text style={Styles.flex}>{getTitles('T-11714')}</Text>
              </Box>
              <UITextInputWithTitle
                rows={2}
                onChange={(e) => setMicroScopicExamination(e.target.value)}
                value={microscopicExamination}
                disabled={actionType === view}
              />

              <Box sx={Styles.subC}>
                <Text style={Styles.flex}>{getTitles('T-10660')}</Text>
              </Box>
              <UITextInputWithTitle
                rows={2}
                onChange={(val) => setRemarks(val.target.value)}
                value={remarks}
                disabled={actionType === view}
              />
            </>
          )}
          {reportSections.length > 0 && (
            <ModalButtons
              btnPositions={isRes ? 'center' : 'start'}
              disabled={false}
              onPrimaryBtnClick={handleSubmit}
              primaryBtnTitle={
                actionType === edit
                  ? getTitles('T-10046')
                  : actionType === view
                    ? getTitles('T-11103')
                    : getTitles('T-10394')
              }
            />
          )}
        </Box>
      </Box>
    </Screen>
  );
};
