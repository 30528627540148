import React, { useEffect, useRef } from 'react';

import { defaultValues } from '../../constants';
import { style } from './styles';
import { Header } from '../../components';
import { Sidebar } from '../side-bar';
import { LoadingScreen } from '../loading-screen';
import { useDispatch, useSelector } from 'react-redux';
import { getCollapse, getMainMenu, getScroll, getUserData } from '../../services/redux/selectors';
import { setCollapse } from '../../services/redux/slices';
import { Box } from '@mui/material';

function UserScreenWithHeader(props) {
  const scroll = useSelector(getScroll);
  const scrollableDivRef = useRef(null);
  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [scroll]);
  return (
    <div style={style.container}>
      <LoadingScreen isLoading={props.isLoading} />

      <Box sx={{ width: '100%' }}>
        <Header {...props} />
      </Box>
      <div ref={scrollableDivRef} style={style.childrenContainer}>
        {props.children}
      </div>
    </div>
  );
}

function ScreenWithSidebar(props) {
  const dispatch = useDispatch();
  const scroll = useSelector(getScroll);
  const scrollableDivRef = useRef(null);
  const mainMenu = useSelector(getMainMenu);

  const userData = useSelector(getUserData);
  const collapse = useSelector(getCollapse);
  const { employee } = defaultValues.userRole;
  const res = defaultValues.isResponsive;

  useEffect(() => {
    if ([employee].includes(userData?.user_role)) {
      const permissionIds = userData?.employee_permissions.map((perm) => perm.permission_id) || [];

      dispatch(
        setCollapse(permissionIds.includes(defaultValues.employeeRol.callCenterAgents) && !res)
      );
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.user_role === 4 && !defaultValues.isResponsive) {
      dispatch(setCollapse([1, 15].includes(mainMenu)));
    }
    if (userData?.user_role === 4 && defaultValues.isResponsive) {
      dispatch(setCollapse(false));
    }
  }, [mainMenu, userData]);

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [scroll]);

  return (
    <div style={style.sidebarContainer}>
      <LoadingScreen isLoading={props.isLoading} />
      {!defaultValues.isResponsive && (
        <div style={style.leftside(collapse)}>
          <Sidebar />
        </div>
      )}
      <div style={style.subContainer(collapse)}>
        <Header {...props} />
        <div style={style.childrenContainer} ref={scrollableDivRef}>
          {props.children}
        </div>
      </div>
    </div>
  );
}

function UserScreenWithOutHeader(props) {
  const scroll = useSelector(getScroll);
  const scrollableDivRef = useRef(null);
  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [scroll]);
  return (
    <div style={style.container}>
      <LoadingScreen isLoading={props.isLoading} />
      <div
        style={{ ...style.childrenContainer, height: 'calc(100% - 10px)' }}
        ref={scrollableDivRef}>
        {props.children}
      </div>
    </div>
  );
}

export const Screen = (props) => {
  if (props.showHeader) {
    return <UserScreenWithHeader {...props} />;
  } else if (props.showSideBar) {
    return <ScreenWithSidebar {...props} />;
  } else {
    return <UserScreenWithOutHeader {...props} />;
  }
};
