import { put, takeLatest } from 'redux-saga/effects';
import { KEYS } from '../../../constants';
import {
  addAdjustmentProductFailure,
  addAdjustmentProductRequest,
  addAdjustmentProductSuccess,
  addInventoryProductFailure,
  addInventoryProductRequest,
  addInventoryProductSuccess,
  addOutwardProductFailure,
  addOutwardProductRequest,
  addOutwardProductSuccess,
  getInventoryProductListDetailsFailure,
  getInventoryProductListDetailsRequest,
  getInventoryProductListDetailsSuccess,
  getInventoryProductListFailure,
  getInventoryProductListRequest,
  getInventoryProductListSuccess,
  getInwardEmployeeFailure,
  getInwardEmployeeRequest,
  getInwardEmployeeSuccess,
  getOutwardProductHistoryFailure,
  getOutwardProductHistoryRequest,
  getOutwardProductHistorySuccess,
  getOutwardProductListFailure,
  getOutwardProductListRequest,
  getOutwardProductListSuccess,
  updateInventoryProductFailure,
  updateInventoryProductRequest,
  updateInventoryProductSuccess
} from '../slices';
import {
  addInventoryAdjustmentProductsAPI,
  addInventoryOutwardProductsAPI,
  addInventoryProductsAPI,
  getEmployeeByPermissionApi,
  getInventoryProductDetailsAPI,
  getInventoryProductsAPI,
  getOutwardProductsAPI,
  getOutwardProductsHistoryAPI,
  updateInventoryProductsAPI
} from '../../api';

function* getInwardEmployees(action) {
  try {
    const response = yield getEmployeeByPermissionApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getInwardEmployeeSuccess(response.data));
    } else {
      yield put(getInwardEmployeeFailure(response.data));
    }
  } catch (error) {
    yield put(getInwardEmployeeFailure(error.response.data));
  }
}

function* getInventoryProducts(action) {
  try {
    const response = yield getInventoryProductsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getInventoryProductListSuccess(response.data));
    } else {
      yield put(getInventoryProductListFailure(response.data));
    }
  } catch (error) {
    yield put(getInventoryProductListFailure(error.response.data));
  }
}

function* addInventoryProducts(action) {
  try {
    const response = yield addInventoryProductsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addInventoryProductSuccess(response.data));
    } else {
      yield put(addInventoryProductFailure(response.data));
    }
  } catch (error) {
    yield put(addInventoryProductFailure(error.response.data));
  }
}

function* updateInventoryProducts(action) {
  try {
    const response = yield updateInventoryProductsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateInventoryProductSuccess(response.data));
    } else {
      yield put(updateInventoryProductFailure(response.data));
    }
  } catch (error) {
    yield put(updateInventoryProductFailure(error.response.data));
  }
}

function* getInventoryProductDetails(action) {
  try {
    const response = yield getInventoryProductDetailsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getInventoryProductListDetailsSuccess(response.data));
    } else {
      yield put(getInventoryProductListDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getInventoryProductListDetailsFailure(error.response.data));
  }
}

function* getOutwardProductList(action) {
  try {
    const response = yield getOutwardProductsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getOutwardProductListSuccess(response.data));
    } else {
      yield put(getOutwardProductListFailure(response.data));
    }
  } catch (error) {
    yield put(getOutwardProductListFailure(error.response.data));
  }
}

function* addOutwardProducts(action) {
  try {
    const response = yield addInventoryOutwardProductsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addOutwardProductSuccess(response.data));
    } else {
      yield put(addOutwardProductFailure(response.data));
    }
  } catch (error) {
    yield put(addOutwardProductFailure(error.response.data));
  }
}

function* addAdjustmentProducts(action) {
  try {
    const response = yield addInventoryAdjustmentProductsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addAdjustmentProductSuccess(response.data));
    } else {
      yield put(addAdjustmentProductFailure(response.data));
    }
  } catch (error) {
    yield put(addAdjustmentProductFailure(error.response.data));
  }
}

function* getOutwardProductsHistory(action) {
  try {
    const response = yield getOutwardProductsHistoryAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getOutwardProductHistorySuccess(response.data));
    } else {
      yield put(getOutwardProductHistoryFailure(response.data));
    }
  } catch (error) {
    yield put(getOutwardProductHistoryFailure(error.response.data));
  }
}

export function* inventorySaga() {
  yield takeLatest(getInwardEmployeeRequest.type, getInwardEmployees);
  yield takeLatest(getInventoryProductListRequest.type, getInventoryProducts);
  yield takeLatest(addInventoryProductRequest.type, addInventoryProducts);
  yield takeLatest(updateInventoryProductRequest.type, updateInventoryProducts);
  yield takeLatest(getInventoryProductListDetailsRequest.type, getInventoryProductDetails);
  yield takeLatest(getOutwardProductListRequest.type, getOutwardProductList);
  yield takeLatest(addOutwardProductRequest.type, addOutwardProducts);
  yield takeLatest(addAdjustmentProductRequest.type, addAdjustmentProducts);
  yield takeLatest(getOutwardProductHistoryRequest.type, getOutwardProductsHistory);
}
