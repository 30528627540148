import React, { useEffect, useState } from 'react';
import { Box, Divider, IconButton, ListSubheader, Menu, MenuItem } from '@mui/material';
import { Text } from '../text';
import { style } from './style';
import sortUp from '../../assets/icons/sort-up.png';
import { Close } from '@mui/icons-material';
import { color } from '../../theme';
import { UIRadioButton } from '../radio-buttons';
import { getTitles } from '../../utils';

// Demo  sort options
// options = [
//   { key: 'Ascending', value: 'asc' },
//   { key: 'Descending', value: 'desc' }
// ];

export const UISort = ({
  activeOption,
  selectedOption,
  iconStyle,
  dropdownOptions,
  showTitle = true
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeOp, setActiveOp] = useState(null);
  const [sortOptions, setSortOptions] = useState([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setActiveOp(activeOption);
  }, [activeOption]);

  useEffect(() => {
    if (dropdownOptions) {
      setSortOptions(dropdownOptions);
    }
  }, [dropdownOptions]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (data) => {
    selectedOption && selectedOption(data);
    setActiveOp(data);
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box style={style.toolsIconContainer} onClick={handleClick}>
        <img style={style.toolsIcons(iconStyle, showTitle)} src={sortUp} alt="Sort Icon" />
        {showTitle && <Text variant="D20">{getTitles('T-10075')}</Text>}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        style={{
          background: anchorEl !== null ? color.palette.backDropColor : 'none'
        }}
        PaperProps={{
          style: style.menuBox
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <ListSubheader>
          <Box sx={style.menuHeader}>
            <Text variant="D18">{getTitles('T-10076')}</Text>
            <IconButton sx={style.marginAuto} onClick={() => setAnchorEl(null)}>
              <Close sx={style.closeBtn} />
            </IconButton>
          </Box>
        </ListSubheader>
        <Divider sx={style.divider} />
        {sortOptions &&
          sortOptions.map((item, index) => (
            <MenuItem key={index + 1} onClick={() => handleChange(item.value)} sx={style.menuText}>
              <Box
                sx={{ ...style.menuItem, fontWeight: item.value === activeOp ? 'bold' : 'normal' }}>
                {item.key}
                <UIRadioButton
                  size="23px"
                  sx={style.marginLeft}
                  containerStyle={{ justifyContent: 'end' }}
                  checked={item.value === activeOp}
                />
              </Box>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};
