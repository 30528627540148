import { Box, IconButton } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import { color } from '../../theme';
import { Text } from '../text';
import { getTitles, isImageUrl, isVideoUrl } from '../../utils';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import Delete from '../../assets/icons/IcDelete.png';
import Image from '../../assets/icons/backup.png';
import { defaultValues } from '../../constants';

const isRes = defaultValues.isMobile;

const Styles = {
  fieldCont: (style) => ({
    width: isRes ? '125px' : '250px',
    height: isRes ? '125px' : '250px',
    border: '3px dashed',
    borderColor: color.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    position: 'relative',
    ...style
  }),
  sub: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer'
  },
  imgIcon: { width: '25px', height: '20px' },
  Img: {
    width: '100%',
    height: '84%',
    position: 'absolute',
    top: 0,
    objectFit: 'cover',
    borderRadius: '5px'
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '5px'
  },
  leftIcon: {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    color: color.primary,
    backgroundColor: 'inherit'
  },
  right: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    color: color.primary,
    backgroundColor: 'inherit'
  },
  iconCont: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  bubblesCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100% - 80px)'
  },
  bubble: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: color.tertiaryText,
    margin: '0 2px',
    transition: 'transform 0.3s ease'
  },
  activeBubble: {
    transform: 'scale(1.5)'
  },
  deIcon: { width: 'auto', display: 'flex' },
  title: {
    marginBottom: '5px'
  },
  asterisk: {
    color: color.errorText
  },
  videoCont: {
    width: '100%',
    position: 'absolute',
    height: '84%',
    top: 0
  }
};

export const MultiImage = ({
  title,
  images,
  onImagesChange,
  prevImg,
  imageToDelete,
  disabled = false,
  showAdd = true,
  showBubble = true,
  variant = 'D22',
  containerStyle,
  error,
  required,
  accept = 'image/*'
}) => {
  const fileInputRef = useRef(null);
  const [selectedImages, setSelectedImages] = useState(images || []);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (prevImg) {
      setSelectedImages(prevImg);
    }
  }, [prevImg]);

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);

    const newImages = [...selectedImages, ...files];

    setSelectedImages(newImages);
    setCurrentIndex(newImages.length - 1);

    onImagesChange(newImages);

    event.target.value = null;
  };

  const handlePrevClick = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? selectedImages.length - 1 : prevIndex - 1));
  };

  const handleNextClick = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === selectedImages.length - 1 ? 0 : prevIndex + 1));
  };

  const handleAddClick = (e) => {
    e.stopPropagation();
    fileInputRef.current.click();
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();

    const imageToBeDeleted = selectedImages[currentIndex];
    if (imageToDelete) {
      imageToDelete(imageToBeDeleted);
    }
    const newImages = selectedImages.filter((_, index) => index !== currentIndex);
    setSelectedImages(newImages);
    setCurrentIndex(0);
    onImagesChange(newImages);
  };

  const renderFile = () => {
    if (
      selectedImages[currentIndex]?.type?.startsWith('video/') ||
      isVideoUrl(selectedImages[currentIndex])
    ) {
      return (
        <Box sx={Styles.videoCont}>
          <video
            src={
              selectedImages[currentIndex] instanceof File
                ? URL.createObjectURL(selectedImages[currentIndex])
                : selectedImages[currentIndex]
            }
            alt={`Selected media ${currentIndex + 1}`}
            style={Styles.video}
            controlsList="nodownload"
            controls
          />
        </Box>
      );
    } else if (
      selectedImages[currentIndex]?.type?.startsWith('image/') ||
      isImageUrl(selectedImages[currentIndex])
    ) {
      return (
        <img
          src={
            selectedImages[currentIndex] instanceof File
              ? URL.createObjectURL(selectedImages[currentIndex])
              : selectedImages[currentIndex]
          }
          alt={`Selected media ${currentIndex + 1}`}
          style={Styles.Img}
        />
      );
    }
  };

  return (
    <Box>
      {title && (
        <Text variant={variant} style={Styles.title}>
          {title}
          {required && <span style={Styles.asterisk}>*</span>}
        </Text>
      )}
      <Box sx={Styles.fieldCont(containerStyle)}>
        <input
          type="file"
          accept={accept}
          multiple
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          disabled={disabled}
        />
        {selectedImages.length === 0 ? (
          <Box onClick={() => !disabled && fileInputRef.current.click()} sx={Styles.sub}>
            <img src={Image} style={Styles.imgIcon} />
            <Text style={{ color: color.secondaryText }} variant="D16">
              {getTitles('T-10306')}
            </Text>
            <Text style={{ color: color.secondaryText }} variant="D16">
              {getTitles('T-10305')}
            </Text>
          </Box>
        ) : (
          <>
            {renderFile()}
            {selectedImages.length > 1 && (
              <>
                <IconButton onClick={handlePrevClick} sx={Styles.leftIcon}>
                  <ArrowBackIosSharpIcon />
                </IconButton>
                <IconButton onClick={handleNextClick} sx={Styles.right}>
                  <ArrowForwardIosSharpIcon />
                </IconButton>
              </>
            )}

            <Box sx={Styles.iconCont}>
              <Box sx={Styles.bubblesCont}>
                {selectedImages?.length > 0 &&
                  selectedImages.map((_, index) => (
                    <Box
                      key={index}
                      sx={{
                        ...(showBubble && Styles.bubble),
                        ...(index === currentIndex && showBubble ? Styles.activeBubble : {})
                      }}
                    />
                  ))}
              </Box>
              {!disabled && (
                <Box sx={Styles.deIcon}>
                  <IconButton onClick={handleDeleteClick} sx={{ color: color.tertiaryText }}>
                    <img src={Delete} />
                  </IconButton>
                  {showAdd && (
                    <IconButton onClick={handleAddClick} sx={{ color: color.tertiaryText }}>
                      <AddBoxSharpIcon />
                    </IconButton>
                  )}
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
      {error && <Text variant="fieldError">{error}</Text>}
    </Box>
  );
};
