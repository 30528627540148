import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  initiatePaymentRequest,
  resetECommerceMsg,
  setEcomShippingAddress,
  setIsEcommerce,
  setPaymentUrl,
  statusCheckRequest,
  updateScrollToTop
} from '../../../../services/redux/slices';
import {
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
  SCREEN_PATH
} from '../../../../constants';
import { styles } from './styles';
import {
  NoData,
  Text,
  UIButton,
  UIPhoneInputTitle,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { useFormik } from 'formik';
import { ecomCheckoutSchema, getTitles, resetMessages } from '../../../../utils';
import {
  authStatus,
  getCartItems,
  getEcommerceErrorMsg,
  getEcommerceSuccessMsg,
  getPaymentCallBackUrl,
  getScroll,
  getUserData
} from '../../../../services/redux/selectors';
import { Address } from '../../../common/address';
import { globalStyles } from '../../../../theme';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cartItems = useSelector(getCartItems);
  const isAuthenticated = useSelector(authStatus);
  const scroll = useSelector(getScroll);
  const userData = useSelector(getUserData);
  const successMsg = useSelector(getEcommerceSuccessMsg);
  const paymentUrl = useSelector(getPaymentCallBackUrl);
  const errorMsg = useSelector(getEcommerceErrorMsg);

  const [btnDisabled, setBtndisabled] = useState(false);

  const totalItems = Object.values(cartItems || {});
  const res = defaultValues.isResponsive;

  const { values, errors, touched, handleBlur, setValues, setFieldValue, isValid } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email_id: '',
      phone_number: '',
      address: {
        address_line_one: '',
        address_line_two: '',
        country_id: '',
        state_id: {
          _id: ''
        },
        district_id: {
          _id: ''
        },
        taluka_id: {
          _id: ''
        },
        village_id: {
          _id: ''
        },
        post_code: ''
      }
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: ecomCheckoutSchema
  });

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const order_id = params.get('order_id');
    const transaction_id = params.get('transaction_id');

    dispatch(resetECommerceMsg());
    dispatch(setPaymentUrl(null));
    dispatch(setIsEcommerce(defaultValues.appLogicalTexts.eCommerce));
    dispatch(updateScrollToTop(!scroll));
    if (order_id && transaction_id) {
      dispatch(statusCheckRequest({ transaction_id: transaction_id, order_id: order_id }));
    }
  }, []);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (successMsg === 'S-10141' && paymentUrl) {
        dispatch(setPaymentUrl(null));
        dispatch(resetECommerceMsg());
        window.location.href = paymentUrl;
      }
      if (successMsg === 'S-10142') {
        navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.ORDER_CONFIRMATION}`);
      }
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetECommerceMsg()));
  }, [successMsg, errorMsg]);

  useEffect(() => {
    if (userData) {
      let newValues = {
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        phone_number: userData?.phone_number,
        email_id: userData?.email_id || ''
      };

      if (userData?.shipping_address?.length > 0) {
        newValues = {
          ...newValues,
          address: {
            address_line_one: userData?.shipping_address[0]?.address_line_one || '',
            address_line_two: userData?.shipping_address[0]?.address_line_two || '',
            country_id: userData?.shipping_address[0]?.country_id || '',
            state_id: userData?.shipping_address[0]?.state_id || { _id: '' },
            district_id: userData?.shipping_address[0]?.district_id || { _id: '' },
            taluka_id: userData?.shipping_address[0]?.taluka_id || { _id: '' },
            village_id: userData?.shipping_address[0]?.village_id || { _id: '' },
            post_code: userData?.shipping_address[0]?.post_code || ''
          }
        };
      }

      setValues(newValues);
    }
  }, [userData]);

  const getQuantity = () => {
    let qty = 0;
    totalItems?.map((item) => (qty = qty + item.quantity));
    return qty;
  };
  const totalQuantity = getQuantity();

  const totalPrice = useMemo(() => {
    let price = 0;
    let shippingCharge = 0;
    totalItems?.map((item) => {
      if (item.free_for_employee && isAuthenticated && [1, 2].includes(userData?.user_role)) {
        price = price + 0;
      } else {
        if (item.shipping_charge && item.final_price && item.quantity) {
          price = price + item.final_price * item.quantity + item.shipping_charge * item.quantity;
          shippingCharge = shippingCharge + item.shipping_charge * item.quantity;
        } else {
          price = price + item.final_price * item.quantity;
        }
      }
    });
    return { price, shippingCharge };
  }, [totalQuantity]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handlePayNow = () => {
    let payload = {
      redirect_url: `${window.location.href?.split('?').shift()}`,
      products_list:
        totalItems.length > 0
          ? totalItems.map((elem) => ({
              _id: isAuthenticated ? elem.product_id : elem._id,
              quantity: elem.quantity
            }))
          : []
    };
    if (!isAuthenticated) {
      payload = {
        ...payload,
        ...values,
        address: {
          ...values.address,
          state_id: values.address?.state_id?._id,
          district_id: values.address?.district_id?._id,
          taluka_id: values?.address?.taluka_id?._id,
          village_id: values?.address?.village_id?._id || null
        }
      };
    } else {
      payload = { ...payload, user_id: userData?._id };
    }

    dispatch(setEcomShippingAddress(values.address));
    dispatch(initiatePaymentRequest(payload));
  };

  const handleUpdateProfile = () => {
    dispatch(setIsEcommerce(defaultValues.appLogicalTexts.eCommerce));
    navigate(SCREEN_PATH.PROFILE);
  };

  const ViewDetails = ({ title, subTitle }) => {
    return (
      <Box sx={styles.viewDetailsCont}>
        <Text variant="BoldH20" style={{ ...styles.viewSub, ...styles.viewTitle }}>
          {title}
        </Text>
        <Text variant="BoldH20" style={styles.viewSub}>
          {subTitle}
        </Text>
      </Box>
    );
  };

  const addressData = [
    {
      title: getTitles('T-10363'),
      subTitle: values?.address?.country_id
    },
    {
      title: getTitles('T-10070'),
      subTitle: values?.address?.state_id?.name
    },
    {
      title: getTitles('T-10069'),
      subTitle: values?.address?.district_id?.name
    },
    {
      title: getTitles('T-10071'),
      subTitle: values?.address?.taluka_id?.name
    },
    {
      title: getTitles('T-10068'),
      subTitle: values?.address?.village_id?.name
    },
    {
      title: getTitles('T-10359'),
      subTitle: values?.address?.post_code
    }
  ];
  return (
    <Box sx={styles.superContainer}>
      {!res && <Text variant="boldH32">{getTitles('T-11718')}</Text>}

      <Box sx={styles.container}>
        <Box sx={styles.subCont}>
          <Box sx={styles.nameCont}>
            <UITextInputWithTitle
              name="first_name"
              title={getTitles('T-10041')}
              placeHolder={getTitles('T-10047')}
              value={values.first_name}
              onChange={handleChange}
              onBlur={handleBlur}
              required={true}
              error={touched.first_name && errors.first_name}
              width={styles.width}
              readOnly={isAuthenticated}
            />

            <UITextInputWithTitle
              name="last_name"
              title={getTitles('T-10042')}
              placeHolder={getTitles('T-10048')}
              value={values.last_name}
              onChange={handleChange}
              onBlur={handleBlur}
              required={true}
              error={touched.last_name && errors.last_name}
              width={styles.width}
              readOnly={isAuthenticated}
            />
          </Box>

          <UITextInputWithTitle
            name="email_id"
            title={getTitles('T-10043')}
            placeHolder={getTitles('T-10049')}
            value={values.email_id}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && errors.email}
            readOnly={isAuthenticated}
          />

          <UIPhoneInputTitle
            name="phone_number"
            phoneValue={values.phone_number}
            onChange={(val) => {
              setFieldValue('phone_number', val);
              setBtndisabled(val ? false : true);
            }}
            handleError={(isError) => setBtndisabled(isError)}
            required={true}
            onBlur={handleBlur}
            containerStyles={styles.phone}
            disabled={isAuthenticated}
          />

          <Text variant="BoldH28" style={{ mt: 2 }}>
            {getTitles('T-11660')}
          </Text>

          {!isAuthenticated ? (
            <>
              <Address
                actionType={defaultValues.actionType.edit}
                objects={values.address}
                divider={false}
                type={'address'}
                showPostCode
                isCountryRequired
                isStateRequired
                isDistrictRequired
                isTalukaRequired
                isPostCodeRequired
                addressOneRequired
                onAddressOneChange={(e) =>
                  handleChange({
                    target: { name: 'address.address_line_one', value: e.target.value }
                  })
                }
                onAddressOneBlurred={handleBlur('address.address_line_one')}
                onAddressTwoChange={(e) =>
                  handleChange({
                    target: { name: 'address.address_line_two', value: e.target.value }
                  })
                }
                addressOneErrorCode={
                  touched.address?.address_line_one && errors.address?.address_line_one
                }
                onCountrySelected={(e) =>
                  handleChange({ target: { name: 'address.country_id', value: e.target.value } })
                }
                onCountryBlurred={handleBlur('address.country_id')}
                countryCodeError={touched.address?.country_id && errors.address?.country_id}
                onStateSelected={(val) => {
                  handleChange({
                    target: { name: 'address.state_id', value: val ? val : { _id: '' } }
                  });
                }}
                onStateBlurred={handleBlur('address.state_id')}
                stateCodeError={touched.address?.state_id && errors.address?.state_id?._id}
                onDistrictSelected={(val) =>
                  handleChange({
                    target: {
                      name: 'address.district_id',
                      value: val ? val : { _id: '' }
                    }
                  })
                }
                onDistrictBlurred={handleBlur('address.district_id')}
                districtCodeError={touched.address?.district_id && errors.address?.district_id?._id}
                onTalukaSelected={(val) =>
                  handleChange({
                    target: { name: 'address.taluka_id', value: val ? val : { _id: '' } }
                  })
                }
                onTalukaBlurred={handleBlur('address.taluka_id')}
                talukaCodeError={touched.address?.taluka_id && errors.address?.taluka_id?._id}
                onVillageSelected={(val) =>
                  handleChange({
                    target: { name: 'address.village_id', value: val ? val : { _id: '' } }
                  })
                }
                villageCodeError={touched.address?.village_id && errors.address?.village_id?._id}
                onVillageBlurred={handleBlur('address.village_id')}
                onPostCodeBlurred={handleBlur('address.post_code')}
                postCodeError={touched.address?.post_code && errors.address?.post_code}
                onPostCodeChange={(val) => {
                  handleChange({ target: { name: 'address.post_code', value: val.target.value } });
                }}
                addressLineContStyle={styles.phone}
                commonContStyle={styles.commonContStyle}
                isAuth={false}
              />
            </>
          ) : (
            <Box sx={styles.addressCont(userData?.shipping_address.length > 0)}>
              {userData?.shipping_address.length > 0 && (
                <Box sx={styles.addressSubCont}>
                  <Text variant="BoldH20" style={{ mb: 2 }}>
                    {[values.address_line_one, values.address_line_two]
                      .filter((el) => ![null, undefined, ''].includes(el))
                      .join(',')}
                  </Text>

                  {addressData?.map((item, idx) => (
                    <ViewDetails key={idx} title={item.title} subTitle={item.subTitle} />
                  ))}
                </Box>
              )}
              <Text variant="D20" style={globalStyles.title} onClick={handleUpdateProfile}>
                {userData?.shipping_address.length > 0
                  ? getTitles('T-11721')
                  : getTitles('T-11061')}
              </Text>
            </Box>
          )}
        </Box>
        <Box sx={styles.subCont}>
          {totalItems?.length > 0 ? (
            <Box sx={styles.superCont}>
              <Box sx={styles.superSubCont}>
                {totalItems.map((elem, idx) => (
                  <Box key={idx} sx={styles.itemsCont}>
                    <img
                      style={styles.img}
                      src={(elem.product_image && elem.product_image[0]) || null}
                    />
                    <Box sx={styles.detailsCont}>
                      <Text variant="BoldH20">{elem.product_name}</Text>
                      <Box sx={styles.detailsSubCont}>
                        <Text variant="BoldH20">
                          {getTitles('T-11643')}&nbsp;
                          <span style={styles.secondaryText}>{elem.quantity}</span>
                        </Text>
                        <Text
                          variant="BoldH20"
                          style={
                            styles.secondaryText
                          }>{`₹${elem.free_for_employee && isAuthenticated ? 0 : elem.final_price || 0}`}</Text>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box sx={styles.subTotalCont}>
                <Box sx={styles.btnCont}>
                  <Text variant="D22">{`${getTitles('T-11530').replace('(in Rs)', '')} :  `}</Text>

                  <span style={styles.price}>{`₹ ${totalPrice?.shippingCharge}`}</span>
                </Box>
                <Box sx={styles.btnCont}>
                  <Text variant="D22">{`${getTitles('T-11664')} :  `}</Text>
                  <span style={styles.price}>{`₹ ${totalPrice?.price}`}</span>
                </Box>
                <Box sx={styles.payNowCont}>
                  <UIButton
                    title={getTitles('T-11717')}
                    style={styles.viewCartBtn(!isValid || btnDisabled)}
                    onClick={handlePayNow}
                    disabled={!isValid || btnDisabled}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <NoData message={getErrorMessage('E-10028')} height="150px" />
          )}
        </Box>
      </Box>
    </Box>
  );
};
