import { Box } from '@mui/material';
import cow5 from '../../../assets/images/cow5.png';
import { color } from '../../../theme';
import { Text } from '../../../ui-kits';

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color.primaryBackground
  },
  img: {
    maxWidth: '300px',
    maxHeight: '300px'
  },
  text: {
    color: color.errorText
  }
};

export const NoDashboard = () => {
  return (
    <Box sx={styles.container}>
      <img src={cow5} style={styles.img} />
      <Text variant="boldH32Sec" style={styles.text}>
        Explore others I will be there soon...
      </Text>
    </Box>
  );
};
