import { defaultValues } from '../../constants';
import { color } from '../../theme';

const isRes = defaultValues.isResponsive;
export const Styles = {
  container: { p: isRes ? 1 : 5 },
  profileAndDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: isRes ? 'column-reverse' : 'row'
  },
  halfWidthContainer: (style) => ({ width: isRes ? '100%' : '65%', ...style }),
  perDetailsContainer: { display: 'flex', rowGap: '10px', columnGap: '20px' },
  halfWidthField: { width: 'calc(47.5% - 5px)' },
  fullWidthField: { maxWidth: '100%' },
  profileContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: isRes ? 'center' : 'self-start'
  },
  columnFor3: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)',
      md: 'repeat(3, 1fr)'
    },
    rowGap: '10px',
    columnGap: '20px',
    width: isRes ? '100%' : '95%'
  },
  commonColumn: (style) => ({
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)',
      md: 'repeat(3, 1fr)'
    },
    rowGap: '10px',
    columnGap: '10px',
    ...style
  }),

  qtrContainer: { width: isRes ? '100%' : '95%' },
  divider: { width: isRes ? '100%' : '60%', my: 1 },
  xsOneColumn: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(1, 1fr)',
      md: 'repeat(2, 1fr)'
    },
    rowGap: '10px',
    columnGap: '20px',
    width: isRes ? '100%' : '65%'
  },
  xs65Two: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)',
      md: 'repeat(2, 1fr)'
    },
    rowGap: '10px',
    columnGap: '20px',
    width: isRes ? '100%' : '65%'
  },
  tableContainer: { width: isRes ? '100%' : '90%', p: 1, boxSizing: 'border-box' },
  locationContainer: { my: 2, display: 'flex', justifyContent: 'space-between' },
  btn: { height: isRes ? '32px' : '48px' },
  tableDiveder: { width: isRes ? '100%' : '60%', mt: 1, mb: isRes ? 1 : 3 },
  responsiveTable: { width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' },
  tData: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  familyMemberCon: {
    width: '100%',
    backgroundColor: color.primaryBackground,
    borderRadius: '5px',
    display: 'flex',
    boxSizing: 'border-box',
    height: '56px',
    p: 1,
    mb: 1,
    alignItems: 'center'
  },
  memDetails: {
    display: 'flex',
    width: '90%',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '5px',
    border: `1px solid ${color.primaryBorder}`
  },
  IconStyle: {
    width: '52px',
    height: '52px',
    borderRadius: '5px',
    backgroundColor: color.primary,
    minWidth: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white', // Set icon color to white
    mr: 1,
    '&:hover': {
      backgroundColor: color.primary, // Keep the same background color on hover
      color: 'white' // Ensure the icon color stays white on hover
    }
  },
  text: { marginY: '12px' },
  rowValueContainerStyle: {
    border: `1px solid transparent`,
    gap: '10px'
  },
  valuesSubStyle: {
    padding: '0px'
  },
  headerContStyle: {
    gap: '10px'
  },

  tableHeading: {
    height: '56px',
    border: `1px solid ${color.primaryBorder}`,
    backgroundColor: color.palette.paleYellow,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    p: 1
  },
  title: {
    textOverflow: 'ellipsis',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 600,
    flex: 1
  },
  documentDetailsContainer: {
    height: 'auto',
    border: isRes ? 'none' : `1px solid ${color.primaryBorder}`,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: isRes ? 'column' : 'row'
  },
  subTitle: {
    textOverflow: 'ellipsis',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 600,
    flex: 1,
    p: isRes ? '2px' : 1
  },
  docsMargin: { width: isRes ? '100%' : '50%', p: isRes ? '2px' : 2 },
  // production details
  milAvgContainer: { width: isRes ? 'calc(100% - 5px)' : 'calc(32% - 5px)' },
  prodContainer: { width: isRes ? 'calc(100% - 5px)' : 'calc(65% - 5px)' },
  // land
  landContainer: { gap: '20px', width: isRes ? '100%' : '65%' },
  fodderContainer: { gap: '20px', width: isRes ? '100%' : '95%' },
  primaryQ: { mt: isRes ? '8px' : '12px' },
  secAns: { width: 'calc(48% - 5px)' },
  landDis: { display: 'flex', justifyContent: 'space-between' },
  secBtn: { height: isRes ? '40px' : '50px', width: isRes ? 'auto' : '120px' },
  // fooder
  foderDisp: { display: 'flex' },
  multiAdd: { display: 'flex', alignItems: 'end', mx: 1 },
  trainingCont: {
    width: '100%',
    backgroundColor: color.primaryBackground,
    borderRadius: '5px',
    display: 'flex',
    boxSizing: 'border-box',
    height: '56px',
    p: 1,
    mb: 1,
    alignItems: 'center'
  },
  trainingContInner: (isAc) => ({
    display: 'flex',
    width: isAc ? '100%' : '90%',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '5px',
    border: `1px solid ${color.primaryBorder}`
  }),
  chipText: { marginTop: '12px' },
  flexWithGap: { display: 'flex', gap: '20px' },
  textWithPadding: { flex: 1, p: 2 },
  iconsCont: { display: 'flex', alignItems: 'center', ml: 1 },
  widPX: { width: '70px', p: 2 },
  phoneField: { maxWidth: '96%' },
  chipTitle: { mt: 1, mb: isRes ? -1 : -2 },
  inputCont: {
    marginTop: '0px'
  },
  mt: { mt: 2 },
  imgPop: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' },
  popT: {
    width: '100%',
    textAlign: 'center',
    marginTop: '10px'
  },
  // auto complete style
  autoCompleteContainer: { marginTop: '8px', marginBottom: 0, padding: 0, paddingRight: 0 },
  autoText: { marginBottom: '5px' }
};
