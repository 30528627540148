import { defaultValues } from '../../../../constants';
import { color, globalStyles } from '../../../../theme';

const res = defaultValues.isResponsive;
export const styles = {
  modalCont: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'fit-content',
    height: 'fit-content',
    left: res ? '50%' : '61%'
  },
  modalChildCont: {
    maxHeight: '70vh',
    overflow: 'auto',
    px: res ? 1 : 10,
    my: 2
  },
  container: {
    height: 'fit-content',
    position: 'relative',
    backgroundColor: color.primaryBackground
  },
  sectionBox2: {
    width: '100%',
    display: 'flex',
    alignItems: res ? 'start' : 'center',
    justifyContent: 'center',
    height: res ? '70vh' : 'none'
  },
  sectionSubBox: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: res ? 'none' : 'center',
    alignItems: 'center',
    height: res ? '100%' : 'none'
  },
  phoneInputBox: {
    width: '60%'
  },
  heading: {
    color: color.primary,
    textAlign: 'center'
  },
  btn: {
    width: '100%',
    marginTop: res ? 'auto' : '20px',
    marginBottom: '20px'
  },
  otpDesc2: {
    ...globalStyles.textAlignCenter,
    color: color.link,
    cursor: 'pointer'
  },
  otpDesc1: {
    ...globalStyles.textAlignCenter,
    color: color.secondaryText,
    marginTop: '10px'
  },
  autoStyle: {
    '& .MuiInputBase-root': {
      paddingRight: '23px !important'
    }
  },
  autoCont: {
    width: res ? '120px' : '130px',
    marginTop: 0,
    marginBottom: 0
  },
  textInp: {
    '& fieldset': { border: 'none' }
  }
};
