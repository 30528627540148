import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getErrorMsg,
  getStateNavDetails,
  getSuccessMsg,
  getVillage,
  getVillageCurrentPage,
  getVillageCurrPage,
  getVillageParams,
  getVillageTotalPage,
  processLoading
} from '../../../services/redux/selectors/manageProccess';

import { toast } from 'react-toastify';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { checkReadOnly, getTitles, resetMessages } from '../../../utils/commonFunction';
import {
  ActionButton,
  EditButton,
  NavigationText,
  Screen,
  Text,
  UILayout,
  UITabs
} from '../../../ui-kits';
import {
  getVillageDetailsRequest,
  getVillageRequest,
  resetManageProcessMsg,
  setVillageActiveStep,
  setVillageData,
  setVillageParams,
  setVillageStep,
  updateDetailsPg,
  updateVillageCurrPage,
  updateVillageStatusRequest
} from '../../../services/redux/slices';
import { AddEditVillage } from '../../HOC/village';
import { color } from '../../../theme';
import { getUserData } from '../../../services/redux/selectors';
import { useLocation } from 'react-router-dom';

export const ManageVillage = () => {
  const styles = {
    title: {
      overflowWrap: 'anywhere',
      textDecoration: 'underline',
      color: color.link,
      cursor: 'pointer'
    },
    columnWidth: '40%',
    subClmWdth: defaultValues.isMobile ? '22%' : '23%',
    overLap: { overflowWrap: 'anywhere' }
  };
  const dispatch = useDispatch();
  const location = useLocation();

  const villageList = useSelector(getVillage);
  const errorMsg = useSelector(getErrorMsg);
  const isLoading = useSelector(processLoading);
  const successMsg = useSelector(getSuccessMsg);
  const details = useSelector(getStateNavDetails);
  const currRender = useSelector(getVillageCurrPage);
  const userData = useSelector(getUserData);
  const totalPages = useSelector(getVillageTotalPage);
  const currentPage = useSelector(getVillageCurrentPage);
  const villageParams = useSelector(getVillageParams);

  const [villages, setVillage] = useState([]);
  const [taluka_id, setTalukaId] = useState('');
  const [state, setState] = useState(false);
  const [district, setDistrict] = useState(false);
  const [taluka, setTaluka] = useState(false);
  const [nextRender, setNextRender] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(null);
  const [renderType, setRenderType] = useState(null);

  const { add, edit, view } = defaultValues.actionType;
  const { process, village } = defaultValues.appLogicalTexts;
  const typeAssign = {
    type: location?.state?.renderType === village ? 2 : null,
    permission_id:
      location?.state?.renderType === village ? defaultValues.employeeRol.villageManager : null
  };

  useEffect(() => {
    setNextRender(false);
  }, []);

  useEffect(() => {
    location?.state && setIsReadOnly(checkReadOnly(userData, location?.state?.access));
    if (location.state?.renderType) {
      setRenderType(location.state?.renderType);
    }
  }, [userData, location?.state]);

  useEffect(() => {
    if (details) {
      setState(details.state);
      setTalukaId(details.taluka_id);
      setTaluka(details.taluk);
      setDistrict(details.district);
      handleVillage();
    }
  }, [details]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (successMsg === 'S-10056') {
        dispatch(updateVillageCurrPage(nextRender));
        dispatch(updateDetailsPg(true));
      }
      if (['S-10021', 'S-10054', 'S-10055'].includes(successMsg)) {
        handleVillage();
      }
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetManageProcessMsg()));
  }, [successMsg, errorMsg]);

  useEffect(() => {
    if (villageList) {
      setVillage(villageList);
    }
  }, [villageList]);

  const handleVillage = (val) => {
    let payload = {
      taluka_id: [details?.taluka_id],
      ...villageParams,
      ...typeAssign,
      ...val
    };
    dispatch(setVillageParams(payload));
    dispatch(getVillageRequest(payload));
  };

  const handleStatus = (id, status) => {
    dispatch(updateVillageStatusRequest({ _id: id, is_active: !status }));
  };

  const handleEdit = (data) => {
    dispatch(getVillageDetailsRequest({ village_id: data._id }));
  };

  const handleSearch = (data) => {
    handleVillage({ taluka_id: [taluka_id], search_text: data || null, page: 1 });
  };

  const handlePagination = (e, data) => {
    handleVillage({ taluka_id: [taluka_id], page: data });
  };

  const tableData = villages.map((item, idx) => [
    renderType === village && {
      title: getTitles('T-11407'),
      values: `${idx + 1}`,
      valueTextStyle: { ml: 3 }
    },
    {
      title: getTitles('T-10068'),
      columnWidth: styles.columnWidth,
      values: (
        <Text
          variant="D20"
          style={styles.title}
          onClick={() => {
            setNextRender(view);
            handleEdit(item);
          }}>
          {item.name}
        </Text>
      )
    },
    {
      title: getTitles('T-10160'),
      columnWidth: styles.subClmWdth,
      values: (
        <Text style={styles.overLap} variant="D20">
          {item.code}
        </Text>
      )
    },
    renderType === process && {
      title: getTitles('T-10103'),
      columnWidth: styles.subClmWdth,
      values: (
        <ActionButton
          status={item.is_active}
          alterTitle={defaultValues.confirmationModel.changeVillageStatus.title(item.is_active)}
          alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
          onStatusChange={() => handleStatus(item._id, item.is_active)}
          isReadOnly={isReadOnly}
        />
      )
    },
    {
      title: getTitles('T-10084'),
      values: (
        <EditButton
          status={item.is_active}
          onClick={() => {
            handleEdit(item);
            setNextRender(edit);
          }}
          isReadOnly={isReadOnly}
        />
      )
    }
  ]);

  if ([add, edit, view].includes(currRender)) {
    return (
      <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-10079')}>
        <AddEditVillage
          currRender={currRender}
          state={state}
          district={district}
          taluka={taluka}
          talukaId={taluka_id}
          isReadOnly={isReadOnly}
          renderType={renderType}
        />
      </Screen>
    );
  }

  return (
    <Screen
      showSideBar={true}
      isLoading={isLoading}
      currentPage={renderType === process ? getTitles('T-10079') : getTitles('T-11383')}>
      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mt: 1 }}>
          {getTitles('T-10079')}
        </Text>
      )}
      <UITabs tabItems={defaultValues.manageProcess.tabList} />
      <NavigationText
        list={[
          { title: getTitles('T-10070'), route: location?.state?.parentRoute },
          {
            title: state,
            route: SCREEN_PATH.MANAGE_DISTRICT,
            state: { state: location?.state }
          },
          {
            title: district,
            route: SCREEN_PATH.MANAGE_TALUK,
            state: { state: location?.state }
          },
          { title: taluka }
        ]}
      />
      <UILayout
        handleSearch={handleSearch}
        onlySearchAlphabets={true}
        searchBarPlaceHolder={getTitles('T-10147')}
        handleAddButton={() => {
          dispatch(updateVillageCurrPage(add));
          dispatch(setVillageData(null));
          dispatch(updateDetailsPg(true));
          dispatch(setVillageStep(null));
          dispatch(setVillageActiveStep(0));
        }}
        tableItems={tableData}
        tableTitle={renderType === process ? getTitles('T-10166') : getTitles('T-11456')}
        showNoData={!villages.length > 0}
        currPage={currentPage}
        handlePagination={handlePagination}
        pageCount={totalPages}
        showPagination={totalPages > 1}
        message={getErrorMessage('E-10033')}
        isReadOnly={isReadOnly}
      />
    </Screen>
  );
};
