import { defaultValues } from '../../constants';
import { color } from '../../theme';

const sm = defaultValues.isMobile;
const res = defaultValues.isResponsive;

export const styles = {
  container: {
    width: '100%',
    height: '100%',
    p: 2,
    boxSizing: 'border-box',
    backgroundColor: color.primaryBackground
  },
  notificationCont: {
    width: '100%',
    height: '65vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: 2,
    boxSizing: 'border-box',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none'
  },
  filterCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    justifyContent: 'end',
    alignItems: 'center',
    mb: 2
  },
  toolsIcons: {
    maxWidth: '100%',
    maxHeight: sm ? '14px' : res ? '18px' : '24px'
  },
  toolsIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: sm ? '10px' : '10px 20px',
    boxSizing: 'border-box',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '8px',
    cursor: 'pointer',
    alignItems: 'center',
    backgroundColor: color.secondaryBackground,
    height: res ? '32px' : '48px'
  },
  markas: {
    color: color.primary,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  loadingCont: {
    width: '100%',
    display: 'flex',
    juastifyContent: 'center'
  }
};
