import { defaultValues } from '../../../../constants';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const styles = {
  mainCont: {
    position: 'relative'
  },
  gridCont: {
    px: 3,
    py: 1,
    display: 'flex',
    height: 'fit-content'
  },
  backBtn: {
    width: '42px',
    height: '28px',
    p: '10px',
    mt: 3
  },
  width: !res && '264px',
  textAreaWidth: '100%',
  editIcon: {
    cursor: 'pointer',
    width: res ? '20px' : md ? '23px' : '26px',
    height: res ? '20px' : md ? '23px' : '26px'
  },
  editBtn: {
    height: '40px',
    position: !res && 'absolute',
    right: !res && '15px',
    top: !res && '15px'
  },
  resEditCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  resEditBtn: {
    padding: res ? '8px' : '5px 20px',
    height: res ? 'fit-content' : '40px',
    width: 'fit-content',
    '&.MuiButton-root': {
      minWidth: '0px'
    },
    '& .MuiButton-startIcon': {
      marginRight: res ? '0px' : '5px',
      marginLeft: '0px'
    },
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    ml: res && 'auto',
    mt: res && 1,
    fontSize: res && '22px'
  },
  buttonCont: {
    marginTop: '0px'
  },
  radioItemTitleStyle: {
    marginRight: '65px'
  }
};
