import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';
const sm = defaultValues.isMobile;
const res = defaultValues.isResponsive;
export const styles = {
  container: {
    width: '100%',
    mt: res ? 1 : 3,
    backgroundColor: color.primaryBackground,
    p: res ? 1 : 3,
    boxSizing: 'border-box'
  },
  tabSuperCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tabSub: (styles) => ({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'end',
    width: '70%',
    ...styles
  }),
  tabCont: (style) => ({
    width: '60%',
    display: 'flex',
    overflow: 'auto',
    gap: '10px',
    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    ...style
  }),
  tab: (isCurrTab) => ({
    borderBottom: `2px solid ${isCurrTab ? color.palette.orange : 'inherit'}`,
    cursor: 'pointer'
  }),
  tabText: (isCurrTab) => ({
    whiteSpace: 'nowrap',
    fontWeight: isCurrTab ? 600 : 400
  }),
  chevron: (show) => ({
    fontSize: '15px',
    visibility: show ? 'visible' : 'hidden',
    cursor: 'pointer'
  }),
  browseContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    backgroundColor: color.palette.blue,
    padding: sm ? '4px' : '7px',
    boxSizing: 'border-box',
    borderRadius: '3px',
    cursor: 'pointer',
    whiteSpace: 'nowrap'
  },
  browseText: {
    color: color.primaryBackground
  },
  browseArrow: {
    color: color.primaryBackground,
    fontSize: res ? '20px' : '25px'
  },
  itemsCont: (style) => ({
    width: '100%',
    mt: 1,
    display: 'flex',
    flexWrap: res ? 'nowrap' : 'wrap',
    overflow: 'auto',
    gap: '10px',
    ...style
  }),
  counterCont: {
    backgroundColor: color.primary
  },
  cartBtnStyle: {
    color: color.tertiaryText
  },
  countStyle: {
    color: color.tertiaryText
  },
  title: {
    whiteSpace: 'nowrap'
  }
};
