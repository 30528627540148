import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const sm = defaultValues.isMobile;
export const styles = {
  container: (style) => ({
    width: '100%',
    borderRadius: '10px',
    height: sm ? '100px' : res ? '200px' : md ? '250px' : '400px',
    position: 'relative',
    padding: sm ? '0px' : '20px',
    boxSizing: 'border-box',
    ...style
  }),
  leftIcon: {
    position: 'absolute',
    left: sm ? -25 : -10,
    top: '50%',
    transform: 'translateY(-50%)',
    color: color.tertiaryText,
    background: 'linear-gradient(90deg, #B5E8B5 0%, #FAEEBD 100%)',
    border: `7px solid ${color.primaryBackground}`
  },
  rightIcon: {
    position: 'absolute',
    right: sm ? -25 : -10,
    top: '50%',
    transform: 'translateY(-50%)',
    color: color.tertiaryText,
    background: 'linear-gradient(90deg, #B5E8B5 0%, #FAEEBD 100%)',
    border: `7px solid ${color.primaryBackground}`
  },
  canvas: (style) => ({
    display: 'flex',
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    ...style
  }),
  icon: {
    fontSize: sm ? '12px' : '25px'
  }
};
