import { Box, Divider } from '@mui/material';
import { getTitles } from '../../../../utils';
import { footerStyles } from '../styles';
import { Email, Phone } from '@mui/icons-material';
import { Text } from '../../../../ui-kits';
import { defaultValues } from '../../../../constants';
import { globalStyles } from '../../../../theme';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/icons/logo.png';

export const CommonFooter = ({ type, social, handleClick }) => {
  return (
    <Box>
      {type !== defaultValues.appLogicalTexts.login && (
        <>
          <Box sx={footerStyles.subContainer(defaultValues.isResponsive)}>
            <Box sx={{ ...footerStyles.flex2 }}>
              <Box sx={footerStyles.logoContainer(defaultValues.isResponsive)}>
                <img style={footerStyles.logo} alt="logo" src={logo} />
              </Box>

              <Box sx={footerStyles.desContainer}>
                <Text variant="D18Open" style={{ ...footerStyles.descText }}>
                  {getTitles('T-10027')}
                </Text>
              </Box>
            </Box>

            <Box sx={footerStyles.servicesCont}>
              <Text variant="D22Open" style={footerStyles.subHeading}>
                {getTitles('T-10034')}
              </Text>

              {defaultValues.footer.support.map((item, index) => (
                <Link to={item.url} key={index} style={{ textDecoration: 'none' }}>
                  <Text variant="D18Open" style={{ ...footerStyles.linkTexts }}>
                    {item.title}
                  </Text>
                </Link>
              ))}
            </Box>
          </Box>
          <Box sx={footerStyles.connectCont}>
            <Text variant="D22Open" style={footerStyles.subHeading}>
              {getTitles('T-10031')}
            </Text>
            <Box sx={footerStyles.socialCont}>
              {social.map((item, idx) => (
                <Box key={idx} onClick={() => handleClick(item)}>
                  {item.icon}
                </Box>
              ))}
            </Box>
          </Box>

          <Divider sx={{ ...globalStyles.divider }} />
        </>
      )}

      <Box sx={footerStyles.labelContainer(defaultValues.isResponsive)}>
        <Box sx={footerStyles.laberRight}>
          <Text
            variant="D16Open"
            style={{
              ...footerStyles.labelText,
              ...footerStyles.copyRight
            }}>
            {getTitles('T-10020')}
          </Text>
        </Box>

        <Box sx={footerStyles.labelRightSec}>
          <Box sx={footerStyles.emailBox}>
            <Phone style={footerStyles.mailIcon} />
            <Text variant="D16Open" style={{ ...footerStyles.mailText }}>
              {defaultValues.footer.phone}
            </Text>
          </Box>

          <Box sx={footerStyles.emailBox}>
            <Email style={footerStyles.mailIcon} />
            <Text variant="D16Open" style={{ ...footerStyles.mailText }}>
              {defaultValues.footer.email}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
