import { Box } from '@mui/material';
import { Text } from '../../../../ui-kits/text';
import { color } from '../../../../theme';

// example of data should pass
// const arr = [{title: 'some title',details: [{ subTitle: 'status', action: any component or string }}];

const styles = {
  superContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  container: {
    width: '100%',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px'
  },
  title: (wrap) => ({
    textOverflow: 'ellipsis',
    width: '100%',
    whiteSpace: wrap ? 'wrap' : 'nowrap',
    overflow: 'hidden'
  }),
  titleCont: {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box'
  },
  accDetails: {
    borderTop: `1px solid ${color.primaryBorder}`,
    borderRadius: '5px'
  },
  detailsCont: {
    width: '100%',
    display: 'flex'
  },
  detailsSubCont: {
    width: '50%',
    padding: '5px',
    boxSizing: 'border-box'
  },
  subTitle: {
    fontWeight: 600
  }
};

export const DetailsCard = ({ contentArray = [] }) => {
  return (
    <div style={styles.superContainer}>
      {contentArray &&
        contentArray.length > 0 &&
        contentArray.map((item, index) => {
          return (
            <Box key={index} sx={styles.container}>
              <Box sx={styles.titleCont}>
                {typeof item.action === 'string' ? (
                  <Text style={{ ...styles.title(), color: color.secondaryText }} variant="D18">
                    {item.title}
                  </Text>
                ) : (
                  item.title
                )}
              </Box>
              <Box sx={styles.accDetails}>
                {item.details?.map((item, idx) => (
                  <Box key={idx} sx={styles.detailsCont}>
                    <Box sx={styles.detailsSubCont}>
                      <Text variant="D18" style={styles.subTitle}>
                        {item.subTitle}
                      </Text>
                    </Box>
                    <Box sx={styles.detailsSubCont}>
                      {typeof item.action === 'string' ? (
                        <Text
                          style={{ ...styles.title(true), color: color.secondaryText }}
                          variant="D18">
                          {item.action}
                        </Text>
                      ) : (
                        item.action
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          );
        })}
    </div>
  );
};
