import { Box } from '@mui/material';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../../constants';
import { CollapsableData, NoData, Text, UIPagination } from '../../../../ui-kits';
import { UIContainer } from '../../../../ui-kits/container';
import { getTitles, resetMessages } from '../../../../utils';
import { styles } from './style';
import { MyOrdersCard } from '../../../common';
import { useDispatch, useSelector } from 'react-redux';
import {
  authStatus,
  getEcommerceErrorMsg,
  getEcommerceSuccessMsg,
  getEcomMyOrder,
  getMyOrderCurrentPage,
  getMyOrderTotalPage
} from '../../../../services/redux/selectors';
import { useEffect, useState } from 'react';
import {
  getMyOrdersRequest,
  resetECommerceMsg,
  setIsEcommerce
} from '../../../../services/redux/slices';
import { toast } from 'react-toastify';

export const EcomMyOrders = () => {
  const dispatch = useDispatch();
  const res = defaultValues.isResponsive;

  const myOrders = useSelector(getEcomMyOrder);
  const isAuthenticated = useSelector(authStatus);
  const successMsg = useSelector(getEcommerceSuccessMsg);
  const errorMsg = useSelector(getEcommerceErrorMsg);
  const totalPages = useSelector(getMyOrderTotalPage);
  const currentPage = useSelector(getMyOrderCurrentPage);

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    dispatch(setIsEcommerce(defaultValues.appLogicalTexts.eCommerce));
    isAuthenticated && dispatch(getMyOrdersRequest({ page: 1 }));
  }, []);

  useEffect(() => {
    myOrders && setOrders(myOrders);
  }, [myOrders]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetECommerceMsg()));
  }, [successMsg, errorMsg]);

  const handlePagination = (_, data) => {
    dispatch(getMyOrdersRequest({ page: data }));
  };

  return (
    <UIContainer styles={styles.mainCont}>
      {!res && (
        <Text variant="boldH32" style={styles.titleTxt}>
          {getTitles('T-11672')}
        </Text>
      )}
      <Box sx={styles.subCont}>
        {orders?.length > 0 ? (
          <>
            {orders?.map((item, idx) => {
              return (
                <CollapsableData
                  key={idx}
                  isOpen={0 === idx}
                  collapseContStyle={styles.collapseHeader}
                  title={
                    <Box sx={styles.titleCont}>
                      <Box sx={styles.orderIdCont}>
                        <Text style={styles.titleBold}>{getTitles('T-11673')}</Text>
                        <Text>{`: ${item?.order_id}`}</Text>
                      </Box>

                      <Box sx={styles.statusBoxSec}>
                        <Text style={styles.statusTxt(item?.status?.id)}>{item?.status?.name}</Text>
                      </Box>
                    </Box>
                  }
                  titleVarient={res ? 'D14' : 'D22'}
                  orderDropdown={true}
                  childContStyle={styles.collapseChildCont}
                  isStatus={false}>
                  {
                    <>
                      {!res && <Box sx={{ ...styles.statusCont, justifyContent: 'start' }}></Box>}
                      <Box sx={styles.orderCardCont}>
                        {item?.products?.map((prod, idx) => {
                          return (
                            <MyOrdersCard
                              key={idx}
                              image={prod?.product_image && prod?.product_image[0]}
                              quantity={prod?.quantity || null}
                              productName={prod?.product_name}
                              orderedDate={item?.ordered_date || null}
                              deliveryDate={
                                prod?.expected_delivery_date || prod?.delivered_date || null
                              }
                              deliveryDateTitle={
                                prod?.expected_delivery_date
                                  ? getTitles('T-11675')
                                  : getTitles('T-11726')
                              }
                              trackingNo={prod?.tracking_number}
                              trackingLink={prod?.tracking_link}
                              deliveryStatus={prod?.delivery_status}
                              totalPrice={prod?.price}
                            />
                          );
                        })}
                      </Box>
                    </>
                  }
                </CollapsableData>
              );
            })}
            <Box sx={styles.paginationBox}>
              <UIPagination
                onChange={handlePagination}
                count={currentPage}
                currentPage={totalPages}
              />
            </Box>
          </>
        ) : (
          <NoData message={getErrorMessage('E-10028')} />
        )}
      </Box>
    </UIContainer>
  );
};
