import { Box } from '@mui/material';
import {
  DatePickers,
  UIPhoneInputTitle,
  UISaveAndAdd,
  UISelect,
  UITextInputWithTitle
} from '../../../../../ui-kits';
import { getTitles } from '../../../../../utils';
import { defaultValues } from '../../../../../constants';
import { globalStyles } from '../../../../../theme';
import { Address } from '../../../../common/address';

const res = defaultValues.isResponsive;

const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    flexWrap: res ? 'wrap' : 'nowrap',
    gap: '10px'
  },
  btnCont: {
    marginTop: '50px'
  },
  title: {
    cursor: 'pointer'
  },
  nameWidth: res ? '100%' : 'calc((100% - 10px)/3)',
  gridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `
    "join join"
    "relieve relieve"
    "salary salary"
    "desig desig"
    "emMode emMode"
    "sector sector"
    `
      : `
    "join relieve salary"
    "desig emMode sector"
    `,
    gridTemplateColumns: res ? 'calc(50% - 5px) calc(50% - 5px)' : '1fr 1fr 1fr',
    gap: '10px'
  },
  joiningCont: {
    gridArea: 'join'
  },
  relievingCont: {
    gridArea: 'relieve'
  },
  salary: {
    gridArea: 'salary'
  },
  designation: {
    gridArea: 'desig'
  },
  modeOfemp: {
    gridArea: 'emMode'
  },
  empSector: {
    gridArea: 'sector'
  }
};

export const PreviousEmploymentForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setBtndisabled,
  handleAddAnother,
  isValid,
  btnDisabled,
  dirty,
  employment
}) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="employer_name"
          title={getTitles('T-10383')}
          placeHolder={getTitles('T-10384')}
          error={touched.employer_name && errors.employer_name}
          value={values.employer_name}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
          width={styles.nameWidth}
        />

        <UIPhoneInputTitle
          phoneValue={values.employer_mobile_number}
          onChange={(val) => {
            setFieldValue('employer_mobile_number', val);
            setBtndisabled(val ? false : true);
          }}
          handleError={(isError) => setBtndisabled(isError)}
          width={styles.nameWidth}
        />
      </Box>

      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="address_line_one"
          title={getTitles('T-10357')}
          placeHolder={getTitles('T-10361')}
          error={touched.address_line_one && errors.address_line_one}
          value={values.address_line_one}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <UITextInputWithTitle
          name="address_line_two"
          title={getTitles('T-10358')}
          placeHolder={getTitles('T-10362')}
          error={touched.address_line_two && errors.address_line_two}
          value={values.address_line_two}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Box>

      <Address
        actionType={defaultValues.actionType.edit}
        objects={values}
        divider={false}
        type={'location'}
        showPostCode={true}
        onCountrySelected={(e) =>
          handleChange({ target: { name: 'country_id', value: e.target.value } })
        }
        onStateSelected={(val) => {
          handleChange({ target: { name: 'state_id', value: val } });
        }}
        onDistrictSelected={(val) => handleChange({ target: { name: 'district_id', value: val } })}
        onTalukaSelected={(val) => handleChange({ target: { name: 'taluka_id', value: val } })}
        onVillageSelected={(val) => handleChange({ target: { name: 'village_id', value: val } })}
        onPostCodeChange={(val) => {
          handleChange({ target: { name: 'post_code', value: val.target.value } });
        }}
      />

      <Box sx={styles.gridCont}>
        <DatePickers
          dateValue={values.date_joining}
          onDateChange={(val) => handleChange({ target: { value: val, name: 'date_joining' } })}
          title={getTitles('T-10378')}
          containerStyles={styles.joiningCont}
          maxDate={values?.date_relieving}
        />

        <DatePickers
          dateValue={values.date_relieving}
          onDateChange={(val) => handleChange({ target: { value: val, name: 'date_relieving' } })}
          title={getTitles('T-10379')}
          containerStyles={styles.relievingCont}
          minDate={values?.date_joining}
        />

        <UITextInputWithTitle
          name="last_salary"
          title={getTitles('T-10382')}
          placeHolder={getTitles('T-10380')}
          error={touched.last_salary && errors.last_salary}
          value={values.last_salary}
          onChange={handleChange}
          onBlur={handleBlur}
          containerStyles={styles.salary}
          titleStyle={globalStyles.text}
        />

        <UITextInputWithTitle
          name="designation"
          title={getTitles('T-10050')}
          placeHolder={getTitles('T-10051')}
          error={touched.designation && errors.designation}
          value={values.designation}
          onChange={handleChange}
          onBlur={handleBlur}
          containerStyles={styles.designation}
        />
        <UISelect
          name="mode_employment"
          title={getTitles('T-10386')}
          placeholder={getTitles('T-10350')}
          options={defaultValues.modeOfEmployment}
          error={touched.mode_employment && errors.mode_employment}
          value={values.mode_employment}
          onChange={handleChange}
          onBlur={handleBlur}
          containerStyles={styles.modeOfemp}
        />

        <UISelect
          name="employment_sector"
          title={getTitles('T-10387')}
          placeholder={getTitles('T-10350')}
          options={defaultValues.employmentSector}
          error={touched.employment_sector && errors.employment_sector}
          value={values.employment_sector}
          onChange={handleChange}
          onBlur={handleBlur}
          containerStyles={styles.empSector}
        />
      </Box>

      <UISaveAndAdd
        title={employment === true ? getTitles('T-10062') : getTitles('T-10477')}
        onAddAnother={handleAddAnother}
        disabled={!isValid || !dirty || btnDisabled}
      />
    </Box>
  );
};
