import { Box } from '@mui/material';

import { employeePreviousemploymentSchema, getTitles } from '../../../../utils';
import { ModalButtons } from '../../../../components';
import { globalStyles } from '../../../../theme';
import { CollapseDropView, NoData } from '../../../../ui-kits';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { PreviousEmploymentForm } from './employment-form';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { updateEmployeeRequest } from '../../../../services/redux/slices';
import { ViewPreviousEmployment } from './view-previous-employment';
const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  },
  btnCont: {
    marginTop: '50px'
  },
  title: {
    cursor: 'pointer'
  }
};

export const EmployeePreviousEmployment = ({
  employeeData,
  employeeId,
  editable,
  handleContinue
}) => {
  const dispatch = useDispatch();

  const [btnDisabled, setBtndisabled] = useState(false);
  const [employment, setEmployment] = useState(true);
  const [previousEmployment, setPreviousEmployment] = useState([]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    dirty,
    isValid,
    setValues,
    resetForm
  } = useFormik({
    initialValues: {
      employer_name: '',
      employer_mobile_number: '',
      company_name: '',
      address_line_one: '',
      address_line_two: '',
      country_id: '',
      state_id: '',
      district_id: '',
      taluka_id: '',
      village_id: '',
      post_code: '',
      date_joining: null,
      date_relieving: null,
      last_salary: '',
      designation: '',
      mode_employment: '',
      employment_sector: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: employeePreviousemploymentSchema
  });

  useEffect(() => {
    if (employeeData && employeeData.length > 0) {
      setPreviousEmployment(employeeData);
    }
  }, [employeeData]);

  const handleSubmit = () => {
    let payload = { _id: employeeId, previous_employment: previousEmployment };
    const obj = { ...values };
    if (employment === true) {
      payload.previous_employment = [...previousEmployment, obj]?.map((el) => ({
        ...el,
        state_id: el.state_id?._id,
        district_id: el.district_id?._id,
        taluka_id: el.taluka_id?._id,
        village_id: el.village_id?._id
      }));
    } else {
      const temp = previousEmployment.map((item, idx) => (idx === employment ? obj : item));
      payload.previous_employment = temp?.map((el) => ({
        ...el,
        state_id: el.state_id?._id,
        district_id: el.district_id?._id,
        taluka_id: el.taluka_id?._id,
        village_id: el.village_id?._id
      }));
    }
    dispatch(updateEmployeeRequest(payload));
    resetForm();
    setEmployment(true);
  };

  const handleAddAnother = () => {
    handleContinue();
    handleSubmit();
  };

  const handleTitleClick = (item, idx) => {
    setBtndisabled(true);
    setValues({
      ...item,
      country_id: item.country_id || '',
      state_id: item.state_id || '',
      district_id: item.district_id || '',
      taluka_id: item.taluka_id || '',
      village_id: item.village_id || '',
      mode_employment: item.mode_employment || '',
      employment_sector: item.employment_sector || '',
      date_joining: dayjs(item.date_joining),
      date_relieving: dayjs(item.date_relieving)
    });

    if (employment === idx) {
      resetForm();
      return setEmployment(true);
    }
    setEmployment(idx);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  if (editable) {
    return (
      <Box sx={styles.container}>
        {previousEmployment.length > 0 &&
          previousEmployment.map((item, idx) => (
            <Box key={idx}>
              <CollapseDropView
                title={item.employer_name}
                handleSubTitleClick={() => handleTitleClick(item, idx)}
                open={employment === idx}
              />
              {employment === idx && (
                <PreviousEmploymentForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setBtndisabled={setBtndisabled}
                  handleAddAnother={handleAddAnother}
                  isValid={isValid}
                  dirty={dirty}
                  btnDisabled={btnDisabled}
                  employment={employment}
                />
              )}
            </Box>
          ))}
        {employment === true && (
          <PreviousEmploymentForm
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setBtndisabled={setBtndisabled}
            handleAddAnother={handleAddAnother}
            isValid={isValid}
            dirty={dirty}
            btnDisabled={btnDisabled}
            employment={employment}
          />
        )}
        <ModalButtons
          onPrimaryBtnClick={handleSubmit}
          primaryBtnTitle={getTitles('T-10339')}
          containerStyle={globalStyles.mt(3)}
          disabled={!isValid || !dirty || btnDisabled}
        />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {employeeData && employeeData.length > 0 ? (
        <ViewPreviousEmployment
          employeeData={employeeData}
          currEmp={values}
          handleSubTitleClick={handleTitleClick}
          index={employment}
        />
      ) : (
        <NoData message={getTitles('T-11061')} height="30vh" />
      )}
    </Box>
  );
};
