import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';

import rootReducer from './rootReducer';
import rootSaga from './sagas';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'process',
    'employee',
    'society',
    'laboratory',
    'insurance',
    'govtOfficial',
    'aiWorker',
    'vet',
    'farmer',
    'cattle',
    'callCenter',
    'inventory',
    'eCommerce',
    'labFarmer'
  ]
};

let sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware)
});

let persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
