import { Box, Divider, Grid } from '@mui/material';
import { CallStatusDropdown, SearchBar, Text, UIButton } from '../../../ui-kits';
import { useEffect, useRef, useState } from 'react';
import { styles } from './styles';
import { defaultValues } from '../../../constants';
import { getTitles } from '../../../utils';
import { AdvancedSearchModal, CallBox, CallerInfoCard, NoteModal } from '..';
import { globalStyles } from '../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCallcenterUserInfo,
  clearCallcenterUsersSugestionList,
  getCallAgentCallerDetailsRequest,
  getCallCenterUserInfoRequest,
  getCallCenterUsersRequest,
  getCallerTasksRequest,
  setCallcenterCurrTab
} from '../../../services/redux/slices';
import {
  getCallcenterCallerTasks,
  getCallcenterCurrTab,
  getCallCenterSuccessMsg,
  getCallcenterUsersList
} from '../../../services/redux/selectors';

export const CallInfoBox = ({
  callState,
  setCallState,
  caller,
  handleCreateTaskModal,
  isLoading
}) => {
  const dispatch = useDispatch();
  const sectionRef = useRef(null);

  const searchOptions = useSelector(getCallcenterUsersList);
  const success = useSelector(getCallCenterSuccessMsg);
  const currTab = useSelector(getCallcenterCurrTab);
  const callerTaskList = useSelector(getCallcenterCallerTasks);

  const [open, setOpen] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [showDrop, setShowDrop] = useState(false);

  const { member, employee } = defaultValues.callCenterUserTypes;
  const { add } = defaultValues.actionType;
  const { callerTask, callerCallHis, callerDetails, myTask } = defaultValues.callCenterCurrTabs;

  useEffect(() => {
    if (success === 'S-10101') {
      dispatch(
        getCallerTasksRequest({ user_id: caller?.user_id, user_type: caller?.user_type?.id })
      );
      handleModalClose();
    }
  }, [success]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sectionRef.current && !sectionRef.current.contains(event.target)) {
        setShowDrop(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchOptions.length > 0) {
      setShowDrop(true);
    }
  }, [searchOptions]);

  const handleModalOpen = () => {
    dispatch(clearCallcenterUsersSugestionList());
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    setShowDrop(false);
    dispatch(clearCallcenterUsersSugestionList());
  };

  const handleNoteModal = (val) => {
    setOpenNote(val);
  };

  const handleSearch = (val) => {
    if (val.search_text) {
      dispatch(getCallCenterUsersRequest(val));
    } else {
      dispatch(clearCallcenterUsersSugestionList());
      dispatch(clearCallcenterUserInfo());
      dispatch(setCallcenterCurrTab(myTask));
      setShowDrop(false);
    }
  };

  const handleCallState = (action) => {
    setCallState(action);
  };

  const handleUserselect = (item) => {
    dispatch(
      getCallCenterUserInfoRequest({
        phone_number: item.phone_number
      })
    );
  };

  const handleCallerTask = () => {
    dispatch(setCallcenterCurrTab(callerTask));
    dispatch(
      getCallerTasksRequest({
        user_type: caller?.user_type,
        user_id: caller?.user_id
      })
    );
  };

  const handleCallerDetailsClick = () => {
    dispatch(getCallAgentCallerDetailsRequest({ caller_user_id: caller.user_id }));
    dispatch(setCallcenterCurrTab(callerDetails));
  };

  return (
    <Box sx={styles.container}>
      <Grid container spacing={2} sx={styles.grid}>
        <Grid item xs={12} md={9}>
          <Grid container sx={styles.searchBarCont}>
            <Grid item sx={styles.searchSub}>
              <SearchBar
                allowOnlyAlphabets={false}
                allowOnlyNumbers={false}
                searchedVlaue={(val) => handleSearch({ search_text: val })}
                placeHolder={getTitles('T-11005')}
                disableSearch={false}
                onFocus={() => setShowDrop(true)}
              />
              {searchOptions?.length > 0 && showDrop && !open && (
                <div style={styles.dropdown} ref={sectionRef}>
                  {searchOptions.map((item, idx) => (
                    <Box key={idx} sx={styles.dropItem} onClick={() => handleUserselect(item)}>
                      {item.full_name}
                    </Box>
                  ))}
                </div>
              )}
            </Grid>
            <Grid item>
              <Text
                variant="secondary"
                style={{ ...globalStyles.linkDesc }}
                onClick={handleModalOpen}>
                {getTitles('T-11343')}
              </Text>
            </Grid>
          </Grid>
          <Divider sx={styles.divider('100%')} />
          <CallerInfoCard caller={caller} />

          {[member, employee].includes(caller?.user_type?.id) && (
            <>
              <Box mt={2} display="flex" gap="10px">
                <UIButton
                  title={getTitles('T-10659')}
                  style={styles.button(currTab === callerDetails)}
                  onClick={handleCallerDetailsClick}
                />
                <UIButton
                  title={`${getTitles('T-11345')}${callerTaskList?.length ? `(${callerTaskList?.length})` : ''}`}
                  style={styles.button(currTab === callerTask)}
                  onClick={handleCallerTask}
                />
                <UIButton
                  title={getTitles('T-11346')}
                  style={styles.button(currTab === callerCallHis)}
                  onClick={() => dispatch(setCallcenterCurrTab(callerCallHis))}
                />
                <UIButton
                  title={getTitles('T-11347')}
                  style={styles.button()}
                  onClick={() => handleNoteModal(true)}
                />
                <UIButton
                  title={getTitles('T-11348')}
                  style={styles.button()}
                  onClick={() => handleCreateTaskModal({ type: caller?.user_type?.id, mode: add })}
                />
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={3} display="flex" flexDirection="column">
          <CallStatusDropdown />
          <CallBox
            type={callState}
            phone_number={callState && caller?.phone_number}
            handleCallState={handleCallState}
          />
        </Grid>
      </Grid>

      {open && (
        <AdvancedSearchModal
          open={open}
          handleModalClose={handleModalClose}
          handleSearch={handleSearch}
          searchOptions={searchOptions}
          isLoading={isLoading}
        />
      )}

      <NoteModal open={openNote} handleClose={() => handleNoteModal(false)} isLoading={isLoading} />
    </Box>
  );
};
