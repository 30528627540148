import { color } from '../../../theme';

export const styles = {
  container: {
    width: '100%',
    backgroundColor: color.primaryBackground,
    padding: '10px 30px',
    boxSizing: 'border-box'
  },
  layoutCont: {
    paddingTop: 0
  },
  text: {
    backgroundColor: color.secondaryBackground,
    p: 1,
    boxSizing: 'border-box'
  },
  layoutSubCont: {
    mt: 0,
    borderRadius: 0
  },
  optionStyles: {
    mb: 0,
    marginTop: 0
  },
  backCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    mb: 1
  }
};
