import { Box } from '@mui/material';
import { Text } from '../text';
import { color } from '../../theme';

const styles = {
  container: (style) => ({
    width: '100%',
    display: 'flex',
    ...style
  }),
  title: (style) => ({
    width: '50%',
    ...style
  }),
  subTitle: (sub, title) => ({
    width: 'calc(100% - 10px)',
    color: sub && color.link,
    textDecoration: sub ? 'underline' : 'none',
    cursor: sub ? 'pointer' : 'text',
    overflowWrap: 'anywhere',
    marginLeft: title ? '0px' : '20px'
  }),
  subCont: {
    width: '100%'
  },
  bullets: {
    marginLeft: '10px',
    marginRight: '10px'
  },
  subsuper: (style) => ({
    width: '50%',
    display: 'flex',
    ...style
  })
};

export const ViewData = ({
  title,
  subTitle,
  handleSubtitle,
  bullets,
  containerStyle,
  isSubTitle = true,
  titleVariant = 'BoldH24',
  subTitleVariant = 'D24',
  titleStyle,
  subTitleCont
}) => {
  return (
    <Box sx={styles.container(containerStyle)}>
      {title && typeof title === 'string' ? (
        <Text variant={titleVariant} style={styles.title(titleStyle)}>
          {bullets && <span style={styles.bullets}>•</span>}
          {title}
        </Text>
      ) : (
        title && title
      )}

      {isSubTitle && (
        <Box sx={styles.subsuper(subTitleCont)}>
          <Text variant={subTitleVariant}>:&nbsp;</Text>
          <Text
            variant={subTitleVariant}
            style={styles.subTitle(handleSubtitle, subTitle)}
            onClick={() => handleSubtitle && handleSubtitle()}>
            {subTitle ? subTitle : '-'}
          </Text>
        </Box>
      )}
    </Box>
  );
};
