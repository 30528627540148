import { Avatar, Box, Button, Dialog, DialogContent } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { color } from '../../theme/color';
import { defaultValues, getErrorMessage } from '../../constants';
import { toast } from 'react-toastify';
import { getTitles } from '../../utils';

const lg = defaultValues.isLarge;

export const UploadPicture = ({
  alt,
  changedImage,
  profilePicture,
  onProPicDelete,
  noDelete = false,
  hideOption = false,
  dialogueContStyle
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const res = defaultValues.isResponsive;
  const sm = defaultValues.isMobile;
  const md = defaultValues.isMedium;

  useEffect(() => {
    setSelectedImage(profilePicture);
  }, [profilePicture]);

  const handleProfile = () => {
    inputRef.current.click();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onProPicDelete ? onProPicDelete() : setSelectedImage(null);
    handleClose();
  };

  const handlefileChange = (e) => {
    if (e.target.files.length > 0) {
      let file = e.target.files[0];

      if (file && file.size > 5000000) {
        toast.error(getErrorMessage('E-10019'));
        e.target.value = '';
      } else if (file && file.size < 5000000) {
        const image = URL.createObjectURL(file);
        changedImage && changedImage(file);
        setSelectedImage(image);
        setOpen(false);
        e.target.value = '';
      }
    }
    e.target.value = '';
  };

  const styles = {
    container: { position: 'relative', width: 'fit-content' },
    imgStyle: {
      width: sm ? '100px' : res ? '150px' : md ? '200px' : '278px',
      height: sm ? '100px' : res ? '150px' : md ? '200px' : '278px'
    },
    optionStyle: {
      width: sm ? '30px' : res ? '50px' : '70px',
      height: sm ? '30px' : res ? '50px' : '70px',
      borderRadius: '40px',
      backgroundColor: color.primaryBackground,
      position: 'absolute',
      boxShadow: 1,
      bottom: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    },
    iconStyle: {
      fontSize: sm ? 20 : res ? 30 : 40
    },
    dialogue: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: '10px',
      boxSizing: 'border-box'
    },
    dBtn: {
      color: color.primaryText,
      textTransform: 'none',
      whiteSpace: 'nowrap',
      fontSize: sm ? '0.875' : md ? '1rem' : '1.25rem'
    },
    dialogueCont: (style) => ({
      position: 'absolute',
      top: sm ? '25%' : res ? '20%' : md ? '25%' : lg ? '40%' : '29%',
      right: res ? '15%' : md ? '2%' : lg ? '10%' : '7%',
      bottom: 'unset',
      '& .MuiModal-backdrop': {
        backgroundColor: 'transparent'
      },
      ...style
    })
  };

  return (
    <Box>
      <Box sx={styles.container}>
        <Avatar alt={alt} src={selectedImage ? selectedImage : ''} sx={styles.imgStyle} />
        {!hideOption && (
          <Box sx={styles.optionStyle}>
            {selectedImage ? (
              <EditOutlinedIcon
                sx={styles.iconStyle}
                onClick={noDelete ? handleProfile : () => setOpen(true)}
              />
            ) : (
              <AddAPhotoIcon sx={styles.iconStyle} onClick={handleProfile} />
            )}

            <Dialog open={open} onClose={handleClose} sx={styles.dialogueCont(dialogueContStyle)}>
              <DialogContent sx={styles.dialogue}>
                <Button onClick={handleProfile} sx={styles.dBtn}>
                  {getTitles('T-10104')}
                </Button>
                <Button onClick={handleDelete} sx={styles.dBtn}>
                  {getTitles('T-10105')}
                </Button>
              </DialogContent>
            </Dialog>
          </Box>
        )}
      </Box>

      <input
        style={{ display: 'none' }}
        type="file"
        accept="image/jpeg"
        ref={inputRef}
        onChange={handlefileChange}
      />
    </Box>
  );
};
