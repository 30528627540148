import { Box } from '@mui/material';
import { CollapseDropView, ViewData } from '../../../../../ui-kits';
import { getTitles } from '../../../../../utils';

const styles = {
  container: {
    width: '100%'
  }
};
export const ViewEmergencyContact = ({ employeeData, handleSubTitleClick, currEmp, index }) => {
  return (
    <Box sx={styles.container}>
      {employeeData &&
        employeeData.length > 0 &&
        employeeData.map((item, idx) => (
          <Box key={idx}>
            <CollapseDropView
              title={item.name}
              handleSubTitleClick={() => handleSubTitleClick(item, idx)}
              open={idx === index}
            />
            {currEmp && idx === index && (
              <>
                <ViewData title={getTitles('T-10439')} subTitle={currEmp.name} />
                <ViewData title={getTitles('T-10440')} subTitle={currEmp.phone_number} />
                <ViewData title={getTitles('T-10365')} subTitle={currEmp.relation} />
                <ViewData
                  title={getTitles('T-10442')}
                  subTitle={`${currEmp.address_line_one} ${currEmp?.address_line_two ? currEmp.address_line_two : ''}`}
                />
                <ViewData title={getTitles('T-10363')} subTitle={currEmp.country_id} />
                <ViewData title={getTitles('T-10070')} subTitle={currEmp?.state_id?.name} />
                <ViewData title={getTitles('T-10069')} subTitle={currEmp?.district_id?.name} />
                <ViewData title={getTitles('T-10071')} subTitle={currEmp?.taluka_id?.name} />
                <ViewData title={getTitles('T-10068')} subTitle={currEmp?.village_id?.name} />
                <ViewData title={getTitles('T-10359')} subTitle={currEmp.post_code} />
              </>
            )}
          </Box>
        ))}
    </Box>
  );
};
