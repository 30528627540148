import { useDispatch, useSelector } from 'react-redux';
import {
  addProductRequest,
  updateDetailsPg,
  updateProductRequest
} from '../../../../services/redux/slices';
import { UIBackButton } from '../../../../ui-kits/back-button';
import { defaultValues } from '../../../../constants';
import { UIContainer } from '../../../../ui-kits/container';
import {
  EditButton,
  UIButton,
  UIRadioButtonWithTitle,
  UISelect,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { getTitles, productMasterSchema } from '../../../../utils';
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { ModalButtons } from '../../../../components';
import { styles } from './style';
import { getDetailsPg } from '../../../../services/redux/selectors';
import { useFormik } from 'formik';
import EditIcon from '@mui/icons-material/Edit';

export const ViewAddEditProduct = ({
  actionType,
  setActionType,
  materialGroup,
  productData,
  units,
  tempConditions,
  isReadOnly
}) => {
  const { add, edit, view } = defaultValues.actionType;
  const isRes = defaultValues.isResponsive;

  const dispatch = useDispatch();

  const showBackButton = useSelector(getDetailsPg);

  const [require, setRequire] = useState(false);
  const [product, setProduct] = useState(null);
  const [btnDisabled, setBtndisabled] = useState(true);

  const {
    values,
    errors,
    touched,
    dirty,
    setValues,
    handleBlur,
    setFieldValue,
    isValid,
    resetForm
  } = useFormik({
    initialValues: {
      name: '',
      material_group: { id: '' },
      description: '',
      offer_scheme: '',
      unit: { _id: '' },
      min_order_qty: '',
      min_stock_qty: '',
      temp_conditions: { _id: '' },
      inspection_interval: '',
      container_required: null
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: productMasterSchema
  });

  useEffect(() => {
    actionType === view ? setRequire(false) : setRequire(true);
  }, [actionType]);

  useEffect(() => {
    if (!showBackButton) {
      handleBackButton();
    }
  }, [showBackButton]);

  useEffect(() => {
    productData && setProduct(productData);
  }, [productData]);

  useEffect(() => {
    if (product && actionType !== add) {
      setValues(product);
    }
  }, [product]);

  const handleBackButton = () => {
    setActionType(null);
    dispatch(updateDetailsPg(false));
  };

  const handleEditButton = () => {
    setActionType(edit);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = {
      ...values,
      min_order_qty: parseFloat(values.min_order_qty),
      min_stock_qty: parseFloat(values.min_stock_qty),
      material_group: values.material_group?.id,
      unit: values.unit?._id,
      temp_conditions: values.temp_conditions._id,
      inspection_interval: values.inspection_interval ? parseInt(values.inspection_interval) : null
    };
    if (actionType === edit) {
      payload._id = product?._id || '';
    }
    actionType === add && dispatch(addProductRequest(payload));
    actionType === edit && dispatch(updateProductRequest(payload));
    setActionType(null);
    dispatch(updateDetailsPg(false));
    resetForm();
  };

  return (
    <>
      {!isRes ? (
        <UIBackButton onClick={handleBackButton} backRoot={styles.backBtn} />
      ) : (
        <Box sx={styles.resEditCont}>
          {actionType === view && !isReadOnly && (
            <UIButton
              startIcon={<EditIcon />}
              style={styles.resEditBtn}
              onClick={handleEditButton}
            />
          )}
        </Box>
      )}
      <UIContainer styles={styles.mainCont}>
        {actionType === view && !isRes && !isReadOnly && (
          <UIButton
            title={getTitles('T-10084')}
            startIcon={<EditButton iconStyle={styles.editIcon} />}
            style={styles.editBtn}
            onClick={handleEditButton}
          />
        )}

        <Grid container spacing={1} sx={styles.gridCont}>
          {actionType !== add && (
            <Grid item xs={isRes ? 12 : 3.3}>
              <UITextInputWithTitle
                name="product_id"
                title={getTitles('T-11457')}
                value={product?.product_id || ''}
                placeHolder={getTitles('T-11458')}
                width={styles.width}
                disabled={true}
              />
            </Grid>
          )}
          <Grid item xs={isRes ? 12 : 3.3}>
            <UISelect
              name="material_group.id"
              title={getTitles('T-10180')}
              value={values.material_group?.id}
              valueToShow={values.material_group?.name}
              options={materialGroup}
              optionLabel="name"
              returnValue={'id'}
              placeholder={getTitles('T-10350')}
              onChange={handleChange}
              error={touched.material_group?.id && errors.material_group?.id}
              onBlur={handleBlur}
              required={require}
              width={styles.width}
              onlyView={actionType === view}
            />
          </Grid>
          <Grid item xs={isRes ? 12 : 3.3}>
            <UITextInputWithTitle
              name="name"
              title={getTitles('T-11437')}
              value={values.name}
              onChange={(val) => {
                handleChange(val);
                setBtndisabled(false);
              }}
              placeHolder={getTitles('T-10586')}
              width={styles.width}
              onBlur={handleBlur}
              error={touched.name && errors.name}
              required={require}
              disabled={actionType === view}
            />
          </Grid>
          <Grid item xs={isRes ? 12 : 6.5}>
            <UITextInputWithTitle
              name="description"
              title={getTitles('T-10109')}
              value={values.description}
              placeHolder={getTitles('T-10096')}
              onChange={(val) => {
                handleChange(val);
              }}
              rows={2}
              width={styles.textAreaWidth}
              disabled={actionType === view}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={styles.gridCont}>
          <Grid item xs={isRes ? 12 : 3.3}>
            <UITextInputWithTitle
              name="offer_scheme"
              title={getTitles('T-11439')}
              placeHolder={getTitles('T-10586')}
              value={values.offer_scheme}
              onChange={(val) => {
                handleChange(val);
              }}
              width={styles.width}
              disabled={actionType === view}
            />
          </Grid>
          <Grid item xs={isRes ? 12 : 3.3}>
            <UISelect
              name="unit._id"
              title={getTitles('T-11440')}
              options={units}
              optionLabel="name"
              returnValue={'_id'}
              placeholder={getTitles('T-10350')}
              required={require}
              width={styles.width}
              value={values.unit?._id}
              valueToShow={values.unit?.name}
              onChange={handleChange}
              error={touched.unit?._id && errors.unit?._id}
              onBlur={handleBlur}
              onlyView={actionType === view}
            />
          </Grid>
          <Grid item xs={isRes ? 12 : 3.3}>
            <UITextInputWithTitle
              name="min_order_qty"
              title={getTitles('T-11441')}
              placeHolder={getTitles('T-11050')}
              width={styles.width}
              required={require}
              allowDecimal={true}
              value={values.min_order_qty}
              onChange={(val) => {
                handleChange(val);
                setBtndisabled(false);
              }}
              onBlur={handleBlur}
              error={touched.min_order_qty && errors.min_order_qty}
              disabled={actionType === view}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={styles.gridCont}>
          <Grid item xs={isRes ? 12 : 3.3}>
            <UITextInputWithTitle
              name="min_stock_qty"
              title={getTitles('T-11442')}
              placeHolder={getTitles('T-11050')}
              width={styles.width}
              required={require}
              allowDecimal={true}
              value={values.min_stock_qty}
              onChange={(val) => {
                handleChange(val);
                setBtndisabled(false);
              }}
              onBlur={handleBlur}
              error={touched.min_stock_qty && errors.min_stock_qty}
              disabled={actionType === view}
            />
          </Grid>
          <Grid item xs={isRes ? 12 : 3.3}>
            <UISelect
              name="temp_conditions._id"
              title={getTitles('T-11443')}
              options={tempConditions}
              optionLabel="name"
              returnValue={'_id'}
              placeholder={getTitles('T-10350')}
              required={require}
              width={styles.width}
              value={values.temp_conditions?._id}
              valueToShow={values.temp_conditions?.name}
              onChange={handleChange}
              error={touched.temp_conditions?._id && errors.temp_conditions?._id}
              onBlur={handleBlur}
              onlyView={actionType === view}
            />
          </Grid>
          <Grid item xs={isRes ? 12 : 3.3}>
            <UITextInputWithTitle
              name="inspection_interval"
              title={getTitles('T-11459')}
              placeHolder={getTitles('T-11268')}
              width={styles.width}
              allowOnlyNumbers={true}
              value={values.inspection_interval}
              onChange={(val) => {
                handleChange(val);
              }}
              onBlur={handleBlur}
              disabled={actionType === view}
            />
          </Grid>
        </Grid>
        <Grid container sx={styles.gridCont}>
          <Grid item xs={isRes ? 12 : 3.3}>
            <UIRadioButtonWithTitle
              name="container_required"
              title={getTitles('T-11444')}
              required={require}
              checkedValue={values.container_required}
              onCheck={(val) => {
                handleChange({ target: { name: 'container_required', value: val } });
                setBtndisabled(false);
              }}
              values={[
                { title: getTitles('T-10705'), value: true },
                { title: getTitles('T-10706'), value: false }
              ]}
              itemTitleStyle={styles.radioItemTitleStyle}
              error={touched.container_required && errors.container_required}
              disabled={actionType === view}
            />
          </Grid>
        </Grid>
        {actionType !== view && (
          <Grid container sx={styles.gridCont}>
            <Grid item xs={isRes ? 12 : 3.3}>
              <ModalButtons
                btnPositions={'flex-start'}
                containerStyle={styles.buttonCont}
                primaryBtnTitle={
                  actionType === add
                    ? getTitles('T-10394')
                    : actionType === edit && getTitles('T-10046')
                }
                onPrimaryBtnClick={actionType !== view && handleSubmit}
                disabled={!isValid || !dirty || btnDisabled}
              />
            </Grid>
          </Grid>
        )}
      </UIContainer>
    </>
  );
};
