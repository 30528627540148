import React, { useState, useRef, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styles } from './styles';
import { Text } from '../../../../ui-kits';
import { defaultValues } from '../../../../constants';

export const MultipleImageCarousel = ({ images, onTitleClick = () => {} }) => {
  const res = defaultValues.isResponsive;
  const sm = defaultValues.isMobile;
  const imageWidth = sm ? 90 : res ? 100 : 130;
  const containerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  useEffect(() => {
    const checkScrollPosition = () => {
      if (containerRef.current) {
        const { scrollWidth, clientWidth, scrollLeft } = containerRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft + clientWidth < scrollWidth - 1);
      }
    };

    checkScrollPosition();

    containerRef.current.addEventListener('scroll', checkScrollPosition);

    return () => {
      containerRef.current?.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);

  const handlePrevClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -containerRef.current.clientWidth,
        behavior: 'smooth'
      });
    }
  };

  const handleNextClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: containerRef.current.clientWidth,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Box sx={styles.container}>
      {canScrollLeft && (
        <IconButton onClick={handlePrevClick} sx={styles.leftIcon}>
          <ArrowBackIosSharpIcon sx={styles.icon} />
        </IconButton>
      )}

      <Box ref={containerRef} sx={styles.carouselWrapper}>
        <Box sx={styles.carouselInner} style={styles.imgCont(imageWidth)}>
          {images.map((item, index) => (
            <Box
              key={index}
              sx={styles.imageContainer}
              style={{ width: `${imageWidth}px` }}
              onClick={() => onTitleClick(item)}>
              <Box sx={styles.imgSubCont}>
                <img src={item.image} alt={`Image ${index + 1}`} style={styles.img} />
              </Box>
              <Text variant="BoldH12" style={styles.imageTitle}>
                {item.title}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>

      {canScrollRight && (
        <IconButton onClick={handleNextClick} sx={styles.rightIcon}>
          <ArrowForwardIosSharpIcon sx={styles.icon} />
        </IconButton>
      )}
    </Box>
  );
};
