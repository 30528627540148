export const styles = {
  btn: {
    height: '35px'
  },
  batchBox: {
    width: '100%',
    mt: 2
  },
  qtyCont: {
    marginTop: '0px'
  }
};
