import { defaultValues } from '../../../constants';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const sm = defaultValues.isMobile;
const mdSm = defaultValues.isMdSmall;

export const styles = {
  backArrowBtn: {
    p: 1,
    marginLeft: '10px'
  },
  btnIcon: {
    fontSize: res ? '25px' : '45px'
  },
  mainCont: {
    px: res ? 1.5 : 3,
    py: res ? 2 : 3,
    width: 'auto',
    borderRadius: '8px',
    marginTop: '20px'
  },
  orderDetailsCont: {
    display: 'flex',
    width: '100%'
  },
  viewDataCont: {
    mb: 0.5
  },
  leftBoxCont: {
    width: res ? '52%' : md ? '60%' : '70%'
  },
  leftTitleTxt: {
    width: res ? '33%' : '25%',
    fontWeight: 600
  },
  leftValTxt: {
    width: res ? '67%' : '75%'
  },
  rightBoxCont: {
    width: res ? '48%' : md ? '40%' : '30%'
  },
  rightTitleTxt: {
    width: '50%',
    fontWeight: 600
  },
  rightTitleTxtRes: {
    width: '40%',
    fontWeight: 600
  },
  rightValTxtRes: {
    width: '60%'
  },
  rightValTxt: {
    width: '50%'
  },
  midorderDetailsCont: {
    display: 'block'
  },
  middleTitleTxt: {
    width: '35%',
    fontWeight: 600
  },
  middleValTxt: {
    width: '65%'
  },
  midCont: {
    display: 'flex',
    width: '100%',
    flexDirection: md ? 'column' : 'row',
    alignItems: md ? 'start' : 'center',
    my: 2,
    flexWrap: 'wrap'
  },
  midChildCont1: {
    width: res ? '100%' : md ? 'auto' : '40%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    mb: md && 1
  },
  midChildCont: {
    width: res ? '100%' : md ? 'auto' : '30%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    mb: md && 1
  },

  midChildContSec: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    mb: md && 1
  },
  fontBold: {
    fontWeight: 600
  },
  productImg: {
    width: res ? '70px' : '90px',
    height: res ? '70px' : '90px'
  },
  col12: '120px',
  col15: '150px',
  col17: '170px',
  col18: '180px',
  col20: '200px',
  col22: '220px',
  col30: '300px',
  uiCont: {
    paddingTop: 0
  },
  uiSubCont: {
    mt: 0,
    p: res ? '0px 8px' : 0,
    border: 0
  },
  tableSubCont: (isList) => ({
    width: 'fit-content',
    minHeight: isList ? '50vh' : 'none'
  }),
  headertxtStyle: {
    whiteSpace: 'pre-wrap'
  },
  statusSelectCont: {
    backgroundColor: 'transparent',
    borderRadius: '5px',
    width: sm ? '100px' : md ? '120px' : '150px',
    minWidth: 'none',
    mx: 0,
    marginTop: '0px'
  },
  statusSelectInput: {
    height: 'auto',
    fontSize: mdSm ? '10px' : res ? '14px' : '20px',
    '& fieldset': {
      border: 'none'
    },
    marginTop: '0px',
    padding: 0,
    '& .MuiSelect-select': {
      padding: mdSm ? '0px 0px 0px 6px' : '0px 0px 0px 8px'
    },
    '& .MuiSelect-icon': {
      right: mdSm ? '30px' : res ? '16px' : md ? '14px' : '30px'
    }
  },
  storeAutoCompleteCont: { marginTop: '0px', marginBottom: 0, padding: 0, paddingRight: 0 },
  storeAutoInput: {
    fontSize: mdSm ? '10px' : res ? '14px' : '1rem',
    marginTop: '0px',
    height: 'auto',
    '& .MuiSelect-select': {
      padding: mdSm ? '5px' : '6px',
      height: 'auto'
    },
    '& .MuiInputBase-root': {
      height: mdSm ? '22px' : res ? '30px' : '40px'
    }
  },
  width: mdSm ? '140px' : res ? '200px' : md ? '235px' : '220px',
  textOverFlow: {
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden'
  },
  dateContainer: {
    mt: res ? 0 : 2
  },

  inputCont: {
    width: res ? '100%' : 'calc(100% - 10px)',
    '& .MuiInputBase-root': {
      height: '42px'
    }
  },
  btn: {
    height: '35px'
  }
};
