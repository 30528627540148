import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const isRes = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const sm = defaultValues.isMobile;
export const styles = {
  container: (showModel) => ({
    top: isRes ? '50%' : '70%',
    left: isRes ? '50%' : 'none',
    right: isRes ? '50%' : '2%',
    transform: isRes ? 'translate(-50%, -50%)' : 'translate(-2%, -70%)',
    minWidth: sm ? '250px' : isRes ? '300px' : md ? '40%' : '30%',
    position: 'absolute',
    bgcolor: color.primaryBackground,
    borderRadius: isRes ? '100px' : '5px',
    boxShadow: 24,
    maxWidth: isRes ? '90%' : '60%',
    width: 'fit-content',
    border: 'none',
    padding: isRes ? 1 : 2,
    boxSizing: 'border-box',
    display: showModel ? 'block' : 'none',
    zIndex: 1111
  }),
  superCont: {
    height: '150px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  superSubCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  itemsCont: {
    width: '100%',
    display: 'flex'
  },
  img: {
    width: '70px',
    maxHeight: '70px'
  },
  detailsCont: {
    width: '70%',
    p: 1,
    boxSizing: 'border-box'
  },
  detailsSubCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  secSub: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },

  checkOutBtn: {
    width: '100%',
    height: '45px',
    backgroundColor: color.secondary,
    color: color.primaryText,
    fontWeight: 600,
    '&:hover': {
      bgcolor: color.primary,
      color: color.tertiaryText
    }
  },
  viewCartBtn: {
    height: '45px',
    border: `2px solid ${color.primary}`,
    backgroundColor: color.primaryBackground,
    color: color.primary,
    '&:hover': {
      bgcolor: color.primary,
      color: color.tertiaryText
    },
    fontWeight: 600
  },
  secondaryText: {
    color: color.secondaryText,
    fontWeight: 600
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    p: 1,
    boxSizing: 'border-box',
    alignItems: 'center'
  },
  titleStyle: (position, style) => ({
    height: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: position,
    ...style
  }),
  closeBtnStyle: { boxSizing: 'border-box', cursor: 'pointer' },
  childContainer: (style, style2) => ({
    width: '100%',
    maxHeight: '60vh',
    overflow: 'auto',
    px: 1,
    boxSizing: 'border-box',
    my: 2,
    ...style,
    ...style2
  }),
  image: (idx) => ({
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    position: 'absolute',
    left: idx === 0 ? 10 : idx === 1 ? 40 : 70
  }),
  subContainer: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center'
  },
  closeIcon2: {
    ml: 'auto',
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    cursor: 'pointer'
  },
  viewCartResBtn: {
    width: sm ? '70px' : isRes && '80px',
    textAlign: 'center',
    backgroundColor: color.secondary,
    borderRadius: '4px',
    cursor: 'pointer'
  }
};
