import { Box } from '@mui/material';
import { styles } from './styles';
import { Text, UIButton } from '../../../../ui-kits';
import { getTitles } from '../../../../utils';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

export const EcomEquipmentCard = ({
  type,
  title,
  subTitle1,
  containerStyles,
  hintStyles,
  image,
  image2,
  image2Style,
  image1Style,
  price,
  priceStyle,
  handleBuyNow
}) => {
  return (
    <Box sx={styles.container(containerStyles)}>
      <Box sx={styles.left}>
        <Text variant="D16" style={styles.hint(hintStyles)}>
          {type}
        </Text>
        <Text variant="boldH32" style={{ mt: 1 }}>
          {title}
        </Text>
        <Text variant="D16" style={styles.subTitle}>
          {subTitle1}
        </Text>
        <UIButton
          style={styles.btn}
          title={getTitles('T-11601')}
          endIcon={<ArrowForwardOutlinedIcon />}
          onClick={handleBuyNow}
        />
      </Box>
      <Box sx={styles.right}>
        {image && <img style={styles.img(image1Style)} src={image} />}
        {image2 && <img style={styles.img(image2Style)} src={image2} />}
        <Text style={styles.price(priceStyle)} variant="D20">{`₹${price || 0}`}</Text>
      </Box>
    </Box>
  );
};
