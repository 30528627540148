import { defaultValues } from '../../../../constants';

const res = defaultValues.isResponsive;
export const styles = {
  titleTxt: {
    marginLeft: !res && '14px'
  },
  modalCont: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: res ? '95%' : '78%',
    height: res ? '95%' : '95%',
    top: res ? '50%' : '50%',
    left: res ? '50%' : '75%'
  },
  childCont: {
    px: res ? 1 : 3,
    my: !res && 2,
    height: '100%',
    maxHeight: '75vh'
  },
  productTxt: {
    fontWeight: 700,
    fontSize: res ? '16px' : '20px'
  },
  batchTxt: {
    fontSize: res ? '15px' : '19px',
    my: 1
  },
  gridCont: {
    display: 'flex',
    height: 'fit-content'
  },
  width: res ? '100%' : '264px',
  autoCompleteContainer: { marginTop: '8px', marginBottom: 0, padding: 0, paddingRight: 0 },
  autoText: { marginBottom: '5px' }
};
