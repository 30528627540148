import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isLoginPage: false,
  successMessage: null,
  errorMessage: null,
  currentPage: null,
  nextPage: null,
  totalPage: null,
  farmers: [],
  farmerParams: null,
  cattles: [],
  cattleCurrentPage: null,
  catteNextPage: null,
  cattleTotalPage: null,
  farmerDetails: null,
  cattleDetails: null,
  cattleReport: null,
  reportCurrRender: null
};

const labFarmerSlice = createSlice({
  initialState,
  name: 'labFarmer',
  reducers: {
    resetLabFarmer(state) {
      state.isLoading = false;
      state.isLoginPage = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
      state.farmers = [];
    },
    resetLabFarmerMsg(state) {
      state.errorMessage = null;
      state.successMessage = null;
    },
    setLabFarmersParams(state, action) {
      state.farmerParams = action.payload;
    },
    setLabFarmersDetails(state, action) {
      state.farmerDetails = action.payload;
    },
    setLabReportCurrRender(state, action) {
      state.reportCurrRender = action.payload;
    },
    getlabFarmersRequest(state) {
      state.isLoading = true;
    },
    getlabFarmerSuccess(state, action) {
      state.isLoading = false;
      state.farmers = action.payload.data.farmers;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
    },
    getlabFarmerFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.farmers = [];
    },
    getlabFarmersCattleRequest(state) {
      state.isLoading = true;
    },
    getlabFarmersCattleSuccess(state, action) {
      state.isLoading = false;
      state.cattles = action.payload.data.cattles;
      state.cattleCurrentPage = action.payload.data.current_page;
      state.catteNextPage = action.payload.data.next_page;
      state.cattleTotalPage = action.payload.data.total_page;
    },
    getlabFarmersCattleFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.cattles = [];
    },
    getlabInchargeCattleDetailsRequest(state) {
      state.isLoading = true;
    },
    getlabInchargeCattleDetailsSuccess(state, action) {
      state.isLoading = false;
      state.cattleDetails = action.payload.data;
    },
    getlabInchargeCattleDetailsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getlabInchargeCattleReportRequest(state) {
      state.isLoading = true;
    },
    getlabInchargeCattleReportSuccess(state, action) {
      state.isLoading = false;
      state.cattleReport = action.payload.data;
    },
    getlabInchargeCattleReportFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    addlabInchargeCattleReportRequest(state) {
      state.isLoading = true;
    },
    addlabInchargeCattleReportSuccess(state, action) {
      state.isLoading = false;
      state.cattleReport = action.payload.data;
      state.successMessage = action.payload.status_code;
    },
    addlabInchargeCattleReportFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updatelabInchargeCattleReportRequest(state) {
      state.isLoading = true;
    },
    updatelabInchargeCattleReportSuccess(state, action) {
      state.isLoading = false;
      state.cattleReport = action.payload.data;
      state.successMessage = action.payload.status_code;
    },
    updatelabInchargeCattleReportFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    setLabInchargeCattleReport(state, action) {
      state.cattleReport = action.payload;
    }
  }
});

export const {
  resetLabFarmerMsg,
  resetLabFarmer,
  setLabReportCurrRender,
  setLabInchargeCattleReport,
  getlabFarmerFailure,
  getlabFarmerSuccess,
  getlabFarmersRequest,
  setLabFarmersParams,
  getlabFarmersCattleFailure,
  getlabFarmersCattleRequest,
  getlabFarmersCattleSuccess,
  setLabFarmersDetails,
  getlabInchargeCattleDetailsFailure,
  getlabInchargeCattleDetailsRequest,
  getlabInchargeCattleDetailsSuccess,
  getlabInchargeCattleReportFailure,
  getlabInchargeCattleReportRequest,
  getlabInchargeCattleReportSuccess,
  addlabInchargeCattleReportFailure,
  addlabInchargeCattleReportRequest,
  addlabInchargeCattleReportSuccess,
  updatelabInchargeCattleReportFailure,
  updatelabInchargeCattleReportRequest,
  updatelabInchargeCattleReportSuccess
} = labFarmerSlice.actions;

export default labFarmerSlice.reducer;
