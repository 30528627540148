import { put, takeLatest } from 'redux-saga/effects';
import {
  addFarmerFailure,
  addFarmerRequest,
  addFarmerSuccess,
  deleteFarmerDocFailure,
  deleteFarmerDocRequest,
  deleteFarmerDocSuccess,
  getFarmerDetailsFailure,
  getFarmerDetailsRequest,
  getFarmerDetailsSuccess,
  getFarmerDistrictFailure,
  getFarmerDistrictRequest,
  getFarmerDistrictSuccess,
  getFarmerFailure,
  getFarmerSuccess,
  getFarmerTalukFailure,
  getFarmerTalukRequest,
  getFarmerTalukSuccess,
  getFarmerVillageFailure,
  getFarmerVillageRequest,
  getFarmerVillageSuccess,
  getFarmersRequest,
  getReligionFailure,
  getReligionRequest,
  getReligionSuccess,
  updateFarmerFailure,
  updateFarmerRequest,
  updateFarmerSuccess,
  uploadFarmerDocFailure,
  uploadFarmerDocRequest,
  uploadFarmerDocSuccess
} from '../slices';
import { KEYS } from '../../../constants';
import {
  addFarmerApi,
  deleteFarmerDocsAPI,
  farmerDetailsAPI,
  getDistrictAPI,
  getFarmerApi,
  getReligionsAPI,
  getTalukAPI,
  getVillageAPI,
  updateFarmerAPI,
  uploadFarmerDocsAPI
} from '../../api';

function* getReligion(action) {
  try {
    const response = yield getReligionsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getReligionSuccess(response.data));
    } else {
      yield put(getReligionFailure(response.data));
    }
  } catch (error) {
    yield put(getReligionFailure(error.response.data));
  }
}

function* addFarmer(action) {
  try {
    const response = yield addFarmerApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addFarmerSuccess(response.data));
    } else {
      yield put(addFarmerFailure(response.data));
    }
  } catch (error) {
    yield put(addFarmerFailure(error.response.data));
  }
}

function* getFarmer(action) {
  try {
    const response = yield getFarmerApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getFarmerSuccess(response.data));
    } else {
      yield put(getFarmerFailure(response.data));
    }
  } catch (error) {
    yield put(getFarmerFailure(error.response.data));
  }
}

function* updateFarmer(action) {
  try {
    const response = yield updateFarmerAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateFarmerSuccess(response.data));
    } else {
      yield put(updateFarmerFailure(response.data));
    }
  } catch (error) {
    yield put(updateFarmerFailure(error.response.data));
  }
}

function* uploadFarmer(action) {
  try {
    const response = yield uploadFarmerDocsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(uploadFarmerDocSuccess(response.data));
    } else {
      yield put(uploadFarmerDocFailure(response.data));
    }
  } catch (error) {
    yield put(uploadFarmerDocFailure(error.response.data));
  }
}

function* deleteFarmerDocs(action) {
  try {
    const response = yield deleteFarmerDocsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(deleteFarmerDocSuccess(response.data));
    } else {
      yield put(deleteFarmerDocFailure(response.data));
    }
  } catch (error) {
    yield put(deleteFarmerDocFailure(error.response.data));
  }
}

function* farmerDetails(action) {
  try {
    const response = yield farmerDetailsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getFarmerDetailsSuccess(response.data));
    } else {
      yield put(getFarmerDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getFarmerDetailsFailure(error.response.data));
  }
}

function* getDistrict(action) {
  try {
    const response = yield getDistrictAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getFarmerDistrictSuccess(response.data));
    } else {
      yield put(getFarmerDistrictFailure(response.data));
    }
  } catch (error) {
    yield put(getFarmerDistrictFailure(error.response.data));
  }
}

function* getTaluk(action) {
  try {
    const response = yield getTalukAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getFarmerTalukSuccess(response.data));
    } else {
      yield put(getFarmerTalukFailure(response.data));
    }
  } catch (error) {
    yield put(getFarmerTalukFailure(error.response.data));
  }
}

function* getVillage(action) {
  try {
    const response = yield getVillageAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getFarmerVillageSuccess(response.data));
    } else {
      yield put(getFarmerVillageFailure(response.data));
    }
  } catch (error) {
    yield put(getFarmerVillageFailure(error.response.data));
  }
}

export function* farmerSaga() {
  yield takeLatest(getReligionRequest.type, getReligion);
  yield takeLatest(addFarmerRequest.type, addFarmer);
  yield takeLatest(getFarmersRequest.type, getFarmer);
  yield takeLatest(updateFarmerRequest.type, updateFarmer);
  yield takeLatest(uploadFarmerDocRequest.type, uploadFarmer);
  yield takeLatest(deleteFarmerDocRequest.type, deleteFarmerDocs);
  yield takeLatest(getFarmerDetailsRequest.type, farmerDetails);
  yield takeLatest(getFarmerDistrictRequest.type, getDistrict);
  yield takeLatest(getFarmerTalukRequest.type, getTaluk);
  yield takeLatest(getFarmerVillageRequest.type, getVillage);
}
