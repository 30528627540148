import { defaultValues } from '../../constants';
import {
  UploadPicture,
  UIButton,
  UIPhoneInputTitle,
  UITextInputWithTitle,
  UICheckBox,
  UISaveAndAdd
} from '../../ui-kits';
import { Box } from '@mui/material';
import { UIContainer } from '../../ui-kits/container';
import { Screen } from '../../ui-kits/screen';
import { profileStyles } from './styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editProfileRequest,
  profilePicChangeRequest,
  profilePicDeleteRequest,
  resetAuthMessages
} from '../../services/redux/slices';
import {
  authLoading,
  getAuthErrorMessage,
  getAuthSuccessMessage,
  getEcommerce,
  getUserData
} from '../../services/redux/selectors';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { getErrorMessage, getSuccessMessage } from '../../constants/getMessage';
import { getTitles, resetMessages } from '../../utils/commonFunction';
import {
  profileSchema,
  profileShipAddressSchema,
  profileBillShipAddressSchema,
  profileBillAddressSchema
} from '../../utils/schemas';
import { Address } from '../../components/common/address';
import { UIBackButton } from '../../ui-kits/back-button';
import { useNavigate } from 'react-router-dom';

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileData = useSelector(getUserData);
  const isLoading = useSelector(authLoading);
  const isEcommerce = useSelector(getEcommerce);
  const successMessage = useSelector(getAuthSuccessMessage);
  const errorMessage = useSelector(getAuthErrorMessage);

  const [btnDisabled, setBtndisabled] = useState(true);
  const [isEcommerceUser, setIsEcommerceUser] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [billRequired, setBillRequired] = useState(false);
  const [shipRequired, setShipRequired] = useState(false);
  const [shipFieldDisable, setShipFieldDisable] = useState(true);
  const [billFieldDisable, setBillFieldDisable] = useState(true);
  const [schema, setSchema] = useState(profileSchema);

  const { values, errors, touched, handleBlur, setFieldValue, setValues, isValid } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email_id: '',
      phone_number: '',
      middle_name: '',
      address: {
        address_line_one: '',
        address_line_two: '',
        country_id: '',
        state_id: { _id: '' },
        district_id: { _id: '' },
        taluka_id: { _id: '' },
        village_id: { _id: '' },
        post_code: ''
      },
      is_shipping_billing_same: false,
      shipping_address: {
        address_line_one: '',
        address_line_two: '',
        country_id: '',
        state_id: { _id: '' },
        district_id: { _id: '' },
        taluka_id: { _id: '' },
        village_id: { _id: '' },
        post_code: ''
      }
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: schema
  });

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
      setBtndisabled(true);
    }
    if (errorMessage && errorMessage !== 'E-10281') {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessages(() => dispatch(resetAuthMessages()));
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (profileData) {
      let newValues = {
        first_name: profileData?.first_name,
        last_name: profileData?.last_name,
        phone_number: profileData?.phone_number,
        email_id: profileData?.email_id || '',
        middle_name: profileData?.middle_name || ''
      };

      newValues = {
        ...newValues,
        is_shipping_billing_same: profileData?.is_shipping_billing_same || false,
        address: {
          address_line_one: profileData?.address?.address_line_one || '',
          address_line_two: profileData?.address?.address_line_two || '',
          country_id: profileData?.address?.country_id || '',
          state_id: profileData?.address?.state_id || { _id: '' },
          district_id: profileData?.address?.district_id || { _id: '' },
          taluka_id: profileData?.address?.taluka_id || { _id: '' },
          village_id: profileData?.address?.village_id || { _id: '' },
          post_code: profileData?.address?.post_code || ''
        },
        shipping_address:
          profileData?.shipping_address?.length > 0
            ? {
                address_line_one: profileData?.shipping_address[0]?.address_line_one || '',
                address_line_two: profileData?.shipping_address[0]?.address_line_two || '',
                country_id: profileData?.shipping_address[0]?.country_id || '',
                state_id: profileData?.shipping_address[0]?.state_id || { _id: '' },
                district_id: profileData?.shipping_address[0]?.district_id || { _id: '' },
                taluka_id: profileData?.shipping_address[0]?.taluka_id || { _id: '' },
                village_id: profileData?.shipping_address[0]?.village_id || { _id: '' },
                post_code: profileData?.shipping_address[0]?.post_code || ''
              }
            : {}
      };

      setValues(newValues);
    }
    if (
      ![defaultValues.userRole.eCommerceUsers, defaultValues.userRole.superAdmin].includes(
        profileData?.user_role
      )
    ) {
      setIsEcommerceUser(false);
    } else {
      setIsEcommerceUser(true);
    }

    if (profileData?.address) {
      setShowAddress(true);
    }
  }, [profileData]);

  useEffect(() => {
    if (values?.is_shipping_billing_same && values?.address) {
      setSchema(profileBillAddressSchema);
      setShipRequired(false);
      values.shipping_address = { ...values.address };
    }
    if (!values?.is_shipping_billing_same) {
      values.shipping_address = {
        address_line_one: '',
        address_line_two: '',
        country_id: '',
        state_id: { _id: '' },
        district_id: { _id: '' },
        taluka_id: { _id: '' },
        village_id: { _id: '' },
        post_code: ''
      };
    }
  }, [values?.is_shipping_billing_same]);

  useEffect(() => {
    let addressLineOne = values?.address?.address_line_one;

    if (addressLineOne && addressLineOne.length > 0) {
      setBillRequired(true);
      setBillFieldDisable(false);
      setSchema(profileBillAddressSchema);
    } else {
      setBillRequired(false);
      setBillFieldDisable(true);
      if (values?.shipping_address?.address_line_one) {
        setSchema(profileShipAddressSchema);
      } else {
        setSchema(profileSchema);
      }
      values.address = {
        address_line_two: '',
        country_id: '',
        state_id: { _id: '' },
        district_id: { _id: '' },
        taluka_id: { _id: '' },
        village_id: { _id: '' },
        post_code: ''
      };
    }
  }, [values?.address?.address_line_one]);

  useEffect(() => {
    let addressLineOne = values?.shipping_address?.address_line_one;

    if (addressLineOne && addressLineOne.length > 0) {
      setShipRequired(true);
      setShipFieldDisable(false);
      setSchema(profileShipAddressSchema);
    } else {
      setShipRequired(false);
      setShipFieldDisable(true);
      if (values?.address?.address_line_one) {
        setSchema(profileBillAddressSchema);
      } else {
        setSchema(profileSchema);
      }

      values.shipping_address = {
        address_line_two: '',
        country_id: '',
        state_id: { _id: '' },
        district_id: { _id: '' },
        taluka_id: { _id: '' },
        village_id: { _id: '' },
        post_code: ''
      };
    }
  }, [values?.shipping_address?.address_line_one]);

  useEffect(() => {
    if (values?.address?.address_line_one && values?.shipping_address?.address_line_one) {
      setBillRequired(true);
      setShipRequired(true);
      setShipFieldDisable(false);
      setBillFieldDisable(false);
      setSchema(profileBillShipAddressSchema);
    } else if (
      values?.address?.address_line_one === '' &&
      values?.shipping_address?.address_line_one === ''
    ) {
      setBillRequired(false);
      setShipRequired(false);
      setShipFieldDisable(true);
      setBillFieldDisable(true);
      setSchema(profileSchema);
    }
  }, [values?.address?.address_line_one, values?.shipping_address?.address_line_one]);

  const handleProfileClick = () => {
    const payload = { ...values };
    delete payload.phone_number;
    payload?.address?.address_line_one === ''
      ? (payload.address = null)
      : (payload.address = {
          ...payload.address,
          state_id: payload?.address?.state_id?._id,
          district_id: payload?.address?.district_id?._id,
          taluka_id: payload?.address?.taluka_id?._id,
          village_id: payload?.address?.village_id?._id || null
        });
    !payload?.shipping_address?.address_line_one
      ? (payload.shipping_address = [])
      : (payload.shipping_address = [
          {
            ...payload.shipping_address,
            state_id: payload?.shipping_address?.state_id?._id,
            district_id: payload?.shipping_address?.district_id?._id,
            taluka_id: payload?.shipping_address?.taluka_id?._id,
            village_id: payload?.shipping_address?.village_id?._id || null
          }
        ]);

    dispatch(editProfileRequest(payload));
  };

  const handlePropicChange = (file) => {
    const payload = { photo_url: file };
    dispatch(profilePicChangeRequest(payload));
  };

  const handlePropicDelete = () => {
    dispatch(profilePicDeleteRequest());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  console.log(errors, 'errors');

  return (
    <Screen
      showSideBar={!isEcommerce}
      showHeader={isEcommerce}
      currentPage={!isEcommerce && getTitles('T-10040')}
      isLoading={isLoading}>
      <UIContainer styles={profileStyles.mainCont}>
        {isEcommerce && (
          <UIBackButton
            backRoot={profileStyles.backArrowBtn}
            buttonStyle={profileStyles.btnIcon}
            onClick={() => navigate(-1)}
          />
        )}
        <Box sx={profileStyles.container}>
          <Box sx={profileStyles.section1}>
            <UploadPicture
              profilePicture={
                profileData?.photo_url &&
                `${profileData?.photo_url}?timestamp=${new Date().getTime()}`
              }
              changedImage={handlePropicChange}
              onProPicDelete={handlePropicDelete}
              dialogueContStyle={isEcommerce && profileStyles.picDialogCont}
            />
          </Box>
          <Box sx={profileStyles.section2}>
            <Box sx={profileStyles.nameCont}>
              <UITextInputWithTitle
                name="first_name"
                required
                value={values.first_name}
                onChange={handleChange}
                maxLength={10}
                onBlur={handleBlur}
                error={touched.first_name && errors.first_name}
                variant="D22"
                title={getTitles('T-10041')}
                containerStyles={profileStyles.nameStyle}
                placeHolder={getTitles('T-10047')}
                allowOnlyAlphabets
              />

              <UITextInputWithTitle
                name="middle_name"
                value={values.middle_name}
                onChange={handleChange}
                maxLength={10}
                onBlur={handleBlur}
                error={touched.middle_name && errors.middle_name}
                variant="D22"
                title={getTitles('T-10337')}
                containerStyles={profileStyles.nameStyle}
                placeHolder={getTitles('T-10338')}
                allowOnlyAlphabets
              />
            </Box>
            <UITextInputWithTitle
              name="last_name"
              required
              value={values.last_name}
              onChange={handleChange}
              maxLength={10}
              onBlur={handleBlur}
              error={touched.last_name && errors.last_name}
              variant="D22"
              title={getTitles('T-10042')}
              containerStyles={profileStyles.nameStyle}
              placeHolder={getTitles('T-10048')}
              allowOnlyAlphabets
            />
            <UIPhoneInputTitle
              required
              variant="D22"
              title={getTitles('T-10003')}
              containerStyles={profileStyles.phoneCont}
              disabled
              phoneValue={values.phone_number}
            />
            <UITextInputWithTitle
              name="email_id"
              value={values.email_id}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email_id && errors.email_id}
              variant="D22"
              title={getTitles('T-10044')}
              placeHolder={getTitles('T-10049')}
            />

            {!showAddress && (
              <>
                <UISaveAndAdd
                  title={getTitles('T-11662')}
                  contStyle={profileStyles.addAnotherbtn}
                  onAddAnother={() => setShowAddress(true)}
                />
                <Box sx={profileStyles.btnsCont}>
                  <UIButton
                    title={getTitles('T-10046')}
                    style={profileStyles.btn}
                    disabled={!isValid || btnDisabled}
                    onClick={handleProfileClick}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
        {showAddress && (
          <Box sx={profileStyles.addressCont}>
            <Address
              objects={values?.address}
              title={getTitles('T-11659')}
              onAddressOneChange={(e) => {
                handleChange({
                  target: { name: 'address.address_line_one', value: e.target.value }
                });
              }}
              onAddressOneBlurred={handleBlur('address.address_line_one')}
              addressOneErrorCode={
                touched.address?.address_line_one && errors.address?.address_line_one
              }
              onAddressTwoChange={(e) => {
                handleChange({
                  target: { name: 'address.address_line_two', value: e.target.value }
                });
              }}
              onCountrySelected={(e) =>
                handleChange({
                  target: { name: 'address.country_id', value: e.target.value }
                })
              }
              onCountryBlurred={handleBlur('address.country_id')}
              countryCodeError={touched.address?.country_id && errors.address?.country_id}
              onStateSelected={(val) =>
                handleChange({
                  target: { name: 'address.state_id', value: val ? val : { _id: '' } }
                })
              }
              onStateBlurred={handleBlur('address.state_id._id')}
              stateCodeError={touched.address?.state_id?._id && errors.address?.state_id?._id}
              onDistrictSelected={(val) =>
                handleChange({
                  target: { name: 'address.district_id', value: val ? val : { _id: '' } }
                })
              }
              onDistrictBlurred={handleBlur('address.district_id._id')}
              districtCodeError={
                touched.address?.district_id?._id && errors.address?.district_id?._id
              }
              onTalukaSelected={(val) =>
                handleChange({
                  target: { name: 'address.taluka_id', value: val ? val : { _id: '' } }
                })
              }
              onTalukaBlurred={handleBlur('address.taluka_id._id')}
              talukaCodeError={touched.address?.taluka_id?._id && errors.address?.taluka_id?._id}
              onVillageSelected={(val) =>
                handleChange({
                  target: { name: 'address.village_id', value: val ? val : { _id: '' } }
                })
              }
              onPostCodeChange={(e) => {
                handleChange({
                  target: { name: 'address.post_code', value: e.target.value }
                });
              }}
              onPostCodeBlurred={handleBlur('address.post_code')}
              postCodeError={touched.address?.post_code && errors.address?.post_code}
              addressOneDisabled={!isEcommerceUser}
              addressTwoDisabled={!isEcommerceUser || billFieldDisable}
              countryDisabled={!isEcommerceUser || billFieldDisable}
              stateDisabled={!isEcommerceUser || billFieldDisable}
              districtDisabled={!isEcommerceUser || billFieldDisable}
              talukaDisabled={!isEcommerceUser || billFieldDisable}
              villageDisabled={!isEcommerceUser || billFieldDisable}
              postCodeDisabled={!isEcommerceUser || billFieldDisable}
              isCountryRequired={billRequired}
              isStateRequired={billRequired}
              isDistrictRequired={billRequired}
              isTalukaRequired={billRequired}
              isPostCodeRequired={billRequired}
              addressOneRequired={billRequired}
            />

            <UICheckBox
              title={getTitles('T-11661')}
              checked={values?.is_shipping_billing_same}
              containerStyle={profileStyles.checkBox}
              onChange={() => {
                if (values?.address?.address_line_one !== '') {
                  handleChange({
                    target: {
                      name: 'is_shipping_billing_same',
                      value: !values.is_shipping_billing_same
                    }
                  });
                }
              }}
            />
            {!values?.is_shipping_billing_same && (
              <Address
                objects={values?.shipping_address}
                title={getTitles('T-11660')}
                dropdownAddressStyle={profileStyles.addressDropdown}
                onAddressOneChange={(e) => {
                  handleChange({
                    target: { name: 'shipping_address.address_line_one', value: e.target.value }
                  });
                }}
                onAddressOneBlurred={handleBlur('shipping_address.address_line_one')}
                addressOneErrorCode={
                  touched.shipping_address?.address_line_one &&
                  errors.shipping_address?.address_line_one
                }
                onAddressTwoChange={(e) => {
                  handleChange({
                    target: { name: 'shipping_address.address_line_two', value: e.target.value }
                  });
                }}
                onCountrySelected={(e) =>
                  handleChange({
                    target: { name: 'shipping_address.country_id', value: e.target.value }
                  })
                }
                onCountryBlurred={handleBlur('shipping_address.country_id')}
                countryCodeError={
                  touched.shipping_address?.country_id && errors.shipping_address?.country_id
                }
                onStateSelected={(val) =>
                  handleChange({ target: { name: 'shipping_address.state_id', value: val } })
                }
                onStateBlurred={handleBlur('shipping_address.state_id._id')}
                stateCodeError={
                  touched.shipping_address?.state_id?._id && errors.shipping_address?.state_id?._id
                }
                onDistrictSelected={(val) =>
                  handleChange({ target: { name: 'shipping_address.district_id', value: val } })
                }
                onDistrictBlurred={handleBlur('shipping_address.district_id._id')}
                districtCodeError={
                  touched.shipping_address?.district_id?._id &&
                  errors.shipping_address?.district_id?._id
                }
                onTalukaSelected={(val) =>
                  handleChange({ target: { name: 'shipping_address.taluka_id', value: val } })
                }
                onTalukaBlurred={handleBlur('shipping_address.taluka_id._id')}
                talukaCodeError={
                  touched.shipping_address?.taluka_id?._id &&
                  errors.shipping_address?.taluka_id?._id
                }
                onVillageSelected={(val) =>
                  handleChange({ target: { name: 'shipping_address.village_id', value: val } })
                }
                onPostCodeChange={(e) => {
                  handleChange({
                    target: { name: 'shipping_address.post_code', value: e.target.value }
                  });
                }}
                onPostCodeBlurred={handleBlur('shipping_address.post_code')}
                postCodeError={
                  touched.shipping_address?.post_code && errors.shipping_address?.post_code
                }
                isCountryRequired={shipRequired}
                isStateRequired={shipRequired}
                isDistrictRequired={shipRequired}
                isTalukaRequired={shipRequired}
                isPostCodeRequired={shipRequired}
                addressOneRequired={shipRequired}
                addressTwoDisabled={shipFieldDisable}
                countryDisabled={shipFieldDisable}
                stateDisabled={shipFieldDisable}
                districtDisabled={shipFieldDisable}
                talukaDisabled={shipFieldDisable}
                villageDisabled={shipFieldDisable}
                postCodeDisabled={shipFieldDisable}
              />
            )}

            <Box sx={profileStyles.btnsCont}>
              <UIButton
                title={getTitles('T-10046')}
                style={profileStyles.btn}
                disabled={!isValid || btnDisabled}
                onClick={handleProfileClick}
              />
            </Box>
          </Box>
        )}
      </UIContainer>
    </Screen>
  );
};
