import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { color } from '../../theme';
import { UICheckBox } from '../check-box';
import { Text } from '../text';
import { defaultValues } from '../../constants';

export const GroupByOptions = ({ data, title, onSelectionChange, reset = false }) => {
  const initializeSelectedItems = () => {
    const initialSelectedItems = {};
    data.forEach((item) => {
      initialSelectedItems[item.key] = false;
    });
    return initialSelectedItems;
  };

  const [expanded, setExpanded] = useState(false);
  const [selectedItems, setSelectedItems] = useState(initializeSelectedItems());

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (reset) {
      setSelectedItems(initializeSelectedItems);
    }
  }, [reset]);

  const handleCheckboxChange = (itemKey) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = {
        ...prevSelectedItems,
        [itemKey]: !prevSelectedItems[itemKey]
      };

      if (onSelectionChange) {
        const formattedItems = Object.keys(newSelectedItems).map((key) => ({
          [key]: newSelectedItems[key]
        }));
        onSelectionChange(formattedItems);
      }

      return newSelectedItems;
    });
  };

  useEffect(() => {
    if (onSelectionChange) {
      const formattedItems = Object.keys(selectedItems).map((key) => ({
        [key]: selectedItems[key]
      }));
      onSelectionChange(formattedItems);
    }
  }, []);

  const Styles = {
    container: {
      cursor: 'pointer',
      backgroundColor: expanded ? color.tableHeading : 'transparent',
      px: '2px',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    iconStyle: {
      transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: 'transform 0.3s ease'
    },
    checkCont: { display: 'flex', alignItems: 'center' },
    checkStyle: { fontSize: defaultValues.isResponsive ? '25px' : '25px' }
  };

  return (
    <Box my={1}>
      <Box onClick={handleToggle} sx={Styles.container}>
        <Text variant="D20">{title}</Text>
        <IconButton size="small" onClick={handleToggle}>
          <ExpandMoreIcon sx={Styles.iconStyle} />
        </IconButton>
      </Box>
      {expanded && (
        <Box>
          {data.map((item) => (
            <Box sx={Styles.checkCont} key={item.key}>
              <UICheckBox
                checked={!!selectedItems[item.key]}
                onChange={() => handleCheckboxChange(item.key)}
                iconStyle={Styles.checkStyle}
                checkedIconStyle={Styles.checkStyle}
              />
              <Text variant="D20">{item.name}</Text>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
