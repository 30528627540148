import { Box } from '@mui/material';
import { color, globalStyles } from '../../../../theme';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsEcommerce, updateScrollToTop } from '../../../../services/redux/slices';
import { defaultValues } from '../../../../constants';
import { getScroll } from '../../../../services/redux/selectors';
import { Text } from '../../../../ui-kits';

const styles = {
  container: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'center',
    mt: 4,
    backgroundColor: color.primaryBackground
  },
  subContainer: { width: '90%', textAlign: 'left' },
  innerText: {
    fontWeight: 400,
    display: 'inline-block'
  }
};

export const EcomShippingPolicy = () => {
  const dispatch = useDispatch();
  const scroll = useSelector(getScroll);

  useEffect(() => {
    dispatch(setIsEcommerce(defaultValues.appLogicalTexts.eCommerce));
    dispatch(updateScrollToTop(!scroll));
  }, []);
  return (
    <Box sx={styles.container}>
      <Box sx={styles.subContainer}>
        <Text variant="boldH40">Shipping Policy</Text>
        <Text variant="D20" style={globalStyles.mt(1)}>
          {`The orders for the user are shipped through registered domestic courier companies and/or
          speed post only. Orders are shipped within 5 days from the date of the order and/or
          payment or as per the delivery date agreed at the time of order confirmation and
          delivering of the shipment, subject to courier company / post office norms. Platform Owner
          shall not be liable for any delay in delivery by the courier company / postal authority.
          Delivery of all orders will be made to the address provided by the buyer at the time of
          purchase. Delivery of our services will be confirmed on your email ID as specified at the
          time of registration. If there are any shipping cost(s) levied by the seller or the
          Platform Owner (as the case be), the same is not refundable.`}
        </Text>
        <Text variant="boldH40" style={globalStyles.mt(1)}>
          Return Policy
        </Text>
        <Text variant="D20" style={globalStyles.mt(1)}>
          {`We offer refund / exchange within first 10 days from the date of your purchase. If 10 days
          have passed since your purchase, you will not be offered a return, exchange or refund of
          any kind. In order to become eligible for a return or an exchange, (i) the purchased item
          should be unused and in the same condition as you received it, (ii) the item must have
          original packaging, (iii) if the item that you purchased on a sale, then the item may not
          be eligible for a return / exchange. Further, only such items are replaced by us (based on
          an exchange request), if such items are found defective or damaged.`}
        </Text>
        <Text variant="D20" style={globalStyles.mt(1)}>
          {`You agree that there may be a certain category of products / items that are exempted from
          returns or refunds. Such categories of the products would be identified to you at the item
          of purchase. For exchange / return accepted request(s) (as applicable), once your returned
          product / item is received and inspected by us, we will send you an email to notify you
          about receipt of the returned / exchanged product. Further. If the same has been approved
          after the quality check at our end, your request (i.e. return / exchange) will be
          processed in accordance with our policies.`}
        </Text>
      </Box>
    </Box>
  );
};
