import { Box } from '@mui/material';
import { UISaveAndAdd, UITextInputWithTitle, ViewData } from '../../../../../ui-kits';
import { getTitles } from '../../../../../utils';
import { defaultValues, getErrorMessage } from '../../../../../constants';
import { styles } from '../styles';

export const BatchForm = ({
  item,
  selectedBatch,
  setSelectedBatch,
  orderDetails,
  handleSave,
  btnDisabled,
  setBtnDisabled,
  open,
  batches
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <ViewData
        title={getTitles('T-11580')}
        subTitle={item.balance_unit}
        titleVariant={'D20'}
        subTitleVariant="D20"
      />
      <UITextInputWithTitle
        title={getTitles('T-10182')}
        containerStyles={styles.qtyCont}
        placeHolder={getErrorMessage('E-10269')}
        value={selectedBatch?.quantity || ''}
        onChange={(e) => {
          let qty = 0;
          batches?.map((el) => (qty = qty + (parseInt(el?.quantity) || 0)));
          if (
            e.target.value <= parseInt(item.balance_unit) &&
            e.target.value <= parseInt(open.quantity)
          ) {
            setBtnDisabled(false);
            setSelectedBatch({ ...selectedBatch, quantity: e.target.value });
          }
        }}
        readOnly={orderDetails?.status?.id === defaultValues.orderStatus.Delivered}
      />
      <UISaveAndAdd
        title={getTitles('T-10394')}
        onAddAnother={handleSave}
        disabled={!selectedBatch?.quantity || btnDisabled}
      />
    </Box>
  );
};
