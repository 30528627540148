import { Screen, Text, UILayout } from '../../ui-kits';
import cowImg from '../../assets/images/profile.jpg';
import { getFormatDate, getTitles, resetMessages } from '../../utils';
import { Box, Divider, Grid, Paper } from '@mui/material';
import { defaultValues, getErrorMessage, SCREEN_PATH } from '../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { DetailsCardImg } from '../common';
import { BasicProfile } from '../common/utility-components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getInsuranceCattleRequest,
  resetInsuranceMsg,
  updateDetailsPg
} from '../../services/redux/slices';
import {
  getCattleInfo,
  getCattleInfoErrorMsg,
  getDetailsPg,
  getInsuranceCattleLoading,
  getInsuranceCurPage,
  getInsuranceTotalPage
} from '../../services/redux/selectors';
import { toast } from 'react-toastify';
import { UIBackButton } from '../../ui-kits/back-button';
import { styles } from './style';

export const ManageInsuranceCattleDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [cattle, setCattle] = useState({});
  const [farmerID, setFarmerId] = useState('');
  const [insuranceInfo, setInsuranceInfo] = useState({});

  const cattleDetails = useSelector(getCattleInfo);
  const error = useSelector(getCattleInfoErrorMsg);
  const isLoading = useSelector(getInsuranceCattleLoading);
  const totalPages = useSelector(getInsuranceTotalPage);
  const currentPage = useSelector(getInsuranceCurPage);
  const showBackButton = useSelector(getDetailsPg);

  useEffect(() => {
    setFarmerId(params.farmer_id);
    if (params.cattle_id) {
      let cattle_id = params.cattle_id;
      dispatch(getInsuranceCattleRequest({ cattle_id }));
    }
  }, [params && params.cattle_id && params.farmer_id]);

  useEffect(() => {
    if (cattleDetails) {
      setCattle({ ...cattleDetails });
    }
  }, [cattleDetails]);

  useEffect(() => {
    if (error) {
      toast.error(getErrorMessage(error));
    }

    resetMessages(() => dispatch(resetInsuranceMsg()));
  }, [error]);

  useEffect(() => {
    if (!showBackButton) {
      navigateToCattleList(farmerID);
    }
  }, [showBackButton]);

  const handlePagination = (e, data) => {
    dispatch(getInsuranceCattleRequest({ page: data }));
  };

  const { insurance_details, cattle_photo, tasks_details, ...cattle_info } = cattle;

  const cattle_properties = [
    { name: getTitles('T-11410'), key: 'cattle_name' },
    { name: getTitles('T-10438'), key: 'cattle_id' },
    { name: getTitles('T-11411'), key: 'cattle_type' }
  ];
  const cattle_insurance_properties = [
    { name: getTitles('T-10641'), key: 'policy_number' },
    { name: getTitles('T-10499'), key: 'policy_provider' },
    { name: getTitles('T-10651'), key: 'start_date' },
    { name: getTitles('T-10652'), key: 'end_date' },
    { name: getTitles('T-11020'), key: 'premium_paid' },
    { name: getTitles('T-11021'), key: 'subsidy' }
  ];

  useEffect(() => {
    if (insurance_details) {
      const detailsInfo = insurance_details && {
        ...insurance_details,
        start_date:
          insurance_details['start_date'] && getFormatDate(insurance_details['start_date']),
        end_date: insurance_details['end_date'] && getFormatDate(insurance_details['end_date'])
      };
      setInsuranceInfo(detailsInfo);
    }
  }, [insurance_details]);

  const tableData = tasks_details?.tasks.map((item, index) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-11407'),
          values: <Text variant="D22">{++index}</Text>,
          columnWidth: styles.columnWidth
        },
        {
          title: getTitles('T-11264'),
          values: <Text variant="D22">{item?.task_name}</Text>,
          columnWidth: '40%'
        },
        {
          title: getTitles('T-11248'),
          columnWidth: styles.columnWidth,
          values: <Text variant="D22">{item?.task_type}</Text>
        },
        {
          title: getTitles('T-11251'),
          columnWidth: styles.columnWidth,
          values: <Text variant="D22">{item?.due_date}</Text>
        }
      ];
    } else {
      return {
        title: (
          <Box component={'div'} sx={styles.tableItem}>
            <BasicProfile
              title={`${++index}. ${item?.task_name}`}
              subTitle={`${getTitles('T-11248')}- ${item?.task_type}`}
              titleStyle={styles.profileTitle}
              subTitleStyle={styles.profileSubTitle}
              isIcon={false}
            />
            <Text variant="D12" sx={styles.tableDate}>
              {item?.due_date}
            </Text>
          </Box>
        ),
        details: []
      };
    }
  });

  const navigateToCattleList = (id) => {
    navigate(`${SCREEN_PATH.MANAGE_INSURANCE_FARMER_CATTLES_LIST}/${id}`);
    dispatch(updateDetailsPg(true));
  };

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-10037')}>
      {defaultValues.isResponsive && (
        <Text variant="boldH48" style={styles.resPageTitle}>
          {getTitles('T-10037')}
        </Text>
      )}
      <Box sx={styles.container}>
        {!defaultValues.isResponsive && (
          <UIBackButton
            backRoot={styles.backArrowButton}
            onClick={() => navigateToCattleList(farmerID)}
          />
        )}

        <Text variant="BoldH22Sec" style={styles.boxTitle}>
          {getTitles('T-11409')}
        </Text>
        {defaultValues.isResponsive && <Divider sx={styles.hrLine} />}

        <Box sx={styles.basicDetailsCont}>
          <Grid container spacing={3}>
            <Grid item xs={defaultValues.isResponsive ? 12 : 5}>
              <Paper sx={styles.cattleInfoBox}>
                <DetailsCardImg
                  properties={cattle_properties}
                  values={cattle_info}
                  cardHeaderTitle={getTitles('T-10981')}
                  imgUrl={cattle_photo || cowImg}
                  customStyle={styles.contentBox}
                  titleWidth={styles.cattleInfoTitleWidth}
                  valueWidth={styles.cattleInfoValueWidth}
                />
              </Paper>
            </Grid>
            <Grid item xs={defaultValues.isResponsive ? 12 : 7}>
              <Paper sx={styles.cattleInsuranceBox}>
                <DetailsCardImg
                  properties={cattle_insurance_properties}
                  values={insuranceInfo}
                  cardHeaderTitle={getTitles('T-10507')}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Text variant="BoldH22Sec" style={styles.boxTitle}>
          {getTitles('T-11414')}
        </Text>
        {defaultValues.isResponsive && <Divider sx={styles.hrLine} />}
        <UILayout
          tableItems={tableData}
          showNoData={!tableData?.length > 0}
          showDataTable={!defaultValues.isResponsive}
          currPage={currentPage}
          pageCount={totalPages}
          showPagination={totalPages > 1}
          handlePagination={handlePagination}
          collapsable={!defaultValues.isResponsive}
          containerStyles={styles.uiContStyle}
          subContStyles={styles.uiSubContStyle}
          message={getErrorMessage('E-10194')}
        />
      </Box>
    </Screen>
  );
};
