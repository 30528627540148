import { defaultValues } from '../../constants';
import { color, globalStyles } from '../../theme';

const res = defaultValues.isResponsive;
export const loginStyles = {
  container: {
    height: 'fit-content',
    position: 'relative',
    backgroundColor: color.primaryBackground,
    mt: res ? 1 : 4,
    borderRadius: '10px'
  },
  languageBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    paddingRight: res ? '0px' : '24px',
    boxSizing: 'border-box'
  },
  subContainer: {
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    height: res ? '90vh' : '83vh'
  },
  imgBox: {
    width: '50%',
    height: res ? '100px' : '500px'
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  sectionBox: {
    width: res ? '100%' : '50%',
    ...globalStyles.flexBox
  },
  sectionBox2: {
    width: res ? '100%' : '50%',
    display: 'flex',
    alignItems: res ? 'start' : 'center',
    justifyContent: 'center',
    height: res ? '80vh' : 'none'
  },
  sectionSubBox: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: res ? 'none' : 'center',
    alignItems: 'center',
    height: res ? '100%' : 'none'
  },
  phoneInputBox: {
    width: '60%'
  },
  heading: {
    color: color.primary,
    textAlign: 'center'
  },
  btn: {
    width: '100%',
    marginTop: res ? 'auto' : '20px',
    marginBottom: '20px'
  },
  otpDesc2: {
    ...globalStyles.textAlignCenter,
    color: color.link,
    cursor: 'pointer'
  },
  otpDesc1: {
    ...globalStyles.textAlignCenter,
    color: color.secondaryText,
    marginTop: '10px'
  },
  autoStyle: {
    '& .MuiInputBase-root': {
      paddingRight: '23px !important'
    }
  },
  autoCont: {
    width: res ? '120px' : '130px',
    marginTop: 0,
    marginBottom: 0
  },
  textInp: {
    '& fieldset': { border: 'none' }
  },
  paperBox: {}
};
