import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { color } from '../../theme';
import { useState } from 'react';
import { SupportAgent } from '@mui/icons-material';
import { Text } from '../text';

const values = [
  {
    title: 'available',
    color: color.success
  },
  {
    title: 'busy',
    color: color.errorText
  },
  {
    title: 'offline',
    color: color.secondaryText
  }
];

const styles = {
  select: {
    alignSelf: 'end',
    backgroundColor: color.primaryBackground,
    width: '70%',
    visibility: 'hidden'
  },
  dropDown: { height: '48px' },
  supportAgentIcon: (option) => ({
    color: color.tertiaryText,
    backgroundColor: option.color,
    borderRadius: '50%',
    mr: 1
  })
};

export const CallStatusDropdown = () => {
  const [status, setStatus] = useState(values[0].title);

  const handleSelect = (e) => {
    const selectedValue = e.target.value;
    setStatus(selectedValue);
  };

  return (
    <FormControl sx={styles.select}>
      <Select value={status} defaultValue={status} onChange={handleSelect} sx={styles.dropDown}>
        {values.map((option, index) => (
          <MenuItem key={index} value={option.title}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <SupportAgent sx={styles.supportAgentIcon(option)} />
              <Text>{option.title}</Text>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
