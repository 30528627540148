import { createSelector } from '@reduxjs/toolkit';

export const getGovtOfficial = createSelector(
  [(state) => state.govtOfficial.govtOfficials],
  (d) => d
);
export const getGovtCattleCurrentPage = createSelector(
  [(state) => state.govtOfficial.cattleCurrentPage],
  (d) => d
);
export const getGovtCattleNextPage = createSelector(
  [(state) => state.govtOfficial.cattleNextPage],
  (d) => d
);
export const getGovtCattleTotalPage = createSelector(
  [(state) => state.govtOfficial.cattleTotalPage],
  (d) => d
);
export const getGovtFarmerCurrentPage = createSelector(
  [(state) => state.govtOfficial.farmerCurrentPage],
  (d) => d
);
export const getGovtFarmerNextPage = createSelector(
  [(state) => state.govtOfficial.farmerNextPage],
  (d) => d
);
export const getGovtFarmerTotalPage = createSelector(
  [(state) => state.govtOfficial.farmerTotalPage],
  (d) => d
);
export const getGovtSocietyNextPage = createSelector(
  [(state) => state.govtOfficial.societyNextPage],
  (d) => d
);
export const getGovtSocietyCurrentPage = createSelector(
  [(state) => state.govtOfficial.societyCurrentPage],
  (d) => d
);
export const getGovtSocietyTotalPage = createSelector(
  [(state) => state.govtOfficial.societyTotalPage],
  (d) => d
);

export const getGovtCattleList = createSelector(
  [(state) => state.govtOfficial.govtCattle],
  (d) => d
);
export const getGovtFarmerList = createSelector(
  [(state) => state.govtOfficial.govtFarmer],
  (d) => d
);
export const getGovtSocietyList = createSelector(
  [(state) => state.govtOfficial.govtSociety],
  (d) => d
);
export const getGovtOfficialCurPage = createSelector(
  [(state) => state.govtOfficial.currentPage],
  (d) => d
);
export const getGovtOfficialNxtPage = createSelector(
  [(state) => state.govtOfficial.nextPage],
  (d) => d
);
export const getGovtOfficialTotalPage = createSelector(
  [(state) => state.govtOfficial.totalPage],
  (d) => d
);
export const getGovtOfficialLoading = createSelector(
  [(state) => state.govtOfficial.isLoading],
  (d) => d
);
export const getGovtOfficialData = createSelector(
  [(state) => state.govtOfficial.govtOfficialData],
  (d) => d
);
export const getGovtOfficialSuccessMsg = createSelector(
  [(state) => state.govtOfficial.successMessage],
  (d) => d
);
export const getGovtOfficialErrorMsg = createSelector(
  [(state) => state.govtOfficial.errorMessage],
  (d) => d
);
export const getGovtOfficialCurrRender = createSelector(
  [(state) => state.govtOfficial.currRender],
  (d) => d
);

export const getGovtOfficialParams = createSelector(
  [(state) => state.govtOfficial.govtOfficialParams],
  (d) => d
);

export const getGovtOfficialCurruserId = createSelector(
  [(state) => state.govtOfficial.currUserId],
  (d) => d
);

export const getGovtOfficialDashboard = createSelector(
  [(state) => state.govtOfficial.dashboardData],
  (d) => d
);
