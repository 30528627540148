import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const md = defaultValues.isMedium;
export const styles = {
  container: (style) => ({
    width: '100%',
    height: 'fit-content',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    p: 2,
    boxSizing: 'border-box',
    display: 'flex',
    gap: '5px',
    minHeight: md ? 'none' : '160px',
    ...style
  }),
  contentCont: {
    width: 'calc(100% - 35px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '7px'
  },
  icon: {
    width: '30px',
    height: '30px'
  },
  locIcon: {
    width: '15px',
    height: '20px',
    borderRadius: 'none'
  },
  locCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  subCont: {
    width: '100%'
  },
  arrow: (expand, isShowable) => ({
    transform: expand ? `rotate(540deg)` : 'none',
    transition: 'transform 0.3s ease',
    fontSize: '32px',
    cursor: isShowable ? 'pointer' : 'default',
    opacity: isShowable ? 1 : 0.3
  })
};
