/*
 *Global cnfig for api and other credentials which are kept in .env
 *All the secrets should be kept in .env file
 *and configured here with some variables
 * Here some sample API endpoints are shown,
 *change them as per project requirement
 */

const {
  REACT_APP_SERVER,
  REACT_APP_API_URL_DEV,
  REACT_APP_API_URL_TEST,
  REACT_APP_API_URL_UAT,
  REACT_APP_API_URL_PROD,
  REACT_APP_API_URL_LOC
} = process.env;

export const API = {
  currentEnv: REACT_APP_SERVER, //api server environment dev/test/uat/prod, fetched from .env
  baseURL: {
    loc: REACT_APP_API_URL_LOC,
    dev: REACT_APP_API_URL_DEV,
    test: REACT_APP_API_URL_TEST, //remove if there is no test server configured
    uat: REACT_APP_API_URL_UAT,
    prod: REACT_APP_API_URL_PROD
  },
  //change the below endpoints as per project requirement
  noAuthUrls: {
    login_request: 'api/v1/send-login-otp',
    verify_login: 'api/v1/verify-login-otp',
    resend_otp: 'api/v1/resend-login-otp',
    ecom_login_request: 'api/v1/ecommerce/request-otp',
    ecom_resend_otp: 'api/v1/ecommerce/resend-login-otp',
    ecom_verify_login: 'api/v1/ecommerce/verify-and-create-user'
  },
  auth_urls: {
    profile: 'api/v1/my-profile',
    district: 'api/v1/district',
    taluk: 'api/v1/taluka',
    village: 'api/v1/village',
    village_details: 'api/v1/village-details',
    states: 'api/v1/states',
    vaccine: 'api/v1/vaccines',
    languages: 'api/v1/languages',
    profile_picture: 'api/v1/profile-picture',
    disease: 'api/v1/diseases',
    masterlist: 'api/v1/master-list',
    employeeList: 'api/v1/employee-names',
    employees: 'api/v1/employees',
    lab_employees: 'api/v1/lab-employees',
    upload_employee_docs: 'api/v1/upload-employees-documents',
    religions: 'api/v1/religion',
    vets: 'api/v1/vets',
    unassign_ai: 'api/v1/unassign-ai-workers-to-vet',
    assign_ai_to_vet: 'api/v1/assign-ai-workers-to-vet',
    update_assigned_ai: 'api/v1/assign-ai-workers-to-vet',
    ai_worker: 'api/v1/ai-workers',
    assign_vet_to_ai: 'api/v1/assign-vet-to-ai-workers',
    society: 'api/v1/society',
    society_details: 'api/v1/society-details',
    mTypes: 'api/v1/master-list-types-wise',
    farmers: 'api/v1/farmers',
    upload_farmer_doc: 'api/v1/farmers-upload-document',
    farmer_details: 'api/v1/farmer-details',
    unassignedVet: 'api/v1/vet-suggestions-for-ai-worker',
    unassigned_cattles: 'api/v1/unassigned-cattle',
    assign_cattles: 'api/v1/assign-cattle-to-ai-workers',
    laboratory: 'api/v1/laboratories',
    laboratoryDetails: 'api/v1/laboratory-details',
    cattle: 'api/v1/cattle',
    reports: 'api/v1/lab-reports',
    cattle_details: 'api/v1/cattle-details',
    cattle_photos: 'api/v1/cattle-upload-photos',
    insurance: 'api/v1/insurance',

    insurance_farmer_details: 'api/v1/insurance/farmers',
    insurance_cattle_details: 'api/v1/insurance/cattle',

    govt_official: 'api/v1/govt-officials',
    govt_official_details: 'api/v1/govt-officials-details',
    associated_farmer: 'api/v1/farmer-by-society',
    lab_suggestion: 'api/v1/lab-suggestions-for-user',
    assign_lab_to_user: 'api/v1/assign-lab-to-user',
    profiling: 'api/v1/profile',
    profile_details: 'api/v1/profile-details',
    call_center_my_tasks: 'api/v1/my-tasks-call-center',
    all_tasks_call_center: 'api/v1/all-tasks-call-center',
    all_call_history: 'api/v1/all-call-history',
    call_center_task_details: 'api/v1/call-center-task-details',
    call_center_users_suggestions: 'api/v1/call-center-users-suggestions',
    call_center_user_info: 'api/v1/call-center-user-info',
    tasks_call_center: 'api/v1/tasks-call-center',
    caller_tasks: 'api/v1/caller-tasks',
    caller_call_history: 'api/v1/caller-call-history',
    note: 'api/v1/note',
    district_by_state: 'api/v1/district',
    taluka_by_district: 'api/v1/taluka',
    village_by_taluka: 'api/v1/village',
    inventory_store: 'api/v1/inventory-store',
    inventory_store_details: 'api/v1/inventory-store-details',
    employees_by_permissions: 'api/v1/employees-by-permission',
    cattleOrEmployee: 'api/v1/employee-cattle-suggestions',
    govt_dashboard: 'api/v1/govt-officials-summary',
    caller_details: 'api/v1/call-center/caller-details',
    cattle_tasks: 'api/v1/tasks/cattle',
    vender: 'api/v1/vendor',
    process_products: 'api/v1/product',
    inventory_products: 'api/v1/inventory/inward-product-list',
    inventory_add_edit_products: 'api/v1/inventory/add-product',
    task: 'api/v1/task',
    associated_farmer_tasks: 'api/v1/associated-farmers',
    task_details: 'api/v1/task-details',
    my_tasks: 'api/v1/my-task',
    banners: 'api/v1/ecommerce/banner',
    ecommerce_products: 'api/v1/ecommerce/products',
    ecommerce_cart_items: 'api/v1/ecommerce/cart-items',
    ecommerce_my_orders: 'api/v1/ecommerce/my-orders',
    inventory_product_details: 'api/v1/inventory/inward-product-list-details',
    inventory_outward_products: 'api/v1/inventory/outward-product-list',
    inventory_add_outward_products: 'api/v1/inventory/outward-product',
    inventory_add_adjustment_products: 'api/v1/inventory/adjustment-product',
    inventory_outward_products_history: 'api/v1/inventory/product-history',
    ecom_product_details: 'api/v1/ecommerce/product-details',
    lab_farmers: 'api/v1/lab-incharge/farmers',
    lab_farmer_cattles: 'api/v1/lab-incharge/farmer-cattles',
    lab_farmer_cattle_details: 'api/v1/lab-incharge/cattle-details',
    lab_farmer_cattle_Reports: 'api/v1/lab-incharge/cattle-reports',
    notifications: 'api/v1/notification',
    notification_read: 'api/v1/notification/mark-as-read',
    call_center_agents: 'api/v1/call-center/employee',
    products_by_user: 'api/v1/inventory/product-by-user',
    ecommerce_manage_orders: 'api/v1/order-list',
    ecommerce_manage_order_details: 'api/v1/order-details',
    ecommerce_update_order_details: 'api/v1/order',
    send_query: 'api/v1/send-query',
    payment: 'api/v1/ecommerce/payment',
    payment_status: 'api/v1/ecommerce/phonepe-callback',
    batches: 'api/v1/batches'
  }
};
