import React from 'react';
import { Screen, Text } from '../../ui-kits';
import { color, globalStyles } from '../../theme';
import { Box } from '@mui/material';

export const TermsConditions = () => {
  const styles = {
    container: {
      display: 'flex',
      padding: '16px',
      justifyContent: 'center',
      mt: 4,
      backgroundColor: color.primaryBackground
    },
    subContainer: { width: '90%', textAlign: 'left' },
    innerText: {
      fontWeight: 400,
      display: 'inline-block'
    }
  };

  return (
    <Screen showHeader={true}>
      <Box sx={styles.container}>
        <Box sx={styles.subContainer}>
          <Text variant="boldH40">Terms & Conditions</Text>
          <Text variant="D20" style={globalStyles.mt(1)}>
            Welcome to My Gopalan! These Terms and Conditions outline the rules and regulations for
            the use of our website and services. By accessing or using our website, you agree to
            comply with these terms. Please read them carefully.
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'1. General Information'}
            <ul>
              <li>
                <Text variant="BoldH20" style={globalStyles.mt(1)}>
                  {'Website Owner: '}
                  <Text variant="D20" style={styles.innerText}>{`My Gopalan`}</Text>
                </Text>
              </li>
              <li>
                <Text variant="BoldH20" style={globalStyles.mt(1)}>
                  {'Website Address: '}
                  <Text variant="D20" style={styles.innerText}>{`www.mygopalan.com`}</Text>
                </Text>
              </li>
              <li>
                <Text
                  variant="D20"
                  style={globalStyles.mt(
                    1
                  )}>{`By accessing this website, you accept these terms and conditions in full. If you do not agree with any part of these terms, you must not use our website. `}</Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'2. Scope of Services'}
            <Text
              variant="D20"
              style={globalStyles.mt(
                1
              )}>{`My Gopalan provides a range of veterinary services, sells veterinary medicines, and offers agricultural equipment through this website. These terms govern your access to and use of all products and services offered on the website. `}</Text>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'3. User Eligibility'}
            <Text variant="D20" style={globalStyles.mt(1)}>
              {'To use our services or make purchases on the My Gopalan website: '}
            </Text>
            <ul>
              <li>
                <Text
                  variant="D20"
                  style={styles.innerText}>{`You must be at least 18 years of age. `}</Text>
              </li>
              <li>
                <Text
                  variant="D20"
                  style={
                    styles.innerText
                  }>{`You are responsible for ensuring that the details you provide (including personal information and payment details) are accurate and current. `}</Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'4. Product Information'}
            <Text variant="D20" style={globalStyles.mt(1)}>
              {
                'My Gopalan provides a range of veterinary services, sells veterinary medicines, and offers agricultural equipment through this website. These terms govern your access to and use of all products and services offered on the website. '
              }
            </Text>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'5. Order Acceptance and Payment'}
            <ul>
              <li>
                <Text variant="BoldH20" style={globalStyles.mt(1)}>
                  {'Order Placement:'}
                  <Text
                    variant="D20"
                    style={
                      styles.innerText
                    }>{`When you place an order on our website, it constitutes an offer to purchase the product. All orders are subject to acceptance by My Gopalan.`}</Text>
                </Text>
              </li>
              <li>
                <Text variant="BoldH20" style={globalStyles.mt(1)}>
                  {'Payment Methods: '}
                  <Text
                    variant="D20"
                    style={
                      styles.innerText
                    }>{`We accept major payment methods, including credit cards, debit cards, and online banking. Payment must be made in full before products are dispatched.`}</Text>
                </Text>
              </li>
              <li>
                <Text variant="BoldH20" style={globalStyles.mt(1)}>
                  {`Order Confirmation:`}

                  <Text
                    variant="D20"
                    style={
                      styles.innerText
                    }>{`Upon successful order placement, you will receive an email confirmation. We reserve the right to refuse or cancel orders if fraud or unauthorized use of payment methods is suspected.`}</Text>
                </Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'6. Shipping and Delivery'}
            <Text variant="D20" style={globalStyles.mt(1)}>
              {
                'Our shipping policies, rates, and delivery times are outlined in the Shipping Policy section of the website. By placing an order, you acknowledge and agree to the terms specified in our Shipping Policy. Delivery times are estimates and may be subject to delays beyond our control (e.g., natural disasters, shipping company delays). '
              }
            </Text>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'7. Returns and Refunds'}
            <ul>
              <li>
                <Text variant="BoldH20" style={globalStyles.mt(1)}>
                  {'Veterinary Medicines: '}
                  <Text
                    variant="D20"
                    style={
                      styles.innerText
                    }>{` Due to the nature of veterinary medicines, returns are not accepted unless the product is damaged, expired, or incorrect. In such cases, please contact us within 48 hours of receiving the item for a replacement or refund.`}</Text>
                </Text>
              </li>
              <li>
                <Text variant="BoldH20" style={globalStyles.mt(1)}>
                  {'Agricultural Equipment: '}
                  <Text
                    variant="D20"
                    style={
                      styles.innerText
                    }>{`We accept returns of unused agricultural equipment in its original packaging within 7 days of receipt. Return shipping costs are the responsibility of the customer unless the product is faulty.`}</Text>
                </Text>
              </li>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {`Please refer to our Returns Policy for more detailed information. `}
                </Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'8. Use of Veterinary Services'}
            <Text variant="D20" style={globalStyles.mt(1)}>
              {
                'My Gopalan provides veterinary guidance and services but does not substitute for professional veterinary-client relationships or in-person consultation. All veterinary services are subject to the judgment of our qualified professionals and are limited to general advice. For serious medical issues, please contact your local veterinarian. '
              }
            </Text>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'9. Intellectual Property'}
            <ul>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'All content on the My Gopalan website, including text, images, logos, product descriptions, and software, is the intellectual property of My Gopalan or its content suppliers. You may not reproduce, modify, or distribute any content from our website without prior written consent. '
                  }
                </Text>
              </li>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {'Unauthorized use of any material from our website may result in legal action. '}
                </Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'10. Limitation of Liability'}
            <ul>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'My Gopalan strives to provide accurate and reliable products and services. However, we do not guarantee that the information on our website is free of errors or omissions.  '
                  }
                </Text>
              </li>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'My Gopalan shall not be liable for any direct, indirect, or consequential damages arising from the use of or inability to use our website, products, or services. This includes but is not limited to financial losses, health issues in animals, or delays in the delivery of products. '
                  }
                </Text>
              </li>

              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'We are not responsible for any losses incurred due to reliance on information provided on the website, including veterinary advice, product recommendations, or equipment guidance. '
                  }
                </Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'11. User Conduct'}
            <Text
              variant="D20"
              style={globalStyles.mt(
                1
              )}>{`When using the My Gopalan website, you agree not to: `}</Text>
            <ul>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {'Engage in any illegal or fraudulent activities. '}
                </Text>
              </li>
              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'Use the website for any purpose that may harm the reputation of My Gopalan or its customers. '
                  }
                </Text>
              </li>

              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {'Submit false, misleading, or inaccurate information. '}
                </Text>
              </li>

              <li>
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {
                    'Interfere with the website’s security or attempt unauthorized access to restricted areas. Any violation of these terms may result in the termination of your access to the website and potential legal action.  '
                  }
                </Text>
              </li>
            </ul>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'12. Third-Party Links'}

            <Text variant="D20" style={globalStyles.mt(1)}>
              {
                'Our website may contain links to third-party websites for additional information or resources. These links are provided for convenience only. My Gopalan is not responsible for the content, policies, or actions of any external sites. '
              }
            </Text>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'13. Privacy Policy'}

            <Text variant="D20" style={globalStyles.mt(1)}>
              {
                'Your use of the website is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. By using the website, you consent to the collection and use of your data as described in the Privacy Policy. '
              }
            </Text>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'14. Changes to Terms and Conditions'}

            <Text variant="D20" style={globalStyles.mt(1)}>
              {
                'My Gopalan reserves the right to modify or update these Terms and Conditions at any time without prior notice. Changes will take effect as soon as they are posted on this page. We encourage you to review this page regularly to stay informed about any updates. '
              }
            </Text>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'15. Governing Law'}

            <Text variant="D20" style={globalStyles.mt(1)}>
              {
                'These Terms and Conditions are governed by the laws of India. Any disputes arising from the use of this website or related to the products or services offered by My Gopalan shall be subject to the exclusive jurisdiction of the courts in India. '
              }
            </Text>
          </Text>

          <Text variant="BoldH22" style={globalStyles.mt(1)}>
            {'16. Contact us'}

            <Text variant="D20" style={globalStyles.mt(1)}>
              {
                'If you have any questions or concerns about these Terms and Conditions, please contact us at: '
              }
              <ul>
                <li>
                  <Text variant="BoldH22" style={globalStyles.mt(1)}>
                    {'Phone : '}
                    <Text variant="D22" style={styles.innerText}>
                      {'+91-8792217331'}
                    </Text>
                  </Text>
                </li>

                <li>
                  <Text variant="BoldH22" style={globalStyles.mt(1)}>
                    {'Email : '}
                    <Text variant="D22" style={styles.innerText}>
                      {'mygopalan24@gmail.com'}
                    </Text>
                  </Text>
                </li>
              </ul>
            </Text>
          </Text>
        </Box>
      </Box>
    </Screen>
  );
};
