import { createSelector } from '@reduxjs/toolkit';

export const getLabFarmerLoading = createSelector([(state) => state.labFarmer.isLoading], (d) => d);

export const getLabFarmerSuccessMsg = createSelector(
  [(state) => state.labFarmer.successMessage],
  (d) => d
);
export const getLabFarmerErrorMsg = createSelector(
  [(state) => state.labFarmer.errorMessage],
  (d) => d
);

export const getLabFarmerCurPage = createSelector(
  [(state) => state.labFarmer.currentPage],
  (d) => d
);
export const getLabFarmerNxtPage = createSelector([(state) => state.labFarmer.nextPage], (d) => d);
export const getLabFarmerTotalPage = createSelector(
  [(state) => state.labFarmer.totalPage],
  (d) => d
);

export const getLabFarmer = createSelector([(state) => state.labFarmer.farmers], (d) => d);
export const getLabFarmerParams = createSelector(
  [(state) => state.labFarmer.farmerParams],
  (d) => d
);

export const getLabFarmerCattleCurPage = createSelector(
  [(state) => state.labFarmer.cattleCurrentPage],
  (d) => d
);
export const getLabFarmerCattleNxtPage = createSelector(
  [(state) => state.labFarmer.catteNextPage],
  (d) => d
);
export const getLabFarmerCattleTotalPage = createSelector(
  [(state) => state.labFarmer.cattleTotalPage],
  (d) => d
);

export const getLabFarmerCattles = createSelector([(state) => state.labFarmer.cattles], (d) => d);

export const getLabFarmerDetails = createSelector(
  [(state) => state.labFarmer.farmerDetails],
  (d) => d
);

export const getLabInchargeCattleDetails = createSelector(
  [(state) => state.labFarmer.cattleDetails],
  (d) => d
);

export const getLabInchargeReportCurrRender = createSelector(
  [(state) => state.labFarmer.reportCurrRender],
  (d) => d
);

export const getLabInchargeReport = createSelector(
  [(state) => state.labFarmer.cattleReport],
  (d) => d
);
