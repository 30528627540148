import { defaultValues } from '../../../../constants';

const res = defaultValues.isResponsive;

export const styles = {
  container: {
    width: '100%'
  },
  input: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px'
    },
    height: res ? '30px' : '40px'
  },
  autoInput: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px',

      padding: 0,
      paddingLeft: '5px'
    },
    '& .MuiInputBase-input': {
      fontSize: res ? '14px' : '18px'
    },
    '& .MuiPaper-root': {
      paddingRight: '15px',
      padding: 0
    }
  },
  autoCont: {
    mt: 1
  },
  imgSuperCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  },
  imgLeftCont: {
    width: res ? '100%' : 'calc((100% - 8px)/3 * 2)'
  },
  imgRightCont: {
    width: res ? '100%' : 'calc((100% - 20px)/3)'
  },
  width: res ? '100%' : 'calc((100% - 20px)/3)',
  secWidth: res ? 'calc((100% - 20px)/2)' : 'calc((100% - 20px)/3)',
  imgConWidth: res ? '100%' : 'calc((100% - 10px)/2)',
  aboveHalf: {
    width: res ? '100%' : 'calc((100% - 8px)/3 * 2)',
    maxWidth: 'none'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '10px'
  },
  gridCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap'
  },
  autoTitle: {
    mb: 0.8
  },
  radioCont: {
    width: res ? '100%' : 'calc((100% - 10px)/2)'
  },
  radioSubCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  }
};
