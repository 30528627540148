import { Cell, Pie, PieChart } from 'recharts';
import { defaultValues } from '../../constants';
import { color } from '../../theme';

const CircularProgressBar = ({ value, total, colors, label }) => {
  const isRes = defaultValues.isResponsive;

  const data = [{ value }, { value: total - value }];
  const COLORS = [colors, color.secondaryBackground];
  return (
    <PieChart width={isRes ? 100 : 150} height={isRes ? 100 : 150}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={isRes ? 35 : 55}
        outerRadius={isRes ? 45 : 70}
        startAngle={90}
        endAngle={450}
        paddingAngle={0}
        dataKey="value">
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index]} />
        ))}
      </Pie>
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={24}
        fontWeight="bold">
        {value}
      </text>
      <text
        x="50%"
        y="70%"
        textAnchor="middle"
        fontSize={14}
        fontWeight="bold"
        dominantBaseline="middle">
        {label}
      </text>
    </PieChart>
  );
};

export const CircleChart = ({ value, total, color, label }) => {
  return (
    <div>
      <CircularProgressBar value={value} total={total} colors={color} label={label} />
    </div>
  );
};
