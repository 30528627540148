import { useDispatch, useSelector } from 'react-redux';
import {
  CollapseDropView,
  DatePickers,
  PopupModal,
  Text,
  UIAutocompleted,
  UIButton,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { getTitles } from '../../../../utils';
import {
  getBatchesRequest,
  getStoreRequest,
  setBatches,
  updateEcomOrderDetailsRequest
} from '../../../../services/redux/slices';
import { defaultValues, getErrorMessage } from '../../../../constants';
import { useEffect, useState } from 'react';
import { styles } from './styles';
import { Box } from '@mui/material';
import { globalStyles } from '../../../../theme';
import { useFormik } from 'formik';
import { BatchForm } from './batch-form';
import { getEcommerceSuccessMsg, getProductBatches } from '../../../../services/redux/selectors';
import dayjs from 'dayjs';

export const OrderDetailsModel = ({ open, handleClose, storeList, orderDetails }) => {
  const dispatch = useDispatch();
  const batches = useSelector(getProductBatches);
  const success = useSelector(getEcommerceSuccessMsg);

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [batchesList, setBatchesList] = useState([]);
  const [index, setIndex] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);

  const { values, errors, touched, handleBlur, resetForm, setValues, setFieldValue, isValid } =
    useFormik({
      initialValues: {
        assigned_store: { _id: '' },
        tracking_number: '',
        tracking_link: '',
        expected_delivery_date: null
      },

      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: false
    });

  let timer;

  useEffect(() => {
    if (batches) {
      setBatchesList(batches);
    }
  }, [batches]);

  useEffect(() => {
    if (success === 'S-10137') {
      onClose();
    }
  }, [success]);

  useEffect(() => {
    if (open) {
      setBatchesList(open.batch);
      setValues({
        assigned_store: open.assigned_store,
        tracking_number: open.tracking_number,
        tracking_link: open.tracking_link,
        expected_delivery_date: open.expected_delivery_date && dayjs(open.expected_delivery_date)
      });
    } else {
      resetForm();
      setBtnDisabled(true);
      setIndex(null);
      setSelectedBatch(null);
    }
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtnDisabled(false);
  };

  const onClose = () => {
    dispatch(setBatches([]));
    handleClose();
  };

  const handleBatch = () => {
    let batchQty = 0;
    let batchListQty = 0;
    open.batch?.map((el) => (batchQty = batchQty + parseInt(el.quantity || 0)));
    batchesList?.map((el) => (batchListQty = batchListQty + parseInt(el.quantity || 0)));
    if ((batchListQty > batchQty && batchListQty <= open.quantity) || open.batch?.length === 0) {
      return batchesList.map((el) => ({
        batch_number: el.batch_number,
        quantity: parseInt(el.quantity)
      }));
    }
    return undefined;
  };

  const handleSubmit = () => {
    dispatch(
      updateEcomOrderDetailsRequest({
        _id: orderDetails?._id,
        product_id: open._id,
        assigned_store: values.assigned_store?._id || null,
        expected_delivery_date: values.expected_delivery_date,
        tracking_link: values.tracking_link,
        tracking_number: values.tracking_number,
        batch: handleBatch(),
        delivery_status: open.delivery_status?.id
      })
    );
  };

  const handleTitleClick = (item, idx) => {
    setSelectedBatch(null);
    setSelectedBatch(item);
    if (index === idx) {
      setSelectedBatch(null);
      return setIndex(null);
    }
    setIndex(idx);
  };

  const handleSave = (idx) => {
    setBatchesList(
      batchesList.map((el, indx) => (idx === indx ? { ...el, ...selectedBatch } : el))
    );
    setSelectedBatch(null);
    setIndex(null);
  };

  return (
    <PopupModal showModel={open} onClose={onClose} title={getTitles('T-10659')}>
      <UIAutocompleted
        name="assigned_store"
        required={true}
        title={getTitles('T-11689')}
        preSelectedValue={values?.assigned_store || ''}
        selectedValue={(val) => {
          setFieldValue('assigned_store', val);
          setBtnDisabled(false);
          dispatch(
            getBatchesRequest({
              product_id: open._id,
              assigned_store_id: val._id
            })
          );
          setSelectedBatch(null);
        }}
        onBlur={handleBlur}
        error={touched.assigned_store?._id && errors.assigned_store?._id}
        options={storeList}
        placeHolder={getTitles('T-10077')}
        optionLabel={(elem) => elem && elem.name}
        onTyping={(val) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            if (val.length > 2) {
              dispatch(
                getStoreRequest({
                  search_text: val,
                  filter: [{ is_active: true }]
                })
              );
            }
          }, 500);
        }}
        readOnly={
          orderDetails?.status?.id === defaultValues.orderStatus.Delivered ||
          open.delivery_status?.id === defaultValues.orderStatus.Delivered
        }
      />

      <Box sx={styles.batchBox}>
        <Text variant="BoldH22" style={{ mb: 1 }}>
          {getTitles('T-11734')}
        </Text>
        {batchesList && batchesList.length > 0 ? (
          batchesList.map((item, idx) => (
            <Box key={idx} sx={{ width: '100%' }}>
              <CollapseDropView
                title={item.batch_number}
                handleSubTitleClick={() => handleTitleClick(item, idx)}
                open={index === idx}
              />
              {index === idx && (
                <BatchForm
                  item={item}
                  selectedBatch={selectedBatch}
                  setSelectedBatch={setSelectedBatch}
                  orderDetails={orderDetails}
                  handleSave={() => handleSave(idx)}
                  btnDisabled={btnDisabled}
                  setBtnDisabled={setBtnDisabled}
                  open={open}
                  batches={batchesList}
                />
              )}
            </Box>
          ))
        ) : (
          <Text variant="D20">{getErrorMessage('E-10293')}</Text>
        )}
      </Box>

      <UITextInputWithTitle
        name="tracking_number"
        title={getTitles('T-11727')}
        placeHolder={getTitles('T-11730')}
        value={values.tracking_number}
        onChange={handleChange}
        readOnly={
          orderDetails?.status?.id === defaultValues.orderStatus.Delivered ||
          open.delivery_status?.id === defaultValues.orderStatus.Delivered
        }
      />

      <UITextInputWithTitle
        name="tracking_link"
        title={getTitles('T-11728')}
        placeHolder={getTitles('T-11729')}
        value={values.tracking_link}
        onChange={handleChange}
        readOnly={
          orderDetails?.status?.id === defaultValues.orderStatus.Delivered ||
          open.delivery_status?.id === defaultValues.orderStatus.Delivered
        }
      />

      <DatePickers
        title={`${orderDetails?.status?.id !== defaultValues.orderStatus.Delivered ? getTitles('T-11726') : getTitles('T-11675')}`}
        onDateChange={(val) =>
          handleChange({ target: { value: val, name: 'expected_delivery_date' } })
        }
        value={values.expected_delivery_date}
        disablePast
        readOnly={
          orderDetails?.status?.id === defaultValues.orderStatus.Delivered ||
          open.delivery_status?.id === defaultValues.orderStatus.Delivered
        }
      />

      <Box sx={{ ...globalStyles.flexColXCenter, mt: 2 }}>
        {(orderDetails?.status?.id !== defaultValues.orderStatus.Delivered ||
          open.delivery_status?.id !== defaultValues.orderStatus.Delivered) && (
          <UIButton
            title={getTitles('T-10394')}
            onClick={handleSubmit}
            style={styles.btn}
            disabled={!isValid || btnDisabled}
          />
        )}
      </Box>
    </PopupModal>
  );
};
