import {
  PopupModal,
  Text,
  UIAutocompleted,
  UISelect,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { callCenterTaskSchema, getTitles, removeFalsyValues } from '../../../../utils';
import { useEffect, useState } from 'react';
import { defaultValues } from '../../../../constants';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { ModalButtons } from '../../../common/modal-buttons';
import { globalStyles } from '../../../../theme';
import mFarmer from '../../../../assets/icons/farmer.png';
import mVet from '../../../../assets/icons/vet.png';
import mLaboratory from '../../../../assets/icons/laborotary.png';
import mParavet from '../../../../assets/icons/paravet.png';
import cattleIcon from '../../../../assets/icons/cattle.png';
import { CallCenterCommonField } from '../common-form';
import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTaskCallCenterRequest,
  getCallAgentCattleOrEmployeeRequest,
  getMasterListRequest,
  setCallCenterTaskDetails,
  updateTaskCallCenterRequest
} from '../../../../services/redux/slices';
import { getMasterList } from '../../../../services/redux/selectors/manageProccess';
import {
  getCallcenterCalleOrEmployee,
  getCallcenterTaskDetails
} from '../../../../services/redux/selectors';
import dayjs from 'dayjs';

export const TaskCallCenterModal = ({ showModel, onClose, isLoading, caller }) => {
  const dispatch = useDispatch();
  const taskTypes = useSelector(getMasterList);
  const taskDetails = useSelector(getCallcenterTaskDetails);
  const cattleOrEmployee = useSelector(getCallcenterCalleOrEmployee);

  const [btnDisabled, setBtndisabled] = useState(true);

  const { member, nonMember, employee } = defaultValues.callCenterUserTypes;
  const { add, edit, view } = defaultValues.actionType;
  const { cattleMan, admin, reasonsOthers } = defaultValues.reasonForCallValues;
  const { ai, vo, addCattle, delCattle, concernOthers } = defaultValues.memberConcernsValues;
  const { others, enquiry } = defaultValues.nonMemberReasonForCallValues;
  const { suggestEmployee, suggestCattle } = defaultValues.callCenterSuggestionType;

  const { concern } = defaultValues.appLogicalTexts;

  let timer;

  const {
    values,
    errors,
    touched,
    resetForm,
    setValues,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      task_name: '',
      description: '',
      task_type: '',
      task_status: '',
      due_date: null,
      priority: '',
      call_center_status: '',
      repeat: null,
      no_of_days: '',
      assign_to: '',
      notify_to: [],
      remind_to: [],
      remind_on: [
        {
          days: '',
          remind_at: '',
          timing: null
        }
      ],
      reason_for_call: '',
      concern: '',
      cattle_id: '',
      employee_id: '',
      details: '',
      no_of_cattles: '',
      time_to_visit: '',
      timing: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: callCenterTaskSchema
  });

  useEffect(() => {
    if (!showModel) {
      resetForm();
      setBtndisabled(true);
    }
  }, [showModel]);

  useEffect(() => {
    resetForm();
    dispatch(
      getMasterListRequest({
        master_id: defaultValues.mListIds.tastType,
        filter: JSON.stringify([{ is_active: true }])
      })
    );
  }, []);

  useEffect(() => {
    setFieldValue('repeat', values.repeat);
  }, [values.repeat]);

  useEffect(() => {
    if (taskDetails) {
      let newValues = {
        ...taskDetails,
        due_date: taskDetails.due_date && dayjs(taskDetails.due_date),
        priority: taskDetails.priority?.id,
        created_at: taskDetails.created_at && dayjs(taskDetails.created_at),
        task_type: taskDetails.task_type?.id,
        task_status: taskDetails.task_status?.id,
        call_center_status: taskDetails.call_center_status?.id || '',
        assign_to: taskDetails.assign_to?.id,
        notify_to:
          taskDetails.notify_to?.map((elem) => ({
            value: elem.id,
            title: defaultValues.roleOptions.find((item) => item.id === elem.id)?.name
          })) || [],
        remind_to:
          taskDetails.remind_to?.map((elem) => ({
            value: elem.id,
            title: defaultValues.roleOptions.find((item) => item.id === elem.id)?.name
          })) || [],
        remind_on: getRemaindOn(taskDetails.remind_on),
        reason_for_call: taskDetails.reason_for_call?.id || '',
        concern: taskDetails.concern?.id || '',
        cattle_id: taskDetails?.cattle && {
          _id: taskDetails.cattle?._id,
          full_name: taskDetails.cattle?.name
        },
        employee_id: taskDetails?.employee_id && {
          _id: taskDetails.employee_id?._id,
          full_name: taskDetails.employee_id?.name
        }
      };
      setValues(newValues);
    }
  }, [taskDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFieldValue(name, value);
    setBtndisabled(false);
    if (
      (name === concern &&
        ![concernOthers, addCattle].includes(value) &&
        [member].includes(showModel?.type)) ||
      (name === concern && [employee].includes(showModel?.type))
    ) {
      if ([employee].includes(showModel?.type)) {
        searchList(null, value);
      } else {
        searchList();
      }
    }
  };

  const handleSubmit = () => {
    let payload = {
      ...values,
      user_id: values.user_id?._id || caller?.user_id,
      user_type: values.user_type?.id || caller?.user_type?.id,
      no_of_days: values.no_of_days && parseInt(values.no_of_days),
      remind_on: values.remind_on.slice(0, -1).map((elem) => ({
        ...elem,
        days: elem.days ? parseInt(elem.days) : [2, 3].includes(elem.remind_at) && 1,
        timing: elem.timing || dayjs().hour(0).minute(0).second(0).millisecond(0)
      })),
      notify_to: values.notify_to.map((item) => item.value),
      remind_to: values.remind_to.map((item) => item.value),
      cattle_id: values.cattle_id?._id,
      call_center_status: values.call_center_status || defaultValues.callCenterStatus[0].value,
      employee_id: values.employee_id?._id
    };
    showModel?.mode === add && dispatch(addTaskCallCenterRequest(removeFalsyValues(payload)));
    showModel?.mode === edit && dispatch(updateTaskCallCenterRequest(removeFalsyValues(payload)));
  };

  const handleAddAnother = () => {
    setValues({
      ...values,
      remind_on: [...values.remind_on, { days: '', remind_at: '', timing: null }]
    });
  };

  const handleRemove = (idx) => {
    const newRemindDate = values.remind_on.filter((_, i) => i !== idx);
    setFieldValue('remind_on', newRemindDate);
  };

  const handleClose = () => {
    setBtndisabled(true);
    resetForm();
    onClose();
    dispatch(setCallCenterTaskDetails(null));
  };

  const searchList = (val, perm) => {
    let payload = {
      search_text: val,
      type: showModel?.type === employee ? suggestEmployee : suggestCattle,
      farmer_user_id: showModel?.type === member ? caller?.user_id : null,
      employee_permission: showModel?.type === employee ? perm : null
    };
    dispatch(getCallAgentCattleOrEmployeeRequest(payload));
  };

  const getRemaindOn = (remindOn) => {
    const temp =
      (remindOn?.length > 0 &&
        taskDetails.remind_on?.map((elem) => ({
          ...elem,
          timing: elem.timing && dayjs(elem.timing)
        }))) ||
      [];
    return [...temp, { days: '', remind_at: '', timing: null }];
  };

  const info = [
    taskDetails?.cattle && {
      heading: getTitles('T-11403'),
      details: {
        name: taskDetails?.cattle?.name,
        phone_number: taskDetails?.cattle?.cattle_id
      },
      icon: cattleIcon
    },
    taskDetails?.associated_farmer?.full_name && {
      heading: getTitles('T-11291'),
      details: {
        name: taskDetails?.associated_farmer?.full_name,
        phone_number: taskDetails?.associated_farmer?.phone_number,
        location: taskDetails?.associated_farmer?.location
      },
      icon: mFarmer
    },
    taskDetails?.assigned_gopalan?.full_name && {
      heading: getTitles('T-11292'),
      details: {
        name: taskDetails?.assigned_gopalan?.full_name,
        phone_number: taskDetails?.assigned_gopalan?.phone_number,
        location: taskDetails?.assigned_gopalan?.location
      },
      icon: mParavet
    },
    taskDetails?.assigned_vet?.full_name && {
      heading: getTitles('T-11293'),
      details: {
        name: taskDetails?.assigned_vet?.full_name,
        phone_number: taskDetails?.assigned_vet?.phone_number,
        location: taskDetails?.assigned_vet?.location
      },
      icon: mVet
    },
    taskDetails?.assigned_lab_vet?.length > 0 && {
      heading: getTitles('T-11294'),
      details: taskDetails?.assigned_lab_vet,
      icon: mLaboratory
    },
    taskDetails?.assigned_lab_gopalan?.length > 0 && {
      heading: getTitles('T-11295'),
      details: taskDetails?.assigned_lab_gopalan,
      icon: mLaboratory
    }
  ];

  return (
    <PopupModal
      showModel={showModel}
      title={
        showModel?.mode === add
          ? getTitles('T-11263')
          : showModel?.mode === view
            ? getTitles('T-11299')
            : getTitles('T-11300')
      }
      onClose={handleClose}
      isLoading={isLoading}
      childContStyle={styles.childCont}
      containerStyle={styles.popUpContainer}>
      <CallCenterCommonField
        showModel={showModel}
        info={[employee, member].includes(showModel?.type) && info}
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        handleRemove={handleRemove}
        handleAddAnother={handleAddAnother}
        setFieldTouched={setFieldTouched}
        setBtndisabled={setBtndisabled}
        setFieldValue={setFieldValue}
        taskTypes={taskTypes}
      />

      <Box sx={styles.nameCont}>
        {[member, nonMember].includes(showModel?.type) && (
          <UISelect
            name="reason_for_call"
            value={values.reason_for_call}
            title={getTitles('T-11269')}
            options={
              showModel?.type === member
                ? defaultValues.memberReasonsForCallOptions
                : showModel?.type === nonMember && defaultValues.nonMemberReasonsForCallOptions
            }
            onChange={(e) => {
              handleChange(e);
              setFieldValue('concern', '');
              setFieldValue('cattle_id', '');
              setFieldValue('details', '');
              setFieldValue('no_of_cattles', '');
              setFieldValue('time_to_visit', '');
              setFieldValue('timing', '');
            }}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            width={styles.width}
            inputStyle={styles.input}
            readOnly={showModel?.mode === view}
          />
        )}
        {((showModel?.type === member && [cattleMan, admin].includes(values.reason_for_call)) ||
          showModel?.type === employee) && (
          <UISelect
            name="concern"
            value={values.concern}
            title={getTitles('T-11270')}
            options={
              showModel?.type === member && values.reason_for_call === cattleMan
                ? defaultValues.memberCattleConcernsOptions
                : showModel?.type === member && values.reason_for_call === admin
                  ? defaultValues.memberAdminConcernsOptions
                  : showModel?.type === employee
                    ? defaultValues.employeePermissions
                    : []
            }
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            width={styles.width}
            inputStyle={styles.input}
            readOnly={showModel?.mode === view}
          />
        )}
        {((values.reason_for_call === cattleMan && [ai, vo, delCattle].includes(values.concern)) ||
          showModel?.type === employee) && (
          <UIAutocompleted
            name={showModel?.type === employee ? 'employee_id' : 'cattle_id'}
            title={getTitles('T-10081')}
            textInputStyles={styles.autoInput}
            containerStyle={styles.autoCont}
            titleStyle={{ mb: '4px' }}
            options={cattleOrEmployee}
            optionLabel={(item) => item.full_name || ''}
            variant={defaultValues.isResponsive ? 'medium' : 'body'}
            onTyping={(val) => {
              clearTimeout(timer);
              timer = setTimeout(() => {
                if (val.length > 2) {
                  searchList(val);
                }
              }, 500);
            }}
            onBlur={handleBlur}
            placeHolder={getTitles('T-10077')}
            selectedValue={(val) => {
              setFieldValue('cattle', null);
              setFieldValue(showModel?.type === employee ? 'employee_id' : 'cattle_id', val);
              setBtndisabled(false);
            }}
            preSelectedValue={values.cattle_id || values.employee_id}
            width={styles.width}
            readOnly={showModel?.mode === view}
          />
        )}
        {((showModel?.type === nonMember && values.reason_for_call === others) ||
          (showModel?.type === member && values.reason_for_call === reasonsOthers)) && (
          <UITextInputWithTitle
            name="details"
            value={values.details}
            title={getTitles('T-10659')}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10384')}
            width={styles.longWidth}
            readOnly={showModel?.mode === view}
            rows={2}
          />
        )}
      </Box>

      {((showModel?.type === member && [admin].includes(values.reason_for_call)) ||
        (showModel?.type === member &&
          [cattleMan].includes(values.reason_for_call) &&
          values.concern === concernOthers)) && (
        <UITextInputWithTitle
          name="details"
          value={values.details}
          title={getTitles('T-10659')}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10384')}
          width={styles.width}
          readOnly={showModel?.mode === view}
          rows={2}
        />
      )}

      {showModel?.type === nonMember && values.reason_for_call === enquiry && (
        <Box sx={styles.nameCont}>
          <UITextInputWithTitle
            name="no_of_cattles"
            value={values.no_of_cattles}
            title={getTitles('T-11302')}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10360')}
            width={styles.width}
            inputStyle={styles.input}
            readOnly={showModel?.mode === view}
          />
          <UITextInputWithTitle
            name="time_to_visit"
            value={values.time_to_visit}
            title={getTitles('T-11303')}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10384')}
            width={styles.width}
            inputStyle={styles.input}
            readOnly={showModel?.mode === view}
          />
          <UITextInputWithTitle
            name="timing"
            value={values.timing}
            title={getTitles('T-10569')}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-11214')}
            width={styles.width}
            inputStyle={styles.input}
            readOnly={showModel?.mode === view}
          />
        </Box>
      )}

      {showModel?.mode === edit && taskDetails?.created_by?.full_name && (
        <Text
          sx={{ mt: 2 }}>{`${getTitles('T-11252')} : ${taskDetails.created_by.full_name}`}</Text>
      )}

      <ModalButtons
        onPrimaryBtnClick={handleSubmit}
        primaryBtnTitle={showModel?.mode === edit ? getTitles('T-10046') : getTitles('T-10394')}
        disabled={!isValid || !dirty || btnDisabled}
        containerStyle={globalStyles.mt(3)}
      />
    </PopupModal>
  );
};
