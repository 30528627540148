import { color } from '../../../theme';

export const styles = {
  container: {
    width: '100%',
    height: '500px',
    backgroundColor: color.primaryBackground,
    borderRadius: '10px',
    border: `1px solid ${color.secondaryBorder}`,
    mt: 1,
    p: 3,
    boxSizing: 'border-box',
    overflow: 'auto'
  },
  subContainer: {
    width: '100%'
  },

  subCont: {
    width: '100%',
    p: 2,
    boxSizing: 'border-box',
    border: `1px solid ${color.secondaryBorder}`,
    display: 'flex',
    gap: '10px'
  },
  leftCont: {
    width: '30%',
    border: `1px solid ${color.secondaryBorder}`,
    borderRadius: '10px'
  },
  rightCont: {
    width: '70%',
    height: 'fit-content',
    border: `1px solid ${color.secondaryBorder}`,
    borderRadius: '10px'
  },
  addressSubCont: {
    width: '100%',
    p: 2,
    boxSizing: 'border-box'
  },
  viewDataTitle: {
    width: '32%'
  },
  viewData2Title: {
    width: '32%',
    pl: '15%',
    boxSizing: 'border-box'
  },
  viewDataSubTitle: {
    width: '70%'
  },
  text: {
    backgroundColor: color.secondaryBackground,
    p: 1,
    boxSizing: 'border-box',
    pl: 2,
    borderRadius: '10px 10px 0px 0px'
  },
  layoutCont: {
    paddingTop: 0
  },

  layoutSubCont: {
    mt: 0,
    borderRadius: 0
  },
  optionStyles: {
    mb: 0,
    marginTop: 0
  },
  textSubHead: {
    backgroundColor: color.palette.lightGoldenrodYellow,
    p: 1,
    boxSizing: 'border-box',
    borderRadius: '10px 10px 0px 0px',
    pl: 2
  },
  layoutSub: {
    minHeight: '0px'
  },
  chevron: {
    fontSize: '28px'
  }
};
