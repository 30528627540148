import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { employeeEmergencySchema, getTitles } from '../../../../utils';
import { useEffect, useState } from 'react';
import { ModalButtons } from '../../../../components';
import { globalStyles } from '../../../../theme';
import { useDispatch } from 'react-redux';
import { updateEmployeeRequest } from '../../../../services/redux/slices';
import { EmergencyForm } from './emergency-form';
import { CollapseDropView, NoData } from '../../../../ui-kits';
import { ViewEmergencyContact } from './view-emergency-contact';

const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  },
  btnCont: {
    marginTop: '50px'
  },
  title: {
    cursor: 'pointer'
  }
};

export const EmployeeEmergencyContact = ({
  employeeData,
  employeeId,
  editable,
  handleContinue
}) => {
  const dispatch = useDispatch();

  const [btnDisabled, setBtndisabled] = useState(false);
  const [emergency, setemergency] = useState(true);
  const [emrgencyContact, setEmergencyContact] = useState([]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    setValues,
    resetForm,
    setFieldValue,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      name: '',
      phone_number: '',
      relation: '',
      address_line_one: '',
      address_line_two: '',
      country_id: '',
      state_id: {
        _id: ''
      },
      district_id: {
        _id: ''
      },
      taluka_id: {
        _id: ''
      },
      village_id: {
        _id: ''
      },
      post_code: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: employeeEmergencySchema
  });

  useEffect(() => {
    if (employeeData && employeeData.length > 0) {
      setEmergencyContact(employeeData);
    }
  }, [employeeData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = { _id: employeeId, emergency_contact: emrgencyContact };
    const obj = { ...values };
    if (emergency === true) {
      payload.emergency_contact = [...emrgencyContact, obj]?.map((el) => ({
        ...el,
        state_id: el.state_id?._id,
        district_id: el.district_id?._id,
        taluka_id: el.taluka_id?._id,
        village_id: el.village_id?._id
      }));
    } else {
      const temp = emrgencyContact.map((item, idx) => (idx === emergency ? obj : item));
      payload.emergency_contact = temp?.map((el) => ({
        ...el,
        state_id: el.state_id?._id,
        district_id: el.district_id?._id,
        taluka_id: el.taluka_id?._id,
        village_id: el.village_id?._id
      }));
    }
    dispatch(updateEmployeeRequest(payload));
    resetForm();
  };

  const handleAddAnother = () => {
    handleContinue();
    handleSubmit();
    setemergency(true);
  };

  const handleTitleClick = (item, idx) => {
    setBtndisabled(true);
    setValues(item);

    if (emergency === idx) {
      resetForm();
      return setemergency(true);
    }
    setemergency(idx);
  };

  if (editable) {
    return (
      <Box sx={styles.container}>
        {emrgencyContact.length > 0 &&
          emrgencyContact.map((item, idx) => (
            <Box key={idx}>
              <CollapseDropView
                title={item.name}
                handleSubTitleClick={() => handleTitleClick(item, idx)}
                open={emergency === idx}
              />

              {emergency === idx && (
                <EmergencyForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setBtndisabled={setBtndisabled}
                  handleAddAnother={handleAddAnother}
                  isValid={isValid}
                  dirty={dirty}
                  btnDisabled={btnDisabled}
                  emergency={emergency}
                />
              )}
            </Box>
          ))}
        {emergency === true && (
          <EmergencyForm
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setBtndisabled={setBtndisabled}
            handleAddAnother={handleAddAnother}
            isValid={isValid}
            dirty={dirty}
            btnDisabled={btnDisabled}
            emergency={emergency}
          />
        )}

        <ModalButtons
          onPrimaryBtnClick={handleSubmit}
          primaryBtnTitle={getTitles('T-10339')}
          disabled={!isValid || !dirty || btnDisabled}
          containerStyle={globalStyles.mt(3)}
        />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {emrgencyContact.length > 0 ? (
        <ViewEmergencyContact
          employeeData={employeeData}
          handleSubTitleClick={handleTitleClick}
          currEmp={values}
          index={emergency}
        />
      ) : (
        <NoData message={getTitles('T-11061')} height="30vh" />
      )}
    </Box>
  );
};
