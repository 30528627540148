import { useFormik } from 'formik';
import { styles } from './styles';
import { CollapseDropView, PopupModal, Text, UISaveAndAdd } from '../../../ui-kits';
import { CallCenterCommonField } from '../../call-center';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssocFarmer,
  getAssocNxtPage,
  getCommonTasksDetails,
  getProductByUser,
  getTasksCurrTab,
  getTasksLoading,
  getTasksSuccessMsg
} from '../../../services/redux/selectors/tasksSelector';
import { commonTaskSchema, deduplicateAndSet, getTitles, removeFalsyValues } from '../../../utils';
import { defaultValues, getErrorMessage } from '../../../constants';
import { ModalButtons } from '../modal-buttons';
import { globalStyles } from '../../../theme';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import mFarmer from '../../../assets/icons/farmer.png';
import mVet from '../../../assets/icons/vet.png';
import mLaboratory from '../../../assets/icons/laborotary.png';
import mParavet from '../../../assets/icons/paravet.png';
import cattleIcon from '../../../assets/icons/cattle.png';
import {
  addMyTasksRequest,
  getAssociatedFarmerTasksRequest,
  getCattlesRequest,
  getProductsByUserRequest,
  setCommonTasksCurrRender,
  updateMyTasksRequest
} from '../../../services/redux/slices';
import { getCattleList, getUserData } from '../../../services/redux/selectors';
import { Box } from '@mui/material';
import { AdjustmentCompos } from './adjustment';

export const TasksCommon = ({ taskTypes }) => {
  const dispatch = useDispatch();

  const currRender = useSelector(getTasksCurrTab);
  const isLoading = useSelector(getTasksLoading);
  const taskDetails = useSelector(getCommonTasksDetails);

  const associatedFarmer = useSelector(getAssocFarmer);
  const nextPage = useSelector(getAssocNxtPage);
  const successMsg = useSelector(getTasksSuccessMsg);
  const cattleList = useSelector(getCattleList);
  const userData = useSelector(getUserData);
  const products = useSelector(getProductByUser);

  const [btnDisabled, setBtndisabled] = useState(true);
  const [farmerList, setFarmerList] = useState([]);
  const [farmerErrorMsg, setFarmerErrorMsg] = useState(null);
  const [adjustments, setAdjustments] = useState([]);
  const [index, setIndex] = useState(true);

  const { add, edit, view } = defaultValues.actionType;
  const { vetDoctor, aiWorker, labSupportEmployee, laboratoryIncharge } = defaultValues.employeeRol;

  const {
    values,
    errors,
    touched,
    resetForm,
    setValues,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      task_name: '',
      description: '',
      task_type: '',
      task_status: '',
      due_date: null,
      priority: '',
      call_center_status: '',
      repeat: false,
      no_of_days: '',
      assign_to: '',
      notify_to: [],
      remind_to: [],
      remind_on: [
        {
          days: '',
          remind_at: '',
          timing: null
        }
      ],
      cattle_id: '',
      farmer_id: '',
      employee_permission: '',
      adjustment: {
        product_id: null,
        adjust_quantity: ''
      }
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: commonTaskSchema
  });

  useEffect(() => {
    if (successMsg) {
      if (['S-10098', 'S-10097'].includes(successMsg)) handleClose();
    }
  }, [successMsg]);

  useEffect(() => {
    if (taskDetails && currRender) {
      let newValues = {
        ...taskDetails,
        no_of_days: taskDetails.repeat_days,
        due_date: taskDetails.due_date && dayjs(taskDetails.due_date),
        priority: taskDetails.priority?.id || '',
        created_at: taskDetails.created_at && dayjs(taskDetails.created_at),
        task_type: taskDetails.task_type?._id || '',
        task_status: taskDetails.task_status?.id || '',
        call_center_status: taskDetails.call_center_status?.id || '',
        assign_to: taskDetails.assign_role?.id,
        notify_to:
          (taskDetails.notify_role?.length > 0 &&
            taskDetails.notify_role?.map((elem) => ({
              value: elem.id,
              title: defaultValues.roleOptions.find((item) => item.id === elem.id)?.name
            }))) ||
          [],
        remind_to:
          (taskDetails.remind_role?.length > 0 &&
            taskDetails.remind_role?.map((elem) => ({
              value: elem.id,
              title: defaultValues.roleOptions.find((item) => item.id === elem.id)?.name
            }))) ||
          [],
        remind_on: getRemaindOn(taskDetails.remind_on, currRender),
        employee_permission: taskDetails.employee_permission?.id,
        adjustment: {
          product_id: null,
          adjust_quantity: ''
        }
      };

      setAdjustments(taskDetails?.adjustment || []);
      setValues(newValues);
    }
  }, [taskDetails, setValues, currRender]);

  useEffect(() => {
    if (values.farmer_id) {
      dispatch(getCattlesRequest({ farmer_id: values.farmer_id?._id }));
    }
  }, [values.farmer_id]);

  useEffect(() => {
    if (nextPage !== null) {
      dispatch(
        getAssociatedFarmerTasksRequest({
          employee_permission: values.employee_permission,
          page: nextPage
        })
      );
    }
  }, [nextPage]);

  useEffect(() => {
    setFarmerList(deduplicateAndSet(farmerList, associatedFarmer || []));
  }, [associatedFarmer]);

  useEffect(() => {
    setValues({ ...values });
  }, [values.repeat]);

  useEffect(() => {
    setFarmerList([]);
    if (values.employee_permission && userData.user_role === 2) {
      dispatch(
        getAssociatedFarmerTasksRequest({ employee_permission: values.employee_permission })
      );
      setFarmerErrorMsg(null);
    }

    if (
      [vetDoctor, aiWorker, labSupportEmployee, laboratoryIncharge].includes(
        values.employee_permission
      ) &&
      userData.user_role === 2 &&
      userData.employee_permissions?.some((elem) => elem.permission_id === laboratoryIncharge)
    ) {
      dispatch(getProductsByUserRequest());
    }
  }, [values.employee_permission]);

  const getRemaindOn = (remindOn, render) => {
    const temp =
      (remindOn?.length > 0 &&
        taskDetails.remind_on?.map((elem) => ({
          ...elem,
          timing: elem.timing && dayjs(elem.timing)
        }))) ||
      [];

    return [add, edit].includes(render)
      ? [...temp, { days: '', remind_at: '', timing: null }]
      : [...temp];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'adjustment.adjust_quantity' && parseInt(value) > values.adjustment?.balance_qty) {
      return;
    }
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = {
      ...values,
      repeat_days: values.no_of_days && parseInt(values.no_of_days),
      remind_on: values.remind_on.slice(0, -1).map((elem) => ({
        ...elem,
        days: elem.days ? parseInt(elem.days) : [2, 3].includes(elem.remind_at) && 1,
        timing: elem.timing || dayjs().hour(0).minute(0).second(0).millisecond(0)
      })),
      notify_role: values.notify_to.map((item) => item.value),
      remind_role: values.remind_to.map((item) => item.value),
      call_center_status: values.call_center_status || defaultValues.callCenterStatus[0].value,
      assign_role: values.assign_to,
      priority: values.priority || 3,
      adjustment:
        adjustments?.length > 0
          ? adjustments?.map((item) => ({
              ...item,
              adjust_quantity: parseInt(item.adjust_quantity)
            }))
          : []
    };
    delete payload.remind_to;
    delete payload.notify_to;
    delete payload.no_of_days;

    currRender === add && dispatch(addMyTasksRequest(removeFalsyValues(payload)));
    currRender === edit && dispatch(updateMyTasksRequest(removeFalsyValues(payload)));
  };

  const handleAddAnother = () => {
    setValues({
      ...values,
      remind_on: [...values.remind_on, { days: '', remind_at: '', timing: null }]
    });
    setBtndisabled(false);
  };

  const handleAdjustmentAddAnother = () => {
    const obj = { ...values.adjustment };
    if (index === true) {
      setAdjustments([...adjustments, obj]);
    } else {
      const temp = adjustments.map((item, idx) => (idx === index ? obj : item));
      setAdjustments(temp);
    }
    setIndex(true);
    setFieldValue('adjustment', {
      product_id: null,
      adjust_quantity: ''
    });
    setBtndisabled(false);
  };

  const handleRemove = (idx) => {
    const newRemindDate = values.remind_on.filter((_, i) => i !== idx);
    setFieldValue('remind_on', newRemindDate);
    setBtndisabled(false);
  };

  const handleClose = () => {
    setBtndisabled(true);
    resetForm();
    dispatch(setCommonTasksCurrRender(null));
  };

  const searchList = (val) => {
    if (values.employee_permission) {
      dispatch(
        getAssociatedFarmerTasksRequest({
          employee_permission: values.employee_permission,
          search_text: val
        })
      );
      setFarmerErrorMsg(null);
    } else {
      setFarmerErrorMsg(getErrorMessage('E-10022'));
    }
  };

  const handleTitleClick = (item, idx) => {
    setBtndisabled(true);
    setValues({
      ...values,
      adjustment: {
        ...item,
        product_id: item.product_id,
        adjust_quantity: item.adjust_quantity
      }
    });
    if (index === idx) {
      setFieldValue('adjustment', {
        product_id: '',
        adjust_quantity: ''
      });
      setBtndisabled(false);
      return setIndex(true);
    }
    setIndex(idx);
  };

  const info = [
    taskDetails?.cattle_id && {
      heading: getTitles('T-11403'),
      details: {
        name: taskDetails?.cattle_id?.cattle_name,
        phone_number: taskDetails?.cattle_id?.cattle_id
      },
      icon: cattleIcon
    },
    taskDetails?.farmer_id?.full_name && {
      heading: getTitles('T-11291'),
      details: {
        name: taskDetails?.farmer_id?.full_name,
        phone_number: taskDetails?.farmer_id?.phone_number,
        location: [
          taskDetails?.farmer_id?.taluk?.name || '',
          taskDetails?.farmer_id?.village?.name || ''
        ]
          ?.filter((item) => ![null, '', undefined].includes(item))
          .join(', ')
      },
      icon: mFarmer
    },
    taskDetails?.gopalan?.full_name && {
      heading: getTitles('T-11292'),
      details: {
        name: taskDetails?.gopalan?.full_name,
        phone_number: taskDetails?.gopalan?.phone_number,
        location: taskDetails?.gopalan?.location
      },
      icon: mParavet
    },
    taskDetails?.vet?.full_name && {
      heading: getTitles('T-11293'),
      details: {
        name: taskDetails?.vet?.full_name,
        phone_number: taskDetails?.vet?.phone_number,
        location: taskDetails?.vet?.location
      },
      icon: mVet
    },
    taskDetails?.vet?.assigned_lab?.length > 0 && {
      heading: getTitles('T-11294'),
      details: taskDetails?.vet?.assigned_lab,
      icon: mLaboratory
    },
    taskDetails?.gopalan?.assigned_lab?.length > 0 && {
      heading: getTitles('T-11295'),
      details: taskDetails?.gopalan?.assigned_lab,
      icon: mLaboratory
    }
  ];

  return (
    <PopupModal
      showModel={[add, edit, view].includes(currRender)}
      title={
        currRender === add
          ? getTitles('T-11263')
          : currRender === view
            ? getTitles('T-11299')
            : getTitles('T-11300')
      }
      onClose={handleClose}
      isLoading={isLoading}
      childContStyle={styles.childCont}
      containerStyle={styles.popUpContainer}>
      <CallCenterCommonField
        showModel={{ mode: currRender }}
        info={info}
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        handleRemove={handleRemove}
        handleAddAnother={handleAddAnother}
        setFieldTouched={setFieldTouched}
        setBtndisabled={setBtndisabled}
        setFieldValue={setFieldValue}
        taskTypes={taskTypes}
        searchList={searchList}
        isCattleSelect={
          currRender === add ||
          (currRender === edit && taskDetails?.created_by?._id === userData?._id)
        }
        farmerList={associatedFarmer}
        cattleList={cattleList}
        farmerErrorMsg={farmerErrorMsg}
        endFields={
          userData?.employee_permissions?.every((elem) =>
            [vetDoctor, aiWorker, labSupportEmployee, laboratoryIncharge].includes(
              elem.permission_id
            )
          ) && (
            <Box sx={styles.adjustSuperCont}>
              {adjustments.length > 0 &&
                adjustments.map((item, idx) => (
                  <Box key={idx}>
                    <CollapseDropView
                      title={item.product_id?.name}
                      handleSubTitleClick={() => handleTitleClick(item, idx)}
                      open={index === idx}
                    />
                    {index === idx && (
                      <AdjustmentCompos
                        productList={products}
                        searchList={searchList}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        setBtndisabled={setBtndisabled}
                        values={values}
                        currRender={currRender}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        adjustments={adjustments}
                      />
                    )}
                  </Box>
                ))}
              {index === true && (
                <AdjustmentCompos
                  productList={products}
                  searchList={searchList}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  setBtndisabled={setBtndisabled}
                  values={values}
                  currRender={currRender}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  adjustments={adjustments}
                />
              )}
              <UISaveAndAdd
                onAddAnother={handleAdjustmentAddAnother}
                disabled={!values.adjustment?.product_id}
              />
            </Box>
          )
        }
      />

      {currRender === edit && taskDetails?.created_by?.full_name && (
        <Text
          sx={{ mt: 2 }}>{`${getTitles('T-11252')} : ${taskDetails.created_by.full_name}`}</Text>
      )}

      {[add, edit].includes(currRender) && (
        <ModalButtons
          onPrimaryBtnClick={handleSubmit}
          primaryBtnTitle={currRender === edit ? getTitles('T-10046') : getTitles('T-10394')}
          disabled={!isValid || !dirty || btnDisabled}
          containerStyle={globalStyles.mt(3)}
        />
      )}
    </PopupModal>
  );
};
