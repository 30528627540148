import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const sm = defaultValues.isMobile;

export const styles = {
  container: {
    width: '100%'
  },
  width: res ? '100%' : 'calc((100% - 10px)/3)',
  secRemindWidth: res ? 'calc((100% - 10px)/2)' : 'calc((100% - 65px)/3)',
  secWidth: res ? '100%' : 'calc((100% - 65px)/3)',
  longWidth: res ? '100%' : 'calc(((100% - 10px)/3)*2)',
  input: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px'
    },
    height: res ? '30px' : '40px'
  },
  remindInput: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px'
    },
    height: res ? '30px' : '40px',
    marginTop: '0px'
  },
  timeInput: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px'
    },
    height: res ? '30px' : '40px',
    marginTop: '0px',
    '& .MuiFormLabel-root': {
      transform: 'translate(14px, 9px) scale(1)',
      color: color.primaryBorder
    }
  },
  dateCont: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px'
    }
  },
  dateInput: {
    '& .MuiInputBase-input': {
      width: 'calc(100% - 20px)',
      fontSize: res ? '14px' : '16px',
      padding: res ? '9.5px 7px' : '3px 14px'
    }
  },
  nameCont: {
    width: res ? '100%' : '80%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: res ? '0px' : '10px'
  },
  repeatCont: {
    width: '90%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: res ? '0px' : '10px'
  },
  radioSubCont: {
    width: res ? '100%' : 'calc((100% - 10px)/3)',
    display: 'flex',
    gap: '40px'
  },
  btn: (isLast) => ({
    width: '30px',
    height: '30px',
    minWidth: 0,
    fontSize: '1.35rem',
    marginTop: '10px',
    padding: 0,
    visibility: isLast ? 'hidden' : 'none',
    display: res ? (isLast ? 'none' : 'inline-block') : 'inline-block'
  }),
  remindCont: {
    width: res ? '100%' : md ? 'calc(80% + 45px)' : '85%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: res ? '5px' : '10px',
    alignItems: 'center',
    justifyContent: res ? 'end' : 'start'
  },
  remaindSub: {
    width: '100%'
  },

  cardCont: {
    width: sm ? '100%' : md ? 'calc((100% - 15px)/2)' : 'calc((100% - 30px)/3)'
  },
  detailsCont: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px'
  },
  timePicker: {
    width: res ? 'calc(100%/2)' : 'calc((100% - 65px)/3)',
    mt: res ? 1.2 : 1
  },
  autoInput: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px',

      padding: 0,
      paddingLeft: '5px'
    },
    '& .MuiInputBase-input': {
      fontSize: res ? '14px' : '18px'
    },
    '& .MuiPaper-root': {
      paddingRight: '15px',
      padding: 0
    }
  },
  autoCont: {
    marginTop: '10px',
    width: res ? '100%' : 'calc((100% - 10px)/3)'
  },
  radioCont: {
    width: res ? '100%' : 'calc((100% - 10px)/3)'
  },
  radioTitle: {
    textOverflow: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
};
