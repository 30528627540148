import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { defaultValues } from '../../../../constants';
import { getTitles, societyDetailsSchema } from '../../../../utils';
import {
  DatePickers,
  UIPhoneInputTitle,
  UIRadioButton,
  UITextInputWithTitle,
  UITitleWithContent
} from '../../../../ui-kits';
import { color, globalStyles } from '../../../../theme';
import { ModalButtons } from '../../../common/modal-buttons';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSocietyRequest,
  updateScrollToTop,
  updateSocietyCurrentRender,
  updateSocietyRequest
} from '../../../../services/redux/slices';
import { getScroll, getUserData } from '../../../../services/redux/selectors';
import { FormTitleCard } from '../../../common';
import { Address } from '../../../common/address';
const res = defaultValues.isResponsive;

const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: res ? '0px' : '10px'
  },
  bankCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  },
  width: res ? '100%' : 'calc((100% - 10px)/3)',
  phone: {
    width: res ? '100%' : 'calc((100% + 5px)/3 * 2)',
    maxWidth: 'none'
  },
  radioSubCont: {
    width: defaultValues.isResponsive ? '100%' : 'calc((100% + 5px)/3 * 2)',
    display: 'flex',
    gap: '10px'
  },
  radioSuper: {
    width: defaultValues.isResponsive ? '100%' : 'calc((100% + 5px)/3 * 2)',
    display: 'flex',
    flexDirection: res ? 'column' : 'row'
  },
  radioCont: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 2
  },
  checkboxIcon: {
    color: color.primaryText
  },
  divider: {
    borderBottom: `2px solid ${color.primaryBorder}`,
    height: '10px',
    width: res ? '1005' : 'calc(((100%/3 )* 2) - 5px)'
  },
  gridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `
      "country state"
      "district taluk"
      "village post"
        `
      : `
        "country state district"
        "taluk village post"
        `,
    gridTemplateColumns: res
      ? 'calc(50% - 5px) calc(50% - 5px)'
      : 'calc((100%/3 ) - 5px) calc((100%/3 ) - 5px) calc((100%/3 ) - 5px)',
    gap: '10px',
    marginTop: '10px'
  }
};

export const SocietyDetailsForm = ({ societyData, onNext, societyId, currRender }) => {
  const dispatch = useDispatch();

  const scroll = useSelector(getScroll);
  const [btnDisabled, setBtndisabled] = useState(true);
  const [sbDisable, setSbDisable] = useState(false);
  const userData = useSelector(getUserData);

  const { whatsApp, app, phone } = defaultValues.preferedContact;
  const { member, nonMember, proposed } = defaultValues.societyStatus;
  const { general, women, branch } = defaultValues.societyType;
  const { add, edit, view } = defaultValues.actionType;

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldTouched,
    handleBlur,
    setFieldValue,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      name: '',
      society_code: '',
      phone_number: '',
      secondary_phone_number: '',
      email_id: '',
      registration_date: null,
      preferred_contact_mode: { id: '' },
      society_status: { id: '' },
      society_type: { id: '' },
      bank_details: {
        bank_name: '',
        account_number: '',
        ifsc_code: '',
        branch_name: ''
      },
      location: {
        country_id: '',
        state_id: { _id: '' },
        district_id: { _id: '' },
        taluka_id: { _id: '' },
        village_id: { _id: '' },
        post_code: ''
      },
      address: {
        address_line_one: '',
        address_line_two: '',
        country_id: '',
        state_id: { _id: '' },
        district_id: { _id: '' },
        taluka_id: { _id: '' },
        village_id: { _id: '' },
        post_code: ''
      }
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: societyDetailsSchema
  });

  useEffect(() => {
    setSbDisable(values.bank_details && values.bank_details.bank_name ? false : true);
  }, [values.bank_details && values.bank_details.bank_name]);

  useEffect(() => {
    if (societyData) {
      let newValues = {
        ...societyData,
        registration_date: societyData.registration_date && dayjs(societyData.registration_date)
      };

      if (societyData.address) {
        newValues = {
          ...newValues,
          address: {
            address_line_one: societyData.address.address_line_one,
            address_line_two: societyData.address.address_line_two,
            country_id: societyData.address.country_id || '',
            state_id: societyData.address.state_id || { _id: '' },
            district_id: societyData.address.district_id || { _id: '' },
            taluka_id: societyData.address.taluka_id || { _id: '' },
            village_id: societyData.address.village_id || { _id: '' },
            post_code: societyData.address.post_code
          }
        };
      }

      setValues(newValues);
    }
  }, [societyData, setValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = { step_number: defaultValues.societyRegisterSteps.societyDetails };
    if (currRender === add) {
      payload = {
        ...payload,
        data: {
          ...values,
          preferred_contact_mode: values.preferred_contact_mode?.id,
          society_status: values.society_status?.id,
          society_type: values.society_type?.id,
          address: {
            ...values.address,
            state_id: values.address.state_id?._id || null,
            district_id: values.address.district_id?._id || null,
            taluka_id: values.address.taluka_id?._id || null,
            village_id: values.address.village_id?._id || null
          },
          location: {
            ...values.location,
            state_id: values.location.state_id?._id || null,
            district_id: values.location.district_id?._id || null,
            taluka_id: values.location.taluka_id?._id || null,
            village_id: values.location.village_id?._id || null
          }
        }
      };
      dispatch(addSocietyRequest(payload));
    } else if (currRender === edit) {
      payload = {
        ...payload,
        _id: societyId,
        society_details: {
          ...values,
          preferred_contact_mode: values.preferred_contact_mode?.id,
          society_status: values.society_status?.id,
          society_type: values.society_type?.id,
          address: {
            ...values.address,
            state_id: values.address.state_id?._id || null,
            district_id: values.address.district_id?._id || null,
            taluka_id: values.address.taluka_id?._id || null,
            village_id: values.address.village_id?._id || null
          },
          location: {
            ...values.location,
            state_id: values.location.state_id?._id || null,
            district_id: values.location.district_id?._id || null,
            taluka_id: values.location.taluka_id?._id || null,
            village_id: values.location.village_id?._id || null
          }
        }
      };
      payload._id = societyId;
      dispatch(updateSocietyRequest(payload));
    }
    onNext();
    dispatch(updateScrollToTop(!scroll));
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="name"
          value={values.name}
          title={getTitles('T-10708')}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={getTitles('T-10384')}
          required={true}
          error={touched.name && errors.name}
          width={styles.width}
          disabled={currRender === view}
        />

        <UITextInputWithTitle
          name="society_code"
          title={getTitles('T-10709')}
          placeHolder={getTitles('T-10642')}
          error={touched.society_code && errors.society_code}
          value={values.society_code}
          onChange={handleChange}
          onBlur={handleBlur}
          width={styles.width}
          required={true}
          disabled={currRender === view}
        />
      </Box>

      <DatePickers
        dateValue={values.registration_date}
        onDateChange={(val) => handleChange({ target: { value: val, name: 'registration_date' } })}
        title={getTitles('T-10710')}
        width={styles.width}
        error={touched.registration_date && errors.registration_date}
        onOpen={() => setFieldTouched('registration_date', true)}
        required={true}
        disableFuture={true}
        disabled={currRender === view}
      />

      <UIPhoneInputTitle
        phoneValue={values.phone_number}
        onChange={(val) => {
          setFieldValue('phone_number', val);
          setBtndisabled(val ? false : true);
        }}
        handleError={(isError) => setBtndisabled(isError)}
        required={true}
        containerStyles={styles.phone}
        disabled={currRender === view}
        title={getTitles('T-10486')}
      />

      <UIPhoneInputTitle
        phoneValue={values.secondary_phone_number}
        onChange={(val) => {
          setFieldValue('secondary_phone_number', val);
          setBtndisabled(val ? false : true);
        }}
        handleError={(isError) => setBtndisabled(isError)}
        containerStyles={styles.phone}
        disabled={currRender === view}
        title={getTitles('T-10487')}
      />

      <UITextInputWithTitle
        name="email_id"
        title={getTitles('T-10043')}
        placeHolder={getTitles('T-10049')}
        error={touched.email_id && errors.email_id}
        value={values.email_id}
        onChange={handleChange}
        onBlur={handleBlur}
        containerStyles={styles.phone}
        disabled={currRender === view}
      />

      <UITitleWithContent title={getTitles('T-10711')} required={true}>
        <Box sx={styles.radioSubCont}>
          <UIRadioButton
            title={getTitles('T-10723')}
            checked={values.preferred_contact_mode?.id === phone}
            onClick={() =>
              handleChange({ target: { name: 'preferred_contact_mode.id', value: phone } })
            }
            disabled={currRender === view}
          />
          <UIRadioButton
            title={getTitles('T-10712')}
            checked={values.preferred_contact_mode?.id === whatsApp}
            onClick={() =>
              handleChange({
                target: { name: 'preferred_contact_mode.id', value: whatsApp }
              })
            }
            disabled={currRender === view}
          />
          <UIRadioButton
            title={getTitles('T-10713')}
            checked={values.preferred_contact_mode?.id === app}
            onClick={() =>
              handleChange({ target: { name: 'preferred_contact_mode.id', value: app } })
            }
            disabled={currRender === view}
          />
        </Box>
      </UITitleWithContent>

      <UITitleWithContent title={getTitles('T-10714')} required={true}>
        <Box sx={styles.radioSuper}>
          <Box sx={styles.radioCont}>
            <UIRadioButton
              title={getTitles('T-10715')}
              checked={values.society_status?.id === member}
              onClick={() => handleChange({ target: { name: 'society_status.id', value: member } })}
              disabled={currRender === view}
            />
            <UIRadioButton
              title={getTitles('T-10716')}
              checked={values.society_status?.id === nonMember}
              onClick={() =>
                handleChange({ target: { name: 'society_status.id', value: nonMember } })
              }
              disabled={currRender === view}
            />
          </Box>
          <UIRadioButton
            title={getTitles('T-10717')}
            checked={values.society_status?.id === proposed}
            onClick={() => handleChange({ target: { name: 'society_status.id', value: proposed } })}
            containerStyle={{ width: res ? '100%' : 'calc(100%/3 - 7px)' }}
            disabled={currRender === view}
          />
        </Box>
      </UITitleWithContent>

      <UITitleWithContent title={getTitles('T-10718')} required={true}>
        <Box sx={styles.radioSubCont}>
          <UIRadioButton
            title={getTitles('T-10719')}
            checked={values.society_type?.id === general}
            onClick={() => handleChange({ target: { name: 'society_type.id', value: general } })}
            disabled={currRender === view}
          />
          <UIRadioButton
            title={getTitles('T-10720')}
            checked={values.society_type?.id === women}
            onClick={() => handleChange({ target: { name: 'society_type.id', value: women } })}
            disabled={currRender === view}
          />
          <UIRadioButton
            title={getTitles('T-10721')}
            checked={values.society_type?.id === branch}
            onClick={() => handleChange({ target: { name: 'society_type.id', value: branch } })}
            disabled={currRender === view}
          />
        </Box>
      </UITitleWithContent>

      <Address
        title={getTitles('T-10722')}
        objects={values.location}
        type="location"
        permissionId={
          userData.user_role === defaultValues.userRole.employee &&
          defaultValues.employeeRol.societyManager
        }
        showAssignedLocation={userData.user_role === defaultValues.userRole.employee}
        actionType={currRender}
        isCountryRequired={true}
        isDistrictRequired={true}
        isStateRequired={true}
        isPostCodeRequired={true}
        isTalukaRequired={true}
        onCountrySelected={(e) =>
          handleChange({ target: { name: 'location.country_id', value: e.target.value } })
        }
        onCountryBlurred={handleBlur('location.country_id')}
        countryCodeError={touched.location?.country_id && errors.location?.country_id}
        onStateSelected={(val) =>
          handleChange({ target: { name: 'location.state_id', value: val } })
        }
        onStateBlurred={handleBlur('location.state_id._id')}
        stateCodeError={touched.location?.state_id?._id && errors.location?.state_id?._id}
        onDistrictSelected={(val) =>
          handleChange({ target: { name: 'location.district_id', value: val } })
        }
        onDistrictBlurred={handleBlur('location.district_id._id')}
        districtCodeError={touched.location?.district_id?._id && errors.location?.district_id?._id}
        onTalukaSelected={(val) =>
          handleChange({ target: { name: 'location.taluka_id', value: val } })
        }
        onTalukaBlurred={handleBlur('location.taluka_id._id')}
        talukaCodeError={touched.location?.taluka_id?._id && errors.location?.taluka_id?._id}
        onVillageSelected={(val) =>
          handleChange({ target: { name: 'location.village_id', value: val } })
        }
        onPostCodeChange={(e) => {
          handleChange({ target: { name: 'location.post_code', value: e.target.value } });
        }}
        onPostCodeBlurred={handleBlur('location.post_code')}
        postCodeError={touched.location?.post_code && errors.location?.post_code}
      />

      <Address
        title={getTitles('T-10506')}
        objects={values.address}
        actionType={currRender}
        onAddressOneChange={(e) => {
          handleChange({ target: { name: 'address.address_line_one', value: e.target.value } });
        }}
        onAddressTwoChange={(e) => {
          handleChange({ target: { name: 'address.address_line_two', value: e.target.value } });
        }}
        onPostCodeChange={(e) => {
          handleChange({ target: { name: 'address.post_code', value: e.target.value } });
        }}
        onPostCodeBlurred={handleBlur('address.post_code')}
        postCodeError={touched.address?.post_code && errors.address?.post_code}
        onCountrySelected={(e) =>
          handleChange({ target: { name: 'address.country_id', value: e.target.value } })
        }
        onStateSelected={(val) =>
          handleChange({ target: { name: 'address.state_id', value: val } })
        }
        onDistrictSelected={(val) =>
          handleChange({ target: { name: 'address.district_id', value: val } })
        }
        onTalukaSelected={(val) =>
          handleChange({ target: { name: 'address.taluka_id', value: val } })
        }
        onVillageSelected={(val) =>
          handleChange({ target: { name: 'address.village_id', value: val } })
        }
      />

      <FormTitleCard title={getTitles('T-10509')}>
        <Box sx={styles.bankCont}>
          <UITextInputWithTitle
            name="bank_details.bank_name"
            title={getTitles('T-10404')}
            placeHolder={getTitles('T-11232')}
            value={values.bank_details.bank_name}
            onChange={handleChange}
            width={styles.width}
            disabled={currRender === view}
          />

          <UITextInputWithTitle
            name="bank_details.branch_name"
            title={getTitles('T-10500')}
            placeHolder={getTitles('T-11233')}
            value={values.bank_details?.branch_name}
            error={touched.bank_details?.branch_name && errors.bank_details?.branch_name}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
            disabled={currRender === view || sbDisable}
            required={values.bank_details?.bank_name}
          />
        </Box>

        <Box sx={styles.nameCont}>
          <UITextInputWithTitle
            name="bank_details.ifsc_code"
            title={getTitles('T-10405')}
            placeHolder={getTitles('T-10406')}
            value={values.bank_details.ifsc_code || ''}
            error={touched.bank_details?.ifsc_code && errors.bank_details?.ifsc_code}
            bankIfscCode={true}
            maxLength={11}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
            disabled={currRender === view || sbDisable}
            required={values.bank_details?.bank_name}
          />

          <UITextInputWithTitle
            name="bank_details.account_number"
            title={getTitles('T-10724')}
            placeHolder={getTitles('T-10360')}
            allowOnlyNumbers={true}
            value={values.bank_details.account_number || ''}
            error={touched.bank_details?.account_number && errors.bank_details?.account_number}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
            disabled={currRender === view || sbDisable}
            required={values.bank_details?.bank_name}
          />
        </Box>
      </FormTitleCard>

      <ModalButtons
        secondaryBtnTitle={getTitles('T-10045')}
        onSecondaryBtnClick={() => dispatch(updateSocietyCurrentRender(1))}
        onPrimaryBtnClick={handleSubmit}
        primaryBtnTitle={
          currRender === add
            ? getTitles('T-10339')
            : currRender === edit
              ? getTitles('T-10733')
              : getTitles('T-10804')
        }
        disabled={currRender !== view && (!isValid || !dirty || btnDisabled)}
        containerStyle={globalStyles.mt(3)}
      />
    </Box>
  );
};
