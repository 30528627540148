import { defaultValues } from '../../constants';
import { color } from '../../theme';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const sm = defaultValues.isMobile;
const mdSm = defaultValues.isMdSmall;

export const styles = {
  uiDateSelectContainerStyle: {
    backgroundColor: color.secondaryBackground,
    borderRadius: '5px',
    width: sm ? '100px' : md ? '120px' : '150px',
    minWidth: 'none',
    mx: 0
  },
  uiDateInput: {
    backgroundColor: color.secondaryBackground,
    overFlow: 'hidden'
  },
  uiDateCont: {
    width: res ? '50%' : '175px',
    backgroundColor: color.secondaryBackground,
    mt: 0,
    '& .MuiInputBase-root': {
      height: mdSm ? '30px' : res ? '30px' : '48px'
    },
    '& .MuiStack-root': {
      pt: '0px'
    }
  },
  resItemCont: {
    width: '100%',
    display: 'flex',
    gap: '4px',
    my: 2
  },
  resDateSelectCont: {
    backgroundColor: color.secondaryBackground,
    borderRadius: '5px',
    width: res ? '50%' : md ? '120px' : '150px',
    marginTop: 0
  },
  resDateSelectInput: {
    mt: 0,
    backgroundColor: color.secondaryBackground,
    height: res ? '30px' : '48px'
  },
  tableItemId: {
    textDecoration: 'underline',
    cursor: 'pointer',
    overflowWrap: 'anywhere'
  },
  col12: '120px',
  col15: '150px',
  col17: '170px',
  col20: '200px',
  col22: '220px',
  headertxtStyle: {
    whiteSpace: 'pre-wrap'
  },
  tableSubCont: (isList) => ({
    width: 'fit-content',
    minHeight: isList ? '50vh' : 'none'
  }),
  statusSelectCont: {
    backgroundColor: 'transparent',
    borderRadius: '5px',
    width: sm ? '100px' : md ? '120px' : '150px',
    minWidth: 'none',
    mx: 0,
    marginTop: '0px'
  },
  statusSelectInput: {
    height: defaultValues.isResponsive ? 'auto' : '50px',
    fontSize: mdSm ? '13.2px' : res ? '13.2px' : '22px',
    '& fieldset': {
      border: 'none'
    },
    marginTop: res && '0px',
    padding: 0,
    '& .MuiSelect-select': {
      padding: '0px 0px 0px 0px'
    },
    '& .MuiSelect-icon': {
      right: mdSm ? '24px' : res ? '25px' : md ? '16px' : '16px'
    }
  }
};
