import { Box, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Text } from '../text';
import { color } from '../../theme';
import { defaultValues } from '../../constants';

export const UISelect = ({
  options,
  value,
  onChange,
  title,
  error,
  onBlur,
  variant = 'D20',
  placeholder,
  width,
  returnValue = 'value',
  returnObject = false,
  optionLabel = 'title',
  defaultValue,
  required = false,
  containerStyles,
  inputStyle,
  onlyView = false,
  valueToShow,
  size = defaultValues.isResponsive ? 'small' : 'medium',
  ...rest
}) => {
  const styles = {
    container: (style) => ({
      width: width || '100%',
      marginTop: '10px',
      ...style
    }),
    select: (style) => ({
      width: '100%',
      marginTop: '5px',
      height: defaultValues.isResponsive ? '42px' : '50px',
      '& .Mui-disabled': {
        cursor: 'not-allowed !important'
      },
      ...style
    }),
    asterisk: {
      color: color.errorText
    },
    menuItem: {
      width: '100%',
      whiteSpace: 'normal',
      wordWrap: 'break-word'
    },
    placeholder: {
      color: color.palette.lightGray,
      whiteSpace: 'normal',
      wordWrap: 'break-word'
    },
    input: (style) => ({
      boxShadow: 0,
      width: '100%',
      marginTop: '5px',
      borderRadius: '8px',
      '& .MuiInputBase-root': {
        height: defaultValues.isResponsive ? '42px' : '50px'
      },
      '& .Mui-disabled': {
        cursor: 'not-allowed'
      },
      ...style
    })
  };

  const [selected, setselected] = useState('');
  useEffect(() => {
    setselected(value);
  }, [value]);

  const handleSelect = (e) => {
    onChange ? onChange(e) : setselected(e.target.value);
  };
  return (
    <Box sx={styles.container(containerStyles)}>
      {title && (
        <Text variant={variant}>
          {title}
          {required && <span style={styles.asterisk}>*</span>}
        </Text>
      )}
      {onlyView ? (
        <TextField
          variant="outlined"
          value={valueToShow}
          sx={styles.input(inputStyle)}
          maxRows={1}
          disabled={true}
          placeholder="-"
          InputProps={{
            style: { cursor: 'no-drop' }
          }}
          {...rest}
        />
      ) : (
        <Select
          defaultValue={defaultValue}
          value={selected}
          onChange={handleSelect}
          onBlur={onBlur}
          sx={styles.select(inputStyle)}
          size={size}
          displayEmpty
          {...rest}>
          {placeholder && (
            <MenuItem value="" sx={styles.menuItem}>
              <div style={styles.placeholder}>{placeholder}</div>
            </MenuItem>
          )}
          {options && options.length > 0 ? (
            options.map((option, index) => (
              <MenuItem
                key={index}
                sx={styles.menuItem}
                value={returnObject ? option : option[returnValue]}>
                {option[optionLabel]}
              </MenuItem>
            ))
          ) : (
            <MenuItem>No options</MenuItem>
          )}
        </Select>
      )}

      {error && <Text variant="fieldError">{error}</Text>}
    </Box>
  );
};
