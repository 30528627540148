import { defaultValues, SCREEN_PATH } from '../../../../constants';
import languageIcon from '../../../../assets/icons/language.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { useDispatch } from 'react-redux';
import {
  setAuthStatus,
  setEcommerceLoginModalOpen,
  setIsEcommerce,
  userLogout
} from '../../../../services/redux/slices';
import { Avatar, Box, Button, Dialog, DialogContent } from '@mui/material';
import { Text, UIAutocompleted, UIButton } from '../../../../ui-kits';
import { HeaderStyles } from '../styles';
import { getTitles } from '../../../../utils';
import logo from '../../../../assets/icons/logo.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

export const ECommerceHeader = ({
  isAuthenticated,
  currentPage,
  setLangOpen,
  langOpen,
  handleDisplayLang,
  handleLanguage,
  getApplicationLanguage,
  userData,
  totalNotification
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutBoxRef = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);

  const res = defaultValues.isResponsive;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (logoutBoxRef.current && !logoutBoxRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleGopalanClick = () => {
    navigate(SCREEN_PATH.ECOMMERCE);
    window.location.reload();
  };
  return (
    <>
      <img src={logo} alt="logo" style={HeaderStyles.logoStyles} onClick={handleGopalanClick} />
      {!res && (
        <Text variant="boldMilkH40" style={HeaderStyles.gopalan} onClick={handleGopalanClick}>
          {getTitles('T-10008')}
        </Text>
      )}

      <Box sx={{ flex: 1 }}>
        {!defaultValues.isResponsive && <Text variant="boldH32">{currentPage && currentPage}</Text>}
      </Box>
      <Box sx={HeaderStyles.profileSuperCont}>
        <Box sx={HeaderStyles.languageCont}>
          <img
            src={languageIcon}
            style={HeaderStyles.langImg}
            onClick={() => {
              setLangOpen(true);
            }}
          />

          <Dialog
            open={langOpen}
            onClose={() => handleDisplayLang(false)}
            sx={HeaderStyles.dialogueCont}>
            <DialogContent sx={HeaderStyles.dialogue}>
              {defaultValues.appLanguages.map((item, idx) => (
                <Button key={idx} onClick={() => handleLanguage(item)} sx={HeaderStyles.dBtn}>
                  {item.title}
                </Button>
              ))}
            </DialogContent>
          </Dialog>
          <UIAutocompleted
            options={defaultValues.appLanguages}
            optionLabel={(item) => item.title}
            containerStyle={HeaderStyles.autoCont}
            autoCompleteStyle={HeaderStyles.autoStyle}
            PaperBoxStyle={HeaderStyles.paperBox}
            textInputStyles={HeaderStyles.textInp}
            selectedValue={handleLanguage}
            preSelectedValue={getApplicationLanguage()}
            clearIcon={null}
            startIcon={languageIcon}
            disableClearable
          />
        </Box>

        {isAuthenticated ? (
          <Box sx={HeaderStyles.profile}>
            <Badge
              badgeContent={totalNotification}
              sx={HeaderStyles.badge}
              onClick={() => {
                dispatch(setIsEcommerce(defaultValues.appLogicalTexts.eCommerce));
                navigate(SCREEN_PATH.NOTIFICATION);
              }}>
              <NotificationsIcon />
            </Badge>
            <Box sx={HeaderStyles.profileClickCont} onClick={() => setShowDropdown(!showDropdown)}>
              {!defaultValues.isResponsive && (
                <Text variant="D18">{`${userData?.first_name} ${userData?.last_name}`}</Text>
              )}
              <Avatar alt="" src={userData?.photo_url} sx={HeaderStyles.avatar} />
            </Box>
            {showDropdown && (
              <Box sx={HeaderStyles.logoutBox} ref={logoutBoxRef}>
                <Text
                  variant="D18"
                  style={HeaderStyles.dropDownTxt}
                  onClick={() => {
                    dispatch(setIsEcommerce(defaultValues.appLogicalTexts.eCommerce));
                    navigate(SCREEN_PATH.PROFILE);
                    setShowDropdown(false);
                  }}>
                  {getTitles('T-11668')}
                </Text>
                <Text
                  variant="D18"
                  style={HeaderStyles.dropDownTxt}
                  onClick={() => {
                    dispatch(userLogout());
                    dispatch({ type: defaultValues.appLogicalTexts.userLogout });
                    setShowDropdown(false);
                    dispatch(setAuthStatus(false));
                    handleGopalanClick();
                  }}>
                  {getTitles('T-11669')}
                </Text>
              </Box>
            )}
          </Box>
        ) : (
          <UIButton
            style={{ ...HeaderStyles.loginBtn, ml: res && 1 }}
            variant={'secondary'}
            title={getTitles('T-10030')}
            onClick={() => dispatch(setEcommerceLoginModalOpen(true))}
          />
        )}
      </Box>
    </>
  );
};
