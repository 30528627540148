import { createSlice } from '@reduxjs/toolkit';
import { defaultValues, KEYS } from '../../../constants';

const initialState = {
  isLoading: false,
  isLoginPage: false,
  successMessage: null,
  errorMessage: null,
  isPhoneVerified: false,
  isAuthenticated: false,
  userData: null,
  mainMenu: 1,
  profileData: null,
  phone: null,
  detailPg: false,
  scrollToTop: false,
  collapse: false,
  sidebarScroll: 0,
  eCommerce: false,
  notifications: [],
  currentPage: null,
  nextPage: null,
  totalPage: null,
  eCommerceLoginModalOpen: false,
  ecomIsPhoneVerified: false,
  ecomPhone: null,
  isRegistered: false,
  ecomIsProfile: false,
  totalUnreadNotifications: null,
  notificationParams: null
};

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    resetAuthMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
    },
    setSidebarScrollPosition(state, action) {
      state.sidebarScroll = action.payload;
    },
    setNotificationParams(state, action) {
      state.notificationParams = action.payload;
    },
    setCollapse(state, action) {
      state.collapse = action.payload;
    },
    updateLoginPage(state, action) {
      state.isLoginPage = action.payload;
    },
    updateLanguage(state, action) {
      localStorage.setItem(KEYS.LANGUAGE, action.payload.language);
      window.location.reload();
    },
    updatePhoneVerified(state, action) {
      state.isPhoneVerified = action.payload;
    },
    updateDetailsPg(state, action) {
      state.detailPg = action.payload;
    },
    updateScrollToTop(state, action) {
      state.scrollToTop = action.payload;
    },
    userLogout(state) {
      state = { ...initialState, language: state.language };
      state.isAuthenticated = false;
      state.ecomIsProfile = false;
      state.eCommerce = false;
      state.ecomIsPhoneVerified = false;
      state.isPhoneVerified = false;
      state.totalUnreadNotifications = null;
      state.notificationParams = null;
      state.notifications = [];
      localStorage.removeItem(defaultValues.appLogicalTexts.authToken);
      const language = localStorage.getItem(KEYS.LANGUAGE);
      localStorage.clear();
      if (language) {
        localStorage.setItem(KEYS.LANGUAGE, language);
      }
    },
    updatePhone(state, action) {
      state.phone = action.payload;
    },
    updateMainMenu(state, action) {
      state.mainMenu = action.payload.id;
    },
    loginRequest(state) {
      state.isLoading = true;
    },
    loginSuccess(state, action) {
      state.isLoading = false;
      state.isPhoneVerified = true;
      state.successMessage = action.payload.status_code;
    },
    loginFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    resendOtpRequest(state) {
      state.isLoading = true;
    },
    resendOtpSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    resendOtpFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    verifyLoginRequest(state) {
      state.isLoading = true;
    },
    verifyLoginSuccess(state, action) {
      state.isLoading = false;
      state.userData = action.payload.data.user_data;
      state.isAuthenticated = true;
      localStorage.setItem(KEYS.ACCESS_KEY, action.payload.data.auth_token);
      state.phone = null;
    },
    verifyLoginFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getProfileRequest(state) {
      state.isLoading = true;
    },
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.profileData = action.payload.data;
    },
    getProfileFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    editProfileRequest(state) {
      state.isLoading = true;
    },
    editProfileSuccess(state, action) {
      state.isLoading = false;
      state.profileData = action.payload.data;
      state.userData = { ...state.userData, ...action.payload.data };
      state.successMessage = action.payload.status_code;
    },
    editProfileFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    profilePicChangeRequest(state) {
      state.isLoading = true;
    },
    profilePicChangeSuccess(state, action) {
      state.isLoading = false;
      state.profileData = {
        ...state.profileData,
        photo_url: `${action.payload.data.photo_url}?timestamp=${new Date().getTime()}`
      };
      state.userData = {
        ...state.userData,
        photo_url: `${action.payload.data.photo_url}?timestamp=${new Date().getTime()}`
      };
      state.successMessage = action.payload.status_code;
    },
    profilePicChangeFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    profilePicDeleteRequest(state) {
      state.isLoading = true;
    },
    profilePicDeleteSuccess(state, action) {
      state.isLoading = false;
      state.profileData = { ...state.profileData, photo_url: '' };
      state.userData = { ...state.userData, photo_url: '' };
      state.successMessage = action.payload.status_code;
    },
    profilePicDeleteFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    setIsEcommerce(state, action) {
      state.eCommerce = action.payload;
    },
    setAuthStatus(state, action) {
      state.isAuthenticated = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    getNotificationRequest(state) {
      state.isLoading = true;
    },
    getNotificationSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload.data.notifications;
      state.totalUnreadNotifications = action.payload.data.total_unread;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
    },
    getNotificationFailure(state) {
      state.isLoading = false;
    },
    clearNotificationData(state) {
      state.notifications = [];
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
    },

    markNotificationReadRequest(state) {
      state.isLoading = true;
    },
    markNotificationReadSuccess(state) {
      state.isLoading = false;
    },
    markNotificationReadFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },

    setEcommerceLoginModalOpen(state, action) {
      state.eCommerceLoginModalOpen = action.payload;
    },
    ecomLoginRequest(state) {
      state.isLoading = true;
    },
    ecomLoginSuccess(state, action) {
      state.isLoading = false;
      state.ecomIsPhoneVerified = true;
      state.isRegistered = action.payload.is_registered;
      state.successMessage = action.payload.status_code;
    },
    ecomLoginFailure(state, action) {
      state.isLoading = false;
      state.isRegistered = false;
      state.errorMessage = action.payload.status_code;
    },
    ecomResendOtpRequest(state) {
      state.isLoading = true;
    },
    ecomResendOtpSuccess(state, action) {
      state.isLoading = false;
      state.isRegistered = action.payload.is_registered;
      state.successMessage = action.payload.status_code;
    },
    ecomResendOtpFailure(state, action) {
      state.isLoading = false;
      state.isRegistered = false;
      state.errorMessage = action.payload.status_code;
    },
    ecomVerifyLoginRequest(state) {
      state.isLoading = true;
    },
    ecomVerifyLoginSuccess(state, action) {
      state.isLoading = false;
      state.userData = action.payload.data.user_data;
      state.isAuthenticated = true;
      state.ecomIsProfile = true;
      localStorage.setItem(KEYS.ACCESS_KEY, action.payload.data.auth_token);
      state.ecomPhone = null;
    },
    ecomVerifyLoginFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    ecomUpdatePhone(state, action) {
      state.ecomPhone = action.payload;
    },
    ecomUpdatePhoneVerified(state, action) {
      state.ecomIsPhoneVerified = action.payload;
    },
    sendQueryRequest(state) {
      state.isLoading = true;
    },
    sendQuerySuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    sendQueryFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    }
  }
});

export const {
  updateLoginPage,
  updateLanguage,
  updatePhoneVerified,
  userLogout,
  updatePhone,
  updateScrollToTop,
  resetAuthMessages,
  loginFailure,
  loginRequest,
  loginSuccess,
  verifyLoginFailure,
  verifyLoginRequest,
  verifyLoginSuccess,
  updateMainMenu,
  getProfileFailure,
  getProfileRequest,
  getProfileSuccess,
  editProfileFailure,
  editProfileRequest,
  editProfileSuccess,
  profilePicChangeFailure,
  profilePicChangeRequest,
  profilePicChangeSuccess,
  profilePicDeleteFailure,
  profilePicDeleteRequest,
  profilePicDeleteSuccess,
  resendOtpFailure,
  resendOtpRequest,
  resendOtpSuccess,
  updateDetailsPg,
  setCollapse,
  setSidebarScrollPosition,
  setIsEcommerce,
  setAuthStatus,
  setUserData,
  getNotificationFailure,
  getNotificationRequest,
  getNotificationSuccess,
  setEcommerceLoginModalOpen,
  ecomLoginRequest,
  ecomLoginSuccess,
  ecomLoginFailure,
  ecomResendOtpRequest,
  ecomResendOtpSuccess,
  ecomResendOtpFailure,
  ecomVerifyLoginRequest,
  ecomVerifyLoginSuccess,
  ecomVerifyLoginFailure,
  ecomUpdatePhone,
  ecomUpdatePhoneVerified,
  ecomUserLogout,
  sendQueryFailure,
  sendQueryRequest,
  sendQuerySuccess,
  markNotificationReadFailure,
  markNotificationReadRequest,
  markNotificationReadSuccess,
  clearNotificationData,
  setNotificationParams
} = authSlice.actions;

export default authSlice.reducer;
