import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const res = defaultValues.isResponsive;
export let defaultStyles = {
  superContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  container: {
    width: '100%',
    borderRadius: '4px'
  },
  title: (wrap) => ({
    textOverflow: 'ellipsis',
    width: '100%',
    whiteSpace: wrap ? 'wrap' : 'nowrap',
    overflow: 'hidden'
  }),
  headerCont: {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    backgroundColor: color.palette.paleYellow,
    pl: 2
  },
  headerTitleTxt: {
    fontWeight: 600,
    fontSize: res ? '20px' : '22px'
  },
  bodyCont: {
    borderTop: `1px solid ${color.primaryBorder}`,
    display: 'flex',
    px: 2,
    py: !res ? 1 : 2
  },
  detailsBox: {
    flex: 1
  },
  detailTitleTxt: (style) => ({
    width: res ? '45%' : '40%',
    fontWeight: 600,
    ...style
  }),
  detailValueTxt: (style) => ({
    width: res ? '55%' : '60%',
    ...style
  })
};

export const imgBox = {
  width: 'fit-content',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  pr: 3,
  pl: defaultValues.isResponsive && 2
};

export const imgStyle = {
  width: '70px',
  height: '70px'
};
