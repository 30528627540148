import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getErrorMsg,
  getStateNavDetails,
  getSuccessMsg,
  getTaluka,
  getTalukaCurrPage,
  getTalukaTotalPage,
  getTalukParams,
  processLoading
} from '../../../services/redux/selectors/manageProccess';
import { useFormik } from 'formik';
import { talukaSchema } from '../../../utils/schemas';

import { toast } from 'react-toastify';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { checkReadOnly, getTitles, resetMessages } from '../../../utils/commonFunction';
import {
  ActionButton,
  EditButton,
  NavigationText,
  PopupModal,
  Screen,
  Text,
  UILayout,
  UISaveAndAdd,
  UITabs,
  UITextInputWithTitle
} from '../../../ui-kits';
import { ModalButtons } from '../../common/modal-buttons';
import { useLocation, useNavigate } from 'react-router-dom';
import { color } from '../../../theme';
import {
  addTalukRequest,
  getTalukRequest,
  resetManageProcessMsg,
  setTalukParams,
  setVillageParams,
  updateStateNavDetails,
  updateTalukRequest
} from '../../../services/redux/slices';
import { getUserData } from '../../../services/redux/selectors';

export const ManageTaluk = () => {
  const styles = {
    title: {
      color: color.link,
      textDecoration: 'underline',
      cursor: 'pointer',
      overflowWrap: 'anywhere'
    },
    columnWidth: '40%',
    subClmWdth: defaultValues.isMobile ? '22%' : '23%',
    overLap: { overflowWrap: 'anywhere' }
  };

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();

  const talukList = useSelector(getTaluka);
  const errorMsg = useSelector(getErrorMsg);
  const isLoading = useSelector(processLoading);
  const successMsg = useSelector(getSuccessMsg);
  const details = useSelector(getStateNavDetails);
  const userData = useSelector(getUserData);
  const totalPages = useSelector(getTalukaTotalPage);
  const currentPage = useSelector(getTalukaCurrPage);
  const talukParams = useSelector(getTalukParams);

  const [open, setOpen] = useState(false);
  const [taluk, setTaluka] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [district_id, setDistrictId] = useState('');
  const [state, setState] = useState(false);
  const [district, setDistrict] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(null);
  const [renderType, setRenderType] = useState(null);

  const { process, village } = defaultValues.appLogicalTexts;

  const typeAssign = {
    type: renderType === village ? 2 : renderType === process ? 2 : null,
    permission_id:
      renderType === village
        ? defaultValues.employeeRol.villageManager
        : renderType === process
          ? defaultValues.employeeRol.processManager
          : null
  };

  const { values, errors, touched, handleBlur, resetForm, setFieldValue, dirty, isValid } =
    useFormik({
      initialValues: {
        name: '',
        code: ''
      },
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: talukaSchema
    });

  useEffect(() => {
    location?.state && setIsReadOnly(checkReadOnly(userData, location?.state?.access));
    if (location.state?.renderType) {
      setRenderType(location.state?.renderType);
    }
  }, [userData, location?.state]);

  useEffect(() => {
    if (details) {
      setState(details.state);
      setDistrictId(details.district_id);
      setDistrict(details.district);
      handleTaluk();
    }
  }, [details]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (successMsg === 'S-10020') {
        handleTaluk();
      }
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetManageProcessMsg()));
  }, [successMsg, errorMsg]);

  const handleTaluk = (val) => {
    let payload = {
      district_id: [details?.district_id],
      ...talukParams,
      ...typeAssign,
      ...val
    };
    dispatch(setTalukParams(payload));
    dispatch(getTalukRequest(payload));
  };

  const handleModalClose = () => {
    setOpen(false);
    setIsEdit(false);
    resetForm();
  };

  useEffect(() => {
    if (talukList) {
      setTaluka(talukList);
    }
  }, [talukList]);

  const handleSaveAndClose = () => {
    if (isEdit) {
      dispatch(updateTalukRequest(values));
    } else {
      dispatch(addTalukRequest({ district_id, ...values }));
    }
    handleModalClose();
  };

  const handleAddAnother = () => {
    dispatch(addTalukRequest({ district_id, ...values }));
    resetForm();
  };

  const handleStatus = (id, status) => {
    dispatch(updateTalukRequest({ _id: id, is_active: !status }));
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setFieldValue('_id', data._id);
    setFieldValue('name', data.name);
    setFieldValue('code', data.code);
    setOpen(true);
    setFormChanged(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFormChanged(true);
  };

  const handleSearch = (data) => {
    handleTaluk({ search_text: data || null, page: 1 });
  };

  const handlePagination = (e, data) => {
    handleTaluk({ district_id: [district_id], page: data });
  };

  const handleAdd = () => {
    setOpen(true);
  };

  const tableData = taluk.map((item, idx) => [
    renderType === village && {
      title: getTitles('T-11407'),
      values: `${idx + 1}`,
      valueTextStyle: { ml: 3 }
    },
    {
      title: getTitles('T-10071'),
      columnWidth: styles.columnWidth,
      values: (
        <Text
          variant="D20"
          style={styles.title}
          onClick={() => {
            dispatch(
              updateStateNavDetails({
                ...details,
                taluka_id: item._id,
                taluk: item.name,
                state: state,
                district: district
              })
            );
            dispatch(setVillageParams({}));
            navigation(SCREEN_PATH.MANAGE_VILLAGE, { state: location?.state });
          }}>
          {item.name}
        </Text>
      )
    },
    {
      title: getTitles('T-10160'),
      columnWidth: styles.subClmWdth,
      values: (
        <Text style={styles.overLap} variant="D20">
          {item.code}
        </Text>
      )
    },
    renderType === process && {
      title: getTitles('T-10103'),
      columnWidth: styles.subClmWdth,
      values: (
        <ActionButton
          status={item.is_active}
          alterTitle={defaultValues.confirmationModel.changeTalukStatus.title(item.is_active)}
          alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
          onStatusChange={() => handleStatus(item._id, item.is_active)}
          isReadOnly={isReadOnly}
        />
      )
    },
    renderType === process && {
      title: getTitles('T-10084'),
      values: (
        <EditButton
          status={item.is_active}
          onClick={() => handleEdit(item)}
          isReadOnly={isReadOnly}
        />
      )
    }
  ]);
  return (
    <Screen
      showSideBar={true}
      isLoading={isLoading}
      currentPage={renderType === process ? getTitles('T-10079') : getTitles('T-11383')}>
      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mt: 1 }}>
          {getTitles('T-10079')}
        </Text>
      )}
      <UITabs tabItems={defaultValues.manageProcess.tabList} />
      <NavigationText
        list={[
          { title: getTitles('T-10070'), route: location?.state?.parentRoute },
          {
            title: state,
            route: SCREEN_PATH.MANAGE_DISTRICT,
            state: { state: location?.state }
          },
          { title: district }
        ]}
      />
      <UILayout
        handleSearch={handleSearch}
        onlySearchAlphabets={true}
        searchBarPlaceHolder={getTitles('T-10146')}
        handleAddButton={renderType === process && handleAdd}
        tableItems={tableData}
        tableTitle={renderType === process ? getTitles('T-10165') : getTitles('T-11455')}
        showNoData={!taluk.length > 0}
        currPage={currentPage}
        handlePagination={handlePagination}
        pageCount={totalPages}
        showPagination={totalPages > 1}
        message={getErrorMessage('E-10032')}
        isReadOnly={isReadOnly}
      />
      <PopupModal
        title={isEdit ? getTitles('T-10154') : getTitles('T-10150')}
        onClose={handleModalClose}
        showModel={open}>
        <UITextInputWithTitle
          name="name"
          value={values.name}
          title={getTitles('T-10071')}
          placeHolder={getTitles('T-10158')}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && errors.name}
          allowOnlyAlphabets
          maxLength={30}
        />
        <UITextInputWithTitle
          name="code"
          value={values.code}
          title={getTitles('T-10160')}
          placeHolder={getTitles('T-10368')}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.code && errors.code}
          maxLength={10}
        />
        {!isEdit && (
          <UISaveAndAdd
            onAddAnother={handleAddAnother}
            disabled={!isValid || !dirty || !formChanged}
          />
        )}
        <ModalButtons
          onPrimaryBtnClick={handleSaveAndClose}
          primaryBtnTitle={isEdit ? getTitles('T-10046') : getTitles('T-10063')}
          disabled={!isValid || !dirty || !formChanged}
        />
      </PopupModal>
    </Screen>
  );
};
