import { Box } from '@mui/material';
import { SearchBar } from '../search-bar';
import { color } from '../../theme';
import { UIRefresh } from '../refresh';
import { UISort } from '../sort';
import { UIFilter } from '../filter';
import { UIButton } from '../button';
import { UIDataTable } from '../data-table';
import { defaultValues } from '../../constants';
import { UIPagination } from '../pagination';
import { Text } from '../text';
import { NoData } from '../no-data';
import { CollapsableList } from '../collapsable-list';
import { SelectDropdown } from '../select-dropdown';
import { getTitles } from '../../utils';
import { UIBackButton } from '../back-button';
import { DetailsCard } from '../../components/common/cards/details-card';
import { DatePickers } from '../date-picker';

const res = defaultValues.isResponsive;

export const UILayout = ({
  tableItems,
  tableTitle,
  handleSort,
  handleSearch,
  handleFilter,
  handleRefresh,
  handleAddButton,
  searchBarPlaceHolder,
  optionsForSorting = defaultValues.commonSortOptions, // default options for sorting
  optionsForFiltering = defaultValues.commonFilterOption, // default options filtering
  currentlyActiveSortOption, // make it null when refreshing to clear the active sort option
  currentlyActiveFilterOption, // to clear selected filter option , pass the returning value of handle filter and on refresh make it null
  isMultiSelectFilterOption = false,
  clearSearch,
  currPage,
  pageCount,
  handlePagination,
  showNoData = true,
  showPagination = false,
  message,
  showDataTable = true,
  options,
  selectedOption,
  selectValue,
  subMessage,
  onTitleClick,
  showProfile = false,
  isReadOnly,
  onlySearchAlphabets = false,
  onlySearchNumbers = false,
  disableSearch = false,
  handleBackButton = false,
  addBtnTitle = getTitles('T-10078'),
  handleSecondaryAdd,
  secondaryAddBtnTitle = getTitles('T-10479'),
  collapsable = true,
  addstartIcon,
  addBtnStyle,
  secondaryAddDisabled,
  currPageName,
  numFilter = false,
  customData,
  tableHeaderTextStyle,
  isSelect = false,
  showMlist = true,
  selectPlaceholder,
  selectLabel,
  selectContainerStyle,
  showDatePicker,
  dateValue,
  handleDateChange,
  dateCont,
  dateInput,
  containerStyles,
  subContStyles,
  optionStyles,
  tableSuperSubCont,
  resSelect = false,
  showSelect = true,
  showTableHeader,
  customOptions,
  showSortTitle,
  showRefreshTitle,
  showFilterTitle,
  selectInputStyle,
  beforeDateTableItems,
  showDetailsCard = true
}) => {
  const styles = {
    container: (style) => ({
      width: '100%',
      paddingTop: defaultValues.isResponsive ? 1 : 2,
      boxSizing: 'border-box',
      ...style
    }),
    subContainer: (style) => ({
      mt: 1,
      p: defaultValues.isResponsive ? '20px 10px' : 2,
      backgroundColor: color.primaryBackground,
      borderRadius: 2,
      border: 1,
      borderColor: color.primaryBorder,
      minHeight: '60vh',
      ...style
    }),
    optionsContainer: (style) => ({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: 2,
      marginTop: '10px',
      ...style
    }),
    options: {
      display: 'flex',
      alignItems: 'center',
      gap: defaultValues.isResponsive ? '5px' : '10px'
    },
    btn: (style) => ({
      width: defaultValues.isResponsive ? '32px' : '200px',
      height: defaultValues.isResponsive ? '32px' : '48px',
      minWidth: defaultValues.isResponsive && 0,
      fontSize: res ? '1.35rem' : '1.3rem',
      ...style
    }),
    secAddbtn: {
      width: defaultValues.isResponsive ? '150px' : '250px',
      height: defaultValues.isResponsive ? '32px' : '48px'
    },
    pagination: { display: 'flex', justifyContent: 'center', mt: 4 },
    tableCont: { overflowX: 'auto', width: '100%', my: 1 },
    tableSubCont: (style) => ({ width: '100%', ...style })
  };

  return (
    <Box sx={styles.container(containerStyles)}>
      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mb: 1 }}>
          {currPageName}
        </Text>
      )}
      <Box sx={styles.options}>
        {handleSearch && (
          <SearchBar
            allowOnlyAlphabets={onlySearchAlphabets}
            allowOnlyNumbers={onlySearchNumbers}
            searchedVlaue={handleSearch}
            placeHolder={searchBarPlaceHolder}
            disableSearch={disableSearch}
            onRefresh={clearSearch}
          />
        )}
        {!defaultValues.isResponsive && options && showMlist && (
          <SelectDropdown options={options} selOP={selectValue} selectedOption={selectedOption} />
        )}
      </Box>
      {customData && customData}
      <Box sx={styles.subContainer(subContStyles)}>
        {defaultValues.isResponsive && options && resSelect && (
          <SelectDropdown
            options={options}
            selOP={selectValue}
            selectedOption={selectedOption}
            placeholder={selectPlaceholder}
            label={selectLabel}
            selectContainerStyle={selectContainerStyle}
          />
        )}
        <Box sx={styles.optionsContainer(optionStyles)}>
          <Box sx={styles.options}>
            {handleBackButton && !defaultValues.isResponsive && (
              <UIBackButton onClick={handleBackButton} />
            )}
            {handleRefresh && (
              <UIRefresh handleRefresh={handleRefresh} showTitle={showRefreshTitle} />
            )}
            {handleSort && (
              <UISort
                selectedOption={handleSort}
                dropdownOptions={optionsForSorting}
                activeOption={currentlyActiveSortOption}
                showTitle={showSortTitle}
              />
            )}
            {handleFilter && (
              <UIFilter
                onlyNum={numFilter}
                isMultiSelect={isMultiSelectFilterOption}
                options={optionsForFiltering}
                applyFilter={handleFilter}
                selectedOption={currentlyActiveFilterOption}
                showTitle={showFilterTitle}
              />
            )}
            {tableTitle && <Text variant="BoldH28">{tableTitle}</Text>}
            {((defaultValues.isResponsive && options && showSelect) || (isSelect && options)) && (
              <SelectDropdown
                options={options}
                selOP={selectValue}
                selectedOption={selectedOption}
                placeholder={selectPlaceholder}
                label={selectLabel}
                selectContainerStyle={selectContainerStyle}
                inputStyle={selectInputStyle}
              />
            )}

            {showDatePicker && (
              <DatePickers
                dateValue={dateValue}
                onDateChange={handleDateChange}
                containerStyles={dateCont}
                inputStyle={dateInput}
              />
            )}
            {customOptions}
          </Box>
          <Box>
            {handleAddButton && (
              <UIButton
                title={defaultValues.isResponsive ? '+' : addBtnTitle}
                startIcon={addstartIcon}
                style={styles.btn(addBtnStyle)}
                onClick={handleAddButton}
                disabled={isReadOnly}
              />
            )}
          </Box>
        </Box>

        {beforeDateTableItems}

        {showDataTable && (
          <Box sx={styles.tableCont}>
            <Box sx={styles.tableSubCont(tableSuperSubCont)}>
              <UIDataTable
                tableRows={tableItems}
                headerTextStyle={tableHeaderTextStyle}
                showHeader={showTableHeader}
              />
            </Box>
          </Box>
        )}
        {!showDataTable && collapsable && (
          <CollapsableList
            contentArray={tableItems}
            onTitleClick={onTitleClick}
            showProfile={showProfile}
          />
        )}

        {!showDataTable && !collapsable && showDetailsCard && (
          <DetailsCard
            contentArray={tableItems}
            onTitleClick={onTitleClick}
            showProfile={showProfile}
          />
        )}
        {showNoData && <NoData message={message} subMsg={subMessage} />}
        {showPagination && (
          <Box sx={styles.pagination}>
            <UIPagination onChange={handlePagination} count={pageCount} currentPage={currPage} />
          </Box>
        )}
        {handleSecondaryAdd && (
          <Box sx={styles.pagination}>
            <UIButton
              title={secondaryAddBtnTitle}
              style={styles.secAddbtn}
              onClick={handleSecondaryAdd}
              disabled={isReadOnly || secondaryAddDisabled}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
