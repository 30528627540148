import { Box } from '@mui/material';
import {
  Text,
  UIButton,
  UIminusDeleteButton,
  UIRadioButtonWithTitle,
  UISaveAndAdd,
  UISelect,
  UITextInputWithTitle,
  UITimePicker
} from '../../../ui-kits';
import { getTitles, profileTaskSchema } from '../../../utils';
import { defaultValues } from '../../../constants';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { UISelectCheckGroup } from '../../../ui-kits/select-with-checkbox-group';
import { useSelector } from 'react-redux';
import {
  getProfilingActionType,
  getProfilingData
} from '../../../services/redux/selectors/manageProccess';
import { styles } from './styles';
const isRes = defaultValues.isResponsive;

export const TaskDetails = ({ taskType, data, task }) => {
  const { view, add } = defaultValues.actionType;
  const { values, setFieldValue, errors, handleBlur, touched, isValid, dirty, setValues } =
    useFormik({
      initialValues: {
        task_name: '',
        description: '',
        priority: null,
        trigger_at: '',
        operate: '',
        no_of_days: '',
        repeat: false,
        repeat_days: '',
        call_center_status: 1,
        task_type: '',
        assign_role: '',
        notify_role: [],
        remind_role: [],
        remind_on: []
      },

      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: profileTaskSchema
    });

  const profile = useSelector(getProfilingData);
  const actionType = useSelector(getProfilingActionType);

  const [trigerType, setTrigerType] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const triggerOption = {
    cattle: defaultValues.cattleTriggerType,
    farmer: defaultValues.farmerTriggerType,
    employee: defaultValues.employeeTriggerType,
    lab: defaultValues.labTriggerType,
    society: defaultValues.societyTriggerType
  };
  const subjectMapping = useMemo(
    () => ({
      1: triggerOption.cattle,
      2: triggerOption.farmer,
      3: triggerOption.employee,
      4: triggerOption.lab,
      5: triggerOption.society
    }),
    [triggerOption]
  );

  useEffect(() => {
    const selectedProfileId = profile?.flow_type?.id;
    const subjects = subjectMapping[selectedProfileId] || defaultValues.conditionSubject;
    setTrigerType(subjects);
  }, [profile?.flow_type, subjectMapping]);

  useEffect(() => {
    if (task) {
      setValues(task);
    }
  }, [task]);

  const [remindOn, setRemindOn] = useState({
    remind_at: '',
    days: '',
    timing: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let numericValue = value;
    if (name === 'no_of_days' || name === 'repeat_days') {
      numericValue = isNaN(value) ? null : Number(value);
    }
    setBtnDisabled(false);
    setFieldValue(name, numericValue);
  };

  const addRemindOn = () => {
    setFieldValue('remind_on', [...values.remind_on, remindOn]);
    setRemindOn({
      remind_at: '',
      days: '',
      timing: null
    });
  };

  const deleteRemindOn = (index) => {
    const newRemindOn = values.remind_on.filter((_, i) => i !== index);
    setFieldValue('remind_on', newRemindOn);
  };

  const updateRemindOn = (index, field, value) => {
    const newRemindOn = [...values.remind_on];
    newRemindOn[index] = { ...newRemindOn[index], [field]: value };
    setFieldValue('remind_on', newRemindOn);
  };

  const handleSave = () => {
    const isValidRemindOn =
      remindOn &&
      Object.entries(remindOn).every(
        ([key, value]) => key === 'days' || (value !== '' && value !== null)
      );

    if (isValidRemindOn) {
      data({
        ...values,
        repeat_days: values.repeat_days ? Number(values.repeat_days) : null,
        remind_on: [...values.remind_on, remindOn]
      });
    } else {
      data({ ...values, repeat_days: values.repeat_days ? Number(values.repeat_days) : null });
    }
  };

  return (
    <Box>
      <Box sx={styles.container}>
        {!isRes && (
          <Box sx={styles.labelContainer}>
            <Text>
              {getTitles('T-11289')}
              <span style={styles.asterisk}>*</span>
            </Text>
          </Box>
        )}
        <UITextInputWithTitle
          title={isRes && getTitles('T-11289')}
          containerStyles={styles.fieldContainer}
          placeHolder={getTitles('T-11290')}
          name="task_name"
          onChange={handleChange}
          value={values.task_name}
          onBlur={handleBlur}
          error={touched.task_name && errors.task_name}
          disabled={actionType === view}
          required={true}
        />
      </Box>
      <Box sx={styles.container}>
        {!isRes && (
          <Box sx={styles.labelContainer}>
            <Text>{getTitles('T-10109')}</Text>
          </Box>
        )}
        <UITextInputWithTitle
          title={isRes && getTitles('T-10109')}
          containerStyles={styles.fieldContainer}
          rows={3}
          placeHolder={getTitles('T-10096')}
          name="description"
          onChange={handleChange}
          disabled={actionType === view}
          value={values.description}
        />
      </Box>
      <Box sx={styles.container}>
        {!isRes && (
          <Box sx={styles.labelContainer}>
            <Text>
              {getTitles('T-11248')} <span style={styles.asterisk}>*</span>
            </Text>
          </Box>
        )}
        <UISelect
          title={isRes && getTitles('T-11248')}
          options={taskType}
          containerStyles={styles.fieldContainer}
          placeholder={getTitles('T-11318')}
          name="task_type"
          optionLabel="name"
          returnValue="_id"
          onChange={handleChange}
          value={values.task_type}
          onBlur={handleBlur}
          disabled={actionType === view}
          error={touched.task_type && errors.task_type}
          required={true}
        />
      </Box>
      <Box sx={styles.container}>
        {!isRes && (
          <Box sx={styles.labelContainer}>
            <Text>
              {getTitles('T-11251')} <span style={styles.asterisk}>*</span>
            </Text>
          </Box>
        )}
        <Box sx={styles.threeFieldContainer}>
          <UISelect
            title={isRes && getTitles('T-11251')}
            options={trigerType}
            name="trigger_at"
            onChange={handleChange}
            value={values.trigger_at}
            placeholder={getTitles('T-11319')}
            containerStyles={styles.threeFieldUISelect}
            onBlur={handleBlur}
            disabled={actionType === view}
            error={touched.trigger_at && errors.trigger_at}
            required={true}
          />
          <Box sx={styles.operateCont}>
            <UISelect
              options={defaultValues.taskOperateType}
              name="operate"
              onChange={handleChange}
              value={values.operate}
              containerStyles={styles.uiSelectSub}
              placeholder={getTitles('T-10350')}
              onBlur={handleBlur}
              disabled={actionType === view}
              error={touched.operate && errors.operate}
            />
            <UITextInputWithTitle
              name="no_of_days"
              onChange={handleChange}
              value={values.no_of_days}
              placeholder={getTitles('T-11320')}
              containerStyles={styles.textField}
              onBlur={handleBlur}
              disabled={actionType === view}
              error={touched.no_of_days && errors.no_of_days}
              allowOnlyNumbers={true}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.container}>
        {!isRes && (
          <Box sx={styles.labelContainer}>
            <Text>{getTitles('T-11266')}</Text>
          </Box>
        )}
        <Box sx={styles.threeFieldContainer}>
          <UISelect
            title={isRes && getTitles('T-11266')}
            options={defaultValues.repeatationOptions}
            containerStyles={styles.commonField}
            name="priority"
            onChange={handleChange}
            value={values.priority || ''}
            disabled={actionType === view}
            placeholder={getTitles('T-10350')}
          />
          <Box sx={styles.fieldsWraper}>
            {!isRes && <Text>{getTitles('T-11267')}</Text>}
            <UIRadioButtonWithTitle
              title={isRes && getTitles('T-11267')}
              values={defaultValues.taskRepeatValue}
              onCheck={(val) => setFieldValue('repeat', val)}
              disabled={actionType === view}
              checkedValue={values.repeat}
            />
          </Box>
          {values.repeat && (
            <Box sx={styles.fullScre}>
              <UITextInputWithTitle
                title={isRes && getTitles('T-11358')}
                placeHolder={getTitles('T-11320')}
                name="repeat_days"
                onChange={handleChange}
                disabled={actionType === view}
                allowOnlyNumbers={true}
                value={values.repeat_days}
              />
            </Box>
          )}
        </Box>
      </Box>

      {actionType !== add && values.task_status && (
        <Box sx={styles.container}>
          {!isRes && (
            <Box sx={styles.labelContainer}>
              <Text>{getTitles('T-11249')}</Text>
            </Box>
          )}
          <Box sx={styles.taskStatus}>
            <UISelect
              title={isRes && getTitles('T-11249')}
              options={defaultValues.callCenterStatus}
              containerStyles={styles.statusField}
              name="call_center_status"
              onChange={handleChange}
              value={values.call_center_status}
              disabled={actionType === view}
              placeholder={getTitles('T-10350')}
            />
            <Box sx={styles.taskStatus}>
              {!isRes && <Text>{getTitles('T-11279')}</Text>}
              <UISelect
                title={isRes && getTitles('T-11279')}
                options={defaultValues.taskStatusOptions}
                containerStyles={styles.taskStatusField}
                name="task_status"
                onChange={handleChange}
                value={values.task_status}
                disabled={actionType === view}
                placeholder={getTitles('T-10350')}
              />
            </Box>
          </Box>
        </Box>
      )}

      <Box sx={styles.container}>
        {!isRes && (
          <Box sx={styles.labelContainer}>
            <Text>
              {getTitles('T-11265')} <span style={styles.asterisk}>*</span>
            </Text>
          </Box>
        )}
        <UISelect
          title={isRes && getTitles('T-11265')}
          options={defaultValues.roleOptions}
          containerStyles={styles.fieldContainer}
          onChange={handleChange}
          name="assign_role"
          optionLabel="name"
          returnValue="id"
          value={values.assign_role}
          placeholder={getTitles('T-11321')}
          onBlur={handleBlur}
          disabled={actionType === view}
          error={touched.assign_role && errors.assign_role}
          required={true}
        />
      </Box>
      <Box sx={styles.container}>
        {!isRes && (
          <Box sx={styles.labelContainer}>
            <Text>{getTitles('T-11262')}</Text>
          </Box>
        )}
        <UISelectCheckGroup
          title={isRes && getTitles('T-11262')}
          name="notify_role"
          options={[
            {
              value: defaultValues.roleOptions?.map((item) => ({
                title: item.name,
                value: item.name
              }))
            }
          ]}
          onChange={(val) => {
            let newArr = defaultValues.roleOptions.filter((item) => val.includes(item.name));
            setFieldValue('notify_role', newArr);
            setBtnDisabled(false);
          }}
          preSelectedValues={values.notify_role}
          placeholder={getTitles('T-11321')}
          hasName={true}
          disabled={actionType === view}
          containerStyles={styles.fieldContainer}
        />
      </Box>
      <Box sx={styles.container}>
        {!isRes && (
          <Box sx={styles.labelContainer}>
            <Text>{getTitles('T-11271')}</Text>
          </Box>
        )}
        <UISelectCheckGroup
          title={isRes && getTitles('T-11271')}
          name="remind_role"
          options={[
            {
              value: defaultValues.roleOptions?.map((item) => ({
                title: item.name,
                value: item.name
              }))
            }
          ]}
          onChange={(val) => {
            let newArr = defaultValues.roleOptions.filter((item) => val.includes(item.name));
            setFieldValue('remind_role', newArr);
            if (!newArr.length) {
              setFieldValue('remind_on', []);
              setRemindOn({ remind_at: '', days: '', timing: null });
            }
            setBtnDisabled(false);
          }}
          preSelectedValues={values.remind_role}
          placeholder={getTitles('T-11321')}
          hasName={true}
          disabled={actionType === view}
          containerStyles={styles.fieldContainer}
        />
      </Box>
      {values.remind_role?.length > 0 && actionType !== view && (
        <Box sx={styles.container}>
          {!isRes && (
            <Box sx={styles.labelContainer}>
              <Text>{getTitles('T-11272')}</Text>
            </Box>
          )}
          <Box sx={styles.dueDataContainer}>
            <Box sx={styles.dueDataFieldWraper}>
              <UISelect
                title={isRes && getTitles('T-11272')}
                containerStyles={styles.threeFieldUISelect}
                options={defaultValues.remindAtOptions}
                value={remindOn.remind_at}
                placeholder={getTitles('T-10350')}
                disabled={actionType === view}
                onChange={(e) => {
                  setRemindOn({ ...remindOn, remind_at: e.target.value });
                  setBtnDisabled(false);
                }}
              />
              <Box sx={styles.dueOtherFieldWraper}>
                {remindOn.remind_at && remindOn.remind_at !== 1 && (
                  <UITextInputWithTitle
                    containerStyles={styles.textField}
                    value={remindOn.days}
                    placeHolder={getTitles('T-11320')}
                    allowOnlyNumbers={true}
                    disabled={actionType === view}
                    onChange={(e) => {
                      setRemindOn({ ...remindOn, days: Number(e.target.value) });
                      setBtnDisabled(false);
                    }}
                  />
                )}

                <UITimePicker
                  containerStyle={styles.uiSelectSub}
                  value={remindOn.timing}
                  disabled={actionType === view}
                  selectedTime={(val) => {
                    setRemindOn({ ...remindOn, timing: val });
                    setBtnDisabled(false);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box sx={styles.container}>
        {!isRes && (
          <Box sx={styles.labelContainer}>
            {actionType === view && <Text>{getTitles('T-11272')}</Text>}
          </Box>
        )}
        <Box sx={styles.dueDataContainer}>
          {values.remind_on?.map((item, index) => (
            <Box sx={{ width: '100%' }} key={index}>
              <Box sx={styles.remindAtWraper}>
                <UISelect
                  title={actionType === view && isRes && getTitles('T-11272')}
                  containerStyles={styles.threeFieldUISelect}
                  options={defaultValues.remindAtOptions}
                  value={item.remind_at}
                  disabled={actionType === view}
                  onChange={(e) => updateRemindOn(index, 'remind_at', e.target.value)}
                />
                <Box sx={styles.dueOtherFieldWraper}>
                  {item.remind_at !== 1 && (
                    <UITextInputWithTitle
                      containerStyles={styles.textField}
                      value={item.days}
                      allowOnlyNumbers={true}
                      disabled={actionType === view}
                      onChange={(e) => updateRemindOn(index, 'days', Number(e.target.value))}
                    />
                  )}
                  <UITimePicker
                    containerStyle={styles.uiSelectSub}
                    value={item.timing}
                    disabled={actionType === view}
                    selectedTime={(val) => updateRemindOn(index, 'timing', val)}
                  />
                  {actionType !== view && (
                    <UIminusDeleteButton onClick={() => deleteRemindOn(index)} />
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      {values.remind_role?.length > 0 && actionType !== view && (
        <Box sx={styles.container}>
          {!isRes && <Box sx={styles.labelContainer}></Box>}
          <Box>
            <UISaveAndAdd
              title={getTitles('T-11323')}
              onAddAnother={addRemindOn}
              disabled={
                (remindOn.remind_at === 1 && (!remindOn.timing || !remindOn.remind_at)) ||
                (remindOn.remind_at !== 1 &&
                  (!remindOn.remind_at || !remindOn.timing || !remindOn.days))
              }
            />
          </Box>
        </Box>
      )}
      <Box sx={styles.container}>
        {!isRes && <Box sx={styles.labelContainer}></Box>}
        {actionType !== view && (
          <Box>
            <UIButton
              title={getTitles(actionType !== add ? 'T-10046' : 'T-10394')}
              style={styles.btn}
              disabled={
                !dirty ||
                !isValid ||
                btnDisabled ||
                (values.remind_role.length > 0 &&
                  remindOn.remind_at === 1 &&
                  (!remindOn.timing || !remindOn.remind_at)) ||
                (values.remind_role.length > 0 &&
                  [2, 3].includes(remindOn.remind_at) &&
                  (!remindOn.remind_at || !remindOn.timing || !remindOn.days))
              }
              onClick={handleSave}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
