import { color } from '../../../../theme';

export const styles = {
  tableSubCont: (isList) => ({
    width: 'fit-content',
    minHeight: isList ? '50vh' : 'none'
  }),
  col12: '120px',
  col15: '150px',
  col17: '170px',
  col20: '200px',
  col22: '220px',
  viewTxt: {
    color: color.link,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
};
