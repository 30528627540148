import { defaultValues } from '../../constants';
import { color } from '../../theme';

const res = defaultValues.isResponsive;

export const styles = {
  container: {
    width: '100%',
    px: res ? 1 : 2,
    boxSizing: 'border-box'
  },
  backBtn: {
    position: 'absolute',
    left: 0
  },

  cartCont: {
    display: 'flex',
    alignItems: 'center',
    gap: res ? '7px' : '20px',
    ml: res ? 'auto' : '20px'
  },
  cartSuperCont: {
    width: '100%',
    display: 'flex',
    justifyContent: res ? 'start' : 'center',
    alignItems: 'center',
    position: 'relative'
  },
  searchBarCont: {
    width: '100%s',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.primaryBackground,
    p: 1,
    boxSizing: 'border-box',
    borderRadius: '4px',
    mt: 2
  },
  searchBar: {
    width: res ? '100%' : '70%'
  },
  icons: {
    cursor: 'pointer',
    fontSize: '30px'
  },
  badge: {
    mr: 1,
    '& .MuiBadge-badge': {
      right: 10,
      top: 5,
      padding: '0 4px',
      fontSize: '10px',
      backgroundColor: color.secondary,
      border: res ? '1px solid' : 'none',
      boxSizing: 'border-box'
    },
    cursor: 'pointer'
  },
  call: {
    width: '25px',
    height: '25px'
  },
  dummyDiv: {
    width: '100%',
    height: res ? '40px' : '60px',
    background: 'linear-gradient(90deg, #B5E8B5 0%, #FAEEBD 100%)'
  }
};
