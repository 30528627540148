import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';
const res = defaultValues.isResponsive;

export const styles = {
  container: {
    width: '100%'
  },
  main: {
    width: '100%',
    backgroundColor: color.primaryBackground,
    border: `1px solid ${color.secondaryBackground}`,
    borderRadius: '4px',
    p: res ? 1 : 3,
    boxSizing: 'border-box'
  },
  subCont: {
    width: '100%',
    display: res ? 'block' : 'flex',
    gap: '10px'
  },
  width: res ? '100%' : 'calc((100% - 20px)/3)',
  phone: {
    width: res ? '100%' : 'calc((100% - 8px)/3 * 2)',
    maxWidth: 'none'
  },
  editBtn: {
    padding: res ? '5px' : '5px 20px',
    height: res ? 'fit-content' : '40px',
    width: 'fit-content',
    '&.MuiButton-root': {
      minWidth: '0px'
    },
    '& .MuiButton-startIcon': {
      marginRight: res ? '0px' : '5px',
      marginLeft: '0px'
    },
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    ml: res && 'auto'
  },
  autoComp: {
    mt: 1
  },
  editCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end'
  },
  backCont: {
    width: '100%',
    display: 'flex',
    justifyContent: res ? 'end' : 'space-between',
    py: 1
  }
};
