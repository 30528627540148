import { defaultValues } from '../../constants';

const res = defaultValues.isResponsive;
const sm = defaultValues.isMobile;
const md = defaultValues.isMedium;
const lg = defaultValues.isLarge;

export const profileStyles = {
  mainCont: {
    height: 'max-content',
    display: 'grid'
  },
  backArrowBtn: {
    p: 1,
    mt: res ? 1 : 3,
    ml: res ? 2 : 4
  },
  btnIcon: {
    fontSize: res ? '25px' : '40px'
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    pt: 3,
    px: 4,
    boxSizing: 'border-box',
    overflow: 'none'
  },
  section1: {
    width: res ? '100%' : '40%',
    display: 'flex',
    justifyContent: res ? 'center' : 'left'
  },
  picDialogCont: {
    top: sm ? '25%' : res ? '20%' : md ? '25%' : lg ? '50%' : '29%',
    right: res ? '15%' : md ? '2%' : lg ? '40%' : '7%'
  },
  section2: {
    width: res ? '100%' : '60%',
    height: res ? 'fit-content' : '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  nameStyle: {
    width: res ? '100%' : 'calc(50% - 5px)'
  },
  nameCont: {
    display: 'flex',
    gap: '10px'
  },
  phoneCont: {
    maxWidth: '100%'
  },
  btn: {
    minWidth: '300px',
    width: res && '100%',
    padding: '5px 50px',
    maxWidth: 'none',
    mt: 3
  },
  btnsCont: {
    width: '100%',
    display: 'flex',
    justifyContent: res ? 'center' : 'left',
    gap: '20px',
    mb: 4
  },
  addressCont: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    py: 5,
    px: 4,
    boxSizing: 'border-box'
  },
  checkBox: {
    width: 'fit-content',
    mt: 2
  },
  addAnotherbtn: {
    mt: 3
  }
};
