import { defaultValues } from '../../constants';
import { color } from '../../theme/color';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const sm = defaultValues.isMobile;
export const Styles = {
  toolsIcons: (showTitle) => ({
    maxWidth: '100%',
    maxHeight: sm ? '14px' : res ? '18px' : '24px',
    marginRight: showTitle && '6px'
  }),
  toolsIconContainer: () => ({
    display: 'flex',
    justifyContent: 'center',
    padding: sm ? '10px' : '10px 20px',
    boxSizing: 'border-box',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '8px',
    cursor: 'pointer',
    alignItems: 'center',
    backgroundColor: color.secondaryBackground,
    height: res ? '32px' : '48px'
  }),
  menuHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'space-between',
    justifyContent: 'space-between',
    height: '40px',
    width: '100%'
  },
  projectFilter: { padding: '0px 10px' },
  contentContainer: {
    display: 'flex',
    height: '100%',
    minHeight: '150px',
    width: '100%'
  },
  subContainer: {
    minWidth: '75px',
    width: 'fit-content',
    backgroundColor: color.secondaryBackground
  },
  typesText: (fil) => ({
    padding: '10px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    backgroundColor: fil && color.disabled
  }),
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0',
    boxSizing: 'border-box'
  },
  applyButton: {
    padding: '5px 20px',
    boxSizing: 'border-Box',
    width: 'fit-content',
    minWidth: 0,
    marginRight: '10px',
    fontSize: '14px'
  },
  applyBtnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
    marginTop: 'auto'
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: res ? 'none' : '350px',
    minHeight: '75%',
    overflowY: 'auto'
  },
  checkBox: { padding: 0 },
  checkboxIcon: {
    fontSize: '25px'
  },
  icon: {
    fontSize: '25px'
  },
  menuItem: {
    fontSize: res ? '0.625rem' : md ? '0.75rem' : '1rem'
  },
  divider: { mt: 0.5 },
  menu: (anchorEl) => ({
    background: anchorEl !== null ? color.palette.backDropColor : 'none',
    paddingBottom: '0px',
    '& .MuiList-root': {
      paddingBottom: '0px'
    }
  }),
  textBreak: { whiteSpace: 'pre-wrap' },
  menuAndBtnContainer: { width: '65%', paddingBottom: '10px', boxSizing: 'border-box' },
  list: { padding: '5px', paddingBottom: '0px', minWidth: '300px' }
};
