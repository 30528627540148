import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';
const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const styles = {
  container: {
    width: '100%',
    padding: res ? '2%' : '2% 6%',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground,
    boxSizing: 'border-box',
    marginTop: '10px'
  },
  backBtnCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  editIcon: {
    cursor: 'pointer',
    width: res ? '20px' : md ? '23px' : '26px',
    height: res ? '20px' : md ? '23px' : '26px'
  },
  editBtn: (show) => ({
    height: res ? '30px' : '45px',
    marginLeft: 'auto',
    minWidth: res && 0,
    fontSize: res ? '1.35rem' : '1.3rem',
    padding: res ? '5px' : '5px 20px',
    width: res ? 'fit-content' : '150px',
    '&.MuiButton-root': {
      minWidth: '0px'
    },
    '& .MuiButton-startIcon': {
      marginRight: res ? '0px' : '5px',
      marginLeft: '0px'
    },
    boxSizing: 'border-box',
    visibility: show ? 'visible' : 'hidden'
  }),
  subCont: { width: '100%', display: 'flex' },
  stepperCont: { display: 'flex', width: '100%', mt: 2, mb: 3 }
};
