import { defaultValues, getErrorMessage } from '../../../constants';
import { convertStringsToBoolean, getFormatDate, getTitles } from '../../../utils/commonFunction';
import { UILayout } from '../../../ui-kits';
import callIcon from '../../../assets/icons/call.png';
import high from '../../../assets/icons/high.png';
import low from '../../../assets/icons/low.png';
import medium from '../../../assets/icons/medium.png';
import pending from '../../../assets/icons/pending.png';
import close from '../../../assets/icons/close.png';
import open from '../../../assets/icons/open.png';
import { BasicProfile } from '../../common/utility-components';
import { Box } from '@mui/material';
import { color, globalStyles } from '../../../theme';
import { StatusCard } from '../../common';
import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCallcenterAllTasks,
  getCallcenterCallerTasks,
  getCallcenterMyTasks,
  getCallCenterSuccessMsg,
  getCallcenterTaskDetails
} from '../../../services/redux/selectors';
import { useEffect, useState } from 'react';
import {
  getCallAgentAllTasksRequest,
  getCallAgentMyTasksRequest,
  getCallCenterTaskDetailsRequest,
  getCallerTasksRequest,
  getMasterListRequest,
  setCallCenterTaskModel
} from '../../../services/redux/slices';
import { getMasterList } from '../../../services/redux/selectors/manageProccess';

export const TasksCallcenter = ({ currTab, caller }) => {
  const dispatch = useDispatch();

  const success = useSelector(getCallCenterSuccessMsg);
  const taskDetails = useSelector(getCallcenterTaskDetails);
  const taskTypes = useSelector(getMasterList);

  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  const [filterDate, setFilterDate] = useState(defaultValues.dueDateRange.today);
  const [filterOptions, setFilterOptions] = useState([]);

  const [dateValue, setDateValue] = useState(null);
  const { callerTask, myTask, allTask } = defaultValues.callCenterCurrTabs;
  const { edit } = defaultValues.actionType;

  const allTaskList = useSelector(getCallcenterAllTasks);
  const myTaskList = useSelector(getCallcenterMyTasks);
  const callerTaskList = useSelector(getCallcenterCallerTasks);

  useEffect(() => {
    if (success === 'S-10096')
      dispatch(setCallCenterTaskModel({ type: taskDetails?.user_type?.id, mode: edit }));
    if (['S-10098', 'S-10097'].includes(success)) handleGetTaskList();
  }, [success]);

  useEffect(() => {
    let options = [];
    if (currTab === myTask) options = [...defaultValues.callCenterMyTasksFilterOption];
    if (currTab === allTask && taskTypes?.length > 0)
      options = [...defaultValues.callCenterAllTasksFilterOption(taskTypes)];
    if (currTab === callerTask && taskTypes?.length > 0)
      options = [...defaultValues.callCenterCallerTasksFilterOption(taskTypes)];
    setFilterOptions(options.length > 0 ? options : []);
  }, [currTab, taskTypes]);

  useEffect(() => {
    currTab === allTask &&
      dispatch(
        getMasterListRequest({
          master_id: defaultValues.mListIds.tastType,
          filter: JSON.stringify([{ is_active: true }])
        })
      );
  }, [currTab]);

  const handleGetTaskList = (val) => {
    let payload = {
      sort: sort,
      filter: filter,
      due_date_range: [myTask, allTask].includes(currTab) && filterDate,
      custom_due_date: [myTask, allTask].includes(currTab) && dateValue,
      ...val
    };
    currTab === myTask && dispatch(getCallAgentMyTasksRequest(payload));
    currTab === allTask && dispatch(getCallAgentAllTasksRequest(payload));
    currTab === callerTask &&
      dispatch(
        getCallerTasksRequest({
          ...payload,
          user_type: caller?.user_type,
          user_id: caller?.user_id
        })
      );
  };

  const handleSearch = (data) => {
    handleGetTaskList({ search_text: data });
  };

  const handleFilter = (data) => {
    setFilter(data);
    handleGetTaskList({
      filter: JSON.stringify(convertStringsToBoolean(data)),
      sort: sort
    });
  };

  const handleSort = (data) => {
    setSort(data);
    handleGetTaskList({ sort: data, filter: JSON.stringify(filter) });
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    setDateValue(null);
    setFilterDate(defaultValues.dueDateRange.today);
    handleGetTaskList({
      search_text: null,
      filter: null,
      sort: null,
      due_date_range: null,
      custom_due_date: null
    });
  };

  const handleDueDateRange = (val) => {
    setFilterDate(val);
    setDateValue(null);

    if (val !== defaultValues.dueDateRange.custom) {
      handleGetTaskList({ due_date_range: val });
      return;
    }
    if (val === defaultValues.dueDateRange.custom && dateValue) {
      handleGetTaskList({ due_date_range: val, custom_due_date: dateValue });
      return;
    }
  };

  const handleCustomDateChange = (val) => {
    setDateValue(val);
    handleGetTaskList({ due_date_range: filterDate, custom_due_date: val });
  };
  const handleEdit = (item) => {
    dispatch(getCallCenterTaskDetailsRequest({ task_id: item._id }));
  };

  const getIconOne = (item) => {
    return item.task_status?.id === 1
      ? open
      : item.task_status?.id === 2
        ? pending
        : item.task_status?.id === 3 && close;
  };

  const getIconTwo = (item) => {
    return item.task_task_status?.id === 3
      ? null
      : item.priority?.id === 1
        ? high
        : item.priority?.id === 2
          ? medium
          : item.priority?.id === 3 && low;
  };

  const getTitleOne = (item) => {
    return item.task_status?.id === 1
      ? getTitles('T-11316')
      : item.task_status?.id === 2
        ? getTitles('T-11317')
        : item.task_status?.id === 3 && getTitles('T-11103');
  };

  const getTitleTwo = (item) => {
    return item.task_status?.id === 3
      ? null
      : item.priority?.id === 1
        ? getTitles('T-11305')
        : item.priority?.id === 2
          ? getTitles('T-11306')
          : item.priority?.id === 3 && getTitles('T-11307');
  };

  const callerTasksData =
    callerTaskList?.length > 0 &&
    callerTaskList.map((item) => [
      {
        title: getTitles('T-11245'),
        headerTextStyle: styles.tableHeaderTextStyle,
        values: (
          <Box sx={styles.subCont}>
            <BasicProfile title={item.task_id} isIcon={false} titleStyle={styles.center} />
            <BasicProfile
              title={item.task_name}
              isIcon={false}
              titleStyle={{ ...styles.center, ...globalStyles.title }}
              onTitleClick={() => handleEdit(item)}
            />
          </Box>
        ),
        valueTextStyle: styles.center
      },
      {
        title: getTitles('T-11247'),
        columnWidth: '10%',
        values: (
          <StatusCard
            icon1={getIconOne(item)}
            icon2={getIconTwo(item)}
            title1={getTitleOne(item)}
            title2={getTitleTwo(item)}
          />
        ),
        headerTextStyle: styles.tableHeaderTextStyle
      },
      {
        title: getTitles('T-11249'),
        values:
          item.call_center_status?.id &&
          defaultValues.callCenterStatus.find((ele) => ele.value === item.call_center_status?.id)
            ?.title,
        headerTextStyle: styles.tableHeaderTextStyle,
        valueTextStyle: styles.center
      },
      {
        title: getTitles('T-11248'),
        values: item.task_type?.name,
        headerTextStyle: styles.tableHeaderTextStyle,
        valueTextStyle: styles.center
      },
      {
        title: getTitles('T-11246'),
        values: (
          <Box sx={styles.assigneeCont}>
            <BasicProfile
              title={item.created_by?.full_name}
              isIcon={false}
              containerStyle={styles.mb}
            />
            <BasicProfile
              title={
                defaultValues.userRoles.find((ele) => ele.id === item.created_by?.user_role?.id)
                  ?.role
              }
              isIcon={false}
              containerStyle={styles.mb}
            />
            {item.created_by?.phone_number && (
              <BasicProfile
                title={item.created_by?.phone_number}
                url={callIcon}
                iconPositon="right"
                containerStyle={styles.mb}
              />
            )}
            <BasicProfile title={item.created_by?.location} isIcon={false} />
          </Box>
        ),
        columnWidth: '16%'
      },
      {
        title: getTitles('T-11252'),
        values: (
          <BasicProfile
            title={item.created_by?.full_name}
            isIcon={false}
            titleStyle={styles.center}
          />
        ),
        headerTextStyle: styles.tableHeaderTextStyle
      },
      {
        title: getTitles('T-11282'),
        values: (
          <BasicProfile
            title={item.created_at && getFormatDate(item.created_at)}
            isIcon={false}
            titleStyle={styles.center}
          />
        ),
        headerTextStyle: styles.tableHeaderTextStyle
      },
      {
        title: getTitles('T-11251'),
        values: item.due_date && getFormatDate(item.due_date),
        valueTextStyle: styles.center,
        headerTextStyle: styles.tableHeaderTextStyle
      }
    ]);

  const myTasksData =
    myTaskList?.length > 0 &&
    myTaskList.map((item) => [
      {
        title: getTitles('T-11245'),
        headerTextStyle: styles.tableHeaderTextStyle,
        values: (
          <Box sx={styles.subCont}>
            <BasicProfile title={item.task_id} isIcon={false} titleStyle={styles.center} />
            <BasicProfile
              title={item.task_name}
              isIcon={false}
              titleStyle={{ ...styles.center, ...globalStyles.title }}
              onTitleClick={() => handleEdit(item)}
            />
          </Box>
        ),
        valueTextStyle: styles.center
      },
      {
        title: getTitles('T-11247'),
        values: (
          <StatusCard
            icon1={getIconOne(item)}
            icon2={getIconTwo(item)}
            title1={getTitleOne(item)}
            title2={getTitleTwo(item)}
          />
        ),
        headerTextStyle: styles.tableHeaderTextStyle
      },
      {
        title: getTitles('T-11249'),
        values:
          item.call_center_status?.id &&
          defaultValues.callCenterStatus.find((ele) => ele.value === item.call_center_status?.id)
            ?.title,
        headerTextStyle: styles.tableHeaderTextStyle,
        valueTextStyle: styles.center
      },

      {
        title: getTitles('T-11248'),
        values: item.task_type,
        headerTextStyle: styles.tableHeaderTextStyle,
        valueTextStyle: styles.center
      },
      {
        title: getTitles('T-11387'),
        headerTextStyle: styles.tableHeaderTextStyle,
        values: (
          <Box sx={styles.subCont}>
            <BasicProfile
              title={item.caller_details?.full_name}
              isIcon={false}
              containerStyle={styles.mb}
            />
            <BasicProfile
              title={item.caller_details?.phone_number}
              url={callIcon}
              iconPositon="right"
              containerStyle={styles.mb}
            />
            <BasicProfile title={item.caller_details?.location} isIcon={false} />
          </Box>
        ),
        columnWidth: '16%'
      },
      {
        title: getTitles('T-11250'),
        values: (
          <BasicProfile
            title={item.created_at && getFormatDate(item.created_at)}
            isIcon={false}
            titleStyle={styles.center}
          />
        ),
        headerTextStyle: styles.tableHeaderTextStyle
      },
      {
        title: getTitles('T-11251'),
        values: (
          <BasicProfile
            title={item.due_date && getFormatDate(item.due_date)}
            isIcon={false}
            titleStyle={styles.center}
          />
        ),
        valueTextStyle: styles.center,
        headerTextStyle: styles.tableHeaderTextStyle
      }
    ]);

  const allTasksData =
    allTaskList?.length > 0 &&
    allTaskList.map((item) => [
      {
        title: getTitles('T-11245'),
        headerTextStyle: styles.tableHeaderTextStyle,
        values: (
          <Box sx={styles.subCont}>
            <BasicProfile title={item.task_id} isIcon={false} titleStyle={styles.center} />
            <BasicProfile
              title={item.task_name}
              isIcon={false}
              titleStyle={{ ...styles.center, ...globalStyles.title }}
              onTitleClick={() => handleEdit(item)}
            />
          </Box>
        )
      },
      {
        title: getTitles('T-11247'),
        columnWidth: '10%',
        values: (
          <StatusCard
            icon1={getIconOne(item)}
            icon2={getIconTwo(item)}
            title1={getTitleOne(item)}
            title2={getTitleTwo(item)}
          />
        ),
        headerTextStyle: styles.tableHeaderTextStyle
      },
      {
        title: getTitles('T-11249'),
        values:
          item.call_center_status?.id &&
          defaultValues.callCenterStatus.find((ele) => ele.value === item.call_center_status?.id)
            ?.title,
        headerTextStyle: styles.tableHeaderTextStyle,
        valueTextStyle: styles.center
      },
      {
        title: getTitles('T-11248'),
        values: item.task_type,
        headerTextStyle: styles.tableHeaderTextStyle,
        valueTextStyle: styles.center
      },
      {
        title: getTitles('T-11308'),
        values: (
          <Box sx={{ width: '100%' }}>
            <BasicProfile
              title={item.caller_details?.full_name}
              isIcon={false}
              containerStyle={styles.mb}
            />
            <BasicProfile
              title={item.caller_details?.phone_number}
              url={callIcon}
              iconPositon="right"
              containerStyle={styles.mb}
            />
            <BasicProfile title={item.caller_details?.location} isIcon={false} />
          </Box>
        ),
        columnWidth: '16%'
      },
      {
        title: getTitles('T-11246'),
        values: (
          <Box sx={styles.assigneeCont}>
            <BasicProfile
              title={item.created_by?.full_name}
              isIcon={false}
              containerStyle={styles.mb}
            />
            <BasicProfile
              title={defaultValues.userRoles.find((ele) => ele.id === item.created_by?.role)?.role}
              isIcon={false}
              containerStyle={styles.mb}
            />
            <BasicProfile
              title={item.created_by?.phone_number}
              url={callIcon}
              iconPositon="right"
              containerStyle={styles.mb}
            />
            <BasicProfile title={item.created_by?.location} isIcon={false} />
          </Box>
        ),
        columnWidth: '16%'
      },
      {
        title: getTitles('T-11261'),
        values: (
          <BasicProfile
            title={item.created_at && getFormatDate(item.created_at)}
            subTitle={item.created_by?.full_name}
            subTitleVariant="D20"
            subTitleStyle={{ color: color.primaryText, ...styles.center }}
            isIcon={false}
            titleStyle={styles.center}
          />
        ),
        headerTextStyle: styles.tableHeaderTextStyle
      },

      {
        title: getTitles('T-11251'),
        values: (
          <BasicProfile
            title={item.due_date && getFormatDate(item.due_date)}
            isIcon={false}
            titleStyle={styles.center}
          />
        ),
        valueTextStyle: styles.center,
        headerTextStyle: styles.tableHeaderTextStyle
      }
    ]);

  return (
    <Box sx={styles.container}>
      <UILayout
        handleSearch={handleSearch}
        isMultiSelectFilterOption
        searchBarPlaceHolder={
          currTab === myTask
            ? getTitles('T-11401')
            : currTab === allTask
              ? getTitles('T-11005')
              : getTitles('T-11401')
        }
        tableItems={
          currTab === myTask
            ? myTasksData
            : currTab === allTask
              ? allTasksData
              : currTab === callerTask && callerTasksData
        }
        showNoData={
          currTab === myTask
            ? !myTaskList?.length > 0
            : currTab === allTask
              ? !allTaskList?.length > 0
              : currTab === callerTask && !callerTaskList?.length > 0
        }
        message={getErrorMessage('E-10194')}
        clearSearch={filter}
        currentlyActiveFilterOption={filter}
        currentlyActiveSortOption={sort}
        optionsForSorting={defaultValues.farmerSortOptions}
        optionsForFiltering={filterOptions}
        handleSort={handleSort}
        handleFilter={handleFilter}
        handleRefresh={handleRefresh}
        tableHeaderTextStyle={styles.tableHeaderTextStyle}
        isSelect={[myTask, allTask].includes(currTab)}
        showMlist={false}
        options={defaultValues.dueDateRangeOptions}
        selectLabel={null}
        selectPlaceholder={getTitles('T-10350')}
        selectValue={filterDate}
        selectedOption={handleDueDateRange}
        selectContainerStyle={styles.selectContainerStyle}
        showDatePicker={filterDate === defaultValues.dueDateRange.custom}
        dateValue={dateValue}
        handleDateChange={handleCustomDateChange}
        dateCont={styles.dateCont}
        dateInput={styles.dateInput}
      />
    </Box>
  );
};
