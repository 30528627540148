import { Box, Divider, IconButton } from '@mui/material';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import {
  DatePickers,
  Text,
  UIButton,
  UIChipButton,
  UIDataTable,
  UIPhoneInputTitle,
  UIRadioButtonWithTitle,
  UISaveAndAdd,
  UISelect,
  UITextInputWithTitle,
  UploadPicture
} from '../../../ui-kits';
import {
  farmerPersonalSchema,
  filterSelectedValue,
  getMasterListItems,
  getNamesByIds,
  getTitleWithId,
  getTitles,
  removeFalsyValues
} from '../../../utils';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import {
  addFarmerRequest,
  getReligionRequest,
  updateFarmerRequest,
  uploadFarmerDocRequest
} from '../../../services/redux/slices';
import {
  getActionType,
  getFarmerData,
  getFarmerSuccessMsg,
  getReligions
} from '../../../services/redux/selectors';
import { getMasterLists } from '../../../services/redux/selectors/manageProccess';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ModalButtons } from '../../common/modal-buttons';
import { toast } from 'react-toastify';
import { Styles } from '../style';
import dayjs from 'dayjs';
import { ResponsiveViewDetails } from '../responsive-table-view';
import { useNavigate } from 'react-router-dom';
import locationIcon from '../../../assets/icons/share_location.png';
import { Address } from '../../common/address';

export const PersonalDetails = ({
  onNext,
  setNext,
  society,
  permissionId,
  showAssignedLocation
}) => {
  const { view, edit, viewOnly } = defaultValues.actionType;
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const isRes = defaultValues.isResponsive;
  const religions = useSelector(getReligions);
  const listItems = useSelector(getMasterLists);
  const success = useSelector(getFarmerSuccessMsg);
  const farmerData = useSelector(getFarmerData);
  const actionType = useSelector(getActionType);

  const [casteOptions, setCasteOptions] = useState([]);
  // const [subCasteOptions, setSubCasteOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [societyCode, setSocietyCode] = useState('');
  const [btnDisable, setBtnDisable] = useState(true);

  const [occupationOption, setOccupationOption] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [profile, setProfile] = useState(null);
  const [cardColorOption, setCardColorOption] = useState([]);
  const [currFamilyDetails, setCurrentFamilyDetails] = useState({
    name: '',
    relation: '',
    age: '',
    occupation: '',
    education: '',
    gender: ''
  });
  const [isResponse, setIsResponse] = useState(true);
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (success) {
      let id = farmerData._id;
      if (['S-10042', 'S-10044'].includes(success) && isInit) {
        if (success === 'S-10042' && id) {
          dispatch(
            uploadFarmerDocRequest({ _id: id, id_photo: profile !== null ? profile : null })
          );
        }
        onNext();
      }
    }
  }, [success]);

  useEffect(() => {
    if (farmerData) {
      setIsEdit(true);
    }
  }, [isEdit]);

  useEffect(() => {
    if (listItems) {
      setOccupationOption(getMasterListItems(listItems, defaultValues.mListIds.occupation));
      setCardColorOption(getMasterListItems(listItems, defaultValues.mListIds.rationCardColor));
    }
  }, [listItems]);

  const { values, errors, touched, handleBlur, setFieldValue, setValues, isValid } = useFormik({
    initialValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
      phone_number: '',
      secondary_phone_number: '',
      date_of_birth: null,
      gender: null,
      religion_id: { _id: '' },
      caste_id: { _id: '' },
      // sub_caste_id: { _id: '' },
      occupation_id: [],
      farmer_id: null,
      ration_card_color_id: '',
      longitude: '',
      latitude: '',
      altitude: '',
      address: {
        address_line_one: '',
        address_line_two: '',
        country_id: '',
        state_id: { _id: '' },
        district_id: { _id: '' },
        taluka_id: { _id: '' },
        village_id: { _id: '' },
        post_code: ''
      },
      member_location: {
        country_id: '',
        state_id: { _id: '' },
        district_id: { _id: '' },
        taluka_id: { _id: '' },
        village_id: { _id: '' }
      },
      society_id: '',
      member_society_code: '',
      communication_mode: { id: '' },
      has_personal_insurance: false,
      insurance_number: '',
      insured_amount: null,
      insurance_provider: '',
      bpl: null,
      bank_details: {
        bank_name: '',
        account_number: '',
        ifsc_code: '',
        branch_name: ''
      },
      family_member_details: []
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: farmerPersonalSchema
  });

  useEffect(() => {
    setFilteredOptions(filterSelectedValue(occupationOption, values.occupation_id));
  }, [values.occupation_id, occupationOption]);

  useEffect(() => {
    dispatch(getReligionRequest());
  }, []);

  useEffect(() => {
    if (religions) {
      setReligionOptions(religions.map((item) => ({ name: item.name, _id: item._id })));
    }
  }, [religions]);

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude, altitude } = position.coords;
          setValues({
            ...values,
            latitude: latitude ? latitude.toString() : '0',
            longitude: longitude ? longitude.toString() : '0',
            altitude: altitude ? altitude.toString() : '0'
          });
        },
        (error) => {
          toast.error(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser.');
    }
  };

  const previousValues = useRef({
    religion_id: values.religion_id?._id,
    caste_id: values.caste_id?._id
    // sub_caste_id: values.sub_caste_id?._id
  });

  useEffect(() => {
    const prev = previousValues.current;

    if (values.religion_id?._id !== prev.religion_id && !isResponse) {
      const religion = religions.find((item) => item._id === values.religion_id?._id);

      setCasteOptions(religion.cast_list);
      setFieldValue('caste_id._id', '');
      // setFieldValue('sub_caste_id._id', '');
    }
    // if (values.caste_id?._id !== prev.caste_id && !isResponse) {
    //   const caste = casteOptions.find((item) => item._id === values.caste_id?._id);
    //   setSubCasteOptions(caste?.sub_cast_list);
    //   setFieldValue('sub_caste_id._id', '');
    // }
    previousValues.current = {
      religion_id: values.religion_id?._id,
      caste_id: values.caste_id?._id
      // sub_caste_id: values.sub_caste_id?._id
    };
  }, [values.religion_id?._id, values.caste_id?._id /*, values.sub_caste_id?._id */]);

  useEffect(() => {
    let caste;
    // let subCaste;
    setIsResponse(true);
    if (farmerData?.personal_details) {
      let newValues = {
        ...farmerData.personal_details,
        date_of_birth: farmerData.personal_details?.date_of_birth
          ? dayjs(farmerData.personal_details?.date_of_birth)
          : null,
        farmer_id: farmerData.farmer_id,
        gender: farmerData.personal_details?.gender || '',
        ration_card_color_id: farmerData.personal_details?.ration_card_color_id || '',
        bpl: farmerData.personal_details?.bpl || '',
        society_id: farmerData.personal_details?.society_id || '',
        bank_details: {
          bank_name: farmerData.personal_details?.bank_details?.bank_name || '',
          account_number: farmerData.personal_details?.bank_details?.account_number || '',
          ifsc_code: farmerData.personal_details?.bank_details?.ifsc_code || '',
          branch_name: farmerData.personal_details?.bank_details?.branch_name || ''
        }
      };
      if (farmerData.personal_details.address) {
        newValues = {
          ...newValues,
          address: {
            address_line_one: farmerData.personal_details.address.address_line_one,
            address_line_two: farmerData.personal_details.address.address_line_two,
            country_id: farmerData.personal_details.address.country_id || '',
            state_id: farmerData.personal_details.address.state_id || { _id: '' },
            district_id: farmerData.personal_details.address.district_id || { _id: '' },
            taluka_id: farmerData.personal_details.address.taluka_id || { _id: '' },
            village_id: farmerData.personal_details.address.village_id || { _id: '' },
            post_code: farmerData.personal_details.address.post_code
          }
        };
      }

      setValues(newValues);

      let religion =
        religions.length > 0 &&
        religions.find((item) => item._id === farmerData.personal_details?.religion_id?._id);
      caste = religion?.cast_list;
      setCasteOptions(caste);
      // subCaste = caste?.find((item) => item._id === farmerData.personal_details?.caste_id?._id);
      // setSubCasteOptions(subCaste?.sub_cast_list);
    }
  }, [farmerData]);

  useEffect(() => {
    if (values.society_id) {
      const soc = society.find((item) => item._id === values.society_id);
      soc ? setSocietyCode(soc.society_code) : setSocietyCode('');
    } else {
      setSocietyCode('');
    }
  }, [values.society_id, society]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setBtnDisable(false);
    setIsResponse(false);

    if (name === 'bank_details.bank_name' && !value) {
      setFieldValue('bank_details.branch_name', '');
      setFieldValue('bank_details.account_number', '');
      setFieldValue('bank_details.ifsc_code', '');
    }
    if (name === 'occupation_id') {
      if (!values.occupation_id.includes(value)) {
        setFieldValue(name, [...values.occupation_id, { _id: value }]);
      }
    } else if (['altitude', 'latitude', 'longitude'].includes(name)) {
      const numericValue = Number(value);
      if (!isNaN(numericValue)) {
        setFieldValue(name, numericValue);
      }
    } else {
      setFieldValue(name, value);
    }
  };

  const handleDelete = (occupationToDelete) => {
    const newOccupations = values.occupation_id.filter(
      (occupation) => occupation !== occupationToDelete
    );
    setFieldValue('occupation_id', newOccupations);
  };

  const handleFamChange = (e) => {
    const { name, value } = e.target;
    setCurrentFamilyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleAddFamilyMember = () => {
    setBtnDisable(false);
    setFieldValue('family_member_details', [
      ...values.family_member_details,
      { ...currFamilyDetails }
    ]);
    setCurrentFamilyDetails({
      name: '',
      relation: '',
      age: '',
      occupation: '',
      education: '',
      gender: ''
    });
  };

  const handleDeleteFamilyMember = (index) => {
    setBtnDisable(false);
    const updatedFamilyDetails = [...values.family_member_details];
    updatedFamilyDetails.splice(index, 1);
    setFieldValue('family_member_details', updatedFamilyDetails);
  };

  const handleEditFamilyMember = (index) => {
    setCurrentFamilyDetails({ ...values.family_member_details[index] });
    const updatedFamilyDetails = [...values.family_member_details];
    updatedFamilyDetails.splice(index, 1);
    setFieldValue('family_member_details', updatedFamilyDetails);
  };

  const handleSubmit = () => {
    if (actionType === edit) {
      const personal_details = {
        ...values,
        longitude: values.longitude && parseFloat(values.longitude),
        latitude: values.latitude && parseFloat(values.latitude),
        altitude: values.altitude && parseFloat(values.altitude),
        address: {
          ...values.address,
          country_id: values.address?.country_id === '' ? null : values.address?.country_id,
          state_id: values.address?.state_id?._id === '' ? null : values.address?.state_id?._id,
          district_id:
            values.address?.district_id?._id === '' ? null : values.address?.district_id?._id,
          taluka_id: values.address?.taluka_id?._id === '' ? null : values.address?.taluka_id?._id,
          village_id:
            values.address?.village_id?._id === '' ? null : values.address?.village_id?._id
        },

        member_location: {
          ...values.member_location,
          state_id:
            values.member_location?.state_id?._id || values.member_location.state_id || null,
          district_id:
            values.member_location?.district_id?._id || values.member_location.district_id || null,
          taluka_id:
            values.member_location?.taluka_id?._id || values.member_location.taluka_id || null,
          village_id:
            values.member_location?.village_id?._id === ''
              ? null
              : values.member_location?.village_id?._id ||
                values.member_location?.village_id ||
                null
        },
        ration_card_color_id:
          values.ration_card_color_id?._id || values.ration_card_color_id || null,
        society_id: values.society_id?._id || values.society_id || null,
        religion_id: values.religion_id?._id || values.religion_id || null,
        caste_id: values.caste_id?._id || values.caste_id || null,
        // sub_caste_id: values.sub_caste_id?._id || values.sub_caste_id || null,
        bpl: values.bpl?.id || (typeof values.bpl === 'object' ? null : values.bpl) || null,
        insured_amount: parseInt(values.insured_amount),
        gender: parseInt(values.gender?.id) || parseInt(values.gender),
        family_member_details: values.family_member_details.map((item) => ({
          ...item,
          age: parseInt(item.age),
          gender: item.gender?.id || parseInt(item.gender),
          relation: item.relation?.id || item.relation
        })),
        occupation_id:
          values.occupation_id && values.occupation_id.length > 0
            ? values.occupation_id.map((item) => item._id || item)
            : [],
        communication_mode: values.communication_mode?.id
          ? values.communication_mode?.id
          : values.communication_mode
      };

      if (personal_details?.bank_details?.account_number === '') {
        delete personal_details.bank_details;
      }

      let id = farmerData._id;
      profile instanceof File && dispatch(uploadFarmerDocRequest({ _id: id, id_photo: profile }));
      dispatch(updateFarmerRequest({ _id: id, personal_details }));
    } else {
      let payload = {};
      payload.data = {
        ...values,
        longitude: values.longitude && parseFloat(values.longitude),
        latitude: values.latitude && parseFloat(values.latitude),
        altitude: values.altitude && parseFloat(values.altitude),
        address: {
          ...values.address,
          country_id: values.address?.country_id === '' ? null : values.address?.country_id,
          state_id: values.address?.state_id?._id === '' ? null : values.address?.state_id?._id,
          district_id:
            values.address?.district_id?._id === '' ? null : values.address?.district_id?._id,
          taluka_id: values.address?.taluka_id?._id === '' ? null : values.address?.taluka_id?._id,
          village_id:
            values.address?.village_id?._id === '' ? null : values.address?.village_id?._id
        },
        member_location: {
          ...values.member_location,
          state_id:
            values.member_location?.state_id?._id || values.member_location.state_id || null,
          district_id:
            values.member_location?.district_id?._id || values.member_location.district_id || null,
          taluka_id:
            values.member_location?.taluka_id?._id || values.member_location.taluka_id || null,
          village_id:
            values.member_location?.village_id?._id === ''
              ? null
              : values.member_location?.village_id?._id ||
                values.member_location?.village_id ||
                null
        },
        communication_mode: values.communication_mode?.id
          ? values.communication_mode?.id
          : values.communication_mode,
        ration_card_color_id:
          values.ration_card_color_id?._id || values.ration_card_color_id || null,
        society_id: values.society_id?._id || values.society_id || null,
        religion_id: values.religion_id?._id || values.religion_id || null,
        caste_id: values.caste_id?._id || values.caste_id || null,
        // sub_caste_id: values.sub_caste_id?._id || values.sub_caste_id || null,
        bpl: values.bpl?.id || values.bpl,
        insured_amount: parseInt(values.insured_amount),
        gender: parseInt(values.gender?.id) || parseInt(values.gender),
        family_member_details: values.family_member_details.map((item) => ({
          ...item,
          age: parseInt(item.age),
          gender: item.gender?.id || parseInt(item.gender),
          relation: item.relation?.id || item.relation
        })),
        occupation_id:
          values.occupation_id && values.occupation_id.length > 0
            ? values.occupation_id.map((item) => item._id || item)
            : []
      };

      payload.registration_step = 1;

      if (payload.data?.bank_details?.account_number === '') {
        delete payload.data.bank_details;
      }
      delete payload.data.farmer_id;
      if (payload.data) {
        payload = { ...payload, data: removeFalsyValues(payload.data) };
      }
      dispatch(addFarmerRequest({ ...payload, data: removeFalsyValues(payload.data) }));
    }

    setIsInit(true);
  };

  const tableData = [
    [
      {
        title: getTitles('T-10081'),
        values:
          actionType !== view ? (
            <UITextInputWithTitle
              name="name"
              variant="D22"
              allowOnlyAlphabets={true}
              onChange={handleFamChange}
              containerStyles={Styles.inputCont}
              value={currFamilyDetails.name}
            />
          ) : null
      },
      {
        title: getTitles('T-10449'),
        values:
          actionType !== view ? (
            <UISelect
              name="relation"
              onChange={handleFamChange}
              value={currFamilyDetails.relation?.id || currFamilyDetails.relation}
              variant="D22"
              options={defaultValues.relationship}
              inputStyle={Styles.inputCont}
              containerStyles={Styles.inputCont}
              placeholder={getTitles('T-10350')}
            />
          ) : null
      },
      {
        title: getTitles('T-10450'),
        columnWidth: '70px',
        values:
          actionType !== view ? (
            <UITextInputWithTitle
              name="age"
              variant="D22"
              onChange={handleFamChange}
              containerStyles={Styles.inputCont}
              value={currFamilyDetails.age}
              allowOnlyNumbers
            />
          ) : null
      },
      {
        title: getTitles('T-10351'),
        values:
          actionType !== view ? (
            <UISelect
              name="gender"
              variant="D22"
              options={defaultValues.genderOptions}
              placeholder={getTitles('T-10350')}
              containerStyles={Styles.inputCont}
              onChange={handleFamChange}
              value={currFamilyDetails.gender?.id || currFamilyDetails.gender}
              inputStyle={Styles.inputCont}
            />
          ) : null
      },
      {
        title: getTitles('T-10451'),
        values:
          actionType !== view ? (
            <UITextInputWithTitle
              name="occupation"
              variant="D22"
              onChange={handleFamChange}
              containerStyles={Styles.inputCont}
              value={currFamilyDetails.occupation}
              allowOnlyAlphabets
            />
          ) : null
      },
      {
        title: getTitles('T-10452'),
        values:
          actionType !== view ? (
            <UITextInputWithTitle
              name="education"
              variant="D22"
              onChange={handleFamChange}
              containerStyles={Styles.inputCont}
              value={currFamilyDetails.education}
              allowOnlyAlphabets
            />
          ) : null
      }
    ]
  ];

  return (
    <Box sx={Styles.container}>
      <Box sx={Styles.profileAndDetails}>
        <Box sx={Styles.halfWidthContainer()}>
          {actionType === view && (
            <UITextInputWithTitle
              title={getTitles('T-10751')}
              value={values.farmer_id}
              disabled={true}
              containerStyles={Styles.halfWidthField}
            />
          )}
          <Box sx={Styles.perDetailsContainer}>
            <UITextInputWithTitle
              name="first_name"
              title={getTitles('T-10041')}
              placeHolder={getTitles('T-10047')}
              error={touched.first_name && errors.first_name}
              value={values.first_name}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
              onBlur={handleBlur}
              maxLength={15}
              allowOnlyAlphabets={true}
              required={true}
              containerStyles={Styles.halfWidthField}
            />
            <UITextInputWithTitle
              name="middle_name"
              title={getTitles('T-10337')}
              placeHolder={getTitles('T-10338')}
              value={values.middle_name}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
              onBlur={handleBlur}
              maxLength={15}
              allowOnlyAlphabets={true}
              containerStyles={Styles.halfWidthField}
            />
          </Box>
          <UITextInputWithTitle
            name="last_name"
            title={getTitles('T-10042')}
            placeHolder={getTitles('T-10048')}
            error={touched.last_name && errors.last_name}
            value={values.last_name}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            onBlur={handleBlur}
            maxLength={15}
            allowOnlyAlphabets={true}
            required
            containerStyles={Styles.halfWidthField}
          />
          <UIPhoneInputTitle
            name="phone_number"
            required
            variant="D22"
            title={getTitles('T-10486')}
            onChange={(val) => {
              setFieldValue('phone_number', val);
              setBtnDisable(false);
            }}
            containerStyles={Styles.phoneField}
            phoneValue={values.phone_number}
            disabled={actionType === view || actionType === viewOnly}
          />
          <UIPhoneInputTitle
            name="secondary_phone_number"
            variant="D22"
            onChange={(val) => {
              setFieldValue('secondary_phone_number', val);
              setBtnDisable(false);
            }}
            title={getTitles('T-10487')}
            containerStyles={Styles.phoneField}
            phoneValue={values.secondary_phone_number}
            disabled={actionType === view || actionType === viewOnly}
          />
          <Box sx={Styles.flexWithGap}>
            <UISelect
              name="gender"
              value={values.gender?.id || values.gender || ''}
              title={getTitles('T-10351')}
              onChange={handleChange}
              onlyView={actionType === view}
              valueToShow={values.gender?.name}
              placeholder={getTitles('T-10350')}
              options={defaultValues.genderOptions}
              containerStyles={Styles.halfWidthField}
            />
            <DatePickers
              title={getTitles('T-10344')}
              dateValue={values.date_of_birth}
              onDateChange={(val) =>
                handleChange({ target: { value: val, name: 'date_of_birth' } })
              }
              containerStyles={Styles.halfWidthField}
              disabled={actionType === view || actionType === viewOnly}
              maxDate={dayjs()}
            />
          </Box>
          <Box sx={Styles.flexWithGap}>
            <UISelect
              name="ration_card_color_id"
              value={values.ration_card_color_id?._id || values.ration_card_color_id}
              title={getTitles('T-10491')}
              optionLabel="name"
              returnValue="_id"
              options={cardColorOption}
              onChange={handleChange}
              placeholder={getTitles('T-10350')}
              containerStyles={Styles.halfWidthField}
              valueToShow={values.ration_card_color_id?.name}
              onlyView={actionType === view}
            />
            <UISelect
              name="bpl"
              value={values.bpl?.id || values.bpl || ''}
              title={getTitles('T-10492')}
              optionLabel="name"
              returnValue="id"
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
              options={defaultValues.bpl}
              placeholder={getTitles('T-10350')}
              containerStyles={Styles.halfWidthField}
              valueToShow={defaultValues.bpl?.find((el) => el.id === values.bpl?.id)?.name}
              onlyView={actionType === view}
            />
          </Box>
          {actionType !== view && (
            <UISelect
              name="occupation_id"
              value={''}
              title={getTitles('T-10451')}
              optionLabel="name"
              returnValue="_id"
              options={filteredOptions}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
              placeholder={getTitles('T-10350')}
              containerStyles={Styles.phoneField}
            />
          )}
          {actionType === view && values.occupation_id?.length > 0 && (
            <Text variant="D20" style={Styles.chipTitle}>
              {getTitles('T-10451')}
            </Text>
          )}
          {values.occupation_id &&
            values.occupation_id.map((item, index) => (
              <UIChipButton
                showDelete={actionType !== view}
                title={item?.name || getNamesByIds([item?._id], occupationOption)}
                key={index}
                onDelete={() => handleDelete(item)}
              />
            ))}
        </Box>
        <Box sx={Styles.profileContainer}>
          <UploadPicture
            hideOption={actionType === view || actionType === viewOnly}
            profilePicture={
              farmerData &&
              farmerData.identification_details &&
              farmerData.identification_details.id_photo
            }
            changedImage={(file) => {
              setProfile(file);
              setBtnDisable(false);
            }}
            noDelete={true}
          />
        </Box>
      </Box>
      <Box sx={Styles.columnFor3}>
        <UISelect
          name="religion_id._id"
          required
          value={values.religion_id?._id}
          title={getTitles('T-10488')}
          onChange={handleChange}
          optionLabel="name"
          returnValue="_id"
          disabled={actionType === view || actionType === viewOnly}
          options={religionOptions}
          onBlur={handleBlur}
          error={touched.religion_id?._id && errors.religion_id?._id}
          placeholder={getTitles('T-10350')}
          onlyView={actionType === view}
          valueToShow={values.religion_id?.name}
        />
        <UISelect
          required
          name="caste_id._id"
          value={values.caste_id?._id}
          title={getTitles('T-10489')}
          optionLabel="name"
          returnValue="_id"
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          options={casteOptions}
          onBlur={handleBlur}
          error={touched.caste_id?._id && errors.caste_id?._id}
          placeholder={getTitles('T-10350')}
          onlyView={actionType === view}
          valueToShow={values.caste_id?.name}
        />
        {/* <UISelect
          name="sub_caste_id._id"
          required
          value={values.sub_caste_id?._id}
          title={getTitles('T-10490')}
          optionLabel="name"
          returnValue="_id"
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          options={subCasteOptions}
          onBlur={handleBlur}
          error={touched.sub_caste_id?._id && errors.sub_caste_id?._id}
          placeholder={getTitles('T-10350')}
          onlyView={actionType === view}
          valueToShow={values.sub_caste_id?.name}
        /> */}
      </Box>
      {isRes && (
        <UISelect
          name="society_id"
          value={values.society_id?._id || values.society_id}
          title={getTitles('T-10493')}
          options={society}
          optionLabel="name"
          returnValue="_id"
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          placeholder={getTitles('T-10350')}
          onlyView={actionType === view}
          valueToShow={values.society_id?.name}
        />
      )}

      <Box sx={Styles.columnFor3}>
        {!isRes && (
          <UISelect
            name="society_id"
            value={values.society_id?._id || values.society_id}
            title={getTitles('T-10493')}
            options={society}
            optionLabel="name"
            returnValue="_id"
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            placeholder={getTitles('T-10350')}
            onlyView={actionType === view}
            valueToShow={values.society_id?.name}
          />
        )}
        <UITextInputWithTitle
          name="society_code"
          value={values.society_id?.society_code || societyCode}
          title={getTitles('T-10494')}
          onChange={handleChange}
          disabled={true}
          placeholder={getTitles('T-11324')}
        />
        <UITextInputWithTitle
          name="member_society_code"
          value={values.member_society_code}
          title={getTitles('T-10495')}
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          placeholder={getTitles('T-10642')}
        />
      </Box>

      <UIRadioButtonWithTitle
        title={getTitles('T-10503')}
        required
        onCheck={(val) => {
          setFieldValue('communication_mode.id', val);
          setBtnDisable(false);
        }}
        values={defaultValues.communicationMode}
        checkedValue={values.communication_mode?.id || values.communication_mode}
        disabled={actionType === view || actionType === viewOnly}
      />

      <Box sx={Styles.qtrContainer}>
        <Address
          actionType={actionType}
          objects={values.member_location}
          title={getTitles('T-10504')}
          type={'location'}
          permissionId={permissionId}
          showAssignedLocation={showAssignedLocation}
          showPostCode={false}
          isCountryRequired={true}
          isStateRequired={true}
          isDistrictRequired={true}
          isTalukaRequired={true}
          onCountrySelected={(e) =>
            handleChange({ target: { name: 'member_location.country_id', value: e.target.value } })
          }
          onCountryBlurred={handleBlur('member_location.country_id')}
          countryCodeError={
            touched.member_location?.country_id && errors.member_location?.country_id
          }
          onStateSelected={(val) =>
            handleChange({ target: { name: 'member_location.state_id', value: val } })
          }
          onStateBlurred={handleBlur('member_location.state_id._id')}
          stateCodeError={
            touched.member_location?.state_id?._id && errors.member_location?.state_id?._id
          }
          onDistrictSelected={(val) =>
            handleChange({ target: { name: 'member_location.district_id', value: val } })
          }
          onDistrictBlurred={handleBlur('member_location.district_id._id')}
          districtCodeError={
            touched.member_location?.district_id?._id && errors.member_location?.district_id?._id
          }
          onTalukaSelected={(val) =>
            handleChange({ target: { name: 'member_location.taluka_id', value: val } })
          }
          onTalukaBlurred={handleBlur('member_location.taluka_id._id')}
          talukaCodeError={
            touched.member_location?.taluka_id?._id && errors.member_location?.taluka_id?._id
          }
          onVillageSelected={(val) =>
            handleChange({ target: { name: 'member_location.village_id', value: val } })
          }
        />

        <Box sx={Styles.locationContainer}>
          <Text variant="BoldH22"> {getTitles('T-10505')}</Text>
          {actionType !== view && (
            <UIButton
              startIcon={<img src={locationIcon} alt="icon" />}
              title={!isRes && getTitles('T-10732')}
              style={Styles.btn}
              onClick={handleGetLocation}
            />
          )}
        </Box>
        <Box sx={Styles.commonColumn()}>
          <UITextInputWithTitle
            title={getTitles('T-10496')}
            placeHolder={getTitles('T-11207')}
            name="longitude"
            value={values.longitude}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            onBlur={handleBlur}
            error={touched.longitude && errors.longitude}
            maxLength={6}
            allowDecimal={true}
            required={true}
          />
          <UITextInputWithTitle
            name="latitude"
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            value={values.latitude}
            error={touched.latitude && errors.latitude}
            title={getTitles('T-10497')}
            placeHolder={getTitles('T-11208')}
            maxLength={6}
            allowDecimal={true}
            required={true}
          />
          <UITextInputWithTitle
            name="altitude"
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            onBlur={handleBlur}
            value={values.altitude}
            error={touched.altitude && errors.altitude}
            allowDecimal={true}
            title={getTitles('T-10498')}
            placeHolder={getTitles('T-11206')}
            maxLength={6}
            required={true}
          />
        </Box>

        <Address
          title={getTitles('T-10506')}
          objects={values.address}
          actionType={actionType}
          onAddressOneChange={(e) => {
            handleChange({ target: { name: 'address.address_line_one', value: e.target.value } });
          }}
          onAddressTwoChange={(e) => {
            handleChange({ target: { name: 'address.address_line_two', value: e.target.value } });
          }}
          onPostCodeChange={(e) => {
            handleChange({ target: { name: 'address.post_code', value: e.target.value } });
          }}
          onPostCodeBlurred={handleBlur('address.post_code')}
          postCodeError={touched.address?.post_code && errors.address?.post_code}
          onCountrySelected={(e) =>
            handleChange({ target: { name: 'address.country_id', value: e.target.value } })
          }
          onStateSelected={(val) =>
            handleChange({ target: { name: 'address.state_id', value: val } })
          }
          onDistrictSelected={(val) =>
            handleChange({ target: { name: 'address.district_id', value: val } })
          }
          onTalukaSelected={(val) =>
            handleChange({ target: { name: 'address.taluka_id', value: val } })
          }
          onVillageSelected={(val) =>
            handleChange({ target: { name: 'address.village_id', value: val } })
          }
        />
        <Text variant="BoldH28" style={Styles.text}>
          {getTitles('T-10507')}
        </Text>
        <Divider sx={Styles.divider} />
        <UIRadioButtonWithTitle
          title={getTitles('T-10508')}
          values={defaultValues.personalInsurane}
          onCheck={(val) => {
            setFieldValue('has_personal_insurance', val);
            setBtnDisable(false);
          }}
          checkedValue={values.has_personal_insurance}
          disabled={actionType === view || actionType === viewOnly}
        />
        {values.has_personal_insurance && isRes && (
          <UITextInputWithTitle
            name="insurance_provider"
            title={getTitles('T-10499')}
            placeHolder={getTitles('T-10384')}
            value={values.insurance_provider}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            allowOnlyAlphabets={true}
          />
        )}
        {values.has_personal_insurance && (
          <Box sx={Styles.commonColumn()}>
            {!isRes && (
              <UITextInputWithTitle
                name="insurance_provider"
                title={getTitles('T-10499')}
                placeHolder={getTitles('T-10384')}
                value={values.insurance_provider}
                onChange={handleChange}
                allowOnlyAlphabets={true}
                disabled={actionType === view || actionType === viewOnly}
              />
            )}
            <UITextInputWithTitle
              name="insurance_number"
              value={values.insurance_number}
              title={getTitles('T-10641')}
              placeHolder={getTitles('T-10360')}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
              allowOnlyNumbers={true}
            />
            <UITextInputWithTitle
              name="insured_amount"
              title={getTitles('T-10501')}
              placeHolder={getTitles('T-11201')}
              value={values.insured_amount || ''}
              onChange={handleChange}
              disabled={actionType === view || actionType === viewOnly}
              allowDecimal={true}
            />
          </Box>
        )}
        <Text variant="BoldH28" style={Styles.text}>
          {getTitles('T-10509')}
        </Text>
        <Divider sx={Styles.divider} />
        <Box sx={Styles.xsOneColumn}>
          <UITextInputWithTitle
            name="bank_details.bank_name"
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            value={values.bank_details?.bank_name}
            title={getTitles('T-10404')}
            placeHolder={getTitles('T-10384')}
            allowOnlyAlphabets
          />
          <UITextInputWithTitle
            name="bank_details.branch_name"
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={
              actionType === view || actionType === viewOnly || !values.bank_details?.bank_name
            }
            value={values.bank_details?.branch_name}
            title={getTitles('T-10500')}
            placeHolder={getTitles('T-10384')}
            allowOnlyAlphabets
            required={values.bank_details?.bank_name}
            error={touched.bank_details?.branch_name && errors.bank_details?.branch_name}
          />
        </Box>
        <Box sx={Styles.xs65Two}>
          <UITextInputWithTitle
            name="bank_details.account_number"
            value={values.bank_details?.account_number || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.bank_details?.account_number && errors.bank_details?.account_number}
            disabled={
              actionType === view || actionType === viewOnly || !values.bank_details?.bank_name
            }
            title={getTitles('T-10502')}
            placeHolder={getTitles('T-10413')}
            allowOnlyNumbers={true}
            required={values.bank_details?.bank_name}
          />
          <UITextInputWithTitle
            name="bank_details.ifsc_code"
            value={values.bank_details?.ifsc_code || ''}
            bankIfscCode={true}
            maxLength={11}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.bank_details?.ifsc_code && errors.bank_details?.ifsc_code}
            disabled={
              actionType === view || actionType === viewOnly || !values.bank_details?.bank_name
            }
            title={getTitles('T-10405')}
            placeHolder={getTitles('T-11124')}
            required={values.bank_details?.bank_name}
          />
        </Box>
      </Box>
      <Box sx={Styles.tableContainer}>
        {(actionType !== view ||
          (values.family_member_details && values.family_member_details.length > 0)) && (
          <>
            <Text variant="BoldH28" style={Styles.text}>
              {getTitles('T-10696')}
            </Text>
            <Divider sx={Styles.tableDiveder} />
            {!isRes ? (
              <UIDataTable
                tableRows={tableData}
                rowValueContainerStyle={Styles.rowValueContainerStyle}
                valuesSubStyle={Styles.valuesSubStyle}
                headerContStyle={Styles.headerContStyle}
              />
            ) : (
              actionType !== view && (
                <Box sx={Styles.responsiveTable}>
                  {tableData[0].map((item, idx) => (
                    <Box key={idx} sx={Styles.tData}>
                      <Text style={Styles.halfWidthField} variant="D22">
                        {item.title}
                      </Text>
                      <Box style={Styles.halfWidthField}>{item.values}</Box>
                    </Box>
                  ))}
                </Box>
              )
            )}
          </>
        )}
        {actionType !== view && (
          <UISaveAndAdd
            title={
              values.family_member_details.length < 1 ? getTitles('T-10394') : getTitles('T-10062')
            }
            onAddAnother={handleAddFamilyMember}
            disabled={currFamilyDetails.name === '' || currFamilyDetails.relation === ''}
          />
        )}
      </Box>

      {!defaultValues.isResponsive &&
        values.family_member_details &&
        values.family_member_details.map((member, index) => (
          <Box key={index} sx={Styles.familyMemberCon}>
            <Box sx={Styles.memDetails}>
              <Text sx={Styles.textWithPadding}>{member.name}</Text>
              <Text sx={Styles.textWithPadding}>
                {member?.relation?.name ||
                  getTitleWithId(defaultValues.relationship, member.relation)}
              </Text>
              <Text sx={Styles.widPX}>{member.age}</Text>
              <Text sx={Styles.textWithPadding}>
                {member?.gender?.name || getTitleWithId(defaultValues.genderOptions, member.gender)}
              </Text>
              <Text sx={Styles.textWithPadding}>{member.occupation}</Text>
              <Text sx={Styles.textWithPadding}>{member.education}</Text>
            </Box>
            {actionType !== view && (
              <Box sx={Styles.iconsCont}>
                <IconButton sx={Styles.IconStyle}>
                  <EditIcon onClick={() => handleEditFamilyMember(index)} />
                </IconButton>
                <IconButton sx={Styles.IconStyle}>
                  <DeleteIcon onClick={() => handleDeleteFamilyMember(index)} />
                </IconButton>
              </Box>
            )}
          </Box>
        ))}

      {defaultValues.isResponsive &&
        values.family_member_details.map((member, index) => {
          const details = [
            { label: getTitles('T-10081'), value: member.name },
            {
              label: getTitles('T-10449'),
              value:
                member?.relation?.name ||
                getTitleWithId(defaultValues.relationship, member.relation)
            },
            { label: getTitles('T-10450'), value: member.age },
            {
              label: getTitles('T-10351'),
              value:
                member?.gender?.name || getTitleWithId(defaultValues.genderOptions, member.gender)
            },
            { label: getTitles('T-10451'), value: member.occupation },
            { label: getTitles('T-10452'), value: member.education }
          ];

          return (
            <ResponsiveViewDetails
              hideIcons={actionType === view}
              key={index}
              title={member.name}
              details={details}
              handleEdit={() => handleEditFamilyMember(index)}
              handleDelete={() => handleDeleteFamilyMember(index)}
            />
          );
        })}

      <ModalButtons
        btnPositions={isRes ? 'center' : 'start'}
        disabled={actionType === view || actionType === viewOnly ? false : !isValid || btnDisable}
        secondaryBtnTitle={getTitles('T-10340')}
        onSecondaryBtnClick={() => navigation(SCREEN_PATH.MANAGE_FARMER)}
        onPrimaryBtnClick={
          actionType === view || actionType === viewOnly ? () => setNext(1) : handleSubmit
        }
        primaryBtnTitle={
          actionType === edit
            ? getTitles('T-10733')
            : actionType === view || actionType === viewOnly
              ? getTitles('T-10804')
              : getTitles('T-10339')
        }
      />
    </Box>
  );
};
