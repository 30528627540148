import { Box } from '@mui/material';
import { color } from '../../../theme';
import {
  GroupByOptions,
  LoadingScreen,
  SelectDropdown,
  Text,
  UIButton,
  UIDataTable,
  UIFilter,
  UIRefresh
} from '../../../ui-kits';
import { formatKey, getMasterListItems, getTitles, getUniqueAndSortedData } from '../../../utils';
import { CircleChart } from '../../../ui-kits/charts';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getDiseaseRequest,
  getGovtOfficialDashboardRequest,
  getMasterListWiseRequest,
  getReligionRequest,
  getSocietyRequest,
  getVaccineRequest
} from '../../../services/redux/slices';
import {
  getDiseasesList,
  getMasterLists,
  getNxtPage,
  getVaccineCurPage,
  getVannices
} from '../../../services/redux/selectors/manageProccess';
import { defaultValues } from '../../../constants';
import {
  getGovtCattleList,
  getGovtCattleNextPage,
  getGovtFarmerList,
  getGovtFarmerNextPage,
  getGovtOfficialDashboard,
  getGovtOfficialLoading,
  getGovtSocietyList,
  getGovtSocietyNextPage,
  getSocietyNextpage,
  getSocietys
} from '../../../services/redux/selectors';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { Styles } from './style';

export const GovtDashboard = () => {
  const isRes = defaultValues.isResponsive;
  const dispatch = useDispatch();

  const diseaseNextPage = useSelector(getNxtPage);
  const vaccineNextPage = useSelector(getVaccineCurPage);
  const diseaseList = useSelector(getDiseasesList);
  const vaccineList = useSelector(getVannices);
  const listItems = useSelector(getMasterLists);
  const societyNextPage = useSelector(getSocietyNextpage);
  const societyList = useSelector(getSocietys);
  const dataForDashboard = useSelector(getGovtOfficialDashboard);
  const isLoading = useSelector(getGovtOfficialLoading);
  const gvtCattleNextPage = useSelector(getGovtCattleNextPage);
  const gvtFarmerNextPage = useSelector(getGovtFarmerNextPage);
  const gvtSocietyNextPage = useSelector(getGovtSocietyNextPage);
  const cattleData = useSelector(getGovtCattleList);
  const farmerData = useSelector(getGovtFarmerList);
  const societyData = useSelector(getGovtSocietyList);

  const [vaccines, setVaccines] = useState([]);
  const [diseases, setDisease] = useState([]);
  const [cattleType, setCattleType] = useState([]);
  const [cattleBreed, setCattleBreed] = useState([]);
  const [policyProvider, setPolicyProvider] = useState([]);
  const [societies, setSocieties] = useState([]);
  const [cardColorOption, setCardColorOption] = useState([]);
  const [cattleGroupBy, setCattleGroupBy] = useState([]);
  const [farmerGroupBy, setFarmerGroupBy] = useState([]);
  const [societyGroupBy, setSocietyGroupBy] = useState([]);
  const [cattleFilteredOptions, setCattleFilteredOptions] = useState([]);
  const [farmerFilteredOptions, setFarmerFilteredOptions] = useState([]);
  const [societyFilteredOptions, setSocietyFilteredOptions] = useState([]);
  const [noOfCattles, setNoOfCattles] = useState(null);
  const [noOfFarmers, setNoOfFarmers] = useState(null);
  const [noOfSocieties, setNoOfSocieties] = useState(null);
  const [selectedReport, setSelectedReport] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [selectedOp, setSelectedOp] = useState(null);
  const [resetGroupBy, setResetGroupBy] = useState(false);
  const [cattleList, setCattleList] = useState([]);
  const [farmerList, setFarmerList] = useState([]);
  const [societyDataList, setSocietyDataList] = useState([]);

  const options = [
    { type: getTitles('T-11397'), id: 1 },
    { type: getTitles('T-11398'), id: 2 },
    { type: getTitles('T-11399'), id: 3 }
  ];

  useEffect(() => {
    dispatch(getGovtOfficialDashboardRequest());
    dispatch(getVaccineRequest());
    dispatch(getDiseaseRequest({ filter: JSON.stringify([{ is_active: true }]) }));
    dispatch(getSocietyRequest({ page: 1, filter: JSON.stringify([{ is_active: true }]) }));
    dispatch(getReligionRequest());
    dispatch(
      getMasterListWiseRequest({
        master_id: JSON.stringify([
          defaultValues.mListIds.cattle,
          defaultValues.mListIds.cattleBreeds,
          defaultValues.mListIds.policyProvider,
          defaultValues.mListIds.rationCardColor
        ]),
        filter: JSON.stringify([{ is_active: true }])
      })
    );
  }, []);

  useEffect(() => {
    const tableData = selectedReport?.map((item) => {
      const keys = Object.keys(item);

      return keys.map((key) => {
        let displayValue;
        if (key === 'state_of_cow') {
          displayValue =
            item[key] === 1
              ? getTitles('T-11058')
              : item[key] === 2
                ? getTitles('T-11059')
                : item[key] === 3
                  ? getTitles('T-11060')
                  : 'NIL';
        } else {
          displayValue =
            item[key] === true
              ? getTitles('T-10705')
              : item[key] === false
                ? getTitles('T-10706')
                : item[key] === null
                  ? 'NIL'
                  : item[key];
        }

        return {
          title: formatKey(key),
          columnWidth: '200px',
          values: (
            <Text variant="D20" style={Styles.valueStyle}>
              {Array.isArray(displayValue)
                ? displayValue
                    ?.map(
                      (item) =>
                        defaultValues.cattleCapacity?.find((el) => el.value === item.id)?.title
                    )
                    ?.join(', ')
                : displayValue}
            </Text>
          )
        };
      });
    });
    setDataTable(tableData);
  }, [selectedReport]);

  useEffect(() => {
    if (dataForDashboard) {
      setNoOfCattles(dataForDashboard?.number_of_cattles);
      setNoOfFarmers(dataForDashboard?.number_of_farmers);
      setNoOfSocieties(dataForDashboard?.number_of_society);
      setCattleList(cattleData);
      setFarmerList(farmerData);
      setSocietyDataList(societyData);
    }
  }, [dataForDashboard]);

  useEffect(() => {
    if (diseaseNextPage !== null) dispatch(getDiseaseRequest({ page: diseaseNextPage }));
  }, [diseaseNextPage]);

  useEffect(() => {
    if (vaccineNextPage !== null) dispatch(getVaccineRequest({ page: vaccineNextPage }));
  }, [vaccineNextPage]);

  useEffect(() => {
    if (societyNextPage !== null)
      dispatch(
        getSocietyRequest({ page: societyNextPage, filter: JSON.stringify([{ is_active: true }]) })
      );
  }, [societyNextPage]);

  useEffect(() => {
    let temp = [...vaccines, ...vaccineList];

    const uniqueStates = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });
    setVaccines(uniqueStates);
  }, [vaccineList]);

  useEffect(() => {
    let temp = [...diseases, ...diseaseList];

    const uniqueStates = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });
    setDisease(uniqueStates);
  }, [diseaseList]);

  useEffect(() => {
    let temp = [...societyList, ...societies];
    setSocieties(getUniqueAndSortedData(temp));
  }, [societyList]);

  useEffect(() => {
    if (listItems) {
      setCattleType(getMasterListItems(listItems, defaultValues.mListIds.cattle));
      setCattleBreed(getMasterListItems(listItems, defaultValues.mListIds.cattleBreeds));
      setPolicyProvider(getMasterListItems(listItems, defaultValues.mListIds.policyProvider));
      setCardColorOption(getMasterListItems(listItems, defaultValues.mListIds.rationCardColor));
    }
  }, [listItems]);

  const handleSubmit = () => {
    setSelectedOp(null);
    setSelectedReport([]);
    dispatch(
      getGovtOfficialDashboardRequest({
        filter_by_cattle: JSON.stringify(cattleFilteredOptions),
        filter_by_farmer: JSON.stringify(farmerFilteredOptions),
        filter_by_society: JSON.stringify(societyFilteredOptions),
        group_by_farmer: JSON.stringify(farmerGroupBy),
        group_by_cattle: JSON.stringify(cattleGroupBy),
        group_by_society: JSON.stringify(societyGroupBy)
      })
    );
  };

  useEffect(() => {
    if (gvtCattleNextPage || gvtFarmerNextPage || gvtSocietyNextPage) {
      dispatch(
        getGovtOfficialDashboardRequest({
          cattle_page: gvtCattleNextPage,
          farmer_page: gvtFarmerNextPage,
          society_page: gvtSocietyNextPage,
          filter_by_cattle: JSON.stringify(cattleFilteredOptions),
          filter_by_farmer: JSON.stringify(farmerFilteredOptions),
          filter_by_society: JSON.stringify(societyFilteredOptions),
          group_by_farmer: JSON.stringify(farmerGroupBy),
          group_by_cattle: JSON.stringify(cattleGroupBy),
          group_by_society: JSON.stringify(societyGroupBy)
        })
      );
    }
  }, [gvtCattleNextPage, gvtFarmerNextPage, gvtSocietyNextPage]);

  const handleSelectedOption = (option) => {
    setSelectedOp(option);
    switch (option) {
      case 1:
        setSelectedReport(cattleList);
        break;
      case 2:
        setSelectedReport(farmerList);
        break;
      case 3:
        setSelectedReport(societyDataList);
        break;
      default:
        setSelectedReport(cattleList);
    }
  };
  const handleCattleFilter = (val) => {
    setCattleFilteredOptions(val);
  };
  const handleSocietyFilter = (val) => {
    setSocietyFilteredOptions(val);
  };
  const handleFarmerFilter = (val) => {
    setFarmerFilteredOptions(val);
  };

  const handleRefresh = () => {
    setCattleFilteredOptions([]);
    setFarmerFilteredOptions([]);
    setSocietyFilteredOptions([]);
    setSelectedReport([]);
    setSelectedOp(null);
    setResetGroupBy(true);
    setCattleList([]);
    setFarmerList([]);
    setSocietyDataList([]);
    setCattleGroupBy([]);
    setFarmerGroupBy([]);
    setSocietyGroupBy([]);
    dispatch(getGovtOfficialDashboardRequest());
    setTimeout(() => setResetGroupBy(false), 0);
  };

  return (
    <Box sx={Styles.container}>
      <LoadingScreen isLoading={isLoading} />
      <Box sx={Styles.dashboardContainer}>
        <Box sx={Styles.optionCont}>
          <UIRefresh handleRefresh={handleRefresh} />
          <UIFilter
            title={isRes ? 'CT' : getTitles('T-10173')}
            applyFilter={handleCattleFilter}
            selectedOption={cattleFilteredOptions}
            isMultiSelect={true}
            options={[
              {
                filterType: getTitles('T-10192'),
                value: 'cattle_type_id',
                option: cattleType.map((item) => ({
                  name: item.name,
                  value: item._id
                }))
              },
              {
                filterType: getTitles('T-11006'),
                value: 'cattle_breed_id',
                option: cattleBreed.map((item) => ({
                  name: item.name,
                  value: item._id
                }))
              },
              {
                filterType: getTitles('T-11177'),
                value: 'vaccination_id',
                option: vaccines.map((item) => ({
                  name: item.name,
                  value: item._id
                }))
              },
              {
                filterType: getTitles('T-10101'),
                value: 'disease_id',
                option: diseases.map((item) => ({
                  name: item.name,
                  value: item._id
                }))
              },
              {
                filterType: getTitles('T-11179'),
                value: 'has_allergies',
                option: [
                  { name: getTitles('T-10705'), value: true },
                  { name: getTitles('T-10706'), value: false }
                ]
              },
              {
                filterType: getTitles('T-11178'),
                value: 'dewormed',
                option: [
                  { name: getTitles('T-10705'), value: true },
                  { name: getTitles('T-10706'), value: false }
                ]
              },
              {
                filterType: getTitles('T-10499'),
                value: 'insurance_provider_id',
                option: policyProvider.map((item) => ({
                  name: item.name,
                  value: item._id
                }))
              },
              {
                filterType: getTitles('T-11029'),
                value: 'state_of_cow',
                option: [
                  { value: 1, name: getTitles('T-11058') },
                  { value: 2, name: getTitles('T-11059') },
                  { value: 3, name: getTitles('T-11060') }
                ]
              }
            ]}
          />
          <UIFilter
            applyFilter={handleFarmerFilter}
            selectedOption={farmerFilteredOptions}
            isMultiSelect={true}
            options={[
              {
                filterType: getTitles('T-10491'),
                value: 'ration_card_color',
                option: cardColorOption.map((item) => ({
                  name: item.name,
                  value: item._id
                }))
              },
              {
                filterType: getTitles('T-10492'),
                value: 'bpl',
                option: [
                  {
                    value: 1,
                    name: getTitles('T-10591')
                  },
                  {
                    value: 2,
                    name: getTitles('T-10592')
                  }
                ]
              },
              {
                filterType: getTitles('T-10536'),
                value: 'cattle_capacity',
                option: [
                  { value: 1, name: getTitles('T-10624') },
                  { value: 2, name: getTitles('T-10625') },
                  { value: 3, name: getTitles('T-10626') },
                  { value: 4, name: getTitles('T-10627') },
                  { value: 5, name: getTitles('T-10628') },
                  { value: 6, name: getTitles('T-10629') },
                  { value: 7, name: getTitles('T-10630') }
                ]
              },
              {
                filterType: getTitles('T-10951'),
                value: 'society_id',
                option: societies.map((item) => ({
                  name: item.name,
                  value: item._id
                }))
              },
              {
                filterType: getTitles('T-11328'),
                value: 'have_mukta_gota',
                option: [
                  { name: getTitles('T-10705'), value: true },
                  { name: getTitles('T-10706'), value: false }
                ]
              }
            ]}
            title={isRes ? 'FM' : getTitles('T-11162')}
          />
          <UIFilter
            applyFilter={handleSocietyFilter}
            selectedOption={societyFilteredOptions}
            isMultiSelect={true}
            options={[
              {
                filterType: getTitles('T-10714'),
                value: 'society_status',
                option: [
                  { value: 1, name: getTitles('T-10715') },
                  { value: 2, name: getTitles('T-10716') },
                  { value: 3, name: getTitles('T-10717') }
                ]
              },
              {
                filterType: getTitles('T-10718'),
                value: 'society_type',
                option: [
                  { value: 1, name: getTitles('T-10719') },
                  { value: 2, name: getTitles('T-10720') },
                  { value: 3, name: getTitles('T-10721') }
                ]
              },
              {
                filterType: getTitles('T-10746'),
                value: 'pf_scheme',
                option: [
                  { name: getTitles('T-10705'), value: true },
                  { name: getTitles('T-10706'), value: false }
                ]
              }
            ]}
            title={isRes ? 'SO' : getTitles('T-10951')}
          />
        </Box>
        {!isRes && (
          <Text variant="BoldH28" style={Styles.titleStyle}>
            {getTitles('T-11400')}
          </Text>
        )}
        <Box sx={Styles.chartCont}>
          <CircleChart
            value={noOfCattles}
            total={noOfCattles}
            color={color.primary}
            label={getTitles('T-10173')}
          />
          <CircleChart
            value={noOfFarmers}
            total={noOfFarmers}
            color={color.secondary}
            label={getTitles('T-11291')}
          />
          <CircleChart
            value={noOfSocieties}
            total={noOfSocieties}
            color={color.successAction}
            label={getTitles('T-10951')}
          />
        </Box>
        <Box sx={Styles.tableOptionCont}>
          <SelectDropdown
            options={options}
            selectedOption={handleSelectedOption}
            selOP={selectedOp}
          />
          <UIButton
            title={getTitles('T-11433')}
            startIcon={<UpgradeIcon fontSize="large" />}
            style={Styles.btn}
            disabled={true}
          />
        </Box>
        {defaultValues.isResponsive && (
          <Box sx={Styles.resGpCont}>
            <GroupByOptions
              title={getTitles('T-10173')}
              reset={resetGroupBy}
              onSelectionChange={(selectedItems) => setCattleGroupBy(selectedItems)}
              data={[
                { name: getTitles('T-10070'), key: 'state' },
                { name: getTitles('T-10069'), key: 'district' },
                { name: getTitles('T-10071'), key: 'taluka' },
                { name: getTitles('T-10837'), key: 'cattle_breed' },
                { name: getTitles('T-10983'), key: 'cattle_type' },
                { name: getTitles('T-11177'), key: 'vaccination' },
                { name: getTitles('T-10101'), key: 'disease' },
                { name: getTitles('T-11179'), key: 'allergy' },
                { name: getTitles('T-11178'), key: 'dewormed' },
                { name: getTitles('T-11313'), key: 'insurance' },
                { name: getTitles('T-11415'), key: 'state_of_cow' },
                { name: getTitles('T-11182'), key: 'number_of_ai_done' }
              ]}
            />
            <GroupByOptions
              reset={resetGroupBy}
              title={getTitles('T-11291')}
              onSelectionChange={(selectedItems) => setFarmerGroupBy(selectedItems)}
              data={[
                { name: getTitles('T-10070'), key: 'state' },
                { name: getTitles('T-10069'), key: 'district' },
                { name: getTitles('T-10071'), key: 'taluka' },
                { name: getTitles('T-10488'), key: 'religion' },
                { name: getTitles('T-10489'), key: 'caste' },
                { name: getTitles('T-10490'), key: 'sub_caste' },
                { name: getTitles('T-11416'), key: 'bpl' },
                { name: getTitles('T-10491'), key: 'ration_card_color' },
                { name: getTitles('T-10536'), key: 'cattle_capacity' },
                { name: getTitles('T-10951'), key: 'society' },
                { name: getTitles('T-11328'), key: 'mukta_gota' }
              ]}
            />
            <GroupByOptions
              reset={resetGroupBy}
              title={getTitles('T-10951')}
              onSelectionChange={(selectedItems) => setSocietyGroupBy(selectedItems)}
              data={[
                { name: getTitles('T-10070'), key: 'state' },
                { name: getTitles('T-10069'), key: 'district' },
                { name: getTitles('T-10071'), key: 'taluka' },
                { name: getTitles('T-10714'), key: 'society_status' },
                { name: getTitles('T-10718'), key: 'society_type' },
                { name: getTitles('T-11417'), key: 'pf_scheme' },
                { name: getTitles('T-11418'), key: 'registered_date' }
              ]}
            />
            <UIButton title={'Submit'} style={Styles.subBtn} onClick={handleSubmit} />
          </Box>
        )}

        <Box sx={Styles.tableCont}>
          <Box sx={Styles.tableSubCont}>
            {dataTable?.length > 0 ? (
              <UIDataTable
                tableRows={dataTable}
                showHeader={true}
                headerSubStyle={Styles.tableTitle}
              />
            ) : (
              selectedOp && <Text>{getTitles('T-10086')}</Text>
            )}
          </Box>
        </Box>
      </Box>
      {!defaultValues.isResponsive && (
        <Box sx={Styles.groupByCont}>
          <Text variant="BoldH24">Group by</Text>
          <GroupByOptions
            reset={resetGroupBy}
            title={getTitles('T-10173')}
            onSelectionChange={(selectedItems) => setCattleGroupBy(selectedItems)}
            data={[
              { name: getTitles('T-10070'), key: 'state' },
              { name: getTitles('T-10069'), key: 'district' },
              { name: getTitles('T-10071'), key: 'taluka' },
              { name: getTitles('T-10837'), key: 'cattle_breed' },
              { name: getTitles('T-10983'), key: 'cattle_type' },
              { name: getTitles('T-11177'), key: 'vaccination' },
              { name: getTitles('T-10101'), key: 'disease' },
              { name: getTitles('T-11179'), key: 'allergy' },
              { name: getTitles('T-11178'), key: 'dewormed' },
              { name: getTitles('T-11313'), key: 'insurance' },
              { name: getTitles('T-11415'), key: 'state_of_cow' },
              { name: getTitles('T-11182'), key: 'number_of_ai_done' }
            ]}
          />
          <GroupByOptions
            reset={resetGroupBy}
            title={getTitles('T-11291')}
            onSelectionChange={(selectedItems) => setFarmerGroupBy(selectedItems)}
            data={[
              { name: getTitles('T-10070'), key: 'state' },
              { name: getTitles('T-10069'), key: 'district' },
              { name: getTitles('T-10071'), key: 'taluka' },
              { name: getTitles('T-10488'), key: 'religion' },
              { name: getTitles('T-10489'), key: 'caste' },
              { name: getTitles('T-10490'), key: 'sub_caste' },
              { name: getTitles('T-11416'), key: 'bpl' },
              { name: getTitles('T-10491'), key: 'ration_card_color' },
              { name: getTitles('T-10536'), key: 'cattle_capacity' },
              { name: getTitles('T-10951'), key: 'society' },
              { name: getTitles('T-11328'), key: 'mukta_gota' }
            ]}
          />
          <GroupByOptions
            reset={resetGroupBy}
            title={getTitles('T-10951')}
            onSelectionChange={(selectedItems) => setSocietyGroupBy(selectedItems)}
            data={[
              { name: getTitles('T-10070'), key: 'state' },
              { name: getTitles('T-10069'), key: 'district' },
              { name: getTitles('T-10071'), key: 'taluka' },
              { name: getTitles('T-10714'), key: 'society_status' },
              { name: getTitles('T-10718'), key: 'society_type' },
              { name: getTitles('T-11417'), key: 'pf_scheme' },
              { name: getTitles('T-11418'), key: 'registered_date' }
            ]}
          />
          <UIButton title={'Submit'} style={Styles.subBtn} onClick={handleSubmit} />
        </Box>
      )}
    </Box>
  );
};
