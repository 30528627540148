import { Box } from '@mui/material';
import { styles } from './styles';
import { NoData, Screen, Text, UIFilter } from '../../ui-kits';
import { deduplicateAndSet, formatDateTime, getTitles, resetMessages } from '../../utils';
import { NotificationCard } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  authLoading,
  getAuthErrorMessage,
  getAuthSuccessMessage,
  getEcommerce,
  getNotificationList,
  notificationNextPage
} from '../../services/redux/selectors';
import { defaultValues, getErrorMessage, getSuccessMessage, SCREEN_PATH } from '../../constants';
import { useCallback, useEffect, useState } from 'react';
import {
  clearNotificationData,
  getCommonTaskDetailsRequest,
  getNotificationRequest,
  markNotificationReadRequest,
  resetAuthMessages,
  setCommonTaskDetails,
  setNotificationParams,
  updateMainMenu
} from '../../services/redux/slices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const NotificationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notifications = useSelector(getNotificationList);
  const nextPage = useSelector(notificationNextPage);
  const isLoading = useSelector(authLoading);
  const errorMsg = useSelector(getAuthErrorMessage);
  const successMsg = useSelector(getAuthSuccessMessage);
  const isEcommerce = useSelector(getEcommerce);

  const [notificationList, setNotificationList] = useState([]);
  const [filter, setFilter] = useState([]);

  const { OrderStatusUpdate, TaskAssigned, TaskDueDate } = defaultValues.notificationType;

  useEffect(() => {
    dispatch(clearNotificationData());
    handleNotification();
  }, []);

  useEffect(() => {
    setNotificationList(deduplicateAndSet(notificationList, notifications));
  }, [notifications]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (successMsg === 'S-10135') handleNotification();
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetAuthMessages()));
  }, [successMsg, errorMsg]);

  const handleNotification = (data) => {
    let payload = { limit: 50, filter: JSON.stringify(filterByScreen()), ...data };
    dispatch(setNotificationParams(payload));
    dispatch(getNotificationRequest(payload));
  };

  const handleScroll = useCallback(
    (e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target;

      const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1;

      if (isBottom && !isLoading && nextPage) {
        handleNotification({ page: nextPage });
      }
    },
    [isLoading, nextPage, dispatch]
  );

  const handleMarkAsRead = (payload) => {
    dispatch(markNotificationReadRequest(payload));
  };

  const handleFilter = (data) => {
    let finalData = filterByScreen(data);
    setFilter(data);
    setNotificationList([]);
    handleNotification({ filter: data.length > 0 ? JSON.stringify(finalData) : null });
  };

  const filterByScreen = (elem) => {
    let finalData = elem ? [...elem] : [];
    const notifiyType = (type) => {
      return { notification_type: type };
    };

    if (isEcommerce) {
      finalData.push(notifiyType(OrderStatusUpdate));
    } else {
      finalData.push(notifiyType(TaskAssigned));
      finalData.push(notifiyType(TaskDueDate));
    }
    return finalData;
  };

  const handleTitleClick = (item) => {
    if ([TaskAssigned, TaskDueDate].includes(item?.notification_type)) {
      dispatch(updateMainMenu({ id: 2 }));
      dispatch(setCommonTaskDetails(null));
      dispatch(getCommonTaskDetailsRequest({ task_id: item?.payload?._id }));
      handleMarkAsRead({ _id: [item._id] });
      navigate(SCREEN_PATH.TASKS);
    } else if ([OrderStatusUpdate].includes(item?.notification_type)) {
      handleMarkAsRead({ _id: [item._id] });
      navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.MY_ORDERS}`);
    }
    handleNotification();
  };

  return (
    <Screen
      showSideBar={!isEcommerce}
      showHeader={isEcommerce}
      currentPage={!isEcommerce && getTitles('T-11666')}
      isLoading={isLoading}>
      <Box sx={styles.container}>
        <Box sx={styles.filterCont}>
          <Text variant="D20" style={styles.markas} onClick={() => handleMarkAsRead({})}>
            {getTitles('T-11667')}
          </Text>

          <UIFilter
            isMultiSelect={true}
            options={defaultValues.notificationFilterOption}
            applyFilter={handleFilter}
            selectedOption={filter}
          />
        </Box>
        <div style={styles.notificationCont} onScroll={(e) => handleScroll(e)}>
          {notificationList?.length > 0 ? (
            notificationList.map((item, idx) => (
              <NotificationCard
                key={idx}
                title={item.title}
                description={item.description}
                time={item.received_at && formatDateTime(item.received_at)}
                isread={item.is_read}
                onTitleClick={() => handleTitleClick(item)}
              />
            ))
          ) : (
            <NoData message={getErrorMessage('E-10281')} height="100vh" />
          )}
        </div>
      </Box>
    </Screen>
  );
};
