import { useDispatch, useSelector } from 'react-redux';
import { defaultValues } from '../../../constants';
import { color, globalStyles } from '../../../theme';
import {
  getdistrictByState,
  getdistrictByStateNxtPage,
  getStateNextPage,
  getStates,
  gettalukaByDistrict,
  gettalukaByDistrictNxtPage,
  getvillageByTaluka,
  getvillageByTalukaNxtPage,
  processLoading
} from '../../../services/redux/selectors/manageProccess';
import {
  getGovtOfficialCurrRender,
  getGovtOfficialSuccessMsg
} from '../../../services/redux/selectors/govtOfficialSelector';
import { getDetailsPg } from '../../../services/redux/selectors';
import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import {
  addGovtOfficialRequest,
  getDistrictByStateRequest,
  getGovtOfficialDetailsRequest,
  getStateRequest,
  getTalukaByDistrictRequest,
  getVillageByTalukaRequest,
  resetProcess,
  setClearProcessPages,
  setGovtOfficialCurrentRender,
  setGovtOfficialData,
  updateDetailsPg,
  updateGovtOfficialRequest
} from '../../../services/redux/slices';
import { Box } from '@mui/material';
import { UIBackButton } from '../../../ui-kits/back-button';
import {
  EditButton,
  Text,
  UIButton,
  UIPhoneInputTitle,
  UISelect,
  UITextInputWithTitle
} from '../../../ui-kits';
import { getFormattedOptions, getTitles, govtOfficerSchema } from '../../../utils';
import { FormTitleCard, ModalButtons } from '../..';
import { UISelectCheckGroup } from '../../../ui-kits/select-with-checkbox-group';
import { Address } from '../../common/address';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const styles = {
  container: {
    width: '100%',
    padding: res ? '2%' : '2% 6%',
    borderRadius: '5px',
    backgroundColor: color.primaryBackground,
    boxSizing: 'border-box',
    mt: res ? 1 : 2
  },
  subCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  backBtnCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    position: 'relative'
  },
  backBtn: {
    position: 'absolute',
    left: md ? -45 : -55
  },
  editIcon: {
    cursor: 'pointer',
    width: res ? '20px' : md ? '23px' : '26px',
    height: res ? '20px' : md ? '23px' : '26px'
  },
  editBtn: {
    height: '35px',
    marginLeft: 'auto'
  },
  gridCont: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `
      "country state"
      "district taluk"
      "village post"
        `
      : `
        "country state district"
        "taluk village post"
        `,
    gridTemplateColumns: res
      ? 'calc(50% - 5px) calc(50% - 5px)'
      : 'calc((100%/3 ) - 6.3px) calc((100%/3 ) - 6.3px) calc((100%/3 ) - 6.3px)',
    gap: '10px',
    marginTop: '10px'
  },
  nameGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: res
      ? `
      "first middle"
      "last none"
        `
      : `
        "first middle last"
        `,
    gridTemplateColumns: res
      ? 'calc(50% - 5px) calc(50% - 5px)'
      : 'calc((100%/3 ) - 6.3px) calc((100%/3 ) - 6.3px) calc((100%/3 ) - 6.3px)',
    gap: '10px',
    marginTop: '10px'
  },
  width: res ? '100%' : 'calc((100% - 15px)/3)',
  depWidth: res ? '100%' : 'calc((100% - 15px)/3)',
  phone: {
    width: res ? '100%' : 'calc((100% - 5px)/3 * 2)',
    maxWidth: 'none',
    gridArea: 'phone'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: res ? '0px' : '10px'
  }
};

export const AddEditGovtOfficial = ({ govtOfficialData, isReadOnly, currUserId }) => {
  const dispatch = useDispatch();

  const states = useSelector(getStates);
  const currRender = useSelector(getGovtOfficialCurrRender);
  const nextPage = useSelector(getStateNextPage);
  const detailsPg = useSelector(getDetailsPg);
  const successMsg = useSelector(getGovtOfficialSuccessMsg);
  const processIsLoading = useSelector(processLoading);

  const districts = useSelector(getdistrictByState);
  const talukas = useSelector(gettalukaByDistrict);
  const villages = useSelector(getvillageByTaluka);
  const districtNxtPg = useSelector(getdistrictByStateNxtPage);
  const villageNxtPge = useSelector(getvillageByTalukaNxtPage);
  const talukNxtPge = useSelector(gettalukaByDistrictNxtPage);

  const [btnDisabled, setBtndisabled] = useState(true);
  const [isEmp, setIsEmp] = useState(false);
  const [districtsList, setDistrictsLists] = useState([]);
  const [talukasList, setTalukasList] = useState([]);
  const [villagesList, setVillagesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const { add, edit, view } = defaultValues.actionType;
  const typeAssign = {
    type: 2,
    permission_id: defaultValues.employeeRol.govtOfficeManager,
    filter: JSON.stringify([{ is_active: true }])
  };

  const { values, errors, touched, dirty, setValues, handleBlur, setFieldValue, isValid } =
    useFormik({
      initialValues: {
        phone_number: '',
        email_id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        department: '',
        address: {
          address_line_one: '',
          country_id: '',
          state_id: {
            _id: ''
          },
          district_id: {
            _id: ''
          },
          taluka_id: {
            _id: ''
          },
          village_id: {
            _id: ''
          },
          post_code: ''
        },
        assign_location: {
          country_id: '',
          state_id: [],
          district_id: [],
          taluka_id: [],
          village_id: []
        }
      },
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: govtOfficerSchema
    });

  useEffect(() => {
    dispatch(getStateRequest({ page: 1, ...typeAssign }));
    [view, edit].includes(currRender) &&
      dispatch(getGovtOfficialDetailsRequest({ _id: currUserId }));
  }, []);

  useEffect(() => {
    if (['S-10083', 'S-10082'].includes(successMsg)) {
      dispatch(setGovtOfficialCurrentRender(1));
      dispatch(updateDetailsPg(false));
    }
  }, [successMsg]);

  useEffect(() => {
    if (!detailsPg) {
      handleBackButton();
    }
  }, [detailsPg]);

  useEffect(() => {
    deduplicateAndSet(stateList, states, setStateList);

    deduplicateAndSet(districtsList, districts, setDistrictsLists);

    deduplicateAndSet(talukasList, talukas, setTalukasList);

    deduplicateAndSet(villagesList, villages, setVillagesList);
  }, [districts, talukas, villages, states]);

  const previousOptions = useRef({
    stateNxtPage: nextPage,
    districtNxtPage: districtNxtPg,
    villageNxtPage: villageNxtPge,
    talukaNextPage: talukNxtPge
  });

  useEffect(() => {
    const prev = previousOptions.current;

    if (nextPage !== prev?.stateNxtPage && nextPage !== null) {
      dispatch(getStateRequest({ page: nextPage, ...typeAssign }));
    }

    if (districtNxtPg !== prev?.districtNxtPage && districtNxtPg !== null) {
      dispatch(
        getDistrictByStateRequest({
          state_id: JSON.stringify(values.assign_location?.state_id),
          page: districtNxtPg,
          ...typeAssign
        })
      );
    }
    if (talukNxtPge !== prev?.talukaNextPage && talukNxtPge !== null) {
      dispatch(
        getTalukaByDistrictRequest({
          district_id: JSON.stringify(values.assign_location?.district_id),
          page: talukNxtPge,
          ...typeAssign
        })
      );
    }

    if (villageNxtPge !== prev?.villageNxtPage && villageNxtPge !== null) {
      dispatch(
        getVillageByTalukaRequest({
          taluka_id: JSON.stringify(values.assign_location?.taluka_id),
          page: villageNxtPge,
          ...typeAssign
        })
      );
    }

    previousOptions.current = {
      districtNxtPage: districtNxtPg,
      villageNxtPage: villageNxtPge,
      talukaNextPage: talukNxtPge
    };
  }, [districtNxtPg, villageNxtPge, talukNxtPge]);

  useEffect(() => {
    if (govtOfficialData) {
      setValues({
        ...govtOfficialData,
        email_id: govtOfficialData.email_id || '',
        address: {
          ...govtOfficialData.address,
          village_id: govtOfficialData.address.village_id || ''
        },
        assign_location: {
          ...govtOfficialData.assign_location
        }
      });

      dispatch(
        getDistrictByStateRequest({
          state_id: JSON.stringify(govtOfficialData.assign_location?.state_id),
          page: 1,
          ...typeAssign
        })
      );

      dispatch(
        getTalukaByDistrictRequest({
          district_id: JSON.stringify(govtOfficialData.assign_location?.district_id),
          page: 1,
          ...typeAssign
        })
      );
      dispatch(
        getVillageByTalukaRequest({
          taluka_id: JSON.stringify(govtOfficialData.assign_location?.taluka_id),
          page: 1,
          ...typeAssign
        })
      );
    }
  }, [govtOfficialData, states]);

  const previousValues = useRef({
    state: values?.assign_location?.state_id?.length,
    district: values?.assign_location?.district_id?.length,
    village: values?.assign_location?.village_id?.length,
    taluka: values?.assign_location?.taluka_id?.length
  });

  useEffect(() => {
    const prev = previousValues.current;

    if (isEmp) {
      const [updatedDistricts, updatedDistrictOptions] = removeDataOfRemovedItem(
        values.assign_location?.state_id,
        values.assign_location?.district_id,
        'state_id',
        districtsList
      );

      setFieldValue('assign_location.district_id', updatedDistricts);
      setDistrictsLists(updatedDistrictOptions);

      const [updatedTaluka, updatedTalukaOptions] = removeDataOfRemovedItem(
        values.assign_location?.district_id,
        values.assign_location?.taluka_id,
        'district_id',
        talukasList
      );
      setFieldValue('assign_location.taluka_id', updatedTaluka);
      setTalukasList(updatedTalukaOptions);

      const [updatedVillage, updatedVillageOptions] = removeDataOfRemovedItem(
        values.assign_location?.taluka_id,
        values.assign_location?.village_id,
        'taluka_id',
        villagesList
      );
      setFieldValue('assign_location.village_id', updatedVillage);
      setVillagesList(updatedVillageOptions);
      setTimeout(() => {
        setIsEmp(false);
      }, 3000);
    }

    if (
      prev?.state !== values.assign_location?.state_id?.length &&
      values.assign_location?.state_id?.length > 0
    ) {
      dispatch(
        getDistrictByStateRequest({
          state_id: JSON.stringify(values.assign_location?.state_id),
          page: 1,
          ...typeAssign
        })
      );
    }
    if (
      prev?.district !== values.assign_location?.district_id?.length &&
      values.assign_location?.district_id?.length > 0
    ) {
      dispatch(
        getTalukaByDistrictRequest({
          district_id: JSON.stringify(values.assign_location?.district_id),
          page: 1,
          ...typeAssign
        })
      );
    }
    if (
      prev?.taluka !== values.assign_location?.taluka_id?.length &&
      values.assign_location?.taluka_id?.length > 0
    ) {
      dispatch(
        getVillageByTalukaRequest({
          taluka_id: JSON.stringify(values.assign_location?.taluka_id),
          page: 1,
          ...typeAssign
        })
      );
    }

    previousValues.current = {
      state: values?.assign_location?.state_id?.length,
      district: values?.assign_location?.district_id?.length,
      village: values?.assign_location?.village_id?.length,
      taluka: values?.assign_location?.taluka_id?.length
    };
  }, [
    values.assign_location?.state_id?.length,
    values.assign_location?.district_id?.length,
    values.assign_location?.taluka_id?.length,
    values.assign_location?.village_id?.length,
    villagesList,
    talukasList,
    districtsList
  ]);

  const deduplicateAndSet = (list, newItems, setFunction) => {
    let temp = [...list, ...newItems];

    const uniqueItems = temp.filter((item, index, self) => {
      return index === self.findIndex((s) => s._id === item._id);
    });

    setFunction(uniqueItems?.length > 0 ? uniqueItems : []);
  };

  const handleBackButton = () => {
    dispatch(resetProcess());
    dispatch(setClearProcessPages());
    dispatch(setGovtOfficialData(null));
    dispatch(setGovtOfficialCurrentRender(1));
    dispatch(updateDetailsPg(false));
  };

  const removeDataOfRemovedItem = (parentArray, childArray, childField, distList) => {
    const remainingData = distList.filter((item) => parentArray.includes(item[childField]));
    const secondary = remainingData?.map((ele) => ele._id);
    const final = secondary?.filter((ele) => childArray?.includes(ele));

    return [final?.length > 0 ? final : [], remainingData?.length > 0 ? remainingData : []];
  };

  const handleSubmit = () => {
    delete values?.user_id;
    let payload = {
      ...values,
      address: {
        ...values.address,
        state_id: values.address?.state_id?._id,
        district_id: values.address?.district_id?._id,
        taluka_id: values.address?.taluka_id?._id,
        village_id: values.address.village_id?._id || null,
        post_code: parseInt(values.address?.post_code)
      },
      assign_location: {
        ...values.assign_location,
        state_id: values.assign_location?.state_id,
        village_id:
          values.assign_location?.village_id?.length > 0 ? values.assign_location?.village_id : null
      }
    };

    currRender === add && dispatch(addGovtOfficialRequest(payload));
    currRender === edit && dispatch(updateGovtOfficialRequest(payload));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setBtndisabled(false);
    setIsEmp(true);
  };

  return (
    <Box sx={styles.container}>
      {!res && (
        <Box sx={styles.backBtnCont}>
          <UIBackButton onClick={handleBackButton} style={styles.backBtn} />
          <Text variant="boldH32Sec">
            {currRender === add
              ? getTitles('T-11151')
              : currRender === view
                ? getTitles('T-11149')
                : getTitles('T-11150')}
          </Text>
          {currRender === view && !isReadOnly && (
            <UIButton
              title={getTitles('T-10084')}
              startIcon={<EditButton iconStyle={styles.editIcon} />}
              style={styles.editBtn}
              onClick={() => dispatch(setGovtOfficialCurrentRender(edit))}
            />
          )}
        </Box>
      )}

      <FormTitleCard title={getTitles('T-10330')}>
        {currRender !== add && (
          <UITextInputWithTitle
            name="officer_id"
            value={values.officer_id}
            onChange={(val) => {
              setBtndisabled(false);
              handleChange(val);
            }}
            onBlur={handleBlur}
            title={getTitles('T-10751')}
            placeHolder={getTitles('T-10049')}
            width={styles.width}
            disabled={true}
          />
        )}
        <Box sx={styles.nameGrid}>
          <UITextInputWithTitle
            name="first_name"
            required
            value={values.first_name}
            onChange={(val) => {
              setBtndisabled(false);
              handleChange(val);
            }}
            maxLength={10}
            onBlur={handleBlur}
            error={touched.first_name && errors.first_name}
            title={getTitles('T-10041')}
            containerStyles={globalStyles.gridFirstName}
            placeHolder={getTitles('T-10047')}
            allowOnlyAlphabets={true}
            disabled={currRender === view}
          />

          <UITextInputWithTitle
            name="middle_name"
            title={getTitles('T-10337')}
            placeHolder={getTitles('T-10338')}
            error={touched.middle_name && errors.middle_name}
            value={values.middle_name}
            onChange={handleChange}
            onBlur={handleBlur}
            containerStyles={globalStyles.gridMiddlename}
            maxLength={10}
            allowOnlyAlphabets={true}
            disabled={currRender === view}
          />

          <UITextInputWithTitle
            name="last_name"
            required
            value={values.last_name}
            onChange={(val) => {
              setBtndisabled(false);
              handleChange(val);
            }}
            maxLength={10}
            onBlur={handleBlur}
            error={touched.last_name && errors.last_name}
            title={getTitles('T-10042')}
            containerStyles={globalStyles.gridLastname}
            placeHolder={getTitles('T-10048')}
            allowOnlyAlphabets
            disabled={currRender === view}
          />
        </Box>
        <UIPhoneInputTitle
          phoneValue={values.phone_number}
          onChange={(val) => {
            setFieldValue('phone_number', val);
            setBtndisabled(val ? false : true);
          }}
          handleError={(isError) => setBtndisabled(isError)}
          required={true}
          containerStyles={styles.phone}
          disabled={currRender === view}
          title={getTitles('T-10003')}
        />
        <Box sx={styles.nameCont}>
          <UITextInputWithTitle
            name="email_id"
            value={values.email_id}
            onChange={(val) => {
              setBtndisabled(false);
              handleChange(val);
            }}
            onBlur={handleBlur}
            error={touched.email_id && errors.email_id}
            title={getTitles('T-10043')}
            placeHolder={getTitles('T-10049')}
            containerStyles={styles.phone}
            disabled={currRender === view}
          />
          <UITextInputWithTitle
            name="department"
            required
            value={values.department}
            onChange={(val) => {
              setBtndisabled(false);
              handleChange(val);
            }}
            onBlur={handleBlur}
            error={touched.department && errors.department}
            title={getTitles('T-10320')}
            width={styles.depWidth}
            placeHolder={getTitles('T-10321')}
            allowOnlyAlphabets={true}
            disabled={currRender === view}
          />
        </Box>
      </FormTitleCard>

      <FormTitleCard title={getTitles('T-11077')}>
        <Address
          actionType={currRender}
          objects={values.address}
          divider={false}
          type={'address'}
          showPostCode={true}
          isCountryRequired={true}
          isStateRequired={true}
          isDistrictRequired={true}
          isTalukaRequired={true}
          isPostCodeRequired={true}
          addressOneRequired
          onAddressOneChange={(e) =>
            handleChange({ target: { name: 'address.address_line_one', value: e.target.value } })
          }
          onAddressOneBlurred={handleBlur('address.address_line_one')}
          addressOneErrorCode={
            touched.address?.address_line_one && errors.address?.address_line_one
          }
          onAddressTwoChange={(e) =>
            handleChange({ target: { name: 'address.address_line_two', value: e.target.value } })
          }
          onCountrySelected={(e) =>
            handleChange({ target: { name: 'address.country_id', value: e.target.value } })
          }
          onCountryBlurred={handleBlur('address.country_id')}
          countryCodeError={touched.address?.country_id && errors.address?.country_id}
          onStateSelected={(val) => {
            handleChange({ target: { name: 'address.state_id', value: val ? val : { _id: '' } } });
          }}
          onStateBlurred={handleBlur('address.state_id')}
          stateCodeError={touched.address?.state_id && errors.address?.state_id?._id}
          onDistrictSelected={(val) =>
            handleChange({
              target: { name: 'address.district_id', value: val ? val : { _id: '' } }
            })
          }
          onDistrictBlurred={handleBlur('address.district_id')}
          districtCodeError={touched.address?.district_id && errors.address?.district_id?._id}
          onTalukaSelected={(val) =>
            handleChange({ target: { name: 'address.taluka_id', value: val ? val : { _id: '' } } })
          }
          onTalukaBlurred={handleBlur('address.taluka_id')}
          talukaCodeError={touched.address?.taluka_id && errors.address?.taluka_id?._id}
          onVillageSelected={(val) =>
            handleChange({ target: { name: 'address.village_id', value: val ? val : { _id: '' } } })
          }
          onPostCodeBlurred={handleBlur('address.post_code')}
          postCodeError={touched.address?.post_code && errors.address?.post_code}
          onPostCodeChange={(val) => {
            handleChange({ target: { name: 'address.post_code', value: val.target.value } });
          }}
        />
      </FormTitleCard>

      <FormTitleCard title={getTitles('T-11140')}>
        <Box sx={styles.gridCont}>
          <UISelect
            name="assign_location.country_id"
            value={values.assign_location.country_id}
            containerStyles={globalStyles.gridCountry}
            title={getTitles('T-10363')}
            options={defaultValues.countriesList}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={getTitles('T-10350')}
            required={true}
            error={
              touched.assign_location &&
              touched.assign_location.country_id &&
              errors.assign_location &&
              errors.assign_location.country_id
            }
            disabled={currRender === view}
          />
          <UISelectCheckGroup
            name="assign_location.state_id"
            error={
              touched.assign_location &&
              touched.assign_location.state_id &&
              errors.assign_location &&
              errors.assign_location.state_id
            }
            required={true}
            onBlur={handleBlur}
            options={
              stateList?.length > 0
                ? [
                    {
                      title: '',
                      value: stateList.map((item) => ({ title: item.name, value: item._id })) || []
                    }
                  ]
                : []
            }
            title={getTitles('T-10070')}
            showTitle
            onChange={(val) => {
              setFieldValue('assign_location.state_id', val);
              setBtndisabled(false);
              setIsEmp(true);
            }}
            preSelectedValues={values.assign_location.state_id}
            placeholder={getTitles('T-10350')}
            containerStyles={globalStyles.gridState}
            disabled={currRender === view || processIsLoading}
          />
          <UISelectCheckGroup
            name={'assign_location.district_id'}
            error={
              touched.assign_location &&
              touched.assign_location.district_id &&
              errors.assign_location &&
              errors.assign_location.district_id
            }
            required={true}
            onBlur={handleBlur}
            options={getFormattedOptions(districtsList, 'state_id').map((item) => ({
              title: stateList.find((ele) => ele._id === item.id)?.name,
              value:
                item.value &&
                item.value.map((item) => ({
                  title: item.name,
                  value: item._id
                }))
            }))}
            showTitle
            title={getTitles('T-10069')}
            onChange={(val) => {
              setBtndisabled(false);
              setIsEmp(true);
              setFieldValue('assign_location.district_id', val);
            }}
            preSelectedValues={values.assign_location && values.assign_location.district_id}
            placeholder={getTitles('T-10350')}
            disabled={
              currRender === view ||
              (values.assign_location && values.assign_location.state_id.length < 1) ||
              processIsLoading
            }
            containerStyles={globalStyles.gridDistrict}
          />
          <UISelectCheckGroup
            name={'assign_location.taluka_id'}
            error={
              touched.assign_location &&
              touched.assign_location.taluka_id &&
              errors.assign_location &&
              errors.assign_location.taluka_id
            }
            required={true}
            onBlur={handleBlur}
            options={getFormattedOptions(talukasList, 'district_id').map((unique) => ({
              title: districtsList.find((ele) => ele._id === unique.id)?.name,
              value:
                unique.value &&
                unique.value.map((item) => ({
                  title: item.name,
                  value: item._id
                }))
            }))}
            showTitle
            title={getTitles('T-10071')}
            onChange={(val) => {
              setFieldValue('assign_location.taluka_id', val);
              setBtndisabled(false);
              setIsEmp(true);
            }}
            preSelectedValues={values.assign_location.taluka_id}
            placeholder={getTitles('T-10350')}
            disabled={
              (values.assign_location && values.assign_location.district_id.length < 1) ||
              currRender === view ||
              processIsLoading
            }
            containerStyles={globalStyles.gridTaluk}
          />
          <UISelectCheckGroup
            name={'assign_location.village_id'}
            onBlur={handleBlur}
            options={getFormattedOptions(villagesList, 'taluka_id').map((unique) => ({
              title: talukasList.find((ele) => ele._id === unique.id)?.name,
              value:
                unique.value &&
                unique.value.map((item) => ({
                  title: item.name,
                  value: item._id
                }))
            }))}
            showTitle
            title={getTitles('T-10068')}
            onChange={(val) => {
              setFieldValue('assign_location.village_id', val);
              setBtndisabled(false);
              setIsEmp(true);
            }}
            preSelectedValues={values.assign_location && values.assign_location.village_id}
            placeholder={getTitles('T-10350')}
            disabled={
              (values.assign_location && values.assign_location.taluka_id.length < 1) ||
              currRender === view ||
              processIsLoading
            }
            containerStyles={globalStyles.gridVillage}
          />
        </Box>
      </FormTitleCard>

      <ModalButtons
        secondaryBtnTitle={currRender === view ? getTitles('T-11103') : getTitles('T-10045')}
        onSecondaryBtnClick={handleBackButton}
        onPrimaryBtnClick={currRender !== view && handleSubmit}
        primaryBtnTitle={
          currRender === add ? getTitles('T-10394') : currRender === edit && getTitles('T-10733')
        }
        disabled={!isValid || !dirty || btnDisabled}
        containerStyle={globalStyles.mt(3)}
        secondaryVariant={currRender === view ? 'primary' : 'secondary'}
      />
    </Box>
  );
};
