import { Box } from '@mui/material';
import { styles } from './styles';
import { Text } from '../../../../ui-kits';

export const NotificationCard = ({ title, description, time, isread, onTitleClick }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.indicator(isread)}></Box>
      <Box sx={styles.detailsCont}>
        <Text variant="BoldH20" style={styles.title} onClick={onTitleClick}>
          {title}
        </Text>
        <Text variant="D20">{description}</Text>
      </Box>
      <Box sx={styles.timeCont}>
        <Text variant="D20">{time}</Text>
      </Box>
    </Box>
  );
};
