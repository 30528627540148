import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Text } from '../text';
import { UIRadioButton } from '../radio-buttons';
import { color } from '../../theme';
import { defaultValues } from '../../constants';
const isRes = defaultValues.isResponsive;

export const UIRadioButtonWithTitle = ({
  title,
  onCheck,
  values,
  touched,
  error,
  titleStyle,
  errorStyle,
  itemTitleStyle,
  required = false,
  variant = 'D20',
  checkedValue,
  disabled = false,
  ...rest
}) => {
  const [checked, setChecked] = React.useState('');

  useEffect(() => {
    setChecked(checkedValue);
  }, [checkedValue]);

  const styles = {
    mainBox: { margin: '5px 0px' },
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      flexWrap: 'wrap',
      height: isRes ? 'auto' : '50px'
    },
    title: (style) => ({
      marginBottom: '5px',
      ...style
    }),
    asterisk: {
      color: color.errorText
    },
    flexBox: { display: 'flex', alignItems: 'center' },
    checkBoxItemTitle: (style) => ({
      marginRight: isRes ? '25px' : '25px',
      whiteSpace: 'nowrap',
      ...style
    })
  };

  return (
    <Box sx={styles.mainBox} {...rest}>
      <Text variant={variant} style={styles.title(titleStyle)}>
        {title}
        {required && <span style={styles.asterisk}>*</span>}
      </Text>
      <Box sx={styles.checkbox}>
        {values?.map((item) => (
          <Box sx={styles.flexBox} key={item.value}>
            <UIRadioButton
              size={isRes ? '20px' : '25px'}
              checked={checked === item.value}
              disabled={disabled}
              onChange={() => {
                if (checked === item.value) {
                  // If the item is already selected, removing it
                  setChecked('');
                } else {
                  // If the item is not selected, adding it
                  setChecked(item.value);
                }
                onCheck(item.value);
              }}
            />

            <Text variant="D20" style={styles.checkBoxItemTitle(itemTitleStyle)}>
              {item.title}
            </Text>
          </Box>
        ))}
      </Box>
      {error && touched && (
        <Text style={errorStyle} variant="fieldError">
          {error}
        </Text>
      )}
    </Box>
  );
};
