import { defaultValues } from '../../../constants';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const styles = {
  width: res ? '100%' : 'calc((100% - 30px)/3)',
  longWidth: res ? '100%' : 'calc(((100% - 10px)/3)*2)',
  childCont: {
    px: res ? 1 : 4
  },
  popUpContainer: {
    maxWidth: 'none',
    width: res ? '90%' : md ? '80%' : '70%'
  },
  input: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px'
    },
    height: res ? '30px' : '40px'
  },
  autoInput: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px',

      padding: 0,
      paddingLeft: '5px'
    },
    '& .MuiInputBase-input': {
      fontSize: res ? '14px' : '18px'
    },
    '& .MuiPaper-root': {
      paddingRight: '15px',
      padding: 0
    }
  },

  autoCont: {
    marginTop: '10px',
    width: res ? '100%' : 'calc((100% - 10px)/3)'
  },

  adjustmentTitleStyle: {
    mb: '5px'
  },

  nameCont: {
    width: res ? '100%' : '80%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: res ? 'column' : 'row',
    gap: res ? '0px' : '10px'
  },

  btn: (isLast) => ({
    width: '30px',
    height: '30px',
    minWidth: 0,
    fontSize: '1.35rem',
    marginTop: '10px',
    padding: 0,
    visibility: isLast ? 'hidden' : 'none'
  }),
  adjustQtyCont: (values) => ({
    mt: res ? '10px' : values.adjustment?.product_id ? '0px' : '10px'
  }),
  adjustSuperCont: {
    width: '100%'
  }
};
