import { createSelector } from '@reduxjs/toolkit';

export const getEcommerceLoading = createSelector([(state) => state.eCommerce.isLoading], (d) => d);

export const getEcommerceSuccessMsg = createSelector(
  [(state) => state.eCommerce.successMessage],
  (d) => d
);
export const getEcommerceErrorMsg = createSelector(
  [(state) => state.eCommerce.errorMessage],
  (d) => d
);
export const getEcommerceFeaturedCurrRender = createSelector(
  [(state) => state.eCommerce.featuredCurrRender],
  (d) => d
);

export const getEcommerceRecentCurrRender = createSelector(
  [(state) => state.eCommerce.recentCurrRender],
  (d) => d
);

export const getEcommerceProductListRecentExpand = createSelector(
  [(state) => state.eCommerce.productListTabExpand],
  (d) => d
);

export const getEcommerceProductListCurrRender = createSelector(
  [(state) => state.eCommerce.productListTabCurrRender],
  (d) => d
);

export const getEcommerceBanners = createSelector([(state) => state.eCommerce.banners], (d) => d);
export const getEcommerceFeaturedProducts = createSelector(
  [(state) => state.eCommerce.featuredProducts],
  (d) => d
);

export const getEcommerceRecentProducts = createSelector(
  [(state) => state.eCommerce.recentProducts],
  (d) => d
);

export const getAdvertisementProducts = createSelector(
  [(state) => state.eCommerce.adverticement],
  (d) => d
);

export const getActivityProducts = createSelector(
  [(state) => state.eCommerce.activityProducts],
  (d) => d
);

export const getEcommerceFeaturedProductsMG = createSelector(
  [(state) => state.eCommerce.featuredCurrMateriaGrp],
  (d) => d
);

export const getEcommerceRecentProductsMG = createSelector(
  [(state) => state.eCommerce.recentCurrMateriaGrp],
  (d) => d
);
export const getEcommerceActivityRecentProductsMG = createSelector(
  [(state) => state.eCommerce.activityRecentCurrMateriaGrp],
  (d) => d
);

export const getBestDeals = createSelector([(state) => state.eCommerce.bestDeals], (d) => d);
export const getHot = createSelector([(state) => state.eCommerce.hot], (d) => d);
export const getBestSeller = createSelector([(state) => state.eCommerce.bestSeller], (d) => d);
export const getNewArrival = createSelector([(state) => state.eCommerce.newArrival], (d) => d);
export const getActivityCurrPage = createSelector(
  [(state) => state.eCommerce.activityProductsCurrPage],
  (d) => d
);
export const getActivityTotalPage = createSelector(
  [(state) => state.eCommerce.activityProductsTotalPage],
  (d) => d
);
export const getActivityNextPage = createSelector(
  [(state) => state.eCommerce.activityProductsNextPage],
  (d) => d
);

export const getCartItems = createSelector([(state) => state.eCommerce.cartItems], (d) => d);
export const getCartOpen = createSelector([(state) => state.eCommerce.cartOpen], (d) => d);
export const getTotalProduct = createSelector([(state) => state.eCommerce.totalProduct], (d) => d);
export const getActivityFilter = createSelector(
  [(state) => state.eCommerce.activityFilter],
  (d) => d
);

export const getEcomSearchText = createSelector([(state) => state.eCommerce.searchText], (d) => d);
export const getEcomProductDetails = createSelector(
  [(state) => state.eCommerce.productDetails],
  (d) => d
);

export const getEcomMyOrder = createSelector([(state) => state.eCommerce.myOrders], (d) => d);
export const getMyOrderCurrentPage = createSelector(
  [(state) => state.eCommerce.myOrderCurrentPage],
  (d) => d
);
export const getMyOrderNextPage = createSelector(
  [(state) => state.eCommerce.myOrderNextPage],
  (d) => d
);
export const getMyOrderTotalPage = createSelector(
  [(state) => state.eCommerce.myOrderTotalPage],
  (d) => d
);

export const getEcomOrderList = createSelector([(state) => state.eCommerce.ecomOrders], (d) => d);

export const getEcomOrderInfo = createSelector(
  [(state) => state.eCommerce.ecomOrderDetails],
  (d) => d
);
export const getEcomOrdersCurrentPage = createSelector(
  [(state) => state.eCommerce.ecomOrdersCurrPage],
  (d) => d
);
export const getEcomOrdersNextPage = createSelector(
  [(state) => state.eCommerce.ecomOrdersNextPage],
  (d) => d
);
export const getEcomOrdersTotalPage = createSelector(
  [(state) => state.eCommerce.ecomOrdersTotalPage],
  (d) => d
);
export const getEcomOrderActionType = createSelector(
  [(state) => state.eCommerce.ecomManageOrderActionType],
  (d) => d
);

export const getEcomShippingAddress = createSelector(
  [(state) => state.eCommerce.shippingAddress],
  (d) => d
);

export const getPaymentCallBackUrl = createSelector(
  [(state) => state.eCommerce.callBackUrl],
  (d) => d
);

export const getProductBatches = createSelector([(state) => state.eCommerce.batches], (d) => d);
