import React from 'react';
import { Screen, Text } from '../../ui-kits';
import { getTitles } from '../../utils';
import { color, globalStyles } from '../../theme';
import { Box } from '@mui/material';

export const AboutUs = () => {
  const styles = {
    container: {
      display: 'flex',
      padding: '16px',
      boxSizing: 'border-box',
      justifyContent: 'center',
      backgroundColor: color.primaryBackground,
      minHeight: '100%',
      mt: 4
    },
    subContainer: { width: '90%', textAlign: 'left' }
  };

  return (
    <Screen showHeader={true}>
      <Box sx={styles.container}>
        <Box sx={styles.subContainer}>
          <Text variant="boldH40">{getTitles('T-10005')}</Text>

          <Text variant="D22" style={globalStyles.mt(1)}>
            {getTitles('T-11704')}
          </Text>

          <Text variant="D22" style={globalStyles.mt(1)}>
            {getTitles('T-11705')}
          </Text>

          <Text variant="D22" style={globalStyles.mt(1)}>
            {getTitles('T-11706')}
          </Text>

          <Text variant="D22" style={globalStyles.mt(1)}>
            {getTitles('T-11707')}
          </Text>
          <Text variant="D22" style={globalStyles.mt(1)}>
            {getTitles('T-11708')}
          </Text>
          <Text variant="D22" style={globalStyles.mt(1)}>
            {getTitles('T-11709')}
          </Text>
          <Text variant="D22" style={globalStyles.mt(1)}>
            {getTitles('T-11710')}
          </Text>
        </Box>
      </Box>
    </Screen>
  );
};
