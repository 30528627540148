import { Box, Divider, IconButton } from '@mui/material';
import {
  DatePickers,
  Text,
  UIButton,
  UIChipButton,
  UIDataTable,
  UIRadioButtonWithTitle,
  UISaveAndAdd,
  UISelect,
  UITextInputWithTitle
} from '../../../ui-kits';
import {
  filterSelectedValue,
  getFormatDate,
  getIsOther,
  getMasterListItems,
  getNamesByIds,
  getTitleWithId,
  getTitles,
  resetMessages
} from '../../../utils';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import { ModalButtons } from '../../common/modal-buttons';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActionType,
  getFarmerData,
  getFarmerSuccessMsg
} from '../../../services/redux/selectors';
import {
  addFarmerRequest,
  resetFarmerMsg,
  updateFarmerRequest
} from '../../../services/redux/slices';
import { Styles } from '../style';
import { getMasterLists } from '../../../services/redux/selectors/manageProccess';
import { useNavigate } from 'react-router-dom';
import { color } from '../../../theme';
import { ResponsiveViewDetails } from '../responsive-table-view';
import dayjs from 'dayjs';

export const FodderDetails = ({ onPrev, activeStep }) => {
  const { view, edit, add, remove, viewOnly } = defaultValues.actionType;

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const listItems = useSelector(getMasterLists);
  const farmerData = useSelector(getFarmerData);
  const success = useSelector(getFarmerSuccessMsg);
  const actionType = useSelector(getActionType);

  const [sourceOfWaterOption, setSourceOfWaterOption] = useState([]);
  const [filteredSourceOfWaterOption, setFilterdSourceOfWaterOption] = useState([]);
  const [resonForBrand, setResonForBrand] = useState([]);
  const [filteredResonForBrand, setFilteredResonForBrand] = useState([]);
  const [foodAlt, setFoodAlt] = useState([]);
  const [filteredFoodAlt, setFilteredFoodAlt] = useState([]);
  const [training, setTraining] = useState([]);
  const [feedBrand, setFeedBrand] = useState([]);
  const [filteredFeedBrand, setFilteredFeedBrand] = useState([]);
  const [reasonsOfMineralMixtureBrand, setReasonsOfMineralMixtureBrand] = useState([]);
  const [fodderGrown, setFodderGrown] = useState('');
  const [timing, setTiming] = useState('');
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [mineralMixture, setMinaralMixture] = useState('');
  const [currTrainingDetails, setCurrentTrainingDetails] = useState({
    training_id: '',
    date_attended: null
  });
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (success) {
      if (['S-10042', 'S-10044'].includes(success) && isInit) {
        resetMessages(() => navigation(SCREEN_PATH.MANAGE_FARMER));
      }
    }
  }, [success]);
  useEffect(() => {
    dispatch(resetFarmerMsg());

    if (listItems) {
      setSourceOfWaterOption(
        getMasterListItems(listItems, defaultValues.mListIds.cattleSourceWater)
      );
      setFeedBrand(getMasterListItems(listItems, defaultValues.mListIds.feedBrand));
      setResonForBrand(
        getMasterListItems(listItems, defaultValues.mListIds.reasonsForOtherFeedBrand)
      );
      setFoodAlt(getMasterListItems(listItems, defaultValues.mListIds.feedAlternatives));
      setTraining(getMasterListItems(listItems, defaultValues.mListIds.training));
    }
  }, [listItems]);

  const { values, setFieldValue } = useFormik({
    initialValues: {
      total_number_of_agriculture_land: null,
      source_natural_fodder: null,
      fodder_grown: [],
      quantity_with_productive: {
        green: null,
        dry: null,
        feed: null,
        mineral_mixture: null,
        salt: null,
        water: null
      },
      quantity_with_non_productive: {
        green: null,
        dry: null,
        feed: null,
        mineral_mixture: null,
        salt: null,
        water: null
      },
      family_members_feeding_cattle: null,
      maze_seed_packet_size: null,
      feeding_times_per_day: null,
      feeding_timings: [],
      cattle_water_source: [],
      has_chopper: null,
      chopper_type: null,
      feed_details: {
        feed_brands_used: [],
        reasons_for_other_brand: [],
        feed_alternatives: [],
        max_credit_quantity: null
      },
      mineral_mixture: {
        brands_used: [],
        reasons_for_brand: []
      },
      order_delivery_time: null,
      preferred_order_mode: null,
      attended_training: null,
      training_details: [],
      other_preferred_order_mode: null,
      expectations_from_dairy: null,
      expectations_from_society: null
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'cattle_water_source') {
      if (!values[name].includes(value)) {
        setFieldValue(name, [...values[name], { _id: value }]);
      }
    } else {
      setFieldValue(name, value);
    }
  };

  const handleDelete = (name, itemToDelete) => {
    const newValues = values[name].filter((item) => item !== itemToDelete);
    setFieldValue(name, newValues);
  };

  const updateArrayField = (fieldName, value, action, idField = '_id') => {
    const nameParts = fieldName.split('.');
    let newArray = [];

    if (nameParts.length > 1) {
      const [mainName, subName] = nameParts;
      newArray = [...(values[mainName][subName] || [])];
    } else {
      newArray = [...(values[fieldName] || [])];
    }

    if (action === add) {
      newArray.push({ [idField]: value });
    } else if (action === remove) {
      newArray = newArray.filter((item) => item[idField] !== value);
    }

    if (nameParts.length > 1) {
      const [mainName, subName] = nameParts;
      setFieldValue(mainName, {
        ...values[mainName],
        [subName]: newArray
      });
    } else {
      setFieldValue(fieldName, newArray);
    }
  };

  useEffect(() => {
    dispatch(resetFarmerMsg());

    if (farmerData) {
      setFieldValue('_id', farmerData._id);

      if (farmerData.feed_fodder_details) {
        const feedFodderDetails = farmerData.feed_fodder_details;

        setFieldValue('source_natural_fodder', feedFodderDetails.source_natural_fodder);

        setFieldValue(
          'total_number_of_agriculture_land',
          feedFodderDetails.total_number_of_agriculture_land
        );

        setFieldValue('fodder_grown', feedFodderDetails.fodder_grown || []);

        if (feedFodderDetails.quantity_with_productive) {
          setFieldValue(
            'quantity_with_productive.green',
            feedFodderDetails.quantity_with_productive.green
          );
          setFieldValue(
            'quantity_with_productive.dry',
            feedFodderDetails.quantity_with_productive.dry
          );
          setFieldValue(
            'quantity_with_productive.feed',
            feedFodderDetails.quantity_with_productive.feed
          );
          setFieldValue(
            'quantity_with_productive.mineral_mixture',
            feedFodderDetails.quantity_with_productive.mineral_mixture
          );
          setFieldValue(
            'quantity_with_productive.salt',
            feedFodderDetails.quantity_with_productive.salt
          );
          setFieldValue(
            'quantity_with_productive.water',
            feedFodderDetails.quantity_with_productive.water
          );
        }

        if (feedFodderDetails.quantity_with_non_productive) {
          setFieldValue(
            'quantity_with_non_productive.green',
            feedFodderDetails.quantity_with_non_productive.green
          );
          setFieldValue(
            'quantity_with_non_productive.dry',
            feedFodderDetails.quantity_with_non_productive.dry
          );
          setFieldValue(
            'quantity_with_non_productive.feed',
            feedFodderDetails.quantity_with_non_productive.feed
          );
          setFieldValue(
            'quantity_with_non_productive.mineral_mixture',
            feedFodderDetails.quantity_with_non_productive.mineral_mixture
          );
          setFieldValue(
            'quantity_with_non_productive.salt',
            feedFodderDetails.quantity_with_non_productive.salt
          );
          setFieldValue(
            'quantity_with_non_productive.water',
            feedFodderDetails.quantity_with_non_productive.water
          );
        }

        setFieldValue(
          'family_members_feeding_cattle',
          feedFodderDetails.family_members_feeding_cattle
        );
        setFieldValue('maze_seed_packet_size', feedFodderDetails.maze_seed_packet_size);
        setFieldValue('feeding_times_per_day', feedFodderDetails.feeding_times_per_day);
        setFieldValue('feeding_timings', feedFodderDetails.feeding_timings || []);
        setFieldValue('cattle_water_source', feedFodderDetails.cattle_water_source || []);
        setFieldValue('has_chopper', feedFodderDetails.has_chopper);
        setFieldValue('chopper_type', feedFodderDetails.chopper_type);

        if (feedFodderDetails.feed_details) {
          setFieldValue(
            'feed_details.feed_brands_used',
            feedFodderDetails.feed_details.feed_brands_used
          );
          setFieldValue(
            'feed_details.other_brands_used',
            feedFodderDetails.feed_details.other_brands_used
          );
          setFieldValue(
            'feed_details.reasons_for_other_brand',
            feedFodderDetails.feed_details.reasons_for_other_brand
          );
          setFieldValue(
            'feed_details.other_reasons_for_other_brand',
            feedFodderDetails.feed_details.other_reasons_for_other_brand
          );
          setFieldValue(
            'feed_details.feed_alternatives',
            feedFodderDetails.feed_details.feed_alternatives
          );
          setFieldValue(
            'feed_details.max_credit_quantity',
            feedFodderDetails.feed_details.max_credit_quantity
          );
        }

        if (feedFodderDetails.mineral_mixture) {
          setFieldValue(
            'mineral_mixture.brands_used',
            feedFodderDetails.mineral_mixture.brands_used || []
          );
          setFieldValue(
            'mineral_mixture.reasons_for_brand',
            feedFodderDetails.mineral_mixture.reasons_for_brand || []
          );
        }

        setFieldValue('order_delivery_time', feedFodderDetails.order_delivery_time);
        setFieldValue('preferred_order_mode', feedFodderDetails.preferred_order_mode?.id);
        setFieldValue('other_preferred_order_mode', feedFodderDetails.other_preferred_order_mode);
        setFieldValue('attended_training', feedFodderDetails.attended_training);
        const trainingDetails = feedFodderDetails.training_details?.map((detail) => {
          return {
            ...detail,
            date_attended: dayjs(detail?.date_attended)
          };
        });
        setFieldValue('training_details', trainingDetails);
        setFieldValue('expectations_from_dairy', feedFodderDetails.expectations_from_dairy);
        setFieldValue('expectations_from_society', feedFodderDetails.expectations_from_society);
      }
    }
  }, [farmerData]);

  useEffect(() => {
    setFilterdSourceOfWaterOption(
      filterSelectedValue(sourceOfWaterOption, values.cattle_water_source)
    );
  }, [values.cattle_water_source, sourceOfWaterOption]);

  useEffect(() => {
    setFilteredFeedBrand(filterSelectedValue(feedBrand, values.feed_details.feed_brands_used));
  }, [values.feed_details.feed_brands_used, feedBrand]);

  useEffect(() => {
    setFilteredResonForBrand(
      filterSelectedValue(resonForBrand, values.feed_details.reasons_for_other_brand)
    );
  }, [values.feed_details.reasons_for_other_brand, resonForBrand]);

  useEffect(() => {
    setFilteredFoodAlt(filterSelectedValue(foodAlt, values.feed_details.feed_alternatives));
  }, [values.feed_details.feed_alternatives, foodAlt]);

  useEffect(() => {
    setReasonsOfMineralMixtureBrand(
      filterSelectedValue(
        defaultValues.reasonsOfMineralMixtureBrand,
        values.mineral_mixture.reasons_for_brand,
        'id',
        'value'
      )
    );
  }, [values.mineral_mixture.reasons_for_brand]);

  const handleMultiAdd = (val, field, fieldArrr, setVal) => {
    setFieldValue(field, [...fieldArrr, val]);
    setVal('');
  };

  const handleAddTrainingDetails = () => {
    setFieldValue('training_details', [...values.training_details, { ...currTrainingDetails }]);
    setCurrentTrainingDetails({
      training_id: '',
      date_attended: null
    });
  };

  const handleDeleteTrainingDetails = (index) => {
    const updatedTrainingDetails = [...values.training_details];
    updatedTrainingDetails.splice(index, 1);
    setFieldValue('training_details', updatedTrainingDetails);
  };

  const handleEditTrainingDetails = (index) => {
    setCurrentTrainingDetails({ ...values.training_details[index] });
    const updatedTrainingDetails = [...values.training_details];
    updatedTrainingDetails.splice(index, 1);
    setFieldValue('training_details', updatedTrainingDetails);
  };

  const handleFamChange = (e) => {
    const { name, value } = e.target;
    setCurrentTrainingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    const normalizeFeedDetails = (feedDetails) => {
      const normalizeArray = (arr) => arr.map((item) => item?._id || item);

      return {
        ...feedDetails,
        feed_brands_used: normalizeArray(feedDetails.feed_brands_used),
        reasons_for_other_brand: normalizeArray(feedDetails.reasons_for_other_brand),
        feed_alternatives: normalizeArray(feedDetails.feed_alternatives),
        max_credit_quantity: feedDetails.max_credit_quantity || null
      };
    };

    if (actionType === edit && activeStep === 5) {
      let _id = farmerData._id;
      const feed_fodder_details = {
        ...values,
        cattle_water_source: values.cattle_water_source?.map((item) => item?._id),
        feed_details: normalizeFeedDetails(values.feed_details),
        training_details: values.training_details?.map((training) => ({
          training_id: training?.training_id?._id || training.training_id,
          date_attended: training?.date_attended
        })),
        mineral_mixture: {
          ...values.mineral_mixture,
          reasons_for_brand: values.mineral_mixture.reasons_for_brand.map((reason) => reason.id)
        },
        order_delivery_time: values.order_delivery_time?.id || values.order_delivery_time,
        preferred_order_mode: values.preferred_order_mode?.id
          ? values.preferred_order_mode?.id
          : values.preferred_order_mode
            ? values.preferred_order_mode
            : null,
        quantity_with_productive: {
          ...values.quantity_with_productive,
          green: parseFloat(values.quantity_with_productive?.green),
          dry: parseFloat(values.quantity_with_productive?.dry),
          feed: parseFloat(values.quantity_with_productive?.feed),
          mineral_mixture: parseFloat(values.quantity_with_productive?.mineral_mixture),
          salt: parseFloat(values.quantity_with_productive?.salt),
          water: parseFloat(values.quantity_with_productive?.water)
        },
        quantity_with_non_productive: {
          ...values.quantity_with_non_productive,
          green: parseFloat(values.quantity_with_non_productive?.green),
          dry: parseFloat(values.quantity_with_non_productive?.dry),
          feed: parseFloat(values.quantity_with_non_productive?.feed),
          mineral_mixture: parseFloat(values.quantity_with_non_productive?.mineral_mixture),
          salt: parseFloat(values.quantity_with_non_productive?.salt),
          water: parseFloat(values.quantity_with_non_productive?.water)
        },
        maze_seed_packet_size: parseFloat(values.maze_seed_packet_size),
        feeding_times_per_day: parseInt(values.feeding_times_per_day),
        family_members_feeding_cattle: parseInt(values.family_members_feeding_cattle)
      };

      dispatch(updateFarmerRequest({ _id, feed_fodder_details }));
    } else {
      const payload = {
        data: {
          ...values,
          _id: farmerData?._id,
          cattle_water_source: values.cattle_water_source?.map((item) => item?._id),
          feed_details: normalizeFeedDetails(values.feed_details),
          training_details: values.training_details?.map((training) => ({
            training_id: training?.training_id?._id || training.training_id,
            date_attended: training?.date_attended
          })),
          mineral_mixture: {
            ...values.mineral_mixture,
            reasons_for_brand: values.mineral_mixture.reasons_for_brand.map((reason) => reason.id)
          },
          order_delivery_time: values.order_delivery_time,
          preferred_order_mode: values.preferred_order_mode?.id
            ? values.preferred_order_mode?.id
            : values.preferred_order_mode
              ? values.preferred_order_mode
              : null,

          quantity_with_productive: {
            ...values.quantity_with_productive,
            green: parseFloat(values.quantity_with_productive?.green),
            dry: parseFloat(values.quantity_with_productive?.dry),
            feed: parseFloat(values.quantity_with_productive?.feed),
            mineral_mixture: parseFloat(values.quantity_with_productive?.mineral_mixture),
            salt: parseFloat(values.quantity_with_productive?.salt),
            water: parseFloat(values.quantity_with_productive?.water)
          },
          quantity_with_non_productive: {
            ...values.quantity_with_non_productive,
            green: parseFloat(values.quantity_with_non_productive?.green),
            dry: parseFloat(values.quantity_with_non_productive?.dry),
            feed: parseFloat(values.quantity_with_non_productive?.feed),
            mineral_mixture: parseFloat(values.quantity_with_non_productive?.mineral_mixture),
            salt: parseFloat(values.quantity_with_non_productive?.salt),
            water: parseFloat(values.quantity_with_non_productive?.water)
          },
          maze_seed_packet_size: parseFloat(values.maze_seed_packet_size),
          feeding_times_per_day: parseInt(values.feeding_times_per_day),
          family_members_feeding_cattle: parseInt(values.family_members_feeding_cattle)
        },
        registration_step: 5
      };

      dispatch(addFarmerRequest(payload));
    }
    setIsInit(true);
  };

  useEffect(() => {
    if (values.feed_details && values.feed_details.feed_brands_used) {
      const names = getIsOther(values.feed_details.feed_brands_used, feedBrand);

      const isOtherPresent = names.includes('Other');
      setIsOtherSelected(isOtherPresent);
    }
  }, [values.feed_details.feed_brands_used]);

  const tableData = [
    [
      {
        title: getTitles('T-10582'),
        values:
          actionType === view ? null : (
            <UISelect
              name="training_id"
              onChange={handleFamChange}
              value={currTrainingDetails.training_id?._id || currTrainingDetails.training_id}
              variant="D22"
              optionLabel="name"
              returnValue="_id"
              options={training}
              placeholder={getTitles('T-10350')}
            />
          )
      },
      {
        title: getTitles('T-10583'),
        values:
          actionType === view ? null : (
            <DatePickers
              dateValue={currTrainingDetails.date_attended}
              onDateChange={(val) =>
                handleFamChange({ target: { value: val, name: 'date_attended' } })
              }
            />
          )
      }
    ]
  ];

  const handleDeleteChip = (index, field, currentValues, setFieldValue) => {
    const updatedValues = currentValues.filter((_, i) => i !== index);
    setFieldValue(field, updatedValues);
  };

  return (
    <Box sx={Styles.container}>
      <Box sx={Styles.fodderContainer}>
        <UITextInputWithTitle
          name="source_natural_fodder"
          value={values.source_natural_fodder || ''}
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          title={getTitles('T-10556')}
          placeHolder={getTitles('T-11212')}
          containerStyles={Styles.prodContainer}
        />
        {actionType !== view && (
          <Box sx={Styles.foderDisp}>
            <UITextInputWithTitle
              name="fodder_grown"
              title={getTitles('T-10557')}
              placeHolder={getTitles('T-11213')}
              containerStyles={Styles.prodContainer}
              onChange={(e) => setFodderGrown(e.target.value)}
              value={fodderGrown || ''}
            />
            <Box sx={Styles.multiAdd}>
              <UIButton
                title={getTitles('T-10677')}
                style={Styles.secBtn}
                onClick={() =>
                  handleMultiAdd(fodderGrown, 'fodder_grown', values.fodder_grown, setFodderGrown)
                }
              />
            </Box>
          </Box>
        )}
        <Box>
          {actionType === view && values.fodder_grown.length > 0 && (
            <Text variant="D20" style={Styles.chipTitle}>
              {getTitles('T-10557')}
            </Text>
          )}
          {values.fodder_grown.map((item, index) => (
            <UIChipButton
              showDelete={actionType !== view}
              title={item}
              key={index}
              backgroundColor={color.primary}
              onDelete={() =>
                handleDeleteChip(index, 'fodder_grown', values.fodder_grown, setFieldValue)
              }
            />
          ))}
        </Box>
        <UITextInputWithTitle
          name="total_number_of_agriculture_land"
          value={values.total_number_of_agriculture_land || ''}
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          title={getTitles('T-10537')}
          containerStyles={Styles.prodContainer}
          placeHolder={getTitles('T-10360')}
        />
        <Text variant="BoldH28" style={Styles.text}>
          {getTitles('T-10558')}
        </Text>
        <Divider sx={Styles.divider} />
        <Box sx={Styles.commonColumn()}>
          <UITextInputWithTitle
            name="quantity_with_productive.green"
            value={values.quantity_with_productive.green || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10559')}
            placeHolder={getTitles('T-11050')}
            maxLength={6}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="quantity_with_productive.dry"
            value={values.quantity_with_productive.dry || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10560')}
            placeHolder={getTitles('T-11050')}
            maxLength={6}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="quantity_with_productive.feed"
            value={values.quantity_with_productive.feed || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10561')}
            placeHolder={getTitles('T-11050')}
            maxLength={6}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="quantity_with_productive.mineral_mixture"
            value={values.quantity_with_productive.mineral_mixture || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10562')}
            placeHolder={getTitles('T-11050')}
            maxLength={6}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="quantity_with_productive.salt"
            value={values.quantity_with_productive.salt || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10563')}
            placeHolder={getTitles('T-11050')}
            maxLength={6}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="quantity_with_productive.water"
            value={values.quantity_with_productive.water || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10564')}
            placeHolder={getTitles('T-11050')}
            maxLength={6}
            allowDecimal={true}
          />
        </Box>
        <Text variant="BoldH28" style={Styles.text}>
          {getTitles('T-10565')}
        </Text>
        <Divider sx={Styles.divider} />
        <Box sx={Styles.commonColumn()}>
          <UITextInputWithTitle
            name="quantity_with_non_productive.green"
            value={values.quantity_with_non_productive.green || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10559')}
            placeHolder={getTitles('T-11050')}
            maxLength={6}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="quantity_with_non_productive.dry"
            value={values.quantity_with_non_productive.dry || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10560')}
            placeHolder={getTitles('T-11050')}
            maxLength={6}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="quantity_with_non_productive.feed"
            value={values.quantity_with_non_productive.feed || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10561')}
            placeHolder={getTitles('T-11050')}
            maxLength={6}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="quantity_with_non_productive.mineral_mixture"
            value={values.quantity_with_non_productive.mineral_mixture || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10562')}
            placeHolder={getTitles('T-11050')}
            maxLength={6}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="quantity_with_non_productive.salt"
            value={values.quantity_with_non_productive.salt || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10563')}
            placeHolder={getTitles('T-11050')}
            maxLength={6}
            allowDecimal={true}
          />
          <UITextInputWithTitle
            name="quantity_with_non_productive.water"
            value={values.quantity_with_non_productive.water || ''}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            title={getTitles('T-10564')}
            placeHolder={getTitles('T-11050')}
            maxLength={6}
            allowDecimal={true}
          />
        </Box>
        <UITextInputWithTitle
          name="family_members_feeding_cattle"
          value={values.family_members_feeding_cattle || ''}
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          title={getTitles('T-10566')}
          placeHolder={getTitles('T-10360')}
          containerStyles={Styles.prodContainer}
        />
        <UITextInputWithTitle
          name="maze_seed_packet_size"
          value={values.maze_seed_packet_size || ''}
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          title={getTitles('T-10567')}
          placeHolder={getTitles('T-11050')}
          containerStyles={Styles.prodContainer}
          allowDecimal={true}
        />
        <UITextInputWithTitle
          name="feeding_times_per_day"
          value={values.feeding_times_per_day || ''}
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          title={getTitles('T-10568')}
          placeHolder={getTitles('T-10360')}
          containerStyles={Styles.prodContainer}
        />
        {actionType !== view && (
          <Box sx={Styles.foderDisp}>
            <UITextInputWithTitle
              name="feeding_timings"
              value={timing}
              onChange={(e) => setTiming(e.target.value)}
              title={getTitles('T-10569')}
              placeHolder={getTitles('T-11214')}
              containerStyles={{ width: 'calc(40% - 5px)' }}
            />
            <Box sx={Styles.multiAdd}>
              <UIButton
                title={getTitles('T-10677')}
                style={Styles.secBtn}
                onClick={() =>
                  handleMultiAdd(timing, 'feeding_timings', values.feeding_timings, setTiming)
                }
              />
            </Box>
          </Box>
        )}
        <Box>
          {actionType === view && values.feeding_timings.length > 0 && (
            <Text variant="D20" style={Styles.chipTitle}>
              {getTitles('T-10569')}
            </Text>
          )}
          {values &&
            values.feeding_timings &&
            values.feeding_timings.map((item, index) => (
              <UIChipButton
                showDelete={actionType !== view}
                title={item}
                key={index}
                backgroundColor={color.primary}
                onDelete={() =>
                  handleDeleteChip(index, 'feeding_timings', values.feeding_timings, setFieldValue)
                }
              />
            ))}
        </Box>

        {actionType !== view && (
          <UISelect
            name="cattle_water_source"
            value={''}
            title={getTitles('T-10570')}
            optionLabel="name"
            returnValue="_id"
            options={filteredSourceOfWaterOption}
            onChange={handleChange}
            disabled={actionType === view || actionType === viewOnly}
            placeholder={getTitles('T-10350')}
            containerStyles={Styles.prodContainer}
          />
        )}
        {actionType === view && values.cattle_water_source.length > 0 && (
          <Text variant="D20" style={Styles.chipTitle}>
            {getTitles('T-10570')}
          </Text>
        )}
        {values.cattle_water_source &&
          values.cattle_water_source.map((item, index) => (
            <UIChipButton
              title={item?.name || getNamesByIds([item?._id], sourceOfWaterOption)}
              showDelete={actionType !== view}
              key={index}
              onDelete={() => handleDelete('cattle_water_source', item)}
            />
          ))}

        <UIRadioButtonWithTitle
          name="has_chopper"
          onCheck={(val) => {
            setFieldValue('has_chopper', val);
            setFieldValue('chopper_type', null);
          }}
          title={getTitles('T-10571')}
          values={defaultValues.personalInsurane}
          disabled={actionType === view || actionType === viewOnly}
          checkedValue={values.has_chopper}
        />
        {values.has_chopper && (
          <UIRadioButtonWithTitle
            name="chopper_type"
            onCheck={(val) => {
              setFieldValue('chopper_type', val);
            }}
            title={getTitles('T-10678')}
            values={defaultValues.chopperType}
            disabled={actionType === view || actionType === viewOnly}
            checkedValue={values.chopper_type}
          />
        )}
        <Text variant="BoldH28" style={Styles.text}>
          {getTitles('T-10572')}
        </Text>
        <Divider sx={Styles.divider} />
        {actionType !== view && (
          <UISelect
            name="feed_details.feed_brands_used"
            value={''}
            onChange={(e) => updateArrayField('feed_details.feed_brands_used', e.target.value, add)}
            disabled={actionType === view || actionType === viewOnly}
            containerStyles={Styles.prodContainer}
            title={getTitles('T-10573')}
            options={filteredFeedBrand}
            returnValue="_id"
            optionLabel="name"
            placeholder={getTitles('T-10350')}
          />
        )}
        {actionType === view &&
          values.feed_details &&
          values.feed_details.feed_brands_used?.length > 0 && (
            <Text variant="D20" style={Styles.chipTitle}>
              {getTitles('T-10573')}
            </Text>
          )}
        {Array.isArray(values.feed_details.feed_brands_used) &&
          values.feed_details.feed_brands_used.length > 0 &&
          values.feed_details.feed_brands_used.map((item, index) => (
            <UIChipButton
              title={item?.name || getNamesByIds([item._id], feedBrand)}
              showDelete={actionType !== view}
              key={index}
              onDelete={() => updateArrayField('feed_details.feed_brands_used', item?._id, remove)}
            />
          ))}

        {isOtherSelected && (
          <>
            {actionType !== view && (
              <UISelect
                name="feed_details.reasons_for_other_brand"
                value={''}
                onChange={(e) =>
                  updateArrayField('feed_details.reasons_for_other_brand', e.target.value, add)
                }
                disabled={actionType === view || actionType === viewOnly}
                containerStyles={Styles.prodContainer}
                options={filteredResonForBrand}
                title={getTitles('T-10574')}
                returnValue="_id"
                optionLabel="name"
                placeholder={getTitles('T-10350')}
              />
            )}
            {actionType === view &&
              values.feed_details &&
              values.feed_details.reasons_for_other_brand.length > 0 && (
                <Text variant="D20" style={Styles.chipTitle}>
                  {getTitles('T-10574')}
                </Text>
              )}
            {Array.isArray(values.feed_details.reasons_for_other_brand) &&
              values.feed_details.reasons_for_other_brand.length > 0 &&
              values.feed_details.reasons_for_other_brand.map((item, index) => (
                <UIChipButton
                  title={item?.name || getNamesByIds([item?._id], resonForBrand)}
                  showDelete={actionType !== view}
                  key={index}
                  onDelete={() =>
                    updateArrayField('feed_details.reasons_for_other_brand', item?._id, remove)
                  }
                />
              ))}
          </>
        )}

        {actionType !== view && (
          <UISelect
            name="feed_details.feed_alternatives"
            value={''}
            onChange={(e) =>
              updateArrayField('feed_details.feed_alternatives', e.target.value, add)
            }
            disabled={actionType === view || actionType === viewOnly}
            containerStyles={Styles.prodContainer}
            title={getTitles('T-10575')}
            options={filteredFoodAlt}
            returnValue="_id"
            optionLabel="name"
            placeholder={getTitles('T-10350')}
          />
        )}
        {actionType === view &&
          values.feed_details &&
          values.feed_details.feed_alternatives?.length > 0 && (
            <Text variant="D20" style={Styles.chipTitle}>
              {getTitles('T-10575')}
            </Text>
          )}
        {Array.isArray(values.feed_details.feed_alternatives) &&
          values.feed_details.feed_alternatives.length > 0 &&
          values.feed_details.feed_alternatives.map((item, index) => (
            <UIChipButton
              showDelete={actionType !== view}
              title={item?.name || getNamesByIds([item?._id], foodAlt)}
              key={index}
              onDelete={() => updateArrayField('feed_details.feed_alternatives', item?._id, remove)}
            />
          ))}
        <UITextInputWithTitle
          name="feed_details.max_credit_quantity"
          value={values.feed_details.max_credit_quantity}
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          title={getTitles('T-10576')}
          placeHolder={getTitles('T-11050')}
          containerStyles={Styles.prodContainer}
        />
        <Text variant="BoldH28" style={Styles.text}>
          {getTitles('T-10577')}
        </Text>
        <Divider sx={Styles.divider} />
        {actionType !== view && (
          <Box sx={Styles.foderDisp}>
            <UITextInputWithTitle
              name="mineral_mixture.brands_used"
              value={mineralMixture}
              onChange={(e) => setMinaralMixture(e.target.value)}
              title={getTitles('T-10578')}
              placeHolder={getTitles('T-10586')}
              containerStyles={Styles.prodContainer}
            />
            <Box sx={Styles.multiAdd}>
              <UIButton
                title={getTitles('T-10677')}
                style={Styles.secBtn}
                onClick={() =>
                  handleMultiAdd(
                    mineralMixture,
                    'mineral_mixture.brands_used',
                    values.mineral_mixture.brands_used,
                    setMinaralMixture
                  )
                }
              />
            </Box>
          </Box>
        )}
        <Box>
          {actionType === view && values.mineral_mixture.brands_used.length > 0 && (
            <Text variant="D20" style={Styles.chipTitle}>
              {getTitles('T-10578')}
            </Text>
          )}
          {values &&
            values.mineral_mixture.brands_used.map((item, index) => (
              <UIChipButton
                showDelete={actionType !== view}
                title={item}
                key={index}
                backgroundColor={color.primary}
                onDelete={() =>
                  handleDeleteChip(
                    index,
                    'mineral_mixture.brands_used',
                    values.mineral_mixture.brands_used,
                    setFieldValue
                  )
                }
              />
            ))}
        </Box>
        {actionType !== view && (
          <UISelect
            name="mineral_mixture.reasons_for_brand"
            value={''}
            onChange={(e) =>
              updateArrayField('mineral_mixture.reasons_for_brand', e.target.value, add, 'id')
            }
            disabled={actionType === view || actionType === viewOnly}
            containerStyles={Styles.prodContainer}
            title={getTitles('T-10680')}
            options={reasonsOfMineralMixtureBrand}
            placeholder={getTitles('T-10350')}
          />
        )}
        {actionType === view &&
          values.mineral_mixture &&
          values.mineral_mixture.reasons_for_brand.length > 0 && (
            <Text variant="D20" style={Styles.chipTitle}>
              {getTitles('T-10680')}
            </Text>
          )}
        {values.mineral_mixture &&
          values.mineral_mixture.reasons_for_brand &&
          values.mineral_mixture.reasons_for_brand.map((item, index) => (
            <UIChipButton
              showDelete={actionType !== view}
              onDelete={() =>
                updateArrayField('mineral_mixture.reasons_for_brand', item?.id, remove, 'id')
              }
              key={index}
              title={getTitleWithId(defaultValues.reasonsOfMineralMixtureBrand, item.id)}
            />
          ))}
        <UISelect
          name="order_delivery_time"
          value={values.order_delivery_time?.id || values.order_delivery_time || ''}
          onChange={handleChange}
          onlyView={actionType === view}
          valueToShow={getTitleWithId(
            defaultValues.orderDeliveryTime,
            values.order_delivery_time?.id
          )}
          containerStyles={Styles.prodContainer}
          title={getTitles('T-10579')}
          options={defaultValues.orderDeliveryTime}
          placeholder={getTitles('T-10350')}
        />
        <UIRadioButtonWithTitle
          title={getTitles('T-10580')}
          values={defaultValues.preferredOrderMode}
          onCheck={(val) => {
            setFieldValue('preferred_order_mode', val);
            setFieldValue('other_preferred_order_mode', '');
          }}
          disabled={actionType === view || actionType === viewOnly}
          checkedValue={values.preferred_order_mode?.id || values.preferred_order_mode}
        />
        {values.preferred_order_mode === 4 && (
          <UITextInputWithTitle
            name="other_preferred_order_mode"
            value={values.other_preferred_order_mode || ''}
            onChange={handleChange}
            containerStyles={Styles.prodContainer}
            placeHolder={getTitles('T-10586')}
            disabled={actionType === view || actionType === viewOnly}
          />
        )}
        <UIRadioButtonWithTitle
          title={getTitles('T-10581')}
          values={defaultValues.personalInsurane}
          onCheck={(val) => {
            setFieldValue('attended_training', val);
            setFieldValue('training_details', []);
          }}
          disabled={actionType === view || actionType === viewOnly}
          checkedValue={values.attended_training}
        />
        {values.attended_training && (
          <>
            {!defaultValues.isResponsive ? (
              <UIDataTable
                tableRows={tableData}
                rowValueContainerStyle={Styles.rowValueContainerStyle}
                valuesSubStyle={Styles.valuesSubStyle}
                headerContStyle={Styles.headerContStyle}
              />
            ) : (
              <>
                {actionType !== view && (
                  <Box sx={Styles.responsiveTable}>
                    {tableData[0].map((item, idx) => (
                      <Box key={idx} sx={Styles.tData}>
                        <Text style={Styles.halfWidthField} variant="D22">
                          {item.title}
                        </Text>
                        <Box style={Styles.halfWidthField}>{item.values}</Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </>
            )}
            {actionType !== view && (
              <UISaveAndAdd
                title={
                  values.training_details.length < 1 ? getTitles('T-10394') : getTitles('T-10062')
                }
                onAddAnother={handleAddTrainingDetails}
                disabled={
                  currTrainingDetails.date_attended === null ||
                  currTrainingDetails.training_id === ''
                }
              />
            )}
          </>
        )}
        {!defaultValues.isResponsive &&
          values &&
          values.training_details.map((item, index) => (
            <Box key={index} sx={Styles.trainingCont}>
              <Box sx={Styles.trainingContInner(actionType === view)}>
                <Text sx={Styles.docsMargin}>
                  {item?.name ||
                    getNamesByIds([item.training_id._id || item.training_id], training)}
                </Text>
                <Text sx={Styles.docsMargin}>{getFormatDate(item.date_attended)}</Text>
              </Box>
              {actionType !== view && (
                <Box sx={Styles.iconsCont}>
                  <IconButton sx={Styles.IconStyle}>
                    <EditIcon onClick={() => handleEditTrainingDetails(index)} />
                  </IconButton>
                  <IconButton sx={Styles.IconStyle}>
                    <DeleteIcon onClick={() => handleDeleteTrainingDetails(index)} />
                  </IconButton>
                </Box>
              )}
            </Box>
          ))}
        {defaultValues.isResponsive &&
          values.training_details.map((item, index) => {
            const details = [
              {
                label: getTitles('T-10582'),
                value:
                  item?.name || getNamesByIds([item.training_id?._id || item.training_id], training)
              },
              {
                label: getTitles('T-10583'),
                value: getFormatDate(item.date_attended)
              }
            ];

            return (
              <ResponsiveViewDetails
                key={index}
                title={
                  item?.name || getNamesByIds([item.training_id?._id || item.training_id], training)
                }
                details={details}
                handleEdit={() => handleEditTrainingDetails(index)}
                handleDelete={() => handleDeleteTrainingDetails(index)}
                hideIcons={actionType === view}
              />
            );
          })}
        <UITextInputWithTitle
          name="expectations_from_dairy"
          value={values.expectations_from_dairy || ''}
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          rows={3}
          title={getTitles('T-10584')}
          placeHolder={getTitles('T-10586')}
          containerStyles={Styles.prodContainer}
        />
        <UITextInputWithTitle
          name="expectations_from_society"
          value={values.expectations_from_society || ''}
          onChange={handleChange}
          disabled={actionType === view || actionType === viewOnly}
          rows={3}
          title={getTitles('T-10585')}
          placeHolder={getTitles('T-10586')}
          containerStyles={Styles.prodContainer}
        />
      </Box>

      <ModalButtons
        btnPositions={defaultValues.isResponsive ? 'center' : 'start'}
        secondaryBtnTitle={getTitles('T-10340')}
        onSecondaryBtnClick={() => {
          if (actionType === viewOnly) {
            onPrev();
          } else {
            onPrev();
          }
        }}
        onPrimaryBtnClick={
          actionType === view
            ? () => navigation(SCREEN_PATH.MANAGE_FARMER)
            : actionType !== viewOnly && handleSubmit
        }
        primaryBtnTitle={
          actionType === edit
            ? getTitles('T-10733')
            : actionType === view
              ? getTitles('T-11103')
              : getTitles('T-10339')
        }
      />
    </Box>
  );
};
