import { Box } from '@mui/material';
import { styles } from './styles';
import { getFormatDate, getTitles } from '../../../../utils';
import { Text } from '../../../../ui-kits';
import { globalStyles } from '../../../../theme';

export const MyOrdersCard = ({
  image,
  quantity,
  productName,
  deliveryDate,
  deliveryDateTitle,
  orderedDate,
  trackingNo,
  trackingLink,
  deliveryStatus,
  totalPrice
}) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.subCont}>
        <Box sx={styles.imageCont}>
          <img style={styles.img} src={image} />
          <Text variant={'BoldH22'}> {productName}</Text>
        </Box>

        <Box sx={styles.leftCont}>
          <Box sx={styles.detailsCommonSubCont}>
            <Text variant="BoldH16"> {`${getTitles('T-11674')} : `}</Text>
            <Text variant="D14" style={styles.secondaryText}>
              {getFormatDate(orderedDate)}
            </Text>
          </Box>

          <Box sx={styles.detailsCommonSubCont}>
            <Text variant="BoldH16" style={{ maxWidth: '50%' }}>
              {`${deliveryDateTitle} : `}
            </Text>
            <Text variant="D14" style={styles.secondaryText}>
              {deliveryDate && getFormatDate(deliveryDate)}
            </Text>
          </Box>

          <Box sx={styles.detailsCommonSubCont}>
            <Text variant="BoldH16">{`${getTitles('T-11727')} : `} </Text>
            <Text variant="D14" style={styles.secondaryText}>
              {trackingNo}
            </Text>
          </Box>

          <Box sx={styles.detailsCommonSubCont}>
            <Text variant="BoldH16"> {`${getTitles('T-11728')} : `}</Text>
            <Text
              variant="D14"
              style={{ ...styles.secondaryText, ...globalStyles.title, width: '50%' }}
              onClick={() => trackingLink && window.open(trackingLink)}>
              {trackingLink}
            </Text>
          </Box>

          <Box sx={styles.detailsCommonSubCont}>
            <Text variant="BoldH16"> {`${getTitles('T-11733')} : `}</Text>
            <Text variant={'D14'} style={styles.secondaryText}>
              {deliveryStatus?.name}
            </Text>
          </Box>

          <Box sx={styles.detailsCommonSubCont}>
            <Text variant={'BoldH16'}> {`${getTitles('T-11022')} : `}</Text>
            <Text variant={'D14'} style={styles.secondaryText}>
              {quantity}
            </Text>
          </Box>

          <Box sx={styles.detailsCommonSubCont}>
            <Text variant="BoldH16"> {`${getTitles('T-11735')} : `}</Text>
            <Text variant="BoldH16" style={styles.secondaryText}>{`₹ ${totalPrice || ''}`}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
