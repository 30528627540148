/*
 *Commonly used regular expressions
 *
 * Add or remove variables according to your requirement
 */
export const regExp = {
  strLowerCase: /^(?=.*[a-z])/,
  strUpperCase: /^(?=.*[A-Z])/,
  containsNum: /^(?=.*[0-9])/,
  containsSpecialChar: /^(?=.*[!@#$%^&*])/,
  onlyNumbers: /^\d+$/,
  email: /\S+@\S+\.\S+/,
  alphabetRegExp: /^[A-Za-z\s]+$/,
  pinCode: /^\d{6}$/,
  phoneRegExp:
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
  decimalNum: /^\d*\.?\d+$/,
  point: /^\d*\.$/,
  numberWithSpecialChar: /^[0-9!@#$%^&*]+$/,
  aadhaarNumber: /^\d{12}$/,
  panCardNumber: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
  onlyAlphabetNumber: /^[a-zA-Z0-9]+$/,
  ifscCode: /^[A-Z0-9]+$/
};
