import { Box, IconButton, Input } from '@mui/material';
import { useEffect, useState } from 'react';
import { color } from '../../theme/color';
import SearchIcon from '@mui/icons-material/Search';
import { defaultValues, regExp } from '../../constants';
import { getTitles } from '../../utils';

export const SearchBar = ({
  searchedVlaue,
  placeHolder = getTitles('T-10077'),
  showSearchIcon = true,
  onRefresh,
  allowOnlyAlphabets = false,
  allowOnlyNumbers = false,
  disableSearch,
  preSelectedValue,
  containerStyles,
  ...rest
}) => {
  const [search, setSearch] = useState('');
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (changed) {
      const getData = setTimeout(() => {
        searchedVlaue && searchedVlaue(search);
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [search]);

  useEffect(() => {
    if (onRefresh && Object.keys(onRefresh).length === 0) {
      setSearch('');
    }
  }, [onRefresh]);

  useEffect(() => {
    if (preSelectedValue) {
      setSearch(preSelectedValue);
    } else {
      setSearch('');
    }
  }, [preSelectedValue]);

  const Styles = {
    searchBarContainer: (style) => ({
      display: 'flex',
      border: `1px solid ${color.primaryBorder}`,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: defaultValues.isResponsive ? '5px' : '8px',
      padding: !defaultValues.isResponsive && '4px',
      boxSizing: 'border-box',
      backgroundColor: color.primaryBackground,
      height: defaultValues.isResponsive ? '32px' : '48px',
      ...style
    }),
    searchBar: {
      width: '100%',
      height: '100%',
      fontSize: defaultValues.isResponsive ? '16px' : '20px'
    },
    searchIcon: {
      p: '10px'
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const trimmedValue = inputValue.trimStart();

    if (trimmedValue !== inputValue) {
      return;
    }

    if (allowOnlyAlphabets) {
      if (inputValue === '' || regExp.alphabetRegExp.test(inputValue)) {
        setChanged(true);
        setSearch(inputValue);
      }
    } else if (allowOnlyNumbers) {
      if (inputValue === '' || regExp.onlyNumbers.test(inputValue)) {
        setChanged(true);
        setSearch(inputValue);
      }
    } else {
      setChanged(true);
      setSearch(inputValue);
    }
  };

  return (
    <Box sx={Styles.searchBarContainer(containerStyles)}>
      {showSearchIcon && (
        <IconButton type="button" sx={Styles.searchIcon} aria-label="search">
          <SearchIcon />
        </IconButton>
      )}
      <Input
        type="search"
        value={search}
        disableUnderline
        placeholder={placeHolder}
        sx={Styles.searchBar}
        onChange={handleInputChange}
        disabled={disableSearch}
        {...rest}
      />
    </Box>
  );
};
