import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {
  ActionButton,
  PopupModal,
  Text,
  UIAutocompleted,
  UIButton,
  UILayout,
  UISaveAndAdd,
  UITextInputWithTitle
} from '../../../ui-kits';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addDiseaseRequest,
  getDiseaseRequest,
  getDiseaseGroupRequest,
  getDiseaseTypeRequest,
  resetManageProcessMsg,
  updateDiseaseRequest,
  updateDiseaseParams,
  updateDetailsPg,
  setDiseaseCurrRender
} from '../../../services/redux/slices';
import {
  getCurPage,
  getDiseaseCurrRender,
  getDiseaseParams,
  getDiseasesList,
  getErrorMsg,
  getMasterList16,
  getMasterList17,
  getSuccessMsg,
  getTotalPage
} from '../../../services/redux/selectors/manageProccess';
import { toast } from 'react-toastify';
import { getTitles, resetMessages } from '../../../utils/commonFunction';
import { useFormik } from 'formik';
import { ModalButtons } from '../../../components';
import { diseaseSchema } from '../../../utils/schemas';
import { color, globalStyles } from '../../../theme';
import { UIBackButton } from '../../../ui-kits/back-button';
import { getDetailsPg } from '../../../services/redux/selectors';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const ManageDisease = ({ isReadOnly, isdetailsScreen = () => {}, currPageName }) => {
  const dispatch = useDispatch();
  const diseases = useSelector(getDiseasesList);
  const errorMsg = useSelector(getErrorMsg);
  const successMsg = useSelector(getSuccessMsg);
  const totalPages = useSelector(getTotalPage);
  const currentPage = useSelector(getCurPage);
  const diseaseGroup = useSelector(getMasterList16);
  const diseaseType = useSelector(getMasterList17);
  const diseaseParams = useSelector(getDiseaseParams);
  const detailsPg = useSelector(getDetailsPg);
  const currRender = useSelector(getDiseaseCurrRender);

  const [open, setOpen] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const styles = {
    container: { width: '100%' },
    detailsCont: {
      width: '100%',
      paddingTop: '20px'
    },
    detailsSubCont: {
      backgroundColor: color.primaryBackground,
      display: 'flex',
      flexDirection: res ? 'column' : 'row',
      minHeight: '70vh',
      padding: '10px',
      boxSizing: 'border-box'
    },
    backBtnCont: {
      width: res ? '100%' : 'calc(5% + 10px)',
      textAlign: 'center',
      marginTop: res ? '0px' : '10px'
    },
    rightCont: {
      width: res ? '100%' : 'calc(100% - 100px)',
      marginTop: res ? '0px' : '10px',
      paddingRight: res ? '0px' : '10px',
      boxSizing: 'border-box'
    },
    rightSub: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: res ? 'start' : 'center'
    },
    rightNameBox: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      marginTop: res ? '10px' : '20px'
    },
    rightDText: {
      width: 'fit-content',
      maxWidth: '50%',
      padding: res ? '7px' : '10px',
      boxSizing: 'border-box',
      borderRadius: '5px'
    },
    rightLText: {
      width: res ? '50%' : md ? '30%' : '20%',
      maxWidth: res ? '200px' : md ? '30%' : '20%'
    },
    descHead: {
      width: '100%',
      marginTop: res ? '10px' : '40px'
    },
    detailsDesc: {
      border: `1px solid ${color.primaryBorder}`,
      borderRadius: '5px',
      minHeight: '100px',
      padding: '10px',
      boxSizing: 'border-box',
      marginTop: res ? '10px' : '20px',
      width: res ? '100%' : '80%'
    },
    detailsName: {
      width: '100%',
      overflowWrap: 'anywhere'
    },
    title: {
      overflowWrap: 'anywhere'
    },
    editBtn: {
      padding: res ? '5px' : '5px 40px',
      '&.MuiButton-root': {
        minWidth: '0px'
      },
      width: 'fit-content',
      '& .MuiButton-startIcon': {
        marginRight: res ? '0px' : '8px',
        marginLeft: '0px'
      },
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  };

  const { values, errors, touched, handleBlur, resetForm, setFieldValue, dirty, isValid } =
    useFormik({
      initialValues: {
        name: '',
        description: '',
        type_id: '',
        group_id: ''
      },
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: diseaseSchema
    });

  useEffect(() => {
    if (!detailsPg) {
      dispatch(setDiseaseCurrRender(null));
    }
  }, [detailsPg]);

  useEffect(() => {
    isdetailsScreen(currRender);
  }, [currRender]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (['S-10029', 'S-10030'].includes(successMsg)) handleDisease();
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetManageProcessMsg()));
  }, [successMsg, errorMsg]);

  useEffect(() => {
    if (currRender)
      dispatch(setDiseaseCurrRender(diseases.find((item) => item._id === currRender._id)));
  }, [diseases]);

  useEffect(() => {
    handleDisease({ page: 1 });
    dispatch(
      getDiseaseGroupRequest({
        master_id: defaultValues.masterListType.diseaseGroup,
        filter: JSON.stringify([{ is_active: true }])
      })
    );
    dispatch(
      getDiseaseTypeRequest({
        master_id: defaultValues.masterListType.diseaseType,
        filter: JSON.stringify([{ is_active: true }])
      })
    );
  }, []);

  const handleDisease = (data) => {
    const payload = { ...diseaseParams, ...data };
    dispatch(updateDiseaseParams(payload));
    dispatch(getDiseaseRequest(payload));
  };

  const handleStatus = (id, status) => {
    dispatch(updateDiseaseRequest({ _id: id, is_active: !status }));
  };

  const getType = (id) => {
    const rr = diseaseType.filter((item) => item._id === id);
    return rr[0];
  };
  const getGroup = (id) => {
    const rr = diseaseGroup.filter((item) => item._id === id);
    return rr[0];
  };

  const tableData = diseases.map((item) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-10101'),
          values: (
            <Text
              variant="D20"
              onClick={() => {
                dispatch(updateDetailsPg(true));
                dispatch(setDiseaseCurrRender(item));
              }}
              style={globalStyles.title}>
              {item.name}
            </Text>
          )
        },
        {
          title: getTitles('T-10088'),
          values: <Text variant="D20">{getType(item.type_id) && getType(item.type_id).name}</Text>
        },
        {
          title: getTitles('T-10102'),
          values: (
            <Text variant="D20">{getGroup(item.group_id) && getGroup(item.group_id).name}</Text>
          )
        },
        {
          title: getTitles('T-10103'),
          values: (
            <ActionButton
              status={item.is_active}
              onStatusChange={() => handleStatus(item._id, item.is_active)}
              isReadOnly={isReadOnly}
              alterTitle={defaultValues.confirmationModel.changeDiseaseStatus.title(item.is_active)}
              alterSubTitle={defaultValues.confirmationModel.changeDiseaseStatus.subtitles}
            />
          )
        }
      ];
    } else {
      return {
        title: (
          <Text
            variant="D20"
            onClick={() => {
              dispatch(updateDetailsPg(true));
              dispatch(setDiseaseCurrRender(item));
            }}
            style={globalStyles.title}>
            {item.name}
          </Text>
        ),
        details: [
          {
            subTitle: getTitles('T-10088'),
            action: getType(item.type_id) && getType(item.type_id).name
          },
          {
            subTitle: getTitles('T-10102'),
            action: getGroup(item.group_id) && getGroup(item.group_id).name
          },
          {
            subTitle: getTitles('T-10103'),
            action: (
              <ActionButton
                status={item.is_active}
                onStatusChange={() => handleStatus(item._id, item.is_active)}
                isReadOnly={isReadOnly}
                alterTitle={defaultValues.confirmationModel.changeDiseaseStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.changeDiseaseStatus.subtitles}
              />
            )
          }
        ]
      };
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFormChanged(true);
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    const group = diseaseGroup.find((item) => item._id === data.group_id);
    const type = diseaseType.find((item) => item._id === data.type_id);
    setFieldValue('type_id', type);
    setFieldValue('group_id', group);
    setFieldValue('name', data.name);
    setFieldValue('description', data.description);
    setFieldValue('_id', data._id);
    setOpen(true);
    setFormChanged(false);
  };

  const handleSearch = (data) => {
    handleDisease({ search_text: data, page: null });
  };

  const handleModalClose = () => {
    setOpen(false);
    setIsEdit(false);
    resetForm();
  };

  const handlePagination = (e, data) => {
    handleDisease({ page: data });
  };

  const handleSaveAndClose = () => {
    if (isEdit) {
      updateDisease();
    } else {
      addDisease();
    }
    handleModalClose();
  };

  const handleAddAnother = () => {
    if (isValid || formChanged || dirty) {
      addDisease();
      resetForm();
      setFormChanged(false);
    }
  };

  const addDisease = () => {
    dispatch(
      addDiseaseRequest({ ...values, group_id: values.group_id._id, type_id: values.type_id._id })
    );
  };

  const updateDisease = () => {
    dispatch(
      updateDiseaseRequest({
        ...values,
        group_id: values.group_id._id,
        type_id: values.type_id._id
      })
    );
  };

  return (
    <Box sx={styles.container}>
      {!currRender && (
        <UILayout
          handleAddButton={() => setOpen(true)}
          handleSearch={handleSearch}
          tableTitle={getTitles('T-10099')}
          showNoData={!diseases.length > 0}
          currPage={currentPage}
          pageCount={totalPages}
          handlePagination={handlePagination}
          tableItems={tableData}
          showPagination={diseases.length > 0 && totalPages > 1}
          searchBarPlaceHolder={getTitles('T-10100')}
          showDataTable={!defaultValues.isResponsive}
          message={getErrorMessage('E-10054')}
          isReadOnly={isReadOnly}
          currPageName={currPageName}
        />
      )}
      {currRender && (
        <Box sx={styles.detailsCont}>
          <Box sx={styles.detailsSubCont}>
            <Box sx={styles.backBtnCont}>
              {!defaultValues.isResponsive && (
                <UIBackButton onClick={() => dispatch(setDiseaseCurrRender(null))} />
              )}
            </Box>
            <Box sx={styles.rightCont}>
              <Box sx={styles.rightSub}>
                <Text style={styles.detailsName} variant="boldH32">
                  {currRender.name}
                </Text>
                <UIButton
                  startIcon={<EditIcon />}
                  title={!res && getTitles('T-10084')}
                  onClick={() => handleEdit(currRender)}
                  style={styles.editBtn}
                  disabled={isReadOnly}
                />
              </Box>
              <Box sx={styles.rightNameBox}>
                <Text variant="D24" style={styles.rightLText}>
                  {getTitles('T-10091')}
                </Text>
                <Text
                  variant="D22"
                  style={{ ...styles.rightDText, backgroundColor: color.secondaryCardBackground }}>
                  {getType(currRender.type_id) && getType(currRender.type_id).name}
                </Text>
              </Box>

              <Box sx={styles.rightNameBox}>
                <Text variant="D24" style={styles.rightLText}>
                  {getTitles('T-10106')}
                </Text>
                <Text
                  variant="D22"
                  style={{ ...styles.rightDText, backgroundColor: color.primaryCardBackground }}>
                  {getGroup(currRender.group_id) && getGroup(currRender.group_id).name}
                </Text>
              </Box>

              <Box sx={styles.rightNameBox}>
                <Text variant="D24" style={styles.rightLText}>
                  {getTitles('T-10103')}
                </Text>
                <Text
                  variant="D22"
                  style={{
                    ...styles.rightDText,
                    backgroundColor: currRender.is_active ? color.palette.green : color.errorText,
                    color: color.tertiaryText
                  }}>
                  {currRender.is_active ? getTitles('T-10107') : getTitles('T-10108')}
                </Text>
              </Box>

              {currRender.description && (
                <Box sx={styles.descHead}>
                  <Text variant="D24">{getTitles('T-10109')}</Text>
                  <Text variant="D22" style={styles.detailsDesc}>
                    {currRender.description}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <PopupModal
        showModel={open}
        onClose={handleModalClose}
        title={isEdit ? getTitles('T-10098') : getTitles('T-10097')}>
        <UITextInputWithTitle
          name="name"
          required
          title={getTitles('T-10089')}
          placeHolder={getTitles('T-10090')}
          error={touched.name && errors.name}
          value={values.name}
          variant="D22"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <UIAutocompleted
          title={getTitles('T-10091')}
          name={'type_id'}
          placeHolder={getTitles('T-10092')}
          required={true}
          preSelectedValue={values.type_id}
          options={diseaseType.map((item) => item)}
          optionLabel={(elem) => elem && elem.name}
          variant="D22"
          onChange={(val) => handleChange({ target: { value: val, name: 'type_id' } })}
          touched={touched.type_id}
          onBlur={handleBlur}
          error={touched.type_id && errors.type_id}
        />

        <UIAutocompleted
          title={getTitles('T-10093')}
          name={'group_id'}
          placeHolder={getTitles('T-10094')}
          required={true}
          preSelectedValue={values.group_id}
          options={diseaseGroup.map((item) => item)}
          optionLabel={(elem) => elem && elem.name}
          variant="D22"
          onChange={(val) => handleChange({ target: { value: val, name: 'group_id' } })}
          touched={touched.group_id}
          onBlur={handleBlur}
          error={touched.group_id && errors.group_id}
        />

        <UITextInputWithTitle
          name="description"
          variant="D22"
          title={getTitles('T-10095')}
          placeHolder={getTitles('T-10096')}
          value={values.description}
          onChange={handleChange}
          rows={4}
        />

        {!isEdit && (
          <UISaveAndAdd
            onAddAnother={handleAddAnother}
            disabled={!isValid || !dirty || !formChanged}
          />
        )}
        <ModalButtons
          onPrimaryBtnClick={handleSaveAndClose}
          primaryBtnTitle={isEdit ? getTitles('T-10046') : getTitles('T-10063')}
          disabled={!isValid || !dirty || !formChanged}
        />
      </PopupModal>
    </Box>
  );
};
