import { Box, IconButton } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styles } from './styles';

export const ImageCarousel = ({ images, containerStyles, canvasStyle }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const canvasRef = useRef(null);
  const intervalRef = useRef(null); // Auto-scroll interval reference
  const transitionTimeoutRef = useRef(null); // Manual transition debounce

  // Function to adjust canvas size for high DPI screens
  const adjustCanvasSize = (canvas) => {
    const dpr = window.devicePixelRatio || 1;
    const rect = canvas.getBoundingClientRect();

    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;

    const ctx = canvas.getContext('2d');
    ctx.scale(dpr, dpr);
  };

  // Load image on the canvas
  const loadCanvasImage = (index) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.src = images[index];
    img.onload = () => {
      adjustCanvasSize(canvas);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(
        img,
        0,
        0,
        canvas.width / window.devicePixelRatio,
        canvas.height / window.devicePixelRatio
      );
    };
  };

  // Animate transition
  const animateTransition = (direction) => {
    setIsTransitioning(true);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const startIndex = currentIndex;
    const nextIndex =
      direction === 'left'
        ? currentIndex === 0
          ? images.length - 1
          : currentIndex - 1
        : currentIndex === images.length - 1
          ? 0
          : currentIndex + 1;

    const img1 = new Image();
    const img2 = new Image();
    img1.src = images[startIndex];
    img2.src = images[nextIndex];

    img1.onload = () => {
      img2.onload = () => {
        const duration = 500;
        const startTime = performance.now();

        const step = (timestamp) => {
          const progress = (timestamp - startTime) / duration;
          const position = direction === 'left' ? -progress : progress;

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(
            img1,
            position * (canvas.width / window.devicePixelRatio),
            0,
            canvas.width / window.devicePixelRatio,
            canvas.height / window.devicePixelRatio
          );
          ctx.drawImage(
            img2,
            (position + (direction === 'left' ? 1 : -1)) * (canvas.width / window.devicePixelRatio),
            0,
            canvas.width / window.devicePixelRatio,
            canvas.height / window.devicePixelRatio
          );

          if (progress < 1) {
            requestAnimationFrame(step);
          } else {
            setCurrentIndex(nextIndex);
            setIsTransitioning(false);
          }
        };

        requestAnimationFrame(step);
      };

      img2.onerror = () => setIsTransitioning(false);
    };
    img1.onerror = () => setIsTransitioning(false);
  };

  // Manual transition handlers
  const handlePrevClick = () => {
    if (!isTransitioning) {
      clearAutoScroll(); // Stop auto-scroll after manual interaction
      animateTransition('right');
      restartAutoScroll(5000); // Restart auto-scroll after 5s
    }
  };

  const handleNextClick = () => {
    if (!isTransitioning) {
      clearAutoScroll(); // Stop auto-scroll after manual interaction
      animateTransition('left');
      restartAutoScroll(5000); // Restart auto-scroll after 5s
    }
  };

  // Auto-scroll functionality
  const autoScroll = () => {
    if (!isTransitioning) {
      handleNextClick();
    }
  };

  const startAutoScroll = () => {
    intervalRef.current = setInterval(autoScroll, 3000); // Slower auto-scroll every 3 seconds
  };

  const clearAutoScroll = () => {
    clearInterval(intervalRef.current); // Clear existing interval
  };

  const restartAutoScroll = (delay) => {
    clearAutoScroll();
    transitionTimeoutRef.current = setTimeout(startAutoScroll, delay); // Restart after 5 seconds
  };

  // Initialize canvas and auto-scroll on component mount
  useEffect(() => {
    loadCanvasImage(currentIndex);
    startAutoScroll(); // Start auto-scroll on mount

    return () => {
      clearAutoScroll(); // Cleanup on unmount
      clearTimeout(transitionTimeoutRef.current);
    };
  }, [currentIndex]);

  return (
    <Box style={styles.container(containerStyles)}>
      <canvas ref={canvasRef} style={styles.canvas(canvasStyle)}></canvas>

      <IconButton onClick={handlePrevClick} sx={styles.leftIcon} disabled={isTransitioning}>
        <ArrowBackIosSharpIcon sx={styles.icon} />
      </IconButton>
      <IconButton onClick={handleNextClick} sx={styles.rightIcon} disabled={isTransitioning}>
        <ArrowForwardIosSharpIcon sx={styles.icon} />
      </IconButton>
    </Box>
  );
};
