import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';
const res = defaultValues.isResponsive;
const sm = defaultValues.isMobile;
const md = defaultValues.isMedium;
export const styles = {
  container: {
    width: sm ? '100%' : res ? 'calc((100% - 10px)/2)' : 'calc((100% - 30px)/4)',
    minHeight: '30vh',
    backgroundColor: color.primaryBackground,
    p: 1,
    boxSizing: 'border-box'
  },

  sub1: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  subText2: {
    backgroundColor: color.palette.royalBlue,
    color: color.tertiaryText,
    p: '5px 10px',
    boxSizing: 'border-box',
    borderRadius: '3px',
    cursor: 'pointer'
  },
  subText1: {
    fontWeight: 600
  },
  sub2: {
    width: '100%',
    display: 'flex',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    gap: '10px',
    alignItems: 'center'
  },
  img: {
    width: '100%',
    maxHeight: md ? '50px' : '70px',
    objectFit: 'contain'
  },
  imgCont: {
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    p: 1
  },
  textCont: {
    width: '70%'
  },
  price: {
    fontWeight: '600'
  },
  name: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word'
  },
  sub2super: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    mt: 1
  }
};
