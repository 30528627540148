import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const xsm = defaultValues.isMdSmall;
const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const styles = {
  superContainer: {
    width: '100%',
    p: xsm ? 1 : 2,
    boxSizing: 'border-box',
    backgroundColor: color.primaryBackground,
    borderRadius: '4px',
    mt: 2
  },
  container: {
    width: '100%',
    height: '100%',
    gap: '10px',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    mt: 2
    // position: 'relative'
  },
  subCont: {
    width: res ? '100%' : '50%',
    border: res ? 'none' : `1px solid ${color.primaryBorder}`,
    borderRadius: '5px',
    p: res ? 0 : 2,
    backgroundColor: color.primaryBackground,
    boxSizing: 'border-box'
  },
  width: res ? '100%' : '80%',
  childContainer: (style) => ({
    width: '100%',
    my: 2,
    ...style
  }),
  superSubCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  superCont: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    position: 'relative'
  },
  itemsCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  },
  img: {
    width: res ? '50px' : '70px',
    height: res ? '50px' : '70px'
  },
  detailsCont: {
    width: res ? 'calc(100% - 50px)' : 'calc(100% - 70px)'
  },
  detailsSubCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  secSub: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },

  checkOutBtn: {
    width: '100%',
    height: '45px',
    backgroundColor: color.secondary,
    color: color.primaryText,
    fontWeight: 600,
    '&:hover': {
      bgcolor: color.primary,
      color: color.tertiaryText
    }
  },
  viewCartBtn: (disabled) => ({
    width: '100%',
    maxWidth: '100%',
    height: res ? '35px' : '45px',
    border: `2px solid ${disabled ? color.primaryBackground : color.primary}`,
    backgroundColor: color.primaryBackground,
    color: color.primary,
    '&:hover': {
      bgcolor: color.primary,
      color: color.tertiaryText
    },
    fontWeight: 600,
    mt: 2
  }),
  secondaryText: {
    color: color.secondaryText,
    fontWeight: 600
  },
  btnCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  },
  phone: {
    width: '100%',
    maxWidth: '100%'
  },
  commonContStyle: {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  subTotalCont: {
    width: '100%',
    mt: res ? 'none' : 'auto',
    pt: 2,
    backgroundColor: color.primaryBackground,
    position: res ? 'sticky' : 'static',
    bottom: 0
  },
  price: {
    fontSize: res ? '1.125rem' : md ? '1rem' : '1.375rem',
    fontWeight: 600
  },
  addressCont: (isShipping) => ({
    width: '100%',
    mt: isShipping ? 2 : 0
  }),
  addressSubCont: {
    width: '100%',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    p: 1,
    boxSizing: 'border-box'
  },
  viewDetailsCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '30px'
  },
  viewSub: {
    width: '20%'
  },
  viewTitle: {
    color: color.secondaryText
  },
  payNowCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    mb: 1
  }
};
