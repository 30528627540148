import { defaultValues } from '../../constants';
import { color, globalStyles } from '../../theme';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
const sm = defaultValues.isMobile;

export const styles = {
  container: {
    width: '100%'
  },
  profileIcon: {
    width: '30px',
    height: '30px',
    marginLeft: '5px'
  },
  tableHeaderTextStyle: {
    whiteSpace: 'none',
    textAlign: 'center',
    fontSize: '1.3rem'
  },
  center: {
    textAlign: 'center'
  },
  subCont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  selectContainerStyle: {
    backgroundColor: color.secondaryBackground,
    borderRadius: '5px',
    width: sm ? '100px' : md ? '120px' : '150px',
    minWidth: 'none',
    mx: 0
  },
  dateCont: {
    width: res ? '50%' : md ? '120px' : '150px',
    backgroundColor: color.secondaryBackground,
    mt: 0,
    '& .MuiInputBase-root': {
      height: res ? '30px' : '48px',
      overflow: 'hidden',
      borderRadius: '3px'
    },
    '& .MuiStack-root': {
      pt: '0px'
    }
  },
  dateInput: {
    backgroundColor: color.secondaryBackground
  },
  mb: {
    mb: 1
  },
  assigneeCont: { width: '100%' },
  groupByCont: {
    borderRadius: '5px',
    width: res ? '50%' : md ? '120px' : '150px',
    marginTop: 0
  },
  groupByInput: {
    mt: 0,
    backgroundColor: color.secondaryBackground,
    height: res ? '30px' : '48px'
  },
  addBtnStyle: {
    width: res ? '32px' : md ? '100px' : '200px'
  },
  selectInputStyle: {
    height: res ? '30px' : '48px'
  },
  optionStyles: {
    mb: sm ? 0 : 2
  },
  resItemCont: {
    width: '100%',
    display: 'flex',
    gap: '4px',
    my: 1
  },
  groupTableCont: {
    width: '100%'
  },
  groupBySubCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  viewall: {
    textAlign: 'right',
    ...globalStyles.title
  },
  groupText: {
    color: color.secondaryText
  }
};
