import { defaultValues } from '../../../../constants';

const res = defaultValues.isResponsive;

export const styles = {
  mainCont: {
    p: res ? 1 : 2,
    width: res ? '96%' : '100%',
    borderRadius: '8px',
    marginTop: '20px'
  },
  titleCont: {
    display: 'flex',
    alignItems: 'center'
  },
  backArrowBtn: {
    p: 1,
    marginLeft: '10px'
  },
  btnIcon: {
    fontSize: res ? '25px' : '40px'
  },
  titleTxt: {
    fontSize: res ? '1.5rem' : '2rem',
    fontWeight: 700,
    my: res && 1,
    ml: res && 1
  },
  productTxt: {
    fontWeight: 700,
    fontSize: res ? '16px' : '20px',
    ml: res ? 1 : 2,
    mt: 1
  },
  productInfoCont: {
    width: res ? '70%' : '60%',
    my: 2,
    ml: res ? 1 : 2
  },
  detailCont: {
    mb: 1
  },
  detailTitleTxt: {
    width: res ? '45%' : '25%',
    fontWeight: 600
  },
  detailValueTxt: {
    width: res ? '55%' : '75%'
  },
  col15: '150px',
  col17: '170px',
  describeCol: 'max-content',
  uiCont: {
    paddingTop: 0
  },
  uiSubCont: {
    mt: 0,
    p: res ? '0px 8px' : 0,
    border: 0
  },
  tableSubCont: (isList) => ({
    width: 'fit-content',
    minHeight: isList ? '50vh' : 'none'
  })
};
