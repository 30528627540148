import { Box, Toolbar } from '@mui/material';
import { HeaderStyles } from './styles';
import { KEYS, SCREEN_PATH, defaultValues, lang } from '../../../constants';
import { Text } from '../../../ui-kits';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  authStatus,
  getDetailsPg,
  getEcommerce,
  getNotificationTotalUnreadCount,
  getUserData
} from '../../../services/redux/selectors';
import {
  editProfileRequest,
  setIsEcommerce,
  updatePhone,
  updatePhoneVerified
} from '../../../services/redux/slices/authSlice';
import { checkNumber, getApplicationLanguage } from '../../../utils/commonFunction';
import { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Sidebar } from '../../../ui-kits/side-bar';
import { AuthenticatedHeader } from './authenticated-header';
import { NotAuthenticatedHeader } from './not-authenticated-header';
import { ECommerceHeader } from './e-commerce-header';
import { resetECommerce } from '../../../services/redux/slices';

export const Header = ({ currentPage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = useSelector(authStatus);
  const isEcommerce = useSelector(getEcommerce);
  const userData = useSelector(getUserData);
  const detailsPg = useSelector(getDetailsPg);
  const totalNotification = useSelector(getNotificationTotalUnreadCount);

  const [open, setOpen] = useState(false);
  const [langOpen, setLangOpen] = useState(false);

  useEffect(() => {
    dispatch(
      setIsEcommerce(
        ([SCREEN_PATH.ECOMMERCE].includes(location.pathname) ||
          (isEcommerce &&
            [
              SCREEN_PATH.PROFILE,
              SCREEN_PATH.NOTIFICATION,
              `${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.ORDER_CONFIRMATION}`,
              `${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.CHECKOUT}`,
              `${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.MY_ORDERS}`,
              `${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.SHOPPING_CART}`
            ].includes(location.pathname))) &&
          defaultValues.appLogicalTexts.eCommerce
      )
    );
  }, [location, dispatch]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleDisplayLang = (val) => {
    setLangOpen(val);
  };

  const handleLanguage = (val) => {
    if (isAuthenticated) {
      const payload = {
        ...userData,
        is_shipping_billing_same: userData?.is_shipping_billing_same || false,
        language: val.key
      };
      delete payload.phone_number;
      payload?.address?.address_line_one === ''
        ? (payload.address = null)
        : (payload.address = {
            ...payload.address,
            state_id: payload?.address?.state_id?._id,
            district_id: payload?.address?.district_id?._id,
            taluka_id: payload?.address?.taluka_id?._id,
            village_id: payload?.address?.village_id?._id || null
          });
      !payload?.shipping_address?.address_line_one
        ? (payload.shipping_address = [])
        : (payload.shipping_address = [
            {
              ...payload.shipping_address,
              state_id: payload?.shipping_address?.state_id?._id,
              district_id: payload?.shipping_address?.district_id?._id,
              taluka_id: payload?.shipping_address?.taluka_id?._id,
              village_id: payload?.shipping_address?.village_id?._id || null
            }
          ]);
      dispatch(editProfileRequest(payload));
    } else {
      localStorage.setItem(KEYS.LANGUAGE, val?.value || lang.english);
      window.location.reload();
    }
  };

  const handleTools = (item) => {
    dispatch(updatePhoneVerified(false));
    dispatch(updatePhone(null));
    if (item.url) {
      if (item.url === SCREEN_PATH.ECOMMERCE) {
        window.open(SCREEN_PATH.ECOMMERCE);
        dispatch(setIsEcommerce(defaultValues.appLogicalTexts.eCommerce));
      } else {
        navigate(item.url);
      }
    }

    if (item.href) {
      window.open(item.href);
    }

    dispatch(resetECommerce());
  };

  return (
    <Box sx={HeaderStyles.container(isAuthenticated, isEcommerce)}>
      <Box sx={HeaderStyles.subContainer(isAuthenticated)}>
        <Toolbar sx={HeaderStyles.toolbar(isAuthenticated)} disableGutters>
          {isEcommerce ? (
            <ECommerceHeader
              isAuthenticated={isAuthenticated}
              toggleDrawer={toggleDrawer}
              detailsPg={detailsPg}
              currentPage={currentPage}
              setLangOpen={setLangOpen}
              langOpen={langOpen}
              handleDisplayLang={handleDisplayLang}
              handleLanguage={handleLanguage}
              getApplicationLanguage={getApplicationLanguage}
              userData={userData}
              totalNotification={totalNotification && checkNumber(totalNotification)}
            />
          ) : !isAuthenticated ? (
            <NotAuthenticatedHeader handleTools={handleTools} toggleDrawer={toggleDrawer} />
          ) : (
            <AuthenticatedHeader
              isAuthenticated={isAuthenticated}
              toggleDrawer={toggleDrawer}
              detailsPg={detailsPg}
              currentPage={currentPage}
              setLangOpen={setLangOpen}
              langOpen={langOpen}
              handleDisplayLang={handleDisplayLang}
              handleLanguage={handleLanguage}
              getApplicationLanguage={getApplicationLanguage}
              userData={userData}
              totalNotification={totalNotification && checkNumber(totalNotification)}
            />
          )}
        </Toolbar>
      </Box>
      <Drawer open={open} onClose={toggleDrawer(false)} sx={HeaderStyles.drawer}>
        {isAuthenticated ? (
          <Sidebar />
        ) : (
          <Box sx={{ mt: 2 }}>
            {defaultValues.noAuthToolbar.map((item, idx) => (
              <Box key={idx} sx={HeaderStyles.toolCont} onClick={() => handleTools(item)}>
                <Text variant="D24" style={HeaderStyles.toolOptionsText}>
                  {item.title}
                </Text>
              </Box>
            ))}
          </Box>
        )}
      </Drawer>
    </Box>
  );
};
