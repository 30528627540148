import { defaultValues } from '../../constants';
import { color } from '../../theme';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;

export const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '20px',
    padding: '16px',
    boxSizing: 'border-box',
    backgroundColor: color.primaryBackground,
    mt: 4
  },
  subContainer: { width: res ? '100%' : '50%', display: 'flex', flexDirection: 'column' },
  image: {
    width: '100%',
    maxWidth: res ? '200px' : '400px',
    maxHeight: res ? '200px' : '400px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  myGopCont: {
    width: '100%',
    position: 'relative'
  },
  introText1: {
    color: color.primary,
    position: 'sticky',
    zIndex: 2
  },
  introText1backIcon: {
    width: res ? '150px' : md ? '200px' : '260px',
    height: res ? '60px' : md ? '90px' : '110px',
    position: 'absolute',
    top: res ? 0 : 10,
    left: res ? -8 : '-20px',
    zIndex: 0
  },
  fieldTitleStyle: {
    width: '30%'
  },
  subTitleCont: {
    width: '70%'
  },
  head: {
    mt: 2
  },
  contactNum: {
    maxHeight: '40px',
    p: 0,
    m: 0
  },
  btn: {
    width: res ? '100%' : '300px',
    mx: 'auto',
    height: '45px',
    mt: 2
  },
  divider: {
    mt: 2
  }
};
