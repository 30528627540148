import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  getFarmerActivePage,
  getFarmerCurPage,
  getFarmerErrorMsg,
  getFarmerLoading,
  getFarmerSuccessMsg,
  getFarmerTotalPage,
  getFarmersList
} from '../../../services/redux/selectors/farmerSelector';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import {
  checkReadOnly,
  getFullName,
  getTitles,
  resetMessages
} from '../../../utils/commonFunction';
import { BasicProfile } from '../../../components';
import { ActionButton, Screen, Text, UILayout } from '../../../ui-kits';
import {
  setActionType,
  getFarmersRequest,
  resetFarmerMsg,
  setCurrentActiveFarmerPage,
  updateFarmerRequest,
  updateDetailsPg,
  setFarmeDetails,
  setFarmerData,
  setFarmerCompletedStep
} from '../../../services/redux/slices';
import { toast } from 'react-toastify';
import assignIcon from '../../../assets/icons/share_windows.png';
import { getUserData } from '../../../services/redux/selectors';

export const ManageFarmer = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const farmersList = useSelector(getFarmersList);
  const success = useSelector(getFarmerSuccessMsg);
  const error = useSelector(getFarmerErrorMsg);
  const isLoading = useSelector(getFarmerLoading);
  const totalPages = useSelector(getFarmerTotalPage);
  const currentPage = useSelector(getFarmerCurPage);
  const activePage = useSelector(getFarmerActivePage);
  const userData = useSelector(getUserData);

  const [farmers, setFarmers] = useState([]);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [hasAccess, setAcess] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(null);

  useEffect(() => {
    dispatch(
      getFarmersRequest({
        page: activePage && activePage.page,
        sort: activePage && activePage.sort,
        filter: activePage && activePage.filter
      })
    );
    dispatch(setCurrentActiveFarmerPage(null));
  }, []);

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.farmerManager));
  }, [userData]);

  useEffect(() => {
    if (farmersList) {
      setFarmers(farmersList);
    }
  }, [farmersList]);

  useEffect(() => {
    if (userData) {
      if (userData.user_role === defaultValues.userRole.superAdmin) {
        setAcess(true);
      } else if (userData.user_role === defaultValues.userRole.employee) {
        const list =
          userData.employee_permissions &&
          userData.employee_permissions.filter(
            (item) => item.permission_id === defaultValues.permissionsIds.FarmerManager
          );

        const hasApprovePermission = list && list.some((item) => item.has_approve_permission);
        if (hasApprovePermission) {
          setAcess(true);
        }
      }
    }
  }, [userData]);

  useEffect(() => {
    if (success === 'S-10044') {
      toast.success(getSuccessMessage(success));
      dispatch(
        getFarmersRequest({
          page: currentPage,
          filter: JSON.stringify(filter),
          sort: sort,
          search_text: searchText
        })
      );
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }

    resetMessages(() => dispatch(resetFarmerMsg()));
  }, [success, error]);

  const handleFilter = (data) => {
    setFilter(data);
    dispatch(getFarmersRequest({ filter: JSON.stringify(data), sort: sort }));
  };

  const handleSort = (data) => {
    setSort(data);
    dispatch(getFarmersRequest({ sort: data, filter: JSON.stringify(filter) }));
  };

  const handleSearch = (data) => {
    setSearchText(data);
    dispatch(getFarmersRequest({ search_text: data }));
  };

  const handleRefresh = () => {
    setSort(null);
    setFilter([]);
    setSearchText(null);
    dispatch(getFarmersRequest());
  };

  const handlePagination = (e, data) => {
    dispatch(getFarmersRequest({ page: data, sort: sort, filter: JSON.stringify(filter) }));
  };

  const handleAdd = () => {
    dispatch(setActionType(null));
    dispatch(updateDetailsPg(true));
    dispatch(setCurrentActiveFarmerPage(null));
    dispatch(setFarmerData(null));
    dispatch(setFarmerCompletedStep(null));
    navigation(SCREEN_PATH.REGISTER_FARMER);
  };

  const handleStatus = (_id, is_active) => {
    dispatch(
      updateFarmerRequest({
        _id,
        is_active: !is_active
      })
    );
  };

  const handleApproval = (_id, status) => {
    dispatch(
      updateFarmerRequest({
        _id,
        approval_status: status
      })
    );
  };

  const styles = {
    id: {
      textOverflow: 'ellipsis',
      width: '100%',
      overflowWrap: 'break-word'
    },
    columnWidth: '23%',
    assign: {
      padding: defaultValues.isResponsive ? '0px' : '5px 20px',
      boxSizing: 'border-box',
      height: defaultValues.isResponsive ? '15px' : '25px',
      cursor: 'pointer'
    }
  };

  const tableData = farmers.map((item) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-10438'),
          values: (
            <Text style={styles.id} variant="D20">
              {item.farmer_id}
            </Text>
          ),
          columnWidth: '10%'
        },
        {
          title: getTitles('T-10081'),
          columnWidth: styles.columnWidth,
          values: (
            <BasicProfile
              title={getFullName([item.first_name, item.middle_name, item.last_name])}
              url={item.id_photo}
              textVariant="D22"
              onTitleClick={() => {
                dispatch(setActionType('view'));
                dispatch(updateDetailsPg(true));
                dispatch(
                  setCurrentActiveFarmerPage({
                    page: currentPage,
                    sort: sort,
                    filter: JSON.stringify(filter)
                  })
                );
                navigation(SCREEN_PATH.REGISTER_FARMER, { state: { farmer_id: item.farmer_id } });
              }}
            />
          )
        },
        {
          title: getTitles('T-10440'),
          values: <Text variant="D20">{item.phone_number}</Text>,
          columnWidth: '22%'
        },
        {
          title: getTitles('T-10734'),
          values: <Text variant="D20">{item.village}</Text>,
          columnWidth: '16%'
        },
        {
          title: getTitles('T-10682'),
          values: (
            <ActionButton
              alterTitle={defaultValues.confirmationModel.changeAdminStatus.title(item.is_active)}
              alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
              status={item.is_active}
              isReadOnly={isReadOnly}
              onStatusChange={() => handleStatus(item._id, item.is_active)}
            />
          ),
          columnWidth: '10%'
        },
        {
          title: getTitles('T-10681'),
          values: (
            <ActionButton
              showImg={
                item.approval_status === defaultValues.approvalStatus.approved ||
                item.approval_status === defaultValues.approvalStatus.rejected
              }
              pending={item.approval_status === defaultValues.approvalStatus.pending}
              alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
              status={item.approval_status === defaultValues.approvalStatus.approved}
              onStatusChange={() => {
                let newStatus;
                if (
                  item.approval_status === defaultValues.approvalStatus.pending ||
                  item.approval_status === defaultValues.approvalStatus.rejected
                ) {
                  newStatus = defaultValues.approvalStatus.approved;
                } else if (item.approval_status === defaultValues.approvalStatus.approved) {
                  newStatus = defaultValues.approvalStatus.rejected;
                }
                handleApproval(item._id, newStatus);
              }}
              alterTitle={defaultValues.confirmationModel.changeFarmerApprovalStatus.title(
                item.approval_status
              )}
              isReadOnly={!hasAccess}
            />
          ),
          columnWidth: '10%'
        },
        {
          title: getTitles('T-10173'),
          columnWidth: '10%',
          values: (
            <img
              src={assignIcon}
              style={styles.assign}
              onClick={() => {
                dispatch(
                  setCurrentActiveFarmerPage({
                    page: currentPage,
                    sort: sort,
                    filter: JSON.stringify(filter)
                  })
                );
                dispatch(
                  setFarmeDetails({
                    farmer_id: item._id,
                    name: [item.first_name, item.middle_name, item.last_name].join(' '),
                    photo: item.id_photo,
                    phone: item.phone_number,
                    status: item.is_active,
                    approvalStatus: item.approval_status
                  })
                );
                navigation(SCREEN_PATH.MANAGE_CATTLE, {
                  state: {
                    farmer_id: item._id,
                    name: [item.first_name, item.middle_name, item.last_name].join(' '),
                    photo: item.id_photo
                  }
                });
              }}
            />
          )
        }
      ];
    } else {
      return {
        title: (
          <BasicProfile
            title={getFullName([item.first_name, item.middle_name, item.last_name])}
            url={item.id_photo}
            textVariant="D22"
            onTitleClick={() => {
              dispatch(setActionType('view'));
              dispatch(updateDetailsPg(true));
              dispatch(
                setCurrentActiveFarmerPage({
                  page: currentPage,
                  sort: JSON.stringify(sort),
                  filter: JSON.stringify(filter)
                })
              );
              navigation(SCREEN_PATH.REGISTER_FARMER, {
                state: {
                  farmer_id: item.farmer_id
                }
              });
            }}
          />
        ),
        details: [
          {
            subTitle: getTitles('T-10751'),
            action: <Text variant="D20">{item.farmer_id}</Text>
          },
          {
            subTitle: getTitles('T-10440'),
            action: <Text variant="D20">{item.phone_number}</Text>
          },
          {
            subTitle: getTitles('T-10734'),
            action: <Text variant="D20">{item.village}</Text>
          },
          {
            subTitle: getTitles('T-10682'),
            action: (
              <ActionButton
                alterTitle={defaultValues.confirmationModel.changeAdminStatus.title(item.is_active)}
                alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
                status={item.is_active}
                onStatusChange={() => handleStatus(item._id, item.is_active, 1)}
                isReadOnly={isReadOnly}
              />
            )
          },
          {
            subTitle: getTitles('T-10681'),
            action: (
              <ActionButton
                showImg={
                  item.approval_status === defaultValues.approvalStatus.approved ||
                  item.approval_status === defaultValues.approvalStatus.rejected
                }
                pending={item.approval_status === defaultValues.approvalStatus.pending}
                alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
                status={item.approval_status === defaultValues.approvalStatus.approved}
                onStatusChange={() => {
                  let newStatus;
                  if (
                    item.approval_status === defaultValues.approvalStatus.pending ||
                    item.approval_status === defaultValues.approvalStatus.rejected
                  ) {
                    newStatus = defaultValues.approvalStatus.approved;
                  } else if (item.approval_status === defaultValues.approvalStatus.approved) {
                    newStatus = defaultValues.approvalStatus.rejected;
                  }
                  handleApproval(item._id, newStatus);
                }}
                alterTitle={defaultValues.confirmationModel.changeFarmerApprovalStatus.title(
                  item.approval_status
                )}
                isReadOnly={!hasAccess}
              />
            )
          },

          {
            subTitle: getTitles('T-10173'),
            action: (
              <img
                src={assignIcon}
                style={styles.assign}
                onClick={() => {
                  dispatch(
                    setCurrentActiveFarmerPage({
                      page: currentPage,
                      sort: sort,
                      filter: JSON.stringify(filter)
                    })
                  );
                  dispatch(
                    setFarmeDetails({
                      farmer_id: item._id,
                      name: [item.first_name, item.middle_name, item.last_name].join(' '),
                      photo: item.id_photo,
                      phone: item.phone_number,
                      status: item.is_active,
                      approvalStatus: item.approval_status
                    })
                  );
                  navigation(SCREEN_PATH.MANAGE_CATTLE, {
                    state: {
                      farmer_id: item._id,
                      name: [item.first_name, item.middle_name, item.last_name].join(' '),
                      photo: item.id_photo
                    }
                  });
                }}
              />
            )
          }
        ]
      };
    }
  });

  return (
    <Screen
      showSideBar={true}
      menus={defaultValues.superAdminMenu(userData.user_role === 1)}
      isLoading={isLoading}
      currentPage={getTitles('T-10117')}>
      <UILayout
        isMultiSelectFilterOption={true}
        currPageName={getTitles('T-10117')}
        optionsForSorting={defaultValues.farmerSortOptions}
        isReadOnly={isReadOnly}
        numFilter={true}
        optionsForFiltering={defaultValues.farmerFilterOption}
        searchBarPlaceHolder={getTitles('T-10325')}
        handleFilter={handleFilter}
        handleAddButton={handleAdd}
        handleRefresh={handleRefresh}
        handleSort={handleSort}
        handleSearch={handleSearch}
        clearSearch={filter}
        tableItems={tableData}
        showNoData={!farmers.length > 0}
        currPage={currentPage}
        pageCount={totalPages}
        currentlyActiveFilterOption={filter}
        currentlyActiveSortOption={sort}
        showPagination={totalPages > 1}
        handlePagination={handlePagination}
        showDataTable={!defaultValues.isResponsive}
        message={getErrorMessage('E-10073')}
      />
    </Screen>
  );
};
