import { color } from '../../../theme';

export const styles = {
  container: {
    width: '100%'
  },
  profileIcon: {
    width: '30px',
    height: '30px',
    marginLeft: '5px'
  },
  tableHeaderTextStyle: {
    whiteSpace: 'none',
    textAlign: 'center',
    fontSize: '1.3rem'
  },
  center: {
    textAlign: 'center'
  },
  subCont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  selectContainerStyle: {
    backgroundColor: color.secondaryBackground,
    borderRadius: '5px'
  },
  dateCont: {
    width: '175px',
    backgroundColor: color.secondaryBackground,
    mt: 0,
    '& .MuiInputBase-root': {
      height: '48px'
    },
    '& .MuiStack-root': {
      pt: '0px'
    }
  },
  dateInput: {
    backgroundColor: color.secondaryBackground
  },
  mb: {
    mb: 1
  },
  assigneeCont: { width: '100%' }
};
