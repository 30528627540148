import { useDispatch, useSelector } from 'react-redux';
import {
  getInventoryCurPage,
  getInventorySuccessMsg,
  getInventoryTotalPage,
  getOutwardActionType,
  getOutwardMaterialGroup,
  getOutwardProducts
} from '../../../../services/redux/selectors';
import { useEffect, useState } from 'react';
import { defaultValues, getErrorMessage } from '../../../../constants';
import { Text, UILayout } from '../../../../ui-kits';
import { getFormatDate, getTitles } from '../../../../utils';
import {
  getOutwardProductListRequest,
  setOutwardActionType,
  setOutwardAddType,
  setOutwardMaterialGroup,
  setOutwardProductData,
  updateDetailsPg
} from '../../../../services/redux/slices';
import { BasicProfile } from '../../../common/utility-components';
import { AddOutwardAdjustment } from '../add-outward-adjustment';
import { styles } from './style';
import { OutwardProductHistory } from '../outward-view-history';

export const ManageOutward = ({ isReadOnly }) => {
  const dispatch = useDispatch();

  const { semenStraw } = defaultValues.inventoryMaterialGroupId;
  const { add, view } = defaultValues.actionType;
  const { outward, adjustment } = defaultValues.productEntryType;
  const { oldest } = defaultValues.sortOptionsObj;

  const productList = useSelector(getOutwardProducts);
  const totalPages = useSelector(getInventoryTotalPage);
  const success = useSelector(getInventorySuccessMsg);
  const currentPage = useSelector(getInventoryCurPage);
  const materialGroup = useSelector(getOutwardMaterialGroup);
  const actionType = useSelector(getOutwardActionType);

  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);

  useEffect(() => {
    if (success === 'S-10127' || success === 'S-10129') {
      handleRefresh();
    }
  }, [success]);

  const handleSearch = (data) => {
    handleProductList({ search_text: data || null });
  };

  const handleProductList = (data) => {
    let payload = { material_group: materialGroup, sort: oldest, ...data };
    dispatch(getOutwardProductListRequest(payload));
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    handleProductList({ page: 1, sort: oldest });
  };

  const handleAddView = (idx, type) => {
    getParticularProduct(idx);
    if (type !== view) {
      dispatch(setOutwardActionType(add));
      dispatch(setOutwardAddType(type));
    } else {
      dispatch(setOutwardActionType(view));
      dispatch(updateDetailsPg(true));
    }
  };

  const getParticularProduct = (idx) => {
    const specificProduct = productList.find((ele, index) => index === idx);
    dispatch(setOutwardProductData(specificProduct));
  };

  const handleSort = (data) => {
    setSort(data);
    handleProductList({ page: 1, sort: data, filter: JSON.stringify(filter) });
  };

  const handleFilter = (data) => {
    setFilter(data);
    handleProductList({
      page: 1,
      filter: JSON.stringify(data),
      sort: sort
    });
  };

  const handlePagination = (_, data) => {
    handleProductList({ page: data });
  };

  const handleChangeMaterialGroupList = (data) => {
    handleProductList({ material_group: data, sort: oldest, page: 1 });
    dispatch(setOutwardMaterialGroup(data));
  };

  const tableData = productList.map((item, index) => [
    {
      title: getTitles('T-10438'),
      values: item.product_master?.product_id,
      columnWidth: styles.col12
    },
    {
      title: getTitles('T-11437'),
      values: item.product_master?.name,
      columnWidth: styles.col17
    },
    {
      title: getTitles('T-11471'),
      values: item?.category_enum_name,
      columnWidth: styles.col15
    },
    {
      title: getTitles('T-11472'),
      values: `${item?.product_type || ' - '}`,
      columnWidth: styles.col17
    },
    {
      title: getTitles('T-11463'),
      values:
        item?.balance_unit === 0 ? (
          ' - '
        ) : (
          <Text style={styles.viewTxt} onClick={() => handleAddView(index, outward)}>
            {getTitles('T-10444')}
          </Text>
        ),

      columnWidth: styles.col12
    },
    {
      title: getTitles('T-11464'),
      values:
        item?.balance_unit === 0 ? (
          ' - '
        ) : (
          <Text style={styles.viewTxt} onClick={() => handleAddView(index, adjustment)}>
            {getTitles('T-10444')}
          </Text>
        ),

      columnWidth: styles.col15
    },
    {
      title: getTitles('T-11481'),
      values: (
        <Text style={styles.viewTxt} onClick={() => handleAddView(index, view)}>
          {item?.batch_number}
        </Text>
      ),
      columnWidth: styles.col17
    },
    {
      title: getTitles('T-11421'),
      values: item.store ? (
        <BasicProfile isIcon={false} title={item.store?.name} subTitle={item.store?.store_id} />
      ) : (
        ' - '
      ),
      columnWidth: styles.col17
    },
    {
      title: getTitles('T-11163'),
      values: item.employee ? (
        <BasicProfile
          isIcon={false}
          title={item.employee?.name}
          subTitle={item.employee?.employee_id}
        />
      ) : (
        ' - '
      ),
      columnWidth: styles.col17
    },
    materialGroup !== semenStraw && {
      title: getTitles('T-11494'),
      values: item?.brand_name,
      columnWidth: styles.col17
    },
    materialGroup !== semenStraw && {
      title: getTitles('T-10178'),
      values: `${item?.color || ' - '}`,
      columnWidth: styles.col12
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11473'),
      values: item?.breed,
      columnWidth: styles.col15
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11474'),
      values: item?.variety,
      columnWidth: styles.col15
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11475'),
      values: item?.producer_name,
      columnWidth: styles.col20
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11476'),
      values: item?.bull_number,
      columnWidth: styles.col12
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11477'),
      values: item?.bull_name,
      columnWidth: styles.col15
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11478'),
      values: `${item?.bull_type}`,
      columnWidth: styles.col15
    },
    materialGroup === semenStraw && {
      title: getTitles('T-11479'),
      values: getFormatDate(item?.year_production),
      columnWidth: styles.col22
    },
    {
      title: getTitles('T-11449'),
      values: item.vendor?.name,
      columnWidth: styles.col17
    },
    {
      title: getTitles('T-11448'),
      values: item.vendor?.vendor_id,
      columnWidth: styles.col17
    },
    {
      title: getTitles('T-11480'),
      values: `${item?.balance_unit}`,
      columnWidth: styles.col15
    },
    {
      title: getTitles('T-11482'),
      values: getFormatDate(item?.bill_date),
      columnWidth: styles.col15
    },
    {
      title: getTitles('T-11483'),
      values: getFormatDate(item?.manufacturing_date),
      columnWidth: styles.col22
    },
    {
      title: getTitles('T-10743'),
      values: getFormatDate(item?.expiry_date),
      columnWidth: styles.col15
    },
    {
      title: getTitles('T-11484'),
      values: `${item?.mrp_pack || ' - '}`,
      columnWidth: styles.col12
    },
    {
      title: getTitles('T-11485'),
      values: `${item?.final_price || ' - '}`,
      columnWidth: styles.col22
    },
    {
      title: getTitles('T-11486'),
      values: `${item?.discount || ' - '}`,
      columnWidth: styles.col12
    }
  ]);

  return (
    <>
      {actionType !== view && (
        <UILayout
          handleSearch={handleSearch}
          isMultiSelectFilterOption={true}
          searchBarPlaceHolder={getTitles('T-11470')}
          disableSearch={!materialGroup}
          handleFilter={materialGroup && handleFilter}
          handleRefresh={materialGroup && handleRefresh}
          handleSort={materialGroup && handleSort}
          optionsForSorting={defaultValues.farmerSortOptions}
          optionsForFiltering={defaultValues.outwardFilterOptions}
          tableItems={materialGroup && tableData}
          showNoData={!productList.length > 0}
          message={materialGroup ? getErrorMessage('E-10028') : getTitles('T-10086')}
          subMessage={!materialGroup && getTitles('T-10085')}
          options={defaultValues.inventoryMaterialGroupOptions}
          selectedOption={handleChangeMaterialGroupList}
          selectValue={materialGroup}
          isReadOnly={isReadOnly}
          tableSuperSubCont={styles.tableSubCont(productList.length > 0)}
          currentlyActiveFilterOption={filter}
          currentlyActiveSortOption={sort}
          currPage={currentPage}
          pageCount={totalPages}
          handlePagination={handlePagination}
          showPagination={productList.length > 0 && totalPages > 1}
          clearSearch={filter}
          resSelect={true}
          isSelect={false}
          showSelect={false}
          showTableHeader={true}
        />
      )}
      {actionType === add && <AddOutwardAdjustment isReadOnly={isReadOnly} />}
      {actionType === view && <OutwardProductHistory />}
    </>
  );
};
