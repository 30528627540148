import { color } from '../../../../theme';

export const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: 2,
    boxSizing: 'border-box'
  },
  subCont: {
    width: '60%',
    borderRadius: '4px',
    backgroundColor: color.palette.lightGreen,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    p: 2,
    boxSizing: 'border-box'
  },
  secondaryText: {
    color: color.secondaryText
  },
  btn: {
    height: '40px',
    mt: 2
  }
};
