import React from 'react';
import { Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Text } from '../text';
import { color } from '../../theme';
import { useNavigate } from 'react-router-dom';
import { defaultValues } from '../../constants';

export const NavigationText = ({ list }) => {
  const navigation = useNavigate();
  const style = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      p: 1,
      alignItems: 'center',
      flexDirection: defaultValues.isResponsive ? 'column' : 'row',
      mt: 1
    },
    text: (index) => ({
      color: index < list.length - 1 && color.link,
      cursor: index < list.length - 1 ? 'pointer' : 'default',
      textDecoration: index < list.length - 1 && 'underline'
    })
  };

  return (
    <Box sx={style.container}>
      {list &&
        list.map((item, index) => (
          <React.Fragment key={index}>
            <Text
              variant="D24"
              style={style.text(index)}
              onClick={() => item.route && navigation(item.route, item.state)}>
              {item.title}
            </Text>
            {index < list.length - 1 && !defaultValues.isResponsive && (
              <NavigateNextIcon color={index < list.length - 2 ? 'primary' : ''} />
            )}
          </React.Fragment>
        ))}
    </Box>
  );
};
