import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const sm = defaultValues.isMobile;
const res = defaultValues.isResponsive;
export const styles = {
  bannerCont: {
    width: '100%',
    p: 2,
    boxSizing: 'border-box'
  },

  equipCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    mt: res ? 1 : 3
  },
  equipLeft: {
    background: 'linear-gradient(90deg, #FAEEBD 0%, #B5E8B5 100%)'
  },
  leftHint: {
    backgroundColor: color.palette.royalBlue,
    color: color.primaryBackground
  },
  equip: {
    background: 'linear-gradient(90deg, #FAEEBD 0%, #B5E8B5 100%)',
    height: '150px'
  },
  rightHint: {
    backgroundColor: color.secondary
  },
  catCont: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    mt: res ? 1 : 3,
    p: res ? 1 : 3,
    boxSizing: 'border-box',
    gap: '10px',
    backgroundColor: color.primaryBackground
  },
  machine1: {
    width: '30%',
    position: 'absolute',
    right: 90,
    bottom: -20
  },
  machine2: {
    width: '45%',
    maxWidth: 'none'
  },
  priceStyle: {
    left: sm ? 10 : 30,
    bottom: sm ? 10 : res ? 0 : 20,
    height: sm ? '50px' : '70px',
    width: sm ? '50px' : '70px',
    borderWidth: sm ? '3px' : '8px'
  },
  tabCont: {
    justifyContent: 'start'
  }
};
