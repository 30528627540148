import { Box } from '@mui/material';
import { ManageState } from '../manageState';
import { defaultValues, SCREEN_PATH } from '../../constants';
import { Screen } from '../../ui-kits';
import { useSelector } from 'react-redux';
import { processLoading } from '../../services/redux/selectors/manageProccess';
import { checkReadOnly, getTitles } from '../../utils';
import { getUserData } from '../../services/redux/selectors';
import { useEffect, useState } from 'react';

const styles = {
  container: {
    width: '100%'
  }
};

export const ManageVillageForVillageManager = () => {
  const isLoading = useSelector(processLoading);
  const userData = useSelector(getUserData);

  const [isReadOnly, setIsReadOnly] = useState(false);

  const { villageManager } = defaultValues.employeeRol;

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, villageManager));
  }, [userData]);

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-11383')}>
      <Box sx={styles.container}>
        <ManageState
          isReadOnly={isReadOnly}
          access={defaultValues.employeeRol.villageManager}
          parentRoute={SCREEN_PATH.MANAGE_VILLAGE_FOR_VILLAGE_MANAGER}
          renderType={defaultValues.appLogicalTexts.village}
        />
      </Box>
    </Screen>
  );
};
