import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  currentPage: null,
  nextPage: null,
  totalPage: null,
  societysList: [],
  societyParams: {},
  currentRender: null,
  currSocietyData: null,
  milk_supplied: [],
  mode: null,
  completedStep: null,
  farmerNextPage: null,
  farmerTotalPage: null,
  farmerCurrenetPage: null,
  associatedFarmers: [],
  activeStep: 0,
  isInit: false
};

const societySlice = createSlice({
  initialState,
  name: 'society',
  reducers: {
    resetSociety(state) {
      state.isLoading = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
      state.societysList = [];
      state.societyParams = {};
      state.currentRender = null;
      state.currSocietyData = null;
      state.milk_supplied = [];
      state.mode = null;
      state.completedStep = null;
      state.farmerNextPage = null;
      state.farmerTotalPage = null;
      state.farmerCurrenetPage = null;
      state.associatedFarmers = [];
      state.activeStep = 0;
      state.isInit = false;
    },
    setSocietyIsInit(state, action) {
      state.isInit = action.payload;
    },
    resetSocietyMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
    },
    setSocietyActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    updateSocietyMode(state, action) {
      state.mode = action.payload;
    },
    updateSocietyParams(state, action) {
      state.societyParams = action.payload;
    },
    updateSocietyCurrentRender(state, action) {
      state.currentRender = action.payload;
    },
    updateSocietyCompletedStep(state, action) {
      state.completedStep = action.payload;
    },
    updateCurrSocietyData(state, action) {
      state.currSocietyData = action.payload;
    },
    addSocietyRequest(state) {
      state.isLoading = true;
    },
    addSocietySuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.currSocietyData = action.payload.data;
      state.completedStep = action.payload.data.step_number;
    },
    addSocietyFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateSocietyRequest(state) {
      state.isLoading = true;
    },
    updateSocietySuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.completedStep = action.payload.data.step_number;
      state.currSocietyData = { ...state.currSocietyData, ...action.payload.data };
    },
    updateSocietyFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getSocietyRequest(state) {
      state.isLoading = true;
      state.societysList = [];
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
    },
    getSocietySuccess(state, action) {
      state.isLoading = false;
      state.societysList = action.payload.data.society;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
      state.associatedFarmers = [];
    },
    getSocietyFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.societysList = [];
    },
    getSocietyDetailsRequest(state) {
      state.isLoading = true;
    },
    getSocietyDetailsSuccess(state, action) {
      state.isLoading = false;
      state.currSocietyData = { ...state.currSocietyData, ...action.payload.data };
      state.completedStep = action.payload.data.step_number;
      state.successMessage = action.payload.status_code;
    },
    getSocietyDetailsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.currSocietyData = null;
    },
    getMilkSuppliedRequest(state) {
      state.isLoading = true;
    },
    getMilkSuppliedSuccess(state, action) {
      state.isLoading = false;
      state.milk_supplied = action.payload.data;
    },
    getMilkSuppliedFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.milk_supplied = [];
    },
    getAssociatedFarmerRequest(state) {
      state.isLoading = true;
    },
    getAssociatedFarmerSuccess(state, action) {
      state.isLoading = false;
      state.associatedFarmers = action.payload.data.farmers;
      state.farmerCurrenetPage = action.payload.data.current_page;
      state.farmerNextPage = action.payload.data.next_page;
      state.farmerTotalPage = action.payload.data.total_page;
    },
    getAssociatedFarmerFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.associatedFarmers = [];
      state.farmerCurrenetPage = null;
      state.farmerNextPage = null;
      state.farmerTotalPage = null;
    }
  }
});

export const {
  resetSociety,
  resetSocietyMessages,
  updateSocietyParams,
  updateSocietyMode,
  updateSocietyCompletedStep,
  addSocietyFailure,
  addSocietyRequest,
  addSocietySuccess,
  updateSocietyCurrentRender,
  updateCurrSocietyData,
  updateSocietyFailure,
  updateSocietyRequest,
  updateSocietySuccess,
  getSocietyFailure,
  getSocietyRequest,
  getSocietySuccess,
  getMilkSuppliedFailure,
  getMilkSuppliedRequest,
  getMilkSuppliedSuccess,
  getSocietyDetailsFailure,
  getSocietyDetailsRequest,
  getSocietyDetailsSuccess,
  getAssociatedFarmerFailure,
  getAssociatedFarmerRequest,
  getAssociatedFarmerSuccess,
  setSocietyActiveStep,
  setSocietyIsInit
} = societySlice.actions;

export default societySlice.reducer;
