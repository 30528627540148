import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getDetailsPg,
  getLabFarmerDetails,
  getLabFarmerErrorMsg,
  getLabFarmerLoading,
  getLabFarmerSuccessMsg,
  getLabInchargeCattleDetails
} from '../../../../services/redux/selectors';
import { toast } from 'react-toastify';
import {
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
  SCREEN_PATH
} from '../../../../constants';
import {
  getlabInchargeCattleDetailsRequest,
  resetLabFarmerMsg,
  setLabInchargeCattleReport,
  updateDetailsPg
} from '../../../../services/redux/slices';
import { getFormatDate, getTitles, resetMessages } from '../../../../utils';
import { Screen, Text, UILayout } from '../../../../ui-kits';
import { styles } from './styles';
import { Box, Divider, Grid, Paper } from '@mui/material';
import { BasicProfile } from '../../../common/utility-components';
import { UIBackButton } from '../../../../ui-kits/back-button';
import { DetailsCardImg } from '../../../common';
import cowImg from '../../../../assets/images/profile.jpg';
import { globalStyles } from '../../../../theme';

export const LabInchargeCattleDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const cattleDetails = useSelector(getLabInchargeCattleDetails);
  const farmerDetails = useSelector(getLabFarmerDetails);
  const errorMsg = useSelector(getLabFarmerErrorMsg);
  const successMsg = useSelector(getLabFarmerSuccessMsg);
  const isLoading = useSelector(getLabFarmerLoading);
  const showBackButton = useSelector(getDetailsPg);

  useEffect(() => {
    if (params.cattle_id) {
      let cattle_id = params.cattle_id;
      dispatch(getlabInchargeCattleDetailsRequest({ cattle_id }));
    }
  }, [params]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetLabFarmerMsg()));
  }, [successMsg, errorMsg]);

  useEffect(() => {
    if (!showBackButton) {
      navigateToCattleList();
    }
  }, [showBackButton]);

  const navigateToCattleList = () => {
    navigate(`${SCREEN_PATH.LAB_FARMER_CATTLE_LIST}/${farmerDetails?._id}`);
    dispatch(updateDetailsPg(true));
  };

  const navigateToReports = () => {
    navigate(`${SCREEN_PATH.LAB_FARMER_CATTLE_REPORTS}/${cattleDetails?._id}`);
    dispatch(updateDetailsPg(true));
    dispatch(setLabInchargeCattleReport(null));
  };

  const tableData =
    cattleDetails?.tasks?.length > 0 &&
    cattleDetails?.tasks.map((item, index) => {
      if (!defaultValues.isResponsive) {
        return [
          {
            title: getTitles('T-11407'),
            values: (
              <Text variant="D22" style={{ ml: 2 }}>
                {++index}
              </Text>
            )
          },
          {
            title: getTitles('T-11264'),
            values: <Text variant="D22">{item?.task_name}</Text>
          },
          {
            title: getTitles('T-11248'),
            values: <Text variant="D22">{item?.task_type?.name}</Text>
          },
          {
            title: getTitles('T-11251'),
            values: <Text variant="D22">{getFormatDate(item?.due_date)}</Text>
          },
          {
            title: getTitles('T-11100'),
            values: (
              <Text variant="D22" style={globalStyles.title} onClick={navigateToReports}>
                {getTitles('T-11100')}
              </Text>
            )
          }
        ];
      } else {
        return {
          title: (
            <Box component={'div'} sx={styles.tableItem}>
              <BasicProfile
                title={`${++index}. ${item?.task_name}`}
                subTitle={`${getTitles('T-11248')}- ${item?.task_type?.name}`}
                titleStyle={styles.profileTitle}
                subTitleStyle={styles.profileSubTitle}
                isIcon={false}
              />
              <Box sx={styles.itemCont}>
                <Text variant="D20" style={styles.tableDate} onClick={navigateToReports}>
                  {getTitles('T-11100')}
                </Text>
                <Text variant="D20">{getFormatDate(item?.due_date)}</Text>
              </Box>
            </Box>
          ),
          details: []
        };
      }
    });

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-10981')}>
      {defaultValues.isResponsive && (
        <Text variant="boldH48" style={styles.resPageTitle}>
          {getTitles('T-10981')}
        </Text>
      )}
      <Box sx={styles.container}>
        {!defaultValues.isResponsive && (
          <UIBackButton backRoot={styles.backArrowButton} onClick={() => navigateToCattleList()} />
        )}

        <Text variant="BoldH22Sec" style={styles.boxTitle}>
          {getTitles('T-11409')}
        </Text>
        {defaultValues.isResponsive && <Divider sx={styles.hrLine} />}

        <Box sx={styles.basicDetailsCont}>
          <Grid container spacing={3}>
            <Grid item xs={defaultValues.isResponsive ? 12 : 5}>
              <Paper sx={styles.cattleInfoBox}>
                <DetailsCardImg
                  properties={defaultValues.cattle_properties(cattleDetails)}
                  cardHeaderTitle={getTitles('T-10981')}
                  imgUrl={cattleDetails?.photo_url || cowImg}
                  customStyle={styles.contentBox}
                  titleWidth={styles.cattleInfoTitleWidth}
                  valueWidth={styles.cattleInfoValueWidth}
                  bodyContStyle={styles.bodyCont}
                />
              </Paper>
            </Grid>
            <Grid item xs={defaultValues.isResponsive ? 12 : 7}>
              <Paper sx={styles.cattleInsuranceBox}>
                <DetailsCardImg
                  properties={defaultValues.assignee_details(cattleDetails)}
                  cardHeaderTitle={getTitles('T-11246')}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Text variant="BoldH22Sec" style={styles.boxTitle}>
          {getTitles('T-11414')}
        </Text>
        {defaultValues.isResponsive && <Divider sx={styles.hrLine} />}
        <UILayout
          tableItems={tableData}
          showNoData={!tableData?.length > 0}
          showDataTable={!defaultValues.isResponsive}
          collapsable={!defaultValues.isResponsive}
          containerStyles={styles.uiContStyle}
          subContStyles={styles.uiSubContStyle}
          message={getErrorMessage('E-10194')}
        />
      </Box>
    </Screen>
  );
};
