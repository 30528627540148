import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const res = defaultValues.isResponsive;
const xsm = defaultValues.isMdSmall;

export const styles = {
  container: {
    width: '100%',
    p: xsm ? 1 : 2,
    boxSizing: 'border-box',
    backgroundColor: color.primaryBackground,
    borderRadius: '4px',
    mt: 2,
    position: 'relative'
  },
  addressSuperCont: {
    width: '100%',
    display: res ? 'block' : 'flex',
    justifyContent: 'space-between',
    gap: '10px'
  },
  addressCont: {
    width: res ? '100%' : '50%',
    p: res ? 0 : 1,
    boxSizing: 'border-box',
    border: res ? 'none' : `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    boxShadow: res ? 'none' : ` 0px 2px 0px rgba(0, 0, 0, 0.2)`,
    display: xsm ? 'block' : 'flex',
    gap: '7px',
    mt: 2
  },
  addressText: {
    color: color.secondaryText
  },
  addressTitle: {
    whiteSpace: 'nowrap'
  },
  subContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    mt: 2
  },
  subSuperCont: {
    width: '100%',
    p: 1,
    boxSizing: 'border-box',
    backgroundColor: res ? color.primaryBackground : color.secondaryBackground,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 2,
    borderRadius: '4px',
    position: res ? 'sticky' : 'static',
    bottom: 0
  },
  subTotalCont: {
    display: res ? 'block' : 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  buynowBtn: {
    height: res ? '35px' : '45px'
  },
  totalPrice: {
    color: res ? color.primary : color.primaryText,
    fontWeight: res ? 600 : 400
  }
};
