import { defaultValues } from '../../constants';
import { color } from '../../theme';

const res = defaultValues.isResponsive;

export const styles = {
  container: {
    mt: res ? 2 : 3,
    p: res ? '20px 10px' : 2,
    backgroundColor: color.primaryBackground,
    borderRadius: 2,
    border: 1,
    borderColor: color.primaryBorder,
    minHeight: '60vh'
  },
  boxTitle: {
    backgroundColor: !res && color.secondaryBackground,
    fontSize: res ? '20px' : '1.5rem',
    borderRadius: '8px 8px 0px 0px',
    py: !res && 1,
    pl: !res && 2
  },
  hrLine: {
    width: '100%',
    mt: 2
  },
  backArrowButton: {
    p: 1,
    mb: 2
  },
  basicDetailsCont: {
    mt: res && 3,
    border: !res && `1px solid ${color.primaryBorder}`,
    p: !res && 3,
    mb: res && 2,
    borderRadius: '0px 0px 8px 8px'
  },
  cattleInfoBox: {
    height: '100%',
    border: !res && `1px solid ${color.primaryBorder}`,
    boxShadow: res && 'none'
  },
  cattleInsuranceBox: {
    height: '100%',
    border: !res && `1px solid ${color.primaryBorder}`,
    boxShadow: res && 'none'
  },
  columnWidth: '20%',
  profileTitle: {
    fontSize: '18px'
  },
  profileSubTitle: {
    fontSize: '16px',
    pl: 2.5
  },
  tableItem: {
    display: 'flex',
    alignItems: 'center',
    pl: '5px',
    position: 'relative'
  },
  tableDate: {
    position: 'absolute',
    right: '5px',
    bottom: '1px',
    fontSize: '12px',
    mt: 5
  },
  contentBox: {
    bodyCont: {
      borderTop: `1px solid ${color.primaryBorder}`,
      display: 'flex',
      px: 2,
      pb: !res ? 3 : 2,
      pt: !res ? 3 : 2
    }
  },
  uiContStyle: {
    paddingTop: '0px'
  },
  uiSubContStyle: {
    mt: 0,
    border: res ? 0 : 1,
    p: res ? '0px' : 2
  },
  resPageTitle: {
    mt: 2
  },
  cattleInfoTitleWidth: {
    width: res ? '25%' : '30%'
  },
  cattleInfoValueWidth: {
    width: res ? '75%' : '70%'
  }
};
