import { defaultValues } from '../../../constants';

const res = defaultValues.isResponsive;
export const styles = {
  columnWidth: '14%',
  tableItemProductName: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: res ? '18px' : '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  },
  textOverFlow: {
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden'
  },
  headertxtStyle: {
    whiteSpace: 'pre-wrap'
  },
  tableSubCont: {
    overflow: 'hidden'
  }
};
