import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const { isMedium, isMobile, isResponsive, isLarge, isMdSmall } = defaultValues;

export const styles = {
  container: (style) => ({
    minWidth: isMobile ? '90%' : isMedium ? 'calc((100% - 20px)/3)' : 'calc((100% - 40px)/5)',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    p: 1,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    ...style
  }),

  cartCont: {
    display: 'flex',
    gap: isMobile ? '4px' : '10px',
    backgroundColor: color.secondary,
    p: 0.8,
    boxSizing: 'border-box',
    borderRadius: '3px',
    alignItems: 'center',
    cursor: 'pointer'
  },
  priceCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    mt: 3
  },
  imgCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  img: (style) => ({
    width: isMobile ? '100px' : '200px',
    height: isMobile ? '100px' : '200px',
    cursor: 'pointer',
    ...style
  }),
  nameCont: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    cursor: 'pointer'
  },
  tag: (type, free) => ({
    backgroundColor: free
      ? color.success
      : type === 1
        ? color.errorText
        : type === 2
          ? color.palette.royalBlue
          : type === 3 && color.palette.violet,
    visibility: type === 4 ? 'hidden' : 'visible',
    color: color.tertiaryText,
    width: 'fit-content',
    padding: '0px 15px',
    boxSizing: 'border-box',
    borderRadius: '3px'
  }),
  cartIcon: {
    fontSize: isMobile ? '15px' : isResponsive ? '20px' : '25px'
  },
  cartText: {
    fontSize: isMdSmall
      ? '0.725rem'
      : isMobile
        ? '0.825rem'
        : isResponsive
          ? '1rem'
          : isLarge
            ? '1rem'
            : '1.20rem',

    whiteSpace: 'nowrap'
  }
};
