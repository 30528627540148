import { useDispatch, useSelector } from 'react-redux';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { useEffect, useState } from 'react';
import {
  getCallCenterAgentsRequest,
  resetCallcenterMsg,
  updateCallCenterAgentsRequest
} from '../../services/redux/slices';
import { toast } from 'react-toastify';
import { checkReadOnly, getTitles, resetMessages } from '../../utils';
import { ActionButton, Screen, Text, UILayout } from '../../ui-kits';
import { BasicProfile } from '../../components';
import {
  getCallCenterAgentsCurrPage,
  getCallCenterAgentsList,
  getCallCenterAgentsTotalPage,
  getCallCenterErrorMsg,
  getCallcenterLoading,
  getCallCenterSuccessMsg,
  getUserData
} from '../../services/redux/selectors';

export const ManageCallCenterAgents = () => {
  const dispatch = useDispatch();

  const callCenterAgents = useSelector(getCallCenterAgentsList);
  const errorMsg = useSelector(getCallCenterErrorMsg);
  const isLoading = useSelector(getCallcenterLoading);
  const successMsg = useSelector(getCallCenterSuccessMsg);
  const currentPage = useSelector(getCallCenterAgentsCurrPage);
  const totalPages = useSelector(getCallCenterAgentsTotalPage);
  const userData = useSelector(getUserData);

  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(null);

  const res = defaultValues.isResponsive;

  useEffect(() => {
    handleCallCenter();
  }, []);

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.callCenterManager));
  }, [userData]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (['S-10139'].includes(successMsg)) handleCallCenter();
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetCallcenterMsg()));
  }, [successMsg, errorMsg]);

  const handleCallCenter = (data) => {
    const payload = { filter: JSON.stringify(filter), sort, ...data };
    dispatch(getCallCenterAgentsRequest(payload));
  };

  const handleStatus = (item) => {
    dispatch(
      updateCallCenterAgentsRequest({
        ...item,
        _id: item._id,
        is_active: !item.is_active
      })
    );
  };

  const handleSearch = (data) => {
    handleCallCenter({ search_text: data });
  };
  const handleFilter = (data) => {
    setFilter(data);
    handleCallCenter({
      page: 1,
      filter: JSON.stringify(data),
      sort: sort
    });
  };

  const handleSort = (data) => {
    setSort(data);
    handleCallCenter({ page: 1, sort: data, filter: JSON.stringify(filter) });
  };

  const handleRefresh = () => {
    setSort([]);
    setFilter([]);
    handleCallCenter({ page: 1, search_text: null, filter: null, sort: null });
  };

  const handlePagination = (e, data) => {
    handleCallCenter({ page: data });
  };

  const tableData =
    callCenterAgents &&
    callCenterAgents.length > 0 &&
    callCenterAgents.map((item) => {
      if (!res) {
        return [
          {
            title: getTitles('T-10438'),
            values: item.employee_id,
            columnWidth: '10%'
          },
          {
            title: getTitles('T-10081'),
            values: <BasicProfile title={item.full_name} textVariant="D22" url={item.photo_url} />
          },

          {
            title: getTitles('T-10440'),
            values: item.phone_number
          },

          {
            title: getTitles('T-10734'),
            values: item.location
          },

          {
            title: getTitles('T-10103'),
            values: (
              <ActionButton
                status={item.is_active}
                onStatusChange={() => handleStatus(item)}
                alterTitle={defaultValues.confirmationModel.changeCallAgentStatus.title(
                  item.is_active
                )}
                alterSubTitle={defaultValues.confirmationModel.subTitle}
                isReadOnly={isReadOnly}
              />
            ),
            columnWidth: '10%'
          }
        ];
      } else {
        return {
          title: (
            <BasicProfile
              title={item.full_name}
              isIcon={false}
              textVariant="D22"
              url={item.photo_url}
            />
          ),
          details: [
            {
              subTitle: getTitles('T-10438'),
              action: item.employee_id
            },
            {
              title: getTitles('T-10734'),
              values: item.location
            },
            {
              subTitle: getTitles('T-10440'),
              action: item.phone_number
            },

            {
              subTitle: getTitles('T-10103'),
              action: (
                <ActionButton
                  status={item.is_active}
                  onStatusChange={() => handleStatus(item)}
                  alterTitle={defaultValues.confirmationModel.changeCallAgentStatus.title(
                    item.is_active
                  )}
                  alterSubTitle={defaultValues.confirmationModel.subTitle}
                  isReadOnly={isReadOnly}
                />
              )
            }
          ]
        };
      }
    });

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-10119')}>
      {res && (
        <Text variant="boldH32Sec" style={{ mt: 1 }}>
          {getTitles('T-10119')}
        </Text>
      )}
      <UILayout
        isMultiSelectFilterOption={true}
        currentlyActiveSortOption={sort}
        currentlyActiveFilterOption={filter}
        handleSearch={handleSearch}
        searchBarPlaceHolder={getTitles('T-11005')}
        handleRefresh={handleRefresh}
        handleSort={handleSort}
        handleFilter={handleFilter}
        optionsForSorting={defaultValues.farmerSortOptions}
        clearSearch={filter}
        tableItems={tableData}
        showNoData={!callCenterAgents?.length > 0}
        showDataTable={!res}
        message={getErrorMessage('E-10282')}
        currPage={currentPage}
        pageCount={totalPages}
        handlePagination={handlePagination}
        showPagination={callCenterAgents?.length > 0 && totalPages > 1}
        isReadOnly={isReadOnly}
      />
    </Screen>
  );
};
