import { Avatar, Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Text } from '../text';
import { SCREEN_PATH, defaultValues } from '../../constants';
import img from '../../assets/images/logo.png';
import logout from '../../assets/icons/logout.png';
import { Styles } from './style';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setCollapse,
  setSidebarScrollPosition,
  updateMainMenu,
  userLogout
} from '../../services/redux/slices/authSlice';
import {
  getCollapse,
  getMainMenu,
  getSidebarScrollPosition,
  getUserData
} from '../../services/redux/selectors';
import { useSelector } from 'react-redux';
import { getTitles } from '../../utils';
import {
  resetCallcenter,
  resetEmployee,
  resetGopalan,
  resetGovtOffice,
  resetInsurance,
  resetInventory,
  resetLaboratory,
  resetProcess,
  resetSociety,
  resetVet
} from '../../services/redux/slices';

export const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollDivRef = useRef(null);
  const location = useLocation();

  const userData = useSelector(getUserData);
  const collapse = useSelector(getCollapse);
  const scrollPosition = useSelector(getSidebarScrollPosition);

  const mainMenu = useSelector(getMainMenu);
  const [selectedMenu, setSelectedMenu] = useState(1);
  const [menuItems, setMenuItems] = useState([]);
  const [currScroll, setCurrScroll] = useState(0);

  const res = defaultValues.isResponsive;
  const {
    callCenter,
    laboratory,
    society,
    employee,
    insurance,
    govtOffice,
    process,
    gopalan,
    vet,
    village,
    inventory
  } = defaultValues.appLogicalTexts;

  useEffect(() => {
    setCurrScroll(scrollPosition);
  }, [scrollPosition]);

  useEffect(() => {
    if (mainMenu && mainMenu !== selectedMenu) {
      setSelectedMenu(mainMenu);
    }
  }, [mainMenu]);

  useEffect(() => {
    const excludeIds = [14, 18, 20, 17];
    const menus = defaultValues.superAdminMenu(userData?.user_role === 1);

    if (userData?.user_role === 4 && !defaultValues.isResponsive) {
      dispatch(setCollapse([1, 15].includes(mainMenu)));
    }

    if (userData?.user_role === 4 && defaultValues.isResponsive) {
      dispatch(setCollapse(false));
    }

    switch (userData?.user_role) {
      // user is super Admin, showing all menu
      case defaultValues.userRole.superAdmin:
        setMenuItems(menus.filter((item) => !item.role_id || !excludeIds.includes(item.id)));
        break;

      // user is Admin, then filter as per permission have
      case defaultValues.userRole.employee: {
        const permissionIds = userData?.employee_permissions.map((perm) => perm.permission_id);
        const filteredMenu = menus.filter((menuItem) => {
          if (Array.isArray(menuItem.role_id)) {
            return menuItem.role_id.some((role) => permissionIds.includes(role));
          } else if (menuItem.role_id === undefined) {
            return menuItem;
          } else {
            return permissionIds.includes(menuItem.role_id);
          }
        });

        dispatch(
          setCollapse(permissionIds.includes(defaultValues.employeeRol.callCenterAgents) && !res)
        );

        setMenuItems(filteredMenu);
        break;
      }

      default:
        setMenuItems(menus.filter(({ role_id }) => role_id === undefined));
    }
  }, [userData]);

  useEffect(() => {
    if (scrollDivRef.current) {
      scrollDivRef.current.scrollTop = currScroll;
    }
  }, [currScroll]);

  const handleScroll = () => {
    if (scrollDivRef.current) {
      dispatch(setSidebarScrollPosition(scrollDivRef.current.scrollTop));
    }
  };

  const resetStateBasedOnMenu = (menuType) => {
    switch (menuType) {
      case laboratory:
        dispatch(resetLaboratory());
        break;
      case society:
        dispatch(resetSociety());
        break;
      case employee:
        dispatch(resetEmployee());
        dispatch(resetProcess());
        break;
      case insurance:
        dispatch(resetInsurance());
        dispatch(resetProcess());
        break;
      case govtOffice:
        dispatch(resetGovtOffice());
        dispatch(resetProcess());
        break;
      case process:
        dispatch(resetProcess());
        break;
      case gopalan:
        dispatch(resetGopalan());
        break;
      case vet:
        dispatch(resetVet());
        break;
      case callCenter:
        dispatch(resetCallcenter());
        break;
      case village:
        dispatch(resetProcess());
        break;
      case inventory:
        dispatch(resetInventory());
        break;
      default:
        break;
    }
  };

  const handleMenuClick = (item) => {
    dispatch(updateMainMenu(item));
    mainMenu !== item.id && resetStateBasedOnMenu(item.type);
    if (item.route) navigate(item.route);
  };

  const handleLogoutClick = () => {
    navigate(SCREEN_PATH.HOME);
    dispatch(userLogout());
    dispatch({ type: defaultValues.appLogicalTexts.userLogout });
    window.location.reload();
  };

  return (
    <Box sx={Styles.container}>
      <Box sx={Styles.logoContainer(collapse)}>
        <Avatar alt={'logo'} src={img} sx={Styles.logoStyle} />
        {!collapse && <Text variant="boldMilkH28">{getTitles('T-10008')}</Text>}
      </Box>
      <div style={Styles.menuContainer(collapse)} ref={scrollDivRef} onScroll={handleScroll}>
        {menuItems.length > 0 &&
          menuItems.map((item) => (
            <Box key={item.id}>
              <Box
                sx={Styles.menuTab(
                  location.pathname !== SCREEN_PATH.NOTIFICATION && selectedMenu === item.id,
                  collapse
                )}
                onClick={() => handleMenuClick(item)}>
                <img src={item.icon} alt="icons" style={Styles.iconStyle()} />
                {!collapse && (
                  <Text
                    variant="D20"
                    style={Styles.menuTitle(
                      location.pathname !== SCREEN_PATH.NOTIFICATION && selectedMenu === item.id
                    )}>
                    {item.menu}
                  </Text>
                )}
              </Box>
            </Box>
          ))}
      </div>

      <Box
        sx={{ ...Styles.menuTab(false, collapse), borderRadius: '0px 0px 20px 0px' }}
        onClick={() => handleLogoutClick()}>
        <img src={logout} alt="icons" style={Styles.logout(collapse)} />
        {!collapse && (
          <Text variant="D20" style={Styles.menuTitle()}>
            {getTitles('T-10036')}
          </Text>
        )}
      </Box>
    </Box>
  );
};
