import { defaultValues } from '../../../../constants';

const res = defaultValues.isResponsive;
const sm = defaultValues.isMobile;

export const styles = {
  container: {
    width: '100%',
    minHeight: '50vh'
  },
  input: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px'
    },
    height: res ? '30px' : '40px'
  },
  autoInput: {
    '& .MuiInputBase-root': {
      height: res ? '30px' : '40px',

      padding: 0,
      paddingLeft: '5px'
    },
    '& .MuiInputBase-input': {
      fontSize: res ? '14px' : '18px'
    },
    '& .MuiPaper-root': {
      paddingRight: '15px',
      padding: 0
    }
  },
  autoCont: {
    mt: 1
  },
  imgSuperCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  },
  imgLeftCont: {
    width: res ? '100%' : 'calc((100% - 8px)/3 * 2)',
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap'
  },
  imgRightCont: {
    width: 'calc((100% - 20px)/3)',
    display: res ? 'none' : 'flex',
    justifyContent: 'center',
    padding: '10px 0px 0px 0px',
    boxSizing: 'border-box'
  },
  width: res ? 'calc((100% - 10px)/2)' : 'calc((100% - 20px)/3)',
  imgConWidth: 'calc((100% - 10px)/2)',
  secWidth: res ? '100%' : 'calc((100% - 20px)/3)',
  tertiaryWidth: sm ? '100%' : res ? 'calc((100% - 10px)/2)' : 'calc((100% - 20px)/3)',
  imgConSecWidth: sm ? '100%' : 'calc((100% - 10px)/2)',

  aboveHalf: {
    width: res ? '100%' : 'calc((100% - 8px)/3 * 2)',
    maxWidth: 'none'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    justifyContent: sm ? 'space-between' : 'none',
    gap: '10px'
  },
  autoTitle: {
    mb: 0.8
  },
  radioCont: {
    width: res ? '100%' : 'calc((100% - 10px)/2)'
  },
  radioSubCont: {
    width: '100%',
    display: 'flex',
    gap: '10px'
  },
  imageCont: {
    width: res ? '125px' : '238px',
    height: res ? '125px' : '238px'
  },
  gridCont: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap'
  },
  checkBoxCont: {
    display: 'flex',
    gap: '57px',
    mt: res ? 1 : 2
  },
  checkBox: {
    width: 'fit-content'
  }
};
