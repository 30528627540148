import { createSelector } from '@reduxjs/toolkit';

export const getInventoryLoading = createSelector([(state) => state.inventory.isLoading], (d) => d);

export const getInventorySuccessMsg = createSelector(
  [(state) => state.inventory.successMessage],
  (d) => d
);
export const getInventoryErrorMsg = createSelector(
  [(state) => state.inventory.errorMessage],
  (d) => d
);

export const getInventoryCurPage = createSelector(
  [(state) => state.inventory.currentPage],
  (d) => d
);
export const getInventoryNxtPage = createSelector([(state) => state.inventory.nextPage], (d) => d);
export const getInventoryTotalPage = createSelector(
  [(state) => state.inventory.totalPage],
  (d) => d
);

export const getInventoryCurrTab = createSelector([(state) => state.inventory.currTab], (d) => d);
export const getInventoryMaterialGroup = createSelector(
  [(state) => state.inventory.materialGroup],
  (d) => d
);

export const getProducts = createSelector([(state) => state.inventory.products], (d) => d);
export const getInwardActivePage = createSelector(
  [(state) => state.inventory.inwardActivePage],
  (d) => d
);
export const getProductDetails = createSelector(
  [(state) => state.inventory.productDetails],
  (d) => d
);

export const getInwardActiveStep = createSelector(
  [(state) => state.inventory.inwardActiveStep],
  (d) => d
);

export const getInwardEmployees = createSelector(
  [(state) => state.inventory.employeeList],
  (d) => d
);

export const getOutwardMaterialGroup = createSelector(
  [(state) => state.inventory.outwardMaterialGroup],
  (d) => d
);

export const getOutwardProducts = createSelector(
  [(state) => state.inventory.outwardProdutList],
  (d) => d
);

export const getOutwardProductHistory = createSelector(
  [(state) => state.inventory.outwardProductHistory],
  (d) => d
);

export const getOutwardProductData = createSelector(
  [(state) => state.inventory.outwardProductData],
  (d) => d
);

export const getOutwardActionType = createSelector(
  [(state) => state.inventory.outwardActionType],
  (d) => d
);

export const getOutwardAddType = createSelector(
  [(state) => state.inventory.outwardAddType],
  (d) => d
);
