import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { color, typography } from '../../theme';
import { defaultValues } from '../../constants';

const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;

// data should be passed like
// renderElements = [{title:'some title',value: render component}]

export const UITablist = ({
  renderElements,
  currentTab = 0,
  onTabChange,
  showTabs = true,
  tabStyle,
  containerStyle
}) => {
  const styles = {
    container: (style) => ({
      width: '100%',
      boxSizing: 'border-box',
      backgroundColor: color.primaryBackground,
      marginTop: '10px',
      ...style
    }),
    subContainer: {
      width: '100%',
      padding: '2% 2% 0% 2%',
      boxSizing: 'border-box'
    },

    tab: (id, style) => ({
      color: value === id ? color.tertiaryText : color.primaryText,
      fontFamily: typography.primary,
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: res ? '1rem' : md ? '1.25rem' : '1.5rem',
      backgroundColor: value === id ? color.primary : color.primaryBackground,
      ...style
    }),
    tabIndicator: {
      display: 'none'
    },
    pagesContainer: {
      width: '100%',
      backgroundColor: color.secondaryBackground
    },
    panel: {
      padding: 0
    },
    tabCont: {
      width: '100%',
      borderRadius: '5px',
      backgroundColor: color.secondaryBackground
    }
  };
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(currentTab);
  }, [currentTab]);

  const handleChange = (event, newValue) => {
    onTabChange ? onTabChange(newValue) : setValue(newValue);
  };

  return (
    <Box sx={styles.container(containerStyle)}>
      <TabContext value={value}>
        {showTabs && (
          <TabList
            textColor="none"
            TabIndicatorProps={{ sx: styles.tabIndicator }}
            onChange={handleChange}
            sx={styles.tabCont}
            variant="scrollable"
            aria-label="lab API tabs example">
            {renderElements.map((item, index) => (
              <Tab key={index} sx={styles.tab(index, tabStyle)} label={item.title} value={index} />
            ))}
          </TabList>
        )}
        <Box sx={styles.pagesContainer}>
          {renderElements.map((item, idx) => (
            <TabPanel key={idx} sx={styles.panel} value={idx}>
              {item.value}
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </Box>
  );
};
