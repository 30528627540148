import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';
const sm = defaultValues.isMobile;
const res = defaultValues.isResponsive;
const md = defaultValues.isMedium;
export const styles = {
  container: (styles) => ({
    width: '100%',
    p: sm ? 1 : res ? 4 : md ? 6 : 8,
    boxSizing: 'border-box',
    background: 'linear-gradient(90deg, #B5E8B5 0%, #FAEEBD 100%)',
    borderRadius: '4px',
    display: 'flex',
    ...styles
  }),
  left: {
    width: '50%'
  },
  right: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: md ? 'center' : 'start',
    position: 'relative',
    objectFit: 'cover'
  },
  hint: (style) => ({
    width: 'fit-content',
    p: 1,
    boxSizing: 'border-box',
    borderRadius: '3px',
    fontWeight: 600,
    ...style
  }),
  subTitle: {
    mt: 1,
    color: color.secondaryText
  },
  btn: {
    mt: 1,
    padding: sm ? '2px 10px' : '5px 40px',
    backgroundColor: color.palette.orange
  },
  img: (style) => ({
    width: sm ? '100%' : '60%',
    maxWidth: sm ? '100px' : res ? '150px' : md ? '200px' : '250px',
    maxHeight: sm ? '100px' : res ? '150px' : md ? '200px' : '250px',
    objectFit: 'cover',
    ...style
  }),
  price: (style) => ({
    position: 'absolute',
    bottom: sm ? -7 : res ? -20 : -30,
    left: sm ? '15%' : '10%',
    height: sm ? '50px' : md ? '70px' : '100px',
    width: sm ? '50px' : md ? '70px' : '100px',
    backgroundColor: color.palette.royalBlue,
    borderRadius: '50%',
    border: `${sm ? '2px' : '10px'} solid ${color.primaryBackground}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.primaryBackground,
    ...style
  })
};
