import { defaultValues } from '../../constants';
import { color } from '../../theme';

const lg = defaultValues.isLarge;
const res = defaultValues.isResponsive;

export const Styles = {
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: color.secondary,
    borderRadius: '0px 20px 20px 0px',
    px: 2,
    boxSizing: 'border-box'
  },

  logoContainer: (isCollapse) => ({
    py: 2,
    pl: isCollapse ? 0 : 2,
    boxSizing: 'border-box',
    backgroundColor: color.secondary,
    borderRadius: '0px 20px 0px 0px'
  }),

  logoStyle: { width: '70px', height: '70px', mb: 1 },
  menuTab: (isActive, collapse) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: collapse ? 'center' : 'start',
    padding: '10px 10px',
    boxSizing: 'border-box',
    backgroundColor: isActive ? color.palette.paleIvory : color.secondary,
    borderRadius: '10px',
    gap: '10px'
  }),
  iconStyle: () => ({
    width: lg ? '24px' : '30px',
    height: lg ? '24px' : '30px',
    color: color.primaryText,
    cursor: 'pointer'
  }),
  logout: (collapse) => ({
    marginRight: collapse ? (res ? '5px' : '0px') : res ? '5px' : '10px',
    width: lg ? '20px' : '22px',
    height: lg ? '20px' : '22px',
    color: color.primaryText,
    cursor: 'pointer'
  }),
  menuTitle: (isActive) => ({
    color: isActive ? color.primary : color.primaryText,
    textAlign: 'left',
    textTransform: 'none',
    cursor: 'pointer'
  }),
  menuContainer: (isCall) => ({
    paddingTop: isCall ? '0px' : '0px',
    height: isCall ? 'calc(100% - 180px)' : 'calc(100% - 210px)',
    overflow: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    backgroundColor: color.secondary
  })
};
