import { defaultValues, SCREEN_PATH } from '../../../../constants';
import logo from '../../../../assets/icons/logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import { HeaderStyles } from '../styles';
import { Box } from '@mui/material';
import { Text, UIButton } from '../../../../ui-kits';
import { getTitles } from '../../../../utils';
import { useNavigate } from 'react-router-dom';

export const NotAuthenticatedHeader = ({ handleTools, toggleDrawer }) => {
  const navigate = useNavigate();

  return (
    <>
      {defaultValues.isResponsive && <MenuIcon onClick={toggleDrawer('Right', true)} />}
      <img src={logo} alt="logo" style={HeaderStyles.logoStyles} />
      {!defaultValues.isResponsive && (
        <Box sx={HeaderStyles.toolSuperCont}>
          {defaultValues.noAuthToolbar.map((item, idx) => (
            <Box key={idx} sx={HeaderStyles.toolCont} onClick={() => handleTools(item)}>
              <Text variant="D24" style={HeaderStyles.toolOptionsText}>
                {item.title}
              </Text>
            </Box>
          ))}
        </Box>
      )}

      <UIButton
        title={getTitles('T-10030')}
        style={HeaderStyles.loginBtn}
        onClick={() => navigate(SCREEN_PATH.LOGIN)}
      />
    </>
  );
};
