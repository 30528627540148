import { Box } from '@mui/material';
import { HomeStyles } from './styles';
import { Screen, Text, UIButton } from '../../ui-kits';
import { globalStyles } from '../../theme/styles';
import gopBackground from '../../assets/images/Vector 7.png';
import cow1 from '../../assets/images/cow1.png';
import vector8 from '../../assets/images/Vector 8.png';
import vector10 from '../../assets/images/Vector 10.png';
import cow2 from '../../assets/images/cow2.png';
import cow3 from '../../assets/images/Group 97.png';
import cow4 from '../../assets/images/Group 95.png';
import cow5 from '../../assets/images/cow5.png';
import DownloadIcon from '@mui/icons-material/Download';
import { Footer } from '../../components/common/footer';
import { getTitles } from '../../utils';
import { defaultValues } from '../../constants';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  setAuthStatus,
  setIsEcommerce,
  setUserData,
  userLogout
} from '../../services/redux/slices';

const res = defaultValues.isResponsive;

export const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAuthStatus(null));
    dispatch(setIsEcommerce(null));
    dispatch(userLogout());
    dispatch(setUserData(null));
  }, []);
  return (
    <Screen showHeader={true}>
      <Box sx={HomeStyles.container}>
        <Box sx={HomeStyles.introCont}>
          <Box sx={HomeStyles.introSubCont}>
            <Box sx={HomeStyles.myGopCont}>
              <Text variant="boldMilkH96" style={HomeStyles.introText1}>
                {getTitles('T-10008')}
              </Text>
              <img src={gopBackground} style={HomeStyles.introText1backIcon} />
            </Box>

            <Text variant="D28" style={HomeStyles.desc1}>
              {getTitles('T-10009')}
            </Text>
            {defaultValues.isResponsive && (
              <Box sx={HomeStyles.cowSuperCont}>
                <Box sx={HomeStyles.cattleCont}>
                  <img src={vector8} style={HomeStyles.vector8} />
                  <img src={cow1} style={HomeStyles.cow1} />
                  <img src={vector10} style={HomeStyles.vector10} />
                </Box>
              </Box>
            )}
            <Text variant="D22Open" style={{ ...globalStyles.mt(4), ...HomeStyles.textJustify }}>
              {getTitles('T-10010')}
            </Text>
            <UIButton style={HomeStyles.contactBtn} title={getTitles('T-10007')} />
          </Box>
          {!defaultValues.isResponsive && (
            <Box sx={HomeStyles.cowSuperCont}>
              <Box sx={HomeStyles.cattleCont}>
                <img src={vector8} style={HomeStyles.vector8} />
                <img src={cow1} style={HomeStyles.cow1} />
                <img src={vector10} style={HomeStyles.vector10} />
              </Box>
            </Box>
          )}
        </Box>

        <Box sx={HomeStyles.benefits}>
          <Text variant="boldH40" style={HomeStyles.head1}>
            {getTitles('T-10014')}
          </Text>
          <Text variant="D22Open" style={HomeStyles.desc3}>
            {getTitles('T-10011')}
          </Text>
        </Box>

        <Box sx={HomeStyles.BlistCont}>
          <Box sx={HomeStyles.bImgCont}>
            <img src={cow2} style={HomeStyles.BCowImg} />
          </Box>

          <Box sx={{ ...HomeStyles.BriefCont, ml: res ? 0 : 6 }}>
            <Text variant="boldH32" style={{ ...globalStyles.mb(1) }}>
              {getTitles('T-10015')}
            </Text>
            <Text variant="D22Open" style={HomeStyles.textJustify}>
              {getTitles('T-10012')}
            </Text>
          </Box>
        </Box>

        <Box sx={{ ...HomeStyles.BlistCont, flexDirection: res ? 'column-reverse' : 'row' }}>
          <Box sx={HomeStyles.BriefCont}>
            <Text variant="boldH32" style={{ ...globalStyles.mb(1) }}>
              {getTitles('T-10016')}
            </Text>
            <Text variant="D22Open" style={HomeStyles.textJustify}>
              {getTitles('T-11388')}
            </Text>
          </Box>
          <Box sx={HomeStyles.bImgCont}>
            <img src={cow3} style={HomeStyles.BCowImg} />
          </Box>
        </Box>

        <Box sx={HomeStyles.BlistCont}>
          <Box sx={HomeStyles.bImgCont}>
            <img src={cow4} style={HomeStyles.BCowImg} />
          </Box>
          <Box sx={{ ...HomeStyles.BriefCont, ml: res ? 0 : 6 }}>
            <Text variant="boldH32" style={{ ...globalStyles.mb(1) }}>
              {getTitles('T-10017')}
            </Text>
            <Text variant="D22Open" style={HomeStyles.textJustify}>
              {getTitles('T-11389')}
            </Text>
          </Box>
        </Box>

        <Box sx={HomeStyles.benefits}>
          <Text variant="boldH40" style={HomeStyles.head1}>
            {getTitles('T-10033')}
          </Text>
          <Text
            variant="D22Open"
            style={{ ...HomeStyles.desc3, width: '92%', ...HomeStyles.textJustify }}>
            {getTitles('T-11390')}
          </Text>
        </Box>

        <Box sx={HomeStyles.serviceCont}></Box>

        <Box sx={HomeStyles.downloadCont}>
          <Box sx={HomeStyles.downloadCImgSuperCont}>
            <Box sx={HomeStyles.downloadCImgCont}>
              <img src={cow5} style={HomeStyles.downCow} />
            </Box>
          </Box>

          <Box sx={HomeStyles.downloadSubCont}>
            <Text variant="boldH40">{getTitles('T-10019')}</Text>
            <Text variant="D22Open">{getTitles('T-10013')}</Text>
            <UIButton
              style={{ ...globalStyles.mt(2), ...HomeStyles.downloadBtn2 }}
              startIcon={<DownloadIcon />}
              title={getTitles('T-10032')}
            />
          </Box>
        </Box>

        <Footer />
      </Box>
    </Screen>
  );
};
