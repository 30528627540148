import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  currentPage: null,
  nextPage: null,
  totalPage: null,
  currTab: 0,
  materialGroup: '',
  products: [],
  inwardActivePage: null,
  productDetails: null,
  inwardActiveStep: 0,
  employeeList: [],
  outwardProdutList: [],
  outwardMaterialGroup: '',
  outwardProductHistory: [],
  outwardProductData: {},
  outwardActionType: null,
  outwardAddType: null
};

const inventorySlice = createSlice({
  initialState,
  name: 'inventory',
  reducers: {
    resetInventory(state) {
      state.isLoading = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
      state.currTab = 0;
      state.materialGroup = '';
      state.products = [];
      state.inwardActivePage = null;
      state.productDetails = null;
      state.inwardActiveStep = 0;
      state.employeeList = [];
      state.outwardMaterialGroup = '';
      state.outwardProdutList = [];
      state.outwardProductHistory = [];
      state.outwardProductData = {};
      state.outwardActionType = null;
      state.outwardAddType = null;
    },
    resetInventoryMsg(state) {
      state.errorMessage = null;
      state.successMessage = null;
    },
    setInventoryCurrTab(state, action) {
      state.currTab = action.payload;
    },
    setMaterialGroup(state, action) {
      state.materialGroup = action.payload;
    },
    setInwardActivePage(state, action) {
      state.inwardActivePage = action.payload;
    },
    setProductDetails(state, action) {
      state.productDetails = action.payload;
    },
    setInwardActiveStep(state, action) {
      state.inwardActiveStep = action.payload;
    },
    getInwardEmployeeRequest(state) {
      state.isLoading = true;
    },
    getInwardEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.employeeList = action.payload.data.employee;
    },
    getInwardEmployeeFailure(state, action) {
      state.isLoading = false;
      state.employeeList = [];
      state.errorMessage = action.payload.status_code;
    },
    getInventoryProductListRequest(state) {
      state.isLoading = true;
    },
    getInventoryProductListSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload.data.product;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
    },
    getInventoryProductListFailure(state, action) {
      state.isLoading = false;
      state.products = [];
      state.errorMessage = action.payload.status_code;
    },
    addInventoryProductRequest(state) {
      state.isLoading = true;
    },
    addInventoryProductSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.productDetails = action.payload.data;
    },
    addInventoryProductFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateInventoryProductRequest(state) {
      state.isLoading = true;
    },
    updateInventoryProductSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
      state.productDetails = action.payload.data;
    },
    updateInventoryProductFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getInventoryProductListDetailsRequest(state) {
      state.isLoading = true;
    },
    getInventoryProductListDetailsSuccess(state, action) {
      state.isLoading = false;
      state.productDetails = action.payload.data;
    },
    getInventoryProductListDetailsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    setOutwardMaterialGroup(state, action) {
      state.outwardMaterialGroup = action.payload;
    },
    getOutwardProductListRequest(state) {
      state.isLoading = true;
    },
    getOutwardProductListSuccess(state, action) {
      state.isLoading = false;
      state.outwardProdutList = action.payload.data.product;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
    },
    getOutwardProductListFailure(state, action) {
      state.isLoading = false;
      state.outwardProdutList = [];
      state.errorMessage = action.payload.status_code;
    },
    addOutwardProductRequest(state) {
      state.isLoading = true;
    },
    addOutwardProductSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    addOutwardProductFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    addAdjustmentProductRequest(state) {
      state.isLoading = true;
    },
    addAdjustmentProductSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    addAdjustmentProductFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getOutwardProductHistoryRequest(state) {
      state.isLoading = true;
    },
    getOutwardProductHistorySuccess(state, action) {
      state.isLoading = false;
      state.outwardProductHistory = action.payload.data.product_history;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
    },
    getOutwardProductHistoryFailure(state, action) {
      state.isLoading = false;
      state.outwardProductHistory = [];
      state.errorMessage = action.payload.status_code;
    },
    setOutwardProductData(state, action) {
      state.outwardProductData = action.payload;
    },
    setOutwardActionType(state, action) {
      state.outwardActionType = action.payload;
    },
    setOutwardAddType(state, action) {
      state.outwardAddType = action.payload;
    }
  }
});

export const {
  resetInventory,
  resetInventoryMsg,
  setInventoryCurrTab,
  setMaterialGroup,
  setInwardActivePage,
  setProductDetails,
  setInwardActiveStep,
  getInwardEmployeeFailure,
  getInwardEmployeeRequest,
  getInwardEmployeeSuccess,
  getInventoryProductListFailure,
  getInventoryProductListRequest,
  getInventoryProductListSuccess,
  addInventoryProductFailure,
  addInventoryProductRequest,
  addInventoryProductSuccess,
  updateInventoryProductFailure,
  updateInventoryProductRequest,
  updateInventoryProductSuccess,
  getInventoryProductListDetailsFailure,
  getInventoryProductListDetailsRequest,
  getInventoryProductListDetailsSuccess,
  setOutwardMaterialGroup,
  getOutwardProductListRequest,
  getOutwardProductListSuccess,
  getOutwardProductListFailure,
  addOutwardProductRequest,
  addOutwardProductSuccess,
  addOutwardProductFailure,
  addAdjustmentProductRequest,
  addAdjustmentProductSuccess,
  addAdjustmentProductFailure,
  getOutwardProductHistoryRequest,
  getOutwardProductHistorySuccess,
  getOutwardProductHistoryFailure,
  setOutwardProductData,
  setOutwardAddType,
  setOutwardActionType
} = inventorySlice.actions;

export default inventorySlice.reducer;
