import { useEffect, useState } from 'react';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import { DatePickers, Screen, Text, UILayout, UISelect } from '../../ui-kits';
import { getTitles, getFormatDate, resetMessages } from '../../utils';
import { styles } from './style';
import { Box } from '@mui/material';
import { EcomOrderDetails } from './e-com-order-details';
import {
  getEcomOrdersRequest,
  resetECommerceMsg,
  setManageOrderActionType,
  updateDetailsPg,
  updateEcomOrderDetailsRequest
} from '../../services/redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getEcommerceErrorMsg,
  getEcommerceLoading,
  getEcommerceSuccessMsg,
  getEcomOrderActionType,
  getEcomOrderList,
  getEcomOrdersCurrentPage,
  getEcomOrdersTotalPage
} from '../../services/redux/selectors';

export const EcomOrdersManage = () => {
  const dispatch = useDispatch();

  const res = defaultValues.isResponsive;
  const { view } = defaultValues.actionType;

  const orderList = useSelector(getEcomOrderList);
  const isLoading = useSelector(getEcommerceLoading);
  const success = useSelector(getEcommerceSuccessMsg);
  const error = useSelector(getEcommerceErrorMsg);
  const currentPage = useSelector(getEcomOrdersCurrentPage);
  const totalPages = useSelector(getEcomOrdersTotalPage);
  const actionType = useSelector(getEcomOrderActionType);

  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState(null);
  const [filterDate, setFilterDate] = useState(defaultValues.orderDueDateRange.all);
  const [dateValue, setDateValue] = useState(null);
  const [orders, setOrders] = useState([]);
  const [order_Id, setOrder_Id] = useState(null);

  useEffect(() => {
    fetchOrdersList();
  }, [actionType]);

  useEffect(() => {
    orderList && setOrders(orderList);
  }, [orderList]);

  useEffect(() => {
    if (success === 'S-10137') {
      toast.success(getSuccessMessage(success));
      fetchOrdersList();
    }
    if (error) {
      toast.error(getErrorMessage(error));
    }

    resetMessages(() => dispatch(resetECommerceMsg()));
  }, [success, error]);

  const handleSearch = (data) => {
    fetchOrdersList({ search_text: data || null });
  };

  const handleFilter = (data) => {
    setFilter(data);
    fetchOrdersList({
      page: 1,
      filter: JSON.stringify(data),
      sort: sort
    });
  };

  const handleSort = (data) => {
    setSort(data);
    fetchOrdersList({ page: 1, sort: data, filter: JSON.stringify(filter) });
  };

  const fetchOrdersList = (data) => {
    let payload = { page: 1, order_date_type: filterDate, ...data };
    dispatch(getEcomOrdersRequest(payload));
  };

  const handleRefresh = () => {
    setFilterDate(defaultValues.orderDueDateRange.all);
    setSort(null);
    setFilter([]);
    setDateValue(null);
    fetchOrdersList({ order_date_type: defaultValues.orderDueDateRange.all });
  };

  const handleDueDateRange = (val) => {
    setFilterDate(val);
    if (val !== defaultValues.orderDueDateRange.custom) {
      setDateValue(null);
      fetchOrdersList({
        page: 1,
        sort: sort,
        filter: JSON.stringify(filter),
        order_date_type: val
      });
    }
  };

  const handleCustomDateChange = (val) => {
    setDateValue(val);
    if (filterDate === defaultValues.orderDueDateRange.custom && val) {
      fetchOrdersList({
        page: 1,
        sort: sort,
        filter: JSON.stringify(filter),
        order_date_type: filterDate,
        custom_date: val
      });
    }
  };

  const handlePagination = (_, data) => {
    fetchOrdersList({ page: data });
  };

  const handleOrderClick = (_id) => {
    dispatch(setManageOrderActionType(view));
    setOrder_Id(_id);
    dispatch(updateDetailsPg(true));
  };

  const tableData = orders?.map((item) => {
    if (!res) {
      return [
        {
          title: getTitles('T-11683'),
          values: (
            <Text
              style={styles.tableItemId}
              variant="D22"
              onClick={() => handleOrderClick(item?._id)}>
              {item?.order_id}
            </Text>
          ),
          columnWidth: styles.col17
        },
        {
          title: getTitles('T-11684'),
          columnWidth: styles.col22,
          values: (
            <Box>
              <Text variant="D22">{item?.order_by?.full_name}</Text>
              {(item?.order_by?.employee_id ||
                item?.order_by?.farmer_id ||
                item?.order_by?.user_id) && (
                <Text variant="D22">{`(${item?.order_by?.employee_id || item?.order_by?.farmer_id || item?.order_by?.user_id})`}</Text>
              )}
              <Text variant="D22">{item?.order_by?.phone_number}</Text>
            </Box>
          )
        },
        {
          title: getTitles('T-11685'),
          values: <Text variant="D22">{item?.no_of_products}</Text>,
          columnWidth: styles.col15
        },
        {
          title: getTitles('T-11674'),
          values: <Text variant="D22">{getFormatDate(item?.ordered_date)}</Text>,
          columnWidth: styles.col15
        },

        {
          title: getTitles('T-10103'),
          values: <Text variant="D22">{item?.status?.id ? item?.status?.name : ' - '}</Text>
        },
        {
          title: getTitles('T-11686'),
          values: <Text variant="D22">{item?.total_amount}</Text>,
          columnWidth: styles.col17
        }
      ];
    } else {
      return {
        title: (
          <Text
            style={styles.tableItemId}
            variant="D22"
            onClick={() => handleOrderClick(item?._id)}>
            {item?.order_id}
          </Text>
        ),
        details: [
          {
            subTitle: getTitles('T-11684'),
            action: (
              <Box>
                <Text variant="D20">{item?.order_by?.full_name}</Text>
                {(item?.order_by?.employee_id ||
                  item?.order_by?.farmer_id ||
                  item?.order_by?.user_id) && (
                  <Text variant="D22">{`(${item?.order_by?.employee_id || item?.order_by?.farmer_id || item?.order_by?.user_id})`}</Text>
                )}
                <Text variant="D20">{item?.order_by?.phone_number}</Text>
              </Box>
            )
          },
          {
            subTitle: getTitles('T-11685'),
            action: <Text variant="D20">{item?.no_of_products}</Text>
          },
          {
            subTitle: getTitles('T-11674'),
            action: <Text variant="D20">{getFormatDate(item?.ordered_date)}</Text>
          },
          {
            subTitle: getTitles('T-11675'),
            action: (
              <Text variant="D20">
                {item?.delivered_date ? getFormatDate(item?.delivered_date) : ' - '}
              </Text>
            )
          },
          {
            subTitle: getTitles('T-10103'),
            action: (
              <UISelect
                containerStyles={styles.statusSelectCont}
                inputStyle={styles.statusSelectInput}
                value={item?.status?.id || defaultValues.orderStatus.Pending}
                onChange={(e) => {
                  dispatch(
                    updateEcomOrderDetailsRequest({ _id: item?._id, status: e.target.value })
                  );
                }}
                options={defaultValues.orderStatusSelectOptions}
              />
            )
          },
          {
            subTitle: getTitles('T-11686'),
            action: <Text variant="D20">{item?.total_amount}</Text>
          }
        ]
      };
    }
  });

  const ResItems = () => {
    return (
      <Box sx={styles.resItemCont}>
        {filterDate === defaultValues.orderDueDateRange.custom && (
          <DatePickers
            dateValue={dateValue}
            onDateChange={handleCustomDateChange}
            containerStyles={styles.uiDateCont}
            inputStyle={styles.uiDateInput}
          />
        )}
        <UISelect
          options={defaultValues.orderDueDateRangeOptions.map((item) => ({
            value: item.id,
            title: item.type
          }))}
          value={filterDate}
          onChange={(e) => handleDueDateRange(e.target.value)}
          containerStyles={styles.resDateSelectCont}
          inputStyle={styles.resDateSelectInput}
        />
      </Box>
    );
  };

  return (
    <>
      {!actionType ? (
        <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-11687')}>
          <UILayout
            handleSearch={handleSearch}
            isMultiSelectFilterOption
            searchBarPlaceHolder={getTitles('T-11681')}
            handleRefresh={handleRefresh}
            handleSort={handleSort}
            handleFilter={handleFilter}
            optionsForSorting={defaultValues.farmerSortOptions}
            optionsForFiltering={defaultValues.orderStatusFilterOptions}
            currentlyActiveFilterOption={filter}
            currentlyActiveSortOption={sort}
            message={getErrorMessage('E-10280')}
            options={defaultValues.orderDueDateRangeOptions}
            showMlist={false}
            showSelect={false}
            selectLabel={null}
            selectValue={filterDate}
            isSelect={!res}
            selectedOption={handleDueDateRange}
            selectContainerStyle={styles.uiDateSelectContainerStyle}
            showDatePicker={filterDate === defaultValues.orderDueDateRange.custom && !res}
            dateValue={dateValue}
            handleDateChange={handleCustomDateChange}
            dateCont={styles.uiDateCont}
            dateInput={styles.uiDateInput}
            tableItems={tableData}
            showNoData={!orderList?.length > 0}
            showDataTable={!res}
            tableHeaderTextStyle={styles.headertxtStyle}
            tableSuperSubCont={styles.tableSubCont(orderList?.length > 0)}
            beforeDateTableItems={res && ResItems()}
            currPage={currentPage}
            pageCount={totalPages}
            showPagination={totalPages > 1}
            handlePagination={handlePagination}
          />
        </Screen>
      ) : (
        <EcomOrderDetails order_Id={order_Id} setOrder_Id={setOrder_Id} />
      )}
    </>
  );
};
