import { defaultValues, getErrorMessage, getSuccessMessage, SCREEN_PATH } from '../../constants';
import { Screen, Text, UILayout } from '../../ui-kits';
import { checkReadOnly, getTitles, resetMessages } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLabFarmer,
  getLabFarmerCurPage,
  getLabFarmerErrorMsg,
  getLabFarmerLoading,
  getLabFarmerParams,
  getLabFarmerSuccessMsg,
  getLabFarmerTotalPage,
  getUserData
} from '../../services/redux/selectors';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import {
  getlabFarmersRequest,
  resetLabFarmerMsg,
  setLabFarmersDetails,
  setLabFarmersParams,
  updateDetailsPg
} from '../../services/redux/slices';
import { BasicProfile } from '../../components';
import { styles } from './styles';

export const LabFarmer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const farmersList = useSelector(getLabFarmer);
  const errorMsg = useSelector(getLabFarmerErrorMsg);
  const successMsg = useSelector(getLabFarmerSuccessMsg);
  const isLoading = useSelector(getLabFarmerLoading);
  const totalPages = useSelector(getLabFarmerTotalPage);
  const currentPage = useSelector(getLabFarmerCurPage);
  const userData = useSelector(getUserData);
  const farmerParams = useSelector(getLabFarmerParams);

  const [farmers, setFarmers] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(null);
  const [sort, setSort] = useState([]);

  useEffect(() => {
    handleFarmer();
  }, []);

  useEffect(() => {
    if (farmersList) {
      setFarmers(farmersList);
    }
  }, [farmersList]);

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, defaultValues.employeeRol.laboratoryIncharge));
  }, [userData]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetLabFarmerMsg()));
  }, [successMsg, errorMsg]);

  const handlePagination = (e, data) => {
    handleFarmer({ page: data });
  };
  const handleSearch = (data) => {
    handleFarmer({ search_text: data, page: 1 });
  };

  const handleFarmer = (data) => {
    const payload = { ...farmerParams, ...data };
    dispatch(setLabFarmersParams(payload));
    dispatch(getlabFarmersRequest(payload));
  };

  const handleSort = (data) => {
    setSort(data);
    handleFarmer({ page: 1, sort: data });
  };

  const handleRefresh = () => {
    setSort([]);
    handleFarmer({ page: 1, search_text: null, sort: null });
  };

  const navigateToCattleList = (item) => {
    dispatch(updateDetailsPg(true));
    navigate(`${SCREEN_PATH.LAB_FARMER_CATTLE_LIST}/${item._id}`);
    dispatch(setLabFarmersDetails(item));
  };

  const tableData =
    farmers.length > 0 &&
    farmers.map((item) => {
      if (!defaultValues.isResponsive) {
        return [
          {
            title: getTitles('T-11406'),
            values: (
              <Text style={styles.id} variant="D22">
                {item?.farmer_id}
              </Text>
            ),
            columnWidth: styles.columnWidth
          },
          {
            title: getTitles('T-10802'),
            columnWidth: styles.columnWidth,
            values: (
              <BasicProfile
                title={item.full_name}
                url={item?.id_photo}
                startIconStyle={styles.profileImg}
                textVariant="D22"
              />
            )
          },
          {
            title: getTitles('T-10440'),
            values: <Text variant="D22">{item?.phone_number}</Text>,
            columnWidth: styles.columnWidth
          },
          {
            title: getTitles('T-10734'),
            values: <Text variant="D22">{item?.location}</Text>,
            columnWidth: styles.columnWidth
          },
          {
            title: getTitles('T-10173'),
            columnWidth: styles.columnWidth,
            values: (
              <Text
                variant="D22"
                style={styles.cattleList}
                onClick={() => navigateToCattleList(item)}>
                {getTitles('T-11412')}
              </Text>
            )
          }
        ];
      } else {
        return {
          title: (
            <BasicProfile
              title={item.full_name}
              url={item?.id_photo}
              startIconStyle={styles.profileImg}
              textVariant="D22"
            />
          ),
          details: [
            {
              subTitle: getTitles('T-10751'),
              action: <Text variant="D20">{item?.farmer_id}</Text>
            },
            {
              subTitle: getTitles('T-10440'),
              action: <Text variant="D20">{item?.phone_number}</Text>
            },
            {
              subTitle: getTitles('T-10734'),
              action: <Text variant="D20">{item?.location}</Text>
            },
            {
              subTitle: getTitles('T-10173'),
              action: (
                <Text
                  variant="D20"
                  style={styles.cattleList}
                  onClick={() => navigateToCattleList(item)}>
                  {getTitles('T-11412')}
                </Text>
              )
            }
          ]
        };
      }
    });

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-11162')}>
      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mt: 2 }}>
          {getTitles('T-11162')}
        </Text>
      )}

      <UILayout
        tableItems={tableData}
        showNoData={!farmers.length > 0}
        showDataTable={!defaultValues.isResponsive}
        currPage={currentPage}
        pageCount={totalPages}
        showPagination={totalPages > 1}
        handlePagination={handlePagination}
        message={getErrorMessage('E-10073')}
        handleSearch={handleSearch}
        searchBarPlaceHolder={getTitles('T-11005')}
        isReadOnly={isReadOnly}
        currentlyActiveSortOption={sort}
        handleRefresh={handleRefresh}
        handleSort={handleSort}
        optionsForSorting={defaultValues.farmerSortOptions}
      />
    </Screen>
  );
};
