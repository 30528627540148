import { Box } from '@mui/material';
import { styles } from '../styles';
import { UIAutocompleted, UITextInputWithTitle } from '../../../../ui-kits';
import { getTitles } from '../../../../utils';
import { defaultValues } from '../../../../constants';

export const AdjustmentCompos = ({
  productList = [],
  searchList = () => {},
  handleBlur,
  setFieldValue,
  setBtndisabled,
  values,
  currRender,
  touched,
  errors,
  handleChange,
  adjustments = []
}) => {
  let timer;
  const { view } = defaultValues.actionType;

  const getList = (opt) => {
    const ids = adjustments.map((el) => el.inward_entry_id);

    return opt.filter((em) => !ids.includes(em.inward_entry_id)) || [];
  };

  return (
    <Box sx={styles.nameCont}>
      <UIAutocompleted
        name="adjustment"
        title={getTitles('T-11437')}
        textInputStyles={styles.autoInput}
        containerStyle={styles.autoCont}
        titleStyle={styles.adjustmentTitleStyle}
        options={getList(productList)}
        optionLabel={(item) => item.product_id?.name || ''}
        onTyping={(val) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            if (val.length > 2) {
              searchList(val);
            }
          }, 500);
        }}
        onBlur={handleBlur}
        placeHolder={getTitles('T-10077')}
        selectedValue={(val) => {
          setFieldValue('adjustment', { ...val, adjust_quantity: '' });
          setBtndisabled(false);
        }}
        preSelectedValue={values.adjustment?.product_id && values.adjustment}
        width={styles.width}
        disabled={currRender === view}
      />

      {values.adjustment?.product_id && (
        <UITextInputWithTitle
          title={getTitles('T-11457')}
          value={values.adjustment?.product_id?.product_id || ''}
          width={styles.width}
          readOnly={!values.adjustment?.product_id}
          inputStyle={styles.input}
        />
      )}

      {values.adjustment?.product_id && (
        <UITextInputWithTitle
          title={getTitles('T-11580')}
          value={values.adjustment?.balance_qty || ''}
          width={styles.width}
          readOnly={true}
          inputStyle={styles.input}
        />
      )}
      <UITextInputWithTitle
        name={`adjustment.adjust_quantity`}
        title={getTitles('T-11633')}
        value={values.adjustment?.adjust_quantity}
        error={touched.adjustment?.adjust_quantity && errors.adjustment?.adjust_quantity}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={getTitles('T-10360')}
        width={styles.width}
        readOnly={!values.adjustment?.product_id?._id}
        inputStyle={styles.input}
        containerStyles={styles.adjustQtyCont(values)}
        allowOnlyNumbers={true}
        required={values.adjustment?.product_id?._id}
      />
    </Box>
  );
};
