import { all } from 'redux-saga/effects';
import { authSaga } from './authSaga';
import { manageProcessSaga } from './manageProcessSaga';
import { employeeSaga } from './employeeSaga';
import { farmerSaga } from './farmerSaga';
import { vetSaga } from './vetSaga';
import { aiWorkerSaga } from './aiWorkerSaga';
import { societySaga } from './societySaga';
import { laboratorySaga } from './laboratorySaga';
import { cattleSaga } from './cattleSaga';
import { insuranceSaga } from './insuranceSaga';
import { govtOfficialSaga } from './govtOfficialSaga';
import { callCenterSaga } from './callCenterSaga';
import { inventorySaga } from './inventorySaga';
import { taskSaga } from './tasksSaga';
import { eCommerceSaga } from './eCommersSaga';
import { labFarmerSaga } from './labFarmerSaga';

const root = function* root() {
  yield all([
    authSaga(),
    manageProcessSaga(),
    employeeSaga(),
    vetSaga(),
    aiWorkerSaga(),
    farmerSaga(),
    societySaga(),
    cattleSaga(),
    insuranceSaga(),
    laboratorySaga(),
    govtOfficialSaga(),
    callCenterSaga(),
    inventorySaga(),
    labFarmerSaga(),
    taskSaga(),
    eCommerceSaga()
  ]);
};

export default root;
