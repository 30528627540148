import { color } from '../../../theme';

export const styles = {
  name: {
    textDecoration: 'underline',
    ml: 1,
    cursor: 'pointer',
    overflowWrap: 'anywhere',
    '&:hover': {
      color: color.link
    }
  },
  container: {
    backgroundColor: color.secondaryBackground,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '280px',
    mt: 1,
    visibility: 'hidden'
  }
};
