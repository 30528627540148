import { Footer } from '../../components/common/footer';
import { defaultValues } from '../../constants';
import { Screen } from '../../ui-kits/screen';
import Box from '@mui/material/Box';
import { loginStyles } from './style';
import logo from '../../assets/icons/logoLarge.png';
import { color } from '../../theme';
import { OtpInput, Text, UIAutocompleted, UIButton, UIPhoneInputTitle } from '../../ui-kits';
import { useEffect, useState } from 'react';
import { getErrorMessage, getSuccessMessage, lang } from '../../constants/getMessage';
import {
  loginRequest,
  resendOtpRequest,
  resetAuthMessages,
  updateLanguage,
  updatePhone,
  updatePhoneVerified,
  verifyLoginRequest
} from '../../services/redux/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationLanguage, getTitles, resetMessages } from '../../utils/commonFunction';
import {
  authLoading,
  getAuthErrorMessage,
  getAuthSuccessMessage,
  getPhone,
  isPhoneVerified
} from '../../services/redux/selectors';
import { toast } from 'react-toastify';

export const LoginPage = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(authLoading);
  const phoneVerified = useSelector(isPhoneVerified);
  const successMessage = useSelector(getAuthSuccessMessage);
  const errorMessage = useSelector(getAuthErrorMessage);
  const phone = useSelector(getPhone);
  const [otpSend, setOtpSend] = useState(false);
  const [otp, setOtp] = useState('');
  const [btnDisabled, setBtndisabled] = useState(true);
  const [otpErrorMessage, setOtpErrorMessage] = useState('');

  useEffect(() => {
    if (phoneVerified) {
      setOtpSend(true);
      setBtndisabled(true);
    } else {
      setOtpSend(false);
      setBtndisabled(true);
    }
  }, [phoneVerified]);

  useEffect(() => {
    if (successMessage) {
      toast.success(getSuccessMessage(successMessage));
    }
    if (errorMessage) {
      toast.error(getErrorMessage(errorMessage));
    }
    resetMessages(() => dispatch(resetAuthMessages()));
  }, [successMessage, errorMessage]);

  const handleButtonClick = () => {
    setOtpSend(false);
    if (phoneVerified) {
      dispatch(verifyLoginRequest({ phone: phone, otp: parseInt(otp), fcm_token: '' }));
    } else {
      dispatch(loginRequest({ phone: phone }));
    }
  };

  const handlePhoneChange = (val) => {
    dispatch(updatePhone(val));
  };
  const handlePhoneError = (error) => {
    setBtndisabled(error);
  };
  const handleOtpSend = (val) => {
    setOtpSend(val);
  };

  const handleOtpChange = (val) => {
    if (!isNaN(Number(val))) {
      setOtp(val);
      if (!val) {
        setOtpErrorMessage(getErrorMessage('E-10008'));
      } else {
        setOtpErrorMessage('');
      }
    } else if (!otp) {
      setOtpErrorMessage(getErrorMessage('E-10008'));
    }
  };

  const handleOtpResend = () => {
    dispatch(resendOtpRequest({ phone: phone }));
    setBtndisabled(true);
    setTimeout(() => {
      setBtndisabled(false);
    }, 20000);
  };

  const handleOtpBlur = () => {
    if (otp.length !== 6) {
      setOtpErrorMessage(getErrorMessage('E-10008'));
    }
  };

  return (
    <Screen showHeader={!defaultValues.isResponsive} isLoading={isLoading}>
      <Box sx={loginStyles.container}>
        <Box sx={loginStyles.languageBox}>
          <UIAutocompleted
            options={defaultValues.appLanguages}
            optionLabel={(item) => item.title}
            containerStyle={loginStyles.autoCont}
            autoCompleteStyle={loginStyles.autoStyle}
            selectedValue={(val) => {
              dispatch(updateLanguage({ language: val?.value || lang.english }));
            }}
            preSelectedValue={getApplicationLanguage()}
            clearIcon={null}
            PaperBoxStyle={loginStyles.paperBox}
            textInputStyles={loginStyles.textInp}
            disableClearable
          />
        </Box>
        <Box sx={loginStyles.subContainer}>
          <Box sx={loginStyles.sectionBox}>
            <Box sx={loginStyles.imgBox}>
              <img src={logo} style={loginStyles.logo} />
            </Box>
          </Box>

          <Box sx={loginStyles.sectionBox2}>
            <Box sx={loginStyles.sectionSubBox}>
              <Text variant="boldH48" style={loginStyles.heading}>
                {phoneVerified ? getTitles('T-10055') : getTitles('T-10054')}
              </Text>
              <UIPhoneInputTitle
                phoneValue={phone}
                onChange={handlePhoneChange}
                handleError={handlePhoneError}
                disabled={phoneVerified}
                onBlurErrorMsg={'E-10002'}
                required={true}
              />

              {phoneVerified && (
                <>
                  <Text variant="D16" style={loginStyles.otpDesc1} textColor={color.secondaryText}>
                    {getTitles('T-10056')}
                  </Text>

                  <Text
                    variant="D16"
                    style={loginStyles.otpDesc2}
                    onClick={() => {
                      setOtp('');
                      setBtndisabled(false);
                      setOtpErrorMessage('');
                      dispatch(updatePhoneVerified(false));
                    }}>
                    <u>{getTitles('T-10057')}</u>
                  </Text>
                  <OtpInput
                    otp={otp}
                    onChange={handleOtpChange}
                    onBlur={() => handleOtpBlur()}
                    errorMsg={otpErrorMessage}
                    onResendClick={handleOtpResend}
                    otpSend={otpSend}
                    handleOtpSend={handleOtpSend}
                  />
                </>
              )}
              <UIButton
                title={phoneVerified ? getTitles('T-10035') : getTitles('T-10002')}
                disabled={phoneVerified ? otp.length < 6 || otpErrorMessage : btnDisabled}
                style={loginStyles.btn}
                onClick={handleButtonClick}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {!defaultValues.isResponsive && <Footer type={defaultValues.appLogicalTexts.login} />}
    </Screen>
  );
};
