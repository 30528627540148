import { createSelector } from 'reselect';

export const authLoading = createSelector([(state) => state.auth.isLoading], (d) => d);
export const isLoginPage = createSelector([(state) => state.auth.isLoginPage], (d) => d);
export const authStatus = createSelector([(state) => state.auth.isAuthenticated], (d) => d);
export const isPhoneVerified = createSelector([(state) => state.auth.isPhoneVerified], (d) => d);
export const getAuthSuccessMessage = createSelector(
  [(state) => state.auth.successMessage],
  (d) => d
);
export const getAuthErrorMessage = createSelector([(state) => state.auth.errorMessage], (d) => d);
export const getUserData = createSelector([(state) => state.auth.userData], (d) => d);
export const getMainMenu = createSelector([(state) => state.auth.mainMenu], (d) => d);
export const getSubMenu = createSelector([(state) => state.auth.subMenu], (d) => d);
export const getProfileData = createSelector([(state) => state.auth.profileData], (d) => d);
export const getPhone = createSelector([(state) => state.auth.phone], (d) => d);
export const getDetailsPg = createSelector([(state) => state.auth.detailPg], (d) => d);
export const getScroll = createSelector([(state) => state.auth.scrollToTop], (d) => d);
export const getCollapse = createSelector([(state) => state.auth.collapse], (d) => d);
export const getSidebarScrollPosition = createSelector(
  [(state) => state.auth.sidebarScroll],
  (d) => d
);
export const getEcommerce = createSelector([(state) => state.auth.eCommerce], (d) => d);

export const getNotificationList = createSelector([(state) => state.auth.notifications], (d) => d);
export const notificationCurrPage = createSelector([(state) => state.auth.currentPage], (d) => d);
export const notificationTotalPage = createSelector([(state) => state.auth.totalPage], (d) => d);
export const notificationNextPage = createSelector([(state) => state.auth.nextPage], (d) => d);
export const getEcommerceLoginOpen = createSelector(
  [(state) => state.auth.eCommerceLoginModalOpen],
  (d) => d
);

export const ecomPhoneVerified = createSelector(
  [(state) => state.auth.ecomIsPhoneVerified],
  (d) => d
);
export const ecomGetPhone = createSelector([(state) => state.auth.ecomPhone], (d) => d);
export const getIsRegisteredUser = createSelector([(state) => state.auth.isRegistered], (d) => d);
export const getEcomProfile = createSelector([(state) => state.auth.ecomIsProfile], (d) => d);
export const getNotificationTotalUnreadCount = createSelector(
  [(state) => state.auth.totalUnreadNotifications],
  (d) => d
);
