import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDistrict,
  getDistrictCurrPage,
  getDistrictParams,
  getDistrictTotalPage,
  getErrorMsg,
  getStateNavDetails,
  getSuccessMsg,
  processLoading
} from '../../../services/redux/selectors/manageProccess';
import { useFormik } from 'formik';
import { districtSchema } from '../../../utils/schemas';
import {
  addDistrictRequest,
  getDistrictRequest,
  resetManageProcessMsg,
  selectedDistrict,
  setDistrictParams,
  setTalukParams,
  updateDistrictRequest,
  updateStateNavDetails
} from '../../../services/redux/slices';
import { toast } from 'react-toastify';
import { SCREEN_PATH, defaultValues, getErrorMessage, getSuccessMessage } from '../../../constants';
import { checkReadOnly, getTitles, resetMessages } from '../../../utils/commonFunction';
import {
  ActionButton,
  EditButton,
  NavigationText,
  PopupModal,
  Screen,
  Text,
  UILayout,
  UISaveAndAdd,
  UITabs,
  UITextInputWithTitle
} from '../../../ui-kits';
import { ModalButtons } from '../../common/modal-buttons';
import { useLocation, useNavigate } from 'react-router-dom';
import { color } from '../../../theme';
import { getUserData } from '../../../services/redux/selectors';

export const ManageDistrict = () => {
  const styles = {
    title: {
      color: color.link,
      textDecoration: 'underline',
      cursor: 'pointer',
      overflowWrap: 'anywhere'
    },
    columnWidth: '40%',
    subClmWdth: defaultValues.isMobile ? '22%' : '23%',
    overLap: { overflowWrap: 'anywhere' }
  };

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();

  const districtList = useSelector(getDistrict);
  const errorMsg = useSelector(getErrorMsg);
  const isLoading = useSelector(processLoading);
  const successMsg = useSelector(getSuccessMsg);
  const details = useSelector(getStateNavDetails);
  const userData = useSelector(getUserData);
  const totalPages = useSelector(getDistrictTotalPage);
  const currentPage = useSelector(getDistrictCurrPage);
  const districtParams = useSelector(getDistrictParams);

  const [open, setOpen] = useState(false);
  const [district, setDistrict] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [state_id, setStateId] = useState('');
  const [state, setState] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(null);
  const [renderType, setRenderType] = useState(null);

  const { process, village } = defaultValues.appLogicalTexts;
  const typeAssign = {
    type: location?.state?.renderType === village ? 2 : null,
    permission_id:
      location?.state?.renderType === village ? defaultValues.employeeRol.villageManager : null
  };

  const { values, errors, touched, handleBlur, resetForm, setFieldValue, isValid, dirty } =
    useFormik({
      initialValues: {
        name: '',
        code: ''
      },
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: districtSchema
    });

  useEffect(() => {
    location?.state && setIsReadOnly(checkReadOnly(userData, location?.state?.access));
    if (location.state?.renderType) {
      setRenderType(location.state?.renderType);
    }
  }, [userData, location?.state]);

  useEffect(() => {
    if (details) {
      setState(details.state);
      setStateId(details.state_id);
      handleDistrict();
    }
  }, [details]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
      if (successMsg === 'S-10019') {
        handleDistrict();
      }
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetManageProcessMsg()));
  }, [successMsg, errorMsg]);

  const handleDistrict = (val) => {
    let payload = { state_id: [details?.state_id], ...districtParams, ...typeAssign, ...val };
    dispatch(setDistrictParams(payload));
    dispatch(getDistrictRequest(payload));
  };

  const handleModalClose = () => {
    setOpen(false);
    setIsEdit(false);
    resetForm();
  };

  useEffect(() => {
    if (districtList) {
      setDistrict(districtList);
    }
  }, [districtList]);

  const handleSaveAndClose = () => {
    if (isEdit) {
      dispatch(updateDistrictRequest(values));
    } else {
      dispatch(addDistrictRequest({ state_id, ...values }));
    }
    handleModalClose();
  };

  const handleAddAnother = () => {
    dispatch(addDistrictRequest({ state_id, ...values }));
    resetForm();
  };

  const handleStatus = (id, status) => {
    dispatch(updateDistrictRequest({ _id: id, is_active: !status }));
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setFieldValue('_id', data._id);
    setFieldValue('name', data.name);
    setFieldValue('code', data.code);
    setOpen(true);
    setFormChanged(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFormChanged(true);
  };

  const handleSearch = (data) => {
    handleDistrict({ search_text: data || null });
  };

  const handlePagination = (e, data) => {
    handleDistrict({ page: data });
  };

  const handleAdd = () => {
    setOpen(true);
  };

  const tableData = district.map((item, idx) => [
    renderType === village && {
      title: getTitles('T-11407'),
      values: `${idx + 1}`,
      valueTextStyle: { ml: 3 }
    },
    {
      title: getTitles('T-10069'),
      columnWidth: styles.columnWidth,
      values: (
        <Text
          variant="D20"
          style={styles.title}
          onClick={() => {
            dispatch(selectedDistrict(currentPage));
            dispatch(
              updateStateNavDetails({
                ...details,
                district_id: item._id,
                district: item.name,
                state: state
              })
            );
            dispatch(setTalukParams({}));

            navigation(SCREEN_PATH.MANAGE_TALUK, {
              state: location?.state
            });
          }}>
          {item.name}
        </Text>
      )
    },
    {
      title: getTitles('T-10160'),
      columnWidth: styles.subClmWdth,
      values: (
        <Text style={styles.overLap} variant="D20">
          {item.code}
        </Text>
      )
    },
    renderType === process && {
      title: getTitles('T-10103'),
      columnWidth: styles.subClmWdth,
      values: (
        <ActionButton
          status={item.is_active}
          alterTitle={defaultValues.confirmationModel.changeDistrictStatus.title(item.is_active)}
          alterSubTitle={defaultValues.confirmationModel.changeAdminStatus.subtitles}
          onStatusChange={() => handleStatus(item._id, item.is_active)}
          isReadOnly={isReadOnly}
        />
      )
    },
    renderType === process && {
      title: getTitles('T-10084'),
      values: (
        <EditButton
          status={item.is_active}
          onClick={() => handleEdit(item)}
          isReadOnly={isReadOnly}
        />
      )
    }
  ]);

  return (
    <Screen
      showSideBar={true}
      isLoading={isLoading}
      currentPage={renderType === process ? getTitles('T-10079') : getTitles('T-11383')}>
      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mt: 1 }}>
          {getTitles('T-10079')}
        </Text>
      )}
      <UITabs tabItems={defaultValues.manageProcess.tabList} />

      <NavigationText
        list={[
          { title: getTitles('T-10070'), route: location?.state?.parentRoute },
          { title: state }
        ]}
      />
      <UILayout
        handleSearch={handleSearch}
        onlySearchAlphabets={true}
        searchBarPlaceHolder={getTitles('T-10145')}
        handleAddButton={renderType === process && handleAdd}
        tableItems={tableData}
        tableTitle={renderType === process ? getTitles('T-10164') : getTitles('T-11454')}
        showNoData={!district.length > 0}
        currPage={currentPage}
        handlePagination={handlePagination}
        pageCount={totalPages}
        showPagination={totalPages > 1}
        message={getErrorMessage('E-10031')}
        isReadOnly={isReadOnly}
      />

      <PopupModal
        title={isEdit ? getTitles('T-10153') : getTitles('T-10149')}
        onClose={handleModalClose}
        showModel={open}>
        <UITextInputWithTitle
          name="name"
          value={values.name}
          title={getTitles('T-10069')}
          placeHolder={getTitles('T-10157')}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && errors.name}
          allowOnlyAlphabets
          maxLength={30}
        />
        <UITextInputWithTitle
          name="code"
          value={values.code}
          title={getTitles('T-10160')}
          placeHolder={getTitles('T-10367')}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.code && errors.code}
          maxLength={10}
        />

        {!isEdit && (
          <UISaveAndAdd
            onAddAnother={handleAddAnother}
            disabled={!isValid || !dirty || !formChanged}
          />
        )}
        <ModalButtons
          onPrimaryBtnClick={handleSaveAndClose}
          primaryBtnTitle={isEdit ? getTitles('T-10046') : getTitles('T-10063')}
          disabled={!isValid || !dirty || !formChanged}
        />
      </PopupModal>
    </Screen>
  );
};
