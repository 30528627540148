import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme';

const sm = defaultValues.isMobile;
const res = defaultValues.isResponsive;

export const styles = {
  container: {
    width: '100%',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    p: 1,
    boxSizing: 'border-box'
  },
  img: {
    width: sm ? '70px' : res ? '110px' : '150px',
    height: sm ? '70px' : res ? '110px' : '150px'
  },
  subCont: {
    width: '100%',
    display: 'flex',
    flexDirection: sm ? 'column' : 'row',
    gap: '10px'
  },
  leftCont: {
    width: sm ? '100%' : '50%'
  },
  imageCont: {
    width: sm ? '100%' : '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: sm ? 'center' : 'start'
  },

  detailsCommonSubCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  secondaryText: {
    color: color.secondaryText
  },
  statusTxt: (status) => ({
    backgroundColor:
      (status === 1 && `${color.primaryText}`) ||
      ([2].includes(status) && `${color.successAction}`) ||
      ([3].includes(status) && `${color.primary}`),
    color: `${color.tertiaryText}`,
    px: res ? 1 : 5,
    borderRadius: '4px',
    fontSize: res ? '1rem' : '1.375rem'
  })
};
