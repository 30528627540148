import { defaultValues } from '../../../constants';
import { color } from '../../../theme';

export const styles = {
  name: {
    textDecoration: 'underline',
    ml: 1,
    cursor: 'pointer',
    overflowWrap: 'anywhere',
    '&:hover': {
      color: color.link
    }
  },
  container: {
    backgroundColor: color.primaryBackground,
    alignItems: 'center',
    border: 1,
    borderRadius: '12px',
    borderColor: color.secondaryBorder
  },
  grid: { padding: 2, justifyContent: 'center' },
  divider: (cW) => ({
    borderBottom: `2px solid ${color.primaryBorder}`,
    height: '10px',
    width: cW ? cW : defaultValues.isResponsive ? '100%' : 'calc(((100%/3 )* 2) - 5px)',
    mb: defaultValues.isResponsive ? 2 : 2.5,
    mt: defaultValues.isResponsive ? 0.2 : 3
  }),
  button: (isCurr) => ({
    width: '18%',
    height: '60px',
    backgroundColor: isCurr ? color.primary : color.secondaryBackground,
    color: isCurr ? color.tertiaryText : color.primaryText,
    fontSize: '1.3rem',
    '&:hover': {
      bgcolor: isCurr ? color.primary : color.secondaryBackground,
      color: isCurr ? color.tertiaryText : color.primaryText
    },
    border: `1px solid ${!isCurr ? color.primaryBorder : color.secondaryBackground}`
  }),
  modalButton: {
    width: '20%',
    height: '50px',
    fontSize: '1.25rem'
  },
  modalTextInput: {
    width: '80%',
    height: '60px'
  },
  searchBarCont: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  searchSub: {
    width: '70%',
    position: 'relative'
  },
  dropdown: {
    width: '100%',
    height: '200px',
    overflow: 'auto',
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px',
    mb: 1,
    position: 'absolute',
    backgroundColor: color.secondaryBackground,
    zIndex: 2
  },
  dropItem: {
    width: '100%',
    p: 1,
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: color.primaryBackground
    }
  }
};
