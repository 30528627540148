import { Box, Divider } from '@mui/material';
import { color, globalStyles } from '../../theme';
import { Screen, Text } from '../../ui-kits';

export const Disclaimer = () => {
  const styles = {
    container: {
      display: 'flex',
      padding: '16px',
      justifyContent: 'center',
      backgroundColor: color.primaryBackground,
      mt: 4
    },
    subContainer: { width: '90%', textAlign: 'left' }
  };

  const textsArr = [
    {
      title: '1. No Professional Veterinary-Client Relationship',
      description: `The use of this website and its content does not establish a veterinary-client-patient relationship. Always seek the direct advice of a qualified veterinarian regarding your cattle’s health or specific medical conditions. Do not disregard professional advice or delay seeking it based on information from this site. `
    },
    {
      title: '2. Accuracy of Information',
      description: `My Gopalan makes no guarantees regarding the completeness, accuracy, or timeliness of the information presented on the website. While we regularly update our content, advancements in veterinary medicine and agricultural equipment may occur that are not immediately reflected on this site.`
    },
    {
      title: '3. Use of Products and Services',
      description: `Any products, equipment, or services mentioned on this website are provided as recommendations based on general best practices. Before using any specific product or service, please consult with a veterinary or agricultural professional to determine its suitability for your individual circumstances.`
    },
    {
      title: '4. Liability',
      description: `My Gopalan and its affiliates, employees, or contributors will not be held responsible for any damages, injuries, or losses that may arise from the use of the information, products, or services listed on this website. You are solely responsible for the decisions and actions taken in managing your livestock or agricultural operations.`
    },
    {
      title: '5. Third-Party Links',
      description: `This website may contain links to external websites or resources for further information. My Gopalan is not responsible for the content or practices of third-party sites and does not endorse any external sources linked within our site.`
    },
    {
      title: '6. Updates to Disclaimer',
      description: `My Gopalan reserves the right to update or modify this disclaimer at any time without prior notice. Please check this page regularly to stay informed about any changes.`
    }
  ];

  return (
    <Screen showHeader={true}>
      <Box sx={styles.container}>
        <Box sx={styles.subContainer}>
          <Text variant="boldH40">Disclaimer</Text>
          <Text variant="D20" style={globalStyles.mt(1)}>
            The information provided on the My Gopalan website is intended for general informational
            purposes related to cattle management, veterinary services, and agricultural practices.
            While we strive to ensure that the content is accurate, up-to-date, and relevant, it
            should not be considered as a substitute for professional veterinary advice, diagnosis,
            or treatment.
          </Text>

          {textsArr.map((item, idx) => (
            <Box key={idx}>
              <Text variant="BoldH22" style={globalStyles.mt(1)}>
                {item.title}
                <Text variant="D20" style={globalStyles.mt(1)}>
                  {item.description}
                </Text>
              </Text>
              <Divider sx={globalStyles.mt(1)} />
            </Box>
          ))}
        </Box>
      </Box>
    </Screen>
  );
};
