import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isLoginPage: false,
  successMessage: null,
  errorMessage: null,
  currentPage: null,
  nextPage: null,
  totalPage: null,
  currRender: null,
  taskDetails: null,
  myTasks: [],
  associatedFarmers: [],
  assocCurrentPage: null,
  assocNextPage: null,
  assocTotalPage: null,
  products: []
};

const tasksSlice = createSlice({
  initialState,
  name: 'tasks',
  reducers: {
    resetTasks(state) {
      state.isLoading = false;
      state.isLoginPage = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
    },
    setCommonTasksCurrRender(state, action) {
      state.currRender = action.payload;
    },
    setCommonTaskDetails(state, action) {
      state.taskDetails = action.payload;
    },
    resetCommonTasksMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
    },
    getMyTasksRequest(state) {
      state.isLoading = true;
    },
    getMyTasksSuccess(state, action) {
      state.isLoading = false;
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
      state.myTasks = action.payload.data;
    },
    getMyTasksFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.myTasks = [];
    },
    addMyTasksRequest(state) {
      state.isLoading = true;
    },
    addMyTasksSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    addMyTasksFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    updateMyTasksRequest(state) {
      state.isLoading = true;
    },
    updateMyTasksSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    updateMyTasksFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getAssociatedFarmerTasksRequest(state) {
      state.isLoading = true;
    },
    getAssociatedFarmerTasksSuccess(state, action) {
      state.isLoading = false;
      state.assocCurrentPage = null;
      state.assocNextPage = null;
      state.assocTotalPage = null;
      state.associatedFarmers = action.payload.data;
    },
    getAssociatedFarmerTasksFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.associatedFarmers = [];
    },
    getCommonTaskDetailsRequest(state) {
      state.isLoading = true;
    },
    getCommonTaskDetailsSuccess(state, action) {
      state.isLoading = false;
      state.taskDetails = action.payload.data;
      state.successMessage = action.payload.status_code;
    },
    getCommonTaskDetailsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getProductsByUserRequest(state) {
      state.isLoading = true;
    },
    getProductsByUserSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload.data;
    },
    getProductsByUserFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    clearTaskFarmers(state) {
      state.associatedFarmers = [];
    }
  }
});

export const {
  setCommonTasksCurrRender,
  getMyTasksRequest,
  getMyTasksFailure,
  getMyTasksSuccess,
  addMyTasksFailure,
  addMyTasksRequest,
  addMyTasksSuccess,
  updateMyTasksFailure,
  updateMyTasksRequest,
  updateMyTasksSuccess,
  getAssociatedFarmerTasksFailure,
  getAssociatedFarmerTasksRequest,
  getAssociatedFarmerTasksSuccess,
  getCommonTaskDetailsFailure,
  getCommonTaskDetailsRequest,
  getCommonTaskDetailsSuccess,
  resetCommonTasksMessages,
  setCommonTaskDetails,
  getProductsByUserFailure,
  getProductsByUserRequest,
  getProductsByUserSuccess,
  clearTaskFarmers
} = tasksSlice.actions;

export default tasksSlice.reducer;
