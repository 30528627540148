import { Box, Divider, Grid } from '@mui/material';
import {
  PopupModal,
  Text,
  UIButton,
  UIMultipleSelectCheckmarks,
  UITextInputWithTitle
} from '../../../../ui-kits';
import { UISelectCheckGroup } from '../../../../ui-kits/select-with-checkbox-group';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getdistrictByState,
  getdistrictByStateNxtPage,
  getStateNextPage,
  getStates,
  gettalukaByDistrict,
  gettalukaByDistrictNxtPage,
  getvillageByTaluka,
  getvillageByTalukaNxtPage,
  processLoading
} from '../../../../services/redux/selectors/manageProccess';
import {
  deduplicateAndSet,
  getFormattedOptions,
  getTitles,
  removeDataOfRemovedItem
} from '../../../../utils';
import { useFormik } from 'formik';
import { globalStyles } from '../../../../theme';
import { defaultValues } from '../../../../constants';
import { LimitedChips } from './limited-chips';
import {
  getCallCenterUserInfoRequest,
  getDistrictByStateRequest,
  getStateRequest,
  getTalukaByDistrictRequest,
  getVillageByTalukaRequest
} from '../../../../services/redux/slices';
import { styles } from './styles';

export const AdvancedSearchModal = ({
  handleModalClose,
  open,
  handleSearch,
  searchOptions,
  isLoading
}) => {
  const dispatch = useDispatch();
  const sectionRef = useRef(null);

  const states = useSelector(getStates);
  const nextPage = useSelector(getStateNextPage);
  const districts = useSelector(getdistrictByState);
  const talukas = useSelector(gettalukaByDistrict);
  const villages = useSelector(getvillageByTaluka);
  const districtNxtPg = useSelector(getdistrictByStateNxtPage);
  const villageNxtPge = useSelector(getvillageByTalukaNxtPage);
  const talukNxtPge = useSelector(gettalukaByDistrictNxtPage);
  const processIsLoading = useSelector(processLoading);

  const [stateList, setStateList] = useState([]);
  const [isEmp, setIsEmp] = useState(false);
  const [districtsList, setDistrictsLists] = useState([]);
  const [talukasList, setTalukasList] = useState([]);
  const [villagesList, setVillagesList] = useState([]);
  const [showDrop, setShowDrop] = useState(false);
  const typeAssign = { type: 2, permission_id: defaultValues.employeeRol.callCenterAgents };

  const { values, handleBlur, resetForm, setFieldValue } = useFormik({
    initialValues: {
      states: [],
      districts: [],
      talukas: [],
      villages: [],
      roles: [],
      search_text: ''
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true
  });

  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sectionRef.current && !sectionRef.current.contains(event.target)) {
        setShowDrop(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(getStateRequest({ page: 1, ...typeAssign }));
  }, []);

  useEffect(() => {
    if (searchOptions.length > 0) {
      setShowDrop(true);
    }
  }, [searchOptions]);

  useEffect(() => {
    setStateList(deduplicateAndSet(stateList, states));

    setDistrictsLists(deduplicateAndSet(districtsList, districts));

    setTalukasList(deduplicateAndSet(talukasList, talukas));

    setVillagesList(deduplicateAndSet(villagesList, villages));
  }, [districts, talukas, villages, states]);

  const previousOptions = useRef({
    stateNxtPage: nextPage,
    districtNxtPage: districtNxtPg,
    villageNxtPage: villageNxtPge,
    talukaNextPage: talukNxtPge
  });

  useEffect(() => {
    const prev = previousOptions.current;

    if (nextPage !== prev?.stateNxtPage && nextPage !== null) {
      dispatch(getStateRequest({ page: nextPage, ...typeAssign }));
    }

    if (districtNxtPg !== prev?.districtNxtPage && districtNxtPg !== null) {
      dispatch(
        getDistrictByStateRequest({
          state_id: JSON.stringify(values.states),
          page: districtNxtPg,
          ...typeAssign
        })
      );
    }
    if (talukNxtPge !== prev?.talukaNextPage && talukNxtPge !== null) {
      dispatch(
        getTalukaByDistrictRequest({
          district_id: JSON.stringify(values.districts),
          page: talukNxtPge,
          ...typeAssign
        })
      );
    }

    if (villageNxtPge !== prev?.villageNxtPage && villageNxtPge !== null) {
      dispatch(
        getVillageByTalukaRequest({
          taluka_id: JSON.stringify(values.talukas),
          page: villageNxtPge,
          ...typeAssign
        })
      );
    }

    previousOptions.current = {
      districtNxtPage: districtNxtPg,
      villageNxtPage: villageNxtPge,
      talukaNextPage: talukNxtPge
    };
  }, [districtNxtPg, villageNxtPge, talukNxtPge]);

  const previousValues = useRef({
    state: values?.states?.length,
    district: values?.districts?.length,
    village: values?.villages?.length,
    taluka: values?.talukas?.length
  });

  useEffect(() => {
    const prev = previousValues.current;

    if (isEmp) {
      const [updatedDistricts, updatedDistrictOptions] = removeDataOfRemovedItem(
        values.states,
        values.districts,
        'state_id',
        districtsList
      );

      setFieldValue('districts', updatedDistricts);
      setDistrictsLists(updatedDistrictOptions);

      const [updatedTaluka, updatedTalukaOptions] = removeDataOfRemovedItem(
        values.districts,
        values.talukas,
        'district_id',
        talukasList
      );
      setFieldValue('talukas', updatedTaluka);
      setTalukasList(updatedTalukaOptions);

      const [updatedVillage, updatedVillageOptions] = removeDataOfRemovedItem(
        values.talukas,
        values.villages,
        'taluka_id',
        villagesList
      );
      setFieldValue('villages', updatedVillage);
      setVillagesList(updatedVillageOptions);
      setTimeout(() => setIsEmp(false), 3000);
    }

    if (prev?.state !== values.states?.length && values.states?.length > 0) {
      dispatch(
        getDistrictByStateRequest({
          state_id: JSON.stringify(values.states),
          page: 1,
          ...typeAssign
        })
      );
    }
    if (prev?.district !== values.districts?.length && values.districts?.length > 0) {
      dispatch(
        getTalukaByDistrictRequest({
          district_id: JSON.stringify(values.districts),
          page: 1,
          ...typeAssign
        })
      );
    }
    if (prev?.taluka !== values.talukas?.length && values.talukas?.length > 0) {
      dispatch(
        getVillageByTalukaRequest({
          taluka_id: JSON.stringify(values.talukas),
          page: 1,
          ...typeAssign
        })
      );
    }

    previousValues.current = {
      state: values?.states?.length,
      district: values?.districts?.length,
      village: values?.villages?.length,
      taluka: values?.talukas?.length
    };
  }, [
    values.states?.length,
    values.districts?.length,
    values.talukas?.length,
    values.villages?.length,
    villagesList,
    talukasList,
    districtsList
  ]);

  const handleDeleteChip = (index, field, currentValues) => {
    const updatedValues = currentValues.filter((_, i) => i !== index);
    setFieldValue(field, updatedValues);
  };

  const handleClose = () => {
    resetForm();
    handleModalClose();
  };

  const handleAdvancedSearch = () => {
    let payload = {
      states: values.states?.length > 0 ? JSON.stringify(values.states) : null,
      districts: values.districts?.length > 0 ? JSON.stringify(values.districts) : null,
      talukas: values.talukas?.length > 0 ? JSON.stringify(values.talukas) : null,
      villages: values.villages?.length > 0 ? JSON.stringify(values.villages) : null,
      role:
        values.roles?.length > 0 ? JSON.stringify(values.roles.map((item) => item.value)) : null,
      search_text: values.search_text
    };

    handleSearch(payload);
  };

  const handleUserselect = (item) => {
    dispatch(
      getCallCenterUserInfoRequest({
        phone_number: item.phone_number
      })
    );
  };

  return (
    <PopupModal
      showModel={open}
      onClose={handleClose}
      title={''}
      containerStyle={styles.modalCont}
      isLoading={isLoading}>
      <Box sx={styles.searchCont}>
        <Box sx={styles.searchSub}>
          <UITextInputWithTitle
            name="search_text"
            value={values.search_text}
            onChange={(e) => {
              setFieldValue('search_text', e.target.value);
            }}
            title={''}
            placeHolder={getTitles('T-10077')}
            containerStyles={styles.modalTextInput}
            onFocus={() => setShowDrop(true)}
          />
          {searchOptions?.length > 0 && showDrop && (
            <div style={styles.dropdown} ref={sectionRef}>
              {searchOptions.map((item, idx) => (
                <Box key={idx} sx={styles.dropItem} onClick={() => handleUserselect(item)}>
                  {item.full_name}
                </Box>
              ))}
            </div>
          )}
        </Box>

        <UIButton
          title={getTitles('T-11343')}
          style={styles.button}
          onClick={handleAdvancedSearch}
          disabled={!values.search_text}
        />
      </Box>
      <Box sx={styles.filterCont}>
        <Text variant="D24">{getTitles('T-10073')}</Text>
        <Divider sx={styles.divider('60%')} />
        <Grid container sx={styles.filterSubCont}>
          <Grid item>
            <Grid container sx={styles.locationCont}>
              <Grid item sx={styles.textCont}>
                <Text variant="D24">{getTitles('T-10734')}</Text>
              </Grid>
              <Grid item sx={styles.selectCont}>
                <Box sx={styles.selectSubCont}>
                  <UISelectCheckGroup
                    name="states"
                    options={[
                      {
                        title: '',
                        value:
                          stateList &&
                          stateList.length > 0 &&
                          stateList.map((item) => ({ title: item.name, value: item._id }))
                      }
                    ]}
                    placeholder={getTitles('T-10070')}
                    onChange={(val) => {
                      setFieldValue('states', val);
                      setIsEmp(true);
                    }}
                    onBlur={handleBlur}
                    preSelectedValues={values.states}
                    containerStyles={{ ...globalStyles.gridState, ...styles.checkbox }}
                    placeholderStyle={styles.selectPlaceholder}
                    selectStyle={styles.select}
                    showSelName={false}
                    onClear={values.states.length === 0}
                  />
                  {values.states.length > 0 && (
                    <LimitedChips
                      values={values.states}
                      options={stateList}
                      setFieldValue={setFieldValue}
                      handleDeleteChip={(index) => handleDeleteChip(index, 'states', values.states)}
                    />
                  )}
                </Box>

                <Box item sx={styles.selectSubCont}>
                  <UISelectCheckGroup
                    name={defaultValues.appLogicalTexts.districts}
                    required={true}
                    onBlur={handleBlur}
                    options={getFormattedOptions(districtsList, 'state_id').map((item) => ({
                      title: stateList.find((ele) => ele._id === item.id)?.name,
                      value:
                        item.value &&
                        item.value.map((item) => ({
                          title: item.name,
                          value: item._id
                        }))
                    }))}
                    onChange={(val) => {
                      setIsEmp(true);
                      setFieldValue('districts', val);
                    }}
                    preSelectedValues={values.districts}
                    placeholder={getTitles('T-10069')}
                    readOnly={values.states.length < 1}
                    containerStyles={{ ...globalStyles.gridState, ...styles.checkbox }}
                    placeholderStyle={styles.selectPlaceholder}
                    selectStyle={styles.select}
                    showSelName={false}
                    onClear={values.districts?.length === 0}
                    disabled={processIsLoading}
                  />
                  {values.districts.length > 0 && (
                    <LimitedChips
                      values={values.districts}
                      options={districtsList}
                      setFieldValue={setFieldValue}
                      handleDeleteChip={(index) =>
                        handleDeleteChip(index, 'districts', values.districts)
                      }
                    />
                  )}
                </Box>

                <Box item sx={styles.selectSubCont}>
                  <UISelectCheckGroup
                    name={'talukas'}
                    required={true}
                    onBlur={handleBlur}
                    options={getFormattedOptions(talukasList, 'district_id').map((unique) => ({
                      title: districtsList.find((ele) => ele._id === unique.id)?.name,
                      value:
                        unique.value &&
                        unique.value.map((item) => ({
                          title: item.name,
                          value: item._id
                        }))
                    }))}
                    onChange={(val) => {
                      setFieldValue('talukas', val);
                      setIsEmp(true);
                    }}
                    preSelectedValues={values.talukas}
                    placeholder={getTitles('T-10071')}
                    disabled={processIsLoading}
                    readOnly={values.districts.length < 1}
                    containerStyles={{ ...globalStyles.gridState, ...styles.checkbox }}
                    placeholderStyle={styles.selectPlaceholder}
                    selectStyle={styles.select}
                    showSelName={false}
                    onClear={values.talukas?.length === 0}
                  />
                  {values.districts.length > 0 && (
                    <LimitedChips
                      values={values.talukas}
                      options={talukasList}
                      setFieldValue={setFieldValue}
                      handleDeleteChip={(index) =>
                        handleDeleteChip(index, 'talukas', values.talukas)
                      }
                    />
                  )}
                </Box>
                <Box item sx={styles.selectSubCont}>
                  <UISelectCheckGroup
                    name={defaultValues.appLogicalTexts.villages}
                    required={true}
                    options={getFormattedOptions(villagesList, 'taluka_id').map((unique) => ({
                      title: talukasList.find((ele) => ele._id === unique.id)?.name,
                      value:
                        unique.value &&
                        unique.value.map((item) => ({
                          title: item.name,
                          value: item._id
                        }))
                    }))}
                    onChange={(val) => {
                      setFieldValue('villages', val);
                      setIsEmp(true);
                    }}
                    disabled={processIsLoading}
                    preSelectedValues={values.villages}
                    placeholder={getTitles('T-10068')}
                    readOnly={values.talukas.length < 1}
                    containerStyles={{ ...globalStyles.gridState, ...styles.checkbox }}
                    placeholderStyle={styles.selectPlaceholder}
                    selectStyle={styles.select}
                    showSelName={false}
                    onClear={values.villages?.length === 0}
                  />

                  {values.villages.length > 0 && (
                    <LimitedChips
                      values={values.villages}
                      options={villagesList}
                      setFieldValue={setFieldValue}
                      handleDeleteChip={(index) =>
                        handleDeleteChip(index, 'villages', values.villages)
                      }
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container sx={styles.locationCont}>
              <Grid item sx={styles.textCont}>
                <Text variant="D24">{getTitles('T-10455')}</Text>
              </Grid>
              <Grid item sx={styles.selectCont}>
                <Box item sx={styles.selectSubCont}>
                  <UIMultipleSelectCheckmarks
                    name="roles"
                    preSelectedValue={values.roles}
                    options={defaultValues.callCenterSearchRoles}
                    onChange={(val) => {
                      setFieldValue('roles', val);
                    }}
                    placeholder={getTitles('T-10429')}
                    containerStyles={{ ...globalStyles.gridState, ...styles.checkbox }}
                    placeholderStyles={styles.selectPlaceholder}
                    selectStyle={styles.select}
                    showTitles={false}
                  />
                  {values.roles.length > 0 && (
                    <LimitedChips
                      values={values.roles.map((el) => el.value)}
                      options={defaultValues.callCenterSearchRoles.map((el) => ({
                        _id: el.value,
                        name: el.title
                      }))}
                      setFieldValue={setFieldValue}
                      handleDeleteChip={(index) => handleDeleteChip(index, 'roles', values.roles)}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </PopupModal>
  );
};
