import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getDetailsPg,
  getLabFarmerCattleCurPage,
  getLabFarmerCattles,
  getLabFarmerCattleTotalPage,
  getLabFarmerDetails,
  getLabFarmerErrorMsg,
  getLabFarmerLoading,
  getLabFarmerSuccessMsg
} from '../../../../services/redux/selectors';
import { useEffect } from 'react';
import {
  getlabFarmersCattleRequest,
  resetLabFarmerMsg,
  updateDetailsPg
} from '../../../../services/redux/slices';
import {
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
  SCREEN_PATH
} from '../../../../constants';
import { getTitles, resetMessages } from '../../../../utils';
import { Screen, Text, UILayout } from '../../../../ui-kits';
import { styles } from './styles';
import { BasicProfile } from '../../../common/utility-components';
import cowImg from '../../../../assets/images/profile.jpg';
import { Box } from '@mui/material';
import { UIBackButton } from '../../../../ui-kits/back-button';
import { toast } from 'react-toastify';

export const LabFarmerCattleTable = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const farmerDetails = useSelector(getLabFarmerDetails);
  const isLoading = useSelector(getLabFarmerLoading);
  const errorMsg = useSelector(getLabFarmerErrorMsg);
  const successMsg = useSelector(getLabFarmerSuccessMsg);
  const showBackButton = useSelector(getDetailsPg);
  const totalPages = useSelector(getLabFarmerCattleTotalPage);
  const currentPage = useSelector(getLabFarmerCattleCurPage);
  const cattles = useSelector(getLabFarmerCattles);

  useEffect(() => {
    if (params?.farmer_id) {
      dispatch(getlabFarmersCattleRequest({ farmer_id: params?.farmer_id }));
    }
  }, [params]);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetLabFarmerMsg()));
  }, [successMsg, errorMsg]);

  useEffect(() => {
    if (!showBackButton) {
      navigateToFarmer();
    }
  }, [showBackButton]);

  const handlePagination = (e, data) => {
    dispatch(getlabFarmersCattleRequest({ page: data, farmer_id: params?.farmer_id }));
  };

  const tableData = cattles.map((item, index) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-11407'),
          values: (
            <Text variant="D22" style={{ ml: 3 }}>
              {++index}
            </Text>
          ),
          columnWidth: styles.columnWidth
        },
        {
          title: getTitles('T-11408'),
          values: (
            <Text style={styles.id} variant="D22">
              {item?.cattle_id}
            </Text>
          ),
          columnWidth: styles.columnWidth
        },
        {
          title: getTitles('T-10984'),
          columnWidth: styles.columnWidth,
          values: (
            <BasicProfile
              title={item?.cattle_name}
              url={item?.cattle_photo || cowImg}
              titleStyle={styles.cattleName}
              startIconStyle={styles.profileImg}
              onTitleClick={() => navigateToCattleDetails(item?._id)}
              textVariant="D22"
            />
          )
        }
      ];
    } else {
      return {
        title: (
          <Box component={'div'} sx={styles.tableItem}>
            <Text variant="D22">{++index}</Text>
            <BasicProfile
              title={item?.cattle_name}
              subTitle={item?.cattle_id}
              titleStyle={styles.profileTitle}
              subTitleStyle={styles.profileSubTitle}
              url={item?.cattle_photo || cowImg}
              onTitleClick={() => navigateToCattleDetails(item?._id)}
              startIconStyle={styles.profileImg}
            />
          </Box>
        ),
        details: []
      };
    }
  });

  const navigateToCattleDetails = (cattleId) => {
    dispatch(updateDetailsPg(true));
    navigate(`${SCREEN_PATH.LAB_FARMER_CATTLE_DETAILS}/${cattleId}`);
  };

  const navigateToFarmer = () => {
    dispatch(updateDetailsPg(false));
    navigate(`${SCREEN_PATH.LAB_FARMER}`);
  };

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-11412')}>
      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mt: 2 }}>
          {getTitles('T-11412')}
        </Text>
      )}
      <Box component={'div'} sx={styles.profileBox}>
        {!defaultValues.isResponsive && (
          <UIBackButton style={styles.backArrowButton} onClick={navigateToFarmer} />
        )}

        <BasicProfile
          title={farmerDetails?.full_name}
          url={farmerDetails?.photo_url}
          startIconStyle={styles.profileImg}
          textVariant="D22"
          onTitleClick={navigateToFarmer}
        />
      </Box>
      <UILayout
        tableItems={tableData}
        showNoData={!cattles?.length > 0}
        showDataTable={!defaultValues.isResponsive}
        collapsable={false}
        message={getErrorMessage('E-10155')}
        currPage={currentPage}
        pageCount={totalPages}
        handlePagination={handlePagination}
        optionStyles={{ mb: 0, mt: 0 }}
      />
    </Screen>
  );
};
