import { Box } from '@mui/material';
import { UISelect, UITextInputWithTitle } from '../../../ui-kits';
import { SCREEN_PATH, defaultValues } from '../../../constants';
import { calculateBodyWeight, CattlelSchema, getMasterListItems, getTitles } from '../../../utils';
import { useFormik } from 'formik';
import { ModalButtons } from '../../common/modal-buttons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Styles } from '../style';
import { getMasterLists } from '../../../services/redux/selectors/manageProccess';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  getCattleActionType,
  getCattleData,
  getCattleSuccess
} from '../../../services/redux/selectors';
import {
  addCattleRequest,
  getCattleDetailsRequest,
  updateCattleRequest
} from '../../../services/redux/slices';
const isRes = defaultValues.isResponsive;

export const CattleDescription = ({ onNext, setNext }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { view, edit, add } = defaultValues.actionType;

  const actionType = useSelector(getCattleActionType);
  const data = useSelector(getCattleData);

  const { values, errors, handleBlur, setFieldValue, touched } = useFormik({
    initialValues: {
      cattle_type: { _id: '' },
      cattle_breed: { _id: '' },
      horns: { _id: '' },
      color: { _id: '' },
      tail_switch: { _id: '' },
      cattle_name: '',
      age: {
        years: '',
        months: ''
      },
      length: '',
      girth: '',
      weight: '',
      description: '',
      note: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: CattlelSchema
  });

  const listItems = useSelector(getMasterLists);
  const success = useSelector(getCattleSuccess);

  const [cattleType, setCattleType] = useState([]);
  const [cattleBreed, setCattleBreed] = useState([]);
  const [tailSwitch, setTailSwitch] = useState([]);
  const [horns, setHorns] = useState([]);
  const [color, setColor] = useState([]);
  const [farmerId, setFarmerId] = useState('');
  const [cattleId, setCattleId] = useState('');
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (['S-10062', 'S-10063'].includes(success) && isInit) {
      onNext();
    }
  }, [success]);

  useEffect(() => {
    if (location.state && location.state.farmer_id) {
      setFarmerId(location.state.farmer_id);
    }
    if (location.state && location.state._id) {
      setCattleId(location.state._id);
    }
  }, [location.state]);

  useEffect(() => {
    if (listItems) {
      setCattleType(getMasterListItems(listItems, defaultValues.mListIds.cattle));
      setCattleBreed(getMasterListItems(listItems, defaultValues.mListIds.cattleBreeds));
      setTailSwitch(getMasterListItems(listItems, defaultValues.mListIds.tailSwitch));
      setHorns(getMasterListItems(listItems, defaultValues.mListIds.horn));
      setColor(getMasterListItems(listItems, defaultValues.mListIds.color));
    }
  }, [listItems]);

  useEffect(() => {
    if (cattleId && actionType) {
      dispatch(getCattleDetailsRequest({ _id: cattleId }));
    }
  }, [cattleId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };
  const handleSubmit = () => {
    const payload = {
      farmer_id: farmerId,
      registration_step: 1,
      cattle_description: {
        ...values,
        cattle_type: values.cattle_type?._id || null,
        cattle_breed: values.cattle_breed?._id || null,
        tail_switch: values.tail_switch?._id || null,
        color: values.color?._id || null,
        horns: values.horns?._id || null,
        age: {
          years: parseInt(values.age.years, 10),
          months: parseInt(values.age.months, 10)
        },
        length: parseFloat(values.length),
        girth: parseFloat(values.girth),
        weight: parseFloat(values.weight)
      }
    };

    if (actionType === 'edit' || (actionType === 'add' && data.cattle_description)) {
      payload.farmer_id = data.farmer_id;
      payload._id = data._id;
      dispatch(updateCattleRequest(payload));
    } else {
      dispatch(addCattleRequest(payload));
    }
    setIsInit(true);
  };

  useEffect(() => {
    const weight = calculateBodyWeight(values.length, values.girth);
    if (weight !== null) {
      setFieldValue('weight', weight);
    }
  }, [values.length, values.girth, setFieldValue]);

  useEffect(() => {
    if (data.cattle_description) {
      setFieldValue('cattle_type', data.cattle_description.cattle_type);
      setFieldValue('cattle_breed', data.cattle_description.cattle_breed);
      setFieldValue(
        'horns',
        data.cattle_description.horns ? data.cattle_description.horns : { _id: '' }
      );
      setFieldValue(
        'tail_switch',
        data.cattle_description.tail_switch ? data.cattle_description.tail_switch : { _id: '' }
      );
      setFieldValue(
        'color',
        data.cattle_description.color ? data.cattle_description.color : { _id: '' }
      );

      setFieldValue('cattle_name', data.cattle_description.cattle_name);
      setFieldValue('length', data.cattle_description.length);
      setFieldValue('girth', data.cattle_description.girth);
      setFieldValue('weight', data.cattle_description.weight);
      setFieldValue('description', data.cattle_description.description);
      setFieldValue('note', data.cattle_description.note);
      if (data.cattle_description.age) {
        setFieldValue('age.years', data.cattle_description.age.years);
        setFieldValue('age.months', data.cattle_description.age.months);
      }
      setFieldValue('cattle_id', data.cattle_id);
    }
  }, [data]);

  return (
    <Box sx={Styles.secOneContainer}>
      {actionType !== add && (
        <UITextInputWithTitle
          title={getTitles('T-10751')}
          value={values.cattle_id}
          disabled={true}
          width={defaultValues.isResponsive ? '100%' : 'calc((100%)/3)'}
        />
      )}
      <UISelect
        required={true}
        name="cattle_type._id"
        value={values.cattle_type?._id}
        options={cattleType}
        optionLabel="name"
        returnValue="_id"
        title={getTitles('T-10983')}
        placeholder={getTitles('T-10350')}
        error={touched.cattle_type?._id && errors.cattle_type?._id}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={actionType === view}
        onlyView={actionType === view}
        valueToShow={values.cattle_type?.name}
      />
      <UISelect
        required={true}
        name="cattle_breed._id"
        value={values.cattle_breed?._id}
        options={cattleBreed}
        optionLabel="name"
        returnValue="_id"
        title={getTitles('T-11006')}
        placeholder={getTitles('T-10350')}
        error={touched.cattle_breed?._id && errors.cattle_breed?._id}
        onChange={handleChange}
        disabled={actionType === view}
        onBlur={handleBlur}
        onlyView={actionType === view}
        valueToShow={values.cattle_breed?.name}
      />
      <UITextInputWithTitle
        name="cattle_name"
        title={getTitles('T-10984')}
        placeHolder={getTitles('T-10384')}
        onBlur={handleBlur}
        value={values.cattle_name}
        error={touched.cattle_name && errors.cattle_name}
        onChange={handleChange}
        maxLength={15}
        allowOnlyAlphabets={true}
        disabled={actionType === view}
        required
      />
      <Box sx={Styles.secOneSubContainer}>
        <UITextInputWithTitle
          name="age.years"
          title={getTitles('T-10985')}
          placeHolder={getTitles('T-11007')}
          onBlur={handleBlur}
          value={values.age.years}
          error={touched.age && touched.age.years && errors.age && errors.age.years}
          onChange={handleChange}
          maxLength={2}
          allowOnlyNumbers={true}
          required
          disabled={actionType === view}
          containerStyles={Styles.halfField}
        />
        <UITextInputWithTitle
          name="age.months"
          title={getTitles('T-10986')}
          placeHolder={getTitles('T-11008')}
          onBlur={handleBlur}
          value={values.age.months}
          error={touched.age && touched.age.months && errors.age && errors.age.months}
          onChange={handleChange}
          maxLength={2}
          allowOnlyNumbers={true}
          required
          disabled={actionType === view}
          containerStyles={Styles.halfField}
        />
      </Box>

      <Box sx={Styles.secOneSubContainer}>
        <UITextInputWithTitle
          name="length"
          title={getTitles('T-10987')}
          placeHolder={getTitles('T-11009')}
          onBlur={handleBlur}
          value={values.length}
          error={touched.length && errors.length}
          onChange={handleChange}
          maxLength={6}
          allowDecimal={true}
          required
          disabled={actionType === view}
          containerStyles={Styles.halfField}
        />
        <UITextInputWithTitle
          name="girth"
          title={getTitles(isRes ? 'T-11325' : 'T-10988')}
          placeHolder={getTitles('T-11010')}
          onBlur={handleBlur}
          value={values.girth}
          error={touched.girth && errors.girth}
          onChange={handleChange}
          maxLength={6}
          allowDecimal={true}
          required
          disabled={actionType === view}
          containerStyles={Styles.halfField}
        />
      </Box>
      <UITextInputWithTitle
        name="weight"
        title={getTitles('T-10989')}
        placeHolder={getTitles('T-10989')}
        onBlur={handleBlur}
        value={values.weight}
        error={touched.weight && errors.weight}
        onChange={handleChange}
        maxLength={6}
        allowOnlyNumbers={true}
        disabled={true}
      />
      <UITextInputWithTitle
        name="description"
        title={getTitles('T-10979')}
        placeHolder={getTitles('T-10096')}
        value={values.description}
        onChange={handleChange}
        disabled={actionType === view}
        rows={3}
      />
      <UISelect
        name="horns._id"
        value={values.horns?._id}
        options={horns}
        optionLabel="name"
        returnValue="_id"
        title={getTitles('T-10990')}
        placeholder={getTitles('T-10350')}
        disabled={actionType === view}
        onChange={handleChange}
        onlyView={actionType === view}
        valueToShow={values.horns?.name}
      />
      <UISelect
        name="tail_switch._id"
        value={values.tail_switch?._id}
        options={tailSwitch}
        optionLabel="name"
        returnValue="_id"
        title={getTitles('T-10991')}
        placeholder={getTitles('T-10350')}
        disabled={actionType === view}
        onChange={handleChange}
        onlyView={actionType === view}
        valueToShow={values.tail_switch?.name}
      />
      <UISelect
        name="color._id"
        value={values.color?._id}
        options={color}
        optionLabel="name"
        returnValue="_id"
        title={getTitles('T-10992')}
        placeholder={getTitles('T-10350')}
        disabled={actionType === view}
        onChange={handleChange}
        onlyView={actionType === view}
        valueToShow={values.tail_switch?.name}
      />

      <UITextInputWithTitle
        name="note"
        title={getTitles('T-10993')}
        placeHolder={getTitles('T-10096')}
        value={values.note}
        onChange={handleChange}
        disabled={actionType === view}
        rows={3}
      />
      <ModalButtons
        btnPositions={isRes ? 'center' : 'start'}
        secondaryBtnTitle={getTitles('T-10340')}
        disabled={
          !values.age.years ||
          !values.cattle_breed?._id ||
          !values.cattle_name ||
          !values.cattle_type?._id ||
          !values.length ||
          !values.girth ||
          !values.age.months
        }
        onSecondaryBtnClick={() =>
          navigation(SCREEN_PATH.MANAGE_CATTLE, { state: { farmer_id: farmerId } })
        }
        onPrimaryBtnClick={actionType === view ? () => setNext(1) : handleSubmit}
        primaryBtnTitle={
          actionType === add && data.cattle_description
            ? getTitles('T-10733')
            : actionType === edit
              ? getTitles('T-10733')
              : actionType === view
                ? getTitles('T-10804')
                : getTitles('T-10339')
        }
      />
    </Box>
  );
};
