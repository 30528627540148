import { useSelector } from 'react-redux';
import { ManageInsuranceDashboard } from '../../components';
import { getUserData } from '../../services/redux/selectors';
import { defaultValues } from '../../constants';
import { Screen } from '../../ui-kits';
import { getTitles } from '../../utils';
import { GovtDashboard } from './govt-officials';
import { NoDashboard } from '../../components/common/no-dashboard';

export const Dashboard = () => {
  const user = useSelector(getUserData);
  const role = user.user_role;

  if (role === defaultValues.userRole?.insurance) {
    return <ManageInsuranceDashboard />;
  } else {
    return (
      <Screen showSideBar={true} currentPage={getTitles('T-10037')}>
        {role === defaultValues.userRole.govtOfficials ? <GovtDashboard /> : <NoDashboard />}
      </Screen>
    );
  }
};
