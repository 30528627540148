import React, { useEffect } from 'react';
import { Screen, Text, UIButton, UITextInputWithTitle, ViewData } from '../../ui-kits';
import { styles } from './styles';
import { Box, Divider } from '@mui/material';
import { contactSchema, getTitles, resetMessages } from '../../utils';
import contactImage from '../../assets/images/contact-image.png';
import gopalan from '../../assets/images/gopalan.png';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { resetAuthMessages, sendQueryRequest } from '../../services/redux/slices';
import { toast } from 'react-toastify';
import { defaultValues, getErrorMessage, getSuccessMessage } from '../../constants';
import {
  authLoading,
  getAuthErrorMessage,
  getAuthSuccessMessage
} from '../../services/redux/selectors';

export const ContactUs = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(authLoading);
  const errorMsg = useSelector(getAuthErrorMessage);
  const successMsg = useSelector(getAuthSuccessMessage);

  const { values, errors, touched, handleBlur, resetForm, setFieldValue, isValid } = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: ''
    },

    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: contactSchema
  });
  const dataFields = [
    {
      title: getTitles('T-11695'),
      subTitle: defaultValues.contactContents.email
    },
    {
      title: getTitles('T-11694'),
      subTitle: defaultValues.contactContents.contact,
      containerStyle: styles.contactNum
    },
    {
      title: getTitles('T-11701'),
      subTitle: getTitles('T-11703'),
      onClick: () => (window.location.href = defaultValues.contactContents.addressLink)
    },
    {
      title: getTitles('T-11696'),
      subTitle: defaultValues.contactContents.liscence
    },
    {
      title: getTitles('T-11697'),
      subTitle: defaultValues.contactContents.gstin,
      containerStyle: styles.contactNum
    }
  ];

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
    }
    if (errorMsg) {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetAuthMessages()));
  }, [successMsg, errorMsg]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleSubmit = () => {
    dispatch(sendQueryRequest({ ...values }));
    resetForm();
  };

  return (
    <Screen showHeader={true} isLoading={isLoading}>
      <Box sx={styles.container}>
        <Box sx={styles.subContainer}>
          <Text variant="boldH40">{getTitles('T-10007')}</Text>
          <img src={contactImage} style={styles.image} />
          <img src={gopalan} style={styles.image} />
        </Box>
        <Box sx={styles.subContainer}>
          {dataFields.map((item, idx) => (
            <ViewData
              key={idx}
              title={item.title}
              titleStyle={styles.fieldTitleStyle}
              subTitle={item.subTitle}
              containerStyle={{ ...item.containerStyle, mt: 2 }}
              subTitleCont={styles.subTitleCont}
              handleSubtitle={item.onClick}
            />
          ))}
          <Divider sx={styles.divider} />

          <Text variant="boldH32Sec" style={styles.head}>
            {getTitles('T-11698')}
          </Text>

          <UITextInputWithTitle
            name="name"
            title={getTitles('T-10081')}
            placeHolder={getTitles('T-10384')}
            error={touched.name && errors.name}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
            required
          />

          <UITextInputWithTitle
            name="email"
            title={getTitles('T-11695')}
            placeHolder={getTitles('T-10049')}
            error={touched.email && errors.email}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
            required
          />

          <UITextInputWithTitle
            name="message"
            title={getTitles('T-11699')}
            placeHolder={getTitles('T-11700')}
            error={touched.message && errors.message}
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            width={styles.width}
            required
            rows={3}
          />

          <UIButton
            title={getTitles('T-11702')}
            disabled={!isValid}
            style={styles.btn}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </Screen>
  );
};
