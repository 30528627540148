import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { defaultValues } from '../../constants';
import { color } from '../../theme';
import { getTitles } from '../../utils';

export const SelectDropdown = ({
  options,
  selOP,
  selectedOption,
  placeholder,
  label = getTitles('T-10087'),
  selectContainerStyle,
  inputStyle
}) => {
  const styles = {
    select: (style) => ({
      mx: !defaultValues.isResponsive && 1,
      minWidth: defaultValues.isResponsive ? 120 : 150,
      maxWidth: defaultValues.isResponsive ? 150 : 200,
      backgroundColor: color.primaryBackground,
      ...style
    }),
    dropDown: (style) => ({ height: '48px', ...style }),
    menuItem: {
      width: '100%',
      whiteSpace: 'normal',
      wordWrap: 'break-word'
    },
    placeholder: {
      color: color.palette.lightGray,
      whiteSpace: 'normal',
      wordWrap: 'break-word'
    }
  };

  const [op, setOP] = useState('');

  useEffect(() => {
    setOP(selOP ? selOP : '');
  }, [selOP]);

  const handleSelect = (e) => {
    const selectedValue = e.target.value;
    setOP(selectedValue);
    selectedOption && selectedOption(selectedValue);
  };
  return (
    <FormControl sx={styles.select(selectContainerStyle)}>
      <InputLabel>{label}</InputLabel>
      <Select value={op} onChange={handleSelect} sx={styles.dropDown(inputStyle)} label={label}>
        {placeholder && (
          <MenuItem value="" sx={styles.menuItem}>
            <div style={styles.placeholder}>{placeholder}</div>
          </MenuItem>
        )}
        {options.map((option, index) => (
          <MenuItem key={index} value={option.id}>
            {option.type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
