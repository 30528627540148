import { Box } from '@mui/material';
import { getTitles } from '../../../../utils';
import { DatePickers, UITextInputWithTitle } from '../../../../ui-kits';
import dayjs from 'dayjs';
import { defaultValues } from '../../../../constants';

const res = defaultValues.isResponsive;

const styles = {
  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: res ? '0px' : '10px'
  },
  width: res ? '100%' : 'calc((100% - 10px)/3)'
};

export const MachineFields = ({
  name1,
  value1,
  name2,
  value2,
  name3,
  value3,
  handleChange,
  disabled
}) => {
  return (
    <Box sx={styles.nameCont}>
      <UITextInputWithTitle
        name={name1}
        title={getTitles('T-11680')}
        placeHolder={getTitles('T-10586')}
        value={value1}
        onChange={handleChange}
        width={styles.width}
        disabled={disabled}
      />
      <UITextInputWithTitle
        name={name2}
        title={getTitles('T-10782')}
        placeHolder={getTitles('T-10800')}
        value={value2}
        onChange={handleChange}
        width={styles.width}
        disabled={disabled}
      />

      <DatePickers
        dateValue={value3}
        maxDate={dayjs()}
        onDateChange={(val) =>
          handleChange({
            target: { value: val, name: name3 }
          })
        }
        title={getTitles('T-10783')}
        width={styles.width}
        disabled={disabled}
      />
    </Box>
  );
};
