import { color } from '../../../../theme';

export const styles = {
  container: {
    width: '100%',
    display: 'flex',
    backgroundColor: color.primaryBackground,
    border: `1px solid ${color.primaryBorder}`,
    borderRadius: '4px'
  },
  indicator: (isRead) => ({
    borderRadius: '4px 0px 0px 4px',
    width: '10px',
    backgroundColor: isRead ? color.primary : color.secondary
  }),
  detailsCont: {
    width: 'calc(100% - 170px)',
    p: 1,
    boxSizing: 'border-box'
  },
  timeCont: {
    width: '150px',
    p: 1,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    cursor: 'pointer'
  }
};
