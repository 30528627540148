import { Box } from '@mui/material';
import { PopupModal, UITextInputWithTitle } from '../../../../ui-kits';
import { styles } from './styles';
import { useState } from 'react';
import { getTitles } from '../../../../utils';
import { ModalButtons } from '../../../common/modal-buttons';

export const NoteModal = ({ handleClose, open, isLoading }) => {
  const [note, setNote] = useState('');

  const handleChange = (e) => {
    setNote(e.target.value);
  };
  return (
    <PopupModal
      showModel={open}
      onClose={handleClose}
      title={''}
      isLoading={isLoading}
      containerStyle={styles.modalContainer}>
      <Box sx={styles.container}>
        <UITextInputWithTitle
          value={note}
          title={getTitles('T-11244')}
          onChange={handleChange}
          placeholder={getTitles('T-10096')}
          rows={3}
          //   inputStyle={styles.input}
        />
        <ModalButtons
          primaryBtnTitle={getTitles('T-10394')}
          onPrimaryBtnClick={() => {}}
          disabled={!note}
        />
      </Box>
    </PopupModal>
  );
};
