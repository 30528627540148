import { useDispatch, useSelector } from 'react-redux';
import { defaultValues } from '../../../../constants';
import {
  getDetailsPg,
  getInventoryMaterialGroup,
  getInwardActivePage,
  getInwardActiveStep
} from '../../../../services/redux/selectors';
import { deduplicateAndSet, getTitles } from '../../../../utils';
import { useEffect, useState } from 'react';
import {
  getMasterListWiseRequest,
  getProductRequest,
  getStateRequest,
  getVenderRequest,
  setInwardActivePage,
  setInwardActiveStep,
  updateDetailsPg
} from '../../../../services/redux/slices';
import { Box } from '@mui/material';
import { UIBackButton } from '../../../../ui-kits/back-button';
import { EditButton, Text, UIButton, UIStepper } from '../../../../ui-kits';
import { styles } from './styles';
import { InwardProductDetails } from '../inward-product-details';
import { InwardVendorDetails } from '../inward-vender-details';
import {
  getMasterLists,
  getProductList,
  getStateNextPage,
  getStates,
  getVender
} from '../../../../services/redux/selectors/manageProccess';

export const AddEditInward = ({ isReadOnly }) => {
  const dispatch = useDispatch();

  const detailsPg = useSelector(getDetailsPg);
  const activeStep = useSelector(getInwardActiveStep);
  const activePage = useSelector(getInwardActivePage);
  const productList = useSelector(getProductList);
  const masterList = useSelector(getMasterLists);
  const states = useSelector(getStates);
  const stateNextPage = useSelector(getStateNextPage);
  const vendor = useSelector(getVender);
  const materialGroup = useSelector(getInventoryMaterialGroup);

  const [stateList, setStateList] = useState([]);

  const { edit, view } = defaultValues.actionType;
  const { commonCategory, cowBreed, cowVariety, buffaloBreed } = defaultValues.mListIds;

  const titles = [getTitles('T-11497'), getTitles('T-11498')];

  const steps = defaultValues.isResponsive ? ['', ''] : titles;

  useEffect(() => {
    dispatch(setInwardActiveStep(0));
    dispatch(
      getProductRequest({
        filter: JSON.stringify([{ is_active: true }, { material_group: materialGroup }])
      })
    );
    dispatch(
      getMasterListWiseRequest({
        master_id: JSON.stringify([commonCategory, cowBreed, cowVariety, buffaloBreed])
      })
    );
    dispatch(getStateRequest({ page: 1, filter: JSON.stringify([{ is_active: true }]) }));
    dispatch(getVenderRequest({ page: 1, filter: JSON.stringify([{ is_active: true }]) }));
  }, []);

  useEffect(() => {
    if (stateNextPage !== null) {
      dispatch(getStateRequest({ page: stateNextPage }));
    }
  }, [stateNextPage]);

  useEffect(() => {
    setStateList(deduplicateAndSet(stateList, states));
  }, [states]);

  useEffect(() => {
    if (!detailsPg) {
      handleBackButton();
    }
  }, [detailsPg]);

  const handleNextStep = (step = 1) => {
    dispatch(setInwardActiveStep(activeStep + step));
  };

  const handlePrevStep = (step = 1) => {
    dispatch(setInwardActiveStep(activeStep - step >= 0 ? activeStep - step : activeStep));
  };

  const handleBackButton = () => {
    if (activeStep === 0 && activePage !== 1) {
      dispatch(setInwardActivePage(null));
    } else {
      handlePrevStep(1);
      dispatch(updateDetailsPg(true));
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.stepperCont}>
        {!defaultValues.isResponsive && <UIBackButton onClick={handleBackButton} />}
        <Box sx={{ flex: 1 }}>
          <UIStepper
            steps={steps}
            activeStep={activeStep}
            onStepperClick={(index) => dispatch(setInwardActiveStep(index))}
          />
          {defaultValues.isResponsive && (
            <Text variant="BoldH24" style={{ mt: 1 }}>
              {titles[activeStep]}
            </Text>
          )}
        </Box>

        <UIButton
          title={!defaultValues.isResponsive && getTitles('T-10084')}
          startIcon={<EditButton iconStyle={styles.editIcon} />}
          style={styles.editBtn(activePage === view && !isReadOnly)}
          onClick={() => dispatch(setInwardActivePage(edit))}
        />
      </Box>

      {activeStep === 0 && (
        <InwardProductDetails
          productList={productList}
          commonCategory={masterList.find((el) => el.master_id === commonCategory)?.data}
          cowBreed={masterList.find((el) => el.master_id === cowBreed)?.data}
          cowVariety={masterList.find((el) => el.master_id === cowVariety)?.data}
          buffaloBreed={masterList.find((el) => el.master_id === buffaloBreed)?.data}
          onNext={handleNextStep}
          onPrev={handleBackButton}
        />
      )}
      {activeStep === 1 && (
        <InwardVendorDetails stateList={stateList} vendor={vendor} onPrev={handlePrevStep} />
      )}
    </Box>
  );
};
