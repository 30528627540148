import { defaultValues, SCREEN_PATH } from '../../constants';
import { Screen, Text, UITablist } from '../../ui-kits';
import { ManageState } from '../manageState';
import { checkReadOnly, getTitles } from '../../utils/commonFunction';
import { useDispatch, useSelector } from 'react-redux';
import { getProcessCurrTab, processLoading } from '../../services/redux/selectors/manageProccess';
import { ManageProfiling, MasterList } from '../../components';
import { useEffect, useState } from 'react';
import { getUserData } from '../../services/redux/selectors';

import { ManageDisease } from './manage-disease';
import {
  resetProcess,
  setStoreActivepage,
  setVenderActivepage,
  updateProcessCurrTab
} from '../../services/redux/slices';
import { ManageVaccines } from './manage-vaccinces';
import { ManageStore } from './manage-store';
import { ManageVendor } from './manage-vendor';
import { ManageProductMaster } from './manage-product-master';

export const ManageProcessPage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(processLoading);
  const userData = useSelector(getUserData);
  const currTab = useSelector(getProcessCurrTab);

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isDiseaseDetails, setIsDiseaseDetails] = useState(null);

  const { disease, store, vendor } = defaultValues.processTabs;
  const { processManager } = defaultValues.employeeRol;

  useEffect(() => {
    setIsReadOnly(checkReadOnly(userData, processManager));
  }, [userData]);

  const onDetailsPageChange = (val) => {
    setIsDiseaseDetails(val ? true : false);
  };

  const tabList = [
    {
      value: (
        <ManageState
          isReadOnly={isReadOnly}
          access={processManager}
          parentRoute={SCREEN_PATH.MANAGE_PROCESS}
          renderType={defaultValues.appLogicalTexts.process}
        />
      ),
      title: getTitles('T-10299'),
      permission: processManager
    },
    {
      value: <ManageDisease isReadOnly={isReadOnly} isdetailsScreen={onDetailsPageChange} />,
      title: getTitles('T-10301'),
      permission: processManager
    },
    {
      value: <MasterList isReadOnly={isReadOnly} />,
      title: getTitles('T-10302'),
      permission: processManager
    },
    {
      value: <ManageVaccines isReadOnly={isReadOnly} />,
      title: getTitles('T-10421'),
      permission: processManager
    },
    {
      value: <ManageProfiling isReadOnly={isReadOnly} />,
      title: getTitles('T-11154'),
      permission: processManager
    },
    {
      value: <ManageStore isReadOnly={isReadOnly} />,
      title: getTitles('T-11421'),
      permission: processManager
    },
    {
      value: <ManageVendor isReadOnly={isReadOnly?.processManager} />,
      title: getTitles('T-11450'),
      permission: processManager
    },
    {
      value: <ManageProductMaster isReadOnly={isReadOnly} />,
      title: getTitles('T-11436'),
      permission: processManager
    }
  ];

  const handleTabChange = (val) => {
    if (val === disease) dispatch(resetProcess());
    if (val === store) dispatch(setStoreActivepage(null));
    if (val === vendor) dispatch(setVenderActivepage(null));
    dispatch(updateProcessCurrTab(val));
  };

  const renderTabs = () => {
    if (userData.employee_permissions?.length > 0) {
      const permIds = userData.employee_permissions.map((item) => item.permission_id);
      return tabList.filter((ele) => permIds.includes(ele.permission));
    }
    return tabList;
  };

  return (
    <Screen
      showSideBar={true}
      isLoading={isLoading}
      currentPage={isDiseaseDetails ? getTitles('T-10307') : getTitles('T-10079')}>
      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mt: 1 }}>
          {isDiseaseDetails ? getTitles('T-10307') : getTitles('T-10079')}
        </Text>
      )}
      <UITablist renderElements={renderTabs()} currentTab={currTab} onTabChange={handleTabChange} />
    </Screen>
  );
};
