import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getEcomShippingAddress, getScroll } from '../../../../services/redux/selectors';
import { useEffect } from 'react';
import {
  resetECommerceMsg,
  setCartItems,
  setIsEcommerce,
  updateScrollToTop
} from '../../../../services/redux/slices';
import { defaultValues, SCREEN_PATH } from '../../../../constants';
import { useNavigate } from 'react-router-dom';
import { styles } from './styles';
import { Text, UIButton } from '../../../../ui-kits';
import { getTitles } from '../../../../utils';

export const OrderConfirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scroll = useSelector(getScroll);
  const shippingAddress = useSelector(getEcomShippingAddress);

  useEffect(() => {
    dispatch(resetECommerceMsg());
    dispatch(setIsEcommerce(defaultValues.appLogicalTexts.eCommerce));
    dispatch(updateScrollToTop(!scroll));
    dispatch(setCartItems(null));
  }, []);

  const handleGopalanClick = () => {
    navigate(SCREEN_PATH.ECOMMERCE);
    window.location.reload();
  };

  const address = [
    shippingAddress?.address_line_one,
    shippingAddress?.address_line_two,
    shippingAddress?.country_id,
    shippingAddress?.state_id?.name,
    shippingAddress?.district_id?.name,
    shippingAddress?.taluka_id?.name,
    shippingAddress?.village_id?.name,
    shippingAddress?.post_code
  ];

  return (
    <Box sx={styles.container}>
      <Box sx={styles.subCont}>
        <Text variant="boldH48"> {getTitles('T-11722')}</Text>
        <Text variant="boldH32"> {getTitles('T-11723')}</Text>
        <Text variant="BoldH22" style={styles.secondaryText}>
          {getTitles('T-11724')}
        </Text>
        <Text variant="BoldH22" style={styles.secondaryText}>
          {address.filter((rr) => ![null, undefined, ''].includes(rr)).join(', ')}
        </Text>
        <UIButton style={styles.btn} title={getTitles('T-11725')} onClick={handleGopalanClick} />
      </Box>
    </Box>
  );
};
