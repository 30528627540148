import { Box } from '@mui/material';
import { styles } from './styles';
import { CartCounter } from '../../utility-components';
import { Text } from '../../../../ui-kits';
import CancelIcon from '@mui/icons-material/Cancel';
import { getFormatDate, getTitles } from '../../../../utils';
import { defaultValues } from '../../../../constants';
import { globalStyles } from '../../../../theme';

export const ShoppingCartCard = ({
  price,
  totalPrice,
  materialGroup,
  productName,
  count,
  image,
  addQty = () => {},
  minusQty = () => {},
  onCloseButtonClick = () => {},
  myOrdersCard = false,
  myOrderQuantity = null,
  myOrderOrderedDate = null,
  myOrderStatusDate = null,
  myOrderStatusTitle = null,
  myOrderStatus = null,
  cardMainContStyle,
  myOrderTrackingNo,
  myOrderTrackingLink
}) => {
  const res = defaultValues.isResponsive;
  return (
    <Box sx={styles.container(cardMainContStyle)}>
      {!myOrdersCard && <CancelIcon sx={styles.closeIcon} onClick={onCloseButtonClick} />}

      <Box sx={styles.subCont(myOrdersCard)}>
        <Box sx={styles.imgCont}>
          <img style={styles.img} src={image} />
          {res && !myOrdersCard && <Text variant="BoldH24">{`₹ ${price || ''}`}</Text>}
          {res && myOrdersCard && <Text variant="BoldH24">{`₹ ${totalPrice || ''}`}</Text>}
          {!myOrdersCard && (
            <CartCounter
              containerStyles={styles.counterCont}
              count={count}
              onAddClick={addQty}
              onMinusClick={minusQty}
            />
          )}
          {myOrdersCard && myOrderStatus && res && myOrderStatus}
        </Box>
        <Box sx={styles.detailsCont(myOrdersCard)}>
          {myOrdersCard ? (
            <Box sx={styles.productNameCont}>
              <Text variant="BoldH24"> {productName}</Text>
            </Box>
          ) : (
            <Text variant="BoldH24"> {productName}</Text>
          )}

          {!myOrdersCard && (
            <Box sx={styles.materialCont}>
              <Text variant="BoldH16"> {`${getTitles('T-10180')} : `}</Text>
              <Text variant="D14" style={styles.secondaryText}>
                {materialGroup}
              </Text>
            </Box>
          )}
          {myOrdersCard && myOrderQuantity && (
            <Box sx={styles.detailsCommonSubCont}>
              <Text variant={res ? 'D18' : 'BoldH22'}> {`${getTitles('T-11022')} : `}</Text>
              <Text variant={res ? 'D18' : 'D22'} style={styles.secondaryText}>
                {myOrderQuantity}
              </Text>
            </Box>
          )}
          {myOrdersCard && myOrderOrderedDate && res && (
            <Box sx={styles.detailsCommonSubCont}>
              <Text variant="BoldH16"> {`${getTitles('T-11674')} : `}</Text>
              <Text variant="D14" style={styles.secondaryText}>
                {getFormatDate(myOrderOrderedDate)}
              </Text>
            </Box>
          )}
          {myOrdersCard && myOrderStatusDate && res && (
            <Box sx={styles.detailsCommonSubCont}>
              <Text variant="BoldH16" style={{ maxWidth: '50%' }}>
                {`${myOrderStatusTitle} : `}
              </Text>
              <Text variant="D14" style={styles.secondaryText}>
                {getFormatDate(myOrderStatusDate)}
              </Text>
            </Box>
          )}

          {myOrdersCard && myOrderTrackingNo && res && (
            <Box sx={styles.detailsCommonSubCont}>
              <Text variant="BoldH16">{`${getTitles('T-11727')} : `} </Text>
              <Text variant="D14" style={styles.secondaryText}>
                {myOrderTrackingNo}
              </Text>
            </Box>
          )}

          {myOrdersCard && myOrderTrackingLink && res && (
            <Box sx={styles.detailsCommonSubCont}>
              <Text variant="BoldH16"> {`${getTitles('T-11728')} : `}</Text>
              <Text
                variant="D14"
                style={{ ...styles.secondaryText, ...globalStyles.title, width: '50%' }}
                onClick={() => myOrderTrackingLink && window.open(myOrderTrackingLink)}>
                {myOrderTrackingLink}
              </Text>
            </Box>
          )}

          {!res && !myOrdersCard && (
            <Text variant="D24" style={styles.secondaryText}>
              {`₹ ${price || ''}`}
            </Text>
          )}
          {res && !myOrdersCard && (
            <Box sx={styles.totalCont}>
              <Text variant="BoldH16"> {getTitles('T-11665')}</Text>
              <Text variant="D14" style={styles.priceText}>
                {`₹ ${totalPrice || ''}`}
              </Text>
            </Box>
          )}
        </Box>
        {!res && (
          <Box sx={styles.priceCont(myOrdersCard)}>
            <Text variant={myOrdersCard ? 'boldH32' : 'BoldH22'}> {`₹ ${totalPrice || ''}`}</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};
