import React from 'react';
import { Box } from '@mui/material';
import { defaultValues } from '../../../constants/defaultValues';
import { footerStyles } from './styles';
import { CommonFooter } from './common-footer';
import { EcommerceFooter } from './ecommerce-footer';
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';

export const Footer = ({ type, containerStyle }) => {
  const { eCommerce } = defaultValues.appLogicalTexts;

  const handleClick = (item) => {
    window.open(item.url);
  };

  const social = [
    {
      icon: <FacebookSharpIcon sx={footerStyles.socialIcon(0)} />,
      url: 'https://www.facebook.com/profile.php?id=61562227682971&sfnsn=wiwspwa&mibextid=RUbZ1f'
    },
    {
      icon: <YouTubeIcon sx={footerStyles.socialIcon(1)} />,
      url: 'https://www.youtube.com/@MyGopalanCreatingHappyFarmers'
    },
    {
      icon: <InstagramIcon sx={footerStyles.socialIcon(0)} />,
      url: 'https://www.instagram.com/mygopalan24'
    }
  ];
  return (
    <Box sx={footerStyles.container(type === eCommerce, containerStyle)}>
      {type === eCommerce ? (
        <EcommerceFooter social={social} handleClick={handleClick} />
      ) : (
        <CommonFooter type={type} social={social} handleClick={handleClick} />
      )}
    </Box>
  );
};
