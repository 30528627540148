import { Box, Grid } from '@mui/material';
import { defaultValues, getErrorMessage } from '../../../../constants';
import {
  PopupModal,
  Text,
  UIAutocompleted,
  UISelect,
  UITextInputWithTitle
} from '../../../../ui-kits';
import {
  addAdjustmentSchema,
  addOutwardToEmployeeSchema,
  addOutwardToStoreSchema,
  commonAddOutwardSchema,
  getMasterListItems,
  getTitles
} from '../../../../utils';
import { ModalButtons } from '../../../common/modal-buttons';
import { styles } from './style';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAdjustmentProductRequest,
  addOutwardProductRequest,
  getInwardEmployeeRequest,
  getMasterListWiseRequest,
  getStoreRequest,
  setOutwardActionType,
  setOutwardAddType,
  setOutwardProductData
} from '../../../../services/redux/slices';
import {
  getInwardEmployees,
  getOutwardActionType,
  getOutwardAddType,
  getOutwardProductData,
  getUserData
} from '../../../../services/redux/selectors';
import { getMasterLists, getStore } from '../../../../services/redux/selectors/manageProccess';
import { useFormik } from 'formik';

export const AddOutwardAdjustment = ({ isReadOnly }) => {
  let timer;
  const dispatch = useDispatch();
  const isRes = defaultValues.isResponsive;
  const mob = defaultValues.isMobile;
  const { outward, adjustment } = defaultValues.productEntryType;

  const listItems = useSelector(getMasterLists);
  const employees = useSelector(getInwardEmployees);
  const stores = useSelector(getStore);
  const user = useSelector(getUserData);
  const actionType = useSelector(getOutwardActionType);
  const addType = useSelector(getOutwardAddType);
  const product = useSelector(getOutwardProductData);

  const [btnDisabled, setBtndisabled] = useState(true);
  const [reasonStockTransfer, setReasonStockTransfer] = useState([]);
  const [reasonStockAdjustment, setReasonStockAdjustment] = useState([]);
  const [quantityError, setQuantityError] = useState(false);
  const [transferToError, setTransferToError] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [employeeLocationParam, setEmployeeLocationParam] = useState([]);
  const [id, setId] = useState('');
  const [schema, setSchema] = useState(null);

  useEffect(() => {
    addType === outward && setSchema(commonAddOutwardSchema);
    addType === adjustment && setSchema(addAdjustmentSchema);
  }, [addType]);

  useEffect(() => {
    let userPermission = user?.employee_permissions?.find(
      (ele) => ele.permission_id === defaultValues.employeeRol.inventoryManager
    );
    if (userPermission) {
      setEmployeeLocationParam([
        { state: userPermission?.assign_location?.state_id },
        { district: userPermission?.assign_location?.district_id },
        { taluk: userPermission?.assign_location?.taluka_id },
        { village: userPermission?.assign_location?.village_id }
      ]);
    } else {
      setEmployeeLocationParam([]);
    }
  }, [user]);

  useEffect(() => {
    dispatch(
      getMasterListWiseRequest({
        master_id: JSON.stringify([
          defaultValues.mListIds.reasonForStockTransfer,
          defaultValues.mListIds.reasonForStockAdjustment
        ]),
        filter: JSON.stringify([{ is_active: true }])
      })
    );
  }, []);

  useEffect(() => {
    if (listItems) {
      setReasonStockTransfer(
        getMasterListItems(listItems, defaultValues.mListIds.reasonForStockTransfer)
      );
      setReasonStockAdjustment(
        getMasterListItems(listItems, defaultValues.mListIds.reasonForStockAdjustment)
      );
    }
  }, [listItems]);

  useEffect(() => {
    employees && setEmployeeList(employees);
    stores && setStoreList(stores);
  }, [employees, stores]);

  const { values, errors, touched, dirty, handleBlur, setFieldValue, resetForm, isValid } =
    useFormik({
      initialValues: {
        transfer_to: { id: '' },
        store: { _id: '' },
        employee: { _id: '' },
        transfer_unit: '',
        reason: { _id: '' }
      },
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: schema
    });

  useEffect(() => {
    if (
      values.store?._id === product.store?._id ||
      values.employee?._id === product.employee?._id
    ) {
      setTransferToError(getErrorMessage('E-10276'));
      setBtndisabled(true);
      setId('');
    } else {
      setTransferToError(null);
      setBtndisabled(false);
    }
    if (parseInt(values?.transfer_unit) > parseInt(product?.balance_unit)) {
      setQuantityError(true);
      setBtndisabled(true);
    } else {
      setQuantityError(false);
      setBtndisabled(false);
    }
  }, [
    values.store?._id,
    values.employee?._id,
    product.employee?._id,
    product.store?._id,
    values?.transfer_unit,
    product?.balance_unit
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    if (name === 'transfer_to.id') {
      if (value === 3) {
        setFieldValue('store._id', null);
        setFieldValue('employee._id', null);
        setSchema(commonAddOutwardSchema);
      } else if (value === 2) {
        setFieldValue('store._id', null);
        setSchema(addOutwardToEmployeeSchema);
        setId('');
      } else if (value === 1) {
        setFieldValue('employee._id', null);
        setSchema(addOutwardToStoreSchema);
        setId('');
      }
      setStoreList([]);
      setEmployeeList([]);
    }
    setBtndisabled(false);
  };

  const handleSubmit = () => {
    let payload = {
      inward_entry_id: product?.inward_entry_id,
      transfer_unit: parseInt(values?.transfer_unit),
      reason: values.reason?._id
    };
    if (addType === outward) {
      if (product.store) {
        payload.transfer_from = defaultValues.inwardAssignTo.store;
        payload.transfer_from_store_id = product.store?._id;
      } else if (product.employee) {
        payload.transfer_from = defaultValues.inwardAssignTo.employee;
        payload.transfer_from_employee_id = product.employee?._id;
      }
      if (payload.transfer_from !== 3) {
        payload.transfer_to = values.transfer_to?.id;
        if (values.transfer_to?.id === defaultValues.inwardAssignTo.store) {
          payload.transfer_to_store_id = values.store?._id;
        } else if (values.transfer_to?.id === defaultValues.inwardAssignTo.employee) {
          payload.transfer_to_employee_id = values.employee?._id;
        }
      }
      dispatch(addOutwardProductRequest(payload));
    }
    if (addType === adjustment) {
      if (product.store) {
        payload.assign_to = defaultValues.inwardAssignTo.store;
        payload.store_id = product.store?._id;
      } else if (product.employee) {
        payload.assign_to = defaultValues.inwardAssignTo.employee;
        payload.employee_id = product.employee?._id;
      }
      dispatch(addAdjustmentProductRequest(payload));
    }

    resetForm();
    handleClose();
  };

  const handleClose = () => {
    dispatch(setOutwardProductData({}));
    dispatch(setOutwardActionType(null));
    dispatch(setOutwardAddType(null));
  };

  return (
    <>
      <PopupModal
        showModel={actionType !== null}
        containerStyle={styles.modalCont}
        onClose={handleClose}
        title={
          (addType === outward && getTitles('T-11463')) ||
          (addType === adjustment && getTitles('T-11464'))
        }
        isReadOnly={isReadOnly}
        titlePosition={'left'}
        titleStyle={styles.titleTxt}
        childContStyle={styles.childCont}>
        <Box>
          <Text style={styles.productTxt}>
            {product.product_master?.name} &nbsp; {`(${product.product_master?.product_id})`}
          </Text>
          <Text style={styles.batchTxt}>
            {getTitles('T-11481')}: &nbsp; {product?.batch_number}
          </Text>
          <Grid container spacing={!isRes && 6} sx={styles.gridCont}>
            <Grid item xs={12} sm={12} md={5} lg={3.3} xl={3}>
              <UITextInputWithTitle
                title={
                  (product.store && getTitles('T-11419')) ||
                  (product.employee && getTitles('T-11572'))
                }
                value={
                  (product.store && product.store?.name) ||
                  (product.employee && product.employee?.name)
                }
                width={styles.width}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3.3} xl={3}>
              <UITextInputWithTitle
                title={getTitles('T-10751')}
                value={
                  (product.store && product.store?.store_id) ||
                  (product.employee && product.employee?.employee_id)
                }
                width={styles.width}
                disabled={true}
              />
            </Grid>
          </Grid>
          {addType === outward && (
            <>
              <Grid container sx={styles.gridCont}>
                <Grid item xs={12}>
                  <UISelect
                    name="transfer_to.id"
                    title={getTitles('T-11591')}
                    value={values.transfer_to?.id}
                    options={defaultValues.outwardToOptions}
                    optionLabel="type"
                    returnValue={'id'}
                    onChange={handleChange}
                    error={touched.transfer_to?.id && errors.transfer_to?.id}
                    onBlur={handleBlur}
                    required={true}
                    placeholder={getTitles('T-10350')}
                    width={styles.width}
                  />
                </Grid>
              </Grid>
              {values?.transfer_to?.id && (
                <Grid container spacing={!isRes && 6} sx={styles.gridCont}>
                  <Grid item xs={12} sm={12} md={5} lg={3.3} xl={3}>
                    {values?.transfer_to?.id === 1 && (
                      <UIAutocompleted
                        required={true}
                        containerStyle={styles.autoCompleteContainer}
                        titleStyle={styles.autoText}
                        width={styles.width}
                        options={storeList}
                        optionLabel={(elem) => elem && elem.name}
                        title={getTitles('T-11419')}
                        placeHolder={getTitles('T-10350')}
                        error={touched.store?._id || errors.store?._id || transferToError}
                        onChange={(val) => {
                          setFieldValue('store._id', val?._id);
                          setId(val?.store_id);
                          setBtndisabled(false);
                        }}
                        onTyping={(val) => {
                          clearTimeout(timer);
                          timer = setTimeout(() => {
                            if (val.length > 2) {
                              dispatch(
                                getStoreRequest({
                                  search_text: val,
                                  filter: [{ is_active: true }]
                                })
                              );
                            }
                          }, 500);
                        }}
                      />
                    )}
                    {values?.transfer_to?.id === 2 && (
                      <UIAutocompleted
                        required={true}
                        containerStyle={styles.autoCompleteContainer}
                        titleStyle={styles.autoText}
                        width={styles.width}
                        options={employeeList}
                        optionLabel={(elem) => elem && elem.first_name + ' ' + elem.last_name}
                        title={getTitles('T-11572')}
                        placeHolder={getTitles('T-10350')}
                        error={touched.employee?._id || errors.employee?._id || transferToError}
                        onChange={(val) => {
                          setFieldValue('employee._id', val?._id);
                          setId(val?.employee_id);
                          setBtndisabled(false);
                        }}
                        onTyping={(val) => {
                          clearTimeout(timer);
                          timer = setTimeout(() => {
                            if (val.length > 2) {
                              dispatch(
                                getInwardEmployeeRequest({
                                  employee_permission_list: JSON.stringify([
                                    defaultValues.employeeRol.labSupportEmployee,
                                    defaultValues.employeeRol.laboratoryIncharge,
                                    defaultValues.employeeRol.vetDoctor,
                                    defaultValues.employeeRol.aiWorker
                                  ]),
                                  ...(employeeLocationParam.length > 0 && {
                                    location: JSON.stringify(employeeLocationParam)
                                  }),
                                  search_text: val
                                })
                              );
                            }
                          }, 500);
                        }}
                      />
                    )}
                    {values?.transfer_to?.id === 3 && (
                      <UITextInputWithTitle
                        title={getTitles('T-11449')}
                        value={product?.vendor.name}
                        width={styles.width}
                        disabled={true}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={3.3} xl={3}>
                    <UITextInputWithTitle
                      title={getTitles('T-10751')}
                      value={values?.transfer_to?.id === 3 ? product?.vendor.vendor_id : id}
                      width={styles.width}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
          <Grid container spacing={!isRes ? 6 : mob && 2} sx={styles.gridCont}>
            <Grid item xs={12} sm={12} md={5} lg={3.3} xl={3}>
              <UITextInputWithTitle
                name="transfer_unit"
                title={
                  (addType === outward && isRes && getTitles('T-11631')) ||
                  (addType === outward && !isRes && getTitles('T-11579')) ||
                  (addType === adjustment && isRes && getTitles('T-11634')) ||
                  (addType === adjustment && !isRes && getTitles('T-11633'))
                }
                value={values.transfer_unit}
                placeHolder={getTitles('T-11648')}
                onChange={(val) => {
                  if (val.target.value !== '0') {
                    handleChange(val);
                    setBtndisabled(false);
                  }
                }}
                error={touched.transfer_unit && errors.transfer_unit}
                required={true}
                onBlur={handleBlur}
                allowOnlyNumbers={true}
                width={styles.width}
              />
              {quantityError && (
                <Text variant="fieldError">
                  {(addType === outward && getErrorMessage('E-10272')) ||
                    (addType === adjustment && getErrorMessage('E-10277'))}
                </Text>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3.3} xl={3}>
              <UITextInputWithTitle
                title={isRes ? getTitles('T-11632') : getTitles('T-11580')}
                width={styles.width}
                value={product?.balance_unit}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container sx={styles.gridCont}>
            <Grid item xs={12}>
              {addType === outward && (
                <UISelect
                  name="reason._id"
                  title={getTitles('T-11035')}
                  required={true}
                  options={reasonStockTransfer}
                  value={values.reason?._id}
                  optionLabel="name"
                  returnValue={'_id'}
                  placeholder={getTitles('T-10350')}
                  onChange={handleChange}
                  error={touched.reason?._id && errors.reason?._id}
                  onBlur={handleBlur}
                  width={styles.width}
                />
              )}
              {addType === adjustment && (
                <UISelect
                  name="reason._id"
                  title={getTitles('T-11035')}
                  required={true}
                  options={reasonStockAdjustment}
                  value={values.reason?._id}
                  optionLabel="name"
                  returnValue={'_id'}
                  placeholder={getTitles('T-10350')}
                  onChange={handleChange}
                  error={touched.reason?._id && errors.reason?._id}
                  onBlur={handleBlur}
                  width={styles.width}
                />
              )}
            </Grid>
          </Grid>

          <Grid container sx={styles.gridCont}>
            <Grid item xs={12}>
              <ModalButtons
                btnPositions={'flex-start'}
                containerStyle={styles.buttonCont}
                primaryBtnTitle={
                  (addType === outward && getTitles('T-11431')) ||
                  (addType === adjustment && getTitles('T-10394'))
                }
                onPrimaryBtnClick={handleSubmit}
                disabled={!dirty || btnDisabled || !isValid || quantityError || transferToError}
              />
            </Grid>
          </Grid>
        </Box>
      </PopupModal>
    </>
  );
};
