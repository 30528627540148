import { Box } from '@mui/material';
import { UIPhoneInputTitle, UISaveAndAdd, UITextInputWithTitle } from '../../../../../ui-kits';
import { getTitles } from '../../../../../utils';
import { defaultValues } from '../../../../../constants';
import { Address } from '../../../../common/address';

const res = defaultValues.isResponsive;
const styles = {
  container: {
    width: '100%'
  },
  nameCont: {
    width: '100%',
    display: 'flex',
    flexDirection: res ? 'column' : 'row',
    gap: '10px'
  },
  btnCont: {
    marginTop: '50px'
  },
  title: {
    cursor: 'pointer'
  },
  nameWidth: defaultValues.isResponsive ? '100%' : 'calc((100% - 10px)/3)',
  phoneWidth: defaultValues.isResponsive ? '100%' : 'calc(100%/3)'
};

export const EmergencyForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setBtndisabled,
  handleAddAnother,
  isValid,
  btnDisabled,
  dirty,
  emergency
}) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="name"
          title={getTitles('T-10081')}
          placeHolder={getTitles('T-10384')}
          error={touched.name && errors.name}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
          width={styles.nameWidth}
          allowOnlyAlphabets={true}
        />
        <UIPhoneInputTitle
          phoneValue={values.phone_number}
          onChange={(val) => {
            setFieldValue('phone_number', val);
            setBtndisabled(val ? false : true);
          }}
          handleError={(isError) => setBtndisabled(isError)}
          required={true}
          width={styles.phoneWidth}
        />
      </Box>

      <UITextInputWithTitle
        name="relation"
        title={getTitles('T-10365')}
        placeHolder={getTitles('T-10366')}
        error={touched.relation && errors.relation}
        value={values.relation}
        onChange={handleChange}
        onBlur={handleBlur}
        required={true}
        width={styles.nameWidth}
      />

      <Box sx={styles.nameCont}>
        <UITextInputWithTitle
          name="address_line_one"
          title={getTitles('T-10357')}
          placeHolder={getTitles('T-10361')}
          error={touched.address_line_one && errors.address_line_one}
          value={values.address_line_one}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
        />
        <UITextInputWithTitle
          name="address_line_two"
          title={getTitles('T-10358')}
          placeHolder={getTitles('T-10362')}
          error={touched.address_line_two && errors.address_line_two}
          value={values.address_line_two}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Box>

      <Address
        actionType={defaultValues.actionType.edit}
        objects={values}
        divider={false}
        type={'location'}
        showPostCode={true}
        isCountryRequired={true}
        isStateRequired={true}
        isDistrictRequired={true}
        isTalukaRequired={true}
        isPostCodeRequired={true}
        onCountrySelected={(e) =>
          handleChange({ target: { name: 'country_id', value: e.target.value } })
        }
        onCountryBlurred={handleBlur('country_id')}
        countryCodeError={touched.country_id && errors.country_id}
        onStateSelected={(val) => {
          handleChange({ target: { name: 'state_id', value: val ? val : { _id: '' } } });
        }}
        onStateBlurred={handleBlur('state_id')}
        stateCodeError={touched.state_id && errors.state_id?._id}
        onDistrictSelected={(val) =>
          handleChange({ target: { name: 'district_id', value: val ? val : { _id: '' } } })
        }
        onDistrictBlurred={handleBlur('district_id')}
        districtCodeError={touched.district_id && errors.district_id?._id}
        onTalukaSelected={(val) =>
          handleChange({ target: { name: 'taluka_id', value: val ? val : { _id: '' } } })
        }
        onTalukaBlurred={handleBlur('taluka_id')}
        talukaCodeError={touched.taluka_id && errors.taluka_id?._id}
        onVillageSelected={(val) =>
          handleChange({ target: { name: 'village_id', value: val ? val : { _id: '' } } })
        }
        onPostCodeBlurred={handleBlur('post_code')}
        postCodeError={touched.post_code && errors.post_code}
        onPostCodeChange={(val) => {
          handleChange({ target: { name: 'post_code', value: val.target.value } });
        }}
      />

      <UISaveAndAdd
        title={emergency === true ? getTitles('T-10062') : getTitles('T-10477')}
        onAddAnother={handleAddAnother}
        disabled={!isValid || !dirty || btnDisabled}
      />
    </Box>
  );
};
