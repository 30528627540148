import * as yup from 'yup';
import { getErrorMessage, regExp } from '../constants';

export const loginSchema = yup.object({
  email: yup
    .string()
    .required(getErrorMessage('E-10002'))
    .email(getErrorMessage('E-10003'))
    .matches(regExp.email, getErrorMessage('E-10003'))
});

export const profileSchema = yup.object().shape({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  email_id: yup.string().matches(regExp.email, getErrorMessage('E-10084'))
});

export const profileBillAddressSchema = yup.object().shape({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  email_id: yup.string().matches(regExp.email, getErrorMessage('E-10084')),
  address: yup.object().shape({
    address_line_one: yup.string().required(getErrorMessage('E-10081')),
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    }),
    post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
  })
});

export const profileShipAddressSchema = yup.object().shape({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  email_id: yup.string().matches(regExp.email, getErrorMessage('E-10084')),
  shipping_address: yup.object().shape({
    address_line_one: yup.string().required(getErrorMessage('E-10081')),
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    }),
    village_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10092'))
    }),
    post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
  })
});

export const profileBillShipAddressSchema = yup.object().shape({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  email_id: yup.string().matches(regExp.email, getErrorMessage('E-10084')),
  address: yup.object().shape({
    address_line_one: yup.string().required(getErrorMessage('E-10081')),
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    }),
    post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
  }),
  shipping_address: yup.object().shape({
    address_line_one: yup.string().required(getErrorMessage('E-10081')),
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    }),
    post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
  })
});

export const stateSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10025')),
  code: yup.string().required(getErrorMessage('E-10026'))
});

export const districtSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10046')),
  code: yup.string().required(getErrorMessage('E-10078'))
});

export const talukaSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10048')),
  code: yup.string().required(getErrorMessage('E-10079'))
});

export const villageSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10049')),
  code: yup.string().required(getErrorMessage('E-10080'))
});

export const masterListSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10029'))
});

export const addAdminSchema = yup.object({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  admin_roles: yup.array().min(1, getErrorMessage('E-10022')),
  states: yup.array().min(1, getErrorMessage('E-10051')),
  districts: yup.array().min(1, getErrorMessage('E-10052')),
  taluka: yup.array().min(1, getErrorMessage('E-10050')),
  villages: yup.array().min(1, getErrorMessage('E-10045'))
});

export const languageSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10039')),
  native_name: yup.string().required(getErrorMessage('E-10040'))
});

export const diseaseSchema = yup.object().shape({
  name: yup.string().required(getErrorMessage('E-10055')),
  type_id: yup.object().required(getErrorMessage('E-10056')),
  group_id: yup.object().required(getErrorMessage('E-10057'))
});

export const employeePersonalSchema = yup.object().shape({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  phone_number: yup.string().required(getErrorMessage('E-10057')),
  address_line_one: yup.string().required(getErrorMessage('E-10081')),
  email_id: yup.string().matches(regExp.email, getErrorMessage('E-10084')),
  secondary_email_id: yup.string().matches(regExp.email, getErrorMessage('E-10084')),
  date_of_birth: yup.string().required(getErrorMessage('E-10085')),
  pan_card_number: yup.string().min(10, getErrorMessage('E-10130')),
  nationality: yup.string().required(getErrorMessage('E-10086')),
  aadhaar_number: yup
    .string()
    .required(getErrorMessage('E-10087'))
    .matches(regExp.aadhaarNumber, getErrorMessage('E-10128')),
  country_id: yup.string().required(getErrorMessage('E-10088')),
  state_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10089'))
  }),
  district_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10090'))
  }),
  taluka_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10091'))
  }),
  village_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10092'))
  }),
  post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
});

export const employeeEmergencySchema = yup.object().shape({
  name: yup.string().required(getErrorMessage('E-10082')),
  phone_number: yup.string().required(getErrorMessage('E-10057')),
  address_line_one: yup.string().required(getErrorMessage('E-10081')),
  relation: yup.string().required(getErrorMessage('E-10112')),
  country_id: yup.string().required(getErrorMessage('E-10088')),
  state_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10089'))
  }),
  district_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10090'))
  }),
  taluka_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10091'))
  }),
  post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
});

export const employeePreviousemploymentSchema = yup.object().shape({
  employer_name: yup.string().required(getErrorMessage('E-10082')),
  post_code: yup.string().min(6, getErrorMessage('E-10129'))
});

export const employeeAccountsSchema = yup.object().shape({
  insurance_policy_number: yup.string().required(getErrorMessage('E-10096')),
  insurance_provider: yup.string().required(getErrorMessage('E-10135')),
  end_date: yup.string().required(getErrorMessage('E-10099')),
  start_date: yup.string().required(getErrorMessage('E-10098')),
  ifsc_code: yup.string().optional().matches(regExp.ifsc, getErrorMessage('E-10295'))
});

export const employeeEmploymentSchema = yup.object().shape({
  department_id: yup.string().required(getErrorMessage('E-10104')),
  designation_id: yup.string().required(getErrorMessage('E-10103')),
  date_joining: yup.string().required(getErrorMessage('E-10100')),
  salary: yup.string().required(getErrorMessage('E-10106'))
});

export const employeepermissionsSchema = yup.object().shape({
  permissions: yup.string().required(getErrorMessage('E-10022')),

  states: yup.array().when('$permissions', {
    is: (s) => ![9, 10].includes(s),
    then: (s) => s.min(1, getErrorMessage('E-10051')),
    otherwise: (s) => s.notRequired()
  }),
  districts: yup.array().when('$permissions', {
    is: (s) => ![9, 10].includes(s),
    then: (s) => s.min(1, getErrorMessage('E-10052')),
    otherwise: (s) => s.notRequired()
  }),
  taluka: yup.array().when('$permissions', {
    is: (s) => ![9, 10].includes(s),
    then: (s) => s.min(1, getErrorMessage('E-10050')),
    otherwise: (s) => s.notRequired()
  })
});

export const vaccineSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10066')),
  disease_id: yup.string().required(getErrorMessage('E-10067'))
});

export const societyDetailsSchema = yup.object({
  name: yup.string().required(getErrorMessage('E-10136')),
  society_code: yup.string().required(getErrorMessage('E-10137')),
  registration_date: yup.string().required(getErrorMessage('E-10138')),
  phone_number: yup.string().required(getErrorMessage('E-10017')),
  preferred_contact_mode: yup
    .object()
    .shape({ id: yup.string().required(getErrorMessage('E-10139')) }),
  society_status: yup.object().shape({ id: yup.string().required(getErrorMessage('E-10140')) }),
  society_type: yup.object().shape({ id: yup.string().required(getErrorMessage('E-10141')) }),
  location: yup.object().shape({
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    }),
    post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
  }),
  address: yup.object().shape({
    post_code: yup.string().min(6, getErrorMessage('E-10129'))
  }),
  bank_details: yup.object().shape({
    bank_name: yup.string(),
    account_number: yup.string().when('$bank_details.bank_name', {
      is: (s) => ![null, undefined, ''].includes(s),
      then: (s) => s.required(getErrorMessage('E-10110')),
      otherwise: (s) => s.notRequired()
    }),
    ifsc_code: yup.string().when('$bank_details.bank_name', {
      is: (s) => ![null, undefined, ''].includes(s),
      then: (s) =>
        s.required(getErrorMessage('E-10095')).matches(regExp.ifsc, getErrorMessage('E-10295')),
      otherwise: (s) => s.notRequired()
    }),
    branch_name: yup.string().when('$bank_details.bank_name', {
      is: (s) => ![null, undefined, ''].includes(s),
      then: (s) => s.required(getErrorMessage('E-10289')),
      otherwise: (s) => s.notRequired()
    })
  })
});

export const societyOperationalSchema = yup.object({
  total_member: yup.string().required(getErrorMessage('E-10142')),
  active_member: yup.string().required(getErrorMessage('E-10143')),
  employee_count: yup.string().required(getErrorMessage('E-10144')),
  chairman_first_name: yup.string().required(getErrorMessage('E-10015')),
  chairman_last_name: yup.string().required(getErrorMessage('E-10016')),
  chairman_phone_number: yup.string().required(getErrorMessage('E-10017')),
  secretary_first_name: yup.string().required(getErrorMessage('E-10015')),
  secretary_last_name: yup.string().required(getErrorMessage('E-10016')),
  secretary_phone_number: yup.string().required(getErrorMessage('E-10017'))
});

export const farmerPersonalSchema = yup.object().shape({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  phone_number: yup.string().required(getErrorMessage('E-10057')),
  longitude: yup.number().required(getErrorMessage('E-10113')),
  latitude: yup.number().required(getErrorMessage('E-10114')),
  altitude: yup.number().required(getErrorMessage('E-10115')),
  member_location: yup.object().shape({
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    })
  }),
  address: yup.object().shape({
    post_code: yup.string().nullable().min(6, getErrorMessage('E-10129'))
  }),
  religion_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10132'))
  }),
  caste_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10117'))
  }),
  // sub_caste_id: yup.object().shape({
  //   _id: yup.string().required(getErrorMessage('E-10131'))
  // }),
  communication_mode: yup.object().shape({
    id: yup.string().required(getErrorMessage('E-10116'))
  }),
  aadhaar_number: yup.string().nullable().matches(regExp.aadhaarNumber, getErrorMessage('E-10128')),
  pan_card_number: yup
    .string()
    .nullable()
    .matches(regExp.panCardNumber, getErrorMessage('E-10130')),

  bank_details: yup.object().shape({
    bank_name: yup.string(),
    account_number: yup.string().when('$bank_details.bank_name', {
      is: (s) => ![null, undefined, ''].includes(s),
      then: (s) => s.required(getErrorMessage('E-10110')),
      otherwise: (s) => s.notRequired()
    }),
    ifsc_code: yup.string().when('$bank_details.bank_name', {
      is: (s) => ![null, undefined, ''].includes(s),
      then: (s) =>
        s.required(getErrorMessage('E-10095')).matches(regExp.ifsc, getErrorMessage('E-10295')),
      otherwise: (s) => s.notRequired()
    }),
    branch_name: yup.string().when('$bank_details.bank_name', {
      is: (s) => ![null, undefined, ''].includes(s),
      then: (s) => s.required(getErrorMessage('E-10289')),
      otherwise: (s) => s.notRequired()
    })
  })
});

export const CattlelSchema = yup.object().shape({
  cattle_type: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10146'))
  }),
  cattle_breed: yup.object().shape({ _id: yup.string().required(getErrorMessage('E-10147')) }),
  cattle_name: yup.string().required(getErrorMessage('E-10151')),
  age: yup.object().shape({
    years: yup.string().required(getErrorMessage('E-10152')),
    months: yup.string().required(getErrorMessage('E-10152'))
  }),
  girth: yup.string().required(getErrorMessage('E-10154')),
  length: yup.string().required(getErrorMessage('E-10153'))
});

export const societyAdditionalSchema = yup.object().shape({
  ai_worker_available: yup.boolean().nullable(),
  ai_worker_first_name: yup.string().when('$ai_worker_available', {
    is: (s) => s === true,
    then: (s) => s.required(getErrorMessage('E-10015')),
    otherwise: (s) => s.notRequired()
  }),
  ai_worker_last_name: yup.string().when('$ai_worker_available', {
    is: (s) => s === true,
    then: (s) => s.required(getErrorMessage('E-10016')),
    otherwise: (s) => s.notRequired()
  })
});

export const villageDetailsSchema = yup.object().shape({
  village: yup.string().required(getErrorMessage('E-10082')),
  village_code: yup.string().required(getErrorMessage('E-10080')),
  pincode: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
});

export const insuranceSchema = yup.object().shape({
  insurance_company_id: yup.string().required(getErrorMessage('E-10168')),
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  phone_number: yup.string().required(getErrorMessage('E-10017')),
  address_line_one: yup.string().required(getErrorMessage('E-10081')),
  country_id: yup.string().required(getErrorMessage('E-10088')),
  state_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10089'))
  }),
  district_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10090'))
  }),
  taluka_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10091'))
  }),
  post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129')),
  assigned_location: yup.object().shape({
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.array().min(1, getErrorMessage('E-10089')),
    district_id: yup.array().min(1, getErrorMessage('E-10090')),
    taluka_id: yup.array().min(1, getErrorMessage('E-10091'))
  })
});

export const labDetailsSchema = yup.object().shape({
  laboratory_name: yup.string().required(getErrorMessage('E-10082')),
  primary_mobile_number: yup.string().required(getErrorMessage('E-10017')),
  email: yup.string().matches(regExp.email, getErrorMessage('E-10084')),
  geographic_coordinates: yup.object().shape({
    longitude: yup.string().required(getErrorMessage('E-10113')),
    latitude: yup.string().required(getErrorMessage('E-10114')),
    altitude: yup.string().required(getErrorMessage('E-10115'))
  }),
  address: yup.object().shape({
    address_line_one: yup.string().required(getErrorMessage('E-10081')),
    country: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    }),
    pincode: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
  })
});

export const govtOfficerSchema = yup.object().shape({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  phone_number: yup.string().required(getErrorMessage('E-10017')),
  department: yup.string().required(getErrorMessage('E-10104')),
  email_id: yup.string().matches(regExp.email, getErrorMessage('E-10084')),
  address: yup.object().shape({
    address_line_one: yup.string().required(getErrorMessage('E-10081')),
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    }),
    post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
  }),
  assign_location: yup.object().shape({
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.array().min(1, getErrorMessage('E-10089')),
    district_id: yup.array().min(1, getErrorMessage('E-10090')),
    taluka_id: yup.array().min(1, getErrorMessage('E-10091'))
  })
});

export const cattleProfileSchema = yup.object({
  flow_type: yup.string().required(getErrorMessage('E-10174')),
  flow_name: yup.string().required(getErrorMessage('E-10173'))
});

export const profileConditionSchema = yup.object({
  trigger_rule: yup.string().required(getErrorMessage('E-10191')),
  rules: yup.object().shape({
    subject: yup.string().required(getErrorMessage('E-10176')),
    condition_number: yup.string().required(getErrorMessage('E-10177')),
    condition_number_min_value: yup.string().required(getErrorMessage('E-10178')),
    condition_number_max_value: yup.string().required(getErrorMessage('E-10178')),
    condition_string: yup.string().required(getErrorMessage('E-10177')),
    condition_string_value: yup.string().required(getErrorMessage('E-10178')),
    condition_date: yup.string().required(getErrorMessage('E-10177')),
    condition_date_start_value: yup.string().required(getErrorMessage('E-10178')),
    condition_date_end_value: yup.string().required(getErrorMessage('E-10178')),
    condition_date_days_value: yup.string().required(getErrorMessage('E-10178'))
  })
});

export const profileTaskSchema = yup.object({
  task_name: yup.string().required(getErrorMessage('E-10180')),
  task_type: yup.string().required(getErrorMessage('E-10192')),
  trigger_at: yup.string().required(getErrorMessage('E-10181')),
  operate: yup.string().required(getErrorMessage('E-10182')),
  no_of_days: yup.string().required(getErrorMessage('E-10183')),
  assign_role: yup.string().required(getErrorMessage('E-10185'))
});

export const storeSchema = yup.object().shape({
  name: yup.string().required(getErrorMessage('E-10208')),
  phone_number: yup.string().required(getErrorMessage('E-10017')),
  address: yup.object().shape({
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    }),
    post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
  }),
  store_manager: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10209'))
  })
});

export const callCenterTaskSchema = yup.object({
  task_name: yup.string().required(getErrorMessage('E-10180')),
  task_type: yup.string().required(getErrorMessage('E-10192')),
  due_date: yup.string().required(getErrorMessage('E-10193')),
  repeat: yup.boolean().required(getErrorMessage('E-10182')),
  no_of_days: yup.string().when('$repeat', {
    is: true,
    then: () => yup.string().required(getErrorMessage('E-10199')),
    otherwise: () => yup.string().notRequired()
  }),
  assign_to: yup.string().required(getErrorMessage('E-10185'))
});

export const commonTaskSchema = yup.object({
  task_name: yup.string().required(getErrorMessage('E-10180')),
  task_type: yup.string().required(getErrorMessage('E-10192')),
  due_date: yup.string().required(getErrorMessage('E-10193')),
  repeat: yup.boolean().required(getErrorMessage('E-10182')),
  no_of_days: yup.string().when('$repeat', {
    is: true,
    then: () => yup.string().required(getErrorMessage('E-10199')),
    otherwise: () => yup.string().notRequired()
  }),
  assign_to: yup.string().required(getErrorMessage('E-10185')),
  adjustment: yup.object().shape({
    product_id: yup.object().notRequired(),
    adjust_quantity: yup.string().when('$adjustment.product_id', {
      is: (s) => typeof s?._id === 'string',
      then: (s) => s.required(getErrorMessage('E-10277')),
      otherwise: (s) => s.notRequired()
    })
  })
});

export const vendorSchema = yup.object().shape({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  phone_number: yup.string().required(getErrorMessage('E-10017')),
  address: yup.object().shape({
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    }),
    post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
  })
});

export const productMasterSchema = yup.object().shape({
  name: yup.string().required(getErrorMessage('E-10213')),
  material_group: yup.object().shape({
    id: yup.number().required(getErrorMessage('E-10212'))
  }),
  unit: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10214'))
  }),
  min_order_qty: yup.number().required(getErrorMessage('E-10215')),
  min_stock_qty: yup.number().required(getErrorMessage('E-10216')),
  temp_conditions: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10217'))
  }),
  container_required: yup.boolean().required(getErrorMessage('E-10218'))
});

export const inwardProDetDefaultSchema = yup.object().shape({
  product_master_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10220'))
  })
});

export const inwardCommonSchema = yup.object().shape({
  product_master_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10220'))
  }),
  product_image: yup.array().min(1, getErrorMessage('E-10221')),
  category_enum: yup.string().required(getErrorMessage('E-10222')),
  brand_name: yup.string().required(getErrorMessage('E-10238')),
  product_type: yup.string().required(getErrorMessage('E-10261')),
  color: yup.string().required(getErrorMessage('E-10241')),
  power: yup.string().required(getErrorMessage('E-10242')),
  return_policy: yup.string().required(getErrorMessage('E-10239')),
  hsn: yup.string().required(getErrorMessage('E-10237')),
  mark_as: yup.string().required(getErrorMessage('E-10266')),
  assign_to: yup.number().required(getErrorMessage('E-10266')),
  store_id: yup.object({
    _id: yup.string().when('$assign_to', {
      is: (s) => s === 1,
      then: (s) => s.required(getErrorMessage('E-10208')),
      otherwise: (s) => s.notRequired()
    })
  }),

  employee_id: yup.object({
    _id: yup.string().when('$assign_to', {
      is: (s) => s === 2,
      then: (s) => s.required(getErrorMessage('E-10262')),
      otherwise: (s) => s.notRequired()
    })
  })
});

export const inwardMGCommonOrOtherSchema = yup.object().shape({
  product_master_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10220'))
  }),
  product_image: yup.array().min(1, getErrorMessage('E-10221')),
  category_id: yup.string().required(getErrorMessage('E-10222')),
  brand_name: yup.string().required(getErrorMessage('E-10238')),
  product_type: yup.string().required(getErrorMessage('E-10261')),
  color: yup.string().required(getErrorMessage('E-10241')),
  power: yup.string().required(getErrorMessage('E-10242')),
  return_policy: yup.string().required(getErrorMessage('E-10239')),
  hsn: yup.string().required(getErrorMessage('E-10237')),
  mark_as: yup.string().required(getErrorMessage('E-10266')),
  assign_to: yup.number().required(getErrorMessage('E-10266')),
  store_id: yup.object({
    _id: yup.string().when('$assign_to', {
      is: (s) => s === 1,
      then: (s) => s.required(getErrorMessage('E-10208')),
      otherwise: (s) => s.notRequired()
    })
  }),

  employee_id: yup.object({
    _id: yup.string().when('$assign_to', {
      is: (s) => s === 2,
      then: (s) => s.required(getErrorMessage('E-10262')),
      otherwise: (s) => s.notRequired()
    })
  })
});

export const equipmentCatSchema = yup.object().shape({
  product_master_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10220'))
  }),
  product_image: yup.array().min(1, getErrorMessage('E-10221')),
  category_enum: yup.string().required(getErrorMessage('E-10222')),
  brand_name: yup.string().required(getErrorMessage('E-10238')),
  power: yup.string().required(getErrorMessage('E-10242')),
  product_type: yup.string().required(getErrorMessage('E-10261')),
  return_policy: yup.string().required(getErrorMessage('E-10239')),
  hsn: yup.string().required(getErrorMessage('E-10237')),
  mark_as: yup.string().required(getErrorMessage('E-10266')),
  assign_to: yup.number().required(getErrorMessage('E-10266')),
  store_id: yup.object({
    _id: yup.string().when('$assign_to', {
      is: (s) => s === 1,
      then: (s) => s.required(getErrorMessage('E-10208')),
      otherwise: (s) => s.notRequired()
    })
  }),

  employee_id: yup.object({
    _id: yup.string().when('$assign_to', {
      is: (s) => s === 2,
      then: (s) => s.required(getErrorMessage('E-10262')),
      otherwise: (s) => s.notRequired()
    })
  })
});

export const inwardMedicinesSchema = yup.object().shape({
  product_master_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10220'))
  }),
  product_image: yup.array().min(1, getErrorMessage('E-10221')),
  category_enum: yup.string().required(getErrorMessage('E-10222')),
  brand_name: yup.string().required(getErrorMessage('E-10238')),
  refrigeration_required: yup.boolean().required(getErrorMessage('E-10240')),
  hsn: yup.string().required(getErrorMessage('E-10237')),
  mark_as: yup.string().required(getErrorMessage('E-10266')),
  assign_to: yup.number().required(getErrorMessage('E-10266')),
  store_id: yup.object({
    _id: yup.string().when('$assign_to', {
      is: (s) => s === 1,
      then: (s) => s.required(getErrorMessage('E-10208')),
      otherwise: (s) => s.notRequired()
    })
  }),

  employee_id: yup.object({
    _id: yup.string().when('$assign_to', {
      is: (s) => s === 2,
      then: (s) => s.required(getErrorMessage('E-10262')),
      otherwise: (s) => s.notRequired()
    })
  })
});

export const inwardSemenCowSchema = yup.object().shape({
  product_master_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10220'))
  }),
  product_image: yup.array().min(1, getErrorMessage('E-10221')),
  category_enum: yup.string().required(getErrorMessage('E-10222')),
  breed_id: yup.string().required(getErrorMessage('E-10223')),
  variety_id: yup.string().when('$category_enum', {
    is: (s) => s === 1,
    then: (s) => s.required(getErrorMessage('E-10273')),
    otherwise: (s) => s.notRequired()
  }),
  type: yup.string().required(getErrorMessage('E-10224')),
  producer_first_name: yup.string().required(getErrorMessage('E-10015')),
  producer_last_name: yup.string().required(getErrorMessage('E-10016')),
  iso_certified: yup.boolean().required(getErrorMessage('E-10227')),
  bull_number: yup.string().required(getErrorMessage('E-10228')),
  bull_name: yup.string().required(getErrorMessage('E-10284')),
  bull_type: yup.string().required(getErrorMessage('E-10260')),
  bull_image: yup.array().min(1, getErrorMessage('E-10230')),
  dob: yup.string().required(getErrorMessage('E-10085')),
  inaph_id: yup.string().required(getErrorMessage('E-10231')),
  dose: yup.string().required(getErrorMessage('E-10232')),
  year_production: yup.string().required(getErrorMessage('E-10233')),
  dam_yield: yup.string().required(getErrorMessage('E-10234')),
  fat_per: yup.string().required(getErrorMessage('E-10235')),
  fat_kg: yup.string().required(getErrorMessage('E-10236')),
  hsn: yup.string().required(getErrorMessage('E-10237')),
  mark_as: yup.string().required(getErrorMessage('E-10266')),
  assign_to: yup.number().required(getErrorMessage('E-10266')),
  store_id: yup.object({
    _id: yup.string().when('$assign_to', {
      is: (s) => s === 1,
      then: (s) => s.required(getErrorMessage('E-10208')),
      otherwise: (s) => s.notRequired()
    })
  }),

  employee_id: yup.object({
    _id: yup.string().when('$assign_to', {
      is: (s) => s === 2,
      then: (s) => s.required(getErrorMessage('E-10262')),
      otherwise: (s) => s.notRequired()
    })
  })
});

export const InwardVendorDetailsSchema = yup.object().shape({
  vendor_id: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10263'))
  }),
  bill_date: yup.string().required(getErrorMessage('E-10244')),
  bill_number: yup.string().required(getErrorMessage('E-10243')),
  state_supply: yup.string().required(getErrorMessage('E-10245')),
  manufacturing_date: yup.string().required(getErrorMessage('E-10246')),
  expiry_date: yup.string().required(getErrorMessage('E-10247')),
  gross_weight: yup.string().required(getErrorMessage('E-10248')),
  net_weight: yup.string().required(getErrorMessage('E-10249')),
  mrp_pack: yup.string().required(getErrorMessage('E-10250')),
  unit_price: yup.string().required(getErrorMessage('E-10251')),
  total_unit: yup.string().required(getErrorMessage('E-10252')),
  free_qty: yup.string().required(getErrorMessage('E-10253')),
  sale_price: yup.string().required(getErrorMessage('E-10254')),
  gst: yup.string().required(getErrorMessage('E-10255')),
  discount: yup.string().required(getErrorMessage('E-10256')),
  tax: yup.string().required(getErrorMessage('E-10257')),
  shipping: yup.string().required(getErrorMessage('E-10258')),
  final_price: yup.string().required(getErrorMessage('E-10259'))
});

export const commonAddOutwardSchema = yup.object().shape({
  transfer_to: yup.object().shape({
    id: yup.number().required(getErrorMessage('E-10268'))
  }),
  transfer_unit: yup.number().required(getErrorMessage('E-10269')),
  reason: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10270'))
  })
});

export const addOutwardToStoreSchema = yup.object().shape({
  transfer_to: yup.object().shape({
    id: yup.number().required(getErrorMessage('E-10268'))
  }),
  store: yup.object().shape({
    _id: yup.string().nullable().required(getErrorMessage('E-10208'))
  }),
  transfer_unit: yup.number().required(getErrorMessage('E-10269')),
  reason: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10270'))
  })
});

export const addOutwardToEmployeeSchema = yup.object().shape({
  transfer_to: yup.object().shape({
    id: yup.number().required(getErrorMessage('E-10268'))
  }),
  employee: yup.object().shape({
    _id: yup.string().nullable().required(getErrorMessage('E-10262'))
  }),
  transfer_unit: yup.number().required(getErrorMessage('E-10269')),
  reason: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10270'))
  })
});

export const addAdjustmentSchema = yup.object().shape({
  transfer_unit: yup.number().required(getErrorMessage('E-10269')),
  reason: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10270'))
  })
});

export const ecomNewUserSchema = yup.object().shape({
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016'))
});

export const contactSchema = yup.object().shape({
  name: yup.string().required(getErrorMessage('E-10082')),
  email: yup
    .string()
    .required(getErrorMessage('E-10288'))
    .matches(regExp.email, getErrorMessage('E-10084')),
  message: yup.string().required(getErrorMessage('E-10287'))
});

export const ecomCheckoutSchema = yup.object().shape({
  email_id: yup.string().matches(regExp.email, getErrorMessage('E-10084')),
  first_name: yup.string().required(getErrorMessage('E-10015')),
  last_name: yup.string().required(getErrorMessage('E-10016')),
  phone_number: yup.string().required(getErrorMessage('E-10017')),
  address: yup.object().shape({
    address_line_one: yup.string().required(getErrorMessage('E-10081')),
    country_id: yup.string().required(getErrorMessage('E-10088')),
    state_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10089'))
    }),
    district_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10090'))
    }),
    taluka_id: yup.object().shape({
      _id: yup.string().required(getErrorMessage('E-10091'))
    }),
    post_code: yup.string().required(getErrorMessage('E-10093')).min(6, getErrorMessage('E-10129'))
  })
});

export const ecomOrderSchema = yup.object().shape({
  assigned_store: yup.object().shape({
    _id: yup.string().required(getErrorMessage('E-10294'))
  })
});
