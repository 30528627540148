import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './services/redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import RootNavigation from './navigation';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { typography } from './theme';
import { KEYS } from './constants';
import { language_name } from './utils';

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Theme customization for typography
  const theme = createTheme({
    typography: {
      fontFamily: typography.primary
    },
    components: {}
  });

  window.onresize = () => {
    if (windowWidth !== window.innerWidth) {
      window.location.reload();
      setWindowWidth(window.innerWidth);
    }
  };

  useEffect(() => {
    const lang = localStorage.getItem(KEYS.LANGUAGE);
    if (!lang) localStorage.setItem(KEYS.LANGUAGE, language_name.english);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router basename={process.env.PUBLIC_URL}>
            <RootNavigation />
          </Router>
        </PersistGate>
      </Provider>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ThemeProvider>
  );
}

export default App;
