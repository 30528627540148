import { Box } from '@mui/material';
import { ViewData } from '../../../../../ui-kits';
import { getFormatDate, getTitles } from '../../../../../utils';
import { defaultValues } from '../../../../../constants';

const styles = {
  container: {
    width: '100%'
  }
};
export const ViewPersonel = ({ employeeData }) => {
  return (
    <Box sx={styles.container}>
      {employeeData && (
        <>
          <ViewData title={getTitles('T-10438')} subTitle={employeeData.employee_id} />
          <ViewData
            title={getTitles('T-10439')}
            subTitle={`${employeeData.first_name} ${employeeData.middle_name || ''} ${employeeData.last_name}`}
          />
          <ViewData title={getTitles('T-10440')} subTitle={employeeData.phone_number} />
          <ViewData title={getTitles('T-10441')} subTitle={employeeData.secondary_phone_number} />
          <ViewData title={getTitles('T-10043')} subTitle={employeeData.email_id} />
          <ViewData title={getTitles('T-10342')} subTitle={employeeData.secondary_email_id} />
          <ViewData
            title={getTitles('T-10344')}
            subTitle={employeeData.date_of_birth && getFormatDate(employeeData.date_of_birth)}
          />
          <ViewData
            title={getTitles('T-10351')}
            subTitle={
              employeeData.gender &&
              defaultValues.genderOptions.find((item) => item.value == employeeData.gender).title
            }
          />
          <ViewData title={getTitles('T-10356')} subTitle={employeeData.nationality} />
          <ViewData title={getTitles('T-10436')} subTitle={employeeData.voter_id} />
          <ViewData title={getTitles('T-10437')} subTitle={employeeData.ration_card_number} />
          <ViewData
            title={getTitles('T-10352')}
            subTitle={
              employeeData.marital_status &&
              defaultValues.maritalStatus.find((item) => item.value == employeeData.marital_status)
                .title
            }
          />
          <ViewData title={getTitles('T-10353')} subTitle={employeeData.aadhaar_number} />
          <ViewData title={getTitles('T-10354')} subTitle={employeeData.driving_license_number} />
          <ViewData title={getTitles('T-10355')} subTitle={employeeData.pan_card_number} />
          <ViewData title={getTitles('T-10374')} subTitle={employeeData.highest_qual} />

          <ViewData title={getTitles('T-10375')} subTitle={employeeData.years_experience} />

          <ViewData
            title={getTitles('T-10442')}
            subTitle={
              (employeeData.address_line_one || employeeData.address_line_two) &&
              `${employeeData.address_line_one} ${employeeData.address_line_two}`
            }
          />
          <ViewData title={getTitles('T-10363')} subTitle={employeeData.country_id} />
          <ViewData title={getTitles('T-10070')} subTitle={employeeData?.state_id?.name} />
          <ViewData title={getTitles('T-10069')} subTitle={employeeData?.district_id?.name} />
          <ViewData title={getTitles('T-10071')} subTitle={employeeData?.taluka_id?.name} />
          <ViewData title={getTitles('T-10068')} subTitle={employeeData?.village_id?.name} />
          <ViewData title={getTitles('T-10359')} subTitle={employeeData.post_code} />
        </>
      )}
    </Box>
  );
};
