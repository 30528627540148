import { put, takeLatest } from 'redux-saga/effects';
import { KEYS } from '../../../constants';
import {
  addLaboratoryFailure,
  addLaboratoryRequest,
  addLaboratorySuccess,
  getLabTechFailure,
  getLabTechRequest,
  getLabTechSuccess,
  getLaboratoryDetailsFailure,
  getLaboratoryDetailsRequest,
  getLaboratoryDetailsSuccess,
  getLaboratoryFailure,
  getLaboratorySuccess,
  getLaboratorysRequest,
  getPathologistFailure,
  getPathologistRequest,
  getPathologistSuccess,
  getSupportEmployeeFailure,
  getSupportEmployeeRequest,
  getSupportEmployeeSuccess,
  updateLaboratoryFailure,
  updateLaboratoryRequest,
  updateLaboratorySuccess
} from '../slices';
import {
  addLaboratoryAPI,
  getEmployeeByPermissionApi,
  getLaboratoryAPI,
  getLaboratoryDetailsAPI,
  updateLaboratoryAPI
} from '../../api';

function* addLaboratory(action) {
  try {
    const response = yield addLaboratoryAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(addLaboratorySuccess(response.data));
    } else {
      yield put(addLaboratoryFailure(response.data));
    }
  } catch (error) {
    yield put(addLaboratoryFailure(error.response.data));
  }
}

function* updateLaboratory(action) {
  try {
    const response = yield updateLaboratoryAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(updateLaboratorySuccess(response.data));
    } else {
      yield put(updateLaboratoryFailure(response.data));
    }
  } catch (error) {
    yield put(updateLaboratoryFailure(error.response.data));
  }
}

function* getLaboratory(action) {
  try {
    const response = yield getLaboratoryAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getLaboratorySuccess(response.data));
    } else {
      yield put(getLaboratoryFailure(response.data));
    }
  } catch (error) {
    yield put(getLaboratoryFailure(error.response.data));
  }
}

function* getLaboratoryDetails(action) {
  try {
    const response = yield getLaboratoryDetailsAPI(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getLaboratoryDetailsSuccess(response.data));
    } else {
      yield put(getLaboratoryDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(getLaboratoryDetailsFailure(error.response.data));
  }
}

function* getPathologist(action) {
  try {
    const response = yield getEmployeeByPermissionApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getPathologistSuccess(response.data));
    } else {
      yield put(getPathologistFailure(response.data));
    }
  } catch (error) {
    yield put(getPathologistFailure(error.response.data));
  }
}

function* getLabTech(action) {
  try {
    const response = yield getEmployeeByPermissionApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getLabTechSuccess(response.data));
    } else {
      yield put(getLabTechFailure(response.data));
    }
  } catch (error) {
    yield put(getLabTechFailure(error.response.data));
  }
}

function* getSupportEmployee(action) {
  try {
    const response = yield getEmployeeByPermissionApi(action.payload);
    if (response.data.status === KEYS.SUCCESS_KEY) {
      yield put(getSupportEmployeeSuccess(response.data));
    } else {
      yield put(getSupportEmployeeFailure(response.data));
    }
  } catch (error) {
    yield put(getSupportEmployeeFailure(error.response.data));
  }
}

export function* laboratorySaga() {
  yield takeLatest(addLaboratoryRequest.type, addLaboratory);
  yield takeLatest(updateLaboratoryRequest.type, updateLaboratory);
  yield takeLatest(getLaboratorysRequest.type, getLaboratory);
  yield takeLatest(getLaboratoryDetailsRequest.type, getLaboratoryDetails);
  yield takeLatest(getPathologistRequest.type, getPathologist);
  yield takeLatest(getLabTechRequest.type, getLabTech);
  yield takeLatest(getSupportEmployeeRequest.type, getSupportEmployee);
}
