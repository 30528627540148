import { Box } from '@mui/material';
import { UIAutocompleted, UISaveAndAdd, UITextInputWithTitle } from '../../../../../ui-kits';
import { defaultValues } from '../../../../../constants';
import { getTitles } from '../../../../../utils';
const res = defaultValues.isResponsive;
const styles = {
  container: {
    width: '100%'
  },
  cont: {
    display: 'flex',
    gap: '10px'
  },
  width: res ? '100%' : 'calc((100% - 10px)/3)',
  phone: {
    width: res ? '100%' : 'calc((100% + 5px)/3 * 2)',
    maxWidth: 'none'
  }
};
export const SupportEmployee = ({
  suppEmp,
  searchList,
  handleBlur,
  setFieldValue,
  values,
  handleChange,
  handleAddAnother,
  preSelectedValue,
  currRender,
  view
}) => {
  let timer;

  return (
    <Box sx={styles.container}>
      {currRender !== view && (
        <UIAutocompleted
          required={true}
          options={suppEmp && suppEmp.length > 0 ? suppEmp.map((item) => item) : []}
          optionLabel={(item) =>
            item.first_name
              ? item.first_name + ' ' + item.last_name
              : item.full_name
                ? item.full_name
                : ''
          }
          variant={defaultValues.isResponsive ? 'medium' : 'body'}
          onTyping={(val) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (val.length > 2) {
                searchList(val, defaultValues.appLogicalTexts.supportEmp);
              }
            }, 500);
          }}
          onBlur={handleBlur}
          containerStyle={styles.phone}
          placeHolder={getTitles('T-10077')}
          selectedValue={(val) => {
            setFieldValue('support_employee.employee_id', val ? val._id : null);
            setFieldValue('support_employee.employee_code', val ? val.employee_id : '');
            setFieldValue(
              'support_employee.full_name',
              val ? `${val.first_name + ' ' + val.last_name}` : null
            );
          }}
          preSelectedValue={
            preSelectedValue?.employee_id && {
              employee_id: preSelectedValue.employee_id,
              full_name: preSelectedValue.full_name
            }
          }
          disabled={currRender === view}
        />
      )}
      <Box sx={styles.cont}>
        <UITextInputWithTitle
          name="support_employee.employee_code"
          title={getTitles('T-11115')}
          value={values.support_employee && values.support_employee.employee_code}
          onChange={handleChange}
          onBlur={handleBlur}
          width={styles.width}
          disabled={true}
        />
        <UITextInputWithTitle
          name="support_employee.role"
          placeHolder={getTitles('T-11088')}
          value={values.support_employee && values.support_employee.role}
          onChange={handleChange}
          onBlur={handleBlur}
          width={styles.width}
          title={getTitles('T-10455')}
          disabled={currRender === view}
        />
      </Box>
      {currRender !== view && (
        <UISaveAndAdd
          title={
            currRender === defaultValues.actionType.add
              ? getTitles('T-10062')
              : getTitles('T-10477')
          }
          onAddAnother={handleAddAnother}
          disabled={!values.support_employee || !values.support_employee.employee_code}
        />
      )}
    </Box>
  );
};
