import { Box } from '@mui/material';
import { NoData, Text } from '../../../../ui-kits';
import { styles } from './styles';
import { getTitles } from '../../../../utils';
import { getErrorMessage } from '../../../../constants';

export const EcomCategoryCard = ({ title, list, onViewAllClick = () => {} }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.sub1}>
        <Text variant="D16" style={styles.subText1}>
          {title}
        </Text>
        {list.length > 0 && (
          <Text variant="D16" style={styles.subText2} onClick={onViewAllClick}>
            {getTitles('T-10415')}
          </Text>
        )}
      </Box>

      <Box sx={styles.sub2super}>
        {list?.length > 0 ? (
          list.map((item, idx) => (
            <Box sx={styles.sub2} key={idx}>
              <Box sx={styles.imgCont}>
                <img style={styles.img} src={item.product_image && item.product_image[0]} />
              </Box>
              <Box sx={styles.textCont}>
                <Text variant="D20" style={styles.name}>
                  {item.product_name}
                </Text>
                <Text variant="D16" style={styles.price}>
                  {`₹${item.final_price || 0}`}
                </Text>
              </Box>
            </Box>
          ))
        ) : (
          <NoData message={getErrorMessage('E-10207')} height="30vh" titleVariant="BoldH20" />
        )}
      </Box>
    </Box>
  );
};
