import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isLoginPage: false,
  successMessage: null,
  errorMessage: null,
  currentPage: null,
  nextPage: null,
  totalPage: null,
  govtOfficials: [],
  govtOfficialData: null,
  currRender: 1,
  govtOfficialParams: null,
  currUserId: null,
  dashboardData: {},
  cattleNextPage: null,
  cattleTotalPage: null,
  cattleCurrentPage: null,
  farmerNextPage: null,
  farmerTotalPage: null,
  farmerCurrentPage: null,
  societyNextPage: null,
  societyTotalPage: null,
  societyCurrentPage: null,
  govtCattle: [],
  govtFarmer: [],
  govtSociety: []
};

const govtOfficialSlice = createSlice({
  initialState,
  name: 'govtOfficial',
  reducers: {
    resetGovtOffice(state) {
      state.isLoading = false;
      state.isLoginPage = false;
      state.successMessage = null;
      state.errorMessage = null;
      state.currentPage = null;
      state.nextPage = null;
      state.totalPage = null;
      state.govtOfficials = [];
      state.govtOfficialData = null;
      state.currRender = 1;
      state.govtOfficialParams = null;
      state.currUserId = null;
    },
    setCurrUserId(state, action) {
      state.currUserId = action.payload;
    },
    addGovtOfficialRequest(state) {
      state.isLoading = true;
    },
    addGovtOfficialSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    addGovtOfficialFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },
    getGovtOfficialsRequest(state) {
      state.isLoading = true;
    },
    getGovtOfficialSuccess(state, action) {
      state.isLoading = false;
      state.govtOfficials = action.payload.data.govtOfficer;
      state.currentPage = action.payload.data.current_page;
      state.nextPage = action.payload.data.next_page;
      state.totalPage = action.payload.data.total_page;
    },
    getGovtOfficialFailure(state, action) {
      state.isLoading = false;
      state.govtOfficials = [];
      state.errorMessage = action.payload.status_code;
    },
    updateGovtOfficialRequest(state) {
      state.isLoading = true;
    },
    updateGovtOfficialSuccess(state, action) {
      state.isLoading = false;
      state.successMessage = action.payload.status_code;
    },
    updateGovtOfficialFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    },

    getGovtOfficialDetailsRequest(state) {
      state.isLoading = true;
    },
    getGovtOfficialDetailsSuccess(state, action) {
      state.isLoading = false;
      state.govtOfficialData = action.payload.data;
    },
    getGovtOfficialDetailsFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
      state.govtOfficialData = null;
    },

    setGovtOfficialData(state, action) {
      state.govtOfficialData = action.payload;
    },
    setGovtOfficialCurrentRender(state, action) {
      state.currRender = action.payload;
    },
    setGovtOfficialParams(state, action) {
      state.govtOfficialParams = action.payload;
    },
    resetGovtOfficialMsg(state) {
      state.errorMessage = null;
      state.successMessage = null;
    },
    getGovtOfficialDashboardRequest(state) {
      state.isLoading = true;
    },
    getGovtOfficialDashboardSuccess(state, action) {
      state.isLoading = false;
      state.dashboardData = action.payload.data;
      const newCattleList = action.payload.data?.cattle?.cattle_list || [];
      if (state.cattleCurrentPage < action.payload.data?.cattle?.current_page) {
        state.govtCattle = [...(state.govtCattle || []), ...newCattleList];
      } else {
        state.govtCattle = newCattleList;
      }
      const newFarmerList = action.payload.data?.farmer?.farmer_list || [];
      if (state.farmerCurrentPage < action.payload.data?.farmer?.current_page) {
        state.govtFarmer = [...(state.govtFarmer || []), ...newFarmerList];
      } else {
        state.govtFarmer = newFarmerList;
      }
      const newSocietyList = action.payload.data?.society?.society_list || [];
      if (state.govtSociety < action.payload.data?.society?.current_page) {
        state.govtSociety = [...(state.govtSociety || []), ...newSocietyList];
      } else {
        state.govtSociety = newSocietyList;
      }
      state.cattleNextPage = action.payload.data?.cattle?.next_page;
      state.cattleTotalPage = action.payload.data?.cattle?.total_page;
      state.cattleCurrentPage = action.payload.data?.cattle?.current_page;
      state.societyNextPage = action.payload.data?.society?.next_page;
      state.societyTotalPage = action.payload.data?.society?.total_page;
      state.societyCurrentPage = action.payload.data?.society?.current_page;
      state.farmerNextPage = action.payload.data?.farmer?.next_page;
      state.farmerTotalPage = action.payload.data?.farmer?.total_page;
      state.farmerCurrentPage = action.payload.data?.farmer?.current_page;
    },
    getGovtOfficialDashboardFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload.status_code;
    }
  }
});

export const {
  resetGovtOfficialMsg,
  setGovtOfficialData,
  setGovtOfficialParams,
  setGovtOfficialCurrentRender,
  addGovtOfficialFailure,
  addGovtOfficialRequest,
  addGovtOfficialSuccess,
  getGovtOfficialFailure,
  getGovtOfficialsRequest,
  getGovtOfficialSuccess,
  updateGovtOfficialFailure,
  updateGovtOfficialRequest,
  updateGovtOfficialSuccess,
  getGovtOfficialDetailsRequest,
  getGovtOfficialDetailsFailure,
  getGovtOfficialDetailsSuccess,
  resetGovtOffice,
  setCurrUserId,
  getGovtOfficialDashboardFailure,
  getGovtOfficialDashboardRequest,
  getGovtOfficialDashboardSuccess
} = govtOfficialSlice.actions;

export default govtOfficialSlice.reducer;
