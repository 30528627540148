import { Box } from '@mui/material';
import { MultipleImageCarousel } from '../multiple-image-carousel';
import { styles } from './styles';
import { ImageCarousel } from '../image-carousel';
import { ProductListEcom } from '../product-list';
import { getTitles, resetMessages } from '../../../../utils';
import { EcomCategoryCard, EcomEquipmentCard } from '../../../common';

import { useNavigate } from 'react-router-dom';
import {
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
  SCREEN_PATH
} from '../../../../constants';

import { useDispatch, useSelector } from 'react-redux';
import {
  addCartItemsRequest,
  getAdverticementRequest,
  getBannersRequest,
  getBestDealsRequest,
  getBestSellerRequest,
  getCartItemsRequest,
  getFeaturedProductsRequest,
  getHotRequest,
  getNewArrivalRequest,
  getRecentProductsRequest,
  resetECommerceMsg,
  setCartItems,
  setECommerceFeaturedCurrRender,
  setEcommerceFeaturedMaterialGrp,
  setEcommerceRecentCurrRender,
  setEcommerceRecentMaterialGrp,
  updateScrollToTop
} from '../../../../services/redux/slices';
import { useEffect } from 'react';
import {
  authStatus,
  getAdvertisementProducts,
  getBestDeals,
  getBestSeller,
  getCartItems,
  getEcommerceBanners,
  getEcommerceErrorMsg,
  getEcommerceFeaturedCurrRender,
  getEcommerceFeaturedProducts,
  getEcommerceFeaturedProductsMG,
  getEcommerceRecentCurrRender,
  getEcommerceRecentProducts,
  getEcommerceRecentProductsMG,
  getEcommerceSuccessMsg,
  getHot,
  getNewArrival,
  getScroll
} from '../../../../services/redux/selectors';
import { toast } from 'react-toastify';

export const EcomMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const featuredCurrRender = useSelector(getEcommerceFeaturedCurrRender);
  const recentCurrRender = useSelector(getEcommerceRecentCurrRender);
  const banners = useSelector(getEcommerceBanners);
  const featuredProductList = useSelector(getEcommerceFeaturedProducts);
  const recentProducts = useSelector(getEcommerceRecentProducts);
  const featuredProductsMg = useSelector(getEcommerceFeaturedProductsMG);
  const recentProductsMg = useSelector(getEcommerceRecentProductsMG);
  const successMsg = useSelector(getEcommerceSuccessMsg);
  const errorMsg = useSelector(getEcommerceErrorMsg);
  const adverticement = useSelector(getAdvertisementProducts);
  const bestDeals = useSelector(getBestDeals);
  const hot = useSelector(getHot);
  const bestSeller = useSelector(getBestSeller);
  const newArrival = useSelector(getNewArrival);
  const scroll = useSelector(getScroll);
  const isAuthenticated = useSelector(authStatus);
  const cartItems = useSelector(getCartItems);

  const { recent, featured, bestDealsVar, bestSellerVar, hotDealsVar, newArrivalVar } =
    defaultValues.appLogicalTexts;
  const renderMaterialGroup = defaultValues.materialGroupOptionsEcom;
  const res = defaultValues.isResponsive;
  const { recentType, featuredType } = defaultValues.productSearchType;
  const {
    actFeatured,
    actRecentAdded,
    actRecentViewed,
    actBestDeals,
    actHotProducts,
    actBestSeller,
    actNewArrival
  } = defaultValues.productActivityTitles;

  useEffect(() => {
    dispatch(updateScrollToTop(!scroll));
    dispatch(getBannersRequest());
    isAuthenticated && dispatch(getCartItemsRequest());

    dispatch(
      getFeaturedProductsRequest({
        filter: JSON.stringify([{ material_group: featuredProductsMg }]),
        type: featuredType
      })
    );
    dispatch(
      getRecentProductsRequest({
        filter: JSON.stringify([{ material_group: recentProductsMg }]),
        type: recentType
      })
    );
    dispatch(
      getAdverticementRequest({
        limit: 3
      })
    );

    dispatch(
      getBestDealsRequest({
        limit: 3,
        mark_as: defaultValues.markAs[1].value
      })
    );
    dispatch(
      getHotRequest({
        limit: 3,
        mark_as: defaultValues.markAs[0].value
      })
    );
    dispatch(
      getBestSellerRequest({
        limit: 3,
        mark_as: defaultValues.markAs[2].value
      })
    );

    dispatch(
      getNewArrivalRequest({
        limit: 3,
        mark_as: defaultValues.markAs[3].value
      })
    );
  }, []);

  useEffect(() => {
    if (successMsg) {
      toast.success(getSuccessMessage(successMsg));
    }
    if (errorMsg && errorMsg !== 'E-10281') {
      toast.error(getErrorMessage(errorMsg));
    }
    resetMessages(() => dispatch(resetECommerceMsg()));
  }, [successMsg, errorMsg]);

  const handleBrowseAllClick = (type, title, material_group) => {
    navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.ACTIVITY}`, {
      state: { title, type, material_group }
    });
  };

  const handleProductdetails = (item) => {
    navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.PRODUCT_DETAILS}/${item._id}`);
  };

  const handleTabChange = (item, idx, type) => {
    if (type === recent) {
      dispatch(setEcommerceRecentMaterialGrp(item.id));
      dispatch(
        getRecentProductsRequest({
          filter: JSON.stringify([{ material_group: item.id }]),
          type: recentType
        })
      );
      dispatch(setEcommerceRecentCurrRender(idx));
    }
    if (type === featured) {
      dispatch(setEcommerceFeaturedMaterialGrp(item.id));
      dispatch(
        getFeaturedProductsRequest({
          filter: JSON.stringify([{ material_group: item.id }]),
          type: featuredType
        })
      );
      dispatch(setECommerceFeaturedCurrRender(idx));
    }
  };

  const handleViewAllClick = (type) => {
    let payload = {};
    let title = '';

    if (type === bestDealsVar) {
      payload.mark_as = defaultValues.markAs[1].value;
      title = actBestDeals;
    }
    if (type === hotDealsVar) {
      payload.mark_as = defaultValues.markAs[0].value;
      title = actHotProducts;
    }
    if (type === bestSellerVar) {
      payload.mark_as = defaultValues.markAs[2].value;
      title = actBestSeller;
    }
    if (type === newArrivalVar) {
      payload.mark_as = defaultValues.markAs[3].value;
      title = actNewArrival;
    }

    navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.ACTIVITY}`, {
      state: { title, mark_as: payload.mark_as }
    });
  };

  const onMaterialGroupClick = (item) => {
    navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.ACTIVITY}`, {
      state: { title: item.title, material_group: item.id }
    });
  };

  const addCartClick = (item) => {
    if (isAuthenticated) {
      dispatch(addCartItemsRequest({ product_id: item._id }));
    } else {
      (!cartItems || !cartItems[item._id]) &&
        dispatch(setCartItems({ ...cartItems, [item._id]: { ...item, quantity: 1 } }));
    }
  };

  const handleBuyNow = (item) => {
    addCartClick(item);
    navigate(`${SCREEN_PATH.ECOMMERCE}/${SCREEN_PATH.SHOPPING_CART}`);
  };

  return (
    <>
      <MultipleImageCarousel images={renderMaterialGroup} onTitleClick={onMaterialGroupClick} />

      <Box sx={styles.bannerCont}>
        <ImageCarousel images={banners || []} key={banners.join(',')} />
      </Box>

      <ProductListEcom
        tablist={renderMaterialGroup}
        currentTab={featuredCurrRender}
        title={getTitles('T-11592')}
        list={featuredProductList}
        onBrowseAll={() => handleBrowseAllClick(featuredType, actFeatured, featuredProductsMg)}
        onProductTitleClick={handleProductdetails}
        onTabChange={(item, idx) => handleTabChange(item, idx, featured)}
        tabContStyle={styles.tabCont}
      />

      {adverticement?.length >= 2 && (
        <Box sx={styles.equipCont}>
          {!res && (
            <EcomEquipmentCard
              type={getTitles('T-11602')}
              title={adverticement[0] && adverticement[0]?.product_name}
              containerStyles={styles.equipLeft}
              hintStyles={styles.leftHint}
              image={
                (adverticement[0] &&
                  adverticement[0]?.product_image &&
                  adverticement[0]?.product_image[0]) ||
                null
              }
              price={(adverticement[0] && adverticement[0]?.final_price) || 0}
              handleBuyNow={() => handleBuyNow(adverticement[0])}
            />
          )}
          <EcomEquipmentCard
            type={getTitles('T-11603')}
            hintStyles={styles.rightHint}
            title={adverticement[1] && adverticement[1]?.product_name}
            image={
              (adverticement[1] &&
                adverticement[1]?.product_image &&
                adverticement[1]?.product_image[0]) ||
              null
            }
            price={(adverticement[1] && adverticement[1]?.final_price) || 0}
            handleBuyNow={() => handleBuyNow(adverticement[1])}
          />
        </Box>
      )}

      <ProductListEcom
        tablist={renderMaterialGroup}
        title={isAuthenticated ? getTitles('T-11594') : getTitles('T-11651')}
        list={recentProducts}
        onProductTitleClick={handleProductdetails}
        onBrowseAll={() =>
          handleBrowseAllClick(
            recentType,
            isAuthenticated ? actRecentViewed : actRecentAdded,
            recentProductsMg
          )
        }
        currentTab={recentCurrRender}
        onTabChange={(item, idx) => handleTabChange(item, idx, recent)}
        tabContStyle={styles.tabCont}
        itemsContainer={styles.itemsContainer}
      />

      {adverticement?.length === 3 && (
        <EcomEquipmentCard
          type={getTitles('T-11603')}
          containerStyles={{ ...styles.equipLeft, mt: res ? 2 : 3 }}
          hintStyles={styles.rightHint}
          title={adverticement[2] && adverticement[2]?.product_name}
          image={
            (adverticement[2] &&
              adverticement[2]?.product_image &&
              adverticement[2]?.product_image[0]) ||
            null
          }
          price={(adverticement[2] && adverticement[2]?.final_price) || 0}
          priceStyle={styles.priceStyle}
          handleBuyNow={() => handleBuyNow(adverticement[2])}
        />
      )}

      <Box sx={styles.catCont}>
        {bestDeals?.length > 0 && (
          <EcomCategoryCard
            title={getTitles('T-11595')}
            list={bestDeals}
            onViewAllClick={() => handleViewAllClick(bestDealsVar)}
          />
        )}
        {hot?.length > 0 && (
          <EcomCategoryCard
            title={getTitles('T-11596')}
            list={hot}
            onViewAllClick={() => handleViewAllClick(hotDealsVar)}
          />
        )}
        {bestSeller?.length > 0 && (
          <EcomCategoryCard
            title={getTitles('T-11597')}
            list={bestSeller}
            onViewAllClick={() => handleViewAllClick(bestSellerVar)}
          />
        )}
        {newArrival?.length > 0 && (
          <EcomCategoryCard
            title={getTitles('T-11598')}
            list={newArrival}
            onViewAllClick={() => handleViewAllClick(newArrivalVar)}
          />
        )}
      </Box>
    </>
  );
};
