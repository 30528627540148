import { Box } from '@mui/material';
import { styles } from './styles';
import { NoData, Text } from '../../../../ui-kits';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getTitles } from '../../../../utils';
import { EComProductCard } from '../../../common';
import { useDispatch, useSelector } from 'react-redux';
import { authStatus, getCartItems } from '../../../../services/redux/selectors';
import {
  addCartItemsRequest,
  setCartItems,
  updateCartItemsRequest
} from '../../../../services/redux/slices';
import { defaultValues, getErrorMessage } from '../../../../constants';
import { useEffect, useRef, useState } from 'react';

export const ProductListEcom = ({
  tablist,
  currentTab = 0,
  onTabChange = () => {},
  title,
  list,
  onBrowseAll = () => {},
  productCardContainerStyles,
  onProductTitleClick = () => {},
  tabSubStyle,
  tabContStyle,
  showBrowse = true,
  showTabs = true,
  itemsContainer,
  productCardImageStyle
}) => {
  const dispatch = useDispatch();

  const cartItems = useSelector(getCartItems);
  const isAuthenticated = useSelector(authStatus);

  const containerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const res = defaultValues.isResponsive;

  useEffect(() => {
    const checkScrollPosition = () => {
      if (containerRef?.current) {
        const { scrollWidth, clientWidth, scrollLeft } = containerRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft + clientWidth < scrollWidth - 1);
      }
    };

    checkScrollPosition();

    containerRef?.current?.addEventListener('scroll', checkScrollPosition);

    return () => {
      containerRef?.current?.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);

  const handlePrevClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -containerRef.current.clientWidth,
        behavior: 'smooth'
      });
    }
  };

  const handleNextClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: containerRef.current.clientWidth,
        behavior: 'smooth'
      });
    }
  };

  const handleMinusQty = (item) => {
    if (isAuthenticated) {
      dispatch(
        updateCartItemsRequest({ product_id: item._id, quantity: cartItems[item._id].quantity - 1 })
      );
    } else {
      let final = { ...cartItems };
      if (cartItems[item._id].quantity > 1) {
        final = { ...final, [item._id]: { ...item, quantity: cartItems[item._id].quantity - 1 } };
      } else {
        delete final[item._id];
      }
      dispatch(setCartItems(final));
    }
  };

  const addCartClick = (item) => {
    if (isAuthenticated) {
      dispatch(addCartItemsRequest({ product_id: item._id }));
    } else {
      dispatch(setCartItems({ ...cartItems, [item._id]: { ...item, quantity: 1 } }));
    }
  };

  const handleAddCartQuatity = (item) => {
    if (isAuthenticated) {
      dispatch(
        updateCartItemsRequest({ product_id: item._id, quantity: cartItems[item._id].quantity + 1 })
      );
    } else {
      dispatch(
        setCartItems({
          ...cartItems,
          [item._id]: { ...item, quantity: cartItems[item._id].quantity + 1 }
        })
      );
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.tabSuperCont}>
        <Text variant="BoldH24" style={styles.title}>
          {title}
        </Text>
        <Box sx={styles.tabSub(tabSubStyle)}>
          {!res && showTabs && (
            <>
              <ArrowBackIosNewIcon sx={styles.chevron(canScrollLeft)} onClick={handlePrevClick} />
              <Box sx={styles.tabCont(tabContStyle)} ref={containerRef}>
                {tablist.map((item, idx) => (
                  <Box
                    key={idx}
                    sx={styles.tab(currentTab === idx)}
                    onClick={() => onTabChange(item, idx)}>
                    <Text variant="D16" style={styles.tabText(currentTab === idx)}>
                      {item.title}
                    </Text>
                  </Box>
                ))}
              </Box>
              <ArrowForwardIosOutlinedIcon
                sx={styles.chevron(canScrollRight)}
                onClick={handleNextClick}
              />
            </>
          )}
          {showBrowse && (
            <Box sx={styles.browseContainer}>
              <Text variant="D16" style={styles.browseText} onClick={onBrowseAll}>
                {res ? getTitles('T-11646') : getTitles('T-11593')}
              </Text>
              <ArrowForwardOutlinedIcon sx={styles.browseArrow} />
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={styles.itemsCont(itemsContainer)}>
        {list?.length > 0 ? (
          list.map((item, idx) => (
            <EComProductCard
              containerStyles={productCardContainerStyles}
              key={idx}
              item={item}
              onTitleClick={() => onProductTitleClick(item)}
              count={cartItems && cartItems[item._id]?.quantity}
              counterContainerStyle={styles.counterCont}
              cartBtnStyle={styles.cartBtnStyle}
              countStyle={styles.countStyle}
              addCartClick={() => addCartClick(item)}
              addQty={() => handleAddCartQuatity(item)}
              minusQty={() => handleMinusQty(item)}
              imageStyle={productCardImageStyle}
            />
          ))
        ) : (
          <NoData message={getErrorMessage('E-10264')} />
        )}
      </Box>
    </Box>
  );
};
