import { Avatar, Box } from '@mui/material';
import { Text, ViewData } from '../../../../ui-kits';
import { defaultValues } from '../../../../constants';
import { defaultStyles, imgBox, imgStyle } from './style';

export const DetailsCardImg = ({
  cardHeaderTitle,
  imgUrl,
  properties,
  values,
  customStyle,
  titleWidth,
  valueWidth,
  bodyContStyle
}) => {
  const styles = {
    ...defaultStyles,
    ...customStyle,
    ...(imgUrl && {
      imgBox,
      imgStyle
    })
  };

  return (
    <>
      <Box sx={styles.superContainer}>
        <Box sx={styles.container}>
          <Box sx={styles.headerCont}>
            <Text variant="D22" sx={styles.headerTitleTxt}>
              {cardHeaderTitle}
            </Text>
          </Box>
          <Box sx={{ ...styles.bodyCont, ...bodyContStyle }}>
            <Box sx={styles.imgBox}>
              {imgUrl && <Avatar alt="" src={imgUrl} sx={styles.imgStyle} />}
            </Box>
            <Box sx={styles.detailsBox}>
              {properties?.map((item, idx) => {
                return (
                  <ViewData
                    key={idx}
                    title={item.name}
                    titleVariant={defaultValues.isResponsive ? 'D24' : 'BASE'}
                    subTitleVariant={defaultValues.isResponsive ? 'D24' : 'BASE'}
                    titleStyle={styles.detailTitleTxt(titleWidth)}
                    subTitleCont={styles.detailValueTxt(valueWidth)}
                    subTitle={(values && values[item?.key]) || item.value}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
