import { Box } from '@mui/material';
import cowImg from '../../assets/images/profile.jpg';
import { BasicProfile } from '../common/utility-components';
import { getFullName, getTitles } from '../../utils';
import { defaultValues, getErrorMessage, SCREEN_PATH } from '../../constants';
import { Screen, Text, UILayout } from '../../ui-kits';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDetailsPg,
  getFarmerInfo,
  getInsuranceFarmerLoading
} from '../../services/redux/selectors';
import { useEffect, useState } from 'react';
import { UIBackButton } from '../../ui-kits/back-button';
import { updateDetailsPg } from '../../services/redux/slices';
import { styles } from './style';

export const ManageInsuranceCattleTable = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const farmerDetails = useSelector(getFarmerInfo);
  const isLoading = useSelector(getInsuranceFarmerLoading);
  const showBackButton = useSelector(getDetailsPg);

  const [farmer, setFarmer] = useState({});
  const [cattles, setCattles] = useState([]);

  useEffect(() => {
    if (farmerDetails && params.farmer_id) {
      const particularFarmer = farmerDetails.find((ele) => ele.farmer_id === params.farmer_id);
      if (particularFarmer) {
        setFarmer(particularFarmer);
        if (Array.isArray(particularFarmer.cattle)) {
          setCattles(particularFarmer.cattle);
        } else {
          setCattles([]);
        }
      }
    }
  }, [farmerDetails, params.farmer_id]);

  useEffect(() => {
    if (!showBackButton) {
      navigateToFarmer();
    }
  }, [showBackButton]);

  const tableData = cattles.map((item, index) => {
    if (!defaultValues.isResponsive) {
      return [
        {
          title: getTitles('T-11407'),
          values: <Text variant="D22">{++index}</Text>,
          columnWidth: styles.columnWidth
        },
        {
          title: getTitles('T-11408'),
          values: (
            <Text style={styles.id} variant="D22">
              {item?.cattle_id}
            </Text>
          ),
          columnWidth: styles.columnWidth
        },
        {
          title: getTitles('T-10984'),
          columnWidth: styles.columnWidth,
          values: (
            <BasicProfile
              title={item?.cattle_name}
              url={item?.cattle_photo || cowImg}
              titleStyle={styles.cattleName}
              startIconStyle={styles.profileImg}
              onTitleClick={() => navigateToCattleDetails(farmer?.farmer_id, item?._id)}
              textVariant="D22"
            />
          )
        }
      ];
    } else {
      return {
        title: (
          <Box component={'div'} sx={styles.tableItem}>
            <Text variant="D22">{++index}</Text>
            <BasicProfile
              title={item?.cattle_name}
              subTitle={item?.cattle_id}
              titleStyle={styles.profileTitle}
              subTitleStyle={styles.profileSubTitle}
              url={item?.cattle_photo || cowImg}
              onTitleClick={() => navigateToCattleDetails(farmer?.farmer_id, item?._id)}
              startIconStyle={styles.profileImg}
            />
          </Box>
        ),
        details: []
      };
    }
  });

  const navigateToCattleDetails = (farmerId, cattleId) => {
    dispatch(updateDetailsPg(true));
    navigate(`${SCREEN_PATH.MANAGE_INSURANCE_CATTLE_DETAILS}/${farmerId}/${cattleId}`);
  };

  const navigateToFarmer = () => {
    navigate(SCREEN_PATH.MANAGE_INSURANCE_FARMERS_INFO);
  };

  return (
    <Screen showSideBar={true} isLoading={isLoading} currentPage={getTitles('T-11412')}>
      {defaultValues.isResponsive && (
        <Text variant="boldH32Sec" style={{ mt: 2 }}>
          {getTitles('T-11412')}
        </Text>
      )}
      <Box component={'div'} sx={styles.profileBox}>
        {!defaultValues.isResponsive && (
          <UIBackButton style={styles.backArrowButton} onClick={navigateToFarmer} />
        )}

        <BasicProfile
          title={`${getFullName([farmer?.first_name, farmer?.middle_name, farmer?.last_name])} (${farmer?.phone_number})`}
          url={farmer?.id_photo}
          startIconStyle={styles.profileImg}
          textVariant="D22"
        />
      </Box>
      <UILayout
        tableItems={tableData}
        showNoData={!farmer.cattle?.length > 0}
        showDataTable={!defaultValues.isResponsive}
        collapsable={!defaultValues.isResponsive}
        message={getErrorMessage('E-10155')}
      />
    </Screen>
  );
};
